import { useState, useEffect } from "react";
import { useFormik } from "formik";

// Material
import { Card, CardContent, Typography, Stack, Box, Button, Icon, ButtonGroup } from "@mui/material";

import Grid2 from "@mui/material/Unstable_Grid2/Grid2";

import { useCatalogs } from "@hooks/useCatalogs";
import InputSelect from "@components/Selects/BasicSelect";
import ResponsablesList from "@components/Certificacion/Assign/ResponsableList";
import AdvancedSelect from "@components/Selects/AdvancedSelect";
import { isEmptyOrNullObject } from "@utils/validations";
import Yup from "@utils/Yupi18n";

import Swal from "sweetalert2";

import CertificacionServices from "@services/CertificationServices";

const validationSchema = Yup.object().shape({
  Seccion: Yup.array().min(1).required().label("Sección"),
});

const AssignCertification = (props) => {
  const { params, setLoading, getCertificacion } = props;

  const [catalogsFiltered, setCatalogFiltered] = useState({});
  const [catResponsibilitiesType, setCatResponsibilitiesType] = useState([]);
  const [formType, setFormType] = useState(0);
  const staticValue = {
    idPerfil: 184,
    idAmbitoCargo: 8
  }

  const formik = useFormik({
    initialValues: {
      Seccion: [],
      idResponsable: "",
      Responsable: "",
      Municipio: 0,
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      await handleSave(values);
    },
  });

  const catalogsOptions = [
    { id: "municipios_reportes", getAll: true },
    { id: "secciones", getAll: true },
  ];

  const { catalogs, load } = useCatalogs({
    catalogsOptions: catalogsOptions,
    putDefaultOption: true,
  });

  const handleChangeMunicipality = (e) => {
    const _idMunicipality = e.target.value;
    if (_idMunicipality === 0) {
      setCatalogFiltered(catalogs);
    } else {
      setCatalogFiltered((prevState) => ({
        ...prevState,
        secciones: catalogs.secciones.filter(({ idMunicipioReportes }) => idMunicipioReportes === _idMunicipality),
      }));
      formik.setValues((prev) => ({ ...prev, Municipio: _idMunicipality }));
    }
  };

  const handleChangeSection = (newValue) => {
    formik.setValues((prev) => ({ ...prev, Seccion: newValue }));
  };

  const clean = () => {
    setCatalogFiltered(catalogs);
    formik.resetForm();
  };

  const handleSave = async () => {
    try {
      setLoading(true);
      let values = {
        idUsuario: params.id,
        idPerfil: staticValue.idPerfil,
        idAmbitoCargo: staticValue.idAmbitoCargo, 
        AmbitoValorCargo: formik.values.Seccion
      };
      const result = await CertificacionServices.setResponsability(values);
      const { results, message } = result;
      if (results) {
        Swal.fire({
          title: message,
          icon: "success",
        });
        getCertificacion(prev => !prev)
      } else {
        throw new Error(message);
      }
    } catch (error) {
      Swal.fire({
        title: error.message,
        icon: "warning",
      });
    } finally {
      setLoading(false);
      clean();
    }
  };

  useEffect(() => {
    setCatResponsibilitiesType([{ idTipo: 0, Tipo: "Certificación" }]);
  }, []);

  useEffect(() => {
    if (load === false) {
      setCatalogFiltered(catalogs);
    }
    // eslint-disable-next-line
  }, [load]);

  return (
    <Card className="card-primary" sx={{ height: "100%" }}>
      <CardContent>
        <Box textAlign="center" mt={2}>
          <Typography component={"h6"} variant="h6" fontWeight={800} fontSize={18}>
            AGREGAR PROPUESTAS DE RESPONSABILIDAD
          </Typography>
          <Stack spacing={2} justifyContent="center" alignItems="center">
            <Typography variant="div">Tipo de responsabilidad</Typography>
            <ButtonGroup size="medium" variant="outlined">
              {catResponsibilitiesType.map((item) => (
                <Button
                  color="info"
                  key={item.idTipo}
                  onClick={() => setFormType(item.idTipo)}
                  variant={item.idTipo === formType ? "contained" : "outlined"}
                  sx={{
                    borderWidth: "1px",
                    borderRadius: "30px",
                    pr: "23 px",
                    pl: "23 px",
                  }}
                >
                  {item.Tipo}
                </Button>
              ))}
            </ButtonGroup>
          </Stack>
        </Box>
        {/* <Grid2>
          <ResponsablesList
            setResponsable={(e) => {
              formik.setValues((prev) => ({ ...prev, idResponsable: e.value, Responsable: e.label }));
            }}
          />
        </Grid2> */}
        <Grid2 container xs={12} mt={4}>
          <Grid2 item xs={12} md={7} mt={2}>
            <InputSelect
              label="Municipio"
              options={catalogsFiltered.municipios_reportes}
              name="DF"
              value={formik.values.Municipio}
              onChange={handleChangeMunicipality}
              sx={{ width: "100%" }}
              isLoading={load}
              disabled={isEmptyOrNullObject(params)}
            />
          </Grid2>
        </Grid2>
        <Grid2 container xs={12}>
          <Grid2 item xs={12} md={7} mt={2}>
            <AdvancedSelect
              name={"Seccion"}
              label={"Sección"}
              options={catalogsFiltered?.secciones}
              value={formik.values.Seccion}
              onChange={handleChangeSection}
              isLoading={load}
              multiple={true}
              disabled={isEmptyOrNullObject(params)}
              error={Boolean(formik.errors.Seccion)}
              helperText={formik.errors.Seccion}
              disableCloseOnSelect={true}
              sx={{ width: "100%" }}
              isSearchable
            />
          </Grid2>
        </Grid2>
        <Box mt={2} sx={{ display: "flex", justifyContent: "flex-end", gap: "10px" }}>
          <Button variant="outlined" color="primaryDark" size="small" onClick={clean} sx={{ width: "10vh" }}>
            Limpiar
          </Button>
          <Button
            variant="contained"
            color="primaryDark"
            size="small"
            startIcon={<Icon>save</Icon>}
            onClick={formik.handleSubmit}
            sx={{ width: "10vh" }}
            disabled={isEmptyOrNullObject(params)}
          >
            guardar
          </Button>
        </Box>
      </CardContent>
    </Card>
  );
};

export default AssignCertification;
