import { PrivilegedRoute } from "@middlewares";

import Volunteers from "@pages/Volunteers/Volunteers";

const VolunteersRoutes = [
    {
        path: "/voluntarios",
        element: <PrivilegedRoute><Volunteers /></PrivilegedRoute>
    }
];

export default VolunteersRoutes;