import { useEffect, useState } from "react";
import { useSorted } from "@hooks/useSorted";

import {
  Card,
  CardContent,
  Container,
  Stack,
  Typography,
  Button,
  Icon,
  Box,
} from "@mui/material";

import AdminLayout from "@components/MainPage/AdminLayout";
import ActionHeader from "@components/Containers/ActionHeader";
import CircularProgressPorcent from "@components/LinearProgress/CircularProgressPorcent";
import LoadingForm from "@components/LinearProgress/LoadingForm";
import BasicTable from "@components/Tables/BasicTable";
import FilterHistory from "@components/Rcos/FilterHistory";
import Download from "@components/Downloads/Download";

import rco from "@services/RcosServices";
import Swal from "sweetalert2";
import { COLUMNS_MUNICIPIO_POLIGONO, PROGRESS_KEYS } from "@data/constants/Rco";
import middleware from "@middlewares/middleware";

const MunicipioPolygonal = () => {
  const [loading, setLoading] = useState({
    isLoadigForm: false,
    isSuccessForm: false,
    openLinearProgress: false,
  });
  const [data, setData] = useState({
    page: 0,
    pageSize: 25,
    filtered: [],
    sorted: [],
  });
  const [total, setTotal] = useState(10);
  const [dataHistoryPolygonal, setDataHistoryPolygonal] = useState([]);
  const [isDownload, setIsDownload] = useState(false);
  const { handleRequestSort } = useSorted({
    total: total,
    setData,
  });

  const getInfoRcosHistoryPolygonal = async (body) => {
    try {
      setLoading({ ...loading, isLoadigForm: true, openLinearProgress: true });
      const result = await rco.getInfoRcosPoligonalHistorial(body);
      const {
        results,
        response: { data, total },
        message,
      } = result;

      if (!results) {
        throw new Error(message);
      }

      setTotal(total);
      parseToData({ data });
    } catch (error) {
      Swal.fire({
        title: error.message,
        icon: "warning",
      });
    } finally {
      setLoading({
        ...loading,
        isLoadigForm: false,
        openLinearProgress: false,
      });
    }
  };

  const exportToExcel = async () => {
    try {
      setIsDownload(true);
      const result = await rco.export({
        api: "rcos/export-info-rcos-poligonal-hist",
        params: { sorted: data.sorted, filtered: data.filtered },
      });
      const { success } = result;
      if (success) {
        Swal.fire({
          title: "Descargado con éxito",
          icon: "success",
        });
      } else {
        throw new Error(
          "Ocurrió un error en la descarga, consulte con el administrador"
        );
      }
    } catch (error) {
      Swal.fire({
        title: error.message,
        icon: "warning",
      });
    } finally {
      setIsDownload(false);
    }
  };

  const parseToData = ({ data }) => {
    const newData = data.map((item) => {
      const updatedItem = { ...item };

      PROGRESS_KEYS.forEach((key) => {
        updatedItem[key] = (
          <CircularProgressPorcent size={42} fontSize={10} value={item[key]} />
        );
      });

      return updatedItem;
    });

    setDataHistoryPolygonal(newData);
  };

  const onChangeFilter = ({ filtereds }) => {
    setData((prevState) => ({ ...prevState, filtered: filtereds }));
  };

  const onClearFilter = () => {
    setData({ ...data, filtered: [] });
  };

  const handleChangePagination = (pagination) => {
    setData({ ...data, ...pagination });
  };

  useEffect(() => {
    getInfoRcosHistoryPolygonal(data);
  }, [data]);

  return (
    <AdminLayout>
      <LoadingForm
        loadinMessage={"Cargando datos..."}
        isLoading={loading.isLoadigForm}
        success={loading.isSuccessForm}
        isOpen={loading.openLinearProgress}
        setIsOpen={() => setLoading({ ...loading, openLinearProgress: false })}
      />
      <Container maxWidth={false}>
        <ActionHeader title="RCO Municipio - Polígono" />
        <FilterHistory
          onChangeFilter={onChangeFilter}
          onClearFilter={onClearFilter}
        />
        <Card className="card-primary">
          <CardContent>
            <Stack
              direction={"row"}
              justifyContent={"space-between"}
              alignItems={"center"}
              marginBottom={2}
            >
              <Typography variant="body2" fontWeight={700}>
                Historial Poligonal
              </Typography>
              {middleware.checkMenuAction("Exportar") && (
                <Button
                  variant="outlined"
                  color="primaryDark"
                  startIcon={<Icon>download</Icon>}
                  size="small"
                  disabled={isDownload}
                  onClick={exportToExcel}
                >
                  Exportar
                </Button>
              )}
            </Stack>

            {isDownload && (
              <Box marginBottom={2}>
                <Download format={"xlsx"} isDownload={isDownload} />
              </Box>
            )}

            <BasicTable
              rows={dataHistoryPolygonal}
              hcolumns={COLUMNS_MUNICIPIO_POLIGONO}
              total={total}
              pageProp={data.page}
              pageSize={data.pageSize}
              minHeight="0px"
              stickyHeader={true}
              isLoading={false}
              handleChangePagination={handleChangePagination}
              handleManualSort={handleRequestSort}
            />
          </CardContent>
        </Card>
      </Container>
    </AdminLayout>
  );
};

export default MunicipioPolygonal;
