import React, { useState } from "react";
import {
  Modal,
  Card,
  CardContent,
  CardHeader,
  IconButton,
  CardMedia,
  CardActions,
  Button
} from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import InstruccionesIne from "@assets/img/InstruccionesIne.png";

const ModalIne = (props) => {
  const { openModal, setOpenModal } = props;
  const [notOpen,setNotOpen] = useState(false);

  return (
    <Modal open={notOpen ? false : openModal} onClose={(e) => setOpenModal(false)}>
      <Card
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          width: "80%",
          maxHeight: "90%",
          overflowY: "auto",
          transform: "translate(-50%, -50%)",
          boxShadow: 24,
          p: 2,
        }}
      >
        <CardHeader
          title={"Instrucciones"}
          action={
            <IconButton aria-label="cancel" onClick={() => setOpenModal(false)}>
              <CancelIcon />
            </IconButton>
          }
        />
        <CardContent>
          <ul>
            <li>No tomar la foto de la credencial en la mano.</li>
            <li>Apoyarla en una superficie plana, lisa y con contraste.</li>
            <li>La foto no debe tener brillo ni sombra.</li>
            <li>La foto no debe resultar barrida ni borrosa.</li>
            <li>Tomar la foto en orientación vertical, de cerca y que se vean los bordes.</li>
          </ul>
          <CardMedia
            component="img"
            image={InstruccionesIne}
            alt="Detras Ine"
          />
        </CardContent>
        <CardActions sx={{alignContent:"center", justifyContent:"center"}}>
          <Button variant="contained" color="warning" onClick={()=>setNotOpen(true)} >No volver a recordar</Button>
          <Button onClick={()=>setOpenModal(false)} variant="contained" color="primary">Cerrar</Button>
        </CardActions>
      </Card>
    </Modal>
  );
};

export default ModalIne;
