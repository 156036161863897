import React from "react";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import { Box } from "@mui/material";
import PropTypes from "prop-types";

const BasicDraggable = props => {
  const { items, setItems, rowComponent } = props;

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const onDragEnd = result => {
    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }

    const rows = reorder(items, result.source.index, result.destination.index);

    setItems(rows);
  };

  return (
    <DragDropContext onDragEnd={onDragEnd} sx={{ overflow: 'auto' }}>
      <Droppable droppableId="list">
        {provided => (
          <Box
            sx={{ width: "100%", overflow: "auto", ml: 0, mr: 0 }}
            ref={provided.innerRef}
            {...provided.droppableProps}
          >
            {rowComponent}
            {provided.placeholder}
          </Box>
        )}
      </Droppable>
    </DragDropContext>
  );
};

BasicDraggable.propTypes = {
  items: PropTypes.array.isRequired,
  setItems: PropTypes.func.isRequired,
  rowComponent: PropTypes.any.isRequired
};

export default BasicDraggable;
