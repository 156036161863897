import { useState, useEffect } from "react";
import Swal from "sweetalert2";

// Material UI
import { Grid, Button, TextField, InputAdornment, IconButton, Icon, Tooltip } from "@mui/material";
import { CleaningServicesOutlined } from "@mui/icons-material";

// Componentes
import BasicTable from "@components/Tables/BasicTable";

// Servicios
import RouteService from "@services/MapServices/RouteServices";

// Utils
import { getVars } from "@utils/global";

const Search = ({ handleEdit }) => {
  const columns = [
    { id: "Ruta", label: "Ruta", numeric: true },
    { id: "Casillas", label: "Casillas", numeric: true },
    { id: "Lnom", label: "LNOM", numeric: true },
  ];
  const [polygons, setPolygons] = useState([]);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [total, setTotal] = useState(0);
  const [data, setData] = useState({
    page: 0,
    pageSize: 5,
    filtered: [],
    sorted: [],
  });

  useEffect(() => {
    getPolygons();
    // eslint-disable-next-line
  }, [data]);

  const getPolygons = async () => {
    setLoading(true);

    const filterRoutes = getVars("filterRoutes");

    const params = {
      ...data,
      filtered: [
        ...data.filtered,
        { id: "cat_secciones.idMunicipioReportes", filter: "=", value: filterRoutes.idMunicipioReportes },
        // { id: "cat_secciones.idDF", filter: "=", value: filterRoutes.idDF },
      ],
    };

    try {
      const result = await RouteService.getRoutes(params);
      const { results, response, message } = result;

      if (results) {
        const polygonsData = response.data.map((item) => {
          const secciones = item.casillas.map((val, index) => {
            const casilla = val.NombreCasilla.split(" ")
              .map((word) => word[0])
              .join("");
            let texto = val.Seccion + casilla;
            if (index + 1 < item.casillas.length) texto = texto + ", ";
            return texto.toUpperCase();
          });

          return {
            Ruta: (
              <Button variant="text" color="primaryDark" size="small" style={{ fontSize: "9px" }} onClick={() => handleEdit(item)}>
                {item.Ruta}
                {item.Descripcion ? (
                  <>
                    <br />({item.Descripcion} )
                  </>
                ) : (
                  <></>
                )}
                <br />
                {item.Municipio} - {"DF:" + item.idDF}
              </Button>
            ),
            Casillas: (
              <div style={{ fontSize: "9px" }}>
                <b>TS:</b>[{item.TotalSecciones}]<b>TC:</b>[{item.TotalCasillas}]
                <br />
                {secciones}
              </div>
            ),
            Lnom: (
              <div style={{ fontSize: "9px" }}>
                <b>LNOM:</b> {item.Lnom}
                <br />
                <b>LNOMM:</b> {item.LnomM}
                <br />
                <b>LNOMH:</b> {item.LnomH}
              </div>
            ),
          };
        });

        setPolygons(polygonsData);
        setTotal(response.total);
      } else {
        Swal.fire({ title: message, icon: "warning" });
        setPolygons([]);
        setTotal(0);
      }
    } catch (error) {
      Swal.fire({ title: error.message, icon: "warning" });
    } finally {
      setLoading(false);
    }
  };

  const handleChangePagination = (pagination) => setData({ ...data, ...pagination });

  const handleChange = (e) => {
    setSearch(e.target.value);
    if (e.target.value.length === 0 && data.filtered.length !== 0) setData({ ...data, filtered: [] });
  };

  const handleSearch = (e) => {
    const value = e.target.value;
    if (e.key === "Enter" && value.length !== 0) {
      const _isNaN = !isNaN(e.target.value);
      if (_isNaN) {
        setData({
          ...data,
          filtered: [
            {
              id: "cat_secciones.Seccion",
              filter: "=",
              value: parseInt(value),
            },
          ],
        });
      } else {
        setData({
          ...data,
          filtered: [
            {
              id: "concat(rutas.Ruta, rutas.Descripcion)",
              filter: "LIKE",
              value: value,
            },
          ],
        });
      }
    }
  };

  const handleClean = () => {
    setSearch("");
    setData({ ...data, filtered: [] });
  };

  return (
    <>
      <Grid container rowSpacing={1} style={{ width: "100%" }}>
        <Grid item xs={12}>
          <TextField
            sx={{ width: "100%" }}
            label="Buscar"
            type="text"
            name="Buscar"
            placeholder="Buscar por sección, ruta o descripción."
            onChange={handleChange}
            onKeyDown={handleSearch}
            value={search.toUpperCase()}
            variant="outlined"
            size="small"
            className="fixed-input"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Tooltip title="Buscar">
                    <IconButton
                      color="secondary"
                      aria-label="upload picture"
                      component="label"
                      sx={{ m: -1 }}
                      onClick={() => handleSearch({ key: "Enter", target: { value: search } })}
                    >
                      <Icon>search</Icon>
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Limpiar">
                    <IconButton color="primary" aria-label="upload picture" component="label" onClick={() => handleClean()}>
                      <CleaningServicesOutlined />
                    </IconButton>
                  </Tooltip>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <BasicTable
            sx={{ fontSize: "8px" }}
            rows={polygons}
            hcolumns={columns}
            pageProp={data.page}
            pageSize={data.pageSize}
            total={total}
            handleChangePagination={handleChangePagination}
            isLoading={loading}
            stickyHeader={true}
            cardType={false}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default Search;
