import { PrivilegedRoute } from "@middlewares";

import DashboardCertificacion from "@pages/Certificacion/DashboardCertification";
import Certificaciones from "@pages/Certificacion/Certificaciones";
import AssignCertification from "@pages/Certificacion/AssignCertification";

const ActivationRoute = [
  {
    path: "/dashboard-certificacion",
    element: (
      <PrivilegedRoute>
        <DashboardCertificacion />
      </PrivilegedRoute>
    ),
  },
  {
    path: "/asignar-certificador",
    element: (
      <PrivilegedRoute>
        <AssignCertification />
      </PrivilegedRoute>
    ),
  },
  {
    path: "/certificadores",
    element: (
      <PrivilegedRoute>
        <Certificaciones />
      </PrivilegedRoute>
    ),
  },
];

export default ActivationRoute;
