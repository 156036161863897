import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  TableContainer,
  Typography,
  LinearProgress,
  TableSortLabel,
} from "@mui/material";
import { red, green, yellow } from "@mui/material/colors";
import { numberWithCommas } from "@utils/Utilities/";
import sije from "@services/SijeService";
import Swal from "sweetalert2";

const NacionalTable = (param) => {
  const { casillaName } = param;
  const timelineContainerRef = useRef(null);
  const [loadingNacionalStates, setLoadingNacionalStates] = useState(false);
  const [data, setData] = useState([]);
  const [orderBy, setOrderBy] = useState("");
  const [order, setOrder] = useState("asc");
  const [total, setTotal] = useState(0);
  const [params, setParams] = useState({
    page: 0,
    pageSize: 15,
    filtered: [],
    sorted: [],
  });
  const pd = 1;
  const fW = 700;
  const borderRight = { borderRight: 1, borderRightColor: "#EB8AC3" };

  const getNacionalStatsEntities = async () => {
    try {
      setLoadingNacionalStates(true);
      const result = await sije.getNacionalStats(params);
      const { results, message, response } = result;
      if (results) {
        setTotal(response.total);
        const newData = response.data.map((item) => ({
          ...item,
          casillasAutorizadas: item.CasillasAutorizadas,
        }));
        setData(newData);
      } else {
        throw new Error(message);
      }
    } catch (error) {
      Swal.fire({
        title: error.message || "Error desconocido",
        icon: "warning",
      });
    } finally {
      setLoadingNacionalStates(false);
    }
  };

  const handleSort = (columnId) => {
    const isAsc = orderBy === columnId && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(columnId);

    setParams((prevParams) => ({
      page: 0,
      pageSize: 15,
      filtered:[],
      sorted: [{ id: columnId, value: isAsc ? "desc" : "asc" }],
    }));
  };

  const handleScroll = async () => {
    const container = timelineContainerRef.current;
    if (container && container.scrollTop + container.clientHeight >= container.scrollHeight - 20 && !loadingNacionalStates && data.length < total) {
      setParams((prevParams) => ({ ...prevParams, page: params.page + 1 }));
    }
  };

  const getProgressBarColor = (percentage) => {
    const colorMap = {
      30: red[700],
      70: yellow[700],
    };
    return colorMap[percentage] || green[700];
  };

  useEffect(() => {
    getNacionalStatsEntities();
  }, [params.sorted]);

  useEffect(() => {
    const moreData = async () => {
      if (params.page !== 0) {
        try {
          setLoadingNacionalStates(true);
          const res = await sije.getNacionalStats(params);
          if (res.results) {
            const newData = res.response.data.map((item) => ({
              ...item,
              casillasAutorizadas: item.CasillasAutorizadas,
            }));
            setData((prevData) => [...prevData, ...newData]);
          } else {
            throw new Error(res.message);
          }
        } catch (error) {
          Swal.fire({
            title: error.message,
            icon: "warning",
          });
        } finally {
          setLoadingNacionalStates(false);
        }
      }
    };
    moreData()
  }, [params.page]);

  useEffect(() => {
    const container = timelineContainerRef.current;
    container.addEventListener("scroll", handleScroll);

    return () => {
      container.removeEventListener("scroll", handleScroll);
    };
  }, [loadingNacionalStates, data.length, total, params.page]);

  return (
    <Box sx={{ mx: "auto", textAlign: "center" }}>
      <TableContainer ref={timelineContainerRef} component={Paper} sx={{ height: "73vh", overflowY: "auto" }} onScroll={handleScroll}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell sx={{ padding: pd, fontWeight: fW }}>
                <TableSortLabel
                  active={orderBy === "Entidad"}
                  direction={orderBy === "Entidad" ? order : "asc"}
                  onClick={() => handleSort("Entidad")}
                >
                  Entidades
                </TableSortLabel>
              </TableCell>
              <TableCell align="center" sx={{ padding: pd, fontWeight: fW }}>
                <TableSortLabel
                  active={orderBy === "CasillasAutorizadas"}
                  direction={orderBy === "CasillasAutorizadas" ? order : "asc"}
                  onClick={() => handleSort("CasillasAutorizadas")}
                >
                  Casillas Autorizadas
                </TableSortLabel>
              </TableCell>
              <TableCell align="center" colSpan={2} sx={{ padding: pd, fontWeight: fW }}>
                <TableSortLabel active={orderBy === "Avance"} direction={orderBy === "Avance" ? order : "asc"} onClick={() => handleSort("Avance")}>
                  {casillaName}
                </TableSortLabel>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loadingNacionalStates && data.length === 0 && (
              <TableRow sx={{ my: 2 }}>
                <TableCell colSpan={4} align="center">
                  <LinearProgress color="secondary" />
                </TableCell>
              </TableRow>
            )}
            {data.map((row, index) => {
              let progressBarColor = getProgressBarColor(row.PorcentajeAvance);
              return (
                <TableRow key={row.id} sx={{ backgroundColor: index % 2 ? "white" : "#E4ECFA" }}>
                  <TableCell sx={{ padding: pd, width: "auto", fontSize: 15, fontWeight: fW, ...borderRight }}>
                    <Box display="flex" alignItems="center" gap={0.5}>
                      <Box sx={{ width: 10, height: 27, marginRight: 0.5 }} />
                      <Box sx={{ width: 27, height: "auto" }}>{row.Entidad}</Box>
                    </Box>
                  </TableCell>
                  <TableCell align="right" sx={{ padding: pd, fontWeight: fW, width: "10px" }}>
                    {numberWithCommas(row.casillasAutorizadas)}
                  </TableCell>
                  <TableCell align="right" sx={{ padding: pd, fontWeight: fW }}>
                    {numberWithCommas(row.Avance)}
                  </TableCell>
                  <TableCell align="center" sx={{ padding: pd, fontWeight: fW }}>
                    <Box>
                      <Typography variant="bold" align="center" fontSize={12}>
                        {row.PorcentajeAvance}%
                      </Typography>
                      <LinearProgress
                        variant="determinate"
                        value={row.PorcentajeAvance}
                        sx={{
                          width: "100%",
                          height: "8px",
                          borderRadius: "5px",
                          border: 1,
                          borderColor: "lightgrey",
                          marginTop: 0,
                          backgroundColor: "transparent",
                          "& .MuiLinearProgress-bar": { backgroundColor: progressBarColor },
                        }}
                      />
                    </Box>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
        {loadingNacionalStates && data.length > 0 && (
          <Box mt={2} mb={2}>
            <LinearProgress color="secondary" />
          </Box>
        )}
      </TableContainer>

      <Box sx={{ mt: 2 }}>
        <Typography variant="body2" sx={{ color: "#888888" }}>
          Mostrando {Math.min(data.length, total)} de {total}
        </Typography>
      </Box>
    </Box>
  );
};

export default NacionalTable;
