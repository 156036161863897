import React, { useEffect, useState } from "react";

import {
  Box,
  Chip,
  Icon
} from "@mui/material";
import Grid2 from '@mui/material/Unstable_Grid2';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';

const SocialForm = props => {
  const {
    catalogs,
    loadigCatalogs,
    values,
    setValues,
  } = props;

  const animatedComponents = makeAnimated();
  const [selected, setSelected] = useState([]);

  useEffect(() => {
    if (values.Segmentos && values.Segmentos.length < 1) {
      setSelected([]);
    }
    else {
      const array = values.Segmentos.map(item => parseInt(item));

      const items = catalogs.mis_segmentos.filter(item => array.includes(item.value))
      setSelected(items);
      //handleSelectedOptions(items);
    }
    // eslint-disable-next-line
  }, [values])

  const handleSelectedOptions = items => {
    setSelected(items);
    setValues({
      ...values,
      Segmentos: items.map(item => item.value)
    });
  }

  const selectStyles = {
    menuPortal: base => ({ ...base, zIndex: 9999 }),
    menu: provided => ({ ...provided, zIndex: "9999 !important" })
  };

  return (
    <Box sx={{ mt: 2, mb: 4 }}>
      <Grid2 item xs={12} sm={12} md={12} lg={12}>
        <Chip icon={<Icon>groups2</Icon>} label="Segmentos Sociales" />
      </Grid2>

      <Grid2 container spacing={3} sx={{ mt: 2 }}>
        <Grid2 item xs={12} sm={12} md={12} lg={12}>
          <Select
            menuPlacement="top"
            styles={selectStyles}
            closeMenuOnSelect={false}
            components={animatedComponents}
            value={selected}
            onChange={handleSelectedOptions}
            isMulti
            options={catalogs.mis_segmentos}
            isLoading={loadigCatalogs}
          />
        </Grid2>
      </Grid2>
    </Box>
  )
}

export default SocialForm;