import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

//MUI
import { Container, Button, Icon, Stack, Typography, Card, CardContent } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import Swal from "sweetalert2";

//UTILS && MIDDLEWARE
import middleware from "@middlewares/middleware";
import { numberWithCommas } from "@utils/Utilities";

//COMPONENTS
import AdminLayout from "@components/MainPage/AdminLayout";
import ActionHeader from "@components/Containers/ActionHeader";
import LoadingForm from "@components/LinearProgress/LoadingForm";
import Filter from "@components/Movilizadores/DashBoard/Filter";
import AccumulatedCertifiedPeople from "@components/Movilizadores/DashBoard/AccumulatedCertifiedPeople";
import ProgressChart from "@components/Movilizadores/DashBoard/ProgressChart";
import TableAgrupado from "@components/Movilizadores/DashBoard/Table";
import TableDetalle from "@components/Movilizadores/DashBoard/TableDetalle";

//SERVICES
import mov from "@services/MovilizationServices";
import movilizadores from "@services/MovilizadoresServices";

const Dashboard = () => {
  const navigate = useNavigate();
  const redirect = () => navigate("/carga-movilizadores");

  const [catView, setCatView] = useState([]);
  const [ambitoUser, setAmbitoUser] = useState("");
  const [refresh, setRefresh] = useState(false);
  const [dataTable, setDataTable] = useState([]);
  const [summary, setSummary] = useState([]);
  const [dataTableDetalle, setTableDetalle] = useState([]);
  const [velocimetroData, setVelocimetroData] = useState({ Avance: 0, Meta: 0 });
  const [orderBy, setOrderBy] = useState("")

  const [openDetalle, setOpenDetalle] = useState(false);
  const [loadingTable, setLoadingTable] = useState(false);
  const [loadingDetalle, setLoadingDetalle] = useState(false);
  const [loading, setLoading] = useState({
    isLoadigForm: false,
    isSuccessForm: false,
    openLinearProgress: false,
  });
  const [data, setData] = useState({
    filtered: [],
    sorted: [],
    idAmbitoAgrupador: ambitoUser,
  });

  const canImport = middleware.checkMenuAction("Importar");
  const [page, setPage] = useState(0);
  const openDrawer = useState(true);

  const handleSearchFilter = ({ filtereds, view }) => {
    setOrderBy("")
    setData((prev) => ({
      ...prev,
      sorted:[],
      filtered: filtereds,
      idAmbitoAgrupador: view,
    }));
    handlePage();
  };

  const handleClearFilter = () => {
    setOrderBy("")
    if (data.filtered.length > 0 || data.idAmbitoAgrupador !== ambitoUser) {
      setData((prev) => ({
        ...prev,
        filtered: [],
        idAmbitoAgrupador: ambitoUser,
      }));
      handleRefresh();
    }
  };

  const resetParams = () => {
    setDataTable([]);
    setSummary([]);
    setOpenDetalle(false);
    setVelocimetroData({ Avance: 0, Meta: 0 });
  };

  const getCatView = async () => {
    try {
      const res = await mov.getAmbitoAgrupadorMovilizacion();
      const { results, response, message } = res;

      if (results) {
        let data = response?.data ?? [];
        data.sort((a, b) => a.value - b.value);

        setCatView(data);
        setAmbitoUser(data[0]?.value);
      } else {
        throw new Error(message);
      }
    } catch (error) {
      Swal.fire({ title: error.message, icon: "warning" });
    }
  };

  const ApiResponseTable = async () => {
    try {
      setLoadingTable(true);
      setLoading({
        isLoadigForm: true,
        isSuccessForm: false,
        openLinearProgress: true,
      });
      resetParams();
      const result = await movilizadores.getMovilizadores(data);
      const { results, message, response } = result;
      if (results) {
        let data = response.data.map((item) => {
          return {
            ...item,
            Promovidos: numberWithCommas(item.Promovidos),
            Movilizadores: numberWithCommas(item.Movilizadores),
            Pendientes: numberWithCommas(item.Pendientes),
            Movilizados: numberWithCommas(item.Movilizados),
            NoLocalizados: numberWithCommas(item.NoLocalizados),
          };
        });
        let TotalsForVel = response.totales;
        const summaryValues = Object.values(response.totales);
        setSummary([...summaryValues]);
        setDataTable(data);
        setVelocimetroData({ Avance: TotalsForVel.Movilizados, Meta: TotalsForVel.Promovidos });
      } else {
        resetParams();
        throw new Error(message);
      }
    } catch (error) {
      Swal.fire({ title: error.message, icon: "warning" });
    } finally {
      setLoadingTable(false);
      setLoading({
        isLoadigForm: false,
        isSuccessForm: false,
        openLinearProgress: false,
      });
    }
  };

  const tableDetalle = async (value) => {
    try {
      setLoadingDetalle(true);
      setLoading({
        isLoadigForm: true,
        isSuccessForm: false,
        openLinearProgress: true,
      });
      const result = await movilizadores.getMovilizadoresDetalle({
        idAmbitoAgrupador: data.idAmbitoAgrupador,
        value: value,
      });
      const { results, message, response } = result;
      if (results) {
        setTableDetalle(response.data);
        setOpenDetalle((prev) => !prev);
      } else {
        setTableDetalle([]);
        setOpenDetalle(false);
        throw new Error(message);
      }
    } catch (error) {
      Swal.fire({
        title: error.message,
        icon: "warning",
      });
    } finally {
      setLoadingDetalle(false);
      setLoading({
        isLoadigForm: false,
        isSuccessForm: false,
        openLinearProgress: false,
      });
    }
  };

  useEffect(() => {
    getCatView();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (data.idAmbitoAgrupador) {
      ApiResponseTable();
    }
    //eslint-disable-next-line
  }, [data.idAmbitoAgrupador, data.filtered, data.sorted]);

  useEffect(() => {
    setData({
      ...data,
      idAmbitoAgrupador: ambitoUser,
    });
    //eslint-disable-next-line
  }, [ambitoUser]);

  const handleRefresh = () => {
    handlePage();
    setOrderBy("")
    setRefresh(true);
    setTimeout(() => {
      setRefresh(false);
    }, 1000);
  };

  const handlePage = () => {
    setPage(1);
    setTimeout(() => {
      setPage(0);
    }, 0);
  };

  return (
    <AdminLayout>
      <Container maxWidth={false}>
        <LoadingForm
          loadinMessage={"Cargando datos..."}
          isLoading={loading.isLoadigForm}
          success={loading.isSuccessForm}
          isOpen={loading.openLinearProgress}
          setIsOpen={() => setLoading({ ...loading, openLinearProgress: false })}
        />
        <ActionHeader title="Resumen de Movilizadores" isCustom>
          {canImport && (
            <Button variant="outlined" size="small" startIcon={<Icon>backup</Icon>} color="primaryDark" onClick={redirect}>
              Cargar información
            </Button>
          )}
        </ActionHeader>

        <Grid2 container spacing={2}>
          <Grid2 xs={12} sm={6} md={6} lg={4}>
            <AccumulatedCertifiedPeople data={velocimetroData} loading={loadingTable} handleRefresh={handleRefresh} refresh={refresh} />
          </Grid2>

          <Grid2 xs={12} sm={6} md={6} lg={8}>
            <ProgressChart openDrawer={openDrawer} />
          </Grid2>
          <Grid2 xs={12}>
            <Filter
              onSearch={handleSearchFilter}
              onClear={handleClearFilter}
              changeTypeView={() => {}}
              refresh={refresh}
              setLoading={setLoading}
              catView={catView}
              setIdFilter={() => {}}
              ambitoUser={ambitoUser}
            />
          </Grid2>

          <Grid2 xs={12}>
            <Card className="card-primary" sx={{ mb: "0 !important" }}>
              <CardContent>
                {openDetalle ? (
                  <Stack spacing={1}>
                    <Stack direction={"row"} justifyContent={"flex-end"}>
                      <Button
                        variant="contained"
                        color="primaryDark"
                        size="small"
                        sx={{ width: "10vh" }}
                        onClick={() => setOpenDetalle((prev) => !prev)}
                      >
                        REGRESAR
                      </Button>
                    </Stack>
                    <TableDetalle
                      dataTableDetalle={dataTableDetalle}
                      loadingDetalle={loadingDetalle}
                      page={(data.idAmbitoAgrupador || data.filtered) && loadingDetalle}
                    />
                  </Stack>
                ) : (
                  <TableAgrupado
                    dataTable={dataTable}
                    summary={summary}
                    loadingTable={loadingTable}
                    tableDetalle={tableDetalle}
                    page={page}
                    setPage={setPage}
                    setSorted={(e)=>{setData({...data, sorted:[e]}); setOrderBy(e.id)}}
                    orderBy={orderBy}
                  />
                )}
              </CardContent>
            </Card>
          </Grid2>
        </Grid2>
      </Container>
    </AdminLayout>
  );
};

export default Dashboard;
