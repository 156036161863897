import { useState, useEffect } from "react";

// Material UI
import { Box, Grid, Button, Stack } from "@mui/material";

// Componentes
import AdvancedSelect from "@components/Selects/AdvancedSelect";
import BasicSelect from "@components/Selects/BasicSelect";

import { getVars, setVars } from "@utils/global";

const Filter = ({ catalogs, loadingCatalogs, handleFilter }) => {
  const clearFilter = { idRegion: 0, idMunicipio: 0, idPoligono: 0, idSeccion: [] };

  const [catMunicipios, setCatMunicipios] = useState([]);
  const [catPoligonos, setCatPoligonos] = useState([]);
  const [catSecciones, setCatSecciones] = useState([]);
  const [filter, setFilter] = useState(clearFilter);

  useEffect(() => {
    const Re = catalogs?.regiones;

    if (Re?.length) {
      const Mu = catalogs?.municipios;
      const Po = catalogs?.poligonos;
      const Se = catalogs?.secciones;

      setCatMunicipios(Mu);
      setCatPoligonos(Po);

      const filterVars = getVars("filterCasaAmiga", "");

      if (filterVars !== "") {
        // Cargar del Storage
        const seccion = getVars("catalogsCasaAmiga", catSecciones);
        setFilter(filterVars);
        setCatSecciones(seccion);
      } else {
        setCatSecciones(Se);

        let filtered = clearFilter;

        if (Re?.length === 1) filtered.idRegion = Re[0].value;
        if (Mu?.length === 1) filtered.idMunicipio = Mu[0].value;
        if (Po?.length === 1) filtered.idPoligono = Po[0].value;
        if (Se?.length === 1) filtered.idSeccion = [Se[0].value];

        setFilter(filtered);
      }
    }
    // eslint-disable-next-line
  }, [catalogs]);

  const handleChangeGlobal = (localValue, name) => {
    const clear = { idMunicipio: 0, idPoligono: 0, idSeccion: [] };

    const validCat = (ids, zero, local = localValue) => local === ids || zero === 0;

    const invalid = (value) => value === 0;

    switch (name) {
      case "idRegion":
        const M_R = catalogs.municipios.filter((i) => validCat(i.idRegion, i.value));
        const P_R = catalogs.poligonos.filter((i) => validCat(i.idRegion, i.value));
        const S_R = catalogs.secciones.filter((i) => validCat(i.idRegion, i.value));
        setCatMunicipios(M_R.length === 1 ? catalogs.municipios : M_R);
        setCatPoligonos(P_R.length === 1 ? catalogs.poligonos : P_R);
        setCatSecciones(S_R.length === 1 ? catalogs.secciones : S_R);
        setFilter({ ...clear, [name]: localValue });
        break;

      case "idMunicipio":
        if (invalid(localValue) && invalid(filter.idRegion)) {
          setCatPoligonos(catalogs.poligonos);
          setCatSecciones(catalogs.secciones);
        } else if (!invalid(localValue)) {
          const P_M = catalogs.poligonos.filter((i) => validCat(i.idMunicipioReportes, i.value));
          const S_M = catalogs.secciones.filter((i) => validCat(i.idMunicipioReportes, i.value));
          setCatPoligonos(P_M.length === 1 ? catalogs.poligonos : P_M);
          setCatSecciones(S_M.length === 1 ? catalogs.secciones : S_M);
        } else {
          const P_R = catalogs.poligonos.filter((i) => validCat(i.idRegion, i.value, filter.idRegion));
          const S_R = catalogs.secciones.filter((i) => validCat(i.idRegion, i.value, filter.idRegion));
          setCatPoligonos(P_R.length === 1 ? catalogs.poligonos : P_R);
          setCatSecciones(S_R.length === 1 ? catalogs.secciones : S_R);
        }

        setFilter({ ...filter, ...clear, [name]: localValue });
        break;

      case "idPoligono":
        if (invalid(localValue) && invalid(filter.idRegion) && invalid(filter.idMunicipio)) {
          setCatSecciones(catalogs.secciones);
        } else if (!invalid(localValue)) {
          const S_P = catalogs.secciones.filter((i) => validCat(i.idPoligono, i.value));
          setCatSecciones(S_P.length === 1 ? catalogs.secciones : S_P);
        } else if (!invalid(filter.idMunicipio)) {
          const S_R = catalogs.secciones.filter((i) =>
            validCat(i.idMunicipioReportes, i.value, filter.idMunicipio)
          );
          setCatSecciones(S_R.length === 1 ? catalogs.secciones : S_R);
        } else {
          const S_M = catalogs.secciones.filter((i) => validCat(i.idRegion, i.value, filter.idRegion));
          setCatSecciones(S_M.length === 1 ? catalogs.secciones : S_M);
        }

        setFilter({ ...filter, [name]: localValue, idSeccion: [] });
        break;

      default: // idSeccion
        if (localValue.includes(0) && localValue.length > 1) {
          if (localValue.indexOf(0) > 0) localValue = [0];
          else localValue = localValue.filter((value) => value !== 0);
        }

        setFilter({ ...filter, [name]: localValue });
        break;
    }
  };

  const valid = (value, noValid, noValidArray) => {
    return value !== "" && value !== undefined && value.length !== noValid && !value.includes(noValidArray);
  };

  const handleClick = (params) => {
    const filter_data = [{ id: "m.Seccion", filter: "IN", value: params.idSeccion }];

    setVars("filterCasaAmiga", params);
    setVars("catalogsCasaAmiga", catSecciones);
    handleFilter(filter_data);
  };

  return (
    <Box sx={{ px: 2 }}>
      <Grid container spacing={2}>
        {/* <Grid item xs={12} sm={6} md={12}>
          <BasicSelect
            label="Región"
            name="idRegion"
            id="idRegion"
            options={catalogs?.regiones}
            value={filter.idRegion}
            onChange={(e) => handleChangeGlobal(e.target.value, e.target.name)}
            disabled={catalogs?.regiones?.length <= 1 || loadingCatalogs}
            isLoading={loadingCatalogs}
            sx={{ width: "100%" }}
          />
        </Grid> */}
        <Grid item xs={12} sm={6} md={12}>
          <BasicSelect
            label="Municipio"
            name="idMunicipio"
            id="idMunicipio"
            options={catMunicipios}
            value={filter.idMunicipio}
            onChange={(e) => handleChangeGlobal(e.target.value, e.target.name)}
            disabled={catMunicipios.length <= 1 || loadingCatalogs}
            isLoading={loadingCatalogs}
            sx={{ width: "100%" }}
          />
        </Grid>
        {/* <Grid item xs={12} sm={6} md={12}>
          <BasicSelect
            label="Poligono"
            name="idPoligono"
            id="idPoligono"
            options={catPoligonos}
            value={filter.idPoligono}
            onChange={(e) => handleChangeGlobal(e.target.value, e.target.name)}
            disabled={catPoligonos.length <= 1 || loadingCatalogs}
            isLoading={loadingCatalogs}
            sx={{ width: "100%" }}
          />
        </Grid> */}
        <Grid item xs={12} sm={6} md={12}>
          <AdvancedSelect
            label="Sección"
            name="idSeccion"
            id="idSeccion"
            options={catSecciones}
            value={filter.idSeccion}
            onChange={(e) => handleChangeGlobal(e, "idSeccion")}
            disabled={catSecciones.length <= 1 || loadingCatalogs}
            isLoading={loadingCatalogs}
            disableCloseOnSelect
            isSearchable
            multiple
            error={filter.idSeccion.length > 3}
            helperText={filter.idSeccion.length > 3 ? "Solo puedes ingresar 3 secciones" : ""}
          />
        </Grid>
      </Grid>

      <br />

      <Stack direction="row" justifyContent="center" alignItems="center" spacing={2}>
        <Button
          variant="outlined"
          disabled={!valid(filter.idSeccion, 0, 0) || filter.idSeccion.length > 3}
          onClick={() => handleClick(filter)}
        >
          Filtrar
        </Button>
      </Stack>
    </Box>
  );
};

export default Filter;
