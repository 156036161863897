const ActaEscrutinioInterface = {
  idTipoEleccion: "",
  Seccion: "",
  idCasilla: "",
};

const AddPartyInterface = {
  Partido: '',
  Siglas: '',
  Color: '#b71c1c',
  EsIndependiente: 0,
  EsCoalicion: 0
}

const AddCandidateInterface = {
  idTipoEleccion: '',
  idPartido: '',
  NombreCandidato: '',
  Color: '#b71c1c',
  AmbitoValor: ''
}

export { ActaEscrutinioInterface, AddPartyInterface, AddCandidateInterface };
