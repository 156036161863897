import React, { useState } from "react";
import {
  Button,
  Container,
  Icon,
  IconButton,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";

import Navbar from "../Navbar";
import WebCam from "../WebCam";
import Toast from "../Toast";

import moment from "moment/moment";
import "moment/locale/es";
import esLocale from "date-fns/locale/es";
import { getVars } from "@utils/global";
import { useMobileServices } from "@hooks/MobileDiaD/MobileServices";

const ScreenCierreCasilla = ({ setTypeScreen }) => {
  const {
    casilla_rc: {
      Casillas: { NombreCasilla, Seccion, idCasilla },
    },
    user: { ambito_perfil },
  } = getVars("Token");

  const { loading, snackbar, setSnackbar, setCierreCasilla } =
    useMobileServices();

  const [hour, setHour] = useState(moment());
  const [image, setImage] = useState(null);

  const handleChangeHour = (event) => {
    setHour(event.target.value);
  };

  const handleSetCierreCasilla = async () => {
    const params = {
      idUser: ambito_perfil[0].idUsuario,
      casillas: [
        {
          idCasilla: idCasilla,
          FechaHora: moment(hour).format("HH:mm"),
        },
      ],
    };

    if (image) params.casillas[0].files = [{ file: image }];

    const results = await setCierreCasilla(params);

    if (results) {
      setHour(moment());
      setImage(null);
    }
  };

  return (
    <>
      <Toast
        open={snackbar.show}
        message={snackbar.message}
        setOpen={setSnackbar}
      />
      <Navbar
        isScreen
        screenBack={"rcs"}
        title={"Cierre de Casilla"}
        setTypeScreen={setTypeScreen}
        color="black"
      />
      <Container sx={{ mt: 4 }}>
        <Typography textAlign={"center"} fontWeight={600} marginBottom={2}>
          ¿Ya cerró la casilla?
        </Typography>
        <Stack direction={"column"} spacing={1}>
          <Typography variant="body2">Sección</Typography>
          <Typography variant="subtitle1" fontWeight={600}>
            {Seccion}
          </Typography>
          <Typography variant="body2">Casilla</Typography>
          <Typography variant="subtitle1" fontWeight={600}>
            {NombreCasilla}
          </Typography>

          <Typography variant="body2">
            Hora del cierre de casilla formato 24hrs (Ejemplo: 07:30)
          </Typography>
          <Stack direction={"row"} spacing={1}>
            <LocalizationProvider
              dateAdapter={AdapterDateFns}
              adapterLocale={esLocale}
              localeText={{
                timePicker24Hour: "24 horas",
                okButtonLabel: "Aceptar",
                cancelButtonLabel: "Cancelar",
              }}
            >
              <TimePicker
                label={"Hora cierre casilla"}
                value={hour}
                onChange={(newValue) => setHour(newValue)}
                renderInput={(params) => <TextField fullWidth {...params} />}
              />
            </LocalizationProvider>
            <IconButton onClick={() => setHour("")}>
              <Icon>delete</Icon>
            </IconButton>
          </Stack>
          <WebCam image={image} setImage={setImage} />
          <Button
            variant="contained"
            onClick={handleSetCierreCasilla}
            disabled={loading}
          >
            {loading ? "Guardando..." : "Cerró la casilla"}
          </Button>
        </Stack>
      </Container>
    </>
  );
};

export default ScreenCierreCasilla;
