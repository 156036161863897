import ApiExec from "@utils/ApiExec";

/**
 * Clase para invocar las apis del módulo menus
 */

class MenuService {
  /**
   * @property {Function} getAllMenus - Función para devolver todos los menús disponibles ya sean de tipo web o móvil
   * @param {Object} params - Parámetros para el llamado de la api
   * @returns {Object}
   */
  getAllMenus = (params) => {
    /**
     * @typedef {Object} params
     * @property {Array<any>} filtered - Arreglo con los valores a filtrar [opcional]
     * @property {Array<any>} sorted - Arreglo con los valores a ordenar [opcional]
     */
    return ApiExec(params, "menus/get-all", "POST")
      .then(function (res) {
        return res;
      })
      .then((res) => {
        /**
         * @typedef {Object} res
         * @property {boolean} success - Indica si la ejecución en el servidor tuvo éxito o no
         * @property {boolean} results - Ejecución con éxito en el servidor, que indica si hubo o no resultados, o estatus de validación
         * @property {number} status - Código de respuesta http
         * @property {string} message - Mensaje para el usuario
         * @property {web: Array<any>, app: Array<any>} response - Objeto con los atributos de respuesta. Cada uno con el listado de menús correspondientes
         */
        return res;
      })
      .catch((e) => {
        return e;
      });
  };

  /**
   * @property {Function} getMenus - Función para devolver los menús de un tipo; web o móvil
   * @param {Object} params - Parámetros para el llamado de la api
   */
  getMenus = (params) => {
    /**
     * @typedef {Object} params
     * @property {number} Movil - bandera para indicar si es menú móvil o web, posibles valores 0,1
     * @property {Array<any>} filtered - Arreglo con los valores a filtrar [opcional]
     * @property {Array<any>} sorted - Arreglo con los valores a ordenar [opcional]
     */
    return ApiExec(params, "menus/get", "POST")
      .then(function (res) {
        return res;
      })
      .then((res) => {
        /**
         * @typedef {Object} res
         * @property {boolean} success - Indica si la ejecución en el servidor tuvo éxito o no
         * @property {boolean} results - Ejecución con éxito en el servidor, que indica si hubo o no resultados, o estatus de validación
         * @property {number} status - Código de respuesta http
         * @property {string} message - Mensaje para el usuario
         * @property {data: Array<any>} response - Listado de menús correspondientes
         */
        return res;
      })
      .catch((e) => {
        return e;
      });
  };

  /**
   * @property {Function} setMenu - Función para registar un nuevo menú
   * @param {Object} params - Parámetros para el llamado de la api
   * @returns {Object}
   */
  setMenu = (params) => {
    /**
     * @typedef {Object} params
     * @property {string} Menu - Nombre del menú [obligatorio],
     * @property {string} Ruta - Ruta, path, url del menú [obligatorio],
     * @property {number} Ver - Bit de Acceso 0,1 [obligatorio],
     * @property {number} Agregar - Bit de Acceso 0,1 [obligatorio],
     * @property {number} Editar - Bit de Acceso 0,1 [obligatorio],
     * @property {number} Eliminar - Bit de Acceso 0,1 [obligatorio],
     * @property {number} Exportar - Bit de Acceso 0,1 [obligatorio],
     * @property {number} Visible - Bit de Acceso 0,1 [obligatorio],
     * @property {string} Clave - Clave interna [opcional],
     * @property {string} Descripcion - Descripción del menú [opcional],
     * @property {number} Movil - Bit para indicar tipo 0 = Web, 1 = Móvil [obligatorio],
     * @property {number} idPadre - id del menú del cual depende [opcional],
     * @property {string} Icono - Nombre del ícono [opcional],
     * @property {string} TipoIcono - Nombre de la librería de íconos utilizada [opcional],
     * @property {string} Siglas - Siglas para sustituir en caso de no contar con un ícono [obligatorio],
     * @property {string} Plantilla - Plantilla utilizada en el proyecto front [opcional],
     * @property {number} idEstatus - id del Estatus del menú
     * @property {number} Orden - Orden del menú [opcional]
     * @property {number} Desplegable - Bit para indicar si el menú tendrá submenús [opcional]
     */
    return ApiExec(params, "menus/create", "POST")
      .then(function (res) {
        return res;
      })
      .then((res) => {
        /**
         * @typedef {Object} res
         * @property {boolean} success - Indica si la ejecución en el servidor tuvo éxito o no
         * @property {boolean} results - Ejecución con éxito en el servidor, que indica si hubo o no resultados, o estatus de validación
         * @property {number} status - Código de respuesta http
         * @property {string} message - Mensaje para el usuario
         * @property {Object} response - Datos del menú registrado
         */
        return res;
      })
      .catch((e) => {
        return e;
      });
  };

  /**
   * @property {Function} updateMenu - Función para registar un nuevo menú
   * @param {Object} params - Parámetros para el llamado de la api
   * @returns {Object}
   */
  updateMenu = (params) => {
    /**
     * @typedef {Object} params
     * @property {string} Menu - Nombre del menú [obligatorio],
     * @property {string} Ruta - Ruta, path, url del menú [obligatorio],
     * @property {number} Ver - Bit de Acceso 0,1 [obligatorio],
     * @property {number} Agregar - Bit de Acceso 0,1 [obligatorio],
     * @property {number} Editar - Bit de Acceso 0,1 [obligatorio],
     * @property {number} Eliminar - Bit de Acceso 0,1 [obligatorio],
     * @property {number} Exportar - Bit de Acceso 0,1 [obligatorio],
     * @property {number} Visible - Bit de Acceso 0,1 [obligatorio],
     * @property {string} Clave - Clave interna [opcional],
     * @property {string} Descripcion - Descripción del menú [opcional],
     * @property {number} Movil - Bit para indicar tipo 0 = Web, 1 = Móvil [obligatorio],
     * @property {number} idPadre - id del menú del cual depende [opcional],
     * @property {string} Icono - Nombre del ícono [opcional],
     * @property {string} TipoIcono - Nombre de la librería de íconos utilizada [opcional],
     * @property {string} Siglas - Siglas para sustituir en caso de no contar con un ícono [obligatorio],
     * @property {string} Plantilla - Plantilla utilizada en el proyecto front [opcional],
     * @property {number} idEstatus - id del Estatus del menú
     * @property {number} Orden - Orden del menú [opcional]
     * @property {number} Desplegable - Bit para indicar si el menú tendrá submenús [opcional]
     */
    return ApiExec(params, "menus/update-order", "PUT")
      .then(function (res) {
        return res;
      })
      .then((res) => {
        /**
         * @typedef {Object} res
         * @property {boolean} success - Indica si la ejecución en el servidor tuvo éxito o no
         * @property {boolean} results - Ejecución con éxito en el servidor, que indica si hubo o no resultados, o estatus de validación
         * @property {number} status - Código de respuesta http
         * @property {string} message - Mensaje para el usuario
         * @property {Object} response - Datos del menú registrado
         */
        return res;
      })
      .catch((e) => {
        return e;
      });
  };

  /**
   * @property {Function} changeOrder - Función para actualizar el orden de los menús
   * @param {Object} params - Parámetros para el llamado de la api
   * @returns {Object}
   */
  changeOrder = (params) => {
    /**
     * @typedef {Object} params
     * @property {number} id - id del menú a modificar [obligatorio]
     * @property {number} Orden - Número en múltiplos de 100 [obligatorio]
     */
    return ApiExec({ params: params }, "menus/update-order", "PUT")
      .then(function (res) {
        return res;
      })
      .then((res) => {
        /**
         * @typedef {Object} res
         * @property {boolean} success - Indica si la ejecución en el servidor tuvo éxito o no
         * @property {boolean} results - Ejecución con éxito en el servidor, que indica si hubo o no resultados, o estatus de validación
         * @property {number} status - Código de respuesta http
         * @property {string} message - Mensaje para el usuario
         * @property {Object} response - {}
         */
        return res;
      })
      .catch((e) => {
        return e;
      });
  };

  /**
   * @property {Function} changeAccess - Función para actualizar los privilegios de un menú
   * @param {Object} params - Parámetros para el llamado de la api
   * @returns {Object}
   */
  changeAccess = (params) => {
    /**
     * @typedef {Object} params
     * @property {number} id - id del menú a modificar
     * @property {string} colum - Opción de ["Ver","Agregar","Editar","Eliminar","Exportar","Visible"]
     * @property {number} value - bit de acceso para la columna [0,1]
     */
    return ApiExec(params, "menus/update-access", "PUT")
      .then(function (res) {
        return res;
      })
      .then((res) => {
        /**
         * @typedef {Object} res
         * @property {boolean} success - Indica si la ejecución en el servidor tuvo éxito o no
         * @property {boolean} results - Ejecución con éxito en el servidor, que indica si hubo o no resultados, o estatus de validación
         * @property {number} status - Código de respuesta http
         * @property {string} message - Mensaje para el usuario
         * @property {Object} response - {}
         */
        return res;
      })
      .catch((e) => {
        return e;
      });
  };

  /**
   * @property {Function} getImpact4Change - Función para mostrar al usuario el impacto que causaría realizar el cambio y tomar la decisión de continuar o cancelar
   * @param {Object} params - Parámetros para el llamado de la api
   * @returns {Object}
   */
  getImpact4Change = (params) => {
    /**
     * @typedef {Object} params
     * @property {number} id - id del menú a modificar
     * @property {string} colum - Opción de ["Ver","Agregar","Editar","Eliminar","Exportar","Visible"]
     * @property {number} value - bit de acceso para la columna [0,1]
     */
    return ApiExec(params, "menus/get-impact", "GET")
      .then(function (res) {
        return res;
      })
      .then((res) => {
        /**
         * @typedef {Object} res
         * @property {boolean} success - Indica si la ejecución en el servidor tuvo éxito o no
         * @property {boolean} results - Ejecución con éxito en el servidor, que indica si hubo o no resultados, o estatus de validación
         * @property {number} status - Código de respuesta http
         * @property {string} message - Mensaje para el usuario
         * @property {Object} response - {}
         */
        return res;
      })
      .catch((e) => {
        return e;
      });
  };
}

const menu = new MenuService()
export default menu;