import { useState, useEffect } from "react";
import Swal from "sweetalert2";

// Material UI
import { Box, Divider } from "@mui/material";
import { blue, red } from '@mui/material/colors';

// Componentes
import StackedBar from "@components/Charts/StackedBar";

// Servicios
import certificacion from "@services/CertificationServices";

const ChartTops = ({ body, setLoading, openDrawer, ambitoUser }) => {
  const [localLoading, setLocalLoading] = useState(false)

  const [dataChartMejores, setDataChartMejores] = useState({
    title: '',
    subtitle: '',
    date: '',
    categories: [],
    series: []
  });

  const [dataChartPeores, setDataChartPeores] = useState({
    title: '',
    subtitle: '',
    date: '',
    categories: [],
    series: []
  });

  const topTenChart = async (params) => {
    setLoading((prev) => ({ ...prev, isLoading: true, open: true }));
    setLocalLoading(true)
    try {
      const res = await certificacion.getTopTenChart();
      const { results, message, response } = res;
      if (results) {
        setDataChartMejores({
          categories: response.categories,
          series: response.series.map((item) => ({
            ...item,
            data: item.data.map((value) => parseInt(value)),
          })),
          title: response.title.toUpperCase(),
          date: response.date,
        });
      } else throw new Error(message);
    } catch (error) {
      Swal.fire({ title: error.message, icon: "warning" });
    } finally {
      setLoading((prev) => ({ ...prev, isLoading: false, open: false }));
      setLocalLoading(false)
    }

    try {
      const res = await certificacion.getTenWorstChart();
      const { results, message, response } = res;
      if (results) {
        setDataChartPeores({
          categories: response.categories,
          series: response.series.map((item) => ({
            ...item,
            data: item.data.map((value) => parseInt(value)),
          })),
          title: response.title.toUpperCase(),
          date: response.date,
        });
      } else throw new Error(message);
    } catch (error) {
      Swal.fire({ title: error.message, icon: "warning" });
    } finally {
      setLoading((prev) => ({ ...prev, isLoading: false, open: false }));
      setLocalLoading(false)
    }
  };

  useEffect(() => {
    topTenChart()
    // eslint-disable-next-line
  }, []);

  return (<>
    <Box>
      <StackedBar
        title={dataChartMejores ?? ""}
        series={dataChartMejores.series ?? []}
        date={"Última actualización -  " + dataChartMejores.date ?? ""}
        categories={dataChartMejores.categories ?? []}
        color={blue[500]}
        fontSize={'18px'}
        fontSizeSubtitle={'14px'}
        align={'center'}
        loading={localLoading} />
    </Box>

    <Divider />

    <Box sx={{ mt: 2 }}>
      <StackedBar
        title={dataChartPeores ?? ""}
        series={dataChartPeores.series ?? []}
        date={"Última actualización -  " + dataChartPeores.date ?? ""}
        categories={dataChartPeores.categories ?? []}
        color={red[600]}
        fontSize={'18px'}
        fontSizeSubtitle={'14px'}
        align={'center'}
        loading={localLoading} />
    </Box>

  </>
  );
};

export default ChartTops;
