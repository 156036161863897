import { ProtectedRoute } from "@middlewares";
import Nombres from "@pages/Nombres";

const routes = [
  {
    path: "/editar-colonias",
    element: (
      <ProtectedRoute>
        <Nombres />
      </ProtectedRoute>
    ),
  },
];

export default routes;
