import React, { useState } from "react";
import { Container, Box, Grid, Card, CardContent, TextField, MenuItem, Button } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { format } from "date-fns";
import Swal from "sweetalert2";

import AdminLayout from "@components/MainPage/AdminLayout";
import ActionHeader from "@components/Containers/ActionHeader";
import FilterCollapse from "@components/Filters/FilterCollapse";
import UserSearchHistory from "@components/Users/UserSearchHistory";
import UserRiskAlertHistory from "@components/Users/UserRiskAlertHistory";
import SystemServices from "@services/SystemServices";

function UserRiskAlerts() {
  const [userId, setuserId] = useState(null);
  const [userName, setuserName] = useState("");

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const [selectedOption, setSelectedOption] = useState(null);
  const [apiData, setApiData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleUserIdChange = (id) => {
    setuserId(id);
  };

  const handleUserNameChange = (name) => {
    setuserName(name);
  };

  const [params, setParams] = useState({
    page: 0,
    pageSize: 10,
    filtered: [
      {
        id: "idAccion",
        filter: "=",
        value: null,
      },
      {
        id: "FechaInicio",
        filter: ">=",
        value: null,
      },
      {
        id: "FechaFinal",
        filter: "<=",
        value: null,
      },
    ],
  });

  const options = [
    {
      id: 1,
      label: "Cambio de contraseña sin consentimiento",
      idClasificacion: 3,
    },
    {
      id: 2,
      label: "Asignación de responsabilidad por encima de la meta",
      idClasificacion: 2,
    },
    {
      id: 3,
      label: "Superó el máximo de cambios de contraseña por hora",
      idClasificacion: 1,
    },
    {
      id: 4,
      label: "Intento de acceso al sistema fuera del horario establecido",
      idClasificacion: 3,
    },
  ];

  const handleOptionChange = (event) => {
    const selectedId = event.target.value;
    const selectedOption = options.find((option) => option.id === selectedId);

    setSelectedOption(selectedOption);

    setParams((prevParams) => {
      const updatedFiltered = [
        {
          id: "idAccion",
          filter: "=",
          value: selectedOption ? selectedId : 0, // Usamos 0 como valor predeterminado si selectedOption es null
        },
        ...prevParams.filtered.slice(1),
      ];

      return {
        ...prevParams,
        filtered: updatedFiltered,
      };
    });
  };

  const handleStartDateChange = (newValue) => {
    setStartDate(newValue); // Actualizamos startDate con la nueva fecha seleccionada

    const formattedStartDate = newValue ? format(newValue, "yyyy-MM-dd") : null;
    setParams((prevParams) => ({
      ...prevParams,
      filtered: [
        ...prevParams.filtered.slice(0, 1),
        {
          id: "FechaInicio",
          filter: ">=",
          value: formattedStartDate,
        },
        ...prevParams.filtered.slice(2),
      ],
    }));
  };

  const handleEndDateChange = (newValue) => {
    setEndDate(newValue);

    const formattedEndDate = newValue ? format(newValue, "yyyy-MM-dd") : null;
    setParams((prevParams) => ({
      ...prevParams,
      filtered: [
        ...prevParams.filtered.slice(0, 2),
        {
          id: "FechaFinal",
          filter: "<=",
          value: formattedEndDate,
        },
      ],
    }));
  };

  const handleFilterClick = () => {
    // Verifica al menos uno de los campos (selectedOption, startDate, endDate) tenga valor
    if (selectedOption || startDate || endDate) {
      if (!selectedOption) {
        alert("Por favor, selecciona una opción");
        return;
      }

      setApiData(null);
      sendColorId();
      sendNameId();
      fetchData();
    } else {
      console.error("Campos obligatorios incompletos");
    }
  };

  const [changeColor, setChangeColor] = useState("");
  const sendColorId = () => {
    setChangeColor(selectedOption.idClasificacion);
  };

  const [changeName, setChangeName] = useState("");
  const sendNameId = () => {
    setChangeName(selectedOption.label);
  };

  const handleClearClick = () => {
    setSelectedOption(null);
    setStartDate(null);
    setEndDate(null);
    setApiData(null);
    setChangeName(null);
    setChangeColor(null);
    setParams({
      page: 0,
      pageSize: 10,
      filtered: [
        {
          id: "idAccion",
          filter: "=",
          value: null,
        },
        {
          id: "FechaInicio",
          filter: ">=",
          value: null,
        },
        {
          id: "FechaFinal",
          filter: "<=",
          value: null,
        },
      ],
    });
  };

  const fetchData = async () => {
    try {
      setIsLoading(true);

      const response = await SystemServices.getRiskLogs(params);
      //Llenar informacion de la data para el historial de alertas de riesgo
      setApiData(response.response.data);
    } catch (error) {
      console.error("API Error:", error);
      // Mostrar alerta de error
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Hubo un problema al obtener los datos. Por favor, inténtalo de nuevo.",
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <AdminLayout>
        <Container maxWidth="false">
          <ActionHeader title="Alertas de riesgo" isAction={false} titleButton="Regresar" iconButton="arrow_back" />
          <Box sx={{ mt: -2, mb: 1 }}>
            {/* filtro de alertas de riesgo */}
            <FilterCollapse>
              <Card className="card-primary" sx={{ marginBottom: 1 }}>
                <CardContent>
                  <Grid container spacing={1}>
                    <Grid item xs={12} lg={4}>
                      <TextField
                        select
                        label="Selecciona una opción"
                        value={selectedOption ? selectedOption.id : ""}
                        onChange={handleOptionChange}
                        fullWidth
                      >
                        {options.map((option) => (
                          <MenuItem key={option.id} value={option.id}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>

                    <Grid item xs={12} lg={4}>
                      <DatePicker
                        label="Fecha inicial"
                        format="dd/MM/yyyy"
                        inputVariant="outlined"
                        value={startDate}
                        onChange={(newValue) => handleStartDateChange(newValue)}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            InputLabelProps={{
                              ...params.InputLabelProps,
                              shrink: true, // Esto asegura que el label siempre esté en la parte superior
                            }}
                            sx={{
                              width: "100%",
                              marginY: {
                                xs: 2,
                                sm: 2,
                                lg: 0,
                              },
                              "& .MuiInputBase-input": {
                                fontSize: "0.875rem",
                                padding: "10px 14px",
                              },
                            }}
                          />
                        )}
                      />
                    </Grid>

                    <Grid item xs={12} lg={4}>
                      <DatePicker
                        label="Fecha final"
                        format="dd/MM/yyyy"
                        inputVariant="outlined"
                        value={endDate} // Vincula el estado
                        onChange={(newValue) => handleEndDateChange(newValue)}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            InputLabelProps={{
                              ...params.InputLabelProps,
                              shrink: true, // Esto asegura que el label siempre esté en la parte superior
                            }}
                            sx={{
                              width: "100%",
                              marginY: {
                                xs: 1,
                                sm: 1,
                                lg: 0,
                              },
                              "& .MuiInputBase-input": {
                                fontSize: "0.875rem",
                                padding: "10px 14px",
                              },
                            }}
                          />
                        )}
                      />
                    </Grid>

                    <Grid item xs={6} sm={6} lg={2}>
                      <Button
                        fullWidth
                        onClick={handleFilterClick}
                        variant="contained"
                        color="primaryDark"
                        sx={{
                          marginY: {
                            xs: 1,
                            sm: 0,
                          },
                        }}
                      >
                        Filtrar
                      </Button>
                    </Grid>

                    <Grid item xs={6} sm={6} lg={2}>
                      <Button
                        fullWidth
                        variant="outlined"
                        onClick={() => {
                          handleClearClick();
                        }}
                        sx={{
                          color: "rgb(0, 65, 160)",
                          borderColor: "rgb(0, 65, 160)",
                          marginY: {
                            xs: 1,
                            sm: 0,
                          },
                        }}
                      >
                        Limpiar
                      </Button>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </FilterCollapse>

            <Grid container>
              {/* Seleccionador de usuario */}
              <Grid item xs={12} lg={4} sx={{ pr: { lg: 1 }, mt: 1 }}>
                <UserSearchHistory userId={userId} handleUserIdChange={handleUserIdChange} handleUserNameChange={handleUserNameChange} />
              </Grid>

              {/* Historial de alertas de riesgo del usuario seleccionado */}
              <Grid item xs={12} lg={8} sx={{ pl: { lg: 1 }, mt: 1 }}>
                <Card className="card-primary" sx={{ height: "100%", padding: 2 }}>
                  <UserRiskAlertHistory
                    userId={userId}
                    userName={userName}
                    apiData={apiData}
                    sendColorId={changeColor}
                    changeName={changeName}
                    isLoading={isLoading}
                  />
                </Card>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </AdminLayout>
    </LocalizationProvider>
  );
}

export default UserRiskAlerts;
