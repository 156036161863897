import { ApiExec, ApiExecDecrypted } from "@utils/ApiExec";

class ReportsServices {
  getTotals = (params) => {
    return ApiExec(params, "reports/get-totals", "POST")
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };

  getBestTopTen = (params) => {
    return ApiExec(params, "reports/get-best-top-ten", "POST")
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };

  getWorstTopTen = (params) => {
    return ApiExec(params, "reports/get-worst-top-ten", "POST")
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };

  getStatsTable = (params) => {
    return ApiExec(params, "reports/get-stats-table", "POST")
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };
  getPromotionEndDate = (params) => {
    return ApiExec(params, "reports/get-promotion-end-date", "GET")
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };
  getTotalsByElectoralParties = (params) => {
    return ApiExec(params, "reports/get-totals-by-electoral-parties", "POST")
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };
}
var map = new ReportsServices();
export default map;
