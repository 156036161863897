import { useEffect, useState } from "react";
import { useSorted } from "@hooks/useSorted";

import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Icon,
  Stack,
  Typography,
} from "@mui/material";

import AdminLayout from "@components/MainPage/AdminLayout";
import ActionHeader from "@components/Containers/ActionHeader";
import DetailRegional from "@components/Rcos/Detail";
import Download from "@components/Downloads/Download";
import DataTableReport from "@components/Tables/DataTableReport";
import FilterRegional from "@components/Rcos/FilterRegional";

import { COLUMNS_RCO_REGIONAL } from "@data/constants/Rco";
import rco from "@services/RcosServices";
import Swal from "sweetalert2";
import middleware from "@middlewares/middleware";

const Regional = () => {
  const [total, setTotal] = useState(10);
  const [dataRegional, setDataRegional] = useState({
    Info: {},
    Regionales: [],
    data: [],
  });
  const [loadingRegional, setLoadingRegional] = useState(false);
  const [data, setData] = useState({
    page: 0,
    pageSize: 25,
    filtered: [],
    sorted: [],
  });
  const [isDownload, setIsDownloading] = useState(false);
  const [summary, setSummary] = useState([]);
  const { handleRequestSort } = useSorted({
    total: total,
    setData,
    id: "CONCAT_WS(' ', usuarios.Nombre, usuarios.Paterno, usuarios.Materno)",
  });

  const getInfoRcosRegiones = async (body) => {
    try {
      setLoadingRegional(true);
      const result = await rco.getInfoRcosRegiones(body);
      const { response, message, results } = result;
      if (!results) {
        throw new Error(message);
      }
      const { Info, Regionales, data } = response.data;
      setDataRegional({
        ...dataRegional,
        Info,
        Regionales,
        data,
      });
      setTotal(response.data.total);
      setSummary(Object.values(response.data.totals));
    } catch (e) {
      Swal.fire({
        title: e,
        icon: "warning",
      });
    } finally {
      setLoadingRegional(false);
    }
  };

  const onChangeFilter = ({ filters }) => {
    if (filters.length > 0) {
      setData((prevState) => ({
        ...prevState,
        page: 0,
        filtered: filters,
      }));
    }
  };

  const onClearFilter = () => {
    setData((prevState) => ({
      ...prevState,
      page: 0,
      filtered: [],
      sorted: [],
    }));
  };

  const handleExport = async () => {
    setIsDownloading(true);
    try {
      const result = await rco.export({
        api: "rcos/export-rcos-regiones",
        params: { filtered: data.filtered },
      });
      const { success } = result;

      if (!success) {
        throw new Error(
          "Ocurrió un error en la descarga, consulte con el administrador"
        );
      }

      Swal.fire({
        title: "Descargado con éxito",
        icon: "success",
      });
    } catch (e) {
      Swal.fire({
        title: e,
        icon: "warning",
      });
    } finally {
      setIsDownloading(false);
    }
  };

  const handleChangePagination = (pagination) => {
    setData({ ...data, ...pagination });
  };

  useEffect(() => {
    if (data.filtered && data.filtered.length > 0) {
      getInfoRcosRegiones(data);
    } else {
      setDataRegional({
        Info: {},
        Regionales: [],
        data: [],
      });
    }
    //eslint-disable-next-line
  }, [data]);

  return (
    <AdminLayout>
      <Container maxWidth={false}>
        <ActionHeader title="RCO Regional" />
        <Box marginTop={2}>
          <Card className="card-primary">
            <CardContent>
              <FilterRegional
                onChangeFilter={onChangeFilter}
                onClearFilter={onClearFilter}
              />
              {middleware.checkMenuAction("Exportar") && (
                <Stack
                  direction={"row"}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                  marginBottom={2}
                  marginTop={2}
                >
                  <Typography fontWeight={600} variant="body2">
                    SECCIONALES
                  </Typography>
                  <Button
                    variant="outlined"
                    color="primaryDark"
                    startIcon={<Icon>download</Icon>}
                    size="small"
                    disabled={isDownload || data.filtered.length === 0}
                    onClick={handleExport}
                  >
                    Exportar
                  </Button>
                </Stack>
              )}

              {isDownload && (
                <Box marginBottom={2}>
                  <Download format={"pdf"} isDownload={isDownload} />
                </Box>
              )}
              <DetailRegional
                Info={dataRegional.Info}
                data={dataRegional.Regionales}
                type={"REGIONALES"}
                labelType={"SIN REGIONALES"}
                loading={loadingRegional}
              />
              <DataTableReport
                rows={dataRegional.data}
                hcolumns={COLUMNS_RCO_REGIONAL}
                total={total}
                summary={summary}
                startIdxReport={0}
                pageProp={data.page}
                pageSize={data.pageSize}
                minHeight="0px"
                stickyHeader={true}
                isLoading={loadingRegional}
                handleChangePagination={handleChangePagination}
                handleManualSort={handleRequestSort}
              />
            </CardContent>
          </Card>
        </Box>
      </Container>
    </AdminLayout>
  );
};

export default Regional;
