import { useState, useEffect } from "react";
import Swal from "sweetalert2";

// Material UI
import { Card, CardContent, IconButton, Grid, Typography, Chip } from "@mui/material";
import QueryStatsIcon from "@mui/icons-material/QueryStats";
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import { red, green } from '@mui/material/colors';

// Componentes
import CustomTable from "@components/Tables/CustomTable";
import UserSearchHistoryModal from "@components/Certificacion/Certifications/UserSearchHistoryModal";

// Middlewares ,Servicios, Utils
import { setDiynamicColumns } from "@utils/Utilities";
import certificacion from "@services/CertificationServices";

// Constantes
import { COLUMNS_CERTIFICATION_TASKS_HISTORY } from "@data/constants/Certificacion";

// Hooks
import { useSorted } from "@hooks/useSorted";

const TableTotalTaskHistory = (props) => {
    const {
        data,
        dataHistory,
        setShowFirstContainer
    } = props;

    const [localData, setLocalData] = useState(data);

    const [loading, setLoading] = useState(false);

    const [orderBy, setOrderBy] = useState("");

    const [total, setTotal] = useState(10);
    const [summary, setSummary] = useState([]);
    const [columnsDinamics, setColumnsDinamics] = useState([]);
    const [dataMovilization, setDataMovilization] = useState([]);

    const { handleRequestSort } = useSorted({
        total: total,
        setData: setLocalData,
    });

    const [openModalS, setOpenModalS] = useState(false);

    const [refreshSchedule, setRefreshSchedule] = useState(false);

    const [dataTasks, setDataTasks] = useState(null)
    const [resetApi, setResetApi] = useState(false)

    const handleOpenModalS = () => setOpenModalS(true);
    const handleCloseModalS = () => {
        setResetApi(false)
        setOpenModalS(false)
    };

    const nombreCompletoUpperCase = dataHistory?.NombreCompleto ? dataHistory?.NombreCompleto?.toUpperCase() : '';

    const handleChangePagination = (value) => {
        setLocalData((prev) => ({
            ...prev,
            page: value.page,
            pageSize: value.pageSize,
        }));
    };

    const getMovilization = async () => {
        setLoading(true);
        try {
            const params = {
                "idUsuario": dataHistory.idUsuario,
            }

            const res = await certificacion.getTasksDetails(params);

            const { results, response, message } = res;

            if (results) {
                setTotal(response.total);

                const summaryValues = Object.values(response.totales);
                setSummary(["", ...summaryValues.slice(1)]);

                // Modificar los datos para agregar el chip al campo Estatus
                const modifiedData = response.data.map((item) => {
                    let statusChip;
                    switch (item.Estatus) {
                        case "Nuevo":
                            statusChip = <Chip label={item.Estatus} style={{ backgroundColor: green[200], color: green[800], fontWeight: 600 }} />;
                            break;
                        case "Concluido":
                            statusChip = <Chip label={item.Estatus} style={{ backgroundColor: red[200], color: red[800], fontWeight: 600 }} />;
                            break;
                        default:
                            statusChip = <Chip label="Sin Estatus" />;
                    }

                    return {
                        ...item,
                        Estatus: statusChip
                    };
                });
                
                handleMobilizationData({ data: modifiedData });
            } else {
                setTotal(0);
                setSummary([]);
                setColumnsDinamics([]);
                setDataMovilization([]);
                throw new Error(message);
            }
        } catch (error) {
            Swal.fire({ title: error.message, icon: "warning" });
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        getMovilization();
        // eslint-disable-next-line
    }, [localData]);

    useEffect(() => {
        if (localData.sorted.length) {
            setOrderBy(localData.sorted[0].id);
        } else setOrderBy("");
    }, [localData]);

    const handleMobilizationData = ({ data }) => {
        const dataWithHighlight = data.map((item) => {
            return item;
        });

        const columns = setDiynamicColumns({
            data: dataWithHighlight[0],
            columns: [
                ...COLUMNS_CERTIFICATION_TASKS_HISTORY
            ],
        });

        const columnas = [
            {
                id: "Estatus",
                label: "ESTATUS",
                align: "center",
                type: "text",
                sort: false,
            },
            {
                id: "PersonasAsignadas",
                label: "PERSONAS ASIGNADAS EN LA TAREA",
                align: "center",
                type: "button",
                variant: "text",
                onClick: (a, b, c) => {
                    setDataTasks(c)
                    setResetApi(true)
                    handleOpenModalS()
                },
            },
            ...columns,
        ];

        setColumnsDinamics(columnas);
        setDataMovilization(
            dataWithHighlight.map((item) => ({ Button: <QueryStatsIcon />, ...item }))
        );
    };

    useEffect(() => {
        if (refreshSchedule) {
            getMovilization(localData);
            setRefreshSchedule(false);
        }
        // eslint-disable-next-line
    }, [refreshSchedule]);

    const changeOpen = () => {
        setShowFirstContainer(true)
    }

    return (
        <>
            <Card className="card-primary" sx={{ marginBottom: "0px!important" }}>
                <CardContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={4}>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Typography sx={{ textAlign: 'center', fontSize: '22px', fontWeight: 500, my: 2, display: 'sticky' }}>{nombreCompletoUpperCase}</Typography>
                        </Grid>

                        <Grid item xs={12} md={4} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <IconButton sx={{ color: 'rgb(24,117,210)', mr: '2px' }} onClick={changeOpen}>
                                <KeyboardDoubleArrowLeftIcon sx={{ fontSize: 24 }} />

                                <Typography sx={{ fontSize: '16px', fontWeight: 550 }}>
                                    REGRESAR
                                </Typography>
                            </IconButton>
                        </Grid>
                    </Grid>

                    <CustomTable
                        rows={dataMovilization}
                        columns={columnsDinamics}
                        total={total}
                        orderBy={orderBy}
                        page={data.page}
                        pageSize={data.pageSize}
                        isLoading={loading}
                        handlePagination={handleChangePagination}
                        handleSort={handleRequestSort}
                        summary={summary}
                        summarySettings={{ start: 0, title: "Totales: ", align: "center" }}
                        disableCardType
                        showPagination={false}
                    />
                </CardContent>
            </Card>

            <UserSearchHistoryModal
                openModalS={openModalS}
                handleCloseModalS={handleCloseModalS}
                dataTasks={dataTasks ?? null}
                resetApi={resetApi}
            />
        </>
    );
};

export default TableTotalTaskHistory;
