import React from "react";

import { Card, CardHeader, CardContent } from "@mui/material";

const Sheet = ({
  hasCardHeader = false,
  colorTittle =  "white",
  colorbgtittle= "#0d47a1",
  elevation = 3,
  sx,
  children,
  padding,
  borderRadius = 14,
  Tittle = "Titulo",
  className = "card-primary",
  Action=""
}) => {
  return (
    <>
      <Card className={className} elevation={elevation} sx={sx ? sx : { borderRadius: { borderRadius }, p: { padding }, sx }}>
        {hasCardHeader && <CardHeader sx={{ backgroundColor: colorbgtittle, paddingTop: 0, paddingBottom: 0 }} title={Tittle} action={Action} titleTypographyProps={{ color: colorTittle, fontSize: "18px" }} />}
        <CardContent sx={{ p: 3, height: "auto" }}>{children}</CardContent>
      </Card>
    </>
  );
};

export default Sheet;
