import { useState, useEffect, useRef } from "react";
import { Container, Card } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2";

import AdminLayout from "@components/MainPage/AdminLayout";
import ActionHeader from "@components/Containers/ActionHeader";
import LoadingForm from "@components/LinearProgress/LoadingForm";
import UsersSearch from "@components/Users/UsersSearch"
import WeekSchedule from "@components/Schedules/WeekSchedule";

import SupportService from "@services/SupportServices"

const Schedules = () => {
    const usersSearchRef = useRef();

    const [loading, setLoading] = useState(false);
    const [isSuccessForm] = useState(false);
    const [isLoadigForm, setIsLoadingForm] = useState(false);
    const [openLinearProgress, setOpenLinearProgress] = useState(false);
    const [showUserSearch, setShowUserSearch] = useState(false);
    const [userId, setUserId] = useState("");
    const [name, setName] = useState('');

    useEffect(() => {
        if (loading) {
            setIsLoadingForm(true)
            setOpenLinearProgress(true)
        } else {
            setIsLoadingForm(false)
            setOpenLinearProgress(false)
        }
    }, [loading]);

    return (
        <AdminLayout>
            <LoadingForm
                isLoading={isLoadigForm}
                success={isSuccessForm}
                isOpen={openLinearProgress}
                setIsOpen={() => setOpenLinearProgress(!openLinearProgress)}
                loadinMessage={"Cargando..."}
            />
            <Container maxWidth="false">
                <ActionHeader
                    title="Horarios de Sistema"
                />
                <>
                    <Grid2 container spacing={2}>
                        {showUserSearch && (
                            <Grid2 item xs={12} lg={6}>
                                <UsersSearch
                                    idUsuario={setUserId}
                                    nombreCompleto={setName}
                                    loading={setIsLoadingForm}
                                    openLineal={setOpenLinearProgress}
                                    API={SupportService.getUsersToAssign}
                                    ref={usersSearchRef}
                                />
                            </Grid2>
                        )}
                        <Grid2 item xs={12} lg={showUserSearch ? 6 : 12}>
                            <WeekSchedule
                                userId={userId}
                                name={name}
                                setLoading={setLoading}
                                setShowSearch={setShowUserSearch}
                            />
                        </Grid2>
                    </Grid2>

                </>
            </Container>
        </AdminLayout>
    )
}

export default Schedules;