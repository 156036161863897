import { useEffect, useState } from "react";

// Material UI
import { Container, Grid, Button, Typography } from "@mui/material";
import Swal from "sweetalert2";

//Services
import mov from "@services/MovilizationServices";

// Components
import AdminLayout from "@components/MainPage/AdminLayout";
import ActionHeader from "@components/Containers/ActionHeader";
import LoadingForm from "@components/LinearProgress/LoadingForm";
import DrawerRelative from "@components/Containers/DrawerRelative";
import FloatingButton from "@components/Button/FloatingButton";

import LastUpdatedCard from "@components/Movilizadores/DashboardMovilizados/LastUpdatedCard";
import TableMobilized from "@components/Movilizadores/DashboardMovilizados/TableMobilized";
import GraphicColumnStacked from "@components/Movilizadores/DashboardMovilizados/GraphicColumnStacked";
import Filter from "@components/Movilizadores/DashBoard/Filter";

import AdaptiveCard from "@components/Card/AdaptiveCard";

// Iconos   
import {
    KeyboardDoubleArrowRight,
    KeyboardDoubleArrowLeft,
} from "@mui/icons-material";

const DashboardMovilizados = () => {
    const [ambitoUser, setAmbitoUser] = useState("");

    const [openDrawer, setOpenDrawer] = useState(true);

    const [loading, setLoading] = useState({
        isLoadigForm: false,
        isSuccessForm: false,
        openLinearProgress: false,
    });
    const [refresh, setRefresh] = useState(false);

    const [idFilter, setIdFilter] = useState(ambitoUser);

    const [idAmbitoAgrupadorTable, setIdAmbitoAgrupadorTable] =
        useState(ambitoUser);

    const [catView, setCatView] = useState([]);
    const [data, setData] = useState({
        page: 0,
        pageSize: 10,
        filtered: [],
        sorted: [],
        idAmbitoAgrupador: ambitoUser,
    });

    const [dataChart, setDataChart] = useState({ categories: [], series: [] })
    const [totalCharts, setTotalCharts] = useState({ Afines: 0, VotantesAfines: 0, PorcentajeAfines: 0, TotalMovilizados: 0, PorcentajeMovilizados: 0 })
    const [loadingChart, setloadingChart] = useState()

    const handleSearchFilter = ({ filtereds, view }) => {
        const option = catView.find(item => item.value === view);
        const filtered = catView.filter(item => item.Nivel >= option.Nivel);
        setIdAmbitoAgrupadorTable(filtered[0]?.value || ambitoUser);

        setData((prev) => ({
            ...prev,
            page: 0,
            pageSize: 10,
            filtered: filtereds,
            idAmbitoAgrupador: view,
        }));
    };

    const handleClearFilter = () => {
        if (data.filtered.length > 0 || data.idAmbitoAgrupador !== ambitoUser) {
            setData((prev) => ({
                ...prev,
                filtered: [],
                idAmbitoAgrupador: ambitoUser,
            }));
            setIdAmbitoAgrupadorTable(ambitoUser);
        }
    };

    const handleRefresh = () => {
        setData((prev) => ({ ...prev, page: 0, pageSize: 10, sorted: [] }));
        setRefresh(true);
        setTimeout(() => {
            setRefresh(false);
        }, 1000);
    };

    const getCatView = async () => {
        try {
            const res = await mov.getAmbitoAgrupadorMovilizacion();
            const { results, response, message } = res;

            if (results) {
                let data = response?.data ?? [];
                data.sort((a, b) => a.value - b.value);

                setCatView(data);
                setAmbitoUser(data[0]?.value);
            } else {
                throw new Error(message);
            }
        } catch (error) {
            Swal.fire({ title: error.message, icon: "warning" });
        }
    };
    useEffect(() => {
        getCatView();
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (ambitoUser) {
            setIdAmbitoAgrupadorTable(ambitoUser);
            setData((prev) => ({
                ...prev,
                page: 0,
                pageSize: 10,
                filtered: [],
                idAmbitoAgrupador: ambitoUser,
            }));
        }
        // eslint-disable-next-line
    }, [ambitoUser]);

    return (
        <AdminLayout>
            <LoadingForm
                loadinMessage={"Cargando datos..."}
                isLoading={loading.isLoadigForm}
                success={loading.isSuccessForm}
                isOpen={loading.openLinearProgress}
                setIsOpen={() => setLoading({ ...loading, openLinearProgress: false })}
            />
            <Container maxWidth={false}>
                <ActionHeader title={"Dashboard Movilización"} isCustom={true}>
                    <Button
                        onClick={() => setOpenDrawer(!openDrawer)}
                        aria-label="open Drawer"
                        endIcon={
                            openDrawer ? (
                                <KeyboardDoubleArrowRight />
                            ) : (
                                <KeyboardDoubleArrowLeft />
                            )
                        }
                    >
                        <Typography sx={{ fontWeight: 500 }}>FILTROS</Typography>
                    </Button>
                </ActionHeader>

                <Grid
                    container
                    spacing={openDrawer ? 2 : 0}
                    sx={{ flexDirection: { xs: "column-reverse", md: "row" } }}
                >
                    <Grid item xs={12} md={openDrawer ? 8 : 12}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={4}>
                                <LastUpdatedCard
                                    dateHour={""}
                                    handleClick={handleRefresh}
                                    refresh={refresh}
                                    columnIn={openDrawer ? "lg" : "md"}
                                />
                            </Grid>

                            <Grid item xs={12} sm={4}>
                                <AdaptiveCard
                                    title="Avance de Votos Afines"
                                    icon="description"
                                    config={{
                                        percent: !totalCharts?.VotantesAfines || !totalCharts?.Afines ? 0 : ((totalCharts?.VotantesAfines / totalCharts?.Afines) * 100),
                                        fixed: 2
                                    }}
                                    leftConfig={{
                                        title: "Meta",
                                        data: totalCharts.Afines,
                                    }}
                                    rightConfig={{
                                        title: "Avance",
                                        data: totalCharts.VotantesAfines,
                                    }}
                                    fixed
                                    adjust
                                    loading={loadingChart}
                                />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <AdaptiveCard
                                    title="Movilización"
                                    icon="description"
                                    config={{
                                        percent: totalCharts.PorcentajeMovilizados,
                                    }}
                                    leftConfig={{
                                        title: "Meta",
                                        data: totalCharts.Afines,
                                    }}
                                    rightConfig={{
                                        title: "Avance",
                                        data: totalCharts.TotalMovilizados,
                                    }}
                                    fixed
                                    adjust
                                    loading={loadingChart}
                                />
                            </Grid>
                        </Grid>

                        <Grid item xs={12} sx={{ mt: 2 }}>
                            <GraphicColumnStacked openDrawer={openDrawer} dataChart={dataChart} loadingChart={loadingChart} />
                        </Grid>

                        <Grid item xs={12}>
                            <TableMobilized
                                data={data}
                                idFilter={idFilter}
                                catView={catView}
                                idAmbitoAgrupador={idAmbitoAgrupadorTable}
                                ambitoUser={ambitoUser}
                                setDataChart={setDataChart}
                                setTotalCharts={setTotalCharts}
                                setLoading={setloadingChart}
                                loading={loadingChart}
                            />
                        </Grid>
                    </Grid>

                    <Grid item xs={12} md={4}>
                        <DrawerRelative
                            anchor="right"
                            open={openDrawer}
                            setOpen={setOpenDrawer}
                            title={"Filtrar"}
                            icon="person_search"
                            titleComponentPlace="under"
                            sxPaper={{ borderRadius: 3 }}
                            isSticky
                        >
                            <Filter
                                changeSize
                                onSearch={handleSearchFilter}
                                onClear={handleClearFilter}
                                changeTypeView={() => { }}
                                refresh={refresh}
                                setLoading={setLoading}
                                catView={catView}
                                setIdFilter={setIdFilter}
                                ambitoUser={ambitoUser}
                            />
                        </DrawerRelative>
                    </Grid>
                </Grid>
                {!openDrawer && (
                    <FloatingButton
                        onClick={() => setOpenDrawer(true)}
                        label="Mostrar Filtros"
                        sx={{ display: { xs: "none", md: "block" } }}
                    />
                )}
            </Container>
        </AdminLayout>
    );
};

export default DashboardMovilizados;
