import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import {
  Box,
  Icon,
  Tooltip,
  Typography,
  Button,
  Avatar,
  ListItem,
  ListItemAvatar,
  ListItemText,
  ButtonGroup,
  IconButton
} from "@mui/material";
import { blue, red } from "@mui/material/colors";
import Swal from "sweetalert2";

import prep from "@services/PrepServices";
import middleware from "@middlewares/middleware";
import { isEmptyOrNullObject } from "@utils/validations";
import LoadingForm from "@components/LinearProgress/LoadingForm";
import BasicTableCustomizing from "@components/Tables/BasicTableCustomizing";

//import { URL_IMAGES } from "@utils/global"

const CandidatesTable = props => {
  const { filtered, handleEdit } = props;
  const navigate = useNavigate();
  const columns = [
    { id: "Actions", label: "", align: 'left' },
    { id: "TipoEleccion", label: "Tipo de Elección", align: 'left' },
    { id: "NombreCandidato", label: "Candidato", align: 'left' },
    { id: "Partido", label: "Partido", align: 'left' },
    { id: "Ambito", label: "Ámbito de la Elección", align: 'left' },
  ];
  const [loadingTable, setLoadingTable] = useState(true);
  const [sortedData, setSortedData] = useState([]);
  const [dataTable, setDataTable] = useState({
    data: [],
    total: 0
  });
  const [paramsTable, setParamsTable] = useState({
    page: 0,
    pageSize: 25,
    filtered: []
  });
  const [isLoadigForm, setIsLoadingForm] = useState(false);
  const [isSuccessForm] = useState(false);
  const [openLinearProgress, setOpenLinearProgress] = useState(false);

  useEffect(() => {
    if (filtered && filtered.length > 0) {
      setParamsTable((prevState) => ({ ...prevState, filtered: filtered, page: 0 }));
      getTable({ ...paramsTable, filtered: filtered, page: 0 });
    }
    //eslint-disable-next-line
  }, [filtered]);

  useEffect(() => {
    if (sortedData.length > 0) {
      setParamsTable((prevState) => ({ ...prevState, sorted: sortedData }));
    }
    // eslint-disable-next-line
  }, [sortedData]);

  const handleDelete = async (data) => {
    Swal.fire({
      title: 'Advertencia',
      html: `Esta acción eliminará el registro seleccionado. ¿Desea continuar?`,
      icon: "warning",
      cancelButtonText: 'No, Cancelar',
      confirmButtonText: 'Sí, Eliminar',
      showCancelButton: true,
      allowOutsideClick: false,
      allowEscapeKey: false,
    }).then(async (res) => {
      if (res.isConfirmed) {
        await deleteFromServer(data.idCandidato);
      }
    })
  }

  const deleteFromServer = async (id) => {
    try {
      setIsLoadingForm(true);
      setOpenLinearProgress(true);
      const result = await prep.deleteCandidate(id);
      const { results, message } = result;
      if (results) {
        Swal.fire({
          title: message,
          icon: "success",
        });

        setDataTable({
          total: dataTable.total - 1,
          data: dataTable.data.filter(item => item.idCandidato !== id)
        });
      } else {
        Swal.fire({
          title: message,
          icon: "warning",
        });
      }
    } catch (error) {
      Swal.fire({
        title: error.message,
        icon: "warning",
      });
    } finally {
      setIsLoadingForm(false);
      setOpenLinearProgress(false);
    }
  }

  const mapDataTable = (data) => {
    return data.map(item => {
      item.Actions = middleware.checkMenuAction("Eliminar") ? (
        <ButtonGroup>
          <Tooltip title="Eliminar">
            <IconButton size="small" onClick={e => handleDelete(item)}>
              <Icon sx={{ color: red[800] }}>delete</Icon>
            </IconButton>
          </Tooltip>
        </ButtonGroup>
      ) : '';
      item.NombreCandidato = middleware.checkMenuAction("Editar") ?
        (
          <ListItem>
            <ListItemAvatar>
              <Avatar
                src={item.Logo}
              />
            </ListItemAvatar>
            <Tooltip title="Click para editar">
              <Button
                disableElevation
                variant="text"
                color="primaryDark"
                size="small"
                sx={{
                  ":hover": {
                    bgcolor: blue[900],
                    color: "white",
                  },
                  p: "0 auto",
                  textAlign: "left",
                  ml: 2
                }}
                endIcon={<Icon>edit</Icon>}
                onClick={(e) => handleEdit(item)}
              >
                <ListItemText
                  primary={
                    <Typography variant="div" fontWeight={700} fontSize={15}>
                      {item.NombreCandidato}
                    </Typography>
                  }
                />
              </Button>
            </Tooltip>
          </ListItem>
        ) : (
          <ListItem>
            <ListItemAvatar>
              <Avatar
                src={item.Logo}
              />
            </ListItemAvatar>
            <ListItemText
              primary={
                <Typography variant="div" fontWeight={700} fontSize={15}>
                  {item.NombreCandidato}
                </Typography>
              }
            />
          </ListItem>
        );
      item.Partido = (
        <ListItem>
          <ListItemText
            primary={item.Partido}
            secondary={item.Siglas}
          />
        </ListItem>
      )

      return item;
    });
  }

  const getTable = (params) => {
    setLoadingTable(true);
    setIsLoadingForm(true);
    setOpenLinearProgress(true);
    prep.getCandidates(params).then(res => {
      if (res.results) {
        setDataTable({
          data: mapDataTable(res.response.data),
          total: res.response.total,
        });
      } else {
        setDataTable({
          data: [],
          total: 0
        });
      }
    }).catch(error => {
      setDataTable({
        data: [],
        total: 0
      });
    }).finally(() => {
      setLoadingTable(false);
      setIsLoadingForm(false);
      setOpenLinearProgress(false);
    })
  }

  const handleChangePagination = (pagination) => {
    setParamsTable({ ...paramsTable, ...pagination });
  };

  const handleSorted = (dataSorted) => {
    let sorted = [];
    if (!isEmptyOrNullObject(dataSorted)) {
      switch (dataSorted.orderBy) {
        case 'Ambito':
          sorted.push({
            id: 'prep_planillas.AmbitoValor',
            value: dataSorted.order,
          });
          break;
        default:
          sorted.push({
            id: dataSorted.orderBy,
            value: dataSorted.order,
          });
          break;
      }
    }
    return sorted;
  }

  const handleRequestSort = (sortData) => {
    if (dataTable.total > 0) setSortedData(handleSorted(sortData));
  }

  return (
    <Box component={'div'}>

      <LoadingForm
        isLoading={isLoadigForm}
        success={isSuccessForm}
        isOpen={openLinearProgress}
        setIsOpen={() => setOpenLinearProgress(!openLinearProgress)}
        loadinMessage={"Cargando..."}
      />

      <BasicTableCustomizing
        rows={dataTable.data}
        hcolumns={columns}
        pageProp={paramsTable.page}
        pageSize={paramsTable.pageSize}
        total={dataTable.total}
        handleChangePagination={handleChangePagination}
        isLoading={loadingTable}
        stickyHeader={true}
        handleManualSort={handleRequestSort}
      />

    </Box>
  )
}

export default CandidatesTable;