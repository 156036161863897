import { useState, useEffect } from "react";

// Material
import {
  Card,
  CardContent,
  Typography,
  Stack,
  Box,
  Divider,
  Icon,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Tooltip,
} from "@mui/material";
import { grey } from "@mui/material/colors";
import { AccountCircle, LocationOn, LocalPhone } from "@mui/icons-material";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import moment from 'moment';

import Swal from "sweetalert2";

import CertificacionServices from "@services/CertificationServices";
//UTILS
import { isEmptyOrInvalidArray } from "@utils/validations";

const AssignCertification = (props) => {
  const { params, setLoading, certificacion } = props;

  const [data, setData] = useState([]);

  const handleConfirm = (item, index) => {
    Swal.fire({
      title: "¿Estás seguro de que quieres eliminar?",
      text: "No podrás revertir esto.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sí, eliminarlo",
      cancelButtonText: "Cancelar",
    }).then((result) => {
      if (result.isConfirmed) {
        handleDelete(item, index);
      }
    });
  };

  const getPermisos = async (idUser) => {
    try {
      setLoading(true)
      const result = await CertificacionServices.getResponsabilities(idUser);
      const { results, message, response } = result;
      if (results) {
        setData(response.data);
      } else {
        throw new Error(message);
      }
    } catch (error) {
      Swal.fire({ title: error.message, icon: "warning" });
    }finally{
      setLoading(false)
    }
  };

  const handleDelete = async (item) => {
    try {
      setLoading(true);
      let param = {
        id: item.id,
        idUsuario: item.idUsuario,
      };
      const result = await CertificacionServices.deleteResponsability(param);
      const { results, message } = result;
      if (results) {
        Swal.fire({
          title: message,
          icon: "success",
        });
        getPermisos(params.id);
      } else {
        throw new Error(message);
      }
    } catch (error) {
      Swal.fire({
        title: error.message,
        icon: "warning",
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (params.id) {
      getPermisos(params.id);
    }
  }, [params, certificacion]);

  return (
    <Card className="card-primary" sx={{ height: "100%" }}>
      <CardContent>
        <Stack direction="row" spacing={2} justifyContent="center" alignItems="center">
          <Typography variant="h5" color="textPrimary">
            Datos del Usuario
          </Typography>
        </Stack>
        <Divider sx={{ mt: 1, mb: 2 }} />
        {params?.NombreCompleto ? (
          <>
            <Box mb={2}>
              <Stack direction="row" spacing={2} justifyContent="center" alignItems="center">
                <AccountCircle fontSize="large" style={{ color: grey[500] }} />
                <Typography fontWeight={600} variant="h6">
                  {params.NombreCompleto}
                </Typography>
              </Stack>
            </Box>
            <Box mb={2}>
              <Stack direction="row" spacing={2} alignItems="center" ml="2vh">
                <LocationOn />
                <Typography fontWeight={400} fontSize={15}>
                  {params.Municipio}
                </Typography>
              </Stack>
            </Box>
            <Box mb={2}>
              <Stack direction="row" spacing={2} alignItems="center" ml="2vh">
                <LocalPhone />
                <Typography fontWeight={400} fontSize={15}>
                  {params.Username}
                </Typography>
              </Stack>
            </Box>
            <Divider sx={{ mt: 1 }} />
            {!isEmptyOrInvalidArray(data) ? (
              <Box style={{ maxHeight: "400px", overflowY: "auto" }}>
                <List dense>
                  {data.map((item, index) => (
                    <ListItem disablePadding key={index}>
                      <ListItemIcon sx={{ width: "100%" }}>
                        <IconButton color="error" onClick={(e) => handleConfirm(item)}>
                          <Icon>delete</Icon>
                        </IconButton>
                        <ListItemText
                          primary={
                            <Tooltip title={item.label} disableInteractive arrow>
                              <Box
                                sx={{
                                  width: "100%",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  whiteSpace: "noWrap",
                                  fontWeight: 700,
                                }}
                              >
                                Responsabilidad: {item.ResponsabilidadEtiqueta} Sección: {item.AmbitoValorCargo}
                              </Box>
                            </Tooltip>
                          }
                          secondary={
                            <span>
                              <Tooltip title={item.responsable} disableInteractive arrow>
                                <Box
                                  sx={{
                                    width: "100%",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    whiteSpace: "noWrap",
                                  }}
                                >
                                  {item.AsignadoPor} - {moment(item.FechaCreo, "YYYY-MM-DD H:m:s").format("DD/MM/YYYY H:m")}
                                </Box>
                              </Tooltip>
                              {item.fecha}
                            </span>
                          }
                        />
                      </ListItemIcon>
                    </ListItem>
                  ))}
                </List>
              </Box>
            ) : (
              <Box mt={2}>
                <Stack direction="row" alignItems="center" justifyContent="center" spacing={2}>
                  <Typography fontWeight={600} fontSize={15}>
                    SIN CERTIFICACIONES ASIGNADAS
                  </Typography>
                </Stack>
              </Box>
            )}
          </>
        ) : (
          <Box>
            <CardContent>
              <Grid2 container direction="column" justifyContent="center" alignItems="center" m={2}>
                <Icon fontSize="large">info</Icon>
                <Typography variant="body1">Sin Información. Seleccione un usuario.</Typography>
              </Grid2>
            </CardContent>
          </Box>
        )}
      </CardContent>
    </Card>
  );
};

export default AssignCertification;
