import { useState, useEffect, useCallback, useMemo } from "react";
import Swal from "sweetalert2";

// Material UI
import {
  Grid,
  Box,
  Chip,
  Stack,
  Button,
  IconButton,
  Icon,
  Snackbar,
  Alert,
  Typography,
  TextField,
  CircularProgress,
} from "@mui/material";
import { blue, red } from "@mui/material/colors";
import {
  PersonAddAlt,
  PersonOff,
  KeyboardDoubleArrowRight,
  KeyboardDoubleArrowLeft,
} from "@mui/icons-material";

// Componentes
import AdminLayout from "@components/MainPage/AdminLayout";
import ActionHeader from "@components/Containers/ActionHeader";
import CustomTable from "@components/Tables/CustomTable";
import CustomTabs from "@components/Tabs/CustomTabs";
import FloatingButton from "@components/Button/FloatingButton";
import DrawerRelative from "@components/Containers/DrawerRelative";
import ContactsStack from "@components/ContactsStack/ContactsStack";
import Filter from "@components/Movilizacion/Estatus/Filter";

// Servicios
import CatalogServices from "@services/CatalogServices";
import MobilizationService from "@services/MovilizationServices";

// Middleware, Utils y Hooks
import middleware from "@middlewares/middleware";
import useWindowDimensions from "@hooks/useWindowDimensions";

const AsignStatus = () => {
  const { height } = useWindowDimensions();

  const [isLoading, setIsLoading] = useState(false);
  const [tabValue, setTabValue] = useState(0);

  const [catalogs, setCatalogs] = useState(false);
  const [isLoadingCat, setIsLoadingCat] = useState(false);

  const [rows, setRows] = useState([]);

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");

  const [openFilter, setOpenFilter] = useState(true);

  const [searchTerm, setSearchTerm] = useState("");
  const [searchTerm2, setSearchTerm2] = useState("");

  let dataSection = [];

  useEffect(() => {
    loadCatalogs();
    // eslint-disable-next-line
  }, []);

  const loadCatalogs = useCallback(async () => {
    const catalogsParams = [
      /*  { id: "regiones", getAll: false }, */
      { id: "municipios_reportes", getAll: false },
      /* { id: "poligonos", getAll: false }, */
      { id: "secciones", getAll: false },
    ];
    setIsLoadingCat(true);

    try {
      const result = await CatalogServices.getCatalogs(catalogsParams);
      const { results, response, message } = result;

      if (results) {
        if (response.errors.length > 0) {
          Swal.fire({
            title: "Algunos catálogos NO pudieron ser cargados. Contacte al administrador",
            icon: "warning",
          });
        }

        const RSRe = response.catalogs?.regiones ?? [];
        const RSMUN = response.catalogs?.municipios_reportes ?? [];
        const RSPo = response.catalogs?.poligonos ?? [];
        const RSSe = response.catalogs?.secciones ?? [];

        const todas = RSRe?.length === 1 ? RSRe : [{ value: 0, label: "TODAS" }].concat(RSRe);
        const todos = RSMUN?.length === 1 ? RSMUN : [{ value: 0, label: "TODOS" }].concat(RSMUN);
        const todosRSPo = RSPo?.length === 1 ? RSPo : [{ value: 0, label: "TODOS" }].concat(RSPo);
        const todosRSSe = RSSe;

        const all = {
          regiones: RSRe ? todas : [],
          municipios: RSMUN ? todos : [],
          poligonos: RSPo ? todosRSPo : [],
          secciones: RSSe ? todosRSSe : [],
        };

        setCatalogs(all);
      } else throw new Error(message);
    } catch (e) {
      Swal.fire({ title: e.message, icon: "warning" });
    } finally {
      setIsLoadingCat(false);
    }
  }, []);

  const getComponent = (item, option) => {
    switch (option) {
      case "chip":
        return (
          <Chip
            label={item.idEstatus === 2 ? "Movilizado" : "No Localizado"}
            sx={{
              backgroundColor: item.idEstatus === 2 ? blue[200] : red[200],
              color: item.idEstatus === 2 ? blue[800] : red[800],
              fontWeight: 500,
              width: 120,
              height: 25,
            }}
          />
        );
      case "contact":
        return (
          <ContactsStack
            nombre={item.NombreCompleto}
            whatsapp={item.Celular}
            telefono={item.Telefono}
            telefono_mensaje={item.TelMensajes}
          />
        );
      case "contact_prop":
        return <ContactsStack nombre={item.NombrePropietario} whatsapp={item.CelularPropietario} />;
      case "button":
        if (item.isLoading) {
          return (
            <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
              <CircularProgress size={25} />
            </Box>
          );
        }
        return (
          <Stack direction={"row"} alignItems={"center"} gap={1} justifyContent={"right"} flexWrap={"wrap"}>
            <Button
              size="small"
              sx={{ backgroundColor: blue[500], color: "white", textTransform: "none" }}
              variant="contained"
              startIcon={<PersonAddAlt />}
              onClick={() => handleAction({ idEstatus: 2, idCompromisoUnico: item.idCompromisoUnico })}
              disabled={item.isDisabled}
            >
              Movilizado
            </Button>
            <Button
              size="small"
              sx={{
                backgroundColor: red[500],
                color: "white",
                textTransform: "none",
                "&:hover": { backgroundColor: red[700] },
                textWrap: "nowrap",
              }}
              variant="contained"
              startIcon={<PersonOff />}
              onClick={() => handleAction({ idEstatus: 3, idCompromisoUnico: item.idCompromisoUnico })}
              disabled={item.isDisabled}
            >
              No Localizado
            </Button>
          </Stack>
        );
      case "iconButton":
        if (item.isLoading) {
          return (
            <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
              <CircularProgress size={25} />
            </Box>
          );
        }
        return (
          <IconButton onClick={() => handleIconClick(item)} disabled={item.isDisabled}>
            <Icon>autorenew</Icon>
          </IconButton>
        );
      default:
        break;
    }
  };

  const components = (item) => ({
    ...item,
    Contact: getComponent(item, "contact"),
    ContactPropietario: getComponent(item, "contact_prop"),
    Estatus: getComponent(item, "chip"),
    Acciones: getComponent(item, "button"),
    Return: getComponent(item, "iconButton"),
  });

  const getListing = async (params) => {
    if (!params) return;

    try {
      setIsLoading(true);

      const res = await MobilizationService.getListings4Capture(params);
      const { results, message, response } = res;

      if (results) {
        const dataUsers = response.data.map((item) => components(item));

        dataSection = dataUsers;
        setRows(dataUsers);
      } else throw new Error(message);
    } catch (error) {
      dataSection = [];
      setRows([]);
      Swal.fire({ title: error.message, icon: "warning" });
    } finally {
      setIsLoading(false);
    }
  };

  const updateListing = async (params, data = [], prevData = [], initialData = []) => {
    const payload = { OrigenCaptura: "web", data: [params] };

    setRows(prevData);
    dataSection = prevData;

    try {
      const res = await MobilizationService.reportMobilized(payload);
      const { results, message } = res;
      if (results) {
        dataSection = data;
        setRows(data);

        setSnackbarMessage("Reportado con éxito");
        setSnackbarSeverity("success");
        setSnackbarOpen(true);
      } else throw new Error(message);
    } catch (error) {
      setRows(initialData);
      dataSection = initialData;
      setSnackbarMessage("No se pudo hacer el reporte");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    }
  };

  const handleFilter = (values) => getListing({ filtered: values });

  const generateUsuarios = (data, idUser, idEstatus) => {
    return data.reduce(
      (acc, item) => {
        const sameID = item.idCompromisoUnico === idUser;

        const initial = item;
        const updated = sameID ? { ...components({ ...item, idEstatus }) } : initial;
        const prev = sameID
          ? { ...components({ ...item, isLoading: true }) }
          : { ...components({ ...item, isDisabled: true }) };

        acc.initial.push(initial);
        acc.updated.push(updated);
        acc.prev.push(prev);

        return acc;
      },
      { initial: [], updated: [], prev: [] }
    );
  };

  const handleAction = ({ idEstatus, idCompromisoUnico }) => {
    const data = dataSection;

    const { initial, updated, prev } = generateUsuarios(data, idCompromisoUnico, idEstatus);

    updateListing({ idCompromisoUnico, idEstatus }, updated, prev, initial);
  };

  const handleIconClick = (item) => {
    const data = dataSection;

    const { idEstatus, idCompromisoUnico, NombreCompleto } = item;

    let buttonsHtml = "";
    if (idEstatus === 2) {
      buttonsHtml = `<button id="button1" class="swal2-confirm swal2-styled custom-button">Pendiente</button>
                       <button id="button3" class="swal2-confirm swal2-styled custom-button">No Localizado</button>`;
    } else if (idEstatus === 3) {
      buttonsHtml = `<button id="button1" class="swal2-confirm swal2-styled custom-button">Pendiente</button>
                       <button id="button2" class="swal2-confirm swal2-styled custom-button">Movilizado</button>`;
    }

    Swal.fire({
      title: `${NombreCompleto}`,
      html: `<p>Selecciona una opción para cambiar su estatus</p>${buttonsHtml}`,
      icon: "info",
      showCancelButton: true,
      cancelButtonText: "Cerrar",
      showConfirmButton: false,
      buttonsStyling: false,
      customClass: {
        actions: "swal2-actions",
        cancelButton: "custom-cancel-button",
      },
    }).then(() => {
      // Vacio
    });

    const localUpdate = (idEstatus) => {
      const { initial, updated, prev } = generateUsuarios(data, idCompromisoUnico, idEstatus);

      updateListing({ idCompromisoUnico, idEstatus }, updated, prev, initial);

      Swal.close();
    };

    if ([2, 3].includes(idEstatus)) {
      document.getElementById("button1").addEventListener("click", () => localUpdate(1));
    }

    if ([1, 3].includes(idEstatus)) {
      document.getElementById("button2").addEventListener("click", () => localUpdate(2));
    }

    if ([1, 2].includes(idEstatus)) {
      document.getElementById("button3").addEventListener("click", () => localUpdate(3));
    }
  };

  const handleSnackbarClose = () => setSnackbarOpen(false);

  let columns = [
    { id: "Seccion", label: "Sección", type: "text" },
    { id: "DL", label: "DL", type: "text" },
    { id: "NombreCompleto", label: "Nombre", type: "text" },
    { id: "Contact", label: "Contacto", type: "text", sort: false },
    { id: "NombrePropietario", label: "Promotor", type: "text" },
    { id: "ContactPropietario", label: "Contacto Promotor", type: "text", sort: false },
  ];

  let columnsMov = [
    { id: "Seccion", label: "Sección", type: "text" },
    { id: "DL", label: "DL", type: "text" },
    { id: "NombreCompleto", label: "Nombre", type: "text" },
    { id: "Contact", label: "Contacto", type: "text", sort: false },
    { id: "NombrePropietario", label: "Promotor", type: "text" },
    { id: "ContactPropietario", label: "Contacto Promotor", type: "text", sort: false },
    { id: "Estatus", label: "Estatus", type: "text" },
  ];

  if (middleware.checkMenuAction("Agregar"))
    columns.push({ id: "Acciones", label: "Acciones", type: "text", align: "right", sort: false });

  if (middleware.checkMenuAction("Editar"))
    columnsMov.push({ id: "Return", label: "Cambiar", type: "text", sort: false, align: "center" });

  const filteredRows1 = useMemo(() => {
    return rows.filter((item) => {
      if (searchTerm) {
        const labelLower = item.NombreCompleto.toLowerCase();
        const valueLower = searchTerm.toLowerCase();
        const regex = new RegExp(`\\b${valueLower}`);
        return regex.test(labelLower) && [1].includes(item.idEstatus);
      }
      return [1].includes(item.idEstatus);
    });
  }, [rows, searchTerm]);

  const filteredRows2 = useMemo(() => {
    return rows.filter((item) => {
      if (searchTerm2) {
        const labelLower = item.NombreCompleto.toLowerCase();
        const valueLower = searchTerm2.toLowerCase();
        const regex = new RegExp(`\\b${valueLower}`);
        return regex.test(labelLower) && [2, 3].includes(item.idEstatus);
      }
      return [2, 3].includes(item.idEstatus);
    });
  }, [rows, searchTerm2]);

  return (
    <AdminLayout sx={{ overflowX: "hidden" }}>
      <style>
        {`
          .swal2-actions {
            display: flex;
            justify-content: center;
          }

          .custom-cancel-button {
            background-color: gray;
            color: white;
            font-weight: 500;
            border: none;
            padding: 10px 20px;
            margin: 10px;
            cursor: pointer;
            border-radius: 5px;
            width: 180px;
          }

          .custom-cancel-button:hover {
            background-color: darkgray;
          }

          .custom-button {
            background-color: #3085d6;
            color: white;
            font-weight: 500;
            border: none;
            padding: 10px 20px;
            margin: 10px;
            cursor: pointer;
            border-radius: 5px;
          }

          .custom-button:hover {
            background-color: #256ca0;
          }
        `}
      </style>

      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={snackbarOpen}
        autoHideDuration={2000}
        onClose={handleSnackbarClose}
      >
        <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: "100%" }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>

      <ActionHeader title={"Reportar Movilización"} isCustom>
        <Button
          onClick={() => setOpenFilter(!openFilter)}
          aria-label="open Drawer"
          endIcon={openFilter ? <KeyboardDoubleArrowRight /> : <KeyboardDoubleArrowLeft />}
        >
          <Typography sx={{ fontWeight: 500 }}>Filtros</Typography>
        </Button>
      </ActionHeader>

      <Grid
        container
        spacing={openFilter ? 2 : 0}
        sx={{ flexDirection: { xs: "column", md: "row-reverse" } }}
      >
        <Grid item xs={12} md={openFilter ? 4 : 0} lg={openFilter ? 3 : 0}>
          <DrawerRelative
            anchor="right"
            open={openFilter}
            setOpen={setOpenFilter}
            title="Filtrar"
            icon="person_search"
            sxPaper={{ borderRadius: 3 }}
            className="card-primary"
            isSticky
            screenHeight="md"
          >
            <Filter catalogs={catalogs} loadingCatalogs={isLoadingCat} handleFilter={handleFilter} />
          </DrawerRelative>
        </Grid>

        <Grid item xs={12} md={openFilter ? 8 : 0} lg={openFilter ? 9 : 0}>
          <CustomTabs
            value={tabValue}
            setValue={setTabValue}
            tabs={[
              {
                icon: "account_circle",
                label: "Por movilizar",
                value: 0,
                component: (
                  <>
                    <Box sx={{ px: { xs: 0, sm: 2 } }}>
                      <TextField
                        label="Buscar..."
                        variant="outlined"
                        fullWidth
                        size="small"
                        margin="normal"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        disabled={isLoading}
                      />
                    </Box>
                    <CustomTable
                      rows={filteredRows1}
                      columns={columns}
                      stickyHeader
                      maxHeight={height * 0.8}
                      isLoading={isLoading}
                      pageSize={50}
                    />
                  </>
                ),
              },
              {
                icon: "group",
                label: "Movilizados",
                value: 1,
                component: (
                  <>
                    <Box sx={{ px: { xs: 0, sm: 2 } }}>
                      <TextField
                        label="Buscar..."
                        variant="outlined"
                        fullWidth
                        size="small"
                        margin="normal"
                        value={searchTerm2}
                        onChange={(e) => setSearchTerm2(e.target.value)}
                        disabled={isLoading}
                      />
                    </Box>
                    <CustomTable
                      rows={filteredRows2}
                      columns={columnsMov}
                      stickyHeader
                      maxHeight={height * 0.8}
                      isLoading={isLoading}
                      pageSize={50}
                    />
                  </>
                ),
              },
            ]}
          />
        </Grid>
      </Grid>

      {!openFilter && (
        <FloatingButton
          onClick={() => setOpenFilter(true)}
          label="Mostrar Filtros"
          sx={{ display: { xs: "none", md: "block" } }}
        />
      )}
    </AdminLayout>
  );
};

export default AsignStatus;
