import React, { Fragment } from "react";
import {
  IconButton,
  ListItem,
  ListItemButton,
  ListItemAvatar,
  ListItemText,
  Avatar,
  Divider,
} from "@mui/material";
import Icon from "@mui/material/Icon";

const UserListItemInformation = ({
  item,
  selected,
  handleSelectedUserId,
  handleSelectedUserName,
}) => {
  const handleListItemClick = () => {
    // Llama a la función handleSelectedUserId y pasa el id del elemento
    handleSelectedUserId(item.id);
    // Llama a la función handleSelectedUserName y pasa el nombre del elemento
    handleSelectedUserName(item.NombreCompleto);
  };

  return (
    <Fragment key={`item_${item.id}`}>
      <ListItem
        secondaryAction={
          <IconButton
            edge="end"
            aria-label="info"
            onClick={(event) => handleListItemClick(event, item)}
          >
            <Icon sx={{ color: "darkblue" }}>visibility</Icon>
          </IconButton>
        }
        disablePadding
      >
        <ListItemButton
          selected={selected === item.id}
          onClick={(event) => handleListItemClick(event, item)}
        >
          <ListItemAvatar>
            <Avatar>
              <Icon sx={{ fontSize: 40 }}>account_circle</Icon>
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            primary={item.NombreCompleto}
            secondary={
              <div>
                <div>Edad: {item.Edad}</div>
                <div>Municipio: {item.Municipio}</div>
              </div>
            }
          />
        </ListItemButton>
      </ListItem>
      <Divider variant="inset" component="li" />
    </Fragment>
  );
};

export default UserListItemInformation;
