import { useEffect } from "react";
import { useFormik } from "formik";
import { TableRow, TableCell, FormControlLabel, Checkbox, TextField } from "@mui/material";

import { DayScheduleInterface } from "@interfaces/ScheduleInterfaces";
import { DayScheduleSchema } from "@schemas/ScheduleSchemas";


const DaySchedule = (props) => {
    const { day, values, setValues } = props;

    useEffect(() => {
        const day_data = values.Horarios.find(x => x.idDia === day.value);
        if (day_data) {
            formik.setValues(day_data);
        }
        //eslint-disable-next-line
    }, [day]);

    const formik = useFormik({
        initialValues: DayScheduleInterface,
        validationSchema: DayScheduleSchema,
        onSubmit: (values) => {
            // console.log(values);
        }
    });

    const handleCheckboxChange = (event) => {
        const checked = event.target.checked;
        const value = checked ? 1 : 0;

        let schedule_day = formik.values;
        let schedules = values.Horarios.filter(x => x.idDia !== day.value);

        schedule_day.AccesoTotal = value;

        if (value) {
            formik.setValues({
                ...formik.values,
                AccesoTotal: value,
                HoraInicio: '',
                HoraCierre: ''
            });
            schedule_day.HoraInicio = '';
            schedule_day.HoraCierre = '';
        } else {
            formik.setFieldValue('AccesoTotal', value);
        }

        schedules = [...schedules, schedule_day];
        setValues({ ...values, Horarios: schedules });
    }

    const handleChangeStartTime = (e) => {
        formik.handleChange(e);
        const value = e.target.value;

        let schedule_day = formik.values;
        let schedules = values.Horarios.filter(x => x.idDia !== day.value);

        schedule_day.HoraInicio = value;
        schedules = [...schedules, schedule_day];
        const _values = { ...values, Horarios: schedules }
        setValues(_values);
    }

    const handleChangeEndTime = (e) => {
        formik.handleChange(e);
        const value = e.target.value;

        let schedule_day = formik.values;
        let schedules = values.Horarios.filter(x => x.idDia !== day.value);

        schedule_day.HoraCierre = value;
        schedules = [...schedules, schedule_day];
        const _values = { ...values, Horarios: schedules }
        setValues(_values);
    }

    return (
        <TableRow>
            <TableCell>
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={formik.values.AccesoTotal === 1}
                            onChange={(e) => handleCheckboxChange(e)}
                            value={formik.values?.AccesoTotal}
                        />
                    }
                    label={"Todo el día"}
                />
            </TableCell>
            <TableCell>{day.label}</TableCell>
            <TableCell>de</TableCell>
            <TableCell>
                <TextField
                    placeholder="HH:MM"
                    type="text"
                    name="HoraInicio"
                    size="small"
                    value={formik.values.HoraInicio}
                    onChange={(e) => handleChangeStartTime(e)}
                    onBlur={formik.handleBlur}
                    error={formik.values.AccesoTotal === 0 && formik.errors.HoraInicio}
                    helperText={formik.errors.HoraInicio}
                    sx={{ '& input': { textAlign: 'center' }, maxWidth: "67%", minWidth: "130px" }}
                    disabled={formik.values.AccesoTotal === 1}

                />
            </TableCell>
            <TableCell> a </TableCell>
            <TableCell>
                <TextField
                    placeholder="HH:MM"
                    type="text"
                    name="HoraCierre"
                    size="small"
                    value={formik.values.HoraCierre}
                    onChange={(e) => handleChangeEndTime(e)}
                    onBlur={formik.handleBlur}
                    error={formik.values.AccesoTotal === 0 && formik.errors.HoraCierre}
                    helperText={formik.errors.HoraCierre}
                    sx={{ '& input': { textAlign: 'center' }, maxWidth: "67%", minWidth: "130px" }}
                    disabled={formik.values.AccesoTotal === 1}

                />
            </TableCell>
        </TableRow>
    )
}

export default DaySchedule;