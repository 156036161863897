import { useEffect } from "react";

const useScrollToInvalidField = (formik) => {
  useEffect(() => {
    const scrollToInvalidField = () => {
      /* const firstInvalidField = Object.keys(formik.errors)[0];
      const firstErrorField = document.getElementsByName(firstInvalidField)[0]; */
      const firstErrorField = document.querySelector('.Mui-error input');

      if (firstErrorField) {
        firstErrorField.scrollIntoView({ behavior: "smooth", block: "center" });
        firstErrorField.focus();
      }
    };

    if (formik.isSubmitting && !formik.isValid) {
      scrollToInvalidField();
    }
  }, [formik.isSubmitting, formik.isValid, formik.errors]);
};

export default useScrollToInvalidField;
