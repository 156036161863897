import { useEffect, useState, useRef } from "react";

// Charts
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

// Material UI
import { Card, CardContent, Box, CircularProgress } from "@mui/material";
import Swal from "sweetalert2";

// Utilidades
import { numberWithCommas } from "@utils/Utilities/";

// Hooks
import useWindowDimensions from "@hooks/useWindowDimensions";
import certificacion from "@services/CertificationServices";

const PieChart = (props) => {
  const { openDrawer, data } = props;

  const title = "Afinidad de personas certificadas"
  const chartRef = useRef(null);

  const { height, width } = useWindowDimensions();

  const [dataCard, setDataCard] = useState([])

  const [loading, setLoading] = useState(false)


  const cardsTerritorial = async () => {
    setLoading(true)
    try {
      const res = await certificacion.getCardAfinNoAfin({ filtered: data.filtered });

      const { results, message, response } = res;
      if (results) {
        setDataCard([
          {
            data: response.data.data.PorcentajeTotalNoAfin,
            label: "NO AFÍN",
            total: response.data.data.TotalNoAfin,
            totalText: ""
          },
          {
            data: response.data.data.PorcentajeTotalAfin,
            label: "AFÍN",
            total: response.data.data.TotalAfin,
            totalText: ""
          },
          {
            label: "TOTAL",
            total: response.data.data.Total,
            totalText: response.data.data.Total
          },
        ])
        // setRefresh(false);
      } else {
        throw new Error(message);
      }
    } catch (error) {
      Swal.fire({
        title: error.message,
        icon: "warning",
      });
    } finally {
      setLoading(false)
    }
  };

  useEffect(() => {
    cardsTerritorial()
    //eslint-disable-next-line
  }, [data])


  const colors = ["#FF6486", "#37A3EB", "#999999"];

  const seriesData = dataCard.map((item, index) => ({
    name: `<span style="color: ${colors[index]}">${item.label
      } ${numberWithCommas(item.totalText)}</span>`,
    x: item.label,
    y: parseFloat(item.data),
    z: parseFloat(item.total),
    color: colors[index],
    dataLabels: {
      style: {
        fontSize: width > 1440 ? width * 0.009 : "13px",
        color: colors[index],
      },
    },
  }));

  // Opciones del chart
  const options = {
    credits: {
      enabled: false,
    },
    chart: {
      type: "pie",
      height: width > 1440 ? height * 0.4 : "auto",
    },
    title: {
      text: `${title.toUpperCase()}`,
      align: "right",
      style: {
        color: "#999",
        fontSize: width > 1440 ? width * 0.01 : "18px",
      },
    },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        dataLabels: {
          enabled: true,
          format: "{point.percentage:.1f} %", // El porcentaje
          distance: 0.1,
        },
        showInLegend: true,
      },
    },
    legend: {
      itemStyle: {
        fontSize: width > 1440 ? `${width * 0.0085}px` : "12px",
      },
    },
    tooltip: {
      formatter: function () {
        const regex = /<span[^>]*>([^<]+)<\/span>/;
        const coincidencia = this.point.name.match(regex);
        const partes = coincidencia[1].split(" ");
        const resultado =
          partes.length === 3 ? partes[0] + " " + partes[1] : partes[0];
        // Formato personalizado del tooltip
        return `<span style="font-size: ${width > 1440 ? `${width * 0.0085}px` : "12px"
          }"><b>${resultado}  ${this.point.percentage.toFixed(1)} %</b>
      <br/>Total: ${numberWithCommas(this.point.z)}</span>`; // Muestra el nombre y el valor total en el tooltip
      },
    },
    series: [
      {
        type: "pie",
        name: "Total",
        data: seriesData,
      },
    ],
  };

  return (
    <Card className="card-primary" sx={{height: '96%'}}>
      <CardContent CardContent key={`${openDrawer}`}>
        {loading ?
          <Box sx={{ mt:10, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <CircularProgress />
          </Box> :
          <HighchartsReact
            highcharts={Highcharts}
            options={options}
            ref={chartRef}
          />
        }

      </CardContent>
    </Card>
  );
};

export default PieChart;
