import React, { useState, useEffect } from "react";
import {
  Grid
} from "@mui/material";
import swal from "sweetalert2";

import BasicSelect from "@components/Selects/BasicSelect";
import { isEmptyOrInvalidString } from "@utils/validations";
import CatalogService from "@services/CatalogServices";


const TargetForm = (props) => {
  const {
    idAmbito,
    errors,
    touched,
    values,
    handleChange,
    // setValues,
  } = props;

  const [catRegiones, setCatRegiones] = useState([]);
  const [catMunicipalities, setCatMunicipalities] = useState([]);
  const [catMunicipalitiesFilter, setCatMunicipalitiesFilter] = useState([]);
  const [isLoadingCatalogs, setIsLoadingCatalogs] = useState(true);

  //Catalogs
  useEffect(() => {
    let catalogsParams = [
      { "id": "regiones", "getAll": false },
      { "id": "municipios", "getAll": false },
      { "id": "municipios_dl", "getAll": false }
    ];
    CatalogService.getCatalogs(catalogsParams)
      .then((res) => {
        if (res.results) {
          if (res.response.errors.length > 0) {
            swal.fire({
              title:
                "Algunos catálogos pudieron ser cargados. Contacte al administrador",
              icon: "warning",
            });
          }
          catalogsParams.forEach((item) => {
            switch (item.id) {
              case "regiones":
                setCatRegiones(res.response.catalogs[item.id]);
                break;
              case "municipios":
                setCatMunicipalities(res.response.catalogs[item.id]);
                setCatMunicipalitiesFilter(res.response.catalogs[item.id]);
                break;
              default:
                break;
            }
          });
          setIsLoadingCatalogs(false);
        } else {
          swal.fire({ title: res.message, icon: "warning" });
          setIsLoadingCatalogs(false);
        }
      })
      .catch((e) => {
        swal.fire({ title: e.message, icon: "warning" });
        setIsLoadingCatalogs(false);
      });
    // eslint-disable-next-line
  }, []);

  const handleChangeRegion = (e) => {
    let idRegion = e.target.value
    //setValues({ idRegion: idRegion });
    setCatMunicipalitiesFilter(catMunicipalities.filter(item => item.idRegion === idRegion));
  }
  return (
    <>
      {idAmbito === 2 ?
        <Grid item xs={12} sm={12} md={3} lg={3}>
          <BasicSelect
            error={
              touched.idRegion &&
              !isEmptyOrInvalidString(errors.idRegion)
            }
            errorMessage={errors.idRegion}
            name="idRegion"
            label="Region *"
            options={catRegiones}
            value={values.idRegion}
            //onBlur={handleBlur}
            onChange={handleChangeRegion}
            isLoading={isLoadingCatalogs}
            sx={{ width: "100%" }}
          />
        </Grid> :
        idAmbito === 5 ?
          <>
            <Grid item xs={12} sm={12} md={3} lg={3}>
              <BasicSelect
                error={
                  touched.idRegion &&
                  !isEmptyOrInvalidString(errors.idRegion)
                }
                errorMessage={errors.idRegion}
                name="idRegion"
                label="Region *"
                options={catRegiones}
                value={values.idRegion}
                //onBlur={handleBlur}
                onChange={handleChangeRegion}
                isLoading={isLoadingCatalogs}
                sx={{ width: "100%" }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={3} lg={3}>
              <BasicSelect
                error={
                  touched.idMunicipio &&
                  !isEmptyOrInvalidString(errors.idMunicipio)
                }
                errorMessage={errors.idMunicipio}
                name="idMunicipio"
                label="Municipio *"
                options={catMunicipalitiesFilter}
                value={values.idMunicipio}
                //onBlur={handleBlur}
                onChange={handleChange}
                isLoading={isLoadingCatalogs}
                sx={{ width: "100%" }}
              />
            </Grid>
          </>
          :
          <></>
      }
    </>
  );
};

export default TargetForm;
