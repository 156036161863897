import { Box, Typography } from "@mui/material";

const ChipResume = ({ text, textColor = "white", color = "#979797", pY = 1, pX = 2 }) => {
  return (
    <Box
      sx={{
        paddingX: pX,
        paddingY: pY,
        backgroundColor: color,
        borderRadius: "10rem",
      }}
    >
      <Typography variant="h6" color={textColor} align="center" overflow="hidden" textOverflow="ellipsis" whiteSpace="nowrap">
        {text}
      </Typography>
    </Box>
  );
};

export default ChipResume;
