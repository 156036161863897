import { useState, useEffect } from "react";
import Swal from "sweetalert2";

// Material UI
import {
    Box,
    Card,
    CardContent,
    Button,
    Typography,
    Icon,
    Chip
} from "@mui/material";
import ListAltIcon from '@mui/icons-material/ListAlt';
import { green } from '@mui/material/colors';

// Componentes
import CustomTable from "@components/Tables/CustomTable";
import Download from "@components/Downloads/Download";

// Middlewares ,Servicios, Utils
import { setDiynamicColumns } from "@utils/Utilities";
import certificacion from "@services/CertificationServices";

// Constantes
import { COLUMNS_CERTIFICATION } from "@data/constants/Certificacion";

// Hooks
import { useSorted } from "@hooks/useSorted";

import { getVars } from "@utils/global";

const TableCertification = (props) => {
    const { data, setDataHistory, setShowFirstContainer } = props;

    const [localData, setLocalData] = useState(data);

    const [isDownload, setIsDownloading] = useState(false);
    const [loading, setLoading] = useState(false);

    const [orderBy, setOrderBy] = useState("");

    const [total, setTotal] = useState();
    const [summary, setSummary] = useState([]);
    const [columnsDinamics, setColumnsDinamics] = useState([]);
    const [dataMovilization, setDataMovilization] = useState([]);

    const { handleRequestSort } = useSorted({
        total: total,
        setData: setLocalData,
    });

    const [refreshSchedule, setRefreshSchedule] = useState(false);

    const filtered = Object.keys(getVars("filtered")).length === 0 ? [] : getVars("filtered")

    const handleChangePagination = (value) => {
        setLocalData((prev) => ({
            ...prev,
            page: value.page,
            pageSize: value.pageSize,
        }));
    };

    const getMovilization = async (data) => {
        const { idAmbitoAgrupador, sorted, ...params } = data;

        const modifiedSorted = sorted.length > 0 ? sorted : [
            {
                "id": 'TotalPersonasCertificadas',
                "value": "desc"
            }
        ];

        if (modifiedSorted[0].id.startsWith("CONCAT")) {
            modifiedSorted[0].id = "NombreCompleto"
        }

        const updatedParams = {
            ...params,
            sorted: modifiedSorted
        };

        setLoading(true);
        try {
            const res = await certificacion.getCertifiersDetailsList(updatedParams);
            const { results, response, message } = res;
            if (results) {
                setTotal(response.total);

                const { idUsuario, ...summaryWithoutIdUser } = response.totales

                const summaryValues = Object.values(summaryWithoutIdUser);
                setSummary(["", ...summaryValues]);

                const modifiedData = response.data.map((item) => {
                    let tasksChip;

                    tasksChip = <Chip label={item.TotalTareas} style={{ backgroundColor: green[200], color: green[800], fontWeight: 500, width: 60, height: 25 }} />
                    
                    return {
                        ...item,
                        TotalTareasNumber: item.TotalTareas,
                        TotalTareas: tasksChip
                    };
                });

                handleMobilizationData({ data: modifiedData });
            } else {
                setTotal(0);
                setSummary([]);
                setColumnsDinamics([]);
                setDataMovilization([]);
                throw new Error(message);
            }
        } catch (error) {
            Swal.fire({ title: error.message, icon: "warning" });
        } finally {
            setLoading(false);
        }
    };


    useEffect(() => {
        setLocalData({ ...data, filtered: filtered ?? [], sorted: localData.sorted })
        // eslint-disable-next-line
    }, [data]);

    useEffect(() => {
        if (localData.pageSize) getMovilization(localData);

        if (localData.sorted.length) {
            setOrderBy(localData.sorted[0].id);
        } else setOrderBy("");
        //eslint-disable-next-line
    }, [localData]);

    const handleMobilizationData = ({ data }) => {
        const dataWithHighlight = data.map((item) => {
            return item;
        });

        const columns = setDiynamicColumns({
            data: dataWithHighlight[0],
            columns: [
                ...COLUMNS_CERTIFICATION
            ],
        });

        const columnas = [
            {
                id: "Button",
                label: "TOTAL TAREAS CERTIFICADAS",
                type: "iconButton",
                sort: false,
                align: 'center',
                onClick: (a, b, c) => {
                    setShowFirstContainer(false)
                    setDataHistory(c)
                },
            },
            ...columns,
        ];

        setColumnsDinamics(columnas);
        setDataMovilization(
            dataWithHighlight.map((item) => ({ Button: item.TotalTareasNumber > 0 ? <ListAltIcon /> : null, ...item }))
        );
    };

    const exportMovAdvanceTerritorialDetails = async () => {
        setIsDownloading(true);
        const { page, pageSize, idAmbitoAgrupador, ...params } = localData;

        try {
            const res = await certificacion.exportCertifiersDetailsList(params);
            const { results, message } = res;

            if (!results) {
                throw new Error(message);
            }
        } catch (error) {
            Swal.fire({
                title: error.message,
                icon: "warning",
            });
        } finally {
            setIsDownloading(false);
        }
    };

    useEffect(() => {
        if (refreshSchedule) {
            setRefreshSchedule(false);
        }
        // eslint-disable-next-line
    }, [refreshSchedule]);

    return (
        <>
            <Card className="card-primary" sx={{ marginBottom: "0px!important" }}>
                <CardContent>

                    <Typography variant="body2" sx={{ mb: 2 }} fontWeight={700}>
                        CERTIFICADORES
                    </Typography>

                    {isDownload && (
                        <Box marginBottom={2}>
                            <Download format={"xlsx"} isDownload={isDownload} />
                        </Box>
                    )}

                    <Box
                        display={'flex'}
                        justifyContent={"end"}
                        alignItems={"center"}
                        marginBottom={2}
                    >
                        {/* {middleware.checkMenuAction("Exportar") && ( */}
                        <Button
                            variant="outlined"
                            color="primaryDark"
                            startIcon={<Icon>download</Icon>}
                            size="small"
                            disabled={isDownload}
                            onClick={exportMovAdvanceTerritorialDetails}
                        >
                            Exportar
                        </Button>
                    </Box>

                    <CustomTable
                        rows={dataMovilization}
                        columns={columnsDinamics}
                        total={total}
                        orderBy={orderBy}
                        page={localData.page}
                        pageSize={localData.pageSize}
                        isLoading={loading}
                        handlePagination={handleChangePagination}
                        handleSort={handleRequestSort}
                        summary={summary}
                        summarySettings={{ start: 0, title: "Totales: ", align: "center" }}
                        disableCardType
                    />
                </CardContent>
            </Card>
        </>
    );
};

export default TableCertification;
