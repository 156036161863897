import { getColorReverse } from "@utils/Utilities";

export const COLUMNS_MOVILIZADORES = [
  { id: "Region", label: "Región" },
  { id: "DistritoFederal", label: "Distrito Federal" },
  { id: "DistritoLocal", label: "Distrito Local" },
  { id: "Municipio", label: "Municipio" },
  { id: "Seccion", label: "Sección" },
  { id: "Movilizadores", label: "Movilizadores" },
  { id: "PersonasMovilizar", label: "Personas a Movilizar" },
  { id: "PersonasDisponibles", label: "Personas Disponibles" },
];

export const COLUMNS_MOVILIZADOS = [
  { id: "Region", label: "Región", type: 'text'  },
  { id: "Ambito", label: "Ámbito", type: 'text'  },
  { id: "TotalSecciones", label: "Total Secciones", type: 'number' },
  { id: "Afines", label: "Afines",  type: 'number'  },
  { id: "VotantesAfines", label: "Votantes Afines", type: "number"  },
  { id: "TotalMovilizados", label: "Total Movilizados", type: "number"  },
  { id: "NoSimpatizantes", label: "Voto Ciudadano", type: "number"  },
  { id: "TotalVotantes", label: "Total Votantes", type: "number"  },
  { id: "PorcentajeAfines", label: "% Voto Afines",  align: "center", type: "circular", fixed: 2,  },
  { id: "PorcentajeMovilizados", label: "% Movilizados",  align: "center", type: "circular", fixed: 2,  },
  { id: "PorcentajeNoSimpatizantes", label: "% Voto Ciudadano",  align: "center", type: "circular", fixed: 2, customColors: getColorReverse },
];