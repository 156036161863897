import ApiExec, { downloadFile } from "@utils/ApiExec";

class MovilizadoresServices {
  downloadTemplate = () => {
    return downloadFile({
      api: "mobilizer/download-template",
      method: "POST",
      data: {},
    })
      .then((res) => res)
      .catch((error) => error);
  };

  importMovilizadores = (params) => {
    return ApiExec(params, "mobilizer/import", "POST")
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };

  getMovilizadores = (params) => {
    return ApiExec(params, "mobilizer/get-table", "POST")
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };

  getMovilizadoresDetalle  = (params) => {
    return ApiExec(params, "mobilizer/get-table-detalle", "POST")
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };

  getTableDetalleGral  = (params) => {
    return ApiExec(params, "mobilizer/get-table-gral", "POST")
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };
}

const movilizadores = new MovilizadoresServices();
export default movilizadores;
