
import React, { useState, useEffect } from "react";

//Mui
import {
  IconButton,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Avatar,
  TextField,
  Box,
  Typography,
  CircularProgress,
  Tooltip,
  Divider,
  Modal,
  Card,
  Grid,
  Button,
  Stack,
  Skeleton
} from "@mui/material";
import { red, green, yellow, orange } from '@mui/material/colors';
import { useMediaQuery } from '@mui/material';

//Icons
import CloseIcon from '@mui/icons-material/Close';
import Icon from "@mui/material/Icon";
import {
  WhatsApp as WhatsAppIcon,
} from "@mui/icons-material";
import LocationOnIcon from '@mui/icons-material/LocationOn';
import Swal from "sweetalert2";

// Services
import certificacion from "@services/CertificationServices";

function UserSearchHistoryModal({ openModalS, handleCloseModalS, dataTasks, resetApi }) {

  const [isLoading, setIsLoading] = useState(false);

  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);

  const [buttonPhoto, setButtonPhoto] = useState(false);
  const [selectedItemName, setSelectedItemName] = useState("");
  const [selectedItemId, setSelectedItemId] = useState(null);

  const [generatedImage, setGeneratedImage] = useState("");
  const [loadingSkeleton, setLoadingSkeleton] = useState(false);

  const getListResponsibles = async () => {
    setIsLoading(true);

    try {
      const params = {
        "idTarea": dataTasks.idTarea
      }
      const res = await certificacion.getTasksPersons(params);
      const { results, message, response } = res;

      if (results) {
        setData(response.data);
        setFilteredData(response.data);
      } else {
        setFilteredData([]);
        throw new Error(message);
      }
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  const photo = async () => {
    setLoadingSkeleton(true);

    try {
      const params = {
        "id": selectedItemId
      };
      const res = await certificacion.getPictures(params);
      const { response, results, message } = res;
      if (results) {
        const imageUrl = response.data.Imagen[0].RutaFoto;
        setGeneratedImage(imageUrl);
      } else {
        throw new Error(message);
      }
    } catch (error) {
      Swal.fire({
        title: error.message,
        icon: "warning",
        customClass: {
          container: "modal-alert"
        }
      });
    } finally {
      setLoadingSkeleton(false);

    }
  };

  useEffect(() => {
    if (selectedItemId) photo();
    // eslint-disable-next-line
  }, [selectedItemId]);

  useEffect(() => {
    if (resetApi) getListResponsibles();
    // eslint-disable-next-line
  }, [resetApi]);

  const handleSearch = (event) => {
    setButtonPhoto(false)
    setSelectedItemId(null)
    setSelectedItemName("")

    const keyword = event.target.value.toLowerCase();
    const filteredResults = data.filter((item) =>
      item.NombreCompleto.toLowerCase().includes(keyword)
    );
    setFilteredData(filteredResults);
  };

  const redirectToWhatsApp = (phoneNumber) => {
    const whatsappUrl = `https://wa.me/${phoneNumber}`;
    window.open(whatsappUrl, "_blank");
  };

  const redirectToGoogleMaps = (colonia, calleNum) => {
    const address = encodeURIComponent(`${colonia}, ${calleNum}`);
    const mapsUrl = `https://www.google.com/maps/search/?api=1&query=${address}`;
    window.open(mapsUrl, "_blank");
  };

  const handleCloseModal = () => {
    setButtonPhoto(false);
    setSelectedItemId(null)
    setSelectedItemName("")
    handleCloseModalS();
  };

  const isScreenSmallMD = useMediaQuery((theme) => theme.breakpoints.down('md'));

  return (
    <Modal
      open={openModalS}
      onClose={handleCloseModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        height: '100%'
      }}
    >
      <Card sx={{ width: buttonPhoto ? '90%' : 750, height: '92%', bgcolor: 'background.paper', boxShadow: 24, p: 2, borderRadius: 3, overflowY: isScreenSmallMD ? 'auto' : 'hidden' }}>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
          <IconButton onClick={handleCloseModal} size="small" sx={{
            '&:hover': {
              color: '#ff0000',
            }
          }}>
            <CloseIcon />
          </IconButton>
        </Box>

        <Box>
          <TextField
            label="Buscar por nombre"
            variant="outlined"
            onChange={handleSearch}
            fullWidth
            style={{ marginBottom: 30, marginTop: 4 }}
          />

          {isLoading ? (
            <Box
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: 20
              }}
            >
              <CircularProgress />
            </Box>
          ) : (
            <Grid
              container
              spacing={2}
              sx={{mt:1}}
            >
              <Stack
                direction={isScreenSmallMD ? "column-reverse" : "row"} // Usamos la condición aquí
                alignItems="center"
                divider={<Divider orientation="vertical" flexItem />}
                spacing={1}
                sx={{ width: "100%", height: "100%" }}
              >
                <Grid item xs={12} md={!buttonPhoto ? 12 : 7}>
                  <Box sx={{ maxHeight: 450, overflowY: 'auto' }}>

                    {filteredData.length === 0 && (
                      <Box style={{ textAlign: "center", mt: 20 }}>
                        <Typography variant="body1">¡No se encontraron resultados!</Typography>
                        <Icon style={{ fontSize: 40, color: "black" }}>error</Icon>
                      </Box>
                    )}

                    {filteredData.map((item, index) => (
                      <>
                        <ListItem
                          sx={{ maxWidth: 400, ml: buttonPhoto ? 5 : 2 }}
                          key={index}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            padding: 2
                          }}
                        >
                          <Tooltip title={"Da click para ver su foto"} arrow >
                            <ListItemAvatar>
                              {item.TieneFoto === 1 ?
                                <Avatar sx={{
                                  "&:hover": { backgroundColor: "primaryDark.main", cursor: "pointer" },
                                }} onClick={() => {
                                  setSelectedItemName(item.NombreCompleto);
                                  setSelectedItemId(item.id)
                                  setButtonPhoto(true);
                                }}>
                                  <Icon sx={{ fontSize: 24 }}>photo_camera</Icon>
                                </Avatar>
                                :
                                <></>
                              }
                            </ListItemAvatar>
                          </Tooltip>
                          <ListItemText
                            style={{ minWidth: "100%" }}
                            primary={item.NombreCompleto}
                            secondary={"Sección: " + item.Seccion}
                          />
                          <ListItemText
                            style={{
                              minWidth: "40%",
                              backgroundColor:
                                item.EstatusPersona === "No vive en el domicilio" ? orange[200] :
                                  item.EstatusPersona === "Afin" ? green[200] :
                                    item.EstatusPersona === "En Proceso" ? yellow[200] :
                                      item.EstatusPersona === "Domicilio no encontrado" ? orange[200] :
                                        item.EstatusPersona === "Persona no encontrada" ? orange[200] :
                                          item.EstatusPersona === "No Afin" ? "#f9a8a8" : "",
                              borderRadius: "5px",
                              padding: "4px",
                              textAlign: "center",
                            }}
                            secondary={
                              <Typography
                                style={{
                                  color:
                                    item.EstatusPersona === "No vive en el domicilio" ? orange[800] :
                                      item.EstatusPersona === "Afin" ? green[800] :
                                        item.EstatusPersona === "En Proceso" ? yellow[900] :
                                          item.EstatusPersona === "Domicilio no encontrado" ? orange[800] :
                                            item.EstatusPersona === "Persona no encontrada" ? orange[800] :
                                              item.EstatusPersona === "No Afin" ? red[700] : "",
                                  fontSize: "12.4px",
                                  fontWeight: "700",
                                }}
                              >
                                {item.EstatusPersona}
                              </Typography>
                            }
                          />
                          {item.Celular && (
                            <Tooltip title={
                              <Typography sx={{ fontSize: '12px' }}>WhatsApp: {item.Celular ?? ""}</Typography>
                            } arrow>
                              <IconButton
                                edge="end"
                                aria-label="whatsapp"
                                onClick={() => redirectToWhatsApp(item.Celular)}
                                sx={{
                                  "&:hover": {
                                    color: "rgb(0, 65, 160, .9)"
                                  },
                                  marginLeft: 2
                                }}
                              >
                                <WhatsAppIcon sx={{ fontSize: 24 }} />
                              </IconButton>
                            </Tooltip>
                          )}

                          {(item.CalleNum || item.Colonia) && (
                            <Tooltip
                              title={
                                <>
                                  <Typography sx={{ fontSize: '12px' }}>Colonia: {item.Colonia ?? ""}</Typography>
                                  <br />
                                  <Typography sx={{ fontSize: '12px' }}>Calle: {item.CalleNum ?? ""}</Typography>
                                </>
                              }
                              arrow
                            >

                              <IconButton
                                edge="end"
                                aria-label="mensajes"
                                onClick={() => redirectToGoogleMaps(item.Colonia, item.CalleNum)}
                                sx={{
                                  "&:hover": {
                                    color: "rgb(0, 65, 160, .9)"
                                  },
                                  marginLeft: 2
                                }}
                              >
                                <LocationOnIcon sx={{ fontSize: 24 }} />
                              </IconButton>
                            </Tooltip>
                          )}

                        </ListItem>
                        <Divider />
                      </>
                    ))}
                  </Box>
                </Grid>

                {buttonPhoto ? (

                  <Grid item xs={12} sm={5}>
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        width: '80%',
                        height: '80%',
                        mt: { xs: 0, md: -2 },
                        ml: 6,
                      }}
                    >
                      <Typography fontWeight={600} mb={1}>{selectedItemName}</Typography>

                      <Box sx={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        {loadingSkeleton ? (
                          <Skeleton variant="rectangular" animation="wave" sx={{ width: { xs: 200, md: 250, lg: 320 }, height: { xs: 200, md: 250, lg: 320 } }} />
                        ) : (
                          <img
                            src={generatedImage}
                            alt="Foto"
                            style={{ width: '70%', height: '70%', objectFit: 'cover' }}
                          />
                        )}
                      </Box>
                      <Button variant="contained" sx={{ mt: 2, width: '70%' }} color="primaryDark" onClick={() => {
                        setSelectedItemId(null)
                        setSelectedItemName("")
                        setButtonPhoto(false)
                      }}>
                        CERRAR
                      </Button>
                    </Box>
                  </Grid>
                ) : null}
              </Stack>

            </Grid>
          )}
        </Box>
      </Card>
    </Modal>
  );
}

export default UserSearchHistoryModal;

