import React, { useEffect, useState } from "react";
import { Icon, IconButton, TextField, Typography } from "@mui/material";

import BasicSelect from "@components/Selects/BasicSelect";

import { useMobileServices } from "@hooks/MobileDiaD/MobileServices";
import { getVars, setVars } from "@utils/global";

const FormGeneral = ({
  showFuncionario = true,
  values,
  setValues,
  flagStorage,
}) => {
  const { loadingCat, getCasillasBySeccion, getCasillasFuncionarios } =
    useMobileServices();

  const [catCasillas, setCatCasillas] = useState([]);
  const [catFuncionarios, setCatFuncionarios] = useState([]);

  const handleChangeValue = (event) => {
    const value = event.target.value;
    setValues({
      ...values,
      [event.target.name]: value,
    });
  };

  const handleGetCasillasBySeccion = async (seccion) => {
    const response = await getCasillasBySeccion({ Seccion: seccion });
    if (response) {
      setCatCasillas(
        response.data.map((item) => ({
          value: item.idCasilla,
          label: item.NombreCasilla,
        }))
      );
    } else {
      setCatCasillas([]);
      setCatFuncionarios([]);
    }
  };

  const handleGetCasillasFuncionarios = async (idCasilla, seccion) => {
    if (showFuncionario) {
      const response = await getCasillasFuncionarios({
        Seccion: seccion,
        idCasilla,
      });

      if (response) setCatFuncionarios(response);
      else setCatFuncionarios([]);
    }
  };

  useEffect(() => {
    if (flagStorage) {
      setVars("formGeneral", values);
    }
  }, [flagStorage]);

  useEffect(() => {
    const formGeneral = getVars("formGeneral", "");
    if (formGeneral) {
      setValues(formGeneral);
      handleGetCasillasBySeccion(formGeneral.seccion);
      handleGetCasillasFuncionarios(formGeneral.idCasilla, formGeneral.seccion);
    }
  }, []);

  return (
    <>
      {!values.seccion && (
        <Typography variant="body2">
          {showFuncionario
            ? "Ingresa la sección y haz clic en el ícono de lupa para buscar las casillas. Después, selecciona la casilla para encontrar a los representantes."
            : "Ingresa la sección y haz clic en el ícono de lupa para buscar las casillas."}
        </Typography>
      )}
      <TextField
        name="seccion"
        label="Sección"
        fullWidth
        value={values.seccion}
        onChange={(event) => handleChangeValue(event)}
        disabled={loadingCat.secciones}
        InputProps={{
          endAdornment: (
            <IconButton
              onClick={() => handleGetCasillasBySeccion(values.seccion)}
              disabled={!values.seccion}
            >
              <Icon>search</Icon>
            </IconButton>
          ),
        }}
        inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
      />
      <BasicSelect
        name="idCasilla"
        label="Casilla"
        options={catCasillas}
        value={values.idCasilla}
        onChange={(event) => {
          handleChangeValue(event);
          handleGetCasillasFuncionarios(event.target.value, values.seccion);
        }}
        size="small"
        sx={{ width: "100%" }}
        isLoading={loadingCat.secciones}
      />

      {showFuncionario && (
        <BasicSelect
          name="idTipoPropietario"
          label="Representante"
          options={catFuncionarios}
          value={values.idTipoPropietario}
          onChange={(event) => handleChangeValue(event)}
          size="small"
          sx={{ width: "100%" }}
          isLoading={loadingCat.funcionarios}
        />
      )}
    </>
  );
};

export default FormGeneral;
