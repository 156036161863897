import { useState, useContext, useEffect } from "react";
import { useFormik } from "formik";
import {
    Box,
    FormHelperText
} from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2";

import BasicSelect from "@components/Selects/BasicSelect";
import FormContext from './FormContext'
import { AddResponsibilityInterface } from "@interfaces/VolunteerInterfaces";
import { AddResponsibilitySchema } from "@schemas/VolunteerSchemas";
import {
    AMBITO_ESTATAL,
    AMBITO_CAPTURA
} from "@data/constants";
import { SelectFunction, FormButtons } from "./FormComponents";
import { changeResponsibility, changeScope } from "./GenericFunctions";

const GenericForm = (props) => {
    const { formType, handleSubmit, assigned } = props;
    const {
        functionLabel,
        catalogs,
        volunteer,
        catResponsibilities,
        userOwner
    } = useContext(FormContext);

    const formik = useFormik({
        initialValues: {
            ...AddResponsibilityInterface,
            idUsurioAutorizo: userOwner.value,
            idCompromisoUnico: volunteer.id,
        },
        validationSchema: AddResponsibilitySchema,
        onSubmit: (values) => {
            const params = {
                ...values,
                idUsurioAutorizo: userOwner.value,
                idCompromisoUnico: volunteer.id,
            };
            handleSubmit(params);
        },
    });

    const [scopeLabel, setScopeLabel] = useState("");
    const [catScope, setCatScope] = useState([]);
    const [btnDisabled] = useState(false);

    useEffect(() => {
        if (formType) {
            handleClearForm();
        }
        //eslint-disable-next-line
    }, [formType]);

    useEffect(() => {
        if (assigned.length > 0) {
            handleClearForm();
        }
        //eslint-disable-next-line
    }, [assigned]);

    const handleChangeResponsibility = async (value) => {
        const profileInfo = catResponsibilities.find(item => item.value === value);

        try {
            const { success, data } = await changeResponsibility({
                value,
                catalogs,
                profileInfo,
                values: formik.values
            });

            if (success) {
                formik.setValues(data.newValues);
                setCatScope(data.catScope);
                setScopeLabel(data.scopeLabel)
            } else {
                setCatScope([]);
            }
        } catch (error) {
            setCatScope([]);
        }
    }

    const handleChangeScope = async (value) => {
        const profileInfo = catResponsibilities.find(item => item.value === formik.values.idPerfil);

        try {
            const { success, data } = await changeScope({
                value,
                catalogs,
                profileInfo,
                values: formik.values,
                catScope
            });

            if (success) {
                formik.setValues(data);
            }
        } catch (error) {

        }
    }

    const handleClearForm = () => {
        formik.setValues({
            idUsurioAutorizo: userOwner.value,
            idCompromisoUnico: volunteer.id,
            idPerfil: "",
            idAmbitoCargo: "",
            AmbitoValorCargo: "",
            ResponsabilidadEtiqueta: "",
            Prioridad: "",
            idPropietario: "",
        });
    };

    return (
        <Box>
            <SelectFunction
                label={functionLabel}
                values={formik.values}
                touched={formik.touched}
                handleBlur={formik.handleBlur}
                errors={formik.errors}
                options={catResponsibilities}
                handleChange={handleChangeResponsibility}
            />

            {formik.values.idAmbitoCargo &&
                ![AMBITO_ESTATAL, AMBITO_CAPTURA].includes(formik.values.idAmbitoCargo) && (
                    <Box>
                        <Grid2 container spacing={2}>
                            <Grid2 item xs={12} sm={12} md={6} lg={6}>
                                <BasicSelect
                                    label={scopeLabel}
                                    options={catScope}
                                    name="AmbitoValorCargo"
                                    value={formik.values.AmbitoValorCargo}
                                    onChange={(e) => handleChangeScope(e.target.value)}
                                    onBlur={formik.handleBlur}
                                    size="small"
                                    sx={{ width: "100%" }}
                                />
                                <FormHelperText error>
                                    {formik.errors.AmbitoValorCargo &&
                                        formik.errors.AmbitoValorCargo.replace(
                                            "AmbitoValorCargo",
                                            scopeLabel
                                        )}
                                </FormHelperText>
                            </Grid2>
                        </Grid2>
                    </Box>
                )}
            <FormButtons
                submitForm={formik.submitForm}
                handleClearForm={handleClearForm}
                btnDisabled={btnDisabled}
            />
        </Box>
    )
}

export default GenericForm;