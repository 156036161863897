import { useEffect, useState } from "react";

// Material UI
import { Card, CardContent, Box, Button, Grid, Icon } from "@mui/material";
import FilterListIcon from "@mui/icons-material/FilterList";
import Swal from "sweetalert2";

// Servicios
import Auditoria from "@services/AuditServices";

// Componentes
import InputSelect from "@components/Selects/BasicSelect";

// Middleware
import middleware from "@middlewares/middleware";

const FilterTable = (props) => {
  const { selectedOption, setSelectedOption, dataTable, handleExport } = props;
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState("");
  const canExport = middleware.checkMenuAction("Exportar");

  // Llamada a la API
  const ApiResponse = async () => {
    setLoading(true);
    try {
      const result = await Auditoria.getCatScopeReport();
      const { results, message, response } = result;
      if (results) {
        setSelectedOption(response.data[0] ? response.data[0].value : "-1");
        setData(response.data);
        setLoading(false);
      } else {
        Swal.fire({
          title: message,
          icon: "warning",
        });
      }
    } catch (errors) {
      Swal.fire({
        title: errors.message,
        icon: "warning",
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    ApiResponse();
  }, []);

  // Cambiar seleccion al momento
  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  return (
    <>
      <Card elevation={0}>
        <CardContent sx={{ paddingX: { xs: 0, sm: 2 } }}>
          <Box display="flex" flexDirection="column">
            <Box display="flex" alignItems="center" mb={2}>
              <FilterListIcon sx={{ marginRight: 1 }} />
              <span>Filtro</span>
            </Box>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={12} sm={8} md={6}>
                <InputSelect
                  label="Tipo de agrupación"
                  name="tipoAgrupacion"
                  value={selectedOption}
                  options={data}
                  onChange={handleOptionChange}
                  sx={{ width: "100%" }}
                  isLoading={loading}
                />
              </Grid>

              {canExport && (
                <Grid
                  item
                  xs={12}
                  sm={4}
                  md={6}
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <Button
                    variant="overload"
                    startIcon={
                      <Icon sx={{ color: dataTable ? "blue" : "gray" }}>
                        download
                      </Icon>
                    }
                    disabled={dataTable ? false : true}
                    sx={{
                      backgroundColor: "white",
                      color: "blue",
                      border: dataTable ? "1px solid blue" : "1px solid gray",
                    }}
                    color="primaryDark"
                    onClick={dataTable ? handleExport : () => {}}
                  >
                    Exportar
                  </Button>
                </Grid>
              )}
            </Grid>
          </Box>
        </CardContent>
      </Card>
    </>
  );
};

export default FilterTable;
