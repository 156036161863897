import React, { useState, useEffect } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { CircularProgress } from "@mui/material";

const PieChart = (props) => {
  const { series, options, loading, pieColors, height="100%",distance=25 } = props;
  const [data, setData] = useState(null);

  useEffect(() => {
    setData({
      chart: {
        type: "pie",
        height: height, 
      },
      title: {
        text: options.title,
      },
      subtitle: {
        text: options.subtitle,
      },
      accessibility: {
        point: {
          valueSuffix: "%",
        },
      },

      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: "pointer",
          colors: pieColors,
          dataLabels: {
            enabled: true,
            format: "{point.percentage:.1f} %",
            distance: distance,
            style: {
              fontSize: "16px"
            },
          },
          showInLegend: true,
        },
      },

      tooltip: {
        headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
        pointFormat:
          '<span style="color:{point.color}">{point.name}</span>: <b>{point.y:.1f}</b> del <b>{point.total}</b> total<br/>',
      },
      series: series,
      legend: {
        layout: 'vertical',
        align: 'right',
        verticalAlign: 'top',
        x: -40,
        y: 80,
        floating: true,
        borderWidth: 1,
        backgroundColor:
          Highcharts.defaultOptions.legend.backgroundColor || '#FFFFFF',
        shadow: true
      },
    });

    // eslint-disable-next-line
  }, [series, options, loading]);

  const chartContainerStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: height-30 + "px",
    // Puedes agregar estilos adicionales aquí si es necesario
  };


  return data !== null || loading ? (
    <div style={chartContainerStyle}>
      <HighchartsReact highcharts={Highcharts} options={data} />
    </div>
  ) : (
    <CircularProgress />
  );
};

export default PieChart;
