import { useEffect, useState } from "react";
import { useCatalogs } from "@hooks/useCatalogs";

import Select from "react-select";
import makeAnimated from "react-select/animated";
import PropTypes from "prop-types";
import Swal from "sweetalert2";

//Components
import InputSelect from "@components/Selects/BasicSelect";

//Mui
import {
   Box,
   Button,
   Icon,
   Stack,
   Typography
} from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";

import { getVars } from "@utils/global";
import prep from "@services/PrepServices";
import {
   AMBITO_ESTATAL,
   AMBITO_DF,
   AMBITO_DL,
   AMBITO_MPAL,
   AMBITO_MPIO_DL,
   AMBITO_CASILLA
} from "@data/constants";
import {
   ELECCION_DIPUTADO_FEDERAL,
   ELECCION_DIPUTADO_LOCAL,
   ELECCION_PRESIDENTE_MUNICIPAL
} from "@data/constants/Prep";


const Filter = props => {
   const { onChangeFilter, disabledButtons } = props;
   const { user } = getVars('Token');
   const catalogsOptions = [
      { id: "df", getAll: false },
      { id: "dl", getAll: false },
      { id: "municipios", getAll: false },
      { id: "municipios_dl", getAll: false },
      { id: "secciones", getAll: false },
      { id: 'tipo_eleccion' },
   ];

   const { catalogs, load } = useCatalogs({
      catalogsOptions,
      putDefaultOption: false,
   });

   const animatedComponents = makeAnimated();
   const selectStyles = {
      menuPortal: (base) => ({ ...base, zIndex: 9999 }),
      menu: (provided) => ({ ...provided, zIndex: "9999 !important" }),
   };
   const [defaultOptions, setDefaultOptions] = useState([]);
   const [loadingCatalogs, setLoadingCatalogs] = useState(true);
   const [catalog, setCatalog] = useState(() => {
      let newObject = { ambito_agrupador: [] };
      for (const item of catalogsOptions) {
         newObject[item.id] = [];
      }
      return newObject;
   });
   const [catalogsFiltered, setCatalogsFiltered] = useState(() => {
      let newObject = { ambito_agrupador: [] };
      for (const item of catalogsOptions) {
         newObject[item.id] = [];
      }
      return newObject;
   });
   const [filterData, setFilterData] = useState({
      idTipoEleccion: '',
      idAmbitoAgrupador: '',
      ValorAmbito: '',
      Seccion: []
   });
   const [initFilter, setInitFilter] = useState({});
   const [catScope, setCatScope] = useState([{
      value: '', label: 'Seleccione una opción'
   }]);
   const [selected, setSelected] = useState([]);

   useEffect(() => {
      setDefaultOptions(
         // user.ambito_perfil.some(item => item.idAmbito === AMBITO_ESTATAL) ?
         [{ value: 0, label: 'TODOS' }]
         // :
         // []
      )
      //eslint-disable-next-line
   }, []);

   const setCatalogs = async (data) => {
      try {
         setLoadingCatalogs(true);
         const result = await prep.getElectionScope({});
         const { results, response, message } = await result;

         if (results) {
            setCatalog({ ...data, ambito_agrupador: response.data });
            setCatalogsFiltered({ ...data, ambito_agrupador: response.data });
         } else {
            Swal.fire({
               title: message,
               icon: "warning",
            });
         }
      } catch (error) {
         Swal.fire({
            title: error,
            icon: "warning",
         });
      } finally {
         setLoadingCatalogs(false);
      }
   }

   useEffect(() => {
      if (!load) {
         setCatalogs(catalogs);
      }
      //eslint-disable-next-line
   }, [load]);

   useEffect(() => {
      if (catalog.tipo_eleccion.length > 0 && catalog.ambito_agrupador.length > 0) {
         handleSetInitFilter(catalog.tipo_eleccion[0].value);
      }
      //eslint-disable-next-line
   }, [catalog]);

   const handleSetInitFilter = (idTipoEleccion) => {
      let grouper = getDefaultGrouperFromElection(idTipoEleccion);
      let scope = getScopeFromElection(idTipoEleccion);
      setCatalogsFiltered((prevState) => ({
         ...prevState,
         ambito_agrupador:
            catalog.ambito_agrupador.filter(item => item.idTipoEleccion === idTipoEleccion)
      }));

      setSelected([]);

      setFilterData({
         idTipoEleccion: idTipoEleccion,
         idAmbitoAgrupador: grouper,
         ValorAmbito: scope,
         Seccion: []
      });

      if (grouper && scope !== '') {
         onChangeFilter({
            idTipoEleccion: idTipoEleccion,
            idAmbitoAgrupador: grouper,
            ValorAmbito: scope,
            Seccion: []
         })
      }

      setInitFilter({
         idTipoEleccion: idTipoEleccion,
         idAmbitoAgrupador: grouper,
         ValorAmbito: scope,
         Seccion: []
      });

   }

   const getDefaultGrouperFromElection = (idTipoEleccion) => {
      let grouper = { value: -1, label: 'Sin seleccionar' };

      switch (idTipoEleccion) {
         case ELECCION_DIPUTADO_FEDERAL:
            setCatalogsFiltered((prevState) => ({
               ...prevState,
               ambito_agrupador:
                  catalog.ambito_agrupador.filter(item => item.idTipoEleccion === idTipoEleccion)
            }));
            grouper = catalog.ambito_agrupador.find((item) => item.value === AMBITO_DF);
            break;
         case ELECCION_DIPUTADO_LOCAL:
            setCatalogsFiltered((prevState) => ({
               ...prevState,
               ambito_agrupador:
                  catalog.ambito_agrupador.filter(item => item.idTipoEleccion === idTipoEleccion)
            }));
            grouper = catalog.ambito_agrupador.find((item) => item.value === AMBITO_DL);
            break;
         case ELECCION_PRESIDENTE_MUNICIPAL:
            setCatalogsFiltered((prevState) => ({
               ...prevState,
               ambito_agrupador:
                  catalog.ambito_agrupador.filter(item => item.idTipoEleccion === idTipoEleccion)
            }));
            grouper = catalog.ambito_agrupador.find((item) => item.value === AMBITO_MPAL);
            break;
         default:
            setCatalogsFiltered((prevState) => ({ ...prevState, ambito_agrupador: catalog.ambito_agrupador }));
            grouper = catalog.ambito_agrupador[0];
            break;
      }

      return grouper?.value || '';
   }

   const getScopeFromElection = (tipoEleccion) => {
      let scope = { value: -1, label: 'Sin seleccionar' };

      switch (tipoEleccion) {
         case ELECCION_DIPUTADO_FEDERAL:
            scope = defaultOptions.length > 0 ? defaultOptions[0] : catalog.df[0];
            setCatScope(defaultOptions.concat(catalog.df));
            setCatalogsFiltered((prevState) => ({
               ...prevState,
               secciones: scope.value > 0 ?
                  defaultOptions.concat(catalog.secciones.filter(item => item.idDF === scope.value)) :
                  defaultOptions.concat(catalog.secciones)
            }));
            break;
         case ELECCION_DIPUTADO_LOCAL:
            scope = defaultOptions.length > 0 ? defaultOptions[0] : catalog.dl[0];
            setCatScope(defaultOptions.concat(catalog.dl));
            setCatalogsFiltered((prevState) => ({
               ...prevState,
               secciones: scope.value > 0 ?
                  defaultOptions.concat(catalog.secciones.filter(item => item.idDL === scope.value)) :
                  defaultOptions.concat(catalog.secciones)
            }));
            break;
         case ELECCION_PRESIDENTE_MUNICIPAL:
            scope = defaultOptions.length > 0 ? defaultOptions[0] : catalog.municipios[0];
            setCatScope(defaultOptions.concat(catalog.municipios));
            setCatalogsFiltered((prevState) => ({
               ...prevState,
               secciones: scope.value > 0 ?
                  defaultOptions.concat(catalog.secciones.filter(item => item.idMunicipio === scope.value)) :
                  defaultOptions.concat(catalog.secciones)
            }));
            break;
         default:
            // scope = { value: 0, label: 'TODOS' };
            if (user.ambito_perfil.some(item => item.idAmbito === AMBITO_MPIO_DL)) {
               setCatScope(defaultOptions.concat(catalog.municipios_dl));
               scope = defaultOptions.length > 0 ? defaultOptions[0] : catalog.municipios_dl[0];
            } else {
               setCatScope(defaultOptions.concat(catalog.municipios));
               scope = defaultOptions.length > 0 ? defaultOptions[0] : catalog.municipios[0];
            }
            setCatalogsFiltered((prevState) => ({
               ...prevState,
               secciones: defaultOptions.concat(catalog.secciones)
            }));
            break
      }
      return (scope && scope.value !== undefined) ? scope.value : '';
   }

   const handleChangeElectionType = (value) => {
      let grouper = getDefaultGrouperFromElection(value);
      let scope = getScopeFromElection(value);
      setCatalogsFiltered((prevState) => ({
         ...prevState,
         ambito_agrupador: catalog.ambito_agrupador.filter(item => item.idTipoEleccion === value)
      }));

      setFilterData({
         ...filterData,
         idTipoEleccion: value,
         idAmbitoAgrupador: grouper,
         ValorAmbito: scope,
         Seccion: []
      });

      setSelected([]);
   }

   const getScopeFromGrouper = (grouper) => {

      let scope = { value: '', label: 'Sin seleccionar' };
      // if (!ELECCIONES_GLOBALES.includes(filterData.idTipoEleccion)) return scope;

      switch (grouper) {
         case AMBITO_DF:
            setCatScope(defaultOptions.concat(catalog.df));
            scope = defaultOptions.length > 0 ? defaultOptions[0] : catalog.df[0];
            break;

         case AMBITO_DL:
            setCatScope(defaultOptions.concat(catalog.dl));
            scope = defaultOptions.length > 0 ? defaultOptions[0] : catalog.dl[0];
            break;

         case AMBITO_CASILLA:
            switch (filterData.idTipoEleccion) {
               case ELECCION_DIPUTADO_FEDERAL:
                  scope = catalog.df[0];
                  break;
               case ELECCION_DIPUTADO_LOCAL:
                  scope = catalog.dl[0];
                  break;
               case ELECCION_PRESIDENTE_MUNICIPAL:
                  scope = catalog.municipios[0];
                  //setCatScope(defaultOptions.concat(catalog.municipios));
                  break;
               default:
                  scope = defaultOptions.length > 0 ? defaultOptions[0] : catalog.municipios[0];
                  setCatScope(defaultOptions.concat(catalog.municipios));
                  break;
            }
            break;
         case AMBITO_MPIO_DL:
            setCatScope(defaultOptions.concat(catalog.municipios_dl));
            scope = defaultOptions.length > 0 ? defaultOptions[0] : catalog.municipios_dl[0];
            break;
         default:
            setCatScope(defaultOptions.concat(catalog.municipios));
            scope = defaultOptions.length > 0 ? defaultOptions[0] : catalog.municipios[0];
            break;
      }

      console.log(scope);
      return scope.value;
   }

   const handleChangeGrouper = (value) => {

      let scope = value === AMBITO_ESTATAL ? 0 : getScopeFromGrouper(value);

      handleFilterSecciones(
         filterData.idTipoEleccion,
         value,
         scope
      );

      setFilterData({
         ...filterData,
         idAmbitoAgrupador: value,
         ValorAmbito: scope,
         Seccion: []
      });

      setSelected([]);
   }

   const handleChangeScope = (value) => {
      setFilterData({
         ...filterData,
         ValorAmbito: value,
         Seccion: []
      });

      handleFilterSecciones(
         filterData.idTipoEleccion,
         filterData.idAmbitoAgrupador,
         value
      );

      setSelected([]);
   };

   const handleSelectedOptions = items => {
      setSelected(items.filter(item => item.value !== null));
      setFilterData({
         ...filterData,
         Seccion: items.filter(item => item.value !== null).map(item => item.value)
      });
   }

   const handleFilterSecciones = (idTipoEleccion, idAmbitoAgrupador, ValorAmbito) => {

      if (ValorAmbito > 0) {
         switch (idAmbitoAgrupador) {
            case AMBITO_MPAL:
               setCatalogsFiltered((prevState) => ({
                  ...prevState,
                  secciones: defaultOptions.concat(
                     catalog.secciones.filter(item => item.idMunicipio === ValorAmbito))
               }));
               break;

            case AMBITO_MPIO_DL:
               setCatalogsFiltered((prevState) => ({
                  ...prevState,
                  secciones: defaultOptions.concat(
                     catalog.secciones.filter(item => item.idMunicipioReportes === ValorAmbito))
               }));
               break;

            case AMBITO_DL:
               setCatalogsFiltered((prevState) => ({
                  ...prevState,
                  secciones: defaultOptions.concat(
                     catalog.secciones.filter(item => item.idDL === ValorAmbito))
               }));
               break;

            case AMBITO_DF:
               setCatalogsFiltered((prevState) => ({
                  ...prevState,
                  secciones: defaultOptions.concat(
                     catalog.secciones.filter(item => item.idDF === ValorAmbito))
               }));
               break;

            case AMBITO_CASILLA:
               switch (idTipoEleccion) {
                  case ELECCION_DIPUTADO_FEDERAL:
                     setCatalogsFiltered((prevState) => ({
                        ...prevState,
                        secciones: defaultOptions.concat(
                           catalog.secciones.filter(item => item.idDF === ValorAmbito))
                     }));
                     break;

                  case ELECCION_DIPUTADO_LOCAL:
                     setCatalogsFiltered((prevState) => ({
                        ...prevState,
                        secciones: defaultOptions.concat(
                           catalog.secciones.filter(item => item.idDL === ValorAmbito))
                     }));
                     break;

                  default:
                     setCatalogsFiltered((prevState) => ({
                        ...prevState,
                        secciones: defaultOptions.concat(
                           catalog.secciones.filter(item => item.idMunicipio === ValorAmbito))
                     }));
                     break;
               }
               break;

            default:
               break;

         }
      }
      else {
         setCatalogsFiltered((prevState) => ({
            ...prevState,
            secciones: defaultOptions.concat(catalog.secciones)
         }));
      }
   }

   const handleApplyFilter = (e) => {
      e.preventDefault();
      onChangeFilter(filterData);
   }

   const handleClearFilter = (e) => {
      e.preventDefault();
      console.log(initFilter);
      setFilterData(initFilter);
      onChangeFilter(initFilter);
   }

   return (
      <Box sx={{ mt: 2, paddingX: 2 }}>
         <Stack
            direction="row"
            spacing={1}
            alignItems="center"
            marginBottom={2}
         >
            <Icon>filter_list</Icon>
            <Typography variant="body2" fontWeight={600}>
               Filtros
            </Typography>
         </Stack>
         <Grid2 container spacing={2}>
            <Grid2 xs={12} sm={6} md={12}>
               <InputSelect
                  label="Tipo de elección"
                  options={catalogsFiltered.tipo_eleccion}
                  name="idTipoEleccion"
                  value={catalog.tipo_eleccion.length > 0 ? filterData.idTipoEleccion : ''}
                  onChange={e => handleChangeElectionType(e.target.value)}
                  disabled={catalog.tipo_eleccion.length < 2}
                  sx={{ width: "100%" }}
                  isLoading={loadingCatalogs}
               />
            </Grid2>
            <Grid2 xs={12} sm={6} md={12}>
               <InputSelect
                  label="Tipo de agrupación"
                  options={catalogsFiltered.ambito_agrupador}
                  name="idAmbitoAgrupador"
                  // value={
                  //     !loadingCatalogs && catalogsFiltered.ambito_agrupador.length < 2 ?
                  //         catalogsFiltered.ambito_agrupador[0].value :
                  //         filterData.idAmbitoAgrupador
                  // }
                  value={catalogsFiltered.ambito_agrupador.length > 0 ? filterData.idAmbitoAgrupador : ''}
                  onChange={e => handleChangeGrouper(e.target.value)}
                  disabled={catalogsFiltered.ambito_agrupador.length < 2}
                  sx={{ width: "100%" }}
                  isLoading={loadingCatalogs}
               />
            </Grid2>
            {
               filterData.idAmbitoAgrupador !== AMBITO_ESTATAL && (
                  <Grid2 xs={12} sm={6} md={12}>
                     <InputSelect
                        label="Ámbito de elección"
                        options={catScope}
                        name="ValorAmbito"
                        // value={
                        //     catScope.length < 2 ?
                        //         catScope[0].value :
                        //         filterData.ValorAmbito
                        // }
                        value={filterData.ValorAmbito}
                        onChange={e => handleChangeScope(e.target.value)}
                        disabled={catScope.length < 2}
                        sx={{ width: "100%" }}
                        isLoading={loadingCatalogs}
                     />
                  </Grid2>
               )
            }
            {
               filterData.idAmbitoAgrupador === AMBITO_CASILLA && (
                  <Grid2 xs={12} sm={6} md={12}>
                     <Select
                        menuPlacement="top"
                        placeholder="Busqueda por sección"
                        styles={selectStyles}
                        closeMenuOnSelect={false}
                        menuPortalTarget={document.body}
                        components={animatedComponents}
                        isMulti
                        value={selected}
                        options={catalogsFiltered.secciones}
                        isLoading={loadingCatalogs}
                        onChange={handleSelectedOptions}
                     />
                  </Grid2>
               )
            }
         </Grid2>

         <Grid2 container spacing={1} sx={{ mt: 2 }} justifyContent={'end'} alignItems={'end'}>
            <Grid2 xs={6} sm={3} md={6} lg={4}>
               <Button
                  variant="outlined"
                  color="primaryDark"
                  onClick={handleClearFilter}
                  fullWidth
                  disabled={disabledButtons}

               >
                  Limpiar
               </Button>
            </Grid2>
            <Grid2 xs={6} sm={3} md={6} lg={4}>
               <Button
                  variant="contained"
                  color="primaryDark"
                  onClick={handleApplyFilter}
                  fullWidth
                  disabled={disabledButtons}
               >
                  Filtrar
               </Button>
            </Grid2>
         </Grid2>
      </Box>
   )
}

Filter.propTypes = {
   onChangeFilter: PropTypes.func.isRequired
};

export default Filter;