import ico_rc_paselista from "@assets/img/mobilediad/rc/rc_paselista_ini.png";
import ico_rc_instalacion from "@assets/img/mobilediad/rc/rc_instalacion_init.png";
import ico_rc_apertura from "@assets/img/mobilediad/rc/rc_apertura_init.png";
import ico_rc_bingo from "@assets/img/mobilediad/rc/rc_bingo_init.png";
import ico_rc_incidencias from "@assets/img/mobilediad/rc/rc_incidencias_init.png";
import ico_rc_cierre from "@assets/img/mobilediad/rc/rc_cierre_init.png";
import ico_rc_votacion from "@assets/img/mobilediad/rc/rc_votacion_init.png";
import ico_rc_resultados from "@assets/img/mobilediad/rc/rc_resultados_init.png";

import ico_rg_paselista_init from "@assets/img/mobilediad/rg/rg_paselista_init.png";
import ico_rg_instalacion_init from "@assets/img/mobilediad/rg/rg_instalacion_init.png";
import ico_rg_apertura_init from "@assets/img/mobilediad/rg/rg_apertura_init.png";
import ico_rg_bingo from "@assets/img/mobilediad/rg/Bingos.png";
/* import ico_rg_incidencias_init from "@assets/img/mobilediad/rg/rg_incidencias_init.png";
import ico_rg_cierre_init from "@assets/img/mobilediad/rg/rg_cierre_init.png";
import ico_rg_cierre_votacion_init from "@assets/img/mobilediad/rg/rg_cierre_votacion_init.png";
import ico_rg_resultados_init from "@assets/img/mobilediad/rg/rg_resultados_init.png"; */
import ico_rg_paseruta_init from "@assets/img/mobilediad/rg/rg_paseruta_init.png";

import ico_transmision from "@assets/img/mobilediad/Transmision.jpeg";

export const TIPO_BINGO = 1;
export const TIPO_BINGO_COMPROMISO_UNICO = 2;
export const ORIGEN_CAPTURA = "web";
export const PREP_RESULTADOS = 1;
export const PROPIETARIO_RG = 0;
export const VOTO = 1;
export const NO_VOTO = 2;

export const URL_WHATSAPP = "https://api.whatsapp.com/send?phone=52";

export const RCS = [
  {
    id: 1,
    label: "PASE DE LISTA",
    icon: ico_rc_paselista,
    screen: "PaseLista",
    title: "Pase de lista casillas",
  },
  {
    id: 2,
    label: "INICIO DE INSTALACIÓN DE CASILLAS",
    icon: ico_rc_instalacion,
    screen: "InicioInstalacion",
    title: "Inicio de instalación de casillas",
  },
  {
    id: 3,
    label: "APERTURA DE CASILLA",
    icon: ico_rc_apertura,
    screen: "AperturaCasilla",
    title: "Apertura de casilla",
  },
  {
    id: 4,
    label: "BINGO",
    icon: ico_rc_bingo,
    screen: "Bingo",
    title: "Bingos",
  },
  {
    id: 5,
    label: "REPORTE DE INCIDENCIAS",
    icon: ico_rc_incidencias,
    screen: "ReporteIncidencias",
    title: "Incidencias casilla",
  },
  {
    id: 6,
    label: "CIERRE DE CASILLA",
    icon: ico_rc_cierre,
    screen: "CierreCasilla",
    title: "Cierre de casilla",
  },
  {
    id: 7,
    label: "FIN DE VOTACIÓN",
    icon: ico_rc_votacion,
    screen: "CierreVotacion",
    title: "Fin de votación de casilla",
  },
  {
    id: 8,
    label: "REPORTE DE RESULTADOS",
    icon: ico_rc_resultados,
    screen: "ReporteResultados",
    title: "Resultados preliminares",
  },
  {
    id: 9,
    label: "TRANSMISIÓN",
    icon: ico_transmision,
    screen: "Transmision",
  },
];

export const RGS = [
  {
    id: 1,
    label: "PASE DE LISTA",
    icon: ico_rg_paselista_init,
    screen: "PaseListaRg",
    title: "Pase de lista casillas",
  },
  {
    id: 2,
    label: "PASE DE LISTA RUTA",
    icon: ico_rg_paseruta_init,
    screen: "PaseRutaRg",
    title: "Pase de lista rutas",
  },
  {
    id: 3,
    label: "INICIO DE INSTALACIÓN DE CASILLAS",
    icon: ico_rg_instalacion_init,
    screen: "InicioInstalacionRg",
    title: "Inicio de instalación de casilla",
  },
  {
    id: 4,
    label: "APERTURA DE CASILLA",
    icon: ico_rg_apertura_init,
    screen: "AperturaCasillaRg",
    title: "Apertura de casilla",
  },
  {
    id: 10,
    label: "FOLIO BINGO",
    icon: ico_rg_bingo,
    screen: "FolioBingoRg",
    title: "Folio bingo",
  },
  {
    id: 5,
    label: "REPORTE DE INCIDENCIAS",
    icon: ico_rc_incidencias,
    screen: "ReporteIncidenciasRg",
    title: "Incidencias casilla",
  },
  {
    id: 6,
    label: "CIERRE DE CASILLA",
    icon: ico_rc_cierre,
    screen: "CierreCasillaRg",
    title: "Cierre de casilla",
  },
  {
    id: 7,
    label: "FIN DE VOTACIÓN",
    icon: ico_rc_votacion,
    screen: "CierreVotacionRg",
    title: "Fin de votación de casilla",
  },
  {
    id: 8,
    label: "REPORTE DE RESULTADOS",
    icon: ico_rc_resultados,
    screen: "ReporteResultadosRg",
    title: "Resultados preliminares",
  },
  {
    id: 9,
    label: "TRANSMISIÓN",
    icon: ico_transmision,
    screen: "TransmisionRg",
  },
];

export const GENERAL = [
  {
    id: 1,
    label: "PASE DE LISTA",
    icon: ico_rc_paselista,
    screen: "PaseListaGeneral",
    title: "Pase de lista casillas",
  },
  {
    id: 2,
    label: "INICIO DE INSTALACIÓN DE CASILLAS",
    icon: ico_rc_instalacion,
    screen: "InicioInstalacionGeneral",
    title: "Inicio de instalación de casillas",
  },
  {
    id: 3,
    label: "APERTURA DE CASILLA",
    icon: ico_rc_apertura,
    screen: "AperturaCasillaGeneral",
    title: "Apertura de casilla",
  },
  {
    id: 4,
    label: "BINGO",
    icon: ico_rc_bingo,
    screen: "BingoGeneral",
    title: "Bingos",
  },
  {
    id: 5,
    label: "REPORTE DE INCIDENCIAS",
    icon: ico_rc_incidencias,
    screen: "ReporteIncidenciasGeneral",
    title: "Incidencias casilla",
  },
  {
    id: 6,
    label: "CIERRE DE CASILLA",
    icon: ico_rc_cierre,
    screen: "CierreCasillaGeneral",
    title: "Cierre de casilla",
  },
  {
    id: 7,
    label: "FIN DE VOTACIÓN",
    icon: ico_rc_votacion,
    screen: "CierreVotacionGeneral",
    title: "Fin de votación de casilla",
  },
  {
    id: 8,
    label: "REPORTE DE RESULTADOS",
    icon: ico_rc_resultados,
    screen: "ReporteResultadosGeneral",
    title: "Resultados preliminares",
  },
  {
    id: 9,
    label: "TRANSMISIÓN",
    icon: ico_transmision,
    screen: "TransmisionGeneral",
  },
];
