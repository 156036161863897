import {
  Box,
  Card,
  CardContent,
  Divider,
  List,
  ListItem,
  Stack,
  Typography,
  CircularProgress,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";

import CircularProgressPorcent from "@components/LinearProgress/CircularProgressPorcent";

import { numberWithCommas } from "@utils/Utilities";
// import { _round } from "../../utils/Utilities";

const Detail = ({ Info = {}, data = [], type, labelType, loading }) => {
  return (
    <Card elevation={0} variant="outlined" sx={{ mb: 2 }}>
      <CardContent>
        <Grid2 container>
          <Grid2 xs={12} md={5} lg={5}>
            <Typography variant="body2" fontWeight={600}>
              DETALLE
            </Typography>
            <Divider />
            {loading ? (
              <CircularProgress size={20} sx={{ mt: 2 }} />
            ) : Object.keys(Info).length > 0 ? (
              <Stack
                direction={"row"}
                useFlexGap
                flexWrap="wrap"
                justifyContent={"space-between"}
              >
                <List>
                  {Info.Region && (
                    <ListItem
                      sx={{
                        justifyContent: "space-between",
                        padding: 0,
                        mb: 0.5,
                        gap: 2,
                      }}
                    >
                      <Typography variant="subtitle2">Región:</Typography>
                      <Typography variant="body2" fontWeight={600}>
                        {Info?.Region}
                      </Typography>
                    </ListItem>
                  )}

                  {Info.Municipio && (
                    <ListItem
                      sx={{
                        justifyContent: "space-between",
                        padding: 0,
                        mb: 0.5,
                        gap: 2,
                      }}
                    >
                      <Typography variant="subtitle2">Municipio:</Typography>
                      <Typography variant="body2" fontWeight={600}>
                        {Info?.Municipio}
                      </Typography>
                    </ListItem>
                  )}

                  {Info.Poligono && (
                    <ListItem
                      sx={{
                        justifyContent: "space-between",
                        padding: 0,
                        mb: 0.5,
                        gap: 2,
                      }}
                    >
                      <Typography variant="subtitle2">Polígono:</Typography>
                      <Typography variant="body2" fontWeight={600}>
                        {Info?.Poligono}
                      </Typography>
                    </ListItem>
                  )}
                </List>
                {Info.MetaCompromisos && (
                  <List>
                    {Info.Secciones && (
                      <ListItem
                        sx={{
                          justifyContent: "space-between",
                          padding: 0,
                          mb: 0.5,
                          gap: 3,
                        }}
                      >
                        <Typography variant="subtitle2">Secciones:</Typography>
                        <Typography variant="body2" fontWeight={600}>
                          {Info.Secciones
                            ? numberWithCommas(Info.Secciones)
                            : ""}
                        </Typography>
                      </ListItem>
                    )}

                    {Info.MetaCompromisos && (
                      <ListItem
                        sx={{
                          justifyContent: "space-between",
                          padding: 0,
                          mb: 0.5,
                          gap: 3,
                        }}
                      >
                        <Typography variant="subtitle2">
                          Meta Compromisos:
                        </Typography>
                        <Typography variant="body2" fontWeight={600}>
                          {Info.MetaCompromisos
                            ? numberWithCommas(Info.MetaCompromisos)
                            : ""}
                        </Typography>
                      </ListItem>
                    )}

                    {Info.AvanceCompromisosUnicos && (
                      <ListItem
                        sx={{
                          justifyContent: "space-between",
                          padding: 0,
                          mb: 0.5,
                          gap: 3,
                        }}
                      >
                        <Typography variant="subtitle2">
                          Avance de Compromisos:
                        </Typography>
                        <Typography variant="body2" fontWeight={600}>
                          {Info.AvanceCompromisosUnicos
                            ? numberWithCommas(Info.AvanceCompromisosUnicos)
                            : ""}
                        </Typography>
                      </ListItem>
                    )}

                    {/* {Info.PAvanceCompromisos && (
                      <ListItem
                        sx={{
                          justifyContent: "space-between",
                          padding: 0,
                          mb: 0.5,
                          gap: 3,
                        }}
                      >
                        <Typography variant="subtitle2">
                          % de Avance:
                        </Typography>
                        <Typography variant="body2" fontWeight={600}>
                          {Info?.PAvanceCompromisos}
                        </Typography>
                      </ListItem>
                    )} */}
                  </List>
                )}

                {Info.MetaCompromisos && (
                  <List
                    sx={{
                      flexDirection: "row",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    {Info.PAvanceCompromisos && (
                      <ListItem
                        sx={{
                          justifyContent: "space-between",
                          padding: 0,
                          gap: 3,
                        }}
                      >
                        <Typography variant="subtitle2">
                          % de Avance:
                        </Typography>
                        {/* <Typography variant="body2" fontWeight={600}>
                          {Info?.PAvanceCompromisos}
                        </Typography> */}
                      </ListItem>
                    )}

                    <ListItem
                      sx={{
                        justifyContent: "center",
                        flexDirection: "column",
                        gap: 1,
                      }}
                    >
                      <CircularProgressPorcent
                        size={42}
                        fontSize={10}
                        value={Info?.PAvanceCompromisos}
                      />
                      <Typography variant="body2" fontWeight={600}>
                        {parseFloat(Info?.PAvanceCompromisos).toFixed(2)} %
                      </Typography>
                    </ListItem>
                  </List>
                )}
              </Stack>
            ) : (
              <Typography variant="body2" marginBottom={0.5} marginTop={1}>
                SIN DETALLE
              </Typography>
            )}
          </Grid2>
          <Grid2
            xs={12}
            md={1}
            lg={1}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <Divider orientation="vertical" variant="middle" />
          </Grid2>
          <Grid2 xs={12} md={6} lg={6}>
            {loading ? (
              <CircularProgress size={20} sx={{ mt: 2 }} />
            ) : (
                <Box sx={{ width: "100%", overflow: "scroll" }}>
                  <TableContainer sx={{ maxHeight: 200 }}>
                    <Table 
                      aria-label="sticky table" 
                      size="small" 
                      sx={{ fontSize: 8}}
                      stickyHeader={true}
                      >
                      <TableHead>
                        <TableRow>
                          <TableCell width={'40%'}>{type}</TableCell>
                          <TableCell width={'30%'} colSpan={2}>Estadística al día de Hoy</TableCell>
                          <TableCell width={'30%'} colSpan={2}>Semana pasada</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Nombre</TableCell>
                          <TableCell>Usuarios en Red</TableCell>
                          <TableCell>Compromisos en Red</TableCell>
                          <TableCell>Usuarios en Red</TableCell>
                          <TableCell>Compromisos en Red</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {data.length > 0 ? (
                          data.map((item) => (
                          <TableRow key={item.idUsuario}>
                            <TableCell>{item.NombreCompleto.toUpperCase()}</TableCell>
                            <TableCell sx={{textAlign: 'right'}}>{item.UsuariosEnRed ? numberWithCommas(item.UsuariosEnRed) : 0}</TableCell>
                            <TableCell sx={{textAlign: 'right'}}>{item.CompromisosUnicosEnRed ? numberWithCommas(item.CompromisosUnicosEnRed) : 0}</TableCell>
                            <TableCell sx={{textAlign: 'right'}}>{item.PasUsuariosEnRed ? numberWithCommas(item.PasUsuariosEnRed) : 0}</TableCell>
                            <TableCell sx={{textAlign: 'right'}}>{item.PasCompromisosUnicosEnRed ? numberWithCommas(item.PasCompromisosUnicosEnRed) : 0}</TableCell>
                          </TableRow>
                          ))
                        )
                          : (
                            <Typography variant="body2" marginBottom={0.5}>
                              {labelType}
                            </Typography>
                          ) }
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>
            )}
          </Grid2>
        </Grid2>
      </CardContent>
    </Card>
  );
};

export default Detail;
