// Material UI
import { Icon, Stack, Typography, Card, CardContent, Box, Button } from "@mui/material";
import { Update } from "@mui/icons-material";
import { blue } from "@mui/material/colors";

// Hooks
import useRefresh from "@hooks/useRefresh";

const LastUpdatedCard = (props) => {
  const {
    dateHour = "Sin información",
    handleClick = () => {},
    columnIn = "",
    rowIn = "xs",
    refresh,
  } = props;

  const { label } = useRefresh({ handleRefresh: handleClick, reset: refresh });

  return (
    <Card className="card-primary" sx={{ height: "100%" }}>
      <CardContent
        sx={{
          height: "100%",
          display: "flex",
          flexDirection: { [columnIn]: "column", [rowIn]: "row" },
          px: 2,
          py: { xs: 1, sm: 3, [rowIn]: "8px !important" },
          gap: 2,
          flexWrap: "wrap",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box>
          <Stack direction={"row"} spacing={1} justifyContent={"center"} alignItems={"center"}>
            <Icon sx={{ color: blue[900] }}>update</Icon>
            <Typography variant="subtitle2" fontWeight={600} textAlign={"center"}>
              ÚLTIMO CORTE
            </Typography>
          </Stack>

          <Typography variant="body2" textAlign={"center"}>
            {dateHour}
          </Typography>

          <Typography variant="body2" textAlign={"center"} sx={{ mt: 1, fontWeight: "bold" }}>
            {`Próxima actualización en ${label}`}
          </Typography>
        </Box>
        <Button
          onClick={handleClick}
          variant="outlined"
          endIcon={<Update />}
          sx={{
            color: blue[900],
            borderColor: blue[900],
            "&:hover": {
              borderColor: blue[700],
            },
          }}
        >
          Actualizar
        </Button>
      </CardContent>
    </Card>
  );
};

export default LastUpdatedCard;
