import { useState, useMemo, Fragment } from "react";
import Swal from "sweetalert2";

// Material IU
import {
  Stack,
  Typography,
  List,
  ListItem,
  ListItemText,
  Divider,
  TextField,
  Icon,
  Box,
  Chip,
  IconButton,
  Snackbar,
  Alert,
  CircularProgress,
} from "@mui/material";
import { House, Phone, WhatsApp, Autorenew } from "@mui/icons-material";
import { blue, red } from "@mui/material/colors";

// Servicios
import MobilizationService from "@services/MovilizationServices";
import { numberWithCommas } from "@utils/Utilities/";

const Reportados = ({ usuarios, setUsuarios, isLoading, isPerfil }) => {
  const [searchTerm, setSearchTerm] = useState("");

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");

  const handleSearchChange = (event) => setSearchTerm(event.target.value);

  const redirectToWhatsApp = (phoneNumber) => {
    const whatsappUrl = `https://wa.me/${phoneNumber}`;
    window.open(whatsappUrl, "_blank");
  };

  const handleSnackbarClose = () => setSnackbarOpen(false);

  const handleIconClick = (item) => {
    const { idEstatus, idCompromisoUnico, NombreCompleto } = item;

    let buttonsHtml = "";
    if (idEstatus === 2) {
      buttonsHtml = `<button id="button1" class="swal2-confirm swal2-styled custom-button">Pendiente</button>
                     <button id="button3" class="swal2-confirm swal2-styled custom-button">No Localizado</button>`;
    } else if (idEstatus === 3) {
      buttonsHtml = `<button id="button1" class="swal2-confirm swal2-styled custom-button">Pendiente</button>
                     <button id="button2" class="swal2-confirm swal2-styled custom-button">Movilizado</button>`;
    }

    Swal.fire({
      title: `${NombreCompleto}`,
      html: `<p>Selecciona una opción para cambiar su estatus</p>${buttonsHtml}`,
      icon: "info",
      showCancelButton: true,
      cancelButtonText: "Cerrar",
      showConfirmButton: false,
      buttonsStyling: false,
      customClass: {
        actions: "swal2-actions",
        cancelButton: "custom-cancel-button",
        container: "modal-alert",
      },
    }).then(() => {
      // Vacio
    });

    const localUpdate = (idEstatus) => {
      const usuariosActualizados = usuarios.map((e) => {
        if (e.idCompromisoUnico === idCompromisoUnico) return { ...e, idEstatus };
        return e;
      });

      updateListing({ idCompromisoUnico: item.idCompromisoUnico, idEstatus }, usuariosActualizados);

      Swal.close();
    };

    if ([2, 3].includes(idEstatus)) {
      document.getElementById("button1").addEventListener("click", () => localUpdate(1));
    }

    if ([1, 3].includes(idEstatus)) {
      document.getElementById("button2").addEventListener("click", () => localUpdate(2));
    }

    if ([1, 2].includes(idEstatus)) {
      document.getElementById("button3").addEventListener("click", () => localUpdate(3));
    }
  };

  const updateListing = async (params, usuariosActualizados) => {
    const payload = {
      OrigenCaptura: "web",
      data: [params],
    };

    try {
      const res = await MobilizationService.reportMobilized(payload);
      const { results, message } = res;
      if (results) {
        setSnackbarMessage("Reportado con éxito");
        setSnackbarSeverity("success");
        setSnackbarOpen(true);
        setUsuarios(usuariosActualizados);
      } else throw new Error(message);
    } catch (error) {
      setSnackbarMessage("No se pudo hacer el reporte");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    }
  };

  const filteredData = useMemo(() => {
    return usuarios.filter(({ NombreCompleto, idEstatus }) => {
      if (searchTerm) {
        return NombreCompleto.toLowerCase().includes(searchTerm.toLowerCase()) && [2, 3].includes(idEstatus);
      }
      return [2, 3].includes(idEstatus);
    });
  }, [usuarios, searchTerm]);

  return (
    <>
      <style>
        {`
          .swal2-actions {
            display: flex;
            justify-content: center;
          }

          .custom-cancel-button {
            background-color: gray;
            color: white;
            font-weight: 500;
            border: none;
            padding: 10px 20px;
            margin: 10px;
            cursor: pointer;
            border-radius: 5px;
            width: 180px;
          }

          .custom-cancel-button:hover {
            background-color: darkgray;
          }

          .custom-button {
            background-color: #3085d6;
            color: white;
            font-weight: 500;
            border: none;
            padding: 10px 20px;
            margin: 10px;
            cursor: pointer;
            border-radius: 5px;
          }

          .custom-button:hover {
            background-color: #256ca0;
          }
        `}
      </style>

      {!isLoading && (
        <Box sx={{ width: "100%", textAlign: "center" }}>
          <Typography>
            Mostrando {numberWithCommas(filteredData.length)} de {numberWithCommas(usuarios.length)}
          </Typography>
        </Box>
      )}

      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={snackbarOpen}
        autoHideDuration={2000}
        onClose={handleSnackbarClose}
      >
        <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: "100%" }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>

      <TextField
        label="Buscar..."
        variant="outlined"
        fullWidth
        margin="normal"
        value={searchTerm}
        onChange={handleSearchChange}
        disabled={isLoading}
      />

      {isLoading ? (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            mt: 2,
          }}
        >
          <CircularProgress />
          <Typography sx={{ fontWeight: 600, variant: "subtitle2", marginY: 1 }}>Cargando...</Typography>
        </Box>
      ) : (
        <Box sx={{ height: "100%", overflow: "auto", marginTop: 0 }}>
          {filteredData.length > 0 ? (
            <List>
              {filteredData.map((item, index) => (
                <Fragment key={index}>
                  <ListItem sx={{ px: 1 }}>
                    <ListItemText
                      primary={
                        <Stack direction={"column"}>
                          <Box sx={{ display: "flex", alignItems: "center" }}>
                            <House sx={{ color: blue[100], fontSize: 30 }} />
                            <Typography variant="caption" sx={{ fontSize: 18, ml: 1 }}>
                              <strong>{item.NombreCompleto}</strong>
                            </Typography>
                          </Box>

                          <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
                            <Chip
                              label={item.idEstatus === 2 ? "Movilizado" : "No Localizado"}
                              sx={{
                                backgroundColor: item.idEstatus === 2 ? blue[200] : red[200],
                                color: item.idEstatus === 2 ? blue[800] : red[800],
                                fontWeight: 500,
                                width: 120,
                                height: 25,
                              }}
                            />
                            <IconButton size="small" sx={{ ml: 1 }} onClick={() => handleIconClick(item)}>
                              <Autorenew />
                            </IconButton>
                          </Box>

                          {item.Telefono || item.TelMensajes ? (
                            <Typography variant="caption">
                              <strong>Por movilizar:</strong>
                            </Typography>
                          ) : (
                            <></>
                          )}

                          {(item.Telefono || item.Telefono) && (
                            <Stack direction="row" gap={1} sx={{ mb: 2 }}>
                              {item.Telefono ? (
                                <Typography
                                  component={"a"}
                                  href={`tel:${item.Telefono}`}
                                  color={"inherit"}
                                  sx={{ fontSize: 15, ml: 0.5 }}
                                >
                                  <Icon>
                                    <Phone sx={{ fontSize: 15, mt: 1 }} />
                                  </Icon>
                                  {item.Telefono}
                                </Typography>
                              ) : (
                                <></>
                              )}

                              {item.TelMensajes ? (
                                <Box
                                  sx={{ display: "flex", alignItems: "center", cursor: "pointer" }}
                                  onClick={() => redirectToWhatsApp(item.TelMensajes)}
                                >
                                  <Typography
                                    component={"a"}
                                    color={"inherit"}
                                    sx={{ fontSize: 15, ml: 0.5 }}
                                  >
                                    <Icon>
                                      <WhatsApp sx={{ fontSize: 15, mt: 1 }} />
                                    </Icon>
                                    {item.TelMensajes}
                                  </Typography>
                                </Box>
                              ) : (
                                <></>
                              )}
                            </Stack>
                          )}

                          <Typography variant="caption" sx={{ fontSize: "12px" }}>
                            <strong>Domicilio:</strong>
                          </Typography>
                          <Typography variant="caption" sx={{ fontSize: "15px", mb: isPerfil ? 1 : 2 }}>
                            {item.DomicilioCompleto}
                          </Typography>

                          {isPerfil && (
                            <Stack
                              direction={"column"}
                              sx={{ px: 2, py: 1, mb: 2, backgroundColor: "#fff5d3", borderRadius: 2 }}
                            >
                              <Typography variant="caption" sx={{ fontSize: "12px" }}>
                                <strong>Promotor:</strong>{" "}
                              </Typography>

                              <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
                                {item.CelularPropietario && (
                                  <IconButton onClick={() => redirectToWhatsApp(item.CelularPropietario)}>
                                    <WhatsApp />
                                  </IconButton>
                                )}
                                <Typography variant="caption" sx={{ fontSize: "15px" }}>
                                  {item.NombrePropietario}
                                </Typography>
                              </Box>
                            </Stack>
                          )}
                        </Stack>
                      }
                    />
                  </ListItem>
                  <Divider />
                </Fragment>
              ))}
            </List>
          ) : (
            <Box style={{ textAlign: "center", mt: 20 }}>
              <Typography variant="body1">¡No se encontraron resultados!</Typography>
              <Icon style={{ fontSize: 40, color: "black" }}>error</Icon>
            </Box>
          )}
        </Box>
      )}
    </>
  );
};

export default Reportados;
