import { useState } from "react";

import { Container } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";

import AdminLayout from "@components/MainPage/AdminLayout";
import ActionHeader from "@components/Containers/ActionHeader";
import Filter from "@components/Activation/RollCall/Filter";
import ListMobilization from "@components/Activation/RollCall/ListMobilization";
import LoadingForm from "@components/LinearProgress/LoadingForm";

import movilizacion from "@services/MovilizationServices";
import Swal from "sweetalert2";

const RollCall = () => {
  const [dataMovilization, setDataMovilization] = useState([]);
  const [users, setUsers] = useState([]);
  const [filtered, setFiltered] = useState([]);
  const [isLoadigForm, setIsLoadingForm] = useState(false);
  const [isSuccessForm] = useState(false);
  const [openLinearProgress, setOpenLinearProgress] = useState(false);
  const [dataFound, setDataFound] = useState(true);

  const getMovilization = async ({ body }) => {
    try {
      setIsLoadingForm(true);
      setOpenLinearProgress(true);
      const result = await movilizacion.getMovilization({ filtered: body });
      const { results, response, message } = result;
      if (results) {
        setDataMovilization(response.data.data);
        setDataFound(response.data.results);
      } else {
        throw new Error(message);
      }
    } catch (error) {
      Swal.fire({
        title: error,
        icon: "warning",
      });
    } finally {
      setIsLoadingForm(false);
      setOpenLinearProgress(false);
    }
  };

  const setMovilization = async ({ body }) => {
    try {
      setIsLoadingForm(true);
      setOpenLinearProgress(true);
      const result = await movilizacion.setMovilization({ usuarios: body });
      const { results, message } = result;
      if (results) {
        Swal.fire({
          title: message,
          icon: "success",
        }).then(({ isConfirmed }) => {
          if (isConfirmed) {
            getMovilization({ body: filtered });
          }
        });
      } else {
        throw new Error(message);
      }
    } catch (error) {
      Swal.fire({
        title: error,
        icon: "warning",
      });
    } finally {
      setIsLoadingForm(false);
      setOpenLinearProgress(false);
    }
  };

  const onChangeFilter = ({ filtereds }) => {
    if (filtereds.length > 0) {
      setFiltered(filtereds);
      getMovilization({ body: filtereds });
    }
  };

  const onClearFilter = () => {
    setDataMovilization([]);
    setDataFound(true);
  };

  return (
    <AdminLayout>
      <LoadingForm
        loadinMessage={"Cargando Datos..."}
        isLoading={isLoadigForm}
        success={isSuccessForm}
        isOpen={openLinearProgress}
        setIsOpen={() => setOpenLinearProgress(!openLinearProgress)}
      />
      <Container maxWidth={false}>
        <ActionHeader title="Pase de lista" />
        <Grid2 container spacing={2}>
          <Grid2 xs={12} md={3} lg={3}>
            <Filter onChange={onChangeFilter} onClear={onClearFilter} />
          </Grid2>
          <Grid2 xs={12} md={9} lg={9}>
            <ListMobilization
              data={dataMovilization}
              users={users}
              dataFound={dataFound}
              setUsers={setUsers}
              setMovilization={setMovilization}
            />
          </Grid2>
        </Grid2>
      </Container>
    </AdminLayout>
  );
};

export default RollCall;
