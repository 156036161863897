import {
  Avatar,
  Box,
  Card,
  List,
  ListItemButton,
  Stack,
  Typography,
  Button,
  FormHelperText,
} from "@mui/material";
import { PersonOff } from "@mui/icons-material";

const ListsBingos = ({ data, total, disabled = false, handleClick = () => {}, search = null }) => {
  return (
    <Box>
      {data && data.length > 0 ? (
        <>
          <Typography variant="body2" textAlign="center" fontWeight={600} marginBottom={1}>
            {disabled ? "Cargando..." : `Mostrando ${total} resultados`}
          </Typography>
          <Box sx={{ height: "55vh", overflow: "auto" }}>
            <List>
              {data.map((item, index) => (
                <div key={index}>
                  <Box
                    component={Card}
                    variant="outlined"
                    sx={{
                      mb: 2,
                      borderRadius: 4,
                      backgroundColor: "inherit",
                    }}
                  >
                    <ListItemButton onClick={() => handleClick(item.idCompromisoUnico)} disabled={disabled}>
                      <Stack direction="row" alignItems="center" spacing={2}>
                        <Avatar />
                        <Box>
                          <Typography variant="body2" fontWeight={600}>
                            {item.NombreCompleto}
                          </Typography>
                        </Box>
                      </Stack>
                    </ListItemButton>
                  </Box>
                </div>
              ))}
            </List>
          </Box>
        </>
      ) : (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            gap: 2,
          }}
        >
          {search !== null && (
            <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
              <Button
                onClick={() => handleClick(search, true)}
                disabled={search.length < 2}
                variant="outlined"
                startIcon={<PersonOff />}
              >
                Notificar No Encontrado
              </Button>
              {search.length < 2 && (
                <FormHelperText error sx={{ textAlign: "center" }}>
                  Ingresa un nombre de al menos 2 caracteres
                </FormHelperText>
              )}
            </Box>
          )}
          <Typography textAlign={"center"} variant="body2">
            No se encontraron resultados.
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default ListsBingos;
