import React from "react";

import { Box } from "@mui/material";

const ActionContainer = ({ children }) => {
  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        justifyContent: "end",
        marginTop: '3%'
      }}
    >
      {children}
    </Box>
  );
};

export default ActionContainer;
