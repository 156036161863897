import * as React from "react";
import PropTypes from "prop-types";
import LinearProgress from "@mui/material/LinearProgress";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Stack } from "@mui/material";

function LinearProgressWithLabel(props) {
  return (
    <>
      <Typography variant="bold1" color={"text.secondary"}>
        {props.label}
      </Typography>
      <Box sx={{ display: "flex", alignItems: "center", marginTop: "-10px" }}>
        <Box sx={{ width: "100%", mr: 1 }}>
          <LinearProgress variant={props.loading ? "indeterminate" : "determinate"} {...props} />
        </Box>
        <Box sx={{ minWidth: 60, mr: 1 }}>
          <Stack direction={"row"}>
            <Typography variant="body2" color="text.secondary">
              {props.avance}
            </Typography>
            /
            <Typography variant="body2" color="text.secondary">
              {props.total}
            </Typography>
          </Stack>
        </Box>
      </Box>
    </>
  );
}

LinearProgressWithLabel.propTypes = {
  /**
   * The value of the progress indicator for the determinate and buffer variants.
   * Value between 0 and 100.
   */
  value: PropTypes.number.isRequired,
};

export default function LinearWithValueLabel({
  progress,
  avance,
  total,
  label,
  color,
  loading
}) {
  return (
    <Box sx={{ width: "100%" }}>
      <LinearProgressWithLabel
        value={progress}
        avance={avance}
        total={total}
        label={label}
        color={color}
        loading={loading}
      />
    </Box>
  );
}
