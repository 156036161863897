// Librerías
import React, { useState, useEffect, useCallback } from "react";
import Swal from "sweetalert2";
import useWindowDimensions from "@hooks/useWindowDimensions";

// Servicios
import SupportServices from "@services/SupportServices";

// Componentes
import BasicList from "@components/Lists/BasicList";
import UserListItem from "@components/Users/UserListItemInformation";
import LoadingForm from "@components/LinearProgress/LoadingForm";

function UserSearchHistory({ userId, handleUserIdChange, handleUserNameChange, setmessageUserNotFound }) {
  //Configuraciones de cabecera de componente BasicList
  const { height } = useWindowDimensions();
  const config = {
    title: "Listado de Usuarios",
    icon: "people",
    height: height * 0.6,
    endMessage: "No hay más registros para mostrar",
  };

  //Estados
  const [page, setPage] = useState(0);
  const [pageSize] = useState(10);
  const [total, setTotal] = useState(0);
  const [filtered, setFiltered] = useState([]);
  //Informacion completa del usuario
  const [userList, setUserList] = useState([]);

  const [hasMore, setHasMore] = useState(true);

  const [isloadigForm, setIsLoadingForm] = useState(false);
  const [openLinearProgress, setOpenLinearProgress] = useState(false);
  const [issuccessForm] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);

  useEffect(() => {
    if (userId) {
      setSelectedItem(userId);
    }
    // eslint-disable-next-line
  }, [userId]);

  //Funcion para traer los usuarios de la API
  const getUsers = useCallback((data) => {
    setIsLoadingForm(true);
    setOpenLinearProgress(true);

    SupportServices.getSupportUsers({
      page: data.page,
      pageSize: data.pageSize,
      filtered: data.filtered,
    })
      .then((res) => {
        if (res.results) {
          if (res.response.data.length > 0) {
            setUserList(res.response.data);
            setTotal(parseInt(res.response.total));
          }
          if (pageSize >= parseInt(res.response.total)) {
            setHasMore(false);
          }
        } else {
          Swal.fire({ title: res.message, icon: "warning" });
          setHasMore(false);
          setSelectedItem(null);
          handleUserIdChange(null)
          setTotal(0);
          setUserList([]);
          setmessageUserNotFound("NO SE HA ENCONTRADO EL USUARIO")
        }
      })
      .catch((e) => {
        Swal.fire({ title: e.message, icon: "warning" });
        setHasMore(false);
      })
      .finally(() => {
        setIsLoadingForm(false);
        setOpenLinearProgress(false);
      });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    getUsers({ page, pageSize, filtered });
    // eslint-disable-next-line
  }, []);

  //Funcion para scroll infinito y cargar mas perfiles
  const fetchHasMore = () => {
    SupportServices.getSupportUsers({
      page: page + 1,
      pageSize: pageSize,
      filtered: filtered,
    })
      .then((res) => {
        if (res.results) {
          setUserList(userList.concat(res.response.data));
          let end = (page + 1) * pageSize;
          if (end >= res.response.total) {
            setHasMore(false);
          }
          setPage(page + 1);
        } else {
          setHasMore(false);
        }
      })
      .catch((error) => {
        setHasMore(false);
        Swal.fire({
          title: "Error al obtener más registros",
          text: `No se pudo obtener el total del listado de usuarios. Detalles del error: ${error.message}`,
          icon: "warning",
        });
      });
  };

  const handleSearch = (searchInput) => {
    let filter = [];
    if (searchInput === "") {
      filter = [];
      setPage(0); // Restablecer la paginación
      setHasMore(true); // Permitir cargar más usuarios
  
      // No limpiar el usuario seleccionado si ya hay uno
      if (!userId) {
        handleUserIdChange(null);
        handleUserNameChange("");
      }
      setmessageUserNotFound("")
    } else {
      filter = [
        {
          id: `CONCAT_WS('', usuarios.Nombre, usuarios.Paterno, usuarios.Materno, usuarios.Username, usuarios.Telefono)`,
          filter: "LIKE",
          value: `%${searchInput.replace(/\s+/g, "%")}%`,
          inheritFilterType: null,
        },
      ];
    }
    setFiltered(filter);
    getUsers({ page: 0, pageSize, filtered: filter });
  };
  

  //Funcion que almacena el id que viene del componente UserListItemInformation
  const handleSelectedUserId = (id) => {
    handleUserIdChange(id);
  };

  //Funcion que almacena el nombre que viene del componente UserListItemInformation
  const handleSelectedUserName = (nombre) => {
    handleUserNameChange(nombre);
  };

  return (
    <div>
      <LoadingForm
        isLoading={isloadigForm}
        success={issuccessForm}
        isOpen={openLinearProgress}
        setIsOpen={() => setOpenLinearProgress(!openLinearProgress)}
        loadinMessage={"Cargando Datos..."}
      />

      <BasicList
        configParams={config}
        //Informacion de la API
        data={userList}
        totalItems={total}
        //Este es el componente que muestra los items
        itemComponent={(props) => (
          <UserListItem
            {...props}
            selected={selectedItem}
            handleSelectedUserId={handleSelectedUserId}
            handleSelectedUserName={handleSelectedUserName}
          />
        )}
        //Para poder ver si tiene mas perfiles
        hasMore={hasMore}
        //Scroll Infinito de perfiles
        fetchHasMore={fetchHasMore}
        //Barra de busqueda
        search={handleSearch}
        
        finder={true}
      />
    </div>
  );
}

export default UserSearchHistory;
