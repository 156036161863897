import { useState, useEffect } from "react";
import { useCatalogs } from "@hooks/useCatalogs";

// import PropTypes from "prop-types";

import {
    Box,
    Card,
    CardContent,
    Icon,
    Stack,
    Typography,
    Button
} from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";

import InputSelect from "@components/Selects/BasicSelect";

import { ELECCION_DIPUTADO_FEDERAL, ELECCION_DIPUTADO_LOCAL, ELECCION_PRESIDENTE_MUNICIPAL } from "@data/constants/Prep"

const FormFilter = props => {
    const { onChangeFilter } = props;
    const catalogsOptions = [
        { id: "dl" },
        { id: "df" },
        { id: "municipios" },
        { id: "tipo_eleccion" }
    ];

    const { catalogs, load } = useCatalogs({
        catalogsOptions,
        putDefaultOption: true
    });

    const [catalogsFiltered, setCatalogsFiltered] = useState(() => {
        let newObject = {};
        for (const item of catalogsOptions) {
            newObject[item.id] = [];
        }
        return newObject;
    });
    const [filterData, setFilterData] = useState({
        idTipoEleccion: '',
        idPartido: '',
        Partido: '',
        idCandidato: '',
        Candidato: '',
        ValorAmbito: ''
    });
    const [catScope, setCatScope] = useState([]);

    useEffect(() => {
        if (load === false) {
            setCatalogsFiltered(catalogs);
        }
        //eslint-disable-next-line
    }, [load]);

    const handleChangeElectionType = (value) => {

        setFilterData((prevState) => ({
            ...prevState,
            idTipoEleccion: value < 1 ? '' : value,
            idPartido: '',
            idCandidato: '',
            ValorAmbito: ''
        }));

        switch (value) {
            case ELECCION_DIPUTADO_FEDERAL:
                setCatScope(catalogs.df);
                break;
            case ELECCION_DIPUTADO_LOCAL:
                setCatScope(catalogs.dl);
                break;
            case ELECCION_PRESIDENTE_MUNICIPAL:
                setCatScope(catalogs.municipios);
                break;
            default:
                setCatScope([]);
                break;
        }
    }

    const handleChangeScope = (value) => {
        setFilterData({ ...filterData, ValorAmbito: value < 1 ? '' : value });
    }

    const handleClearFilter = () => {
        setFilterData({
            idTipoEleccion: '',
            idPartido: '',
            idCandidato: '',
            ValorAmbito: ''
        });

        onChangeFilter([]);
        setCatalogsFiltered(catalogs);
    }

    const handleApplyFilter = (filterData) => {
        let filtered = [];

        if (filterData.idTipoEleccion !== '') {
            filtered.push({
                id: 'prep_planillas.idTipoEleccion',
                filter: '=',
                value: filterData.idTipoEleccion
            })
        }

        if (filterData.idPartido !== '') {
            filtered.push({
                id: 'prep_planillas.idPartido',
                filter: '=',
                value: filterData.idPartido
            })
        }

        if (filterData.idCandidato !== '') {
            filtered.push({
                id: 'prep_planillas.idCandidato',
                filter: '=',
                value: filterData.idCandidato
            })
        }

        if (filterData.ValorAmbito !== '') {
            filtered.push({
                id: 'prep_planillas.AmbitoValor',
                filter: '=',
                value: filterData.ValorAmbito
            })
        }

        onChangeFilter(filtered);
    }

    return (
        <Box component={Card} className="card-primary">
            <Box component={CardContent}>
                <Stack
                    direction="row"
                    spacing={1}
                    alignItems="center"
                    marginBottom={2}
                >
                    <Icon>filter_list</Icon>
                    <Typography variant="body2" fontWeight={600}>
                        Filtros
                    </Typography>
                </Stack>

                <Grid2 container spacing={2} m={1}>
                    <Grid2 xs={12} md={3}>
                        <InputSelect
                            label="Tipo de partido"
                            options={catalogsFiltered.tipo_eleccion}
                            name="idTipoPartido"
                            value={filterData.idTipoEleccion}
                            onChange={e => handleChangeElectionType(e.target.value)}
                            sx={{ width: "100%" }}
                            isLoading={load}
                        />
                    </Grid2>

                    <Grid2 xs={12} md={3}>
                        <InputSelect
                            label="Ámbito de la elección"
                            options={catScope}
                            name="ValorAmbito"
                            value={filterData.ValorAmbito}
                            onChange={e => handleChangeScope(e.target.value)}
                            sx={{ width: "100%" }}
                            isLoading={load}
                            disabled={![ELECCION_DIPUTADO_FEDERAL, ELECCION_DIPUTADO_LOCAL, ELECCION_PRESIDENTE_MUNICIPAL].includes(filterData.idTipoEleccion)}
                        />
                    </Grid2>
                </Grid2>

                <Grid2 container spacing={2} m={1} justifyContent={'end'}>
                    <Stack
                        direction="row"
                        spacing={1}
                        alignItems="end"
                        marginBottom={2}
                    >
                        <Button
                            variant="outlined"
                            color="primaryDark"
                            onClick={handleClearFilter}
                            sx={{ ml: 1 }}
                            fullWidth
                        >
                            Limpiar
                        </Button>
                        <Button
                            variant="contained"
                            color="primaryDark"
                            onClick={e => handleApplyFilter(filterData)}
                            fullWidth
                        >
                            Filtrar
                        </Button>
                    </Stack>
                </Grid2>
            </Box>
        </Box>
    )
}

export default FormFilter;