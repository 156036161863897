import React, { useState, useEffect } from "react";

//MUI
import { Box, Button, Grid } from "@mui/material";

//Components
import InputSelect from "@components/Selects/BasicSelect";
import CatalogServices from "@services/CatalogServices";
import Swal from "sweetalert2";

const Filter = ({ onChangeFilter}) => {
  //variables
  const defaultOption = { value: 0, label: "TODOS" };
  //States
  const [, setCatRegions] = useState([]);
  const [catRegionsFilter, setCatRegionsFilter] = useState([]);
  const [catMunicipality, setCatMunicipality] = useState([]);
  const [catMunicipalityFilter, setCatMunicipalityFilter] = useState([]);
  const [catPolygon] = useState([
    { value: 0, label: "TODOS" },{ value: 1, label: "Sin Polígono" },{ value: 2, label: "Con Polígono" }
  ]);
  const [clean, setClean] = useState(false);
  const [dataCatalog, setDataCatalog] = useState({
    Region: 0,
    Municipio: 0,
    Poligono: 0,
  });

  //Functions Api's
  useEffect(() => {
    const catalogsParams = [
      { id: "regiones", getAll: false },
      { id: "municipios", getAll: false },/* 
      { id: "poligonos", getAll: false },
      { id: "secciones", getAll: false }, */
    ];

    CatalogServices.getCatalogs(catalogsParams)
      .then((res) => {
        if (res.results) {
          catalogsParams.forEach((item) => {
            switch (item.id) {
              case "regiones":
                setCatRegions(
                  [defaultOption].concat(res.response.catalogs[item.id])
                );
                setCatRegionsFilter(
                  [defaultOption].concat(res.response.catalogs[item.id])
                );
                break;

              case "municipios":
                setCatMunicipality(
                  [defaultOption].concat(res.response.catalogs[item.id])
                );
                setCatMunicipalityFilter(
                  [defaultOption].concat(res.response.catalogs[item.id])
                );
                break;

         /*      case "poligonos":
                setCatPolygon(res.response.catalogs[item.id]);
                setCatPolygonFilter(res.response.catalogs[item.id]);
                break;

              case "secciones":
                setCatSection(res.response.catalogs[item.id]);
                setCatSectionFilter(res.response.catalogs[item.id]);
                break;
 */
              default:
            }
          });
        } else {
          Swal.fire({ title: res.message, icon: "warning" });
        }
      })
      .catch((error) => Swal.fire({ title: error.message, icon: "warning" }));

    // eslint-disable-next-line
  }, []);

  const handleChangeRegion = (event) => {
    const idRegion = event.target.value;
    setDataCatalog({
      ...dataCatalog,
      Region: idRegion,
      Municipio: 0,
      Poligono: [],
      Seccion: [],
    });

    if (idRegion > 0) {
      setCatMunicipalityFilter(
        [defaultOption].concat(
          catMunicipality.filter((item) => item.idRegion === idRegion)
        )
      );
    /*   setCatPolygonFilter(
        catPolygon.filter((item) => item.idRegion === idRegion)
      );
      setCatSectionFilter(
        catSection.filter((item) => item.idRegion === idRegion)
      ); */
    } else {
      setCatMunicipalityFilter(catMunicipality);
 /*      setCatPolygonFilter(catPolygon);
      setCatSectionFilter(catSection); */
    }
  };

  const handleChangeMunicipality = (e) => {
    const idMunicipality = e.target.value;
    setDataCatalog({
      ...dataCatalog,
      Municipio: idMunicipality
    });

  };


  const handleChangePolygon = (e) => {
    const idPolygons = e.target.value;
    setDataCatalog({
      ...dataCatalog,
      Poligono: idPolygons
    });

  };

  const searchFilter = () => {
    const valueFilter = {
      Region: dataCatalog.Region,
      Municipio: dataCatalog.Municipio,
      Poligono: dataCatalog.Poligono
    };

    onChangeFilter(valueFilter);
  };

  const clearFilter = () => {
    setClean(!clean);
    const newFilter = {
      Region: 0,
      Municipio: 0,
      Poligono: 0
    };

    setCatMunicipalityFilter(catMunicipality);
    /* setCatPolygonFilter(catPolygon);
    setCatSectionFilter(catSection);
 */
    setDataCatalog({
      ...dataCatalog,
      Region: 0,
      Municipio: 0,
      Poligono: 0
    });

    /* 
    setData({
      ...data,
      filtered: [],
    }); */
/* 
    setFilter(newFilter); */
    onChangeFilter(newFilter);
  };


  return (
    <>
        <Grid container spacing={2} marginBottom={"1rem"} marginTop={"0.5rem"}>
          <Grid item xs={12} md={4}>
            <InputSelect
              label="Región"
              options={catRegionsFilter}
              name="region"
              value={
                catRegionsFilter.length === 2
                  ? catRegionsFilter[1].value
                  : dataCatalog.Region
              }
              onChange={handleChangeRegion}
              disabled={catRegionsFilter.length === 2 ? true : false}
              sx={{ width: "100%" }}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <InputSelect
              label="Municipio"
              options={catMunicipalityFilter}
              name="municipio"
              value={
                catMunicipalityFilter.length === 2
                  ? catMunicipalityFilter[1].value
                  : dataCatalog.Municipio
              }
              onChange={handleChangeMunicipality}
              disabled={catMunicipality.length === 2 ? true : false}
              sx={{ width: "100%" }}
            />
          </Grid>
            <Grid item xs={12} md={4}>
            <InputSelect
              label="Polígono"
              options={catPolygon}
              name="Polígono"
              value={dataCatalog.Poligono}
              onChange={handleChangePolygon}
              sx={{ width: "100%" }}
            />
            </Grid>
        </Grid>
        <Box display={"flex"} justifyContent={"flex-end"}>
          <Button
            variant="contained"
            color="primaryDark"
            onClick={searchFilter}
          >
            Filtrar
          </Button>
          <Button color="primaryDark" sx={{ ml: 1 }} onClick={clearFilter}>
            Limpiar
          </Button>
        </Box>
    </>
  );
};

export default Filter;
