import { useEffect, useState } from "react";
import { useCatalogs } from "@hooks/useCatalogs";

import { Button, Card, CardContent, Stack } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";

import FilterCollapse from "@components/Filters/FilterCollapse";
import BasicSelect from "@components/Selects/BasicSelect";

const FilterMovilization = ({ onChangeFilter, onClearFilter }) => {
  //CONSTANTS
  const catalogsOptions = [
    { id: "regiones", getAll: false },
    { id: "municipios_reportes", getAll: false },
    { id: "poligonos", getAll: false },
  ];

  const { catalogs, load } = useCatalogs({
    catalogsOptions,
    putDefaultOption: false,
  });

  //States
  const [catalogsFiltered, setCatalogFiltered] = useState(() => {
    let newObject = {};
    for (const item of catalogsOptions) {
      newObject[item.id] = [];
    }
    return newObject;
  });
  const [filterData, setFilterData] = useState({
    Region: "",
    Municipio: "",
    Poligono: "",
    Movilizacion: "",
  });


  useEffect(() => {
    if(!load){
      setCatalogFiltered(catalogs);
    }
    //eslint-disable-next-line
 }, [load]);

  const handleChangeFilter = (e) => {
    const key = e.target.name;
    setFilterData({ ...filterData, [key]: e.target.value });
  };

  const handleChangeRegion = (e) => {
    const idRegion = e.target.value;
    setCatalogFiltered((prevState) => ({
      ...prevState,
      municipios_reportes: catalogs.municipios_reportes.filter(
        (item) => item.idRegion === idRegion
      ),
    }));
  };

  const handleSearch = () => {
    const filters = [
      {
        id: "cat_secciones.idRegion",
        key: "Region",
      },
      {
        id: "cat_secciones.idMunicipio",
        key: "Municipio",
      },
      {
        id: "cat_secciones.idPoligono",
        key: "Poligono",
      },
    ];

    const filtered = filters
      .filter((filter) => filterData[filter.key] > 0)
      .map((filter) => ({
        id: filter.id,
        filter: "=",
        value: filterData[filter.key],
      }));

    onChangeFilter({ filters: filtered });
  };

  const handleClearFilter = () => {
    const cleanFileds = {
      Region: "",
      Municipio: "",
      Poligono: "",
      Movilizacion: "",
    };
    setCatalogFiltered(catalogs);
    setFilterData(cleanFileds);
    onClearFilter();
  };

  return (
    <Card className="card-primary">
      <CardContent>
        <FilterCollapse expand>
          <Grid2 container spacing={2}>
            <Grid2 xs={12} md={3} lg={3}>
              <BasicSelect
                name="Region"
                label="Región"
                options={catalogsFiltered.regiones}
                value={filterData.Region}
                onChange={(e) => {
                  handleChangeFilter(e);
                  handleChangeRegion(e);
                }}
                isLoading={load}
                sx={{ width: "100%" }}
              />
            </Grid2>
            <Grid2 xs={12} md={3} lg={3}>
              <BasicSelect
                name="Municipio"
                label="Municipio"
                options={catalogsFiltered.municipios_reportes}
                value={filterData.Municipio}
                onChange={(e) => {
                  handleChangeFilter(e);
                }}
                isLoading={load}
                sx={{ width: "100%" }}
              />
            </Grid2>
            <Grid2 xs={12} md={3} lg={3}>
              <BasicSelect
                name="Poligono"
                label="Polígono"
                options={catalogsFiltered.poligonos}
                value={filterData.Poligono}
                onChange={(e) => {
                  handleChangeFilter(e);
                }}
                isLoading={load}
                sx={{ width: "100%" }}
              />
            </Grid2>
            <Grid2 xs={12} md={3} lg={3}>
              <BasicSelect
                name="Movilizacion"
                label="Avance Movilización"
                options={[]}
                value={filterData.Movilizacion}
                // onChange={(e) => {
                //   handleChangeFilter(e);
                // }}
                //isLoading={load}
                sx={{ width: "100%" }}
              />
            </Grid2>
          </Grid2>
          <Stack
            direction={"row"}
            spacing={1}
            justifyContent={"flex-end"}
            marginTop={2}
          >
            <Button color="primaryDark" onClick={handleClearFilter}>
              Limpiar
            </Button>
            <Button
              variant="contained"
              color="primaryDark"
              onClick={handleSearch}
            >
              Filtrar
            </Button>
          </Stack>
        </FilterCollapse>
      </CardContent>
    </Card>
  );
};

export default FilterMovilization;
