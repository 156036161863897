import React from "react";
import { Typography, Grid, Divider, Box, CircularProgress } from "@mui/material";
import Alert from "@mui/material/Alert";
import useWindowDimensions from "@hooks/useWindowDimensions";
import moment from "moment/moment";
import "moment/locale/es";

//Iconos
import GoogleChromeIcon from "@assets/img/browsersSVG/googlechrome.svg";
import FireFoxIcon from "@assets/img/browsersSVG/firefox.svg";
import OperaIcon from "@assets/img/browsersSVG/opera.svg";
import PostManIcon from "@assets/img/browsersSVG/postman.svg";
import SafariIcon from "@assets/img/browsersSVG/safari.svg";

function RiskAlertHistory({ userId, userName, apiData, sendColorId, changeName, isLoading }) {
  const { height } = useWindowDimensions();

  const getColorByClasificacion = () => {
    switch (sendColorId) {
      case 1:
        return "info";
      case 2:
        return "warning";
      case 3:
        return "error";
      default:
        return "default";
    }
  };

  const getBrowserIcon = (browserName) => {
    switch (browserName) {
      case "Chrome":
        return <img src={GoogleChromeIcon} alt="Chrome" />;
      case "Opera":
        return <img src={OperaIcon} alt="Opera" />;
      case "Firefox":
        return <img src={FireFoxIcon} alt="Firefox" />;
      case "Safari":
        return <img src={SafariIcon} alt="Safari" />;
      case "Postman":
        return <img src={PostManIcon} alt="Postman" />;
      default:
        return null;
    }
  };

  return (
    <Box>
      <Grid container alignItems="center" mb={2}>
        <Grid item xs={12} sm={12}>
          <Typography
            variant="h6"
            sx={{
              width: "100%",
              marginY: 1,
              marginLeft: 1,
              textAlign: "left",
              fontSize: "17px",
            }}
          >
            Alertas de riesgo del usuario
            {changeName ? " - " + changeName : null}
          </Typography>
        </Grid>
      </Grid>

      <Divider />

      <Typography variant="h6" sx={{ marginTop: 2, textAlign: "center", color: "rgb(0, 0, 129)" }}>
        {userId ? userName : "Selecciona un usuario"}
      </Typography>

      {isLoading ? (
        <Box sx={{ display: "flex", justifyContent: "center", marginTop: 2 }}>
          <CircularProgress />
        </Box>
      ) : (
        <Box
          sx={{
            maxHeight: height * 0.57,
            overflowY: "scroll",
            marginTop: 1,
          }}
        >
          {apiData && apiData.length > 0 ? (
            <Box>
              {apiData.map((dataItem) => (
                <Alert
                  key={dataItem.idAccion}
                  variant="outlined"
                  severity={getColorByClasificacion()}
                  icon={getBrowserIcon(dataItem.Navegador)}
                  sx={{
                    border: "none",
                    boxShadow: "none",
                    width: "100%", // Asegurarse de que ocupa todo el ancho disponible
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: {
                        xs: "column", // En pantallas pequeñas, los elementos se apilan verticalmente
                        sm: "column", // En pantallas más grandes, se disponen en fila
                        md: "row",
                        lg: "row",
                      },
                      width: "100%", // Asegurarse de que ocupa todo el ancho disponible
                    }}
                  >
                    <Typography
                      variant="h2"
                      sx={{
                        fontSize: {
                          xs: "15px", // Margen normal en pantallas más grandes
                        },
                      }}
                    >
                      {dataItem.Accion}
                    </Typography>

                    <Typography
                      variant="h6"
                      sx={{
                        fontSize: {
                          xs: "12px", // Sin margen izquierdo en pantallas pequeñas
                        },
                        marginLeft: {
                          xs: 0, // Sin margen izquierdo en pantallas pequeñas
                          sm: 0, // Margen normal en pantallas más grandes
                          md: 40,
                          lg: 40, // Margen normal en pantallas más grandes
                        },
                        marginBottom: {
                          xs: 2, // Sin margen izquierdo en pantallas pequeñas
                          sm: 0, // Margen normal en pantallas más grandes
                        },
                        color: "gray",
                        flexShrink: 0, // Evitar que la fecha se reduzca más en pantallas pequeñas
                      }}
                    >
                      {moment(dataItem.Fecha, "YYYY-MM-DD HH:m").fromNow()}
                    </Typography>
                  </Box>

                  <Typography
                    sx={{
                      width: "100%", // Asegurarse de que ocupa todo el ancho disponible
                    }}
                  >
                    {dataItem.Comentario}
                  </Typography>
                  <Typography variant="h4" sx={{ marginTop: 2, fontSize: "13px" }}>
                    {moment(dataItem.Fecha, "YYYY-MM-DD HH:m").format("DD/MM/YYYY HH:mm")}
                  </Typography>
                </Alert>
              ))}
            </Box>
          ) : (
            <Typography variant="body2" sx={{ marginTop: 2, textAlign: "center" }}>
              No hay datos disponibles.
            </Typography>
          )}
        </Box>
      )}
    </Box>
  );
}

export default RiskAlertHistory;
