import React from "react";

import {
	Dialog,
	DialogTitle,
	DialogContent,
	IconButton,
	Icon,
	useMediaQuery,
	useTheme,
	DialogActions,
	Button,
} from "@mui/material";

const Modal = (props) => {
	const {
		Content,
		children,
		open,
		setOpen,
		hasButtonClose = true,
		hasButtonSave = false,
		hasButtonCancel = false,
		headerTitle = "Title Header",
		maxWidth = "md",
		fullWidth = true,
		borderRadius= 16,
		isFullScreen = true,
	} = props
	const theme = useTheme();
	const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

	return (
		<>
			<Dialog
				fullScreen={isFullScreen ? fullScreen : false}
				open={open}
				onClose={() => setOpen(false)}
				maxWidth={maxWidth}
				scroll="paper"
				fullWidth={fullWidth}
				//sx={{ borderRadius: 16 }}
				PaperProps={{ style: { borderRadius: borderRadius } }}
			>
				{hasButtonClose || headerTitle !== "Title Header" ? (
					<DialogTitle>
						{headerTitle}
						{hasButtonClose &&
							<IconButton
								aria-label="close"
								onClick={() => setOpen(false)}
								sx={{
									position: 'absolute',
									right: 8,
									top: 8,
								}}
							>
								<Icon color="error">cancel</Icon>
							</IconButton>
						}
					</DialogTitle>
				) : <></>}
				<DialogContent dividers>
					{/* <Content></Content> */}
					{children}
				</DialogContent>
				
				{hasButtonSave || hasButtonCancel ?
					(
						<DialogActions>
							{hasButtonCancel &&
								<Button
									onClick={() => setOpen(false)}
									size="small"
									color='error'
									variant="outlined"
									startIcon={<Icon>close</Icon>}
								>Cancelar
								</Button>
							}
							{hasButtonSave &&
								<Button
									type="submit"
									//onClick={formik.submitForm}
									size="small"
									variant="contained"
									color="primaryDark"
									startIcon={<Icon>save</Icon>}
								>Guardar
								</Button>
							}
						</DialogActions>
					) : <></>}
			</Dialog>
		</>
	);
};

export default Modal;
