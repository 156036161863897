const EventosFilter = {
  actividad: "",
};

const EventosInvitadosFilter = {
  celular: "",
};

const CompromisoEventInterface = {
  isMenor: 0,
  isUsuario: 0,
  Password: "",
  Confirm: "",
  PIN: "",
  idUsuarioPropietario: "",
  idPartido: 1,
  INE: "",
  CURP: "",
  VigenciaINE: "",
  Nombre: "",
  Paterno: "",
  Materno: "",
  FechaNacimiento: "",
  idSexo: "",
  idEstadoNacimiento: "",
  CalleVive: "",
  TipoVialidad: "C",
  NumExtVive: "",
  NumIntVive: "",
  idMunicipioVive: "",
  idLocalidadVive: "",
  ColoniaVive: "",
  CPVive: "",
  SeccionVota: "",
  Celular: "",
  Telefono: "",
  TelMensajes: "",
  Correo: "",
  Facebook: "",
  Instagram: "",
  Twitter: "",
  TikTok: "",
  EsVoluntario: 1,
  idVoluntario: 1,
  TieneAuto: "",
  TieneExperiencia: "",
  AceptaCuidarOtra: "",
  Segmentos: [],
  idMedioContacto: 2,
  idEvento: "",
  token: "",
  preguntas_respuestas:[]
};


export { EventosFilter, EventosInvitadosFilter,CompromisoEventInterface };
