import { useState, useEffect } from "react";
//MUI
import { Card, CardContent, Box, IconButton, Typography } from "@mui/material";
import { Clear } from "@mui/icons-material";
import { red } from '@mui/material/colors';

import Swal from "sweetalert2";

//COMPONENTS
import TreeComponent from "@components/TreeView/TreeView";

//SERVICES
import AuditServices from "@services/AuditoriaOrigenCapturaServices";

const Tree = (props) => {
  const { setLoading, searchTreeValue, prioritario } = props;
  const [loadingTree, setLoadingTree] = useState(false);
  const [treeUsers, setTreeUsers] = useState([]);
  const [inicialValues, setInicialValues] = useState([])
  const [secondaryUserLoad, setSecondaryUserLoad] = useState([]);
  const [showButton, setShowButton] = useState(false);

  const cleenSecondSearch = () => {
    setSecondaryUserLoad([]);
    setShowButton(false);
    setTreeUsers([...inicialValues])
  };

  const getUsers = async () => {
    try {
      cleenSecondSearch()
      setLoadingTree(true);
      setLoading(true);
      const { user } = prioritario;
      const result = await AuditServices.getUserParents(user);
      const { results, message, response } = result;
      if (results) {
        setTreeUsers([response]);
        setInicialValues([response])
      } else {
        throw new Error(message);
      }
    } catch (error) {
      Swal.fire({
        title: error.message,
        icon: "warning",
      });
    } finally {
      setLoadingTree(false);
      setLoading(false);
    }
  };

  const getSecondaryUserLoad = async () => {
    try {
      setLoadingTree(true);
      setLoading(true);
      const result = await AuditServices.getUserParentsSecond(searchTreeValue.response.id);
      const { results, message, response } = result;
      if (results) {
        setSecondaryUserLoad([response]);
      } else {
        throw new Error(message);
      }
    } catch (error) {
      Swal.fire({
        title: error.message,
        icon: "warning",
      });
    } finally {
      setLoadingTree(false);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (searchTreeValue && searchTreeValue?.datalength > 1) {
      getSecondaryUserLoad();
      setShowButton(true);
    }else{
      setShowButton(searchTreeValue.datalength == 1)
    }
    //eslint-disable-next-line
  }, [searchTreeValue]);

  useEffect(() => {
    if (prioritario) {
      getUsers();
    }
    //eslint-disable-next-line
  }, [prioritario]);

  return (
    <Card className="card-primary">
      <CardContent>
        {showButton ? (
          <Box width="100%" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography>
              <strong>Origen: </strong>{searchTreeValue?.response?.Nombre} 
            </Typography>
            <IconButton onClick={cleenSecondSearch}>
              <Clear style={{ color: red[500], fontSize: 28 }}/>
            </IconButton>
          </Box>
        ) : null}
        <TreeComponent
          data={secondaryUserLoad.length > 0 ? secondaryUserLoad : treeUsers}
          handleClick={() => {}}
          typeData={{
            value: "id",
            label: "NombreCompleto",
            T_label: "Celular",
          }}
          loading={loadingTree}
        />
      </CardContent>
    </Card>
  );
};

export default Tree;
