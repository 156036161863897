import Yup from "@utils/Yupi18n";
// import moment from "moment";

const EventosFilterSchema = Yup.object({
  actividad: Yup.string().optional(),
});

const EventosInvitadosFilterSchema = Yup.object({
  celular: Yup.string().optional(),
});

export { EventosFilterSchema, EventosInvitadosFilterSchema };
