import { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Card,
  CardContent,
  Container,
  Icon,
  Typography,
  ListItemText,
  Box,
  Link,
  IconButton,
} from "@mui/material";

import Swal from "sweetalert2";
import "moment/locale/es";

import AdminLayout from "@components/MainPage/AdminLayout";
import BasicTable from "@components/Tables/BasicTable";
import ActionHeader from "@components/Containers/ActionHeader";
import Filter from "@components/FrenteAmplio/Filter.jsx";
import FrenteAmplioServices from "@services/FrenteAmplioServices";
import { isEmptyOrNullObject } from "@utils/validations";
import middleware from "@middlewares/middleware";
import ForwardToInboxIcon from "@mui/icons-material/ForwardToInbox";
import AssignmentReturnedIcon from '@mui/icons-material/AssignmentReturned';

import { blue } from "@mui/material/colors";
import { filter } from "lodash";

const ListPendiente = () => {
  //Constantes
  const colums = [
    {
      label: "Municipio",
      id: "Municipio",
      columnAction: false,
      width: 100,
      orderBy: "Municipio",
    },
    { label: "Modulo", id: "Modulo", columnAction: false, width: 100, orderBy:"Modulo" },
    { label: "Capturo", id: "Capturo", columnAction: false, width: 100, orderBy: "Capturo" },
    { label: "Celular", id: "Celular", columnAction: false, width: 50,orderBy:"Celular" },
    { label: "Pendiente", id: "NombreCompleto", width: 100, columnAction: false, width: 20, orderBy: "Nombre" },
  ];

  let navigate = useNavigate();
  const [data, setData] = useState({
    page: 0,
    pageSize: 10,
    filtered: [],
    sorted: [],
  });


  //State
  const [total, setTotal] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [dataLisCommitments, setDataLisCommitments] = useState([]);
  const [sortedData, setSortedData] = useState([]);

  const handleDownload = async (idImg,NombrePaterno) => {
    try {
      let imgReverso = `https://api.mapea.me/files/ines/frontal/${idImg}.jpeg`
      const response = await fetch(imgReverso);
      const blob = await response.blob();
      const url = URL.createObjectURL(blob);

      const link = document.createElement('a');
      link.href = url;
      link.download = `${NombrePaterno}_Frontal.jpg`;
      link.click();

      URL.revokeObjectURL(url);

      let imgAdverso = `https://api.mapea.me/files/ines/reverso/${idImg}.jpeg`
      const responseAd = await fetch(imgAdverso);
      const blob1 = await responseAd.blob();
      const urlAd = URL.createObjectURL(blob1);

      const link1 = document.createElement('a');
      link1.href = urlAd;
      link1.download = `${NombrePaterno}_Reverso.jpg`; 
      link1.click();

     
      URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Error al descargar la imagen:', error);
    }
  };


  const getList = useCallback((data) => {
    setIsLoading(true);
    FrenteAmplioServices.getPendientes(data)
      .then((res) => {
        if (res.results) {
          const Commitments = res.response.data.map((item) => {
            return  {
              ...item,
              NombreCompleto: middleware.checkMenuAction("Editar") ? (
                <Button
                  disableElevation
                  variant="text"
                  color="primaryDark"
                  size="small"
                  sx={{
                    ":hover": {
                      bgcolor: blue[900],
                      color: "white",
                    },
                    p: "0 auto",
                    textAlign: "left",
                  }}
                  startIcon={<Icon>edit</Icon>}
                  onClick={(e) => handleEdit(item)}
                >
                  <ListItemText
                    primary={
                      <Typography variant="div" fontWeight={700} fontSize={15}>
                        {`${item.Nombre} ${item.Paterno} ${item.Materno}`}
                      </Typography>
                    }
                  />
                </Button>
              ) : (
                <Typography variant="div" fontWeight={700} fontSize={15}>
                 {`${item.Nombre} ${item.Paterno} ${item.Materno}`}
                </Typography>
              ),
              account: (
                <Typography>
                  {item.account}
                  <IconButton
                    color="primary"
                    size="large"
                    target="_blank"
                    component={Link}
                    href={item.URL}
                  >
                    <ForwardToInboxIcon />
                  </IconButton>
                </Typography>
              ),
           /*    descarga: middleware.checkMenuAction("Exportar") ?<>{
                item.insertID ? <IconButton
                color="primary"
                size="large"
                onClick={(e)=>handleDownload(item.insertID,item.NombrePaterno)}
              >
                <AssignmentReturnedIcon />
              </IconButton> : <></>
              }
              </>:<>{item.insertID ? "Si" : "No"}</> */
            };
          });
          setDataLisCommitments(Commitments);
          setTotal(res.response.total);
        } else {
          Swal.fire({ title: res.message, icon: "warning" });
          setDataLisCommitments([]);
          setTotal(0);
        }
      })
      .catch((err) => {
        Swal.fire({ title: err, icon: "error" });
        setDataLisCommitments([]);
        setTotal(0);
      })
      .finally(() => setIsLoading(false));
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    getList(data);
    // eslint-disable-next-line
  }, [data]);

  useEffect(() => {
    if (sortedData.length > 0) {
      const params = {
        ...data,
        sorted: sortedData,
      };

      getList(params);
    }
    // eslint-disable-next-line
  }, [sortedData]);

  const handleEdit = (commitment) => {
   
    const { id } = commitment;

    FrenteAmplioServices.getFind(id)
      .then((res) => {
        if (res.results) {
          navigate("/frente-captura", { state: res.response });
        } else {
          Swal.fire({ title: res.message, icon: "warning" });
        }
      })
      .catch((error) => Swal.fire({ title: error, icon: "warning" }));
  };

  const handleChangeFilter = (filtered) => {
    setDataLisCommitments([]);
    if(filtered.length >0){
      filtered[0] = {...filtered[0], "condition": "AND"}
    }
    setData((prevState) => ({ ...prevState, filtered }));
  };

  const handleChangePagination = (pagination) => {
    setData({ ...data, ...pagination });
  };

  const handleSorted = (dataSorted) => {
    const fields = {
      Municipio: "cat_municipios.Municipio",
      NombreCompleto:
        "CONCAT_WS(' ',compromisos_unicos_fa.Nombre, compromisos_unicos_fa.Paterno, compromisos_unicos_fa.Materno)",
    };

    let sorted = [];
    if (!isEmptyOrNullObject(dataSorted)) {
      sorted.push({
        id: fields[dataSorted.orderBy],
        value: dataSorted.order,
      });
    }

    return sorted;
  };

  const handleRequestSort = (sortData) => {
    if (total > 0) setSortedData(handleSorted(sortData));
  };

  return (
    <AdminLayout>
      <Container maxWidth="false">
        <ActionHeader title="Listado Pendientes Frente Amplio" isLink ={true} titleLink="Ir a la Página Frente Amplio" link="https://frenteampliopormexico.org.mx/RegistroUniversal" />
              <Box>
                <Filter onChangeFilter={(e) => handleChangeFilter(e)} correo={true} municipio={false}/>
                <Card className="card-primary">
                  <CardContent>
                    <BasicTable
                      rows={dataLisCommitments}
                      hcolumns={colums}
                      handleChangePagination={handleChangePagination}
                      isLoading={isLoading}
                      total={total}
                      pageProp={data.page}
                      pageSize={data.pageSize}
                      stickyHeader={true}
                      handleManualSort={handleRequestSort}
                    />
                  </CardContent>
                </Card>
              </Box>
      </Container>
    </AdminLayout>
  );
};

export default ListPendiente;
