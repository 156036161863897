import { useState /* useEffect */ } from "react";

import { Box, CircularProgress } from "@mui/material";
//import { grey } from "@mui/material/colors";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";

//import UserSearchForm from "@components/Sije/CaptureForm/UserSearchForm";
import CaptureForm from "@components/Sije/CaptureForm/CaptureListCasilla";
//import ListCasillaOpening from "@components/Sije/CaptureForm/ListCasillaOpening";
//import ListCasillaOpeningMassive from "@components/Sije/CaptureForm/ListCasillaOpeningMassive";
//import Switch from "../CaptureForm/Switch";
import CardRepresentative from "@components/Sije/CaptureForm/CardRepresentative";

import Swal from "sweetalert2";
import sije from "@services/SijeService";

const Opening = (props) => {
  const {
    nameTab,
    setOpenLinearProgress,
    setIsSuccessFormSave,
    setIsLoadingForm,
    isLoadigForm,
    reload,
    dataRC,
    loadingRc,
    userId,
  } = props;

  const [listCasillas, setListCasillas] = useState([]);
  //const [options, setOptions] = useState([]);
  const [user, setUser] = useState({});
  //const [loadingListCasillas, setLoadingListCasilla] = useState(false);
  //const [clearForm, setClearForm] = useState(false);
  /*   const [page, setPage] = useState(0);
  const [totalList, setTotalList] = useState(0);
  const [filterData, setFilterData] = useState({
    Celular: "",
    Nombre: "",
    Paterno: "",
    Materno: "",
  }); */
  const [selected, setSelected] = useState(0);

  /*   const getCasillasUser = async ({ idUser, isLoading }) => {
    try {
      isLoading && setLoadingListCasilla((prevState) => !prevState);
      const result = await sije.getCasillasUsuario({ idUsuario: idUser });
      const { response, message, results } = result;
      if (results) {
        setListCasillas(response.data);
      } else {
        throw new Error(message);
      }
    } catch (error) {
      Swal.fire({
        title: error,
        icon: "warning",
      });
    } finally {
      isLoading && setLoadingListCasilla((prevState) => !prevState);
    }
  }; */

  const handleSaveForm = async (values) => {
    const { casilla, idUser, hora, TomadosFila, NumFuncionarios } = values;
    try {
      setIsLoadingForm(true);
      setOpenLinearProgress(true);
      setIsSuccessFormSave(false);
      const result = await sije.setAperturaCasilla({
        casillas: [
          {
            idCasilla: casilla,
            FechaHora: hora,
            NumFuncionarios,
            TomadosFila,
          },
        ],
        idUser,
        OrigenCaptura: "web"
      });
      const { results, message } = result;

      if (results) {
        Swal.fire({
          title: message,
          icon: "success",
        });
        //setClearForm(true);
        setSelected(0);
        reload();
        /* nameTab === "APERTURA CASILLA" &&
          getCasillasUser({ idUser: userId, isLoading: false }); */
      } else {
        throw new Error(message);
      }
    } catch (error) {
      Swal.fire({
        title: error,
        icon: "warning",
      });
    } finally {
      setIsLoadingForm(false);
      setOpenLinearProgress(false);
      setIsSuccessFormSave(true);
    }
  };

  /*   const handleClearForm = () => {
    setUser({});
    setOptions([]);
    /* setFilterData({
      Nombre: "",
      Paterno: "",
      Materno: "",
      Celular: "",
    }); */
  /* setPage(0);
    setTotalList(0);
    setSelected(0);
  }; */

  /* const handleClickItem = (id) => {
    const user = options.find((item) => item.value === id);
    getCasillasUser({ idUser: user.value, isLoading: true });
    setUser(user);
  }; */

  /* 
  useEffect(()=>{
    if(userId !== ""){
      setUser(userId)
      getCasillasUser({ idUser: userId, reload: true });
    }
  },[userId]) */

  return (
    <Grid2 container spacing={2}>
      {/* <Grid2 xs={12} md={6} lg={6}>
        <UserSearchForm
          title="Encuentra y elige al usuario para registrar la apertura de la casilla."
          page={page}
          total={totalList}
          setPage={setPage}
          setTotal={setTotalList}
          filterData={filterData}
          options={options}
          setFilterData={setFilterData}
          setOptions={setOptions}
          setSelected={setSelected}
          setUser={setUser}
          handleClickItem={handleClickItem}
        />
      </Grid2> */}
      <Grid2 xs={12} md={6} lg={6}>
        {/* {Object.entries(user).length === 0 && (
          <Box
            className="card-primary"
            component={Card}
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
            height={"400px"}
          >
            <Stack
              direction={"column"}
              justifyContent={"center"}
              alignItems={"center"}
              alignContent={"center"}
            >
              <Box
                sx={{ backgroundColor: grey[200] }}
                width={"80px"}
                height={"80px"}
                padding={2}
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
                borderRadius={"50%"}
              >
                <Icon fontSize={"large"} sx={{ color: grey[700] }}>
                  post_add
                </Icon>
              </Box>
              <Typography variant="body2" marginTop={1} fontWeight={600}>
                Busca y selecciona al usuario para poder hacer el registro de la
                apertura de casilla.
              </Typography>
            </Stack>
          </Box>
        )} */}

        {!user ? (
          <Box
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
            height={"600px"}
          >
            <CircularProgress />
          </Box>
        ) : (
          <CaptureForm
            title={nameTab}
            labelHora={
              "* Hora de Instalación de Casillas (Formato: 24 hrs, ejemplo: 13:30)"
            }
            idUser={userId}
            isNumeroFuncionario={true}
            isFila={true}
            handleSave={handleSaveForm}
            // loading={isLoadigForm}
            flag={listCasillas.length === 0}
          />
        )}
      </Grid2>
      <Grid2 xs={12} md={6} lg={6}>
        <CardRepresentative dataRC={dataRC} loading={loadingRc} />
      </Grid2>
    </Grid2>
  );
};

export default Opening;

{
  /* <Switch test={true}>
            <CaptureForm
              title={nameTab}
              labelHora={
                "* Hora de Instalación de Casillas (Formato: 24 hrs, ejemplo: 13:30)"
              }
              idUser={user}
              isNumeroFuncionario={true}
              isFila={true}
              handleSave={handleSaveForm}
              // loading={isLoadigForm}
              flag={listCasillas.length === 0}
            />
            <ListCasillaOpening
              title={`${nameTab} - RC`}
              itemUser={user}
              //clearForm={clearForm}
              //setClearForm={setClearForm}
              setItemUser={setUser}
              options={options}
              handleSave={handleSaveForm}
              loading={isLoadigForm}
              flag={listCasillas.length === 1}
            />
            <ListCasillaOpeningMassive
              title={`${nameTab} - RGS`}
              selected={selected}
              setSelected={setSelected}
              clearForm={clearForm}
              setClearForm={setClearForm}
              optCasillas={listCasillas}
              itemUser={user}
              setItemUser={setUser}
              options={options}
              handleSave={handleSaveForm}
              handleClear={handleClearForm}
              flag={listCasillas.length > 1}
            />
          </Switch> */
}
