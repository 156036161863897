import React, { useRef } from "react";
import Webcam from "react-webcam";
import { Button } from "@mui/material";
import "./WebCamera.css"; // Asegúrate de tener un archivo CSS llamado App.css en el mismo directorio

const WebCamera = (props) => {
  const webcamRef = useRef(null);

  const videoConstraints = {
    width: 1280,
    height: 720,
    facingMode: "user",
  };

  const capture = React.useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();
    getDataInformation(imageSrc);
  }, [webcamRef]);

  const getDataInformation = (base64Image) => {
    // Aquí se llama a tu API para procesar la imagen
    console.log(base64Image);
  };

  return (
    <div className="camera-wrapper">
      <Webcam
        audio={false}
        height={720}
        ref={webcamRef}
        screenshotFormat="image/jpeg"
        width={1280}
        videoConstraints={videoConstraints}
        className="webcam"
      />
      <div className="bounding-box"></div>
      <Button variant="contained" color="primary" onClick={capture}>
        Capturar foto
      </Button>
    </div>
  );
};

export default WebCamera;
