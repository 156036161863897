import React, { /* useEffect, */ useState } from "react";

//Mui
import { Card, CardContent, Grid, TextField, Box, Button } from "@mui/material";

//Components
import FilterCollapse from "@components/Filters/FilterCollapse";
import InputSelect from "@components/Selects/BasicSelect";


//import Swal from "sweetalert2";

const UserAssistFilter = (props) => {
  const {setFiltered} = props
  const [searchData, setSearchData] = useState({
    celular: "",
    nombre: "",
    solicitud: 0,
    fecha: "",
    tieneUsuario: 2,
    estatus: "",
    noSolicitud: "",
  });

  const handleKeyPress = (event) => {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode < 48 || charCode > 57) {
      event.preventDefault();
    }
  };
  const handleKeyPress2 = (event) => {
    const inputValue = event.key;
    const regex = /^[a-zA-ZáéíóúüÁÉÍÓÚÜñÑ\s]*$/;

    if (!regex.test(inputValue)) {
      event.preventDefault();
    }
  };
  const handleSearch = async () => {
    const filters = {
      celular: { id: "Celular", filter: "=" },
      nombre: { id: "Nombre", filter: "LIKE" },
      solicitud: { id: "idSolicitudSoporte", filter: "=" },
      fecha: { id: "FechaCreo", filter: "LIKE" },
      tieneUsuario: { id: "TieneUsuario", filter: "=" },
    };
  
    const filtered = Object.keys(filters).reduce((acc, key) => {
      const value = searchData[key];
      if (key !== "tieneUsuario" && value) {
        if (key === 'FechaCreo') {
          // Format the date to yyyy-mm-dd
          const formattedDate = new Date(value).toISOString().split('T')[0];
          acc.push({
            id: filters[key].id,
            filter: filters[key].filter,
            value: formattedDate,
          });
        } else {
          acc.push({
            id: filters[key].id,
            filter: filters[key].filter,
            value: value,
          });
        }
      }else if(key==="tieneUsuario" && value !=="" && value !== 2){
        acc.push({
          id: filters[key].id,
          filter: filters[key].filter,
          value: value,
        });
      }
      return acc;
    }, []);

    /* if(filtered.length === 0){
      return Swal.fire({
        title:"Los campos de filtro se encuentran vacíos, haga uso de alguno de ellos.",
        icon: "info"
      })
    }else{ */
      setFiltered(filtered)
    //}
  };
  let solicitud = [
    { value: 0, label: "TODOS" },
    { value: 1, label: "Registro" },
    { value: 2, label: "Capacitación" },
    { value: 3, label: "Dar de baja a la cuenta" },
    { value: 4, label: "Otros " },
  ];

  let tieneUsuario = [
    { value: 2, label: "TODOS"},
    { value: 1, label: "Si" },
    { value: 0, label: "No" },
  ];

  const handleClear = () => {
    setSearchData({
      celular: "",
      nombre: "",
      solicitud: 0,
      fecha: "",
      tieneUsuario: 2,
      estatus: "",
      noSolicitud: "",
    });
    setFiltered([])
  };
  
  return (
    <Card className="card-primary">
      <CardContent>
        <FilterCollapse expand>
          <Grid container spacing={2} alignItems={"center"}>
            <Grid item xs={12} lg={2}>
              <TextField
                label="Celular"
                value={searchData.celular}
                variant="outlined"
                margin="none"
                size="small"
                fullWidth
                onKeyPress={handleKeyPress}
                onChange={(e) => setSearchData({ ...searchData, celular: e.target.value })}
              />
            </Grid>
            <Grid item xs={12} lg={2}>
              <TextField
                label="Nombre"
                value={searchData.nombre}
                onChange={(e) => setSearchData({ ...searchData, nombre: e.target.value })}
                variant="outlined"
                margin="none"
                size="small"
                fullWidth
                onKeyPress={handleKeyPress2}
              />
            </Grid>
            <Grid item xs={12} lg={2}>
              <InputSelect
                label="Tipo Solicitud"
                options={solicitud}
                name="solicitud"
                value={searchData.solicitud}
                onChange={(e) => setSearchData({ ...searchData, solicitud: e.target.value })}
                //isLoading={isLoadingLocal}
                sx={{ width: "100%",}}
              />
            </Grid>
            <Grid item xs={12} lg={2}>
              <TextField
                label="Fecha"
                type="date"
                value={searchData.fecha}
                sx={{ width: "100%" }}
                size="small"
                onChange={(e) => setSearchData({ ...searchData, fecha: e.target.value })}
                InputLabelProps={{
                  shrink: true, 
                }}
              />
            </Grid>
            <Grid item xs={12} lg={2}>
              <InputSelect
                label="Tiene Usuario"
                options={tieneUsuario}
                name="tieneUsuario"
                value={searchData.tieneUsuario}
                onChange={(e) => setSearchData({ ...searchData, tieneUsuario: e.target.value })}
                //isLoading={isLoadingLocal}
                sx={{ width: "100%", height: "100%" }}
              />
            </Grid>
            {/* <Grid item xs={12} lg={1.6}>
              <TextField
                label="No° Solicitud"
                value={searchData.noSolicitud}
                onChange={(e) => setSearchData({ ...searchData, noSolicitud: e.target.value })}
                margin="none"
                size="small"
                fullWidth
                onKeyPress={handleKeyPress}
                sx={{
                  marginBottom: { xs: 1, sm: 0 },
                  marginRight: { xs: 0, sm: 1 },
                }}
              />
            </Grid> */}
            <Grid item xs={12} lg={2}>
              <Grid sx={{ display: "flex" }}>
                <Button
                  variant="contained"
                  color="primaryDark"
                  size="small"
                  onClick={handleSearch}
                  //disabled={isSearchButtonDisabled}
                  sx={{ width: "100%", m: 1 }}
                >
                  buscar
                </Button>
                <Button
                  variant="outlined"
                  color="primaryDark"
                  size="small"
                  onClick={handleClear}
                  sx={{ width: "100%", m: 1 }}
                >
                  Limpiar
                </Button>
              </Grid>
            </Grid>
            <Box marginBottom={2}>
            </Box>
          </Grid>
        </FilterCollapse>
      </CardContent>
    </Card>
  );
};

export default UserAssistFilter;
