import { useState, useEffect } from "react";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";

import { CircularProgress } from "@mui/material";

Highcharts.setOptions({
  lang: {
    thousandsSep: ",",
    numericSymbols: null,
  },
});

const BarColumnTripleHidden = (props) => {
  const { categories, series, title, subtitle, loading = false, initialPosition } = props;

  const [data, setData] = useState(null);

  const mapData = (serie, name, index) => {
    return serie.map((item, i) => {
      if (index !== 0) {
        let percent = 100;
        const meta = series[0].data[i];

        if (index === 1)
          percent = item === 0 ? 0 : item >= meta ? 100 : parseFloat(((item / meta) * 100).toFixed(2));
        if (index === 2) percent = item === 0 ? 0 : parseFloat(((item / meta) * 100).toFixed(2));

        return {
          y: percent,
          value: item,
          name: name,
        };
      }
      return {};
    });
  };

  useEffect(() => {
    // Calcula el porcentaje de avance en relación con la meta y muestra solo el porcentaje para el avance
    const seriesWithPercentages = series.map((item, index) => {
      return {
        name: item.name,
        color: index === 0 ? "" : index === 1 ? "rgba(124,181,236,1)" : " #92d293",
        dataLabels: [
          {
            enabled: true,
            inside: false,
            format: `{point.y}%`,
            style: { fontSize: "0.85em" },
          },
        ],
        data: mapData(item.data, item.name, index),
      };
    });

    if (seriesWithPercentages.length > 1) seriesWithPercentages.shift();

    // Asigna los datos al estado del gráfico
    setData({
      chart: {
        type: "column",
        scrollablePlotArea: {
          minWidth: categories.length * 100,
          scrollPositionX: initialPosition ? 0 : 1,
        },
      },
      title: { text: title },
      subtitle: { text: subtitle },
      xAxis: { categories: categories },
      yAxis: [
        { title: { text: "" }, min: 0 },
        { title: { text: "" }, opposite: true },
      ],
      legend: { shadow: false },
      tooltip: {
        shared: true,
        headerFormat: `<span>{point.x}</span><br/>`,
        pointFormat: `<span style="color:{point.color}">\u25CF</span> <b>{point.name}: {point.value}</b><br/>`,
      },
      series: seriesWithPercentages,
      credits: { enabled: false },
    });
    // eslint-disable-next-line
  }, [categories, series, subtitle, title]);

  return data !== null || loading ? (
    <HighchartsReact highcharts={Highcharts} options={data} />
  ) : (
    <CircularProgress />
  );
};

export default BarColumnTripleHidden;
