import { useEffect, useState } from "react";
import { useSorted } from "@hooks/useSorted";

import { Container, Icon } from "@mui/material";
import { LoadingButton } from "@mui/lab";

import AdminLayout from "@components/MainPage/AdminLayout";
import ActionHeader from "@components/Containers/ActionHeader";
import BasicTable from "@components/Tables/BasicTable";
import FilterMovilization from "@components/Rcos/FilterMovilization";
import CircularProgressPorcent from "@components/LinearProgress/CircularProgressPorcent";

import { COLUMNS_RCO_MOVILIZATION } from "@data/constants/Rco";
import Swal from "sweetalert2";
import rco from "@services/RcosServices";

const Movilization = () => {
  const [total, setTotal] = useState(10);
  const [dataRcos, setDataRcos] = useState([]);
  const [loadingRcos, setLoadingRcos] = useState(false);
  const [data, setData] = useState({
    page: 0,
    pageSize: 25,
    filtered: [],
    sorted: [],
  });
  const [loadingExcelSection, setLoadingExcelSection] = useState(false);
  const { handleRequestSort } = useSorted({ total: total, setData });

  const handleChangePagination = (pagination) => {
    setData({ ...data, ...pagination });
  };

  const getInfoRcos = async (data) => {
    try {
      setLoadingRcos(true);
      const result = await rco.getInfoRcos(data);
      const { response, message, results } = result;
      if (results) {
        setDataRcos(
          response.data.map((item) => ({
            ...item,
            PorcentajeAvance: (
              <CircularProgressPorcent
                size={42}
                fontSize={10}
                value={item.PorcentajeAvance}
              />
            ),
            Seccion: (
              <LoadingButton
                size="small"
                variant="outlined"
                color="primaryDark"
                startIcon={<Icon>download</Icon>}
                loading={loadingExcelSection}
                loadingPosition="start"
                onClick={() => exportExcelSection({ idSeccion: item.Seccion })}
              >
                {item.Seccion}
              </LoadingButton>
            ),
            NoBingo: (
              <LoadingButton
                size="small"
                variant="outlined"
                color="primaryDark"
                startIcon={<Icon>download</Icon>}
                // loading={true}
                loadingPosition="start"
              >
                {item.NoBingo}
              </LoadingButton>
            ),
            DiferenciaBingo: (
              <LoadingButton
                size="small"
                variant="outlined"
                color="primaryDark"
                startIcon={<Icon>download</Icon>}
                // loading={true}
                loadingPosition="start"
              >
                {item.DiferenciaBingo}
              </LoadingButton>
            ),
          }))
        );
        setTotal(response.total);
      } else {
        throw new Error(message);
      }
    } catch (error) {
      Swal.fire({
        title: error,
        icon: "warning",
      });
    } finally {
      setLoadingRcos(false);
    }
  };

  const exportExcelSection = async ({ idSeccion }) => {
    try {
      setLoadingExcelSection(true);
      const result = await rco.export({
        api: "rcos/export-listado-movilizacion-excel",
        params: { Seccion: idSeccion },
      });
      const { success } = result;
      if (success) {
        Swal.fire({
          title: "Descargado con éxito",
          icon: "success",
        });
      } else {
        throw new Error(
          "Ocurrió un error en la descarga, consulte con el administrador"
        );
      }
    } catch (error) {
      Swal.fire({
        title: error,
        icon: "warning",
      });
    } finally {
      setLoadingExcelSection(false);
    }
  };

  const onChangeFilter = ({ filters }) => {
    if (filters.length > 0) {
      setData((prevState) => ({
        ...prevState,
        page: 0,
        filtered: filters,
      }));
    }
  };

  const onClearFilter = () => {
    if (data.filtered.length > 0) {
      setData((prevState) => ({ ...prevState, page: 0, filtered: [] }));
    }
  };

  useEffect(() => {
    getInfoRcos(data);
    // eslint-disable-next-line
  }, [data]);

  return (
    <AdminLayout>
      <Container maxWidth={false}>
        <ActionHeader title={"RCO Movilización"} />
        <FilterMovilization
          onChangeFilter={onChangeFilter}
          onClearFilter={onClearFilter}
        />
        <BasicTable
          rows={dataRcos}
          hcolumns={COLUMNS_RCO_MOVILIZATION}
          total={total}
          pageProp={data.page}
          pageSize={data.pageSize}
          minHeight="0px"
          stickyHeader={true}
          isLoading={loadingRcos}
          handleChangePagination={handleChangePagination}
          handleManualSort={handleRequestSort}
        />
      </Container>
    </AdminLayout>
  );
};

export default Movilization;
