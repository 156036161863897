import React, { Fragment } from "react";
import {
  ListItem,
  Chip,
  ListItemText,
  Divider,
  Button
} from "@mui/material";
import AppRegistrationIcon from '@mui/icons-material/AppRegistration';
import DoneAllIcon from "@mui/icons-material/DoneAll";

const ListItemFA = ({ item, selectedIndex, handleSelectedIndex }) => {
  const handleListItemClick = (event, index) => {
    let data = index;
    handleSelectedIndex(index, data);
  };

  const isSmallScreen = window.innerWidth <= 600;

  return (
    <Fragment key={`item_${item.id}`}>
      <ListItem
        disablePadding
        sx={isSmallScreen ?{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          textAlign: "center",
          padding: "16px",
        }:{}}
        secondaryAction={
          <>
            {window.innerWidth >= 600 ? (
              <Button
                variant="contained"
                endIcon={<AppRegistrationIcon />}
                onClick={(event) => handleListItemClick(event, item)}
              >
                Bingo
              </Button>
            ) : null}
          </>
        }
      >
        <ListItemText
          primary={`${item.Nombre} ${item.Paterno} ${item.Materno}`}
          secondary={
            <>
              {item.Municipio}{" "}
              {item.Voto === 1 && (
                <Chip
                  size="small"
                  label={item.FechaVoto}
                  onDelete={(e) => {}}
                  sx={{
                    color: "white",
                    fontSize: "12px",
                    marginBottom: "8px",
                  }}
                  deleteIcon={<DoneAllIcon size="small" />}
                  color="success"
                />
              )}
            </>
          }
          sx={{
            marginBottom: "8px",
          }}
        />
        {isSmallScreen ? (
          <Button
            variant="contained"
            endIcon={<AppRegistrationIcon />}
            onClick={(event) => handleListItemClick(event, item)}
            sx={{
              marginTop: "8px",
              width: "100%",
            }}
          >
            Bingo
          </Button>
        ) : null}
      </ListItem>
      <Divider variant="inset" component="li" />
    </Fragment>
  );
};

export default ListItemFA;



