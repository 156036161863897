import { Box, Card, CardContent, Stack, Typography } from "@mui/material";
import logo_sian from "@assets/img/c27.png";

const SimplePrivacyPolicy = () => {
  return (
    <Box
      display={"flex"}
      justifyContent={"center"}
      alignItems={"center"}
      padding={4}
    >
      <Card
        className="card-primary"
        sx={{
          maxWidth: 550,
        }}
      >
        <Box bgcolor={"#0041a0"} width={"100%"}>
          <Stack
            direction={"row"}
            spacing={2}
            alignItems={"center"}
            sx={{ padding: "40px 20px" }}
          >
            <Box component={"img"} src={logo_sian} width={80} height={80} />
            <Box>
              <Typography color={"white"} fontWeight={600} variant="h5">
                Aviso de privacidad
              </Typography>
              {/*     <Typography variant="body2" color={"white"}>
                Sistema de Información del Comité Ejecutivo Nacional del PAN
              </Typography> */}
            </Box>
          </Stack>
        </Box>
        <CardContent sx={{ padding: "40px 20px" }}>
          <Typography variant="subtitle2" textAlign={"center"} marginBottom={3}>
            AVISO DE PRIVACIDAD.
          </Typography>

          <Typography variant="body2" marginBottom={4}>
            El Comité Ejecutivo Nacional del Partido Acción Nacional, PAN, es el
            responsable del tratamiento de los datos personales que nos
            proporcione, los cuales serán protegidos conforme a lo dispuesto por
            la Ley General de Protección de Datos Personales en Posesión de
            Sujetos Obligados, y demás normatividad que resulte aplicable.
          </Typography>

          <Typography variant="body2" marginBottom={4}>
            Los datos personales que solicitamos los utilizaremos para elaborar
            un registro de militantes y simpatizantes de los partidos que
            integran el Frente Amplio por México y que manifiesten su deseo de
            ser voluntarios para la consecución de los objetivos políticos, así
            como autenticar su identidad, mantener contacto con usted para temas
            relacionados con el voluntariado y actividades de promoción de
            Xóchitl Gálvez; y contar con información sobre su domicilio
            particular para el desarrollo de las actividades de voluntariado
            acordes a su ubicación.
          </Typography>
          <Typography variant="body2" marginBottom={4}>
            No se realizarán transferencias de datos personales, salvo aquéllas
            que sean necesarias para atender requerimientos de información de
            una autoridad competente, que estén debidamente fundados y
            motivados.
          </Typography>

          {/*   <Typography variant="body2" marginBottom={2}>
            <strong>La finalidad del tratamiento es:</strong>
          </Typography> */}
          <Typography variant="body2" marginBottom={4}>
            Si desea conocer nuestro aviso de privacidad integral, lo podrá
            consultar a través de la página electrónica: <br/>
            <strong>
            <a href= "http://transparencia.pan.org.mx/focalizada.php?id=10" >http://transparencia.pan.org.mx/focalizada.php?id=10</a>
            </strong>
          </Typography>

   
        </CardContent>
      </Card>
    </Box>
  );
};

export default SimplePrivacyPolicy;
