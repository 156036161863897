import { useEffect, useState, useRef } from "react";
import useWindowDimensions from "@hooks/useWindowDimensions";

// Charts
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

// Material UI
import { CircularProgress, Card, CardContent, Typography } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2";
import Swal from "sweetalert2";

// Utilidades
import { numberWithCommas } from "@utils/Utilities/";

const BarChart = ({ dataFiltered, title, idSeries, idCategories, API }) => {
  const { height, width } = useWindowDimensions();
  const [currentDate] = useState(new Date().toLocaleString());
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({
    categories: [],
    seriesData: [],
  });
  const chartRef = useRef(null);

  const handleLegendItemClick = (e) => {
    if (e.target.name === "data") {
      e.preventDefault();
    }
  };

  const options = {
    credits: {
      enabled: false,
    },
    chart: {
      type: "column",
      height: width > 1440 ? height * 0.4 : "auto",
    },
    title: {
      text: ``,
    },

    xAxis: {
      categories: data.categories,
      labels: {
        style: {
          fontSize: width > 1400 ? width * 0.01 : "14px",
        },
      },
    },
    yAxis: {
      title: "",
      labels: {
        style: {
          fontSize: width > 1400 ? width * 0.01 : "14px",
        },
      },
    },
    series: [
      {
        name: "data",
        showInLegend: false,
        data: data.seriesData,
        dataLabels: {
          enabled: true,
          formatter: function () {
            return numberWithCommas(this.y);
          },
          style: {
            color: "#333",
            fontSize: width > 1440 ? width * 0.0085 : "12px",
          },
        },
        events: {
          legendItemClick: handleLegendItemClick,
        },
      },
    ],
    tooltip: {
      formatter: function () {
        return `<span style="font-size: ${
          width > 1440 ? `${width * 0.0085}px` : "12px"
        }">
        <b>${this.point.category}</b><br/>Total: ${numberWithCommas(
          this.point.y
        )}
        </span>`;
      },
    },
  };

  const getNumeraliaTipoSeccionNacional = async (body) => {
    setLoading(true);
    try {
      const result = await API(body);
      const { results, response, message, success } = result;
      if (!results && success) {
        setData({
          ...data,
          categories: [],
          seriesData: [],
        });
        return;
      }

      if (!results && !success) {
        throw new Error(message);
      }

      setData((prev) => ({
        ...prev,
        categories: response.data.map((item) => item[idCategories]),
        seriesData: response.data.map((item) => parseInt(item[idSeries])),
      }));
    } catch (error) {
      Swal.fire({
        title: error.message,
        icon: "warning",
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const body = {
      idAmbitoAgrupador: dataFiltered.idAmbitoAgrupador,
      filtered: dataFiltered.filtered,
      idEstado: dataFiltered.idEstado,
    };
    dataFiltered.idEstado === null && delete body.idEstado;

    getNumeraliaTipoSeccionNacional(body);
  }, [dataFiltered]);

  return (
    <Card className="card-primary">
      <CardContent>
        <div>
          <Typography variant="subtitle2" fontWeight={600} textAlign={"end"}>
            {title}
          </Typography>
          <Typography variant="body2" textAlign={"end"} color={"GrayText"}>
            {currentDate}
          </Typography>
        </div>
        {loading ? (
          <Grid2
            container
            xs={12}
            height={width > 1440 ? height * 0.4 : 400}
            alignItems="center"
            justifyContent="center"
          >
            <CircularProgress size={50} />
          </Grid2>
        ) : (
          <HighchartsReact
            highcharts={Highcharts}
            options={options}
            ref={chartRef}
          />
        )}
      </CardContent>
    </Card>
  );
};

export default BarChart;
