import { useEffect, useState, useRef } from "react";

// Charts
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

// Material UI
import { CircularProgress, Card, CardContent } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2";
import Swal from "sweetalert2";

// Utilidades
import { numberWithCommas } from "@utils/Utilities/";

// Hooks
import useWindowDimensions from "@hooks/useWindowDimensions";

const PieChart = (props) => {
  const { dataAPI, filtered, title = "titulo", change } = props;

  const [localTitle, setLocalTitle] = useState(title);
  const [actualDate, setActualDate] = useState(new Date().toLocaleString());
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const chartRef = useRef(null);

  const { height, width } = useWindowDimensions();

  const fetchData = async () => {
    setLoading(true);
    try {
      const result = await dataAPI(filtered);
      const { results, message, response } = result;

      if (results) {
        const responseData = response.data[0];
        const newData = [
          {
            data: responseData.TotalMujeres ? responseData.TotalMujeres : 0,
            label: "Mujeres",
          },
          {
            data: responseData.TotalHombres ? responseData.TotalHombres : 0,
            label: "Hombres",
          },
        ];

        if (responseData.TotalNoBinario) {
          newData.push({
            data: responseData.TotalNoBinario ? responseData.TotalNoBinario : 0,
            label: "No Binario",
          });
        }

        setData(newData);

        /* if (chartRef.current) {
          const chart = chartRef.current.chart;
          chart.update({
            subtitle: {
              text: `${new Date().toLocaleString()}`,
            },
          });
        } */
      } else {
        Swal.fire({
          title: title,
          text: message,
          icon: "warning",
        });
        setData([]);
      }
    } catch (errors) {
      Swal.fire({
        title: errors.message,
        icon: "warning",
      });
      setData([]);
    } finally {
      setLocalTitle(title);
      setActualDate(new Date().toLocaleString());
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [change]);

  const colors = ["#FF6486", "#37A3EB", "#999999"];

  const seriesData = data.map((item, index) => ({
    name: `<span style="color: ${colors[index]}">${
      item.label
    } ${numberWithCommas(item.data)}</span>`,
    y: parseFloat(item.data),
    x: item.label,
    color: colors[index],
    dataLabels: {
      style: {
        fontSize: width > 1440 ? width * 0.009 : "13px",
        color: colors[index],
      },
    },
  }));

  // Opciones del chart
  const options = {
    credits: {
      enabled: false,
    },
    chart: {
      type: "pie",
      height: width > 1440 ? height * 0.4 : "auto",
    },
    title: {
      text: `${localTitle.toUpperCase()}`,
      align: "right",
      style: {
        color: "#999",
        fontSize: width > 1440 ? width * 0.01 : "18px",
      },
    },
    subtitle: {
      text: `${actualDate}`,
      align: "right",
      style: {
        color: "#666",
        fontSize: width > 1440 ? width * 0.008 : "12px",
      },
    },
    xAxis: {
      categories: data.map((item) => item.Estatus),
    },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        dataLabels: {
          enabled: true,
          format: "{point.percentage:.1f} %", // El porcentaje
          distance: 0.1,
        },
        showInLegend: true,
      },
    },
    legend: {
      itemStyle: {
        fontSize: width > 1440 ? `${width * 0.0085}px` : "12px",
      },
    },
    tooltip: {
      formatter: function () {
        const regex = /<span[^>]*>([^<]+)<\/span>/;
        const coincidencia = this.point.name.match(regex);
        const partes = coincidencia[1].split(" ");
        const resultado =
          partes.length === 3 ? partes[0] + " " + partes[1] : partes[0];
        // Formato personalizado del tooltip
        return `<span style="font-size: ${
          width > 1440 ? `${width * 0.0085}px` : "12px"
        }"><b>${resultado}  ${this.point.percentage.toFixed(1)} %</b>
      <br/>Total: ${numberWithCommas(this.point.y)}</span>`; // Muestra el nombre y el valor total en el tooltip
      },
    },
    series: [
      {
        type: "pie",
        name: "Total",
        data: seriesData,
      },
    ],
  };

  return (
    <Card className="card-primary">
      <CardContent>
        {loading ? (
          <Grid2
            container
            xs={12}
            height={width > 1440 ? height * 0.4 : 400}
            alignItems="center"
            justifyContent="center"
          >
            <CircularProgress size={50} />
          </Grid2>
        ) : (
          <HighchartsReact
            highcharts={Highcharts}
            options={options}
            ref={chartRef}
          />
        )}
      </CardContent>
    </Card>
  );
};

export default PieChart;
