import { useState, useEffect } from "react";
import {
  Button,
  Card,
  CardContent,
  Container,
  Icon,
  Typography,
  Box,
  LinearProgress
} from "@mui/material";

import Swal from "sweetalert2";
import Download from "@components/Downloads/Download";
import "moment/locale/es";
import MessageAlert from "@components/Downloads/MessageAlert";
import AdminLayout from "@components/MainPage/AdminLayout";
import BasicTable from "@components/Tables/BasicTable";
import ActionHeader from "@components/Containers/ActionHeader";
import FilterMovilizador from "../../components/FrenteAmplio/FilterMovilizador";
import FrenteAmplioServices from "@services/FrenteAmplioServices";
import { isEmptyOrNullObject } from "@utils/validations";
import middleware from "@middlewares/middleware";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";

const ListAsignacion = () => {
  //Constantes
  const colums = [
    {
      label: "Filtros",
      id: "Filtros",
      columnAction: false,
      orderBy: false,
      width: 200,
    },
    {
      label: "Movilizador",
      id: "Movilizador",
      columnAction: false,
      orderBy: "Movilizador",
      width: 200,
    },
    {
      label: "A Movilizar",
      id: "Resultados",
      orderBy: "Resultados",
      columnAction: false,
      width: 50,
    },
    {
      label: "Liderazgo",
      id: "Liderazgo",
      orderBy: "Liderazgo",
      columnAction: false,
      width: 200,
    },
    {
      label: "Fecha Asignación",
      id: "FechaCreo",
      orderBy: "FechaCreo",
      columnAction: false,
      width: 100,
    },
    {
      label: "",
      id: "Exportar",
      orderBy: false,
      columnAction: false,
      width: 20,
    },
  ];

  const [data, setData] = useState({
    page: 0,
    pageSize: 10,
    filtered: [],
    sorted: [],
  });

  //State
  const [total, setTotal] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [dataLisCommitments, setDataLisCommitments] = useState([]);
  const [sortedData, setSortedData] = useState([]);
  const [successDownload, setSuccessDownload] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);


 const handleExport = async (id) => {
    const itemToExport = dataLisCommitments.find((item) => item.id === id);
  
    if (itemToExport) {

      itemToExport.Exportar = (<LinearProgress color="success" />);
      setDataLisCommitments([...dataLisCommitments]);
      const movilizador =  itemToExport.Movilizador.props.children;;
      const municipio = itemToExport.Municipios;
      const capturistas = itemToExport.Capturistas;
      const colonias = itemToExport.Colonias;
      const secciones = itemToExport.Secciones;
      const estatus = itemToExport.Estatus;
      
      // Crea un mensaje de error personalizado con los valores extraídos.
      const mensajeDeError = ` 
      Movilizador: ${movilizador}
      Municipio: ${municipio}
      Capturistas: ${capturistas}
      Colonias: ${colonias}
      Secciones: ${secciones}
      Estatus: ${estatus}`;

      try {
        await FrenteAmplioServices.ExportMyListMovilizacion({
          filtered: data.filtered,
          idList:id,
          page: 0,
          pageSize: 99999999999999,
          export:true
        }) .then((res) => {
          if (res.success) {
          } else {

            Swal.fire({ title: "No se pudo descargar el archivo.",text:mensajeDeError, icon: "warning" });
          }
        })
        .catch((error) => {
          Swal.fire({ title: "No se pudo descargar el archivo.",text:mensajeDeError, icon: "warning" });
        })
        
      } catch (error) {
        Swal.fire({ title: "No se pudo descargar el archivo.",text:mensajeDeError, icon: "warning" });
      } finally {
        itemToExport.Exportar = ( <Button
          variant="contained"
          color="primaryDark"
          startIcon={<Icon>download</Icon>}
          size="small"
          onClick={() => handleExport(id)}
          sx={{ borderRadius: "10px" }}
        >Exportar</Button>);
        setDataLisCommitments([...dataLisCommitments]);
      }
    }
  }; 

/*   const handleExport = async (id) => {
    const itemToExport = dataLisCommitments.find((item) => item.id === id);

    if (itemToExport) {
      await  FrenteAmplioServices.getListMovilizadores({
        filtered: data.filtered,
        page: 0,
        pageSize: 99999999999999,
        "export":true
      })
        .then((res) => {
          if (res.success) {
            itemToExport.Exportar = ( <Button
              variant="contained"
              color="primaryDark"
              startIcon={<Icon>download</Icon>}
              size="small"
              onClick={() => handleExport(id)}
              sx={{ borderRadius: "10px" }}
            >Exportar</Button>);
            setDataLisCommitments([...dataLisCommitments]);
          } else {
            setShowMessage(true);
            setSuccessDownload(false);
          }
        })
        .catch((error) => {
          setShowMessage(true);
          setSuccessDownload(false);
        });
    }
 
  }; */
 

  const getList = (data) => {
    setIsLoading(true);
    FrenteAmplioServices.getListMovilizadores(data)
      .then((res) => {
        if (res.results) {
          const list = res.response.data.map((item) => {
            return {
              ...item,
              Movilizador: (
                <Typography variant="div" fontWeight={700} fontSize={15}>
                  {`${item.Movilizador}`}
                </Typography>
              ),
              Filtros: (
                <Grid2 conteiner>
                  <Grid2 item xs={12} sm={12} md={3} lg={3}>
                    <Typography variant="caption" display="block">
                      Municipio: <strong>{item.Municipios}</strong>
                    </Typography>
                  </Grid2>
                  <Grid2 item xs={3} sm={3} md={3} lg={3}>
                    <Typography variant="caption" display="block">
                    Capturistas: <strong>{item.Capturistas}</strong>
                    </Typography>
                  </Grid2>
                  <Grid2 item xs={3} sm={3} md={3} lg={3}>
                    <Typography variant="caption" display="block">
                    Colonias: <strong>{item.Colonias}</strong>
                    </Typography>
                  </Grid2>
                  <Grid2 item xs={3} sm={3} md={3} lg={3}>
                    <Typography variant="caption" display="block">
                    Secciones: <strong>{item.Secciones}</strong>
                    </Typography>
                  </Grid2>
                  <Grid2 item xs={3} sm={3} md={3} lg={3}>
                    <Typography variant="caption" display="block">
                    Estatus: <strong>{item.Estatus}</strong>
                    </Typography>
                  </Grid2>
                </Grid2>
              ),
              Exportar: (
                <Button
                  variant="contained"
                  color="primaryDark"
                  startIcon={<Icon>download</Icon>}
                  size="small"
                  onClick={() => handleExport(item.id)}
                  sx={{ borderRadius: "10px" }} 
                >
                    Exportar
                </Button>
              )
            };
          });
          setDataLisCommitments(list);
          setTotal(res.response.total);
        } else {
          Swal.fire({ title: res.message, icon: "warning" });
          setDataLisCommitments([]);
          setTotal(0);
        }
      })
      .catch((err) => {
        Swal.fire({ title: err, icon: "error" });
        setDataLisCommitments([]);
        setTotal(0);
      })
      .finally(() => setIsLoading(false));
    // eslint-disable-next-line
  };

  useEffect(() => {
    getList(data);
    // eslint-disable-next-line
  }, [data]);

  useEffect(() => {
    if (sortedData.length > 0) {
      const params = {
        ...data,
        sorted: sortedData,
      };

      getList(params);
    }
    // eslint-disable-next-line
  }, [sortedData]);

  const handleChangeFilter = (filtered) => {
    setDataLisCommitments([]);
    setData((prevState) => ({ ...prevState,page:0, filtered }));
  };

  const handleChangePagination = (pagination) => {
    setData({ ...data, ...pagination });
  };

  const handleSorted = (dataSorted) => {
    const fields = {
      Liderazgo: "CONCAT_WS(' ',usuarios.Username,usuarios.Nombre,usuarios.Paterno)",
      Resultados: "fam_listas_movilizacion.Resultados",
      Movilizador:"CONCAT_WS(' ',usuarios_movilizador.Username,usuarios_movilizador.Nombre,usuarios_movilizador.Paterno)",
      FechaCreo:"fam_listas_movilizacion.FechaCreo"
    };

    let sorted = [];
    if (!isEmptyOrNullObject(dataSorted)) {
      sorted.push({
        id: fields[dataSorted.orderBy],
        value: dataSorted.order,
      });
    }

    return sorted;
  };

  const handleRequestSort = (sortData) => {
    if (total > 0) setSortedData(handleSorted(sortData));
  };

  return (
    <AdminLayout>
      <Container maxWidth="false">
        <ActionHeader title={"Listado Movilizadores"} />
        <Box>
          <FilterMovilizador onChangeFilter={(e) => handleChangeFilter(e)} />
          <Card className="card-primary">
            <CardContent>
              {/* 
            {middleware.checkMenuActionId("Exportar") && (<>
              {isDownloading ? (
                <Download isDownload={isDownloading} format="xlsx" />
              ) : (
                <Box
                  display={"flex"}
                  justifyContent={"flex-end"}
                  sx={{ mb: 2 }}
                >
                  <Button
                    variant="outlined"
                    color="primaryDark"
                    startIcon={<Icon>download</Icon>}
                    size="small"
                    onClick={handleExport}
                    sx={{ borderRadius: "10px" }}
                  >
                    Exportar
                  </Button>
                </Box>
              )}
              </>)}
              {showMessage && (
                <Box marginTop={2}>
                  <MessageAlert
                    showMessage={showMessage}
                    successDownload={successDownload}
                    setShowMessage={setShowMessage}
                  />
                </Box>
              )} */}
              <BasicTable
                rows={dataLisCommitments}
                hcolumns={colums}
                handleChangePagination={handleChangePagination}
                isLoading={isLoading}
                total={total}
                pageProp={data.page}
                pageSize={data.pageSize}
                stickyHeader={true}
                handleManualSort={handleRequestSort}
              />
            </CardContent>
          </Card>
        </Box>
      </Container>
    </AdminLayout>
  );
};

export default ListAsignacion;
