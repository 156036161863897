import { useDropzone } from "react-dropzone";
import UploadFile from "@assets/img/upload_files.svg";

// material
import { Box, Typography, styled } from "@mui/material";
import { useState, useEffect } from "react";

// ----------------------------------------------------------------------
const DropZoneStyle = styled("div")(({ theme, isValid, error, inicialState, height }) => ({
  outline: "none",
  display: "flex",
  overflow: "hidden",
  textAlign: "center",
  position: "relative",
  alignItems: "center",
  flexDirection: "column",
  justifyContent: "center",
  padding: theme.spacing(5, 1),
  borderRadius: 6,
  transition: theme.transitions.create("padding"),
  backgroundColor: inicialState ? theme.palette.grey[50] : isValid ? (error ? "#FFC3C3" : "#E2FFE5") : "#FFC3C3",
  border: `1px dashed ${theme.palette.grey[400]}`,
  height: height || "auto",
  "&:hover": {
    opacity: 0.72,
    cursor: "pointer",
  },
  [theme.breakpoints.up("md")]: { textAlign: "left", flexDirection: "row" },
  [theme.breakpoints.down("md")]: { padding: theme.spacing(1, 1) },
}));

// ----------------------------------------------------------------------

const UploadSingleFile = ({ setResumeData = () => {}, handleFileUpload, acceptFiles, error = true, reset= false, fileName = "Archivo", sx, height, ...other }) => {
  const [isFileTypeError, setIsFileTypeError] = useState(false);
  const [inicialState, setInicialState] = useState(true);

  const onDrop = (acceptedFiles) => {
    const file = acceptedFiles[0];
    if (file && file.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
      setIsFileTypeError(false); // Reiniciar el estado de error de tipo de archivo
      handleFileUpload(file);
    } else setIsFileTypeError(true);
    setInicialState(false);
    setResumeData();
  };

  const { getRootProps, getInputProps, isDragActive, isDragReject } = useDropzone({
    multiple: false,
    onDrop,
    ...other,
  });

  useEffect(() => {
    if (reset) {
      setInicialState(true);
      setIsFileTypeError(false);
    }
  }, [reset]);

  return (
    <Box sx={{ width: "100%", ...sx }}>
      <DropZoneStyle
        {...getRootProps()}
        sx={{
          ...(isDragActive && { opacity: 0.72 }),
          ...(isDragReject && {
            color: "error.main",
            borderColor: "error.light",
            bgcolor: "error.lighter",
          }),
        }}
        isValid={!isFileTypeError}
        error={error}
        inicialState={inicialState}
        height={height}
      >
        <input {...getInputProps()} accept={acceptFiles} />

        <Box component="img" alt="avatar upload" src={UploadFile} width={140} />

        <Box
          sx={{
            px: { xs: 1, md: 2, lg: 3 },
            pt: 3,
            pb: { xs: 1, md: 3 },
            ml: { md: 2 },
          }}
        >
          <Typography gutterBottom variant="h5">
            Arrastre o seleccione el {fileName}
          </Typography>

          <Typography variant="body2" sx={{ color: "text.secondary" }}>
            Coloque el {fileName.toLowerCase()} aquí o haga click en&nbsp;
            <Typography variant="body2" component="span" sx={{ color: "primary.main", textDecoration: "underline" }}>
              navegar
            </Typography>
            &nbsp;a través de su máquina
          </Typography>

          {!inicialState && (
            <Typography gutterBottom variant="h6" margin={0} marginTop={2} color={isFileTypeError ? "#9A3939" : error ? "#9A3939" : "#5BA681"}>
              {isFileTypeError ? "Solo se permiten archivos " + acceptFiles : "Archivo permitido" + (error ? " (con errores)" : "")}
            </Typography>
          )}
        </Box>
      </DropZoneStyle>
    </Box>
  );
};

export default UploadSingleFile;
