import {
  Box,
  Card,
  CardContent,
  Icon,
  Stack,
  Typography,
} from "@mui/material";

const CardLayout = ({
  children,
  bgColor,
  color,
  icon,
  title,
  subtitle,
  component,
  sx,
}) => {
  return (
    <Card className="card-secondary" sx={sx}>
      <CardContent>
        <Stack
          direction={"row"}
          //spacing={2}
          alignItems="center"
          justifyContent={"space-between"}
        >
          <Stack direction="row" spacing={2}>
            <Box
              sx={{
                backgroundColor: bgColor,
                padding: "10px",
                borderRadius: "10px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Icon sx={{ color: color }}>{icon}</Icon>
            </Box>
            <Box>
              <Typography variant="body2" fontWeight={700}>
                {title}
              </Typography>
              {subtitle && (
                <Typography variant="body2">
                  {subtitle.toUpperCase()}
                </Typography>
              )}
            </Box>
          </Stack>
          <Box width={"40%"}>{component}</Box>
        </Stack>
        <Box marginTop={3}>{children}</Box>
      </CardContent>
    </Card>
  );
};

export default CardLayout;
