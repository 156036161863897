import React, { useCallback, useEffect, useState } from "react";

import {
  Button,
  Card,
  CardContent,
  Container,
  Grid,
  Icon,
  Stack,
  TextField,
  Typography,
} from "@mui/material";

import AdminLayout from "@components/MainPage/AdminLayout";
import ActionHeader from "@components/Containers/ActionHeader";
import BasicTable from "@components/Tables/BasicTable";
import Popup from "@components/Attendance/Popup";

import CompromisoServices from "@services/CompromisoServices";
import Swal from "sweetalert2";
import { isEmptyArray, useFormik } from "formik";
import { AttendanceFilterInterface } from "@data/interfaces/CompromisoInterfaces";
import { AttendanceFilterSchema } from "@data/schemas/CompromisoSchemas";
import { isTypePhone } from "@utils/validations";
import moment from "moment";

const Attendance = () => {
  const colums = [
    { id: "Region", label: "Región", columnAction: false },
    { id: "Municipio", label: "Municipio", columnAction: false },
    { id: "NombreCompleto", label: "Nombre", columnAction: false },
    {
      id: "FechaPaseLista",
      label: "Fecha de Pase de Lista",
      columnAction: false,
    },
  ];
  const [data, setData] = useState({
    page: 0,
    pageSize: 10,
    filtered: [
      {
        id: "simulacro.FechaCreo",
        filter: "IS NOT",
        value: null,
      },
    ],
    sorted: [{ id: "simulacro.FechaCreo", value: "desc" }],
  });

  const [total, setTotal] = useState(0);
  const [totalFilter, setTotalFilter] = useState(0);
  const [dataAttendance, setDataAttendance] = useState([]);
  const [dataAttendanceFilter, setDataAttendanceFilter] = useState([]);

  const [dataValuesFilter, setDataValuesFilter] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingFilter, setIsLoadingFilter] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  const formik = useFormik({
    initialValues: AttendanceFilterInterface,
    validationSchema: AttendanceFilterSchema,
    onSubmit: (values) => {
      handleSearchFilter(values);
    },
  });

  const handleChangePagination = (pagination) => {
    setData({ ...data, ...pagination });
  };

  const handleSearchFilter = (values) => {
    setDataValuesFilter(values);
    const { celular, nombre } = values;
    let objFilters = [];

    if (formik.values.celular !== "") {
      objFilters = [
        ...objFilters,
        { id: "Celular", filter: "=", value: celular },
      ];
    }
    if (formik.values.nombre !== "") {
      objFilters = [
        ...objFilters,
        {
          id: ["Nombre", "Paterno", "Materno"],
          filter: "LIKE",
          value: nombre,
        },
      ];
    }

    setData({ ...data, filtered: objFilters });
    setOpenModal(true);
  };

  const handleRefresh = () => {
    getListVerification(data);
    getListVerificationFilter(data);
  };

  const handleClearFilter = () => {
    formik.setValues({
      ...formik,
      celular: "",
      nombre: "",
    });
    /* setData({
      ...data,
      filtered: [
        {
          id: "simulacro.FechaCreo",
          filter: "IS NOT",
          value: null,
        },
      ],
    }); */
  };

  const getListVerification = useCallback((data) => {
    setIsLoading(true);
    CompromisoServices.getListVerification(data)
      .then((res) => {
        if (res.results) {
          const response = res.response.data.map((item) => {
            return {
              ...item,
              FechaPaseLista: moment(item.FechaPaseLista).format(
                "DD/MM/YYYY H:mm"
              ),
            };
          });
          setDataAttendance(response);
          setTotal(res.response.total);
        } else {
          Swal.fire({ title: res.message, icon: "warning" });
        }
      })
      .catch((err) => Swal.fire({ title: err, icon: "warning" }))
      .finally(() => setIsLoading(false));
  }, []);

  const getListVerificationFilter = (data) => {
    setIsLoadingFilter(true);
    CompromisoServices.getListVerification(data)
      .then((res) => {
        if (res.results) {
          const response = res.response.data;
          setDataAttendanceFilter(response);
          setTotalFilter(res.response.total);
        } else {
          Swal.fire({ title: res.message, icon: "warning" });
        }
      })
      .catch((err) => Swal.fire({ title: err, icon: "warning" }))
      .finally(() => setIsLoadingFilter(false));
  };

  useEffect(() => {
    if (!isEmptyArray(data.filtered)) {
      if (data.filtered[0].id === "simulacro.FechaCreo") {
        getListVerification(data);
      } else {
        getListVerificationFilter(data);
      }
    }
    // eslint-disable-next-line
  }, [data]);

  const handleOnKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      formik.submitForm();
    }
  };

  return (
    <AdminLayout>
      <Container maxWidth={false}>
        <ActionHeader title="Pase Lista" />
        <Card className="card-primary">
          <CardContent>
            <Stack
              direction="row"
              spacing={1}
              marginBottom={2}
              alignItems="center"
            >
              <Icon>search</Icon>
              <Typography variant="subtitle2" fontWeight={600}>
                Busca al usuario para el pase de lista
              </Typography>
            </Stack>

            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <TextField
                  sx={{ width: "100%" }}
                  label="Celular"
                  name="celular"
                  size="small"
                  className="fixed-input"
                  variant="outlined"
                  value={formik.values.celular}
                  onKeyUp={handleOnKeyPress}
                  onChange={(e) =>
                    formik.handleChange({
                      target: {
                        name: "celular",
                        value: isTypePhone(e.target.value)
                          ? e.target.value
                          : formik.values.celular,
                      },
                    })
                  }
                  onBlur={(e) =>
                    formik.handleChange({
                      target: {
                        name: "celular",
                        value: isTypePhone(e.target.value)
                          ? e.target.value
                          : formik.values.celular,
                      },
                    })
                  }
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <TextField
                  sx={{ width: "100%" }}
                  label="Nombre"
                  name="nombre"
                  size="small"
                  className="fixed-input"
                  variant="outlined"
                  value={formik.values.nombre.toLocaleUpperCase()}
                  onKeyUp={handleOnKeyPress}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={2} lg={2}>
                <Stack direction={"row"}>
                  <Button
                    variant="contained"
                    color="primaryDark"
                    onClick={formik.submitForm}
                  >
                    Buscar
                  </Button>
                  <Button
                    variant="text"
                    color="primaryDark"
                    sx={{ ml: 1 }}
                    onClick={handleClearFilter}
                  >
                    Limpiar
                  </Button>
                </Stack>
              </Grid>
            </Grid>
          </CardContent>
        </Card>

        <Card className="card-primary">
          <CardContent>
            <BasicTable
              rows={dataAttendance}
              hcolumns={colums}
              handleChangePagination={handleChangePagination}
              total={total}
              pageProp={data.page}
              pageSize={data.pageSize}
              isLoading={isLoading}
              stickyHeader={true}
            />
          </CardContent>
        </Card>

        <Popup
          open={openModal}
          setOpen={setOpenModal}
          dataFound={dataAttendanceFilter}
          setDataFound={setDataAttendanceFilter}
          total={totalFilter}
          dataFilter={dataValuesFilter}
          handleRefresh={handleRefresh}
          setData={setData}
          isLoading={isLoadingFilter}
        />
      </Container>
    </AdminLayout>
  );
};

export default Attendance;
