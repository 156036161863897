import { useState } from "react";

//Mui
import { Alert, Container, Typography, Box, Button, Icon, Card, CardContent, Stack, Divider, ListItemText, List, ListItem } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { green, grey, red } from "@mui/material/colors";
import { useNavigate } from "react-router-dom";

import Swal from "sweetalert2";

//Components
import AdminLayout from "@components/MainPage/AdminLayout";
import ActionHeader from "@components/Containers/ActionHeader";
import UploadSingleFile from "@components/Files/UploadSingleExcelFile";
import CustomTable from "@components/Tables/CustomTable";
import Download from "@components/Downloads/Download";
import { processExcelFile } from "@components/Movilizadores/excelProcessor";
import ChipResume from "@components/Movilizadores/ChipResume";
import DownloadButton from "@components/Movilizadores/DownloadButton";
// import generateExcelTemplate from "@components/Movilizadores/generateExcelTemplate";

//UTILS
import { numberWithCommas } from "@utils/Utilities/";

//SERVICES
import MovilizadoresServices from "@services/MovilizadoresServices";

const UploadInformation = () => {
  const [errorData, setErrorData] = useState([]);
  const [fileDataWithError, setFileDataWithError] = useState([]);
  const [fileData, setFileData] = useState([]);
  const [resetUpload, setResetUpload] = useState(false);
  const [page, setPage] = useState(0);
  const [pageSize] = useState(100);
  const excelRowStart = 8;
  const navigate = useNavigate();
  const [isDownload, setIsDownloading] = useState(false);

  const columns_required = [
    { id: 0, name: "Nombre" },
    { id: 1, name: "Paterno" },
    { id: 2, name: "Materno" },
    { id: 3, name: "Celular" },
    { id: 5, name: "Sección" },
  ];

  const columns = [
    { id: "Celular", label: "Celular", type: "text" },
    { id: "NombreCompleto", label: "Movilizador", type: "text" },
    { id: "Seccion", label: "Sección", type: "text" },
  ];

  const stylesIcons = {
    false: { icon: "check_circle", color: green[700] },
    true: { icon: "warning", color: red[700] },
    null: { icon: "info", color: grey[500] },
  };

  const [isLoadingImport, setIsLoadingImport] = useState({
    message: "Cargando",
    successMessage: "Cargado con exito!",
    isLoading: false,
    open: false,
  });

  const downloadExcelTemplate = async () => {
    setIsDownloading(true);

    try {
      const result = await MovilizadoresServices.downloadTemplate();
      const { success, error } = result;
      if (!success) {
        throw new Error(error.message);
      }
      Swal.fire({
        title: "Descargado con éxito",
        icon: "success",
      });
    } catch (error) {
      Swal.fire({
        title: error,
        icon: "warning",
      });
    }finally{
      setIsDownloading(false);
    }
  }

  const validExcel = async (file) => {
    try {
      setIsLoadingImport({
        ...isLoadingImport,
        isLoading: true,
        open: true,
      });
      setErrorData([]);
      setFileData([]);
      setPage(0);

      const { data, error } = await processExcelFile(file, 8, 1);
      
      const localDataUse = data.map((row) => {
        const { Sección, ...rest } = row;
        return { ...rest, Seccion: Sección };
      });

      if (error.length > 0) {
        setErrorData(error);
        setFileDataWithError(localDataUse);
        return;
      }
      if (data.length === 0 && error.length === 0) {
        setResetUpload((prev) => !prev);
        return;
      }

      const upLoadData = data.map((row) => {
        const { Sección, NombreCompleto, ...rest } = row;
        return { ...rest, Seccion: Sección };
      });

      const result = await MovilizadoresServices.importMovilizadores({ data: upLoadData });
      const { success, results, message, errors } = result;
      if (results) {
        await setFileData(localDataUse);
      } else {
        const errorsWithDetails = errors.map((error) => {
          return {
            row: error.path[1] + excelRowStart,
            column: error.path[2],
            message: error.message,
          };
        });

        setErrorData(errorsWithDetails);
        setFileDataWithError(localDataUse);
      }
      if (!success) throw new Error(message);
    } catch (error) {
      Swal.fire({ title: error.message, icon: "warning" });
    } finally {
      setIsLoadingImport({
        ...isLoadingImport,
        isLoading: false,
        open: false,
      });
    }
  };

  return (
    <AdminLayout>
      <Container maxWidth={false}>
        <ActionHeader 
          title="Carga de información"
          isAction={true}
          titleButton="Regresar"
          iconButton="arrow_back"
          handleclick={() => navigate(-1)}
        />

        <Card className="card-primary">
          <Stack direction={"row"} alignItems={"center"} gap={1} sx={{ p: 2 }}>
            <Icon fontSize="small">upload</Icon>
            <Typography variant="body2" fontWeight={600}>
              Importar información
            </Typography>
          </Stack>
          <Divider />
          <CardContent>
            <Grid container spacing={3}>
              <Grid xs={12} md={3}>
                <Alert severity="info" sx={{ mb: 2 }}>
                  ¡Bienvenido a la carga de archivos! Para proceder, descarga la plantilla Excel haciendo clic en el botón "Descargar Excel
                  Plantilla". Luego, carga el archivo en el campo de la derecha con la información solicitada.
                </Alert>

                <Typography variant="body2" fontWeight={600} marginBottom={2}>
                  Columnas requeridas
                </Typography>
                <Divider sx={{ mb: 2 }} />
                <List sx={{ mb: 2 }}>
                  {columns_required.map((column) => {
                    const columnErrors = errorData.filter((error) => error.column === column.name);
                    const hasError = columnErrors.length > 0;
                    const shouldShowInfo = errorData.length === 0 && fileData.length === 0;

                    return (
                      <ListItem
                        key={column.id}
                        secondaryAction={
                          <Icon
                            fontSize="small"
                            sx={{
                              color: shouldShowInfo ? stylesIcons[null].color : hasError ? stylesIcons[true].color : stylesIcons[false].color,
                              animation: isLoadingImport.isLoading ? "spin 1s linear infinite" : "none",
                            }}
                          >
                            {isLoadingImport.isLoading
                              ? "autorenew"
                              : shouldShowInfo
                              ? stylesIcons[null].icon
                              : hasError
                              ? stylesIcons[true].icon
                              : stylesIcons[false].icon}
                          </Icon>
                        }
                        disablePadding
                      >
                        <ListItemText primary={<Typography variant="body2">{column.name}</Typography>} />
                      </ListItem>
                    );
                  })}
                </List>
                {!isDownload && (
                  <Button  
                    variant="contained"
                    color="primaryDark"
                    size="small"
                    onClick={()=>{downloadExcelTemplate()}}
                    startIcon={<Icon>file_download</Icon>} 
                    sx={{ float: "right" }}
                  >
                    Descargar Plantilla
                  </Button>
                )}

                {isDownload && (
                  <Box marginBottom={2}>
                    <Download format={"xlsx"} isDownload={isDownload} />
                  </Box>
                )}
              </Grid>
              <Grid xs={12} md={9}>
                <UploadSingleFile
                  handleFileUpload={validExcel}
                  error={errorData.length && !isLoadingImport.isLoading}
                  fileName="Excel"
                  acceptFiles=".xlsx"
                  height={errorData.length > 0 && !isLoadingImport.isLoading ? 320 : isLoadingImport.isLoading ? 280 : 375}
                  reset={resetUpload}
                />
                {errorData.length > 0 && !isLoadingImport.isLoading ? (
                  <Grid container spacing={2} justifyContent="center">
                    <Grid item xs={12} md={6} lg={4}>
                      <Box display="flex" justifyContent="space-between" alignItems="center" mt={2} gap={2}>
                        <Box>
                          <DownloadButton
                            text="Descargar Formato con errores"
                            fileData={fileDataWithError}
                            errorData={errorData}
                            rowStart={excelRowStart}
                          />
                        </Box>
                        <Box>
                          <ChipResume text={"Errores encontrados: " + numberWithCommas(errorData.length)} color={errorData ? "#E55F5F" : "#77BB99"} />
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                ) : null}
                {isLoadingImport.isLoading && <Download format={"xlsx"} isDownload={isLoadingImport.isLoading} label="Cargando información..." />}
              </Grid>
            </Grid>
            <Box marginTop={4}>
              <Stack direction={"row"} alignItems={"center"} gap={1} marginBottom={2}>
                <Icon fontSize="small">info</Icon>
                <Typography variant="body2" fontWeight={600}>
                  Información cargada
                </Typography>
              </Stack>
              <Divider sx={{ mb: errorData.length > 0 ? null : 2 }} />
              {errorData.length > 0 && (
                <Box sx={{ width: "100%", display: "flex", justifyContent: "center", mb: 2 }}>
                  <Alert severity="info" sx={{ mt: 2, maxWidth: "fit-content" }}>
                    No se pueden cargar los datos debido a que existen errores. Se sugiere corregir el archivo Excel y volver a subirlo, o subir solo
                    los datos que cumplan con la validación.
                  </Alert>
                </Box>
              )}
              <CustomTable
                rows={fileData}
                columns={columns}
                page={page}
                pageSize={pageSize}
                stickyHeader={true}
                isLoading={isLoadingImport.isLoading}
                maxHeight={400}
                disableCardType
              />
            </Box>
          </CardContent>
        </Card>
      </Container>
    </AdminLayout>
  );
};

export default UploadInformation;
