// Material UI
import { Card, CardContent, Typography, Box, CircularProgress } from "@mui/material";
import ErrorIcon from '@mui/icons-material/Error';

// Componentes
import BasicBarColumn from "@components/Charts/BasiBarColumn";

function GraphicColumnStacked({ openDrawer, dataChart, noResults = false, loadingChart }) {
    return (
        <>
            <Card className="card-primary" sx={{ marginBottom: 2 }}>
                <CardContent key={`Bingo_${openDrawer}`}>
                    {noResults ? (
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                justifyContent: "center",
                                color: "black",
                            }}
                        >
                            <ErrorIcon sx={{ fontSize: 40, mt: 1 }} />
                            <Typography fontWeight={600} variant="subtitle2" marginTop={2}>
                                No se encontraron resultados
                            </Typography>
                        </Box>
                    ) : (
                        <>
                            {loadingChart ? 
                            <Box sx={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                                <CircularProgress />
                            </Box> :
                                <BasicBarColumn
                                    loading={false}
                                    series={dataChart.series}
                                    categories={dataChart.categories}
                                />}
                        </>
                    )}
                </CardContent>
            </Card>
        </>
    )
}

export default GraphicColumnStacked