import { useState } from "react";

// Material UI
import { Box, Card, CardContent, Typography } from "@mui/material";

// Componentes
import Map from "@components/Maps/CustomComponents/GenericMap";

// Estilos de mapas
import { style_original_data, style_original_sub_data } from "@components/Maps/Auxiliars/ColorFeature";

const elements = {
  entidad: [
    { title: "Entidad: ", name: "entidad", type: "text" },
    { title: "Lista Nominal: ", name: "ListaNominal", type: "text" },
  ],
};

const MapNumeralia = ({ title, polygons, polygonsBlocks }) => {
  const [currentDate] = useState(new Date().toLocaleString());

  const highlightFeature = (e, params) => {
    const { resetHighlight, setFeature, L, selectedFeature, setSelectedFeature } = params;
    const layer = e.target;
    const properties = layer.feature.properties;

    if (selectedFeature) resetHighlight(selectedFeature);
    if (!properties[properties.shp]) resetHighlight(e);

    setFeature(layer.feature);
    setSelectedFeature(e);

    if (properties[properties.shp]) {
      if (!L.Browser.ie && !L.Browser.opera && !L.Browser.edge) layer.bringToFront();
      layer.setStyle(style_original_data(layer.feature, true));
    }
  };

  return (
    <Card className="card-primary">
      <CardContent>
        <Box marginBottom={1}>
          <Typography variant="subtitle2" fontWeight={600} textAlign={"end"}>
            {title}
          </Typography>
          <Typography variant="body2" textAlign={"end"} color={"GrayText"}>
            {currentDate}
          </Typography>
        </Box>
        <Map
          height="50vh"
          zoom={5}
          position={[23.220226, -101.229282]}
          data={polygons}
          subData={polygonsBlocks}
          cardInfo={{ initialValues: { title: "INFORMACIÓN", elements: elements["entidad"] } }}
          highlightFeature={highlightFeature}
          centerByFeatures={true}
          styleProp={{
            data: style_original_data,
            subData: style_original_sub_data,
            onEachFeature: style_original_data,
            highlightFeature: style_original_data,
          }}
        />
      </CardContent>
    </Card>
  );
};

export default MapNumeralia;
