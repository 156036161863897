//MUI
import { Box, Typography, CircularProgress, circularProgressClasses } from "@mui/material";

const CircularProgressPorcent = ({ size, value, fontSize, fontColor, fontWeight }) => {
  function validateRange(numero, number = false) {
    const porcent = parseInt(numero).toFixed();
    const rango = parseInt(porcent);

    if (rango === 100) return number ? "#2E7D32" : "success";
    else if (rango >= 50 && rango < 100) return number ? "#1976D2" : "primary";
    else if (rango > 0 && rango < 50) return number ? "#ED6C02" : "warning";
  }

  return (
    <Box
      sx={{
        position: "relative",
        display: "inline-flex",
        mt: 0.3,
      }}
    >
      <CircularProgress
        variant="determinate"
        sx={{
          color: (theme) => theme.palette.grey[theme.palette.mode === "light" ? 300 : 800],
        }}
        size={size}
        thickness={4}
        value={100}
      />
      <CircularProgress
        variant="determinate"
        value={parseInt(value)}
        // disableShrink
        //color={value === "100.00" ? "success" : "warning"}
        color={validateRange(value)}
        sx={{
          animationDuration: "550ms",
          position: "absolute",
          left: 0,
          [`& .${circularProgressClasses.circle}`]: {
            strokeLinecap: "round",
          },
        }}
        size={size}
        thickness={4}
      />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: "absolute",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography variant="body2" fontSize={fontSize} fontWeight={fontWeight ?? "auto" } color={fontColor ? validateRange(value, true) : ""}>
          {parseInt(value).toFixed()}%
        </Typography>
      </Box>
    </Box>
  );
};

export default CircularProgressPorcent;
