import React, { useState, useEffect } from "react";

//MUI
import { Box, Button, Grid, TextField, Typography } from "@mui/material";
import Select from "react-select";
import makeAnimated from "react-select/animated";

//Components
import InputSelect from "@components/Selects/BasicSelect";
import CatalogServices from "@services/CatalogServices";
import Swal from "sweetalert2";
import FilterCollapse from "@components/Filters/FilterCollapse";
import { isTypePhone } from "@utils/validations";
import { getVars } from "@utils/global";
import { COORDINADOR_GENERAL } from "@data/constants";

const Filter = ({
  onChangeFilter,
  data,
  setData,
  setGroupByChart,
  isUsuarioEstatal,
}) => {
  //variables
  const animatedComponents = makeAnimated();
  const defaultOption = { value: 0, label: "TODOS" };
  const { user } = getVars('Token');
  //States
  const [, setCatRegions] = useState([]);
  const [catRegionsFilter, setCatRegionsFilter] = useState([]);
  const [catMunicipality, setCatMunicipality] = useState([]);
  const [catMunicipalityFilter, setCatMunicipalityFilter] = useState([]);
  const [catPolygon, setCatPolygon] = useState([]);
  const [catPolygonFilter, setCatPolygonFilter] = useState([]);
  const [catSection, setCatSection] = useState([]);
  const [catSectionFilter, setCatSectionFilter] = useState([]);
  const [catResponsability, setCatResponsability] = useState([]);
  const [catResponsabilityFilter, setCatResponsabilityFilter] = useState([]);
  const [, setCatTypeResponsability] = useState([]);
  const [catFederalDistrict, setCatFederalDistrict] = useState([]);
  const [catLocalDistrict, setCatLocalDistrict] = useState([]);
  const [catTypeResponsabilityFilter, setCatTypeResponsabilityFilter] =
    useState([]);
  const [selectedPolygons, setSelectedPolygons] = useState([]);
  const [selectedSections, setSelectedSections] = useState([]);
  const [dataCatalog, setDataCatalog] = useState({
    Region: 0,
    Municipio: 0,
    Poligono: [],
    Seccion: [],
  });
  //const [catGroupBy, setCatGroupBy] = [];
  const [dataCatalogResponsabilty, setDataCatalogResponsabilty] = useState({
    TipoResponsabilidad: 0,
    Responsabilidad: 0,
  });
  const [filter, setFilter] = useState({
    Region: 0,
    Municipio: 0,
    Poligono: [],
    Seccion: [],
    TipoResponsabilidad: 0,
    Responsabilidad: 0,
    Celular: "",
    ClaveElector: "",
    NombreCompleto: "",
    Calle: "",
    Colonia: "",
    Ranking: "",
    GroupBy: "RL",
    DistritoFederal: 0,
    DistritoLocal: 0,
  });

  //Functions Api's
  useEffect(() => {
    const catalogsParams = [
      { id: "regiones", getAll: false },
      { id: "municipios_dl", getAll: false },
      { id: "poligonos", getAll: false },
      { id: "secciones", getAll: false, getDeleted: true },
      { id: "tipo_perfil", getAll: false },
      { id: "perfil", getAll: false },
      { id: "df", getAll: false },
      { id: "dl", getAll: false },
    ];

    CatalogServices.getCatalogs(catalogsParams)
      .then((res) => {
        if (res.results) {
          catalogsParams.forEach((item) => {
            switch (item.id) {
              case "regiones":
                setCatRegions(
                  [defaultOption].concat(res.response.catalogs[item.id])
                );
                setCatRegionsFilter(
                  [defaultOption].concat(res.response.catalogs[item.id])
                );
                break;

              case "municipios_dl":
                setCatMunicipality(
                  [defaultOption].concat(res.response.catalogs[item.id])
                );
                setCatMunicipalityFilter(
                  [defaultOption].concat(res.response.catalogs[item.id])
                );
                break;

              case "perfil":
                setCatResponsability(
                  [defaultOption].concat(res.response.catalogs[item.id])
                );
                setCatResponsabilityFilter(
                  [defaultOption].concat(res.response.catalogs[item.id])
                );
                break;

              case "poligonos":
                setCatPolygon(res.response.catalogs[item.id]);
                setCatPolygonFilter(res.response.catalogs[item.id]);
                break;

              case "secciones":
                setCatSection(res.response.catalogs[item.id]);
                setCatSectionFilter(res.response.catalogs[item.id]);
                break;

              case "tipo_perfil":
                setCatTypeResponsability(
                  [defaultOption].concat(res.response.catalogs[item.id])
                );

                setCatTypeResponsabilityFilter(
                  [defaultOption].concat(res.response.catalogs[item.id])
                );
                break;

              case "df":
                setCatFederalDistrict(
                  [defaultOption].concat(res.response.catalogs[item.id])
                );
                break;

              case "dl":
                setCatLocalDistrict(
                  [defaultOption].concat(res.response.catalogs[item.id])
                );
                break;

              default:
            }
          });
        } else {
          Swal.fire({ title: res.message, icon: "warning" });
        }
      })
      .catch((error) => Swal.fire({ title: error.message, icon: "warning" }));

    // eslint-disable-next-line
  }, []);

  //Functions and Handlers
  /* const handleChecked = () => {
    setChecked((prevState) => !prevState);
  }; */

  const handleChangeRegion = (event) => {
    const idRegion = event.target.value;
    setDataCatalog({
      ...dataCatalog,
      Region: idRegion,
      Municipio: 0,
      Poligono: [],
      Seccion: [],
    });

    if (idRegion > 0) {
      setCatMunicipalityFilter(
        [defaultOption].concat(
          catMunicipality.filter((item) => item.idRegion === idRegion)
        )
      );
      setCatPolygonFilter(
        catPolygon.filter((item) => item.idRegion === idRegion)
      );
      setCatSectionFilter(
        catSection.filter((item) => item.idRegion === idRegion)
      );
    } else {
      setCatMunicipalityFilter(catMunicipality);
      setCatPolygonFilter(catPolygon);
      setCatSectionFilter(catSection);
    }
  };

  const handleChangeMunicipality = (e) => {
    const idMunicipality = e.target.value;
    setDataCatalog({
      ...dataCatalog,
      Municipio: idMunicipality,
      Poligono: [],
      Seccion: [],
    });

    if (idMunicipality > 0) {
      setCatPolygonFilter(
        catPolygon.filter((item) => item.idMunicipioReportes === idMunicipality)
      );
      setCatSectionFilter(
        catSection.filter((item) => item.idMunicipioReportes === idMunicipality)
      );
    } else {
      setCatSectionFilter(catSection);
      setCatPolygonFilter(catPolygon);
    }
  };

  const handleChangePolygon = (newValue) => {
    setSelectedPolygons(newValue);
    const array_poligon = newValue.map((option) => option.value || option);

    setDataCatalog({
      ...dataCatalog,
      Poligono: array_poligon,
    });

    if (array_poligon.length > 0) {
      setCatSectionFilter(
        catSection.filter((item) =>
          array_poligon.some((id) => id === item.idPoligono)
        )
      );
    } else {
      setCatSectionFilter(catSection);
    }
  };

  const handleChangeSection = (newValue) => {
    setSelectedSections(newValue);
    const array_section = newValue.map((option) => option.value || option);

    setDataCatalog({
      ...dataCatalog,
      Seccion: array_section,
    });
  };

  const handleChangeTypeResponsability = (e) => {
    const idTypeResponsability = e.target.value;
    setDataCatalogResponsabilty({
      ...dataCatalogResponsabilty,
      TipoResponsabilidad: idTypeResponsability,
      Responsabilidad: 0,
    });

    if (idTypeResponsability > 0) {
      setCatResponsabilityFilter(
        [defaultOption].concat(
          catResponsability.filter(
            (item) => item.idTipo === idTypeResponsability
          )
        )
      );
    } else {
      setCatResponsabilityFilter(catResponsability);
    }
  };

  const handleChangeResponsability = (e) => {
    const idResponsability = e.target.value;
    setDataCatalogResponsabilty({
      ...dataCatalogResponsabilty,
      Responsabilidad: idResponsability,
    });
  };

  const hadleChangeTextField = (e) => {
    setFilter({
      ...filter,
      [e.target.name]: e.target.value,
    });
  };

  const handleChangeGroupBy = (e) => {
    setFilter({
      ...filter,
      GroupBy: e.target.value,
    });
  };

  const handleChangeFederalDistrict = (e) => {
    const idFederal = e.target.value;
    setFilter({
      ...filter,
      DistritoFederal: idFederal,
    });

    if (idFederal > 0) {
      setCatPolygonFilter(
        catPolygon.filter((item) => item.idRegion === idFederal)
      );
      setCatSectionFilter(
        catSection.filter((item) => item.idRegion === idFederal)
      );
    } else {
      setCatPolygonFilter(catPolygon);
      setCatSectionFilter(catSection);
    }
  };

  const handleChangeLocalDistrict = (e) => {
    const idLocal = e.target.value;
    setFilter({
      ...filter,
      DistritoLocal: idLocal,
    });

    if (idLocal > 0) {
      setCatPolygonFilter(
        catPolygon.filter((item) => item.idRegion === idLocal)
      );
      setCatSectionFilter(
        catSection.filter((item) => item.idRegion === idLocal)
      );
    } else {
      setCatPolygonFilter(catPolygon);
      setCatSectionFilter(catSection);
    }
  };

  const searchFilter = () => {
    const valueFilter = {
      Region: dataCatalog.Region,
      Municipio: dataCatalog.Municipio,
      Poligono: dataCatalog.Poligono,
      Seccion: dataCatalog.Seccion,
      Tipo: dataCatalogResponsabilty.TipoResponsabilidad,
      Responsabilidad: dataCatalogResponsabilty.Responsabilidad,
      Celular: filter.Celular,
      ClaveElector: filter.ClaveElector,
      NombreCompleto: filter.NombreCompleto,
      Calle: filter.Calle,
      Colonia: filter.Colonia,
      Ranking: filter.Ranking,
      GroupBy: filter.GroupBy,
      DistritoFederal: filter.DistritoFederal,
      DistritoLocal: filter.DistritoLocal,
    };

    onChangeFilter(valueFilter);
  };

  const clearFilter = () => {
    const newFilter = {
      Region: 0,
      Municipio: 0,
      Poligono: [],
      Seccion: [],
      TipoResponsabilidad: 0,
      Responsabilidad: 0,
      Celular: "",
      ClaveElector: "",
      NombreCompleto: "",
      Calle: "",
      Colonia: "",
      Ranking: "",
      GroupBy: "RL",
      DistritoFederal: 0,
      DistritoLocal: 0,
    };

    setSelectedPolygons([]);
    setSelectedSections([]);
    setCatMunicipalityFilter(catMunicipality);
    setCatPolygonFilter(catPolygon);
    setCatSectionFilter(catSection);
    setCatResponsabilityFilter(catResponsability);

    setDataCatalog({
      ...dataCatalog,
      Region: 0,
      Municipio: 0,
      Poligono: [],
      Seccion: [],
    });

    setDataCatalogResponsabilty({
      ...dataCatalogResponsabilty,
      TipoResponsabilidad: 0,
      Responsabilidad: 0,
    });

    setData({
      ...data,
      filtered: [],
    });

    setGroupByChart((prevState) => {
      return {
        ...prevState,
        groupBy: "RL",
        filtered: [],
      };
    });

    setFilter(newFilter);
    onChangeFilter(newFilter);
  };

  const selectStyles = {
    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
    menu: (provided) => ({ ...provided, zIndex: "9999 !important" }),
  };

  return (
    <>
      <FilterCollapse>
        <Typography fontWeight={600} variant={"subtitle2"}>
          Gráfica de avances
        </Typography>
        {isUsuarioEstatal && (
          <Grid
            container
            spacing={2}
            marginBottom={"1rem"}
            marginTop={"0.5rem"}
          >
            <Grid item xs={12} md={3}>
              <InputSelect
                label="Visualizar Por"
                options={[
                  { value: "RL", label: "REGIONAL" },
                  { value: "ML", label: "MUNICIPAL" },
                  { value: "DF", label: "DISTRITO FEDERAL" },
                  { value: "DL", label: "DISTRITO LOCAL" },
                ]}
                name="groupBy"
                value={filter.GroupBy}
                onChange={handleChangeGroupBy}
                sx={{ width: "100%" }}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <InputSelect
                label="Distrito Federal"
                options={catFederalDistrict}
                name="DistritoFederal"
                value={filter.DistritoFederal}
                onChange={handleChangeFederalDistrict}
                sx={{ width: "100%" }}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <InputSelect
                label="Distrito Local"
                options={catLocalDistrict}
                name="DistritoLocal"
                value={filter.DistritoLocal}
                onChange={handleChangeLocalDistrict}
                sx={{ width: "100%" }}
              />
            </Grid>
          </Grid>
        )}

        <Grid container spacing={2} marginBottom={"1rem"} marginTop={"0.5rem"}>
          <Grid item xs={12} md={3}>
            <InputSelect
              label="Región"
              options={catRegionsFilter}
              name="region"
              value={
                catRegionsFilter.length === 2
                  ? catRegionsFilter[1].value
                  : dataCatalog.Region
              }
              onChange={handleChangeRegion}
              disabled={catRegionsFilter.length === 2 ? true : false}
              sx={{ width: "100%" }}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <InputSelect
              label="Municipio"
              options={catMunicipalityFilter}
              name="municipio"
              value={
                catMunicipalityFilter.length === 2
                  ? catMunicipalityFilter[1].value
                  : dataCatalog.Municipio
              }
              onChange={handleChangeMunicipality}
              disabled={catMunicipality.length === 2 ? true : false}
              sx={{ width: "100%" }}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <Select
              placeholder="Polígono"
              styles={selectStyles}
              closeMenuOnSelect={false}
              components={animatedComponents}
              value={
                catPolygonFilter.length === 1
                  ? [catPolygonFilter[0]]
                  : selectedPolygons
              }
              onChange={handleChangePolygon}
              isMulti
              options={catPolygonFilter}
              isDisabled={catPolygonFilter.length === 1 ? true : false}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <Select
              placeholder="Sección"
              styles={selectStyles}
              closeMenuOnSelect={false}
              components={animatedComponents}
              value={
                catSectionFilter.length === 1
                  ? [catSectionFilter[0]]
                  : selectedSections
              }
              onChange={handleChangeSection}
              isMulti
              options={catSectionFilter}
              isDisabled={catSectionFilter.length === 1 ? true : false}
            />
          </Grid>
        </Grid>

        <Typography fontWeight={600} variant={"subtitle2"}>
          Listado de compromisos
        </Typography>
        <Grid container spacing={2} marginBottom={"1rem"} marginTop={"0.5rem"}>
          {
            user.ambito_perfil.some(item => item.idPerfil === COORDINADOR_GENERAL) && (
              <Grid item xs={12} md={3}>
                <InputSelect
                  label="Tipo Responsabilidad"
                  options={catTypeResponsabilityFilter}
                  name="tipo_responsabilidad"
                  value={dataCatalogResponsabilty.TipoResponsabilidad}
                  onChange={handleChangeTypeResponsability}
                  sx={{ width: "100%" }}
                />
              </Grid>
            )
          }

          <Grid item xs={12} md={3}>
            <InputSelect
              label="Responsabilidad"
              options={catResponsabilityFilter}
              name="responsabilidad"
              value={dataCatalogResponsabilty.Responsabilidad}
              onChange={handleChangeResponsability}
              sx={{ width: "100%" }}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <TextField
              label="Celular"
              size="small"
              name="Celular"
              value={filter.Celular}
              //onChange={hadleChangeTextField}
              onChange={(e) =>
                hadleChangeTextField({
                  target: {
                    name: "Celular",
                    value: isTypePhone(e.target.value)
                      ? e.target.value
                      : filter.Celular,
                  },
                })
              }
              sx={{ width: "100%" }}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <TextField
              label="Clave Elector"
              size="small"
              name="ClaveElector"
              value={filter.ClaveElector.toUpperCase()}
              onChange={hadleChangeTextField}
              sx={{ width: "100%" }}
            />
          </Grid>
        </Grid>

        <Grid container spacing={2} marginBottom={"1rem"}>
          <Grid item xs={12} md={3}>
            <TextField
              label="Nombre"
              size="small"
              name="NombreCompleto"
              value={filter.NombreCompleto.toUpperCase()}
              onChange={hadleChangeTextField}
              sx={{ width: "100%" }}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <TextField
              label="Calle"
              size="small"
              name="Calle"
              value={filter.Calle.toUpperCase()}
              onChange={hadleChangeTextField}
              sx={{ width: "100%" }}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <TextField
              label="Colonia"
              size="small"
              name="Colonia"
              value={filter.Colonia.toUpperCase()}
              onChange={hadleChangeTextField}
              sx={{ width: "100%" }}
            />
          </Grid>

          <Grid item xs={12} md={3}>
            <InputSelect
              label="Ranking"
              name="ranking"
              //value={filter.Materno}
              options={[{ value: 0, label: "1" }]}
              sx={{ width: "100%" }}
            />
          </Grid>
        </Grid>

        <Box display={"flex"} justifyContent={"flex-end"}>
          <Button
            variant="contained"
            color="primaryDark"
            onClick={searchFilter}
          >
            Filtrar
          </Button>
          <Button color="primaryDark" sx={{ ml: 1 }} onClick={clearFilter}>
            Limpiar
          </Button>
        </Box>
      </FilterCollapse>
    </>
  );
};

export default Filter;
