import { useState } from "react";

import { Box, Card, CardContent, Container, Icon, Typography } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import { blue } from "@mui/material/colors";

import Navbar from "@components/MobileDiaD/Navbar";
import GridOptionsRcs from "@components/MobileDiaD/GridOptionsRcs";
import GridOptionsRgs from "@components/MobileDiaD/GridOptionsRgs";
import GridGeneral from "@components/MobileDiaD/GridGeneral";

//Screens rcs
import ScreenPaseLista from "@components/MobileDiaD/ScreensRcs/ScreenPaseLista";
import ScreenInstalacionCasilla from "@components/MobileDiaD/ScreensRcs/ScreenInstalacionCasilla";
import ScreenAperturaCasilla from "@components/MobileDiaD/ScreensRcs/ScreenAperturaCasilla";
import ScreenBingo from "@components/MobileDiaD/ScreensRcs/ScreenBingo";
import ScreenIncidencias from "@components/MobileDiaD/ScreensRcs/ScreenIncidencias";
import ScreenCierreCasilla from "@components/MobileDiaD/ScreensRcs/ScreenCierreCasilla";
import ScreenFinVotacion from "@components/MobileDiaD/ScreensRcs/ScreenFinVotacion";
import ScreenReporteResultados from "@components/MobileDiaD/ScreensRcs/ScreenReporteResultados";
import ScreenTransmision from "@components/MobileDiaD/ScreensRcs/ScreenTransmision";

//Screens rgs
import ScreenPaseListaRgs from "@components/MobileDiaD/ScreensRgs/ScreenPaseLista";
import ScreenPaseRuta from "@components/MobileDiaD/ScreensRgs/ScreenPaseRuta";
import ScreenInstalacionCasillaRgs from "@components/MobileDiaD/ScreensRgs/ScreenInstalacionCasilla";
import ScreenAperturaVotacionRgs from "@components/MobileDiaD/ScreensRgs/ScreenAperturaVotacion";
import ScreenIncidenciasRgs from "@components/MobileDiaD/ScreensRgs/ScreenIncidencias";
import ScreenCierreCasillaRgs from "@components/MobileDiaD/ScreensRgs/ScreenCierreCasilla";
import ScreenFinVotacionRgs from "@components/MobileDiaD/ScreensRgs/ScreenFinVotacion";
import ScreenReporteResultadosRgs from "@components/MobileDiaD/ScreensRgs/ScreenReporteResultados";
import ScreenTransmisionRgs from "@components/MobileDiaD/ScreensRgs/ScreenTransmision";
import ScreenBingoRgs from "@components/MobileDiaD/ScreensRgs/ScreenBingo";

import { getVars } from "@utils/global";

const CardOption = ({ icon, label, handleClick }) => {
  return (
    <Box component={Card} className="card-primary" onClick={handleClick}>
      <CardContent sx={{ textAlign: "center" }}>
        <Icon fontSize="large" sx={{ color: blue[900] }}>
          {icon}
        </Icon>
        <Typography variant="body2" fontWeight={600}>
          {label}
        </Typography>
      </CardContent>
    </Box>
  );
};

const Welcome = ({ setTypeScreen }) => {
  const { casilla_rc, ruta_rg /* user, menus  */ } = getVars("Token");

  const handleClickRcs = () => setTypeScreen("rcs");

  const handleClickRgs = () => setTypeScreen("rgs");

  return (
    <>
      <Navbar isScreen={false} disableGoHome enableLogout />
      <Container component="main">
        <Grid2 container spacing={2} height={"100vh"} justifyContent={"center"} alignItems={"center"}>
          {casilla_rc && (
            <Grid2 xs={6} md={6}>
              <CardOption icon={"article"} label={"Día D RC's"} handleClick={handleClickRcs} />
            </Grid2>
          )}

          {ruta_rg && (
            <Grid2 xs={6} md={6}>
              <CardOption icon={"feed"} label={"Día D RG's"} handleClick={handleClickRgs} />
            </Grid2>
          )}
        </Grid2>
      </Container>
    </>
  );
};

const WelcomeDiaD = () => {
  const [typeScreen, setTypeScreen] = useState("welcome");

  const SwitchScreen = (type_screen) => {
    const screen = {
      welcome: <Welcome setTypeScreen={setTypeScreen} />,
      rcs: <GridOptionsRcs setTypeScreen={setTypeScreen} />,
      rgs: <GridOptionsRgs setTypeScreen={setTypeScreen} />,
      general: <GridGeneral setTypeScreen={setTypeScreen} />,

      /* RCS screens */
      PaseLista: <ScreenPaseLista setTypeScreen={setTypeScreen} />,
      InicioInstalacion: <ScreenInstalacionCasilla setTypeScreen={setTypeScreen} />,
      AperturaCasilla: <ScreenAperturaCasilla setTypeScreen={setTypeScreen} />,
      Bingo: <ScreenBingo setTypeScreen={setTypeScreen} />,
      ReporteIncidencias: <ScreenIncidencias setTypeScreen={setTypeScreen} />,
      CierreCasilla: <ScreenCierreCasilla setTypeScreen={setTypeScreen} />,
      CierreVotacion: <ScreenFinVotacion setTypeScreen={setTypeScreen} />,
      ReporteResultados: <ScreenReporteResultados setTypeScreen={setTypeScreen} />,
      Transmision: <ScreenTransmision setTypeScreen={setTypeScreen} />,

      /* RGS screens */
      PaseListaRg: <ScreenPaseListaRgs setTypeScreen={setTypeScreen} />,
      PaseRutaRg: <ScreenPaseRuta setTypeScreen={setTypeScreen} />,
      InicioInstalacionRg: <ScreenInstalacionCasillaRgs setTypeScreen={setTypeScreen} />,
      AperturaCasillaRg: <ScreenAperturaVotacionRgs setTypeScreen={setTypeScreen} />,
      FolioBingoRg: <ScreenBingoRgs setTypeScreen={setTypeScreen} />,
      ReporteIncidenciasRg: <ScreenIncidenciasRgs setTypeScreen={setTypeScreen} />,
      CierreCasillaRg: <ScreenCierreCasillaRgs setTypeScreen={setTypeScreen} />,
      CierreVotacionRg: <ScreenFinVotacionRgs setTypeScreen={setTypeScreen} />,
      ReporteResultadosRg: <ScreenReporteResultadosRgs setTypeScreen={setTypeScreen} />,
      TransmisionRg: <ScreenTransmisionRgs setTypeScreen={setTypeScreen} />,
    };

    return screen[type_screen];
  };

  return (
    <Box
      sx={{
        background:
          typeScreen === "welcome" || typeScreen === "rgs" || typeScreen === "rcs"
            ? "linear-gradient(to bottom,rgba(0, 102, 204, 1) 40%, rgba(255, 105, 180, 0.8) 60%, rgba(255, 69, 0, 0.7) 80%, rgba(255, 255, 0, 0.9) 100%);"
            : "none",
      }}
    >
      {SwitchScreen(typeScreen || "welcome")}
    </Box>
  );
};

export default WelcomeDiaD;
