import { useState, useEffect } from "react";
import {
  Card,
  CardHeader,
  CardContent,
  IconButton,
  Modal,
  Checkbox,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Divider,
  Button,
  CardActions,
  Box
} from "@mui/material";
import Grid2 from '@mui/material/Unstable_Grid2';
import CancelIcon from '@mui/icons-material/Cancel';
import Swal from "sweetalert2";

import CatalogServices from "@services/CatalogServices";
import { Save } from "@mui/icons-material";

function not(a, b) {
  return a.filter((item) => !b.map(x => x.value).includes(item.value));
}

function intersection(a, b) {
  return a.filter((item) => b.map(x => x.value).includes(item.value));
}

function union(a, b) {
  return [...a, ...not(b, a)];
}

const CoalitionPartiesForm = props => {
  const { openModal, setOpenModal, selected, handleSelected } = props;

  const [checked, setChecked] = useState([]);
  const [parties, setParties] = useState([]);
  const [left, setLeft] = useState([]);
  const [right, setRight] = useState(selected);

  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(checked, right);

  useEffect(() => {
    getParties();
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (selected && parties && openModal) {
      if (parties.length > 0) {
        setLeft(parties.filter(item => !(selected.map(x => x.value)).includes(item.value)));
        setRight(parties.filter(item => (selected.map(x => x.value)).includes(item.value)));
      }
    }

  }, [selected, parties, openModal]);

  const mapParties = (parties) => {
    return parties.map(item => {
      if (item.label.trim().toUpperCase() === item.Partido.trim().toUpperCase()) {
        return item;
      }

      return { ...item, label: `${item.Partido} (${item.label})` }
    })
  }

  const getParties = () => {
    const params = [{ id: 'partidos' }];
    CatalogServices
      .getCatalogs(params)
      .then(res => {
        if (res.results) {
          if (res.response.catalogs && res.response.catalogs.partidos) {
            setParties(mapParties(res.response.catalogs.partidos));
          }
        } else {
          Swal.fire({ title: res.message, icon: "warning" });
        }
      })
      .catch(error => {
        Swal.fire({ title: error, icon: "warining" })
      });
  }

  const numberOfChecked = (items) => intersection(checked, items).length;

  const handleToggle = (item) => {
    let newChecked = [...checked];
    if (checked.some(x => x.value === item.value)) {
      newChecked = newChecked.filter(x => x.value !== item.value);
    } else {
      newChecked.push(item);
    }

    setChecked(newChecked);
  };

  const handleToggleAll = (items) => () => {
    if (numberOfChecked(items) === items.length) {
      setChecked(not(checked, items));
    } else {
      setChecked(union(checked, items));
    }
  };

  const handleCheckedRight = () => {
    setRight(right.concat(leftChecked));
    setLeft(not(left, leftChecked));
    setChecked(not(checked, leftChecked));
  };

  const handleCheckedLeft = () => {
    setLeft(left.concat(rightChecked));
    setRight(not(right, rightChecked));
    setChecked(not(checked, rightChecked));
  };

  const handleCancelAction = () => {
    setLeft(parties.filter(item => !(selected.map(x => x.value)).includes(item.value)));
    setRight(parties.filter(item => (selected.map(x => x.value)).includes(item.value)));
  }

  const customList = (title, items) => (
    <Card>
      <CardHeader
        sx={{ px: 2, py: 1 }}
        avatar={
          <Checkbox
            onClick={handleToggleAll(items)}
            checked={numberOfChecked(items) === items.length && items.length !== 0}
            indeterminate={
              numberOfChecked(items) !== items.length && numberOfChecked(items) !== 0
            }
            disabled={items.length === 0}
            inputProps={{
              'aria-label': 'todos los elementos seleccionados',
            }}
          />
        }
        title={title}
        subheader={`${numberOfChecked(items)}/${items.length} seleccionados`}
      />
      <Divider />
      <List
        sx={{
          maxWidth: 400,
          height: 400,
          bgcolor: 'background.paper',
          overflow: 'auto',
        }}
        size="small"
        dense
        component="div"
        role="list"
      >
        {items.map((item, index) => {
          const labelId = `transfer-list-all-item-${index}-label`;

          return (
            <ListItem
              key={index}
              role="listitem"
              onClick={e => handleToggle(item)}
            >
              <ListItemIcon>
                <Checkbox
                  checked={checked.some(x => x.value === item.value)}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{
                    'aria-labelledby': labelId,
                  }}
                />
              </ListItemIcon>
              <ListItemText id={labelId} primary={item.label} />
            </ListItem>
          );
        })}
      </List>
    </Card>
  );

  const handleSave = () => {
    if (right.length < 3) {
      return Swal.fire({
        title: 'Una coalición debe contener al menos 3 combinaciones.',
        icon: 'warning',
        customClass: {
          container: "modal-alert",
        },
      });
    }

    Swal.fire({
      title: "Atencion",
      html: `¿Esta seguro de agregar los ${right.length} partidos seleccionados?`,
      icon: "warning",
      showCancelButton: true,
      cancelButtonText: "No, Cancelar",
      confirmButtonText: `Si, Agregar`,
      reverseButtons: true,
      allowOutsideClick: false,
      allowEscapeKey: false,
      customClass: {
        container: "modal-alert",
      },
    }).then((res) => {
      if (res.isConfirmed) {
        Swal.fire({
          title: '¡Agregado con éxito!',
          icon: 'success',
          allowOutsideClick: false,
          allowEscapeKey: false,
          customClass: {
            container: "modal-alert",
          },
        }).then(r => {
          setOpenModal(false);
        });

        handleSelected(parties.filter(item => (right.map(x => x.value)).includes(item.value)));
      }
    })
  }

  return (
    <Modal
      open={openModal}
      onClose={e => setOpenModal(false)}
    >
      <Card sx={{
        position: "absolute",
        top: "50%",
        left: "50%",
        width: "60%",
        maxHeight: "100%",
        overflowY: "auto",
        transform: "translate(-50%, -50%)",
        boxShadow: 24,
        p: 2,
      }}>
        <CardHeader
          title={<span>Selección de partidos y combinaciones que forman la <strong>Coalición</strong></span>}
          action={
            <IconButton aria-label="cancel" onClick={() => setOpenModal(false)}>
              <CancelIcon />
            </IconButton>
          }
        />

        <CardContent>
          <Grid2 container spacing={2} justifyContent={'center'} alignItems={'center'}>
            <Grid2>{customList('Partidos', left)}</Grid2>
            <Grid2 item>
              <Grid2 container direction="column" alignItems="center">
                <Button
                  sx={{ my: 0.5 }}
                  variant="outlined"
                  size="small"
                  onClick={e => handleCheckedRight()}
                  disabled={leftChecked.length === 0}
                  aria-label="move selected right"
                >
                  &gt;
                </Button>
                <Button
                  sx={{ my: 0.5 }}
                  variant="outlined"
                  size="small"
                  onClick={e => handleCheckedLeft()}
                  disabled={rightChecked.length === 0}
                  aria-label="move selected left"
                >
                  &lt;
                </Button>
              </Grid2>
            </Grid2>
            <Grid2 item>{customList('Partidos de la coalición', right)}</Grid2>
          </Grid2>
        </CardContent>

        <CardActions>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Button
              type="button"
              variant="contained"
              color="error"
              endIcon={<CancelIcon />}
              onClick={e => handleCancelAction()}
            >
              Cancelar
            </Button>
            <Button
              type="button"
              color="primaryDark"
              variant="contained"
              size="small"
              onClick={e => handleSave()}
              startIcon={<Save />}
            >
              Guardar
            </Button>
          </Box>
        </CardActions>
      </Card>
    </Modal>
  )
}

export default CoalitionPartiesForm;