import { useEffect, useState } from "react";
import { useSorted } from "@hooks/useSorted";

import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Icon,
  Stack,
  Typography,
} from "@mui/material";

import AdminLayout from "@components/MainPage/AdminLayout";
import ActionHeader from "@components/Containers/ActionHeader";
import FilterMunicipal from "@components/Rcos/FilterMunicipal";
import DetailMunicipal from "@components/Rcos/Detail";
import Download from "@components/Downloads/Download";
import DataTableReport from "@components/Tables/DataTableReport";

import { COLUMNS_RCO_MUNICIPAL } from "@data/constants/Rco";
import rco from "@services/RcosServices";
import Swal from "sweetalert2";
import middleware from "@middlewares/middleware";

const Municipal = () => {
  const [total, setTotal] = useState(10);
  const [dataMunicipal, setDataMunicipal] = useState({
    Info: {},
    Municipales: [],
    data: [],
  });
  const [loadingMunicipal, setLoadingMunicipal] = useState(false);
  const [data, setData] = useState({
    page: 0,
    pageSize: 25,
    filtered: [],
    sorted: [],
  });
  const [isDownload, setIsDownloading] = useState(false);
  const [summary, setSummary] = useState([]);
  const { handleRequestSort } = useSorted({
    total: total,
    setData,
    id: "CONCAT_WS(' ', usuarios.Nombre, usuarios.Paterno, usuarios.Materno)",
  });

  const getInfoRcosMunicipios = async (body) => {
    try {
      setLoadingMunicipal(true);
      const result = await rco.getInfoRcosMunicipios(body);
      const { response, message, results } = result;
      if (!results) {
        throw new Error(message);
      }
      const { Info, Municipales, data } = response.data;
      setDataMunicipal({
        ...dataMunicipal,
        Info,
        Municipales,
        data,
      });
      setTotal(response.data.total);
      setSummary(Object.values(response.data.totals));
    } catch (e) {
      Swal.fire({
        title: e,
        icon: "warning",
      });
    } finally {
      setLoadingMunicipal(false);
    }
  };

  const onChangeFilter = ({ filters }) => {
    if (filters.length > 0) {
      setData((prevState) => ({
        ...prevState,
        page: 0,
        filtered: filters,
      }));
    }
  };

  const onClearFilter = () => {
    if (data.filtered.length > 0) {
      setData((prevState) => ({
        ...prevState,
        page: 0,
        filtered: [],
        sorted: [],
      }));
    }
  };

  const handleChangePagination = (pagination) => {
    setData({ ...data, ...pagination });
  };

  const handleExport = async () => {
    setIsDownloading(true);
    try {
      const result = await rco.export({
        api: "rcos/export-rcos-municipios",
        params: { filtered: data.filtered },
      });
      const { success } = result;

      if (!success) {
        throw new Error(
          "Ocurrió un error en la descarga, consulte con el administrador"
        );
      }

      Swal.fire({
        title: "Descargado con éxito",
        icon: "success",
      });
    } catch (e) {
      Swal.fire({
        title: e,
        icon: "warning",
      });
    } finally {
      setIsDownloading(false);
    }
  };

  useEffect(() => {
    if (data.filtered && data.filtered.length > 0) {
      getInfoRcosMunicipios(data);
    } else {
      setDataMunicipal({
        Info: {},
        Municipales: [],
        data: [],
      });
    }
    //eslint-disable-next-line
  }, [data]);

  return (
    <AdminLayout>
      <Container maxWidth={false}>
        <ActionHeader title="RCO Municipal" />
        <FilterMunicipal
          onChangeFilter={onChangeFilter}
          onClearFilter={onClearFilter}
        />
        <Box marginTop={2}>
          <Card className="card-primary">
            <CardContent>
              {
                middleware.checkMenuAction("Exportar") && (
                  <Stack
                    direction={"row"}
                    justifyContent={"space-between"}
                    alignItems={"center"}
                    marginBottom={2}
                  >
                    <Typography fontWeight={600} variant="body2">
                      SECCIONALES
                    </Typography>
                    <Button
                      variant="outlined"
                      color="primaryDark"
                      startIcon={<Icon>download</Icon>}
                      size="small"
                      disabled={isDownload || data.filtered.length === 0}
                      onClick={handleExport}
                    >
                      Exportar
                    </Button>
                  </Stack>
                )
              }
              {isDownload && (
                <Box marginBottom={2}>
                  <Download format={"pdf"} isDownload={isDownload} />
                </Box>
              )}
              <DetailMunicipal
                Info={dataMunicipal.Info}
                data={dataMunicipal.Municipales}
                type={"MUNICIPALES"}
                labelType={"SIN MUNICIPALES"}
                loading={loadingMunicipal}
              />
              <DataTableReport
                rows={dataMunicipal.data}
                hcolumns={COLUMNS_RCO_MUNICIPAL}
                total={total}
                summary={summary}
                startIdxReport={0}
                pageProp={data.page}
                pageSize={data.pageSize}
                minHeight="0px"
                stickyHeader={true}
                isLoading={loadingMunicipal}
                handleChangePagination={handleChangePagination}
                handleManualSort={handleRequestSort}
              />
            </CardContent>
          </Card>
        </Box>
      </Container>
    </AdminLayout>
  );
};

export default Municipal;
