import React, { useState, useEffect } from "react";

//MUI
import { Box, Button, Grid } from "@mui/material";

//Components
import InputSelect from "@components/Selects/BasicSelect";
import Select from "react-select";
import CatalogServices from "@services/CatalogServices";
import Swal from "sweetalert2";
import { getVars } from "@utils/global";

const Filter = ({ onChangeFilter }) => {
  //variables
  const defaultOption = { value: 0, label: "TODOS" };
  //States
  const [, setCatRegions] = useState([]);
  const [, /* catRegionsFilter */ setCatRegionsFilter] = useState([]);
  const [catMunicipality, setCatMunicipality] = useState([]);
  const [catMunicipalityFilter, setCatMunicipalityFilter] = useState([]);
  const [catSection, setCatSection] = useState([]);
  const [catSectionFilter, setCatSectionFilter] = useState([]);
  const [catRutas] = useState([
    { value: 0, label: "TODOS" },
    { value: 1, label: "Sin Ruta" },
    { value: 2, label: "Con Ruta" },
  ]);
  const [catRGs] = useState([
    { value: 0, label: "TODOS" },
    { value: 1, label: "Sin RG" },
    { value: 2, label: "Con RG" },
  ]);
  const [catAbogado] = useState([
    { value: 0, label: "TODOS" },
    { value: 1, label: "Sin Abogado" },
    { value: 2, label: "Con Abogado" },
  ]);
  const [catResponsable] = useState([
    { value: 0, label: "TODOS" },
    { value: 1, label: "SIN RESPONSABLES" },
    { value: 2, label: "CON RESPONSABLES" },
    { value: 3, label: "Multiples Asignaciones" },
  ]);
  const [catRutasList, setRutasList] = useState([]);
  const [catRutasFilter, setRutasFilter] = useState([]);
  const [clean, setClean] = useState(false);
  const [selectedSections, setSelectedSections] = useState([]);
  const [dataCatalog, setDataCatalog] = useState({
    Region: 0,
    Municipio: 0,
    Seccion: [],
    Ruta: 0,
    RutaId: 0,
    RG: 0,
    Abogado: 0,
    P1: 0,
    P2: 0,
    S1: 0,
    S2: 0,
  });

  const user = getVars("Token")?.user;

  //Functions Api's
  useEffect(() => {
    const catalogsParams = [
      { id: "regiones", getAll: false },
      { id: "municipios_dl", getAll: false },
      { id: "secciones", getAll: false },
      {
        id: "rutas",
        filtered: [{ id: "rutas.idPartido", filter: "=", value: user.idPartido }],
        getAll: false,
      },
    ];

    CatalogServices.getCatalogs(catalogsParams)
      .then((res) => {
        if (res.results) {
          catalogsParams.forEach((item) => {
            switch (item.id) {
              case "regiones":
                setCatRegions([defaultOption].concat(res.response.catalogs[item.id]));
                setCatRegionsFilter([defaultOption].concat(res.response.catalogs[item.id]));
                break;

              case "municipios_dl":
                setCatMunicipality([defaultOption].concat(res.response.catalogs[item.id]));
                setCatMunicipalityFilter([defaultOption].concat(res.response.catalogs[item.id]));
                break;

              case "secciones":
                setCatSection(res.response.catalogs[item.id]);
                setCatSectionFilter(res.response.catalogs[item.id]);
                break;
              case "rutas":
                setRutasList([defaultOption].concat(res.response.catalogs[item.id]));
                setRutasFilter([defaultOption].concat(res.response.catalogs[item.id]));
                break;
              default:
            }
          });
        } else {
          Swal.fire({ title: res.message, icon: "warning" });
        }
      })
      .catch((error) => Swal.fire({ title: error.message, icon: "warning" }));

    // eslint-disable-next-line
  }, []);

  const selectStyles = {
    menuPortal: (base) => ({ ...base /*  zIndex: "9999999 !important", */ }),
    option: (defaultStyles, state) => ({
      ...defaultStyles,
    }),
    menuList: (styles) => ({
      ...styles,
      height: "90px",
    }),
    menu: (base) => ({
      ...base,
      zIndex: 100,
    }),
  };

  /* const handleChangeRegion = (event) => {
    const idRegion = event.target.value;
    setDataCatalog({
      ...dataCatalog,
      Region: idRegion,
      Municipio: 0,
      Seccion: [],
      RutaId: 0,
    });

    if (idRegion > 0) {
      setCatMunicipalityFilter(
        [defaultOption].concat(catMunicipality.filter((item) => item.idRegion === idRegion))
      );
      setCatSectionFilter(catSection.filter((item) => item.idRegion === idRegion));
      setRutasFilter(catRutasList.filter((item) => item.idRegion === idRegion));
    } else {
      setCatMunicipalityFilter(catMunicipality);
      setCatSectionFilter(catSection);
      setRutasFilter(catRutasList);
    }
  }; */

  const handleChangeMunicipality = (e) => {
    const idMunicipality = e.target.value;
    setDataCatalog({
      ...dataCatalog,
      Municipio: idMunicipality,
      RutaId: 0,
      Seccion: [],
    });

    if (idMunicipality > 0) {
      setCatSectionFilter(catSection.filter((item) => item.idMunicipioReportes === idMunicipality));
      setRutasFilter(catRutasList.filter((item) => item.idMunicipioReportes === idMunicipality));
    } else {
      setRutasFilter(catRutasList);
      setCatSectionFilter(catSection);
    }
  };

  const handleChangeRutasList = (e) => {
    const statusRuta = e.target.value;
    setDataCatalog({
      ...dataCatalog,
      RutaId: statusRuta,
    });
  };

  const handleChangeRutas = (e) => {
    const statusRuta = e.target.value;
    setDataCatalog({
      ...dataCatalog,
      Ruta: statusRuta,
    });
  };

  const handleChangeSection = (newValue) => {
    setSelectedSections(newValue);
    const array_section = newValue.map((option) => option.value || option);

    setDataCatalog({
      ...dataCatalog,
      Seccion: array_section,
    });
  };

  const searchFilter = () => {
    const valueFilter = {
      Region: dataCatalog.Region,
      Municipio: dataCatalog.Municipio,
      RutaId: dataCatalog.RutaId,
      Ruta: dataCatalog.Ruta,
      Seccion: dataCatalog.Seccion,
      RG: dataCatalog.RG,
      Abogado: dataCatalog.Abogado,
      P1: dataCatalog.P1,
      P2: dataCatalog.P2,
      S1: dataCatalog.S1,
      S2: dataCatalog.S2,
    };

    onChangeFilter(valueFilter);
  };

  const clearFilter = () => {
    setClean(!clean);

    const newFilter = {
      Region: 0,
      Municipio: 0,
      Seccion: [],
      Ruta: 0,
      RutaId: 0,
      RG: 0,
      Abogado: 0,
      P1: 0,
      P2: 0,
      S1: 0,
      S2: 0,
    };

    setCatMunicipalityFilter(catMunicipality);
    setCatSectionFilter(catSection);
    setRutasFilter(catRutasList);

    setDataCatalog({ ...dataCatalog, ...newFilter });

    /* 
    setData({
      ...data,
      filtered: [],
    }); */
    /* 
    setFilter(newFilter); */
    onChangeFilter(newFilter);
  };

  return (
    <>
      <Grid container spacing={2} marginBottom={"1rem"} marginTop={"0.5rem"}>
        {/* <Grid item xs={12} md={4}>
          <InputSelect
            label="Región"
            options={catRegionsFilter}
            name="region"
            value={catRegionsFilter.length === 2 ? catRegionsFilter[1].value : dataCatalog.Region}
            onChange={handleChangeRegion}
            disabled={catRegionsFilter.length === 2 ? true : false}
            sx={{ width: "100%" }}
          />
        </Grid> */}
        <Grid item xs={12} md={4}>
          <InputSelect
            label="Municipio"
            options={catMunicipalityFilter}
            name="municipio"
            value={
              catMunicipalityFilter.length === 2 ? catMunicipalityFilter[1].value : dataCatalog.Municipio
            }
            onChange={handleChangeMunicipality}
            disabled={catMunicipality.length === 2 ? true : false}
            sx={{ width: "100%" }}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <InputSelect
            label="Ruta"
            options={catRutasFilter}
            name="Ruta"
            value={catRutasFilter.length === 2 ? catRutasFilter[1].value : dataCatalog.RutaId}
            onChange={handleChangeRutasList}
            disabled={catRutasList.length === 2 ? true : false}
            sx={{ width: "100%" }}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Select
            placeholder="Sección"
            className="basic-single"
            classNamePrefix="select"
            styles={selectStyles}
            closeMenuOnSelect={false}
            /* components={animatedComponents} */
            value={catSectionFilter.length === 1 ? [catSectionFilter[0]] : selectedSections}
            onChange={handleChangeSection}
            isMulti
            options={catSectionFilter}
            isDisabled={catSectionFilter.length === 1 ? true : false}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <InputSelect
            label="Estatus Sección"
            options={catRutas}
            name="Rutas"
            value={dataCatalog.Ruta}
            onChange={handleChangeRutas}
            sx={{ width: "100%" }}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <InputSelect
            label="RGS"
            options={catRGs}
            name="Rutas"
            value={dataCatalog.RG}
            onChange={(e) =>
              setDataCatalog({
                ...dataCatalog,
                RG: e.target.value,
              })
            }
            sx={{ width: "100%" }}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <InputSelect
            label="ABOGADOS"
            options={catAbogado}
            name="Rutas"
            value={dataCatalog.Abogado}
            onChange={(e) => {
              setDataCatalog({
                ...dataCatalog,
                Abogado: e.target.value,
              });
            }}
            sx={{ width: "100%" }}
          />
        </Grid>

        <Grid item xs={12} md={4}>
          <InputSelect
            label="Propietario 1"
            options={catResponsable}
            name="Propietario1"
            value={dataCatalog.P1}
            onChange={(e) => setDataCatalog({ ...dataCatalog, P1: e.target.value })}
            sx={{ width: "100%" }}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <InputSelect
            label="Propietario 2"
            options={catResponsable}
            name="Propietario2"
            value={dataCatalog.P2}
            onChange={(e) => setDataCatalog({ ...dataCatalog, P2: e.target.value })}
            sx={{ width: "100%" }}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <InputSelect
            label="Suplente 1"
            options={catResponsable}
            name="Suplente1"
            value={dataCatalog.S1}
            onChange={(e) => setDataCatalog({ ...dataCatalog, S1: e.target.value })}
            sx={{ width: "100%" }}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <InputSelect
            label="Suplente 2"
            options={catResponsable}
            name="Suplente2"
            value={dataCatalog.S2}
            onChange={(e) => setDataCatalog({ ...dataCatalog, S2: e.target.value })}
            sx={{ width: "100%" }}
          />
        </Grid>
      </Grid>
      <Box display={"flex"} justifyContent={"flex-end"}>
        <Button variant="contained" color="primaryDark" onClick={searchFilter}>
          Filtrar
        </Button>
        <Button color="primaryDark" sx={{ ml: 1 }} onClick={clearFilter}>
          Limpiar
        </Button>
      </Box>
    </>
  );
};

export default Filter;
