import { useState, useEffect } from "react";
import {
  Button,
  Card,
  CardContent,
  Container,
  Icon,
  Typography,
  Tooltip,
  Box,
  Chip,
  Stack,
} from "@mui/material";

import Swal from "sweetalert2";
import Download from "@components/Downloads/Download";
import "moment/locale/es";
import MessageAlert from "@components/Downloads/MessageAlert";
import AdminLayout from "@components/MainPage/AdminLayout";
import BasicTable from "@components/Tables/BasicTable";
import ActionHeader from "@components/Containers/ActionHeader";
import FilterLiderazgo from "../../components/FrenteAmplio/FilterLiderazgo";
import FrenteAmplioServices from "@services/FrenteAmplioServices";
import { isEmptyOrNullObject } from "@utils/validations";
import middleware from "@middlewares/middleware";
import PhoneIphoneIcon from "@mui/icons-material/PhoneIphone";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import PermPhoneMsgIcon from "@mui/icons-material/PermPhoneMsg";

const EstadisticaLiderazgo = () => {
  //Constantes
  const colums =  [
    {
      label: "Municipio",
      id: "Municipio",
      columnAction: false,
      orderBy: "Municipio",
      width: 200,
    },
    {
      label: "Liderazgo",
      id: "Liderazgo",
      columnAction: false,
      orderBy: "Liderazgo",
      width: 200,
    },
    { label: "Registros", id: "Registros",orderBy: "Registros", columnAction: false, width: 200 },
    {
      label: "Movilizados",
      id: "TotalBingos",
      orderBy: "TotalBingos",
      columnAction: false,
      width: 200,
    },
    {
      label: "% Movilizados",
      id: "TotalBingos",
      orderBy: "TotalBingos",
      columnAction: false,
      width: 200,
    },
    {
      label: "Por Movilizar",
      id: "TotalBingos",
      orderBy: "TotalBingos",
      columnAction: false,
      width: 200,
    },
    {
      label: "% Por Movilizar",
      id: "TotalBingos",
      orderBy: "TotalBingos",
      columnAction: false,
      width: 200,
    }/* ,
    {
      label: "% Movilizados",
      id: "%Movilizados",
      orderBy: "%Movilizados",
      columnAction: false,
      width: 200,
    }, */
  ];

  const [data, setData] = useState({
    page: 0,
    pageSize: 10,
    filtered: [],
    sorted: [],
  });

  //State
  const [total, setTotal] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [dataLisCommitments, setDataLisCommitments] = useState([]);
  const [sortedData, setSortedData] = useState([]);
/*   const [successDownload, setSuccessDownload] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false); */
/* 
  const handleExport = () => {
    setIsDownloading(true);
    FrenteAmplioServices.exportMovilizacion({
      filtered: data.filtered,
      page: 0,
      pageSize: 99999999999999,
    })
      .then((res) => {
        if (res.success) {
          setShowMessage(true);
          setSuccessDownload(true);
        } else {
          setShowMessage(true);
          setSuccessDownload(false);
        }
      })
      .catch((error) => {
        setShowMessage(true);
        setSuccessDownload(false);
      })
      .finally(() => setIsDownloading(false));
  }; */

  const getList = (data) => {
    setIsLoading(true);
    FrenteAmplioServices.getListStastLiderazgo(data)
      .then((res) => {
        if (res.results) {
          const Commitments = res.response.data.map((item) => {
            return {
              ...item,
              Liderazgo: (
                <Typography variant="div" fontWeight={700} fontSize={15}>
                  {item.Liderazgo}
                </Typography>
              ),
              TotalBingos:item.TotalBingos === 0 ? "0" :item.TotalBingos
            };
          });
          setDataLisCommitments(Commitments);
          setTotal(res.response.total);
        } else {
          Swal.fire({ title: res.message, icon: "warning" });
          setDataLisCommitments([]);
          setTotal(0);
        }
      })
      .catch((err) => {
        Swal.fire({ title: err, icon: "error" });
        setDataLisCommitments([]);
        setTotal(0);
      })
      .finally(() => setIsLoading(false));
    // eslint-disable-next-line
  };

  useEffect(() => {
    getList(data);
    // eslint-disable-next-line
  }, [data]);

  useEffect(() => {
    if (sortedData.length > 0) {
      const params = {
        ...data,
        sorted: sortedData,
      };

      getList(params);
    }
    // eslint-disable-next-line
  }, [sortedData]);

  const handleChangeFilter = (filtered) => {
    setDataLisCommitments([]);
    setData((prevState) => ({ ...prevState,page:0, filtered }));
  };

  const handleChangePagination = (pagination) => {
    setData({ ...data, ...pagination });
  };

  const handleSorted = (dataSorted) => {
    const fields = {
      Municipio: "estadistica_liderazgos.Municipio",
      Liderazgo:"estadistica_liderazgos.Liderazgo",
      Registros: "estadistica_liderazgos.Registros",
      SIANFAM:"estadistica_liderazgos.SIANFAM",
      TotalBingos: "estadistica_liderazgos.TotalBingos",
      PendientesFAM:"estadistica_liderazgos.PendientesFAM",
      PorcentajeEfectividad:"estadistica_liderazgos.PorcentajeEfectividad"
    };

    let sorted = [];
    if (!isEmptyOrNullObject(dataSorted)) {
      sorted.push({
        id: fields[dataSorted.orderBy],
        value: dataSorted.order,
      });
    }

    return sorted;
  };

  const handleRequestSort = (sortData) => {
    if (total > 0) setSortedData(handleSorted(sortData));
  };

  return (
    <AdminLayout>
      <Container maxWidth="false">
        <ActionHeader title={"Estadísticas Liderazgo"} />
        <Box>
          <FilterLiderazgo onChangeFilter={(e) => handleChangeFilter(e)} />
          <Card className="card-primary">
            <CardContent>
{/* 
            {middleware.checkMenuActionId("Exportar") && (<>
              {isDownloading ? (
                <Download isDownload={isDownloading} format="xlsx" />
              ) : (
                <Box
                  display={"flex"}
                  justifyContent={"flex-end"}
                  sx={{ mb: 2 }}
                >
                  <Button
                    variant="outlined"
                    color="primaryDark"
                    startIcon={<Icon>download</Icon>}
                    size="small"
                    onClick={handleExport}
                    sx={{ borderRadius: "10px" }}
                  >
                    Exportar
                  </Button>
                </Box>
              )}
              </>)}
              {showMessage && (
                <Box marginTop={2}>
                  <MessageAlert
                    showMessage={showMessage}
                    successDownload={successDownload}
                    setShowMessage={setShowMessage}
                  />
                </Box>
              )} */}
              <BasicTable
                rows={dataLisCommitments}
                hcolumns={colums}
                handleChangePagination={handleChangePagination}
                isLoading={isLoading}
                total={total}
                pageProp={data.page}
                pageSize={data.pageSize}
                stickyHeader={true}
                handleManualSort={handleRequestSort}
              />
            </CardContent>
          </Card>
        </Box>
      </Container>
    </AdminLayout>
  );
};

export default EstadisticaLiderazgo;
