import Yup from "@utils/Yupi18n";
// import moment from "moment";
import {
  VOL_RC,
  VOL_RG,
  VOL_OBSERVER,
  VOL_FiL /*VIGENCIA_INE*/,
} from "@data/constants";
import { isEmptyOrInvalidString, isNullOrUndefined } from "@utils/validations";

const pattern = /[A-Z|a-z]{6}[0-9]{8}[A-Z|a-z]{1}[0-9]{3}/g;

const FrenteAddSchema = Yup.object({
  idUsuarioPropietario: Yup.number().optional().nullable(true),
  InvitadoPor: Yup.string().when('flagOtro', {
    is: true,
    then: Yup.string().min(5,"Debe Colocar el nombre de la persona que lo invito").max(250, 'Invitado Por debe tener como máximo 250 caracteres').required('Invitador por es obligatorio.'),
    otherwise: Yup.string().nullable(true),
  }),
  idCompromisoPropietario: Yup.number().when("idUsuarioPropietario", {
    is: (val) => isEmptyOrInvalidString(val),
    then: Yup.number().required(),
    otherwise: Yup.number().optional().nullable(true),
  }),
  idUsuarioResponsable: Yup.number().when("idCompromisoPropietario", {
    is: (val) => !isEmptyOrInvalidString(val),
    then: Yup.number().required(),
    otherwise: Yup.number().optional().nullable(true),
  }),
  INE: Yup.string()
    .min(18)
    .max(18)
    .matches(pattern, "Ingrese una Clave de Elector válida")
    .required()
    .label("Clave de Elector"),
  Nombre: Yup.string().required().min(3).label("Nombre"),
  Paterno: Yup.string().required().min(2).label("Apellido Paterno"),
  Materno: Yup.string().required().min(2).label("Apellido Materno"),
  FechaNacimiento: Yup.date()
    .required()
    .label("Fecha de nacimiento")
    .typeError("Ingrese una Clave de Elector válida"),
  idSexo: Yup.number()
    .required()
    .label("Sexo")
    .typeError("Ingrese una Clave de Elector válida"),
  idEstadoNacimiento: Yup.number()
    .required()
    .label("Estado de nacimiento")
    .typeError("Ingrese una Clave de Elector válida"),
  CalleVive: Yup.string().required().min(3).label("Calle donde vive"),
  NumExtVive: Yup.string().optional().nullable(true),
  NumIntVive: Yup.string().optional().nullable(true),
  idMunicipioVive: Yup.number().required().label("Municipio"),
  idLocalidadVive: Yup.number().required().label("Localidad"),
  ColoniaVive: Yup.string().required().min(3).label("Colonia"),
  CPVive: Yup.number()
  .required('El código postal es obligatorio')
  .integer('El código postal debe ser un número entero')
  .positive('El código postal debe ser un número positivo')
  .test('len', 'El código postal debe tener 5 dígitos', val => val && val.toString().length === 5),
  SeccionVota: Yup.number().required().min(1).max(8000).label("Sección Vota"),
  Celular: Yup.string()
    .phone("Ingrese un número de teléfono válido")
    .optional()
    .nullable(true)
    .min(10)
    .max(10),
  Telefono: Yup.string()
    .phone("Ingrese un número de teléfono válido")
    .optional()
    .nullable(true)
    .min(10)
    .max(10),
  TelMensajes: Yup.string()
    .phone("Ingrese un número de teléfono válido")
    .optional()
    .nullable(true)
    .min(10)
    .max(10),
  Correo: Yup.string().email().optional().nullable(true),
  Facebook: Yup.string().optional().nullable(true),
  Instagram: Yup.string().optional().nullable(true),
  Twitter: Yup.string().optional().nullable(true),
  TikTok: Yup.string().optional().nullable(true),
  VigenciaINE: Yup.number()
    .when("idVoluntario", {
      is: (val) => [VOL_RC, VOL_RG, VOL_OBSERVER, VOL_FiL].includes(val),
      then: Yup.number()
        .required()
        .typeError("Ingrese una Vigencia INE válida"), // .min(VIGENCIA_INE, "la Vigencia no debe ser menor al año actual")
      otherwise: Yup.number().optional().nullable(true),
    })
    .label("Vigencia INE"),
  FrontIne: Yup.string().required().nullable(true).label("FOTO DELANTER"),
    BehindIne: Yup.string().required().nullable(true).label("FOTO TRASERA"),
});

const FrenteEditSchema = Yup.object({
  idUsuarioPropietario: Yup.number().optional().nullable(true),
  idCompromisoPropietario: Yup.number().when("idUsuarioPropietario", {
    is: (val) => isEmptyOrInvalidString(val),
    then: Yup.number().required(),
    otherwise: Yup.number().optional().nullable(true),
  }),
  idUsuarioResponsable: Yup.number().when("idCompromisoPropietario", {
    is: (val) => !isEmptyOrInvalidString(val),
    then: Yup.number().required(),
    otherwise: Yup.number().optional().nullable(true),
  }),
  INE: Yup.string()
    .min(18)
    .max(18)
    .matches(pattern, "Ingrese una Clave de Elector válida")
    .required()
    .label("Clave de Elector"),
  Nombre: Yup.string().required().min(3).label("Nombre"),
  Paterno: Yup.string().required().min(2).label("Apellido Paterno"),
  Materno: Yup.string().required().min(2).label("Apellido Materno"),
  FechaNacimiento: Yup.date()
    .required()
    .label("Fecha de nacimiento")
    .typeError("Ingrese una Clave de Elector válida"),
  idSexo: Yup.number()
    .required()
    .label("Sexo")
    .typeError("Ingrese una Clave de Elector válida"),
  idEstadoNacimiento: Yup.number()
    .required()
    .label("Estado de nacimiento")
    .typeError("Ingrese una Clave de Elector válida"),
  CalleVive: Yup.string().required().min(3).label("Calle donde vive"),
  NumExtVive: Yup.string().optional().nullable(true),
  NumIntVive: Yup.string().optional().nullable(true),
  idMunicipioVive: Yup.number().required().label("Municipio"),
  idLocalidadVive: Yup.number().required().label("Localidad"),
  ColoniaVive: Yup.string().required().min(3).label("Colonia"),
  CPVive: Yup.number()
  .required('El código postal es obligatorio')
  .integer('El código postal debe ser un número entero')
  .positive('El código postal debe ser un número positivo')
  .test('len', 'El código postal debe tener 5 dígitos', val => val && val.toString().length === 5),
  SeccionVota: Yup.number().required().min(1).max(8000).label("Sección Vota"),
  Celular: Yup.string()
    .phone("Ingrese un número de teléfono válido")
    .optional()
    .nullable(true)
    .min(10)
    .max(10),
  Telefono: Yup.string()
    .phone("Ingrese un número de teléfono válido")
    .optional()
    .nullable(true)
    .min(10)
    .max(10),
  TelMensajes: Yup.string()
    .phone("Ingrese un número de teléfono válido")
    .optional()
    .nullable(true)
    .min(10)
    .max(10),
  Correo: Yup.string().email().optional().nullable(true),
  Facebook: Yup.string().optional().nullable(true),
  Instagram: Yup.string().optional().nullable(true),
  Twitter: Yup.string().optional().nullable(true),
  TikTok: Yup.string().optional().nullable(true),
  VigenciaINE: Yup.number()
    .when("idVoluntario", {
      is: (val) => [VOL_RC, VOL_RG, VOL_OBSERVER, VOL_FiL].includes(val),
      then: Yup.number()
        .required()
        .typeError("Ingrese una Vigencia INE válida"), // .min(VIGENCIA_INE, "la Vigencia no debe ser menor al año actual")
      otherwise: Yup.number().optional().nullable(true),
    })
    .label("Vigencia INE"),
  FrontIne: Yup.string().optional().nullable(true),
    BehindIne: Yup.string().optional().nullable(true),
});

const FilterFrenteSchema = Yup.object({
  celular: Yup.string().optional(),
  nombre: Yup.string().optional(),
  Correo: Yup.string().optional(),
});

const BingoFASchema = Yup.object({
  idMunicipio: Yup.object().shape({
    value: Yup.number().min(1).required(),
    label: Yup.string().required(),
  }).required(),
  Hoja:Yup.number().required(),
  Bingo:Yup.number().min(1).required()
});

const AsignacionSchema = Yup.object({

});


export {
  FrenteAddSchema,
  FrenteEditSchema,
  FilterFrenteSchema,
  BingoFASchema,
  AsignacionSchema
};
