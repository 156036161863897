import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import {
  Container,
  Card,
  CardHeader,
  CardContent,
  Button,
  ListItem,
  ListItemText,
  Typography
} from "@mui/material";
import { Add, ChevronLeft } from "@mui/icons-material";
import Swal from "sweetalert2";

import AdminLayout from "@components/MainPage/AdminLayout";
import ActionHeader from "@components/Containers/ActionHeader";
import CandidatesTable from "@components/Prep/CandidatesTable";
import CandidateForm from "@components/Prep/CandidateForm";

import middleware from "@middlewares/middleware"
import { isEmptyOrNullObject } from "@utils/validations";
import prep from "@services/PrepServices";

const Candidatos = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const getStatesURL = location.state ? location.state : null;

  const [flagForm, setFlagForm] = useState(false);
  const [params, setParams] = useState(
    {
      idTipoEleccion: null,
      TipoEleccion: "",
      AmbitoValor: 0,
      Ambito: ""
    }
  );
  const [filtered, setFiltered] = useState([]);
  const [data4Edit, setData4Edit] = useState(null);

  useEffect(() => {
    if (!isEmptyOrNullObject(getStatesURL)) {
      setParams(getStatesURL);
      setFiltered(handleFiltered(getStatesURL));
    } else {
      setFlagForm(true);
    }
  }, [getStatesURL]);

  const handleFiltered = (filterData) => {
    let filtered = [];

    if (filterData.idTipoEleccion !== '') {
      filtered.push({
        id: 'prep_planillas.idTipoEleccion',
        filter: '=',
        value: filterData.idTipoEleccion
      })
    }

    if (filterData.AmbitoValor && filterData.AmbitoValor !== '') {
      filtered.push({
        id: 'prep_planillas.AmbitoValor',
        filter: '=',
        value: filterData.AmbitoValor
      })
    }

    return filtered;
  }

  const handleEdit = async (data) => {
    try {
      const result = await prep.findCandidate(data.idCandidato);
      const { results, response } = result;
      if (results) {
        setFlagForm(true);
        setData4Edit(response);
      } else {
        setData4Edit(null);
      }
    } catch (error) {
      Swal.fire({
        title: error.message,
        icon: 'warning'
      });
      setData4Edit(null);
    }
  }

  return (
    <AdminLayout>
      <Container maxWidth={false}>
        <ActionHeader title="Administración de Candidatos"
          titleButton={"Regresar a Planillas"}
          iconButton={"chevron_left"}
          isAction={true}
          handleclick={() => navigate("/prep-planillas")}
        />

        <Card
          sx={{
            variant: "elevation",
            borderRadius: "12px",
            padding: 0,
            width: "100%",
          }}
          className="card-primary"
        >
          <CardHeader
            title={!flagForm ?
              (<ListItem>
                <ListItemText
                  primary={<Typography variant="h5">Candidatos a {params.TipoEleccion}</Typography>}
                  secondary={<Typography component={'div'} variant="div" fontWeight={200}>{params.Ambito}</Typography>}
                />
              </ListItem>) : 'Registro / Edición'}
            action={
              flagForm ? (
                <Button
                  color="primaryDark"
                  size="medium"
                  variant="contained"
                  onClick={(e) => {
                    if (!isEmptyOrNullObject(getStatesURL)) {
                      setFlagForm(false);
                    } else {
                      navigate('/prep-planillas')
                    }

                  }}
                  startIcon={<ChevronLeft />}
                  sx={{ m: 2 }}
                >
                  Regresar
                </Button>
              )
                : (
                  middleware.checkMenuAction("Agregar") && (
                    <Button
                      color="primaryDark"
                      size="medium"
                      variant="contained"
                      onClick={(e) => {
                        setFlagForm(true);
                        setData4Edit(null);
                      }}
                      startIcon={<Add />}
                      sx={{ m: 2 }}
                    >
                      Agregar
                    </Button>
                  )
                )
            }
          />
          <CardContent className="p-0">
            {
              !flagForm ?
                <CandidatesTable filtered={filtered} handleEdit={handleEdit} /> :
                <CandidateForm data4Edit={data4Edit} />
            }
          </CardContent>
        </Card>
      </Container>
    </AdminLayout>
  )
}

export default Candidatos;