import { useEffect, useState } from "react";
import { useFormik } from "formik";
import { useCatalogs } from "@hooks/useCatalogs";
import { CapturaIncidenciaInterface } from "@data/interfaces/SijeInterface";
import { isEmptyOrInvalidString, isTypePhone } from "@utils/validations";
import { capturaIncidenciaSchema } from "@data/schemas/SijeSchema";
import Swal from "sweetalert2";
import sije from "@services/SijeService";

import {
  TextField,
  Typography,
  FormControlLabel,
  Checkbox,
  InputAdornment,
  Icon,
  Stack,
  Button,
  Divider,
  FormGroup,
} from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";

import BasicSelect from "@components/Selects/BasicSelect";
import LoadingForm from "@components/LinearProgress/LoadingForm";

import moment from "moment";

const CaptureIncindets = () => {
  const [catCasillas, setCatCasillas] = useState([]);
  const [loadCasillas, setLoadCasillas] = useState(false);
  //const [catIncidents, setCatIncidents] = useState([]);
  //const [loadIncendent, setLoadIncendent] = useState(false);
  const [isLoadigForm, setIsLoadingForm] = useState(false);
  const [openLinearProgress, setOpenLinearProgress] = useState(false);
  const { catalogs, load } = useCatalogs({
    catalogsOptions: [{ id: "incidencias", getAll: false }],
    putDefaultOption: false,
  });

  const formik = useFormik({
    initialValues: CapturaIncidenciaInterface,
    validationSchema: capturaIncidenciaSchema,
    onSubmit: (values) => {
      handleSave(values);
    },
  });

  /*  const getCatalogIncendent = async () => {
    try {
      setLoadIncendent((prevState) => !prevState);
      const result = await sije.getCatalogs({
        catalogs: [{ id: "incidencias" }],
      });
      const { response, message, results } = result;
      if (results) {
        setCatIncidents(response.catalogs.incidencias);
      } else {
        throw new Error(message);
      }
    } catch (error) {
      Swal.fire({
        title: error.message,
        icon: "warning",
      });
    } finally {
      setLoadIncendent((prevState) => !prevState);
    }
  }; */

  const handleChangeSection = async (e) => {
    const section = e.target.value;
    try {
      setLoadCasillas((prevState) => !prevState);
      const result = await sije.getCasillasBySeccion({ Seccion: section });
      const { response, results, message } = result;
      if (results) {
        const casillas = response.data.map((item) => {
          return {
            label: item.NombreCasilla,
            value: item.idCasilla,
          };
        });
        setCatCasillas(casillas);
      } else {
        setCatCasillas([]);
        formik.setFieldValue("idCasilla", "");
        throw new Error(message);
      }
    } catch (error) {
      Swal.fire({
        title: error.message,
        icon: "warning",
      });
    } finally {
      setLoadCasillas((prevState) => !prevState);
    }
  };

  const handleSave = async (values) => {
    const params = {
      ...values,
      idStatus: values.esResuelto ? 3 : 1,
      ComoSeResolvio: values.esResuelto ? values.ComoSeResolvio : null,
      HoraSolucion: values.esResuelto ? values.HoraSolucion : null,
    };
    delete params.seccion;

    try {
      setIsLoadingForm(true);
      setOpenLinearProgress(true);
      const result = await sije.setSijeIncidencias({
        ...params,
        OrigenCaptura: "web"
      });
      const { message, results } = result;
      if (results) {
        Swal.fire({
          title: message,
          icon: "success",
        }).then(({ isConfirmed }) => {
          if (isConfirmed) {
            clearForm();
          }
        });
      } else {
        throw new Error(message);
      }
    } catch (error) {
      Swal.fire({
        title: error.message,
        icon: "warning",
      });
    } finally {
      setIsLoadingForm(false);
      setOpenLinearProgress(false);
    }
  };

  const handleCheckActionType = (e) => {
    const checked = e.target.checked;
    const value = e.target.value;
    const name = e.target.name;

    if (name === "AsentadoEnActa") {
      if (checked) {
        formik.setFieldValue("AsentadoEnActa", parseInt(value), false);
      } else {
        formik.setFieldValue("AsentadoEnActa", "", false);
      }
    } else if (name === "esResuelto") {
      if (checked) {
        formik.setFieldValue("esResuelto", parseInt(value), false);
      } else {
        formik.setFieldValue("esResuelto", "", false);
      }
    }
  };

  const handleOnkeyDown = (event) => {
    if (event.keyCode === 9) {
      event.preventDefault();
      handleChangeSection(event);
    }
  };

  const handleOnkeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      handleChangeSection(event);
    }
  };

  const clearForm = () => {
    if (formik.values.seccion.length > 0) {
      formik.setValues({
        seccion: "",
        idCasilla: "",
        idIncidencia: "",
        DescripcionIncidencia: "",
        AsentadoEnActa: 0,
        HoraIncidencia: "",
        esResuelto: 0,
        ComoSeResolvio: "",
        HoraSolucion: "",
      });

      setCatCasillas([]);
    }
  };

  /*   useEffect(() => {
    getCatalogIncendent();
  }, []); */

  useEffect(() => {
    formik.setValues({
      ...formik.values,
      HoraIncidencia: moment().format("HH:mm"),
      HoraSolucion: moment().format("HH:mm"),
    });
  }, []);

  return (
    <>
      <LoadingForm
        loadinMessage={"Guardando datos..."}
        isLoading={isLoadigForm}
        isOpen={openLinearProgress}
        setIsOpen={() => setOpenLinearProgress(!openLinearProgress)}
      />
      <Typography textAlign={"center"} variant="caption">
        Escribe la sección y posteriormente presiona enter para continuar con la
        captura.
      </Typography>
      <Grid2 container spacing={2} marginTop={1}>
        <Grid2 xs={12} md={6} lg={6}>
          <TextField
            label="Sección"
            name="seccion"
            value={formik.values.seccion}
            //onChange={formik.handleChange}
            onChange={(e) =>
              formik.handleChange({
                target: {
                  name: "seccion",
                  value: isTypePhone(e.target.value)
                    ? e.target.value
                    : formik.values.seccion,
                },
              })
            }
            onKeyDown={handleOnkeyDown}
            onKeyUp={handleOnkeyPress}
            size="small"
            sx={{ width: "100%" }}
          />
        </Grid2>
        <Grid2 xs={12} md={6} lg={6}>
          <BasicSelect
            label="Casilla"
            name="idCasilla"
            options={catCasillas}
            value={formik.values.idCasilla}
            onChange={formik.handleChange}
            error={
              formik.touched.idCasilla &&
              !isEmptyOrInvalidString(formik.errors.idCasilla)
            }
            errorMessage={formik.errors.idCasilla}
            isLoading={loadCasillas}
            sx={{ width: "100%" }}
          />
        </Grid2>
        <Grid2 xs={12} md={12} lg={12}>
          <BasicSelect
            label="Incidencia"
            name="idIncidencia"
            options={catalogs.incidencias}
            value={formik.values.idIncidencia}
            onChange={formik.handleChange}
            error={
              formik.touched.idIncidencia &&
              !isEmptyOrInvalidString(formik.errors.idIncidencia)
            }
            errorMessage={formik.errors.idIncidencia}
            isLoading={load}
            sx={{ width: "100%" }}
          />
        </Grid2>
        <Grid2 xs={12} md={12} lg={12}>
          <Typography variant="subtitle2">
            ¿Fue asentado en el Acta de la Jornada Electoral?
          </Typography>
          <FormGroup row>
            <FormControlLabel
              control={
                <Checkbox
                  checked={
                    formik.values.AsentadoEnActa &&
                    formik.values.AsentadoEnActa === 1
                      ? true
                      : false
                  }
                  name="AsentadoEnActa"
                  value={1}
                  onChange={handleCheckActionType}
                />
              }
              label={
                <Typography variant={"body2"} fontWeight={400}>
                  Si fue Asentado
                </Typography>
              }
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={
                    !formik.values.AsentadoEnActa &&
                    formik.values.AsentadoEnActa === 0
                      ? true
                      : false
                  }
                  name="AsentadoEnActa"
                  value={0}
                  onChange={handleCheckActionType}
                />
              }
              label={
                <Typography variant={"body2"} fontWeight={400}>
                  No fue Asentado
                </Typography>
              }
            />
          </FormGroup>
          {/* {formik.errors.AsentadoEnActa && (
            <FormHelperText error>
              {formik.errors.AsentadoEnActa}
            </FormHelperText>
          )} */}
        </Grid2>
        <Grid2 xs={12} md={12} lg={12}>
          <TextField
            label="Descripción Incidencia"
            name="DescripcionIncidencia"
            value={formik.values.DescripcionIncidencia.toUpperCase()}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            multiline
            rows={3}
            error={
              formik.touched.DescripcionIncidencia &&
              !isEmptyOrInvalidString(formik.errors.DescripcionIncidencia)
            }
            helperText={
              formik.touched.DescripcionIncidencia &&
              formik.errors.DescripcionIncidencia &&
              formik.errors.DescripcionIncidencia
            }
            sx={{ width: "100%" }}
          />
        </Grid2>
        <Grid2 xs={12} md={12} lg={12}>
          <TextField
            label={"Hora Incidencia (Formato 24hrs)"}
            name="HoraIncidencia"
            value={formik.values.HoraIncidencia}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            size="small"
            sx={{ width: { md: "35%" } }}
            InputProps={{
              size: "small",
              endAdornment: (
                <InputAdornment position="end">
                  <Icon>schedule</Icon>
                </InputAdornment>
              ),
            }}
            error={
              formik.touched.HoraIncidencia &&
              !isEmptyOrInvalidString(formik.errors.HoraIncidencia)
            }
            helperText={
              formik.touched.HoraIncidencia &&
              formik.errors.HoraIncidencia &&
              formik.errors.HoraIncidencia
            }
          />
        </Grid2>
        <Grid2 xs={12} md={12} lg={12}>
          <Typography variant="subtitle2">
            ¿Fue resuelto el incidente?
          </Typography>
          <FormGroup row>
            <FormControlLabel
              control={
                <Checkbox
                  checked={
                    formik.values.esResuelto && formik.values.esResuelto === 1
                      ? true
                      : false
                  }
                  name="esResuelto"
                  value={1}
                  onChange={handleCheckActionType}
                />
              }
              label={
                <Typography variant={"body2"} fontWeight={400}>
                  Si
                </Typography>
              }
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={
                    !formik.values.esResuelto && formik.values.esResuelto === 0
                      ? true
                      : false
                  }
                  name="esResuelto"
                  value={0}
                  onChange={handleCheckActionType}
                />
              }
              label={
                <Typography variant={"body2"} fontWeight={400}>
                  No
                </Typography>
              }
            />
          </FormGroup>
        </Grid2>
        {formik.values.esResuelto === 1 && (
          <>
            <Grid2 xs={12} md={12} lg={12}>
              <TextField
                label="Descripción de como solucionó el incidente"
                name="ComoSeResolvio"
                value={formik.values.ComoSeResolvio.toUpperCase()}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                multiline
                rows={3}
                error={
                  formik.touched.ComoSeResolvio &&
                  !isEmptyOrInvalidString(formik.errors.ComoSeResolvio)
                }
                helperText={
                  formik.touched.ComoSeResolvio &&
                  formik.errors.ComoSeResolvio &&
                  formik.errors.ComoSeResolvio
                }
                sx={{ width: "100%" }}
              />
            </Grid2>
            <Grid2 xs={12} md={12} lg={12}>
              <TextField
                label={"Hora Solución (Formato 24hrs)"}
                name="HoraSolucion"
                value={formik.values.HoraSolucion}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                size="small"
                sx={{ width: { md: "35%" } }}
                InputProps={{
                  size: "small",
                  endAdornment: (
                    <InputAdornment position="end">
                      <Icon>schedule</Icon>
                    </InputAdornment>
                  ),
                }}
                error={
                  formik.touched.HoraSolucion &&
                  !isEmptyOrInvalidString(formik.errors.HoraSolucion)
                }
                helperText={
                  formik.touched.HoraSolucion &&
                  formik.errors.HoraSolucion &&
                  formik.errors.HoraSolucion
                }
              />
            </Grid2>
          </>
        )}
      </Grid2>
      <Divider sx={{ mt: 2, mb: 2 }} />
      <Stack direction="row" spacing={2} justifyContent={"flex-end"}>
        <Button onClick={clearForm} color="primaryDark">
          Limpiar
        </Button>
        <Button
          variant="contained"
          color="primaryDark"
          onClick={formik.submitForm}
          disabled={formik.values.seccion.length === 0}
        >
          Guardar
        </Button>
      </Stack>
    </>
  );
};

export default CaptureIncindets;
