import { useEffect, useState } from "react";
import { useCatalogs } from "@hooks/useCatalogs";

// Material UI
import { Card, CardContent, Box, Button, Grid } from "@mui/material";

// Componentes
import FilterCollapse from "@components/Filters/FilterCollapse";
import InputSelect from "@components/Selects/BasicSelect";
import makeAnimated from "react-select/animated";
import Select from "react-select";
import { AMBITO_REGIONAL, AMBITO_DF, AMBITO_DL } from "@data/constants";

const FilterData = (props) => {
  const { params, setParams, optionsF, handleFilter } = props;
  const animatedComponents = makeAnimated();
  const selectStyles = {
    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
    menu: (provided) => ({ ...provided, zIndex: "9999 !important" }),
  };

  const catalogsOptions = [
    { id: "regiones", getAll: false },
    { id: "df", getAll: false },
    { id: "dl", getAll: false },
    { id: "poligonos", getAll: false },
    { id: "secciones", getAll: false },
    { id: "municipios_reportes", getAll: false },
    { id: "municipios", getAll: false },
    { id: "mpio_df", getAll: false },
    { id: "mpio_dl", getAll: false }
  ];

  const { catalogs, load } = useCatalogs({
    catalogsOptions,
    putDefaultOption: false,
  });
  const defaultOptions = [{ value: 0, label: "TODOS" }];
  const [catalogsFiltered, setCatalogsFiltered] = useState(() => {
    let newObject = {};
    for (const item of catalogsOptions) {
      newObject[item.id] = [];
    }
    return newObject;
  });
  const [catVariableScope, setCatVariableScope] = useState([]);
  const [labelScope, setLabelScope] = useState('');
  const [selectedPolygons, setSelectedPolygons] = useState([]);
  const [selectedSections, setSelectedSections] = useState([]);

  useEffect(() => {
    if (!load) {
      setCatalogsFiltered(() => {
        let newObject = {};
        const defaultOptions = [{ value: 0, label: "TODOS" }];
        for (const item of catalogsOptions) {
          const key = item.id;
          if (catalogs[key]) {
            const flagConcat = catalogs[key].length > 1 && key !== 'secciones';
            newObject[key] = flagConcat ? defaultOptions.concat(catalogs[key]) : catalogs[key];
          } else {
            newObject[key] = [];
          }
        }

        return newObject;
      });

      handleChangeGroupBy(optionsF[0].value);
    }
    //eslint-disable-next-line
  }, [load]);

  const handleChangeGroupBy = async (value) => {
    // Reinicia los parametros
    setParams({
      ...params,
      Selected: value,
      Variable: 0,
      Municipio: 0,
      Poligono: [],
      Seccion: [],
    });

    // Vacia datos de los selects
    setSelectedPolygons([]);
    setSelectedSections([]);

    let catScope = [];
    switch (value) {
      case AMBITO_REGIONAL:
        setLabelScope('Región');
        catScope = catalogs.regiones.length > 1 ? defaultOptions.concat(catalogs.regiones) : catalogs.regiones;
        setCatVariableScope(catScope);
        break;

      case AMBITO_DF:
        setLabelScope('Distrito Federal');
        catScope = catalogs.df.length > 1 ? defaultOptions.concat(catalogs.df) : catalogs.df;
        setCatVariableScope(catScope);
        break;

      case AMBITO_DL:
        setLabelScope('Distrito Local');
        catScope = catalogs.dl.length > 1 ? defaultOptions.concat(catalogs.dl) : catalogs.dl;
        setCatVariableScope(catScope);
        break;

      default:
        setLabelScope('');
        setCatVariableScope([]);
        break;
    }

    if (catScope.length === 1) {
      handleChangeVariableScope(catScope[0].value)
    } else {

    }
  }

  const handleChangeVariableScope = (value) => {
    // Vacia datos de los selects
    setSelectedPolygons([]);
    setSelectedSections([]);

    let municipalities = [];
    let polygons = [];
    let sections = [];

    if (value < 1) {
      municipalities = catalogs.municipios.length > 1 ? defaultOptions.concat(catalogs.municipios) : catalogs.municipios;
      setCatalogsFiltered(prevState => ({
        ...prevState,
        municipios: municipalities,
        poligonos: catalogs.poligonos,
        secciones: catalogs.secciones
      }));

      setParams({
        ...params,
        Variable: value,
        Municipio: municipalities[0].value,
        Poligono: [],
        Seccion: [],
      });
      return;
    }

    // Reinicia los parametros
    setParams({
      ...params,
      Variable: value,
      Municipio: 0,
      Poligono: [],
      Seccion: [],
    });

    switch (params.Selected) {
      case AMBITO_REGIONAL:
        municipalities = catalogs.municipios.filter(item => item.idRegion === value);
        polygons = catalogs.poligonos.filter(item => item.idRegion === value);
        sections = catalogs.secciones.filter(item => item.idRegion === value);
        setCatalogsFiltered(prevState => ({
          ...prevState,
          municipios: municipalities.length > 1 ? defaultOptions.concat(municipalities) : municipalities,
          poligonos: polygons,
          secciones: sections
        }));
        break;

      case AMBITO_DF:
        municipalities = catalogs.mpio_df.filter(item => item.idDF === value).map(item => ({
          value: item.idMunicipio,
          label: item.Municipio,
          idDF: item.idDF
        }));
        polygons = catalogs.poligonos.filter(item => item.idDF === value);
        sections = catalogs.secciones.filter(item => item.idDF === value);
        setCatalogsFiltered(prevState => ({
          ...prevState,
          municipios: municipalities.length > 1 ? defaultOptions.concat(municipalities) : municipalities,
          poligonos: polygons,
          secciones: sections
        }));
        break;

      case AMBITO_DL:
        municipalities = catalogs.mpio_dl.filter(item => item.idDL === value).map(item => ({
          value: item.idMunicipio,
          label: item.Municipio,
          idDL: item.idDL
        }));
        polygons = catalogs.poligonos.filter(item => item.idDL === value);
        sections = catalogs.secciones.filter(item => item.idDL === value);
        setCatalogsFiltered(prevState => ({
          ...prevState,
          municipios: municipalities.length > 1 ? defaultOptions.concat(municipalities) : municipalities,
          poligonos: polygons,
          secciones: sections
        }));
        break;

      default:
        break;
    }

    if (municipalities.length === 1) {
      handleChangeMunicipality(municipalities[0].value)
    }

  }

  const handleChangeMunicipality = (value) => {
    setParams({
      ...params,
      Municipio: value,
      Poligono: [],
      Seccion: [],
    });
    // Vacia datos de los selects
    setSelectedPolygons([]);
    setSelectedSections([]);
    let polygons = [];
    let sections = [];

    if (value < 1) {
      setCatalogsFiltered(prevState => ({
        ...prevState,
        poligonos: catalogs.poligonos,
        secciones: catalogs.secciones
      }));

      return;
    }

    switch (params.Selected) {
      case AMBITO_REGIONAL:
        polygons = catalogs.poligonos.filter(item => item.idRegion === params.Variable && item.idMunicipio === value);
        sections = catalogs.secciones.filter(item => item.idRegion === params.Variable && item.idMunicipio === value);
        setCatalogsFiltered(prevState => ({
          ...prevState,
          poligonos: polygons,
          secciones: sections
        }));
        break;

      case AMBITO_DF:
        polygons = catalogs.poligonos.filter(item => item.idDF === params.Variable && item.idMunicipio === value);
        sections = catalogs.secciones.filter(item => item.idDF === params.Variable && item.idMunicipio === value);
        setCatalogsFiltered(prevState => ({
          ...prevState,
          poligonos: polygons,
          secciones: sections
        }));
        break;

      case AMBITO_DL:
        polygons = catalogs.poligonos.filter(item => item.idDL === params.Variable && item.idMunicipio === value);
        sections = catalogs.secciones.filter(item => item.idDL === params.Variable && item.idMunicipio === value);
        setCatalogsFiltered(prevState => ({
          ...prevState,
          poligonos: polygons,
          secciones: sections
        }));
        break;
      default:
        break;
    }

    if (polygons.length === 1) {
      handleChangePolygon(polygons);
    }
  }

  const handleChangePolygon = (newValue) => {
    const array_poligon = newValue.map((option) => option.value || option);
    setSelectedPolygons(newValue);
    setSelectedSections([]);

    setParams({
      ...params,
      Poligono: array_poligon,
      Seccion: [],
    });

    let sections = catalogs.secciones;

    switch (params.Selected) {
      case AMBITO_REGIONAL:
        if (params.Variable > 0) {
          sections = catalogs.secciones.filter(item => item.idRegion === params.Variable)
        }
        break;

      case AMBITO_DF:
        if (params.Variable > 0) {
          sections = catalogs.secciones.filter(item => item.idDF === params.Variable)
        }
        break;

      case AMBITO_DL:
        if (params.Variable > 0) {
          sections = catalogs.secciones.filter(item => item.idDL === params.Variable)
        }
        break;
      default:
        break;
    }

    if (params.Municipio > 0) {
      sections = sections.filter(item => item.idMunicipio === params.Municipio)
    }

    if (array_poligon.length > 0) {
      sections = sections.filter(item => array_poligon.some((id) => id === item.idPoligono));
    }

    setCatalogsFiltered(prevState => ({
      ...prevState,
      secciones: sections
    }));
  }

  const handleChangeSection = (newValue) => {
    setSelectedSections(newValue);
    const array_section = newValue.map((option) => option.value || option);
    setParams({
      ...params,
      Seccion: array_section,
    });
  }

  const handleClearFilter = () => {
    handleChangeGroupBy(optionsF[0].value);
    handleFilter("clear");
  }

  return (
    <Card className="card-primary">
      <CardContent>
        <FilterCollapse expand>
          <Box display="flex" flexDirection="column">
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={12} sm={6} lg={4}>
                <InputSelect
                  label="Filtrar por"
                  options={optionsF}
                  name="FiltrarPor"
                  value={params.Selected}
                  onChange={e => handleChangeGroupBy(e.target.value)}
                  sx={{ width: "100%" }}
                />
              </Grid>

              <Grid item xs={12} sm={6} lg={4}>
                <InputSelect
                  label={labelScope}
                  options={catVariableScope}
                  name="variable"
                  value={params.Variable}
                  onChange={e => handleChangeVariableScope(e.target.value)}
                  isLoading={load}
                  sx={{ width: "100%" }}
                  disabled={catVariableScope.length === 1 ? true : false}
                  variant="outlined"
                />
              </Grid>

              <Grid item xs={12} sm={6} lg={4}>
                <InputSelect
                  label="Municipio"
                  options={catalogsFiltered.municipios}
                  name="municipio"
                  value={params.Municipio}
                  onChange={e => handleChangeMunicipality(e.target.value)}
                  isLoading={load}
                  sx={{ width: "100%" }}
                  disabled={catalogsFiltered.municipios.length <= 1 ? true : false}
                  variant="outlined"
                />
              </Grid>

              <Grid item xs={12} sm={6} lg={4}>
                <Select
                  placeholder="Polígono"
                  options={catalogsFiltered.poligonos}
                  value={selectedPolygons}
                  onChange={handleChangePolygon}
                  isLoading={load}
                  isDisabled={catalogsFiltered.poligonos.length <= 1 ? true : false}
                  components={animatedComponents}
                  closeMenuOnSelect={false}
                  isMulti
                />
              </Grid>

              <Grid item xs={12} sm={6} lg={4}>
                <Select
                  placeholder="Sección"
                  styles={selectStyles}
                  menuPortalTarget={document.body}
                  options={catalogsFiltered.secciones}
                  value={selectedSections}
                  onChange={handleChangeSection}
                  isLoading={load}
                  isDisabled={catalogsFiltered.secciones.length <= 1 ? true : false}
                  components={animatedComponents}
                  closeMenuOnSelect={false}
                  isMulti
                />
              </Grid>

              {/* Botones */}
              <Grid item xs={12} lg={4}>
                <Box sx={{ display: "flex", gap: { xs: 1, sm: 2 } }}>
                  <Button
                    variant="outlined"
                    color="primaryDark"
                    onClick={handleClearFilter}
                    disabled={load}
                  >
                    Limpiar
                  </Button>
                  <Button
                    variant="contained"
                    color="primaryDark"
                    onClick={() => handleFilter("filter")}
                    disabled={load}
                  >
                    Filtrar
                  </Button>
                </Box>
              </Grid>

            </Grid>
          </Box>
        </FilterCollapse>
      </CardContent>
    </Card>
  )
}

export default FilterData;