import {
  Box,
  Card,
  CardContent,
  Icon,
  Stack,
  Typography,
  Divider,
  CircularProgress
} from "@mui/material";
import { blue } from "@mui/material/colors";
import { numberWithCommas } from '@utils/Utilities';

const CardCommitments = (props) => {
  const {
    icon,
    title,
    total,
    subtitle1,
    subtotal1,
    subtitle2,
    subtotal2,
    subtitle3,
    subtotal3,
    subtitle4,
    subtotal4,
    subIcon,
    subFooter,
    loading
  } = props;
  return (
    <Card className="card-primary">
      <CardContent>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="flex-start"
        >
          <Icon sx={{ fontSize: "3rem", color: blue[300] }}>{icon}</Icon>
          <Box>
            <Typography variant="body2" sx={{ fontSize: "15px" }}>
              {title}
            </Typography>
            {
              loading ? <CircularProgress /> :
              <Typography variant="h3" sx={{ fontSize: "35px",textAlign:"center" }}>
              {numberWithCommas(total)}
             </Typography>
            }
          </Box>
        </Stack>
        <Stack
          direction="row"
          justifyContent="space-around"
          alignItems="center"
          spacing={1}
        >
        {subtotal1 !== "" &&  <Box margin={"10px"}>
            <Typography variant="body2" sx={{ fontSize: "13px" }}>
              {subtitle1}
            </Typography>
           { loading ? <CircularProgress /> : <Typography variant="h3" sx={{ fontSize: "25px",textAlign:"center" }}>
              {numberWithCommas(subtotal1)}
            </Typography>}
          </Box>}
         {subtotal2 !== "" &&  <Box margin={"10px"}>
            <Typography variant="body2" sx={{ fontSize: "13px" }}>
              {subtitle2}
            </Typography>
          {loading ? <CircularProgress /> :  <Typography variant="h3" sx={{ fontSize: "25px",textAlign:"center" }}>
              {numberWithCommas(subtotal2)}
            </Typography>}
          </Box>}
         {subtotal3 !== "" &&  <Box margin={"10px"}>
            <Typography variant="body2" sx={{ fontSize: "13px" }}>
              {subtitle3}
            </Typography>
          {loading ? <CircularProgress /> :  <Typography variant="h3" sx={{ fontSize: "25px",textAlign:"center" }}>
              {numberWithCommas(subtotal3)}
            </Typography>}
          </Box>}
          {subtotal4 !== "" &&  <Box margin={"10px"}>
            <Typography variant="body2" sx={{ fontSize: "13px" }}>
              {subtitle4}
            </Typography>
          {loading ? <CircularProgress /> :  <Typography variant="h3" sx={{ fontSize: "25px",textAlign:"center" }}>
              {numberWithCommas(subtotal4)}
            </Typography>}
          </Box>}
        </Stack>
        <Divider variant="middle" />
        <Stack
          direction="row"
          alignItems="center"
          spacing={1}
        >
          <Icon sx={{ fontSize: "1rem", color: blue[800] }}>
            {subIcon}
          </Icon>
          <Typography
            variant="subtitle2"
            fontWeight={300}
            component="span"
            display={"flex"}
            alignItems={"center"}
          >
            {subFooter}
          </Typography>
        </Stack>
      </CardContent>
    </Card>
  );
};
export default CardCommitments;
