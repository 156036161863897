import React, { useState, useEffect, useCallback } from 'react';
// import { Box, Table, TableContainer, TableBody } from "@mui/material";
import Swal from "sweetalert2";

import { getVars } from "@utils/global";
import BasicDraggable from "@components/Draggable/BasicDraggable";
import DraggableListItem from "@components/Draggable/DraggableListItem";
import LoadingForm from "@components/LinearProgress/LoadingForm";
import UserRoleService from "@services/RoleServices";

const ListDraggable = (props) => {
  const {
    items,
  } = props;
  return (
    items.map((item, index) => (
      <DraggableListItem item={item} index={index} key={item.id} />
    ))
  );
};

const RoleOrderList = props => {
  const [roleList, setRoleList] = useState([]);
  const [isloadigForm, setIsLoadingForm] = useState(false);
  const [issuccessForm] = useState(false);
  const [openLinearProgress, setOpenLinearProgress] = useState(false);

  const [access_token] = useState(() => {
    let t = getVars("Token");
    if (t !== {}) return t.access_token;
    return "";
  });
  const getRoles = useCallback(() => {
    setIsLoadingForm(true);
    setOpenLinearProgress(true);
    UserRoleService.getRoles({
      page: 0,
      pageSize: 999999
    }, access_token)
      .then((res) => {
        if (res.results) {
          if (res.response.total > 0) {
            setRoleList(res.response.data.map(item => {
              return {
                ...item,
                primary: item.Perfil,
                secondary: item.Descripcion,
              }
            }));
          }
        } else {
          setRoleList([]);
          Swal.fire({ title: res.message, icon: "warning" });
        }
      })
      .catch((e) => {
        Swal.fire({ title: e.message, icon: "warning" });
      }).finally(() => {
        setIsLoadingForm(false);
        setOpenLinearProgress(false);
      });
  }, [access_token])

  useEffect(() => {
    getRoles()
  }, [getRoles])

  const changeOrder = (newOrder) => {
    let Change = [];
    newOrder.forEach((row, index) => {
      if (row.id !== roleList[index].id) {
        Change.push({
          id: row.id,
          Orden: index,
        });
      }
    });

    UserRoleService.changeOrder(Change, access_token)
      .then((res) => {
        if (res.success) {
          setRoleList(newOrder);
          Swal.fire({
            title: "Actualizado con éxito", icon: "success", customClass: {
              container: "modal-alert",
            },
          })
        } else {
          Swal.fire({
            title: res.message, icon: "warning", customClass: {
              container: "modal-alert",
            }
          });
        }
      })
      .catch((e) => {
        Swal.fire({
          title: e.message, icon: "warning", customClass: {
            container: "modal-alert",
          }
        });
      });
  };

  return (
    <>
      <LoadingForm
        isLoading={isloadigForm}
        success={issuccessForm}
        isOpen={openLinearProgress}
        setIsOpen={() => setOpenLinearProgress(!openLinearProgress)}
        loadinMessage={"Cargando..."}
      />
      <BasicDraggable
        items={roleList}
        setItems={changeOrder}
        rowComponent={ListDraggable({
          items: roleList,
        })}
      ></BasicDraggable>
    </>
  )
}

export default RoleOrderList;