import ApiExec from "@utils/ApiExec";

class SystemServices {
  getRiskLogs = (params) => {
    return ApiExec(params, "system/get-risk-logs", "POST")
      .then(function (res) {
        return res;
      })
      .then((res) => {
        return res;
      })
      .catch((e) => {
        return e;
      });
  };
}

const user = new SystemServices();
export default user;
