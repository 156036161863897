import {
  Checkbox,
  Stack,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { grey } from "@mui/material/colors";

const TableHeader = ({ data, selectAll, handleSelectAll, indeterminate }) => {
  return (
    <TableHead sx={{ backgroundColor: grey[100] }}>
      <TableRow>
        <TableCell>
          <Stack direction={"row"} alignItems={"center"}>
            {data.length > 0 && (
              <Checkbox
                checked={selectAll}
                onChange={handleSelectAll}
                indeterminate={indeterminate}
              />
            )}
            <Typography variant="subtitle2" fontWeight={600}>
              ASISTENCIA
            </Typography>
          </Stack>
        </TableCell>
        <TableCell>
          <Typography variant="subtitle2" fontWeight={600}>
            NOMBRE
          </Typography>
        </TableCell>
        <TableCell>
          <Typography variant="subtitle2" fontWeight={600}>
            RESPONSABILIDAD
          </Typography>
        </TableCell>
        <TableCell>
          <Typography variant="subtitle2" fontWeight={600}>
            SECCIÓN
          </Typography>
        </TableCell>
      </TableRow>
    </TableHead>
  );
};

export default TableHeader;
