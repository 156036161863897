import { useEffect, useState } from "react";
import {
  DialogTitle,
  IconButton,
  Dialog,
  List,
  ListItem,
  ListItemText,
  Typography,
  Avatar,
  DialogContent,
  Divider,
  ListItemAvatar
} from "@mui/material";
import CancelIcon from '@mui/icons-material/Cancel';
//import { URL_IMAGES } from "@utils/global"

const CoalitionPartiesList = props => {
  const { openModal, setOpenModal, data } = props;
  const [rows, setRows] = useState([]);
  const [mainParty, setMainParty] = useState({
    idPartidoPrincipal: null,
    idPartido: null,
    Partido: "",
    Siglas: "",
    Color: "",
    RutaLogo: "",
    EsCoalicion: null,
    EsIndependiente: null,
    TotalPartidos: null
  });

  useEffect(() => {
    if (data && data.length > 0) {
      const mainParty = data[0].idPartidoPrincipal;
      setRows(data.filter(item => parseInt(item.idPartido) !== parseInt(mainParty)));
      setMainParty(data.find(item => parseInt(item.idPartido) === parseInt(mainParty)));
    } else {
      setRows([]);
    }
  }, [data]);

  return (
    <Dialog
      open={openModal}
      onClose={e => setOpenModal(false)}
      PaperProps={{ sx: { width: "50%" } }}
    >
      <DialogTitle id="alert-dialog-title">
        <span>Partidos que forman la <strong>Coalición</strong></span>
        <IconButton
          aria-label="close"
          onClick={() => setOpenModal(false)}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
          }}>
          <CancelIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Divider sx={{ mt: 1, mb: 1 }} />
        <ListItem>
          <ListItemAvatar>
            <Avatar src={mainParty.RutaLogo} />
          </ListItemAvatar>
          <ListItemText
            primary={
              <Typography variant="div" fontWeight={700} fontSize={15}>
                {mainParty.Partido}
              </Typography>
            }
            sx={{ ml: 2 }}
          />
        </ListItem>
        <Divider sx={{ mt: 1, mb: 1 }} />
        <List>
          {
            rows.map(item => (
              <ListItem>
                <ListItemAvatar>
                  <Avatar src={item.RutaLogo} />
                </ListItemAvatar>
                <ListItemText
                  primary={
                    <Typography variant="div" fontWeight={700} fontSize={15}>
                      {item.Partido}
                    </Typography>
                  }
                  sx={{ ml: 2 }}
                />
              </ListItem>
            ))
          }
        </List>

      </DialogContent>
    </Dialog>
  )
}

export default CoalitionPartiesList;