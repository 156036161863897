import { useEffect, useState } from "react";

// Material UI
import { Box } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2";
import Swal from "sweetalert2";

// Componentes
import BasicList from "@components/Lists/BasicList";
import PerfilListItem from "@components/Roles/PerfilListItem";

// Servicios
import UserRoleService from "@services/RoleServices";

const PerfileRoles = (props) => {
  const { data, toolbar = true, ComponentToolbar, configToolbar, change } = props;

  const [parentRoles, setParentRoles] = useState([]);
  const [totalPA, setTotalPA] = useState(0);
  const [hasMorePA, setHasMorePA] = useState(true);
  const [pagePA, setPagePA] = useState(0);

  const [childrenRoles, setChildrenRoles] = useState([]);
  const [totalCH, setTotalCH] = useState(0);
  const [hasMoreCH, setHasMoreCH] = useState(true);
  const [pageCH, setPageCH] = useState(0);

  const pageSize = 25;

  const configCH = {
    title: "Perfiles que asigna",
    height: 290,
    endMessage: "No hay más registros para mostrar",
  };
  const configPA = {
    title: "Perfiles que lo asignan",
    height: 290,
    endMessage: "No hay más registros para mostrar",
  };

  const fetchData = async (params) => {
    setHasMorePA(true);
    setHasMoreCH(true);
    setTotalPA(0);
    setTotalCH(0);
    setParentRoles([]);
    setChildrenRoles([]);
    try {
      const result = await UserRoleService.getParentRoles(params);
      const result2 = await UserRoleService.getChildrenRoles(params);

      const { results, message, response } = result;

      if (results && result2.results) {
        setParentRoles(response.data);
        setTotalPA(response.total);

        setChildrenRoles(result2.response.data);
        setTotalCH(result2.response.total);

        if (pageSize >= parseInt(result2.response.total)) setHasMoreCH(false);
        if (pageSize >= parseInt(response.total)) setHasMorePA(false);
      } else {
        Swal.fire({
          title: results ? result2.message : message,
          icon: "warning",
        });
        setParentRoles([]);
        setChildrenRoles([]);
        setTotalPA(0);
        setTotalCH(0);
      }
    } catch (errors) {
      Swal.fire({ title: errors.message, icon: "warning" });
      setParentRoles([]);
      setChildrenRoles([]);
      setTotalPA(0);
      setTotalCH(0);
    }
  };

  // Al cambiar de item en la lista
  useEffect(() => {
    if (data.id) {
      const params = {
        page: 0,
        pageSize: pageSize,
        idPerfil: data.id,
      };

      fetchData(params);
    }
  }, [data.id, change]);

  const fetchHasMore = async ({ getAPI, setData, dataP, setHasMore, setPage, page }) => {
    const params = {
      page: page + 1,
      pageSize: pageSize,
      idPerfil: data.id,
    };
    try {
      const result = await getAPI(params);
      const { results, message, response } = result;

      if (results) {
        setData(dataP.concat(response.data));

        // Verifica si no hay mas datos
        const end = (page + 1) * pageSize;
        if (end >= response.total) setHasMore(false);
      } else {
        Swal.fire({
          title: message,
          icon: "warning",
        });
        setHasMore(false);
      }
    } catch (errors) {
      Swal.fire({ title: errors.message, icon: "warning" });
      setHasMore(false);
    } finally {
      setPage(page + 1);
    }
  };

  const fetchHasMoreCH = () =>
    fetchHasMore({
      getAPI: UserRoleService.getChildrenRoles,
      setData: setChildrenRoles,
      dataP: childrenRoles,
      setHasMore: setHasMoreCH,
      setPage: setPageCH,
      page: pageCH,
    });

  const fetchHasMorePA = () =>
    fetchHasMore({
      getAPI: UserRoleService.getParentRoles,
      setData: setParentRoles,
      dataP: parentRoles,
      setHasMore: setHasMorePA,
      setPage: setPagePA,
      page: pagePA,
    });

  return (
    <>
      {toolbar && <ComponentToolbar configToolbar={configToolbar} />}
      <Box>
        <Grid2 container spacing={1}>
          <Grid2 xs={12} sm={6}>
            <BasicList
              configParams={configCH}
              data={childrenRoles}
              fetchHasMore={fetchHasMoreCH}
              totalItems={totalCH}
              itemComponent={PerfilListItem}
              hasMore={hasMoreCH}
              finder={false}
            />
          </Grid2>
          <Grid2 xs={12} sm={6}>
            <BasicList
              configParams={configPA}
              data={parentRoles}
              fetchHasMore={fetchHasMorePA}
              totalItems={totalPA}
              itemComponent={PerfilListItem}
              hasMore={hasMorePA}
              finder={false}
            />
          </Grid2>
        </Grid2>
      </Box>
    </>
  );
};
export default PerfileRoles;
