import React from "react";

//mui
import { Box, Typography } from "@mui/material";
import { blue } from "@mui/material/colors";
import { TraceSpinner } from "react-spinners-kit";

const InfoDrive = ({ info, loadingInfo }) => {
  return (
    <>
      {loadingInfo ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          <TraceSpinner
            frontColor={blue[800]}
            backColor={blue[100]}
            loading={true}
          />
        </Box>
      ) : (
        <Box>
          <Typography variant="subtitle1" fontWeight={800} marginBottom={2}>
            {info.Label ?? ""}
          </Typography>
          <Box marginBottom={2}>
            <Typography variant="body2" fontWeight={600}>
              Total Secciones
            </Typography>
            <Typography
              variant="body2"
              fontWeight={400}
              color={"text.secondary"}
            >
              {info.TotalSecciones ?? ""}
            </Typography>
          </Box>
          <Typography variant="body2" fontWeight={600}>
            Compromisos capturados
          </Typography>
          <Typography
            variant="body2"
            fontWeight={400}
            color={"text.secondary"}
          >
            {info.CompromisosCapturados ?? ""}
          </Typography>
        </Box>
      )}
    </>
  );
};

export default InfoDrive;
