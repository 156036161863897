import React, { useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Divider,
  Icon,
  IconButton,
  InputAdornment,
  LinearProgress,
  Stack,
  TextField,
  Typography,
} from "@mui/material";

import Navbar from "../Navbar";
import Toast from "../Toast";
import Modal from "@components/Modal/Dataview";

import moment from "moment/moment";
import "moment/locale/es";
import { getVars } from "@utils/global";
import { useMobileServices } from "@hooks/MobileDiaD/MobileServices";

const ScreenAperturaVotacion = ({ setTypeScreen }) => {
  const { loading, snackbar, setSnackbar, setAperturaCasillas } =
    useMobileServices();

  const {
    ruta_rg: { Ruta, Descripcion, Casillas },
    user: { ambito_perfil },
  } = getVars("Token");
  const initialHours = {};
  Casillas.forEach((casilla) => {
    initialHours[casilla.idCasilla] = casilla.HoraAperturaCasilla
      ? moment(casilla.HoraAperturaCasilla).format("HH:mm")
      : "";
  });

  const [hour, setHour] = useState(initialHours);

  const [openSchedule, setOpenSchedule] = useState(false);
  const [formValue, setFormValue] = useState({
    hour: moment().format("HH:mm"),
    officials: "",
    takenRow: "",
  });
  const [idCasillaRg, setIdCasillaRg] = useState("");

  const handleChangeFormValue = (event) => {
    setFormValue({ ...formValue, [event.target.name]: event.target.value });
  };

  const handleOpenSchedule = (idCasilla) => {
    setOpenSchedule(true);
    setIdCasillaRg(idCasilla);
  };

  const handleOkButtonClick = async (_idCasilla) => {
    setOpenSchedule(false);

    setHour((prevValues) => ({
      ...prevValues,
      [_idCasilla]: formValue.hour,
    }));

    const results = await setAperturaCasillas({
      casillas: [
        {
          idCasilla: _idCasilla,
          FechaHora: formValue.hour,
          NumFuncionarios: formValue.officials,
          TomadosFila: formValue.takenRow,
        },
      ],
      idUser: ambito_perfil[0].idUsuario,
    });

    if (results) {
      setFormValue({
        hour: moment().format("HH:mm"),
        officials: "",
        takenRow: "",
      });
    }
  };

  return (
    <>
      <Toast
        open={snackbar.show}
        message={snackbar.message}
        setOpen={setSnackbar}
      />
      <Navbar
        isScreen
        screenBack={"rgs"}
        title={"Apertura de Votación RG"}
        setTypeScreen={setTypeScreen}
        color="black"
      />

      <Container sx={{ mt: 4 }}>
        <Typography textAlign={"center"} fontWeight={600} marginBottom={2}>
          Por favor, indica la hora de apertura de la casilla para la votación.
        </Typography>

        <Stack>
          <Box textAlign={"center"}>
            <Typography variant="subtitle1" fontWeight={600}>
              {Ruta}
            </Typography>
            <Typography variant="subtitle1" fontWeight={600}>
              {Descripcion}
            </Typography>

            {loading && <LinearProgress sx={{ width: "100%" }} />}
          </Box>

          <Divider sx={{ mb: 2 }} />
        </Stack>

        <Box height={"80vh"} overflow={"auto"}>
          {Casillas &&
            Casillas.map((casilla) => (
              <Card
                key={casilla.idCasilla}
                className="card-secondary"
                sx={{ mb: 4 }}
              >
                <CardContent>
                  <Stack
                    direction={"row"}
                    marginBottom={2}
                    justifyContent={"space-between"}
                    alignItems={"center"}
                  >
                    <Box>
                      <Typography
                        variant="subtitle1"
                        fontWeight={600}
                        sx={{ color: "primary.main" }}
                      >
                        {casilla.Seccion}
                      </Typography>
                      <Typography
                        variant="subtitle1"
                        fontWeight={600}
                        sx={{ color: "primary.main" }}
                      >
                        {casilla.NombreCasilla}
                      </Typography>
                    </Box>

                    {casilla.HoraAperturaCasilla && (
                      <Typography variant="caption">
                        Ult. Actualización:{" "}
                        {moment(casilla.HoraAperturaCasilla).format(
                          "YYYY-MM-DD"
                        )}
                      </Typography>
                    )}
                  </Stack>

                  <Stack direction={"row"} spacing={2} alignItems={"center"}>
                    <Button
                      fullWidth
                      variant="contained"
                      size="small"
                      startIcon={<Icon>schedule</Icon>}
                      onClick={() => handleOpenSchedule(casilla.idCasilla)}
                    >
                      Apertura de Votación
                    </Button>
                    <TextField
                      fullWidth
                      variant="standard"
                      value={hour[casilla.idCasilla]}
                      inputProps={{ readOnly: true }}
                    />
                  </Stack>
                </CardContent>
              </Card>
            ))}
        </Box>

        <Modal
          open={openSchedule}
          setOpen={setOpenSchedule}
          hasButtonClose={false}
          //fullWidth={false}
          isFullScreen={false}
          maxWidth={"md"}
          borderRadius={12}
        >
          <Box display={"flex"} justifyContent={"center"} marginBottom={4}>
            <Icon
              sx={{
                backgroundColor: "primary.main",
                color: "white",
                fontSize: 40,
                width: 60,
                height: 60,
                borderRadius: "50%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              groups
            </Icon>
          </Box>

          <Stack direction={"column"} marginBottom={2} spacing={2}>
            <TextField
              label="Número de funcionarios"
              name="officials"
              value={formValue.officials}
              onChange={handleChangeFormValue}
            />
            <TextField
              label="Tomados de la fila"
              name="takenRow"
              value={formValue.takenRow}
              onChange={handleChangeFormValue}
            />
            <TextField
              value={formValue.hour}
              name="hour"
              label="Hora de la apertura, formato HH:mm"
              onChange={handleChangeFormValue}
              error={
                !/^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/.test(
                  formValue.hour
                )
              }
              helperText={
                !/^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/.test(
                  formValue.hour
                ) && "Formato HH:mm"
              }
              sx={{ width: "100%" }}
              InputProps={{
                endAdornment: (
                  <InputAdornment
                    position="end"
                    onClick={() => setFormValue({ ...formValue, hour: "" })}
                  >
                    <IconButton>
                      <Icon>close</Icon>
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Stack>

          <Stack direction={"row"} justifyContent={"space-between"}>
            <Button variant="outlined" onClick={() => setOpenSchedule(false)}>
              Cancelar
            </Button>
            <Button
              variant="contained"
              onClick={() => handleOkButtonClick(idCasillaRg)}
              disabled={
                !formValue.officials ||
                !formValue.takenRow ||
                !formValue.hour ||
                !/^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/.test(
                  formValue.hour
                )
              }
            >
              Aceptar
            </Button>
          </Stack>
        </Modal>
      </Container>
    </>
  );
};

export default ScreenAperturaVotacion;
