import { useState, useEffect } from "react";

// Material UI
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import {
  Card,
  CardMedia,
  CardContent,
  Typography,
  Button,
  Box,
  Table,
  TableContainer,
  TableBody,
  TableCell,
  TableRow,
  TableFooter,
  CircularProgress,
} from "@mui/material";

import Swal from "sweetalert2";
import { QRCode } from "react-qrcode-logo";

//utils
import { URL_PUBLICA_FRONT, ClipboardCopy } from "../../utils/global";

// Services
import UserServices from "@services/UserServices";
import CatalogService from "@services/CatalogServices";

const UserInfo = (props) => {
  const { data } = props;
  const [shortUrl, setShortUrl] = useState("");
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [rows, setRows] = useState([
    {
      ResponsabilidadEtiqueta: "Sin Responsabilidad",
      AsignadoPor: "Sian GTO",
      idUsuarioCreo: 451,
      FechaCreo: "2024-01-29 17:02:30",
    },
  ]);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [load,setLoad] = useState(true);
  const [catalog, setCatalog] = useState({});


  useEffect(() => {
    if (data?.id) {
      setShortUrl(`${URL_PUBLICA_FRONT}invitado/${data.idEstado}/${data.UUID}`);
      getResponsibilities(data.id);
      setLoad(true);
      CatalogService.getCatalogs([{
        id: "entidades",
        filtered: [{ id: "id", value: data.idEstado, filter: "=" }],
      }])
      .then((res) => {
        if (res.success && res.results) {
          setCatalog(res.response.catalogs);
        }
      })
      .catch((e) => {
        Swal.fire({ title: e.message, icon: "warning" });
      })
      .finally(() => {
        setLoad(false);
      });
    }
  }, [data]);

  const getResponsibilities = (id) => {
    setLoading(true);

    UserServices.getResponsibilities(id)
      .then((res) => {
        if (res.results) {
          let list_responsibilities = res.response.data;
          setRows(list_responsibilities);
        }
      })
      .catch((error) =>
        Swal.fire({
          title: error,
          icon: "warning",
        })
      )
      .finally(() => setLoading(false));
  };

  return (
    <Grid2 container>
      <Grid2 item lg={4} xs={12} md={12}>
        <Card
          className="card-primary"
          sx={{ textAlign: "center" }}
        >
          {
            loading ? <CircularProgress/> :
            <Grid2 container spacing={2}>
              <Grid2 xs={12}>
            <CardMedia
            component={() => (
              <QRCode
                value={shortUrl}
                fgColor="#34495E"
              />
            )}
            sx={{m:9}}
          />
          </Grid2>
          <Grid2 xs={12}>
          <CardContent>
            <Grid2 container rowSpacing={1} columnSpacing={1}>
              <Grid2 xs={12}>
                <Typography
                  variant="body1"
                  align="center"
                  sx={{
                    marginBottom: "20px",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  Copia el siguiente link para invitar a los familiares y amigos
                  de {data.Nombre} {data.Paterno} para formar parte de su red
                  para defender a {!load ? catalog.entidades[0]?.label : ""}.
                </Typography>
              </Grid2>
              <Grid2 xs={12}>
                <Button
                  variant="text"
                  href={shortUrl}
                  target="_blank"
                  sx={{
                    minWidth: "150px",
                    width: "80%",
                    maxWidth: "300px",
                    fontSize: "0.9rem",
                  }}
                >
                  {shortUrl}
                </Button>
              </Grid2>
              <Grid2 xs={12}>
                <ClipboardCopy
                  copyText={`Hola, te invito a defender a ${!load ? catalog.entidades[0]?.label : ""}, sé parte de la red de ${data.Nombre} ${data.Paterno}  registrándote en ${shortUrl}`}
                />
              </Grid2>
            </Grid2>
          </CardContent>
          </Grid2>
          </Grid2>
          }
        </Card>
      </Grid2>
      <Grid2 item lg={8} xs={12} md={12}>
        <Box sx={{ p: 1 }}>
          {rows.length > 0 && (
            <Typography gutterBottom variant="body2">
              Responsabilidades:
            </Typography>
          )}
          <TableContainer component={Card}>
            <Table>
              <TableBody>
                {(rowsPerPage > 0
                  ? rows.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    )
                  : rows
                ).map((row) => (
                  <TableRow key={row.name}>
                    <TableCell
                      style={{ minWidth: "50px", textAlign: "center" }}
                    >
                      {row.ResponsabilidadEtiqueta}
                    </TableCell>
                    <TableCell style={{ minWidth: "200px" }}>
                      {row.AsignadoPor}
                    </TableCell>
                    <TableCell /* style={{ minWidth: "50px",textAlign:"center" }} */
                    >
                      {row.FechaCreo}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
              <TableFooter></TableFooter>
            </Table>
          </TableContainer>
        </Box>
      </Grid2>
    </Grid2>
  );
};
export default UserInfo;
