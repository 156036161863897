import React from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { TreeView, TreeItem, treeItemClasses } from "@mui/x-tree-view";
import { Icon, IconButton, Tooltip } from "@mui/material";
import { blue } from "@mui/material/colors";
import { isEmptyOrInvalidArray } from "@utils/validations";
import FolderIcon from "@mui/icons-material/SnippetFolder";

const StyledTreeItemRoot = styled(TreeItem)(({ theme }) => ({
    color: theme.palette.text.secondary,
    [`& .${treeItemClasses.content}`]: {
      color: theme.palette.text.secondary,
      borderTopRightRadius: theme.spacing(2),
      borderBottomRightRadius: theme.spacing(2),
      paddingRight: theme.spacing(1),
      fontWeight: theme.typography.fontWeightMedium,
      "&.Mui-expanded": {
        fontWeight: theme.typography.fontWeightRegular,
      },
      "&:hover": {
        backgroundColor: theme.palette.action.hover,
      },
      "&.Mui-focused, &.Mui-selected, &.Mui-selected.Mui-focused": {
        backgroundColor: `var(--tree-view-bg-color, ${theme.palette.action.selected})`,
        color: "var(--tree-view-color)",
      },
      [`& .${treeItemClasses.label}`]: {
        fontWeight: "inherit",
        color: "inherit",
      },
    },
    [`& .${treeItemClasses.group}`]: {
      marginLeft: 0,
      [`& .${treeItemClasses.content}`]: {
        paddingLeft: theme.spacing(2),
      },
    },
  }));

const StyledTreeItem = React.forwardRef(function StyledTreeItem(props, ref) {
  const theme = useTheme();
  const {
    bgColor,
    color,
    labelIcon: LabelIcon,
    buttonDownload,
    labelText,
    colorForDarkMode,
    bgColorForDarkMode,
    handleClick,
    handleDownload,
    children,
    ...other
  } = props;

  const styleProps = {
    "--tree-view-color":
      theme.palette.mode !== "dark" ? color : colorForDarkMode,
    "--tree-view-bg-color":
      theme.palette.mode !== "dark" ? bgColor : bgColorForDarkMode,
  };

  return (
    <StyledTreeItemRoot
      label={
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            p: 0.5,
            pr: 0,
          }}
        >
          <Box component={LabelIcon} color="inherit" sx={{ mr: 1 }} />
          <Typography
            variant="body2"
            sx={{ fontWeight: "inherit", flexGrow: 1 }}
          >
            {labelText}
          </Typography>
          {buttonDownload ? (
            <Tooltip
              title="Descargar todos los documentos"
              placement="right-end"
            >
              <IconButton
                aria-label="download"
                size="small"
                onClick={(e) => {
                  e.stopPropagation();
                  handleDownload(e);
                }}
                color="inherit"
              >
                <Icon>download</Icon>
              </IconButton>
            </Tooltip>
          ) : null}
        </Box>
      }
      onClick={(e) => {
        e.stopPropagation();
        handleClick(e);
      }}
      style={styleProps}
      {...other}
      ref={ref}
    >
      {children}
    </StyledTreeItemRoot>
  );
});

export default function TreeDrive({ data, handleClick, handleDownload }) {
  const renderTree = (node) => {
    return (
      <StyledTreeItem
        key={node.id}
        nodeId={node.id}
        labelText={node.label}
        labelIcon={FolderIcon}
        buttonDownload={node.hasPermissionDownload}
        bgColor={blue[50]}
        handleClick={() => handleClick(node)}
        handleDownload={(e) => handleDownload(e, node)}
      >
        {!isEmptyOrInvalidArray(node.children) ? node.children.map((childNode) => renderTree(childNode)) : null}
      </StyledTreeItem>
    );
  };

  return (
    <TreeView
      defaultCollapseIcon={<Icon color="primary">expand_more</Icon>}
      defaultExpandIcon={<Icon color="lightgray">chevron_right</Icon>}
    >
      {data && data.map((item) => renderTree(item))}
    </TreeView>
  );
}
