import { ListItem, ListItemButton, ListItemText, Divider } from "@mui/material";

const PerfilListItem = ({ item, index }) => {
  return (
    <>
      <ListItem
        disablePadding
        sx={{ backgroundColor: index % 2 ? "white" : "#E4ECFA" }}
      >
        <ListItemButton>
          <ListItemText primary={item.label} />
        </ListItemButton>
      </ListItem>
      <Divider variant="inset" component="li" />
    </>
  );
};

export default PerfilListItem;
