import { Alert, Snackbar } from "@mui/material";
import React from "react";

const Toast = ({ open, message, severity = "success", setOpen }) => {
  return (
    <Snackbar
      open={open}
      onClose={() => setOpen({ show: false })}
      autoHideDuration={3000}
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
    >
      <Alert severity={severity} sx={{ width: "100%" }}>
        {message}
      </Alert>
    </Snackbar>
  );
};

export default Toast;
