export const CATALOGS_OPTIONS = [{ id: "entidades" }, { id: "df" }];
export const CATALOGS_GROUPS = [
  { value: 0, label: "ESTADOS" },
  { value: 1, label: "DF" },
  { value: 2, label: "DF PRIORITARIO" },
];
export const ENTITY = 0;
export const DF = 1;
export const DF_PRIORITY = 2;
export const PRIORITY = 1;
export const DEFAULT_OPTION = {
  value: 9999,
  label: "TODOS",
};
export const COLUMNS_TABLE = [
  { id: "estado", label: "Estado" },
  { id: "meta", label: "Meta", type: "number" },
  { id: "value", label: "Avance", align: "right", type: "number" },
  { id: "pavance", label: "% Avance", align: "right", type: "circular" },
];
