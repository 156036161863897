import PropTypes from "prop-types";

import {
  Box,
  Card,
  CardContent,
  Icon,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { blueGrey } from "@mui/material/colors";

import ic_pdf from "@assets/img/ic_pdf.svg";
import ic_excel from "@assets/img/ic_excel.svg";
import ic_img from "@assets/img/ic_img.svg";
import ic_file from "@assets/img/ic_file.svg";

const TYPE_FORMAT = {
  pdf: ic_pdf,
  image: ic_img,
  png: ic_img,
  jpeg: ic_img,
  jpg: ic_img,
  xlsx: ic_excel,
  xls: ic_excel,
  csv: ic_excel,
  file: ic_file,
};

const Document = ({
  type,
  name,
  isDownload,
  handlePreview,
  handleDownload,
}) => {
  const format = TYPE_FORMAT[type.toLowerCase() || "file"];

  return (
    <Card elevation={0} sx={{ backgroundColor: blueGrey[50], borderRadius: 4 }}>
      <CardContent>
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Stack direction={"row"} spacing={2} alignItems={"center"}>
            <Box
              component={"img"}
              width={24}
              height={24}
              src={format}
              alt="file_icon"
            />
            <Tooltip title={name}>
              <Box
                component={"div"}
                onClick={handlePreview}
                sx={{ cursor: "pointer" }}
              >
                <Typography
                  variant="subtitle2"
                  textOverflow={"ellipsis"}
                  overflow={"hidden"}
                  whiteSpace={"nowrap"}
                  fontWeight={600}
                  width={"calc(400px - 290px)"}
                >
                  {name}
                </Typography>
              </Box>
            </Tooltip>
          </Stack>
          {isDownload && (
            <Tooltip title="Descargar">
              <IconButton size="small" onClick={handleDownload}>
                <Icon>download</Icon>
              </IconButton>
            </Tooltip>
          )}
        </Stack>

        <Box
          padding={2}
          marginTop={2}
          minHeight={180}
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          sx={{ backgroundColor: "white", borderRadius: 2 }}
        >
          <Box
            component={"img"}
            width={100}
            height={100}
            src={format}
            alt="file_icon"
          />
        </Box>
      </CardContent>
    </Card>
  );
};

Document.propTypes = {
  type: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  isDownload: PropTypes.bool.isRequired,
};

Document.defaultProps = {
  type: "pdf",
  name: "Nombre Documento",
  isDownload: true,
};

export default Document;
