import Yup from "@utils/Yupi18n";
import { AMBITO_ESTATAL } from "@data/constants";

export const addUserSchema = Yup.object().shape({
    idCompromisoUnico: Yup.number().optional(),
    idUsuario: Yup.number().optional(),
    idPerfil: Yup.number().required(),
    idAmbitoCargo: Yup.number().required().label('Ámbito de visualización'),
    AmbitoValorCargo: Yup.number().when("idAmbitoCargo", {
        is: (val) => (val && parseInt(val) > AMBITO_ESTATAL),
        then: Yup.number().required(),
        otherwise: Yup.number().optional().nullable(true)
    }).label('Ámbito'),
});