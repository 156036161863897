import Yup from '@utils/Yupi18n';


export const seccionSchema = Yup.number()
  .required()
  .integer()
  .typeError();

export const bingoSchema = Yup.number()
  .integer('Ingrese un número entero')
  .min(1)
  .max(1000, 'El número debe ser menor o igual a 1000');

export const casillaSchema = Yup.number()
  .required();

export const handleKeyPress = (event) => {
  const charCode = event.which ? event.which : event.keyCode;
  if (charCode < 48 || charCode > 57) {
    event.preventDefault();
  }
};

export const handleKeyPress2 = (event) => {
  const inputValue = event.key;
  const regex = /^[a-zA-ZáéíóúüÁÉÍÓÚÜñÑ\s]*$/; // Expresión regular para permitir letras con acentos, la letra "ñ" y espacios

  if (!regex.test(inputValue)) {
    event.preventDefault();
  }
};

export const AddBingoSchema = Yup.object().shape({
  Seccion: Yup.number()
    .required()
    .integer()
    .typeError()
    .label('Sección'),
  idCasilla: Yup.number()
    .required()
    .integer()
    .typeError()
    .label('Casilla'),
  FolioBingo: Yup.number()
    .required()
    .integer('Ingrese un número entero')
    .min(1)
    .max(1000, 'El número debe ser menor o igual a 1000'),
  OrigenCaptura: Yup.string().required()
});

export const AddBingoIdentificador = Yup.object().shape({
  Seccion: Yup.number()
    .required()
    .integer()
    .typeError()
    .label('Sección'),
  idCasilla: Yup.number()
    .required()
    .integer()
    .typeError()
    .label('Casilla'),
  FolioBingo:   Yup.string()
  .test(
    'is-number-or-xx',
    'Ingrese un número entero o "xx"',
    (value) => {
      if (!value) return false;
      if (value === 'xx' || value === "XX") return true; 
      return /^\d+$/.test(value);
    }
  )
  .required('Este campo es obligatorio'),
  OrigenCaptura: Yup.string().required()
});

export const HourMovilizationSchema = Yup.object().shape({
  Seccion: Yup.number()
    .required()
    .integer()
    .label('Sección'),
  Hora: Yup.string()
    .required(),
})

export const AddMovilizacionName = Yup.object().shape({
  Seccion: Yup.number().required().integer().typeError().label("Sección"),
  idCasilla: Yup.number().required().integer().typeError().label("Casilla"),
  Nombre: Yup.string().optional().min(4).max(100).label("Nombre"),
  RangoInicial: Yup.string()
    .optional()
    .matches(/^[a-zA-Z]$/, "El rango inicial debe ser una única letra")
    .label("Rango inicial"),
  RangoFinal: Yup.string()
    .optional()
    .matches(/^[a-zA-Z]$/, "El rango final debe ser una única letra")
    .when('RangoInicial', (RangoInicial, schema) => {
        return RangoInicial ? schema.test(
            "is-greater",
            "El rango final debe ser mayor que el rango inicial",
            function (value) {
                return value > RangoInicial;
            }
        ) : schema;
    })
    .label("Rango final"),
});

export const MovilizacionFilter = Yup.object().shape({
  Seccion: Yup.number().required().integer().typeError().label("Sección"),
  idCasilla: Yup.number().optional().integer().typeError().label("Casilla"),
  OrigenCaptura: Yup.string().required(),
});
