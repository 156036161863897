// Material UI
import { Icon, Stack, Typography, Card, CardContent, Box, Button, CircularProgress } from "@mui/material";
import { Update } from "@mui/icons-material";
import { blue } from "@mui/material/colors";

// Hooks
import useRefresh from "@hooks/useRefresh";
import useWindowDimensions from "@hooks/useWindowDimensions";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { numberWithCommas } from "@utils/Utilities/";

import certificacion from "@services/CertificationServices";

const LastUpdatedCard = (props) => {
  const {
    handleClick = () => { },
    refresh,
    data
  } = props;

  const { width } = useWindowDimensions();

  const { label } = useRefresh({ handleRefresh: handleClick, reset: refresh, localTime: 1000000 });

  const [dataCard, setDataCard] = useState({
    CertificadosHoy: "",
    FechaActualizacion: "",
  })

  const [loading, setLoading] = useState(false)

  const cardsTerritorial = async () => {
    setLoading(true)
    try {
      const res = await certificacion.getCardCaptureToday({ filtered: data.filtered });

      const { results, message, response } = res;
      if (results) {
        setDataCard({
          CertificadosHoy: numberWithCommas(response.data.data.CertificadosHoy),
          FechaActualizacion: response.data.data.FechaActualizacion,
        });
        // setRefresh(false);
      } else {
        throw new Error(message);
      }
    } catch (error) {
      Swal.fire({
        title: error.message,
        icon: "warning",
      });
    } finally {
      setLoading(false)
    }
  };

  useEffect(() => {
    cardsTerritorial()
  }, [data])

  return (
    <Card className="card-primary" sx={{ height: "95%" }}>
      <CardContent
        sx={{
          height: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box sx={{ textAlign: 'center' }}>
          <Box sx={{ display: 'flex', mt: .5, width: '100%', justifyContent: 'left' }}>
            {dataCard.CertificadosHoy !== "" ?
              <Typography fontWeight={445} fontSize={width > 1440 ? width * 0.011 : "18px"} letterSpacing={-.3} color={'rgb(153,153,153)'} textAlign={"right"}>
                PERSONAS VISITADAS HOY
              </Typography>
              : <></>
            }
          </Box>
          {
            loading ?
              <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <CircularProgress />
              </Box> :
              <Typography sx={{ mb: 4 }} fontSize={44} fontWeight={600} textAlign={"center"}>
                {dataCard.CertificadosHoy !== "" ? dataCard.CertificadosHoy : ""}
              </Typography>
          }

          <Stack direction={"row"} spacing={1} justifyContent={"center"} alignItems={"center"}>
            <Icon sx={{ color: blue[900] }}>update</Icon>
            <Typography variant="subtitle2" fontWeight={600} textAlign={"center"}>
              ÚLTIMO CORTE
            </Typography>
          </Stack>

          <Typography variant="body2" textAlign={"center"}>
            {dataCard.FechaActualizacion !== "" ? dataCard.FechaActualizacion : "Sin información"}
          </Typography>

          <Typography variant="body2" sx={{ my: 2, fontWeight: "bold" }}>
            {`Próxima actualización en ${label}`}
          </Typography>
          <Button
            onClick={handleClick}
            variant="outlined"
            endIcon={<Update />}
            sx={{
              color: blue[900],
              borderColor: blue[900],
              "&:hover": {
                borderColor: blue[700],
              },
            }}
          >
            Actualizar
          </Button>
        </Box>
      </CardContent>
    </Card>
  );
};

export default LastUpdatedCard;
