import React, { StrictMode, useEffect } from "react";
import { createRoot } from "react-dom/client";
import reportWebVitals from "@src/reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import "@src/index.css";
import Router from '@src/router/Router'
import '/node_modules/leaflet-geosearch/dist/geosearch.css';
import { CookiesProvider, useCookies } from "react-cookie";
import { encryptVars } from "@utils/global";

const Root = () => {
  const [cookies, setCookie] = useCookies(["sid"]);

  useEffect(() => {
    if (!cookies.sid) {
      setCookie("sid", encryptVars('c27'), { path: "/" });
    }
    //eslint-disable-next-line
  }, []);

  return (
    <StrictMode>
      <BrowserRouter>
        <CookiesProvider>
          <Router />
        </CookiesProvider>
      </BrowserRouter>
    </StrictMode>
  );
};

const container = document.getElementById("root");
const root = createRoot(container);

root.render(<Root />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
