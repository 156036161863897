import { Alert, Divider, Icon } from "@mui/material";

const NavigatorUserAgent = () => {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;

  const isAndroid = () => {
    return userAgent.match(/Android/i);
  };

  const isIOS = () => {
    return /iPad|iPhone|iPod/.test(userAgent) && !window.MSStream;
  };

  const handleDownloadApp = () => {
    if (isAndroid()) {
      window.location.href =
        "https://play.google.com/store/apps/details?id=com.espormexico.app";
    } else if (isIOS()) {
      window.location.href =
        "https://apps.apple.com/mx/app/espormexico/id6477065888";
    }
  };

  return (
    <>
      {isAndroid() || isIOS() ? (
        <Alert
          icon={<Icon>{isAndroid() ? "android" : "apple"}</Icon>}
          severity="info"
          onClick={handleDownloadApp}
        >
          ¡Puedes descargar la aplicación espormexico para{" "}
          {isAndroid() ? "Android" : "iOS"} presionando en este enlace!.
        </Alert>
      ) : null}
    </>
  );
};

export default NavigatorUserAgent;
