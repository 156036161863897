import { useState, useEffect } from "react";

import Map from "@components/Numeralia/Nacional/Map";
import BarChart from "@components/Numeralia/Nacional/BarChart";

import numeralia from "@services/NumeraliaServices";
import national from "@services/MapServices/NationalServices";
import Swal from "sweetalert2";
import { setVars } from "@utils/global";

const LnomNational = ({ title, entity, dataFiltered, nameGroup }) => {
  const [dataLnom, setDataLnom] = useState([]);
  const [idCategories, setIdCategories] = useState("");
  const [polygons, setPolygons] = useState({
    type: "FeatureCollection",
    features: [],
  });
  const [loadedLayer, setLoadedLayer] = useState(false);
  const [polygonsBlocks, setPolygonsBlocks] = useState({
    type: "FeatureCollection",
    features: [],
  });

  const getNumeraliaLnomNacional = async (body) => {
    setPolygons(null);
    setPolygonsBlocks(null);
    try {
      const result = await numeralia.getNumeraliaLnomNacional(body);
      const { results, response, message } = result;
      if (!results) {
        throw new Error(message);
      }
      setDataLnom(response.data);
      loadMainLayer(response.data);
    } catch (error) {
      Swal.fire({
        title: error.message,
        icon: "warning",
      });
    }
  };

  const loadMainLayer = async (dataLnom) => {
    setPolygons(null);
    setPolygonsBlocks(null);
    try {
      const params = {
        color: 0,
        page: 0,
        pageSize: 10,
        filtered: [],
        shp: "entidad",
        idEntidad: entity,
      };

      const result = await national.getShapes(params);

      const { results, response, message } = result;

      if (results) {
        setPolygons(response.data);
      } else {
        Swal.fire({
          title: message,
          icon: "warning",
        });
      }
    } catch (error) {
      Swal.fire({
        title: error.message,
        icon: "warning",
      });
    } finally {
      setLoadedLayer(true);
    }
  };

  const getEmptyMap = () => {
    setPolygons({
      type: "FeatureCollection",
      features: [],
    });
  };

  useEffect(() => {
    getEmptyMap();
    setVars("filterSavedMap", {
      idEntidad: "",
    });
    //loadMainLayer();
    // eslint-disable-next-line
  }, [entity, dataFiltered]);

  useEffect(() => {
    const body = {
      idAmbitoAgrupador: dataFiltered.idAmbitoAgrupador,
      filtered: dataFiltered.filtered,
      idEstado: dataFiltered.idEstado,
    };
    dataFiltered.idEstado === null && delete body.idEstado;

    getNumeraliaLnomNacional(body);
  }, [dataFiltered]);

  useEffect(() => {
    const idCategories = {
      "VISTA ESTATAL": "Entidad",
      "Distrito Federal": "DF",
      "Distrito Local": "DL",
    };

    setIdCategories(idCategories[nameGroup]);
  }, [nameGroup]);

  return (
    <>
      {idCategories !== "DF" && idCategories !== "DL" ? (
        <Map
          title={title}
          entity={entity}
          lnom={dataLnom}
          dataFiltered={dataFiltered}
          polygons={polygons}
          polygonsBlocks={polygonsBlocks}
          setPolygons={setPolygons}
        />
      ) : (
        <BarChart
          dataFiltered={dataFiltered}
          title={`TIPO DE SECCIÓN ${nameGroup.toUpperCase()}`}
          idCategories={idCategories}
          idSeries={"Lista_Nominal"}
          API={numeralia.getNumeraliaLnomNacional}
        />
      )}
    </>
  );
};

export default LnomNational;
