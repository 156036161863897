import Yup from "@utils/Yupi18n";
import {
    LUN,
    MAR,
    MIE,
    JUE,
    VIE,
    SAB,
    DOM,
    ACCESO_WEB,
    ACCESO_APP,
    time_regex
} from "@data/constants/System";
import moment from "moment/moment";

export const DayScheduleSchema = Yup.object({
    idDia: Yup.number().required().oneOf([LUN, MAR, MIE, JUE, VIE, SAB, DOM]),
    AccesoTotal: Yup.number().required().oneOf([0, 1]),
    HoraInicio: Yup.string().when('AccesoTotal', {
        is: 0,
        then: Yup.string().required().test("validacion-hora", "Ingrese una hora válida y menor a la Hora de Cierre", function (value) {
            const { HoraCierre } = this.parent;
            if (time_regex.test(value)) {
                return moment(value, "HH:mm").isBefore(moment(HoraCierre, "HH:mm"));
            }
            return false;
        }).notOneOf([Yup.ref('HoraCierre')], 'Las horas de inicio y fin no pueden ser iguales'),
    }).label('Hora de Inicio'),
    HoraCierre: Yup.string().when('AccesoTotal', {
        is: 0,
        then: Yup.string().required()
            .test("validacion-hora", "Ingrese una hora válida y mayor a la Hora de Inicio", function (value) {
                const { HoraInicio } = this.parent;
                if (time_regex.test(value)) {
                    return moment(value, "HH:mm").isAfter(moment(HoraInicio, "HH:mm"));
                }
                return false;
            }),
    }).label('Hora de Cierre')
});

export const WeekScheduleSchema = Yup.object({
    idTipoAcceso: Yup.number().required().oneOf([ACCESO_WEB, ACCESO_APP]),
    idUsuario: Yup.number().optional().nullable(),
    Horarios: Yup.array().of(Yup.object(
        {
            idDia: Yup.number().required().oneOf([LUN, MAR, MIE, JUE, VIE, SAB, DOM]),
            AccesoTotal: Yup.number().required().oneOf([0, 1]),
            HoraInicio: Yup.string().when('AccesoTotal', {
                is: 0,
                then: Yup.string().required().test("validacion-hora", "Ingrese una hora válida y menor a la Hora de Cierre", function (value) {
                    const { HoraCierre } = this.parent;
                    if (time_regex.test(value)) {
                        return moment(value, "HH:mm").isBefore(moment(HoraCierre, "HH:mm"));
                    }
                    return false;
                }).notOneOf([Yup.ref('HoraCierre')], 'Las horas de inicio y fin no pueden ser iguales'),
            }).label('Hora de Inicio'),
            HoraCierre: Yup.string().when('AccesoTotal', {
                is: 0,
                then: Yup.string().required()
                    .test("validacion-hora", "Ingrese una hora válida y mayor a la Hora de Inicio", function (value) {
                        const { HoraInicio } = this.parent;
                        if (time_regex.test(value)) {
                            return moment(value, "HH:mm").isAfter(moment(HoraInicio, "HH:mm"));
                        }
                        return false;
                    }),
            }).label('Hora de Cierre')
        }
    )).required().length(7)
    // .unique('idDia')
})