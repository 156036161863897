import PropTypes from "prop-types";

// Material UI
import { Card, Box, Typography, Tooltip } from "@mui/material";

// Componentes
import CircularProgressPorcent from "@components/LinearProgress/CircularProgressPorcent";

// Utils
import { numberWithCommas } from "@utils/Utilities";

const CardProgress = ({ progress, title, total, tooltip, align = "left", sx, sxText }) => {
  return tooltip ? (
    <Tooltip title={tooltip.title || "Titulo"} placement={tooltip.placement || "bottom"} disableInteractive arrow>
      <Card
        elevation={4}
        sx={{
          p: 1,
          borderRadius: 2,
          textAlign: "center",
          overflow: "visible",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          gap: 2,
          ...sx,
        }}
      >
        {align === "right" && <CircularProgressPorcent size={60} value={progress} fontSize={17} fontWeight={700} fontColor />}
        <Box sx={{ ...sxText }}>
          <Typography
            variant="body2"
            align={align}
            fontSize={17}
            fontWeight={600}
            color="gray"
            style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
          >
            {title}
          </Typography>

          <Typography variant="h3" align={align} fontSize={28}>
            {numberWithCommas(total)}
          </Typography>
        </Box>
        {align === "left" && <CircularProgressPorcent size={60} value={progress} fontSize={17} fontWeight={700} fontColor />}
      </Card>
    </Tooltip>
  ) : (
    <Card
      elevation={4}
      sx={{
        p: 1,
        borderRadius: 2,
        textAlign: "center",
        overflow: "visible",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        gap: 2,
        ...sx,
      }}
    >
      <Box sx={{ ...sxText }}>
        <Typography
          variant="body2"
          align="left"
          fontSize={17}
          fontWeight={600}
          color="gray"
          style={{
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
          }}
        >
          {title}
        </Typography>

        <Typography variant="h3" align="left" fontSize={28}>
          {numberWithCommas(total)}
        </Typography>
      </Box>

      <CircularProgressPorcent size={70} value={progress} fontSize={17} fontWeight={700} fontColor />
    </Card>
  );
};

CardProgress.propTypes = {
  progress: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  title: PropTypes.string.isRequired,
  total: PropTypes.number.isRequired,
  tooltip: PropTypes.shape({
    title: PropTypes.string.isRequired,
    placement: PropTypes.string,
  }),
  sx: PropTypes.object,
  sxText: PropTypes.object,
};

CardProgress.defaultProps = {
  progress: "0",
  title: "Titulo",
  total: 0,
  tooltip: null,
  sx: {},
  sxText: {},
};

export default CardProgress;
