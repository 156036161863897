import React, { useEffect, useState } from "react";
import {
  Modal,
  Box,
  IconButton,
  Icon,
  Card,
  CardContent,
  Button,
  Stack,

  Grid,
} from "@mui/material";
import {
  isEmptyOrInvalidString
} from "@utils/validations";
import Swal from "sweetalert2";
import CloseIcon from "@mui/icons-material/Close";
import FrenteAmplioServices from "@services/FrenteAmplioServices";
import ListItemFA from "./itemList";
import BasicList from "@components/Lists/BasicList";
import SelectSearch from "../../components/Selects/SelectSearch";

const ModalSearch = ({
  open,
  setOpen,
  onChange,
  catalogs,
  formik,
  load,
  handleMunicipio
}) => {
  const defaultOption = [{ value: 0, label: "TODOS" }];
    const config = {
        title: "Listado",
        icon: "people",
        height: 600 * 0.5,
        endMessage: "No hay más registros para mostrar",
      };
   const [data, setData] = useState([]);
   const [total,setTotal] = useState(0);
   const [isLoading, setIsLoading] = useState(false);
   const [params, setParams] = useState(formik.values.idMunicipio.value > 0 ?{
    page: 0,
    pageSize: 20,
    filtered: [
      {
        id: "idMunicipio",
        value: formik.values.idMunicipio.value,
        filter: "=",
      },
    ],
  }:{
    page: 0,
    pageSize: 20,
    filtered: [],
  });
   const [hasMore,setHasMore] = useState(true);

   useEffect(() => {
    setData([]);
    if (catalogs.municipios.length === 1) {
      setParams({
        page: 0,
        pageSize: 20,
        filtered: [
          {
            id: "idMunicipio",
            value: catalogs.municipios[0].value,
            filter: "=",
          },
        ],
      });
    } else {
      if (formik.values.idMunicipio.value > 0) {
        let p = {
          page: 0,
          pageSize: 20,
          filtered: [
            {
              id: "idMunicipio",
              value: formik.values.idMunicipio.value,
              filter: "=",
            },
          ],
        };
        setParams(p);
        ListUse(p);
      }else{
        let p = {
          page: 0,
          pageSize: 20,
          filtered: [],};
        setParams(p);
        ListUse(p);
      }
    }
  }, [formik.values.idMunicipio, open]);
  /*  useEffect(()=>{},[open]); */

  useEffect(()=>{
    ListUse(params);
  },[params]);

  const ListUse = (info) => {
    setIsLoading(true);
    FrenteAmplioServices.searchCompromisos(info)
    .then((res) => {
        if (res.results) {
            setData(res.response.data);
            setTotal(res.response.total);
            let end = (info.page + 1) * info.pageSize;
            if (end >= res.response.total) {
              setHasMore(false);
            }
        } else {
          Swal.fire({ title: res.message, icon: "warning" });
        }
      })
      .catch((e) => {
        Swal.fire({ title: e.message, icon: "warning" });
        setTotal(0)
      })
      .finally(() => {setIsLoading(false)});
  };

  const fetchHasMore = () => {
    setIsLoading(true);

    FrenteAmplioServices.searchCompromisos(params)
    .then((res) => {
        if (res.results) {
            setData(data.concat(res.response.data));
            setTotal(res.response.total);
          let end = (params.page + 1) * params.pageSize;
          if (end >= res.response.total) {
            setHasMore(false);
          }else{
            setParams({...params,page:params.page + 1});
          }
        } else {
          Swal.fire({ title: res.message, icon: "warning" });
          setHasMore(false);
        }
      })
      .catch((e) => {
        setHasMore(false);
        setTotal(0)
        Swal.fire({ title: e.message, icon: "warning" });
      })
      .finally(() => setIsLoading(false));

  };

  const handleSearch = (event) =>{
    setData([]);
    setTotal(0);
    if(event.length > 0){
        setParams({...params,page:0,filtered:[{id:"CONCAT(Nombre,Paterno,Materno,Seccion)",filter:"like",value:`%${event.toUpperCase().replace(/ /g, "%")}%`}]})
    }else{
        setParams({...params,page:0,filtered:[]})
    }


  };

  const handleClickItem = (event) =>{
  
    let info = {
        idMunicipio:{value:event.idMunicipio,label:event.Municipio},
        Hoja:0,
        Bingo:event.FolioBingo
    }
    onChange(info)
  };

  const hadleMun = (e) =>{
    setData([]);
    setTotal(0);
    handleMunicipio(e)
  };

  return (
    <>
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            width: "90%", 
            maxWidth: "600px",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            //overflowY: "auto",
            bgcolor: "white",
            borderRadius: "8px",
            boxShadow: 24,
            p: 2,
          }}
        >
          <IconButton
            aria-label="cancel"
            onClick={() => setOpen(false)}
            sx={{ position: "fixed", left: "3%", top: "2%", color: "#18409A" }}
          >
            <Icon>cancel</Icon>
          </IconButton>
          <Card elevation={0}>
            <CardContent>
              <br/>
              <Grid container>
              <Grid item sm={12} xs={12} md={12} lg={12}>
                <SelectSearch
                  multiple={false}
                  placeholder="Municipio *"
                  name="idMunicipio"
                  closeMenuOnSelect={true}
                  value={
                    Object.keys(catalogs).length > 0 &&
                    catalogs.municipios.length === 1
                      ? catalogs.municipios[0]
                      : formik.values.idMunicipio
                  }
                  onChange={(e) =>hadleMun(e)}
                  options={catalogs.municipios}
                  disabled={
                    Object.keys(catalogs).length > 0 &&
                    catalogs.municipios.length === 1
                      ? true
                      : false
                  }
                  isLoading={load}
                  error={
                    formik.touched.idMunicipio &&
                    !isEmptyOrInvalidString(formik.errors.idMunicipio)
                  }
                  helperText={
                    formik.touched.idMunicipio &&
                    formik.errors.idMunicipio &&
                    formik.errors.idMunicipio.values
                  }
                />
              </Grid>
              </Grid>
              <BasicList
                configParams={config}
                data={data}
                fetchHasMore={fetchHasMore}
                totalItems={total}
                itemComponent={ListItemFA}
                hasMore={hasMore}
                handleClickItem={handleClickItem}
                finder={true}
                search={handleSearch}
                
              />
            </CardContent>
          </Card>
          <Stack
            direction="column"
            justifyContent="center"
            alignItems="flex-end"
            spacing={0.5}
          >
            <Button
              size="small"
              variant="contained"
              color="error"
              sx={{ borderRadius: "10px" }}
              onClick={() => setOpen(false)}
              endIcon={<CloseIcon />}
            >
              Cerrar
            </Button>
          </Stack>
        </Box>
      </Modal>
    </>
  );
};

export default ModalSearch;
