import { PrivilegedRoute } from "@middlewares";

import Attendance from "@pages/Attendance/Attendance";
import AttendanceReport from "@pages/Attendance/AttendanceReport";

const SimulacrumRoutes = [
    {
        path: '/pase-lista',
        element: <PrivilegedRoute><Attendance /></PrivilegedRoute>
    },
    {
        path: '/simulacro-asistencia',
        element: <PrivilegedRoute><AttendanceReport /></PrivilegedRoute>
    }
];

export default SimulacrumRoutes;