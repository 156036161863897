import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import { Box, Button, Card, Stack, Typography } from "@mui/material";
import { blue } from "@mui/material/colors";

import AdminLayout from "@components/MainPage/AdminLayout";
import LoadingForm from "@components/LinearProgress/LoadingForm";
import { OPT_REGISTERS, EXPECTED_ROUTES } from "@data/constants/Compromisos";
import { getVars } from "@utils/global";

const ItemCard = ({ icon, selected, handleSelected }) => (
  <Box
    className="card-primary"
    component={Card}
    height={"176px"}
    width={"176px"}
    borderRadius={4}
    display="flex"
    alignItems="center"
    justifyContent="center"
    border={selected && `solid 2px ${blue[900]}`}
    onClick={handleSelected}
    sx={{ cursor: "pointer" }}
  >
    <Box component={"img"} src={icon} width={100} />
  </Box>
);

const CompromisosTypeRegister = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { menus } = getVars("Token");

  const [selected, setSelected] = useState(0);
  const [isLoadigForm, setIsLoadingForm] = useState(true);
  const [openLinearProgress, setOpenLinearProgress] = useState(true);
  const [isSuccessFormSave] = useState(false);

  const handleSelected = (item) => {
    const { id } = item;
    setSelected(id);
  };

  const handleTypeRegister = () => {
    const routeMapping = {
      1: "/compromisos-registro",
      2: "/compromisos-ext-registro",
    };

    const selectedRoute = routeMapping[selected];
    if (selectedRoute) {
      navigate(selectedRoute);
    }
  };

  useEffect(() => {
    const _menus = menus
      .map((item) => item.submenus)
      .flat()
      .filter((item) => EXPECTED_ROUTES.includes(item.Ruta));

    if (_menus.length < 1) {
      navigate("*");
    } else if (_menus.length < 2) {
      navigate(_menus[0].Ruta);
    } else {
      setIsLoadingForm(false);
      setOpenLinearProgress(false);
    }
    // eslint-disable-next-line
  }, []);

  return (
    <AdminLayout>
      <LoadingForm
        loadinMessage={"Enviando"}
        successMessage="¡Consultado con éxito!"
        isLoading={isLoadigForm}
        success={isSuccessFormSave}
        isOpen={openLinearProgress}
        setIsOpen={() => setOpenLinearProgress(!openLinearProgress)}
      />
      {!isLoadigForm && (
        <Box
          display={"flex"}
          flexDirection={"column"}
          alignItems="center"
          justifyContent="center"
          height={"80vh"}
        >
          <Box>
            <Typography
              fontWeight={600}
              fontSize={24}
              sx={{ color: blue[900] }}
              marginTop={6}
              textAlign={"center"}
            >
              CAPTURA DE COMPROMISOS
            </Typography>
            <Typography
              variant="paragraph"
              textAlign={"center"}
              marginBottom={4}
              component={"p"}
            >
              Selecciona el tipo de compromisos que deseas capturar.
            </Typography>
            <Box
              display={"flex"}
              gap={5}
              sx={{ flexDirection: { xs: "column", sm: "row", md: "row" } }}
            >
              {OPT_REGISTERS.map((item, idx) => {
                return (
                  <Box
                    key={idx}
                    display={"flex"}
                    flexDirection={"column"}
                    alignItems={"center"}
                  >
                    <ItemCard
                      icon={item.icon}
                      selected={selected === item.id}
                      handleSelected={() => handleSelected(item)}
                    />
                    <Typography marginTop={2} fontWeight={600} fontSize={16}>
                      {item.title}
                    </Typography>
                  </Box>
                );
              })}
            </Box>
            <Stack
              direction="row"
              justifyContent={"end"}
              marginTop={6}
              marginBottom={1}
            >
              <Button
                variant="contained"
                color="primaryDark"
                onClick={handleTypeRegister}
              >
                Continuar
              </Button>
            </Stack>
          </Box>
        </Box>
      )}
    </AdminLayout>
  );
};

export default CompromisosTypeRegister;
