//MUI
import {
  Avatar,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from "@mui/material";

//Components
import ContactsStack from "@components/ContactsStack/ContactsStack";

const ListRepresentatives = ({ representative }) => {
  return (
    <List>
      {representative.NombreRCP1?.length > 0 && (
        <ListItem alignItems="flex-start">
          <ListItemAvatar>
            <Avatar sx={{ width: 35, height: 35 }} />
          </ListItemAvatar>
          <ListItemText
            primary={
              <Typography variant="body1" noWrap fontWeight={600} fontSize={14}>
                {representative?.NombreRCP1}
              </Typography>
            }
            secondary={
              <ContactsStack
                whatsapp={representative?.CelularRCP1}
                nombre={representative?.NombreRCP1}
                telefono={representative?.TelRCP1}
                telefono_mensaje={representative?.TelMensajesRCP1}
              />
            }
          />
        </ListItem>
      )}

      {representative.NombreRCP2?.length > 0 && (
        <ListItem alignItems="flex-start">
          <ListItemAvatar>
            <Avatar sx={{ width: 35, height: 35 }} />
          </ListItemAvatar>
          <ListItemText
            primary={
              <Typography variant="body1" noWrap fontWeight={600} fontSize={14}>
                {representative?.NombreRCP2}
              </Typography>
            }
            secondary={
              <ContactsStack
                whatsapp={representative?.CelularRCP2}
                nombre={representative?.NombreRCP2}
                telefono={representative?.TelRCP2}
                telefono_mensaje={representative?.TelMensajesRCP2}
              />
            }
          />
        </ListItem>
      )}

      {representative.NombreRCS1?.length > 0 && (
        <ListItem alignItems="flex-start">
          <ListItemAvatar>
            <Avatar sx={{ width: 35, height: 35 }} />
          </ListItemAvatar>
          <ListItemText
            primary={
              <Typography variant="body1" noWrap fontWeight={600} fontSize={14}>
                {representative?.NombreRCS1}
              </Typography>
            }
            secondary={
              <ContactsStack
                whatsapp={representative?.CelularRCS1}
                nombre={representative?.NombreRCS1}
                telefono={representative?.TelRCS1}
                telefono_mensaje={representative?.TelMensajesRCS1}
              />
            }
          />
        </ListItem>
      )}

      {representative.NombreRCS2?.length > 0 && (
        <ListItem alignItems="flex-start">
          <ListItemAvatar>
            <Avatar sx={{ width: 35, height: 35 }} />
          </ListItemAvatar>
          <ListItemText
            primary={
              <Typography variant="body1" noWrap fontWeight={600} fontSize={14}>
                {representative?.NombreRCS2}
              </Typography>
            }
            secondary={
              <ContactsStack
                whatsapp={representative?.CelularRCS2}
                nombre={representative?.NombreRCS2}
                telefono={representative?.TelRCS2}
                telefono_mensaje={representative?.TelMensajesRCS2}
              />
            }
          />
        </ListItem>
      )}
    </List>
  );
};

export default ListRepresentatives;
