import { useEffect, useState } from "react";
import useWindowDimensions from "@hooks/useWindowDimensions";

import { CircularProgress, Card, CardContent, Typography } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2";

import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import Swal from "sweetalert2";

const BarGraph = ({ dataFiltered, title, type, API }) => {
  const { height, width } = useWindowDimensions();
  const categorias = ["Entidad", "DF", "DL"];

  const [currentDate] = useState(new Date().toLocaleString());
  const [loading, setIsLoading] = useState(false);
  const [data, setData] = useState({
    categories: [],
    seriesData: [],
  });

  const options = {
    chart: {
      type: "column",
      height: width > 1440 ? height * 0.4 : "auto",
    },
    title: {
      text: ``,
      align: "right",
      style: {
        color: "#999",
        fontSize: width > 1440 ? width * 0.012 : "18px",
      },
    },
    xAxis: {
      categories: data.categories,
      labels: {
        style: {
          fontSize: width > 1400 ? width * 0.01 : "14px",
        },
      },
    },
    yAxis: {
      title: "",
      labels: {
        style: {
          fontSize: width > 1400 ? width * 0.01 : "14px",
        },
      },
    },
    credits: {
      enabled: false,
    },
    series: [
      {
        name: "Prioridad 1",
        data: type === "total" ? data.seriesData.p1 : data.seriesData.LN1,
        style: {
          fontSize: width > 1440 ? width * 0.0085 : "12px",
        },
      },
      {
        name: "Prioridad 2",
        data: type === "total" ? data.seriesData.p2 : data.seriesData.LN2,
        color: "rgba(39, 72, 160)",
        style: {
          fontSize: width > 1440 ? width * 0.0085 : "12px",
        },
      },
      {
        name: "Prioridad 3",
        data: type === "total" ? data.seriesData.p3 : data.seriesData.LN3,
        color: "rgba(83, 208, 236)",
        style: {
          fontSize: width > 1440 ? width * 0.0085 : "12px",
        },
      },
    ],
  };

  const getNumeraliaPrioridadMznTotalNacional = async (body) => {
    setIsLoading(true);
    try {
      const result = await API(body);
      const { results, response, message, success } = result;
      if (!results && !success) {
        setData({
          ...data,
          categories: [],
          seriesData: [],
        });
      }

      if (!results && success) {
        throw new Error(message);
      }

      const newData = response.data;
      const newCategories = newData.map((item) => {
        const category = categorias.find((cat) => item[cat]);
        return category ? item[category] : null;
      });

      let newSeriesData;
      if (type === "total") {
        newSeriesData = {
          p1: newData.map((item) => parseInt(item.P1)),
          p2: newData.map((item) => parseInt(item.P2)),
          p3: newData.map((item) => parseInt(item.P3)),
        };
      } else {
        newSeriesData = {
          LN1: newData.map((item) => parseInt(item.LN1)),
          LN2: newData.map((item) => parseInt(item.LN2)),
          LN3: newData.map((item) => parseInt(item.LN3)),
        };
      }
      setData({
        ...data,
        categories: newCategories,
        seriesData: newSeriesData,
      });
    } catch (error) {
      Swal.fire({
        title: error.message,
        icon: "warning",
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const body = {
      idAmbitoAgrupador: dataFiltered.idAmbitoAgrupador,
      filtered: dataFiltered.filtered,
      idEstado: dataFiltered.idEstado,
    };
    dataFiltered.idEstado === null && delete body.idEstado;

    getNumeraliaPrioridadMznTotalNacional(body);
  }, [dataFiltered]);

  return (
    <Card className="card-primary">
      <CardContent>
        <div>
          <Typography variant="subtitle2" fontWeight={600} textAlign={"end"}>
            {title}
          </Typography>
          <Typography variant="body2" textAlign={"end"} color={"GrayText"}>
            {currentDate}
          </Typography>
        </div>
        {loading ? (
          <Grid2
            container
            xs={12}
            height={width > 1440 ? height * 0.4 : 400}
            alignItems="center"
            justifyContent="center"
          >
            <CircularProgress size={50} />
          </Grid2>
        ) : (
          <HighchartsReact highcharts={Highcharts} options={options} />
        )}
      </CardContent>
    </Card>
  );
};

export default BarGraph;
