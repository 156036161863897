import { isString } from "lodash";
import { useDropzone } from "react-dropzone";

// material
import { Avatar, Box, Stack, Typography, styled } from "@mui/material";
import { Person } from "@mui/icons-material";

// ----------------------------------------------------------------------
const DropZoneStyle = styled("div")(({ theme }) => ({
    outline: "none",
    display: "flex",
    overflow: "hidden",
    textAlign: "center",
    position: "relative",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "center",
    padding: theme.spacing(5, 0),
    borderRadius: 6,
    transition: theme.transitions.create("padding"),
    backgroundColor: theme.palette.grey[50],
    border: `1px dashed ${theme.palette.grey[400]}`,
    "&:hover": {
        opacity: 0.72,
        cursor: "pointer",
    },
    [theme.breakpoints.up("md")]: { textAlign: "left", flexDirection: "row" },
}));

// ----------------------------------------------------------------------

const UploadProfilePicture = ({ file, setFile, sx, ...other }) => {

    const onDrop = (acceptedFiles) => {
        const file = acceptedFiles[0];
        if (file) {
            setFile(Object.assign(file, {
                preview: URL.createObjectURL(file)
            }))
        }
    };

    const { getRootProps, getInputProps, isDragActive, isDragReject } =
        useDropzone({
            multiple: false,
            onDrop,
            ...other,
        });

    return (
        <Box sx={{ width: "100%", ...sx }}>
            <Stack spacing={2} justifyContent={'center'} alignItems={'center'}>
                <DropZoneStyle
                    {...getRootProps()}
                    sx={{
                        ...(isDragActive && { opacity: 0.72 }),
                        ...(isDragReject && {
                            color: "error.main",
                            borderColor: "error.light",
                            bgcolor: "error.lighter",
                        }),
                        ...(file && { padding: "12% 0" }), //12% padding: "12% 0"
                        width: 200,
                        height: 200,
                        borderRadius: '50%'
                    }}
                >
                    <input {...getInputProps()} />

                    {file ? (
                        <img
                            alt="Logotipo"
                            src={isString(file) ? file : file.preview}
                            width={'100%'}
                        />

                    ) :
                        <Avatar
                            sx={{ width: 200, height: 200 }}>
                            <Person sx={{ fontSize: 200 }} />
                        </Avatar>
                    }
                </DropZoneStyle>

                <Typography gutterBottom variant="h6">
                    Arrastre o seleccione la imagen
                </Typography>
            </Stack>
        </Box>
    );
};

export default UploadProfilePicture;
