const SocialAddInterface = {
    "NombreSocial":"",
    "Alias":""
}

const SocialEditInterface = {
    "id":null,
    "NombreSocial":"",
    "Alias":""
}

export {
    SocialAddInterface,
    SocialEditInterface
}