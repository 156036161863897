import { useEffect, useState, useRef } from "react";

// Charts
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

// Material UI
import { Card, CardContent, Box, CircularProgress } from "@mui/material";
import { numberWithCommas } from "@utils/Utilities/";
import LastUpdatedCard from "@components/Movilizadores/LastUpdatedCard";

// Hooks
import useWindowDimensions from "@hooks/useWindowDimensions";

const AccumulatedCertifiedPeople = (props) => {
  const { openDrawer, data, loading, handleRefresh, refresh } = props;

  const title = "Movilización Total";

  const chartRef = useRef(null);

  const { height, width } = useWindowDimensions();

  const [dataCard, setDataCard] = useState({
    Avance: "",
    Meta: "",
    PorcentajeAvance: "",
  });

  useEffect(() => {
    let porcentajeAvance;

    if (data.Avance === 0 && data.Meta === 0) {
        porcentajeAvance = "0.00";
    } else {
        porcentajeAvance = ((data.Avance / data.Meta) * 100).toFixed(2);
    }
    setDataCard({
      Avance: data.Avance,
      Meta: data.Meta,
      PorcentajeAvance: porcentajeAvance,
    });
  }, [data]);

  // Opciones del chart
  const options = {
    title: {
      text: `${title.toUpperCase()}`,
      align: "right",
      style: {
        color: "#999",
        fontSize: width > 1440 ? width * 0.01 : "18px",
      },
    },
    chart: { type: "solidgauge", height: width > 1440 ? height * 0.4 : "auto",},
    pane: {
      center: ["50%", "60%"],
      size: "100%",
      startAngle: -90,
      endAngle: 90,
      background: {
        backgroundColor: Highcharts.defaultOptions.legend.backgroundColor || "#EEE",
        innerRadius: "60%",
        outerRadius: "100%",
        shape: "arc",
      },
    },
    exporting: { enabled: false },
    tooltip: { enabled: false },
    yAxis: {
      min: 0,
      max: dataCard.Meta,
      stops: [
        [0.1, "#DF5353"], // red
        [0.5, "#DDDF0D"], // yellow
        [0.9, "#55BF3B"], // green
      ],
      lineWidth: 0,
      tickWidth: 0,
      minorTickInterval: null,
      tickInterval: dataCard.Meta / 1,
      title: { y: -70 },
      labels: {
        y: 16,
        style: { fontSize: "12px" },
        formatter: function () {
          // Verificamos si es el valor mínimo
          if (this.isFirst) return "0"; // Colocamos '0' como etiqueta para el mínimo

          // Verificamos si es el valor máximo
          if (this.isLast) return `<span>${numberWithCommas(dataCard.Meta)}</span>`; // Colocamos el valor de Meta como etiqueta para el máximo

          // En otros casos, simplemente retornamos el valor original
          return this.value;
        },
      },
    },
    plotOptions: { solidgauge: { dataLabels: { y: -10, borderWidth: 0, useHTML: true } } },
    series: [
      {
        data: [parseInt(dataCard.Avance)],
        dataLabels: {
          format:
            '<div style="text-align:center">' +
            `<span style="font-size:29px";opacity:0.4">${numberWithCommas(dataCard.Avance)}</span><br/> ` +
            `<span style="font-size:18px;opacity:0.4">${dataCard.PorcentajeAvance}%</span><br/>` +
            "</div>",
        },
        tooltip: { valueSuffix: " km/h" },
      },
    ],
    credits: { enabled: false },
  };

  return (
    <Card className="card-primary" sx={{ height: "100%", mb: "0 !important" }}>
      <CardContent key={openDrawer}>
        {loading ? (
          <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", minHeight: "40vh" }}>
            <CircularProgress />
          </Box>
        ) : (
          <Box width={"100%"}>
            <Box>
              <HighchartsReact highcharts={Highcharts} options={options} ref={chartRef} />
            </Box>
            <Box>
              <LastUpdatedCard
                dateHour={""}
                handleClick={handleRefresh}
                refresh={refresh}
                columnIn={"md"}
                sx={{ height: "100%", mb: "0 !important" }}
              />
            </Box>
          </Box>
        )}
      </CardContent>
    </Card>
  );
};

export default AccumulatedCertifiedPeople;
