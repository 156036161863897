// Material UI
import { Divider, Drawer, Typography, Toolbar, Box } from "@mui/material";

/* Ejemplo de  properties
{
    open: true, // Si se desea que este inicialmente abierto.
    title: "Titulo del Drawer",
    component: ReactComponent,
    height: { sm: "100%", md: 350, lg: 500, xl: 700 }, // Solo valores variables o vacio (se ajusta al contenido)
}*/

const DrawerTop = (props) => {
  const { properties, open } = props;

  const height = open ? (properties.height ? properties.height : "fit-content") : 0;
  const titleFlag = properties.title ? true : false;

  return (
    <Drawer
      sx={{
        flexShrink: 0,
        width: "100%",
        "& .MuiDrawer-paper": {
          height: height,
          position: "relative",
          transition: "all 10s ease-in-out",
          zIndex: 100,
        },
      }}
      variant="persistent"
      anchor="top"
      open={open}
    >
      <Box id="MapDrawerTop">
        {titleFlag && (
          <>
            <Toolbar sx={{ justifyContent: "space-between" }}>
              <Typography variant="h6" noWrap component="div">
                {properties.title}
              </Typography>
            </Toolbar>
            <Divider />
          </>
        )}
        {properties.component}
      </Box>
    </Drawer>
  );
};

export default DrawerTop;
