import { useState, useEffect } from "react";
import Swal from "sweetalert2";

// Material UI
import { Card, CardContent, Typography, Box } from "@mui/material";
import ErrorIcon from '@mui/icons-material/Error';

// Componentes
import BasicLine from "@components/Charts/BasicLine";

// Servicios
import MovilizationServices from "@services/MovilizationServices";

const ChartCumulativeProgress = ({ body, setLoading, openDrawer, ambitoUser }) => {
  const [dataChart, setDataChart] = useState({
    categories: [],
    series: [],
    title: "",
  });

  // controlar si no se encontraron resultados
  const [noResults, setNoResults] = useState(false);

  const mobilizedChartTerritorialCumulative = async () => {
    const filteredBody = {
      ...body,
      filtered: body.filtered.filter((item) => item.id !== "PorcentajeAvance"),
    };

    try {
      const res = await MovilizationServices.getMobilizedChartElectoralCumulative(filteredBody);
      const { results, message, response } = res;

      if (results && response && response.series && response.categories) {
        setDataChart({
          ...dataChart,
          categories: response.categories,
          series: response.series,
          title: response.title.toUpperCase(),
        });

        setNoResults(false);
      } else {
        setDataChart({
          categories: [],
          series: [],
          title: "",
        });
        setNoResults(true);
        throw new Error(message);
      }
    } catch (error) {
      Swal.fire({
        title: error.message,
        icon: "warning",
      });
    } finally {
      setLoading((prevState) => ({
        ...prevState,
        isLoadigForm: false,
        openLinearProgress: false,
      }));
    }
  };

  useEffect(() => {
    if (ambitoUser) mobilizedChartTerritorialCumulative(body);
    // eslint-disable-next-line
  }, [body]);

  return (
    <Card className="card-primary">
      <CardContent key={`Mobilization_${openDrawer}`}>
        {noResults ? (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              color: "black",
            }}
          >
            <ErrorIcon sx={{ fontSize: 40, mt: 1 }} />
            <Typography fontWeight={600} variant="subtitle2" marginTop={2}>
              No se encontraron resultados
            </Typography>
          </Box>
        ) : (
          <>
            <Typography fontWeight={600} variant="subtitle2" marginBottom={2}>
              {dataChart.title}
            </Typography>
            <BasicLine
              title={{
                primary: "",
              }}
              legend={{
                layout: "horizontal",
                align: "center",
                verticalAlign: "bottom",
              }}
              series={dataChart.series}
              categories={dataChart.categories}
              initialPosition
            />
          </>
        )}
      </CardContent>
    </Card>
  );
};

export default ChartCumulativeProgress;
