// Material UI
import { Icon, Stack, Typography, Card, CardContent, Box, Button } from "@mui/material";
import { Update } from "@mui/icons-material";
import { blue } from "@mui/material/colors";

// Hooks
import useRefresh from "@hooks/useRefresh";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { numberWithCommas } from "@utils/Utilities/";

import certificacion from "@services/CertificationServices";

const LastUpdatedCard = (props) => {
  const { handleClick = () => { }, refresh, data, sx = {} } = props;

  const { label } = useRefresh({ handleRefresh: handleClick, reset: refresh, localTime: 1000000 });

  const [dataCard, setDataCard] = useState({
    CertificadosHoy: "",
    FechaActualizacion: "",
  });

  const [loading, setLoading] = useState(false);

  return (
    <Box sx={{ textAlign: "center", mt: -7, zIndex: 1 }}>
    <Stack direction="row" spacing={1} justifyContent="center" alignItems="center">
      <Icon sx={{ color: blue[900], zIndex: 1 }}>update</Icon>
      <Typography variant="subtitle2" fontWeight={600} textAlign="center" sx={{ zIndex: 1 }}>
        ÚLTIMO CORTE
      </Typography>
      <Button
        onClick={handleClick}
        variant="outlined"
        endIcon={<Update />}
        sx={{
          color: blue[900],
          borderColor: blue[900],
          "&:hover": {
            borderColor: blue[700],
          },
        }}
      >
        Actualizar
      </Button>
    </Stack>
    <Box sx={{ mt: 2 }}> {/* Ajusta el margen superior según sea necesario */}
      <Typography variant="body2" sx={{ fontWeight: "bold", zIndex: 10 }}>
        {`Próxima actualización en ${label}`}
      </Typography>
    </Box>
  </Box>
  
  );
};

export default LastUpdatedCard;
