import { useEffect, useState } from "react";
import moment from "moment";
import Swal from "sweetalert2";
import sije from "@services/SijeService";

import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import { Box, Container, Card, CardContent, Tab, Fab, IconButton } from "@mui/material";
import { TabList, TabContext } from "@mui/lab";
import { Refresh, ChevronLeft, ChevronRight } from "@mui/icons-material";

import AdminLayout from "@components/MainPage/AdminLayout";
import ActionHeader from "@components/Containers/ActionHeader";
import FilterSije from "@components/Sije/Nacional/filter";
import CardStats from "@components/Card/CardStats";
import NacionalTable from "@components/Sije/Nacional/NacionalTable";
import Map from "@components/Maps/CustomComponents/GenericMap";
import dataMap from "@components/Sije/Nacional/dataMap.json";


const SijeNacional = () => {
  //STATES
  const [filteredChart, setFilteredChart] = useState({ filtered: [] });
  const [, /* totalIncidencias */ setTotalIncidencias] = useState([]);
  const [totalCasillas, setTotalCasillas] = useState([]);
  const [tabValue, setTabValue] = useState(0);
  const [openFilter, setOpenFilter] = useState(true);
  const [title, setTitle] = useState("")
  const [loadGetTotal, setLoadGetTotal] = useState(false);

  const [data, setData] = useState({
    page: 0,
    pageSize: 10,
    filtered: [],
    sorted: [],
  });

  let complementoCasilla;

  switch (tabValue) {
    case 0:
      complementoCasilla = "Pase de Lista";
      break;
    case 1:
      complementoCasilla = "Casillas Instaladas";
      break;
    case 2:
      complementoCasilla = "Casillas Aperturadas";
      break;
    case 3:
      complementoCasilla = "Casillas Cerradas";
      break;
    case 4:
      complementoCasilla = "Cierre Votación";
      break;
    default:
      complementoCasilla = "";
  }

  const getTotalesCards = async () => {
    try {
      setLoadGetTotal(true);
      const result = await sije.getTotalesCards({
        catalogs: [{ id: "casillas" }, { id: "incidencias" }],
        filtered: filteredChart.filtered,
      });
      const { response, message, results } = await result;
      if (results) {
        setTotalIncidencias(() => {
          return {
            Favorables: response.data.incidencias[0].value,
            Competidas: response.data.incidencias[1].value,
            Adversas: response.data.incidencias[2].value,
          };
        });

        setTotalCasillas(() => {
          return {
            CasillasAprobadas: parseInt(response.data.casillas[0].CasillasAprobadas !== null ? response.data.casillas[0].CasillasAprobadas : 0),
            CasillasInstaladas: parseInt(response.data.casillas[0].CasillasInstaladas !== null ? response.data.casillas[0].CasillasInstaladas : 0),
            CasillasAperturadas: parseInt(response.data.casillas[0].CasillasAperturadas !== null ? response.data.casillas[0].CasillasAperturadas : 0),
            CasillasCerradas: parseInt(response.data.casillas[0].CasillasCerradas !== null ? response.data.casillas[0].CasillasCerradas : 0),
            VotacionCerrada: parseInt(response.data.casillas[0].VotacionCerrada !== null ? response.data.casillas[0].VotacionCerrada : 0),
          };
        });
      } else {
        Swal.fire({
          title: message,
          icon: "warning",
        });
      }
    } catch (error) {
      Swal.fire({
        title: error,
        icon: "warning",
      });
    } finally {
      setLoadGetTotal(false);
    }
  };

  const onChangeFilter = ({ filtersChart, filtersData }) => {
    if (filtersChart.length > 0 || filtersData.length > 0) {
      setFilteredChart((prevState) => ({
        ...prevState,
        filtered: filtersChart,
      }));
      setData((prevState) => ({
        ...prevState,
        page: 0,
        filtered: filtersData,
      }));
    }
  };

  const onClearFilter = () => {
    if (filteredChart.filtered.length > 0 || data.filtered.length > 0) {
      setFilteredChart((prevState) => ({
        ...prevState,
        filtered: [],
      }));
      setData((prevState) => ({ ...prevState, filtered: [] }));
    }
  };

  const handleChangeTab = (e, newVal) => {
    setTabValue(newVal);
  };

  const handleRefresh = () => {
    getTotalesCards();
    onClearFilter()
  };

  const handleClearFilter = () => {
    setFilteredChart((prevState) => ({
      ...prevState,
      filtered: [],
    }));
    setData((prevState) => ({ ...prevState, filtered: [] }));
  };

  useEffect(() => {
    getTotalesCards();
    //eslint-disable-next-line
  }, [filteredChart]);

  return (
    <AdminLayout>
      <Container maxWidth="false">
        <ActionHeader title={`SIJE(${title})`} />
        <Grid2 container spacing={2} direction={{ md: "row-reverse" }}>
          {!openFilter && (
            <IconButton onClick={() => setOpenFilter(!openFilter)} aria-label="open Drawer" color={openFilter ? "default" : "primary"}>
              {openFilter ? <ChevronRight /> : <ChevronLeft />}
            </IconButton>
          )}
        </Grid2>
        <Grid2 container spacing={2} direction={{ md: "row-reverse" }}>
          {/* Filtros */}
          {openFilter && (
            <Grid2 xs={12} md={3}>
              <FilterSije onChangeFilter={onChangeFilter} onClearFilter={onClearFilter} setOpenFilter={setOpenFilter} handleClearFilter={handleClearFilter}/>
            </Grid2>
          )}

          {/* Dashboard */}
          <Grid2 xs={12} md={openFilter ? 9 : 12}>
            <Box component="div" marginBottom={1}>
              <Grid2 container spacing={2}>
                <Grid2 xs={12} md={4}>
                  <CardStats icon={"home"} title={"CASILLAS APERTURADAS"} total={totalCasillas.CasillasAperturadas} loading={loadGetTotal} />
                </Grid2>
                <Grid2 xs={12} md={4}>
                  <CardStats icon={"warning"} title={"INCIDENCIAS"} total={10} loading={loadGetTotal} />
                </Grid2>
                <Grid2 xs={12} md={4}>
                  <CardStats
                    icon={"history"}
                    title={"ÚLTIMO CORTE"}
                    total={moment().format("DD/MM/YYYY HH:mm:ss") || "--/--/---- HH:mm:ss"}
                    loading={loadGetTotal}
                    fontSize={25}
                  />
                </Grid2>
              </Grid2>
            </Box>
            <Card className="card-primary">
              <CardContent>
                <Grid2 container spacing={2}>
                  <Grid2 item xs={12} md={12}>
                    <Box style={{ overflowX: "auto" }}>
                      <TabContext value={tabValue}>
                        <TabList
                          onChange={handleChangeTab}
                          aria-label="tab for user module"
                          textColor="primary"
                          indicatorColor="primary"
                          start
                          scrollButtons="auto"
                          centered
                        >
                          <Tab iconPosition="start" label="Pase de lista" value={0} />
                          <Tab iconPosition="start" label="Instaladas" value={1} />
                          <Tab iconPosition="start" label="Aperturadas" value={2} />
                          <Tab iconPosition="start" label="Casillas Cerradas" value={3} />
                          <Tab iconPosition="start" label="Cierre Votación" value={4} />
                        </TabList>
                      </TabContext>
                    </Box>
                  </Grid2>
                  <Grid2 item xs={12} md={4}>
                    <NacionalTable casillaName={complementoCasilla}/>
                  </Grid2>
                  <Grid2 item xs={12} md={8}>
                    <Map isHidden={false} height={"78vh"} data={dataMap} highlightFlag={false} navigate={false} />
                  </Grid2>
                </Grid2>
              </CardContent>
            </Card>
          </Grid2>
        </Grid2>
        <Fab color="primary" aria-label="Actualizar" sx={{ position: "fixed", bottom: 16, right: 16 }} onClick={handleRefresh}>
          <Refresh />
        </Fab>
      </Container>
    </AdminLayout>
  );
};

export default SijeNacional;

