import { useState, useEffect } from "react";
import useWindowDimensions from "@hooks/useWindowDimensions";
import Swal from "sweetalert2";
import "moment/locale/es";

// Material UI
import {
  Container,
  Box,
  Card,
  CardContent,
  Stack,
  ToggleButtonGroup,
  Icon,
  ToggleButton,
  Typography,
} from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import { blue } from "@mui/material/colors";

// Componentes
import AdminLayout from "@components/MainPage/AdminLayout";
import ActionHeader from "@components/Containers/ActionHeader";
import SolidGauge from "@components/Reports/SolidGauge";
import TabsOptions from "@components/Reports/TabsOptions";
import Chart from "@components/Reports/Chart";
import Map from "@components/Reports/Map";
import ReportsContext from "@components/Reports/ReportsContext";
import LoadingForm from "@components/LinearProgress/LoadingForm";
import HistoricalCard from "@components/Reports/CardContenet";
import CustomTable from "@components/Tables/CustomTable";
// import FilterMap from "@components/Reports/FilterMap";

// Utils y data
import { numberWithCommas } from "@utils/Utilities";
import { /* DEFAULT_OPTION, */ COLUMNS_TABLE } from "@data/constants/Reports";

// Servicios
import NationalServices from "@services/MapServices/NationalServices";
import ReportsServices from "@services/ReportsServices";

const DashboardNational = () => {
  const { height, width } = useWindowDimensions();
  const [labels, setLabels] = useState([
    { name: "Top Mejores Estados", type: "Compromisos" },
    { name: "Top Peores Estados", type: "Compromisos" },
  ]);
  const [tabValue, setTabValue] = useState(0);
  const [loading, setLoading] = useState(false);
  //LoadigForm
  const [isLoadigForm, setIsLoadingForm] = useState(true);
  const [isSuccessFormSave] = useState(false);
  const [openLinearProgress, setOpenLinearProgress] = useState(true);
  const [totals, setTotals] = useState({
    Ejercicio: "",
    avance: 0,
    Meta: 0,
  });
  const [bestTopList, setBestTopList] = useState([]);
  const [worstTopList, setWorstTopList] = useState([]);
  const [data, setData] = useState({
    historico: 0,
    esperado: 0,
    capturadoHoy: 0,
    capturadoAyer: 0,
  });
  const [viewOption, setViewOption] = useState("map");
  const [rows, setRows] = useState([]);

  const [hasEntity, setHasEntity] = useState(false);

  /* const [filterValue, setFilterValue] = useState({
    group: DEFAULT_OPTION.value,
    entity: DEFAULT_OPTION.value,
    df: DEFAULT_OPTION.value,
    dfPriority: DEFAULT_OPTION.value,
  }); */
  const [polygons, setPolygons] = useState(null);
  const [loadedLayer, setLoadedLayer] = useState(false);

  const getEmptyMap = () => setPolygons({ type: "FeatureCollection", features: [] });

  useEffect(() => {
    const init = async () => {
      getEmptyMap();
      await loadMainLayer();
    };

    init();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const init = async () => {
      setLoading(true);
      setIsLoadingForm(true);
      setOpenLinearProgress(true);
      const type = tabValue + 1;

      await getTotals(type);
      await getBestTopTen(type);
      await getWorstTopTen(type);
      await getStatsTable(type);

      setLoading(false);
      setIsLoadingForm(false);
      setOpenLinearProgress(false);

      switch (type) {
        case 1:
          setLabels((prevState) => prevState.map((item) => ({ ...item, type: "Compromisos" })));
          break;
        case 2:
          setLabels((prevState) => prevState.map((item) => ({ ...item, type: "Est. Electoral" })));
          break;
        case 3:
          setLabels((prevState) => prevState.map((item) => ({ ...item, type: "Est. Territorial" })));
          break;
        default:
          setLabels((prevState) => prevState.map((item) => ({ ...item, type: "Compromisos" })));
          break;
      }
    };

    init();
    // eslint-disable-next-line
  }, [tabValue]);

  const getTotals = async (type) => {
    try {
      const result = await ReportsServices.getTotals({ type });
      const {
        results,
        response: { data },
        message,
      } = result;

      if (results) setTotals(data);
      else {
        setTotals({
          Ejercicio: "",
          avance: 0,
          Meta: 0,
        });
        throw new Error(message);
      }
    } catch (error) {
      Swal.fire({ title: error.message, icon: "warning" });
    }
  };

  const getBestTopTen = async (type) => {
    try {
      const result = await ReportsServices.getBestTopTen({ type });
      const {
        results,
        response: { data },
        message,
      } = result;

      if (results) setBestTopList(data);
      else {
        setBestTopList([]);
        throw new Error(message);
      }
    } catch (error) {
      Swal.fire({ title: error.message, icon: "warning" });
    }
  };

  const getWorstTopTen = async (type) => {
    try {
      const result = await ReportsServices.getWorstTopTen({ type });
      const {
        results,
        response: { data },
        message,
      } = result;

      if (results) setWorstTopList(data);
      else {
        setWorstTopList([]);
        throw new Error(message);
      }
    } catch (error) {
      Swal.fire({ title: error.message, icon: "warning" });
    } finally {
    }
  };

  const getStatsTable = async (type) => {
    try {
      const result = await ReportsServices.getStatsTable({ type });
      const {
        results,
        response: { data },
        message,
      } = result;

      if (results) {
        setRows(
          data.map((item) => ({
            ...item,
            estado: item.estado,
            meta: numberWithCommas(item.meta),
            value: numberWithCommas(item.value),
          }))
        );
      } else throw new Error(message);
    } catch (error) {
      setRows([]);
      Swal.fire({ title: error.message, icon: "warning" });
    }
  };

  const handleLoading = (loadingValue) => {
    setIsLoadingForm(loadingValue);
    setOpenLinearProgress(loadingValue);
  };

  const handleViewChange = (e, newValue) => {
    if (newValue !== null) setViewOption(newValue);
  };

  const loadMainLayer = async () => {
    setPolygons(null);
    handleLoading(true);
    try {
      const params = {
        color: 0,
        page: 0,
        pageSize: 10,
        filtered: [{ filter: "IN", id: "tb.idEntidad", value: 0 }],
        shp: "entidad",
        idEntidad: 0,
      };

      const result = await NationalServices.getShapes(params);
      const { results, response, message } = result;

      if (results) setPolygons(response.data);
      else Swal.fire({ title: message, icon: "warning" });
    } catch (error) {
      Swal.fire({ title: error.message, icon: "warning" });
    } finally {
      handleLoading(false);
      setLoadedLayer(true);
    }
  };

  return (
    <AdminLayout>
      <Container maxWidth={false}>
        <ActionHeader title="Reporte Nacional" />
        <LoadingForm
          loadinMessage={"Consultando..."}
          successMessage="¡Consultado con éxito!"
          isLoading={isLoadigForm}
          success={isSuccessFormSave}
          isOpen={openLinearProgress}
          setIsOpen={() => setOpenLinearProgress(!openLinearProgress)}
        />

        <ReportsContext.Provider
          value={{ setIsLoadingForm, setOpenLinearProgress, handleLoading, polygons, loadedLayer }}
        >
          <Box>
            <Grid2 container spacing={2}>
              <Grid2 xs={12} md={3} lg={4}>
                <HistoricalCard
                  data={data}
                  title={"Al momento"}
                  firstTitle={"Capturados hoy"}
                  secondTitle={"Capturados ayer"}
                  loading={isLoadigForm}
                />
              </Grid2>
              <Grid2 xs={12} md={3} lg={4}>
                <HistoricalCard
                  data={data}
                  title={"Días transcurridos: 22"}
                  firstTitle={"Acumulados"}
                  secondTitle={"Esperados"}
                  lastTitle={"Días transcurridos"}
                  showLast={true}
                  loading={isLoadigForm}
                />
              </Grid2>
              <Grid2 xs={12} md={3} lg={4}>
                <SolidGauge
                  max={parseInt(totals.Meta)}
                  data={parseInt(totals.avance)}
                  title={"Porcentaje de avance nacional"}
                  //loading={isLoadigForm}
                />
              </Grid2>
            </Grid2>
          </Box>
          <TabsOptions tabValue={tabValue} setTabValue={setTabValue} setLoading={setLoading} />
          <Box>
            <Grid2 container spacing={2}>
              {!hasEntity && (
                <Grid2 xs={12} md={4} lg={4}>
                  <Stack direction={"column"}>
                    <Chart
                      categories={bestTopList.map((item) => item.estado)}
                      series={bestTopList}
                      label={labels[0]}
                      loading={loading}
                      graphType={1}
                    />
                    <Chart
                      categories={worstTopList.map((item) => item.estado)}
                      series={worstTopList}
                      label={labels[1]}
                      loading={loading}
                      graphType={3}
                    />
                  </Stack>
                </Grid2>
              )}
              <Grid2 xs={12} md={!hasEntity ? 8 : 12} lg={!hasEntity ? 8 : 12}>
                <Card className="card-primary">
                  <CardContent>
                    <Grid2 container>
                      {/* <Grid2 xs={12} lg={2}>
                        <FilterMap
                          filterValue={filterValue}
                          setFilterValue={setFilterValue}
                          setHasEntity={setHasEntity}
                        />
                      </Grid2> */}
                      <Grid2 xs={12} lg={8}>
                        <Box display={"flex"} justifyContent={"center"} marginBottom={2}>
                          <ToggleButtonGroup
                            value={viewOption}
                            onChange={handleViewChange}
                            exclusive
                            size="small"
                            aria-label="type-view"
                          >
                            <ToggleButton value="map" aria-label="map">
                              <Icon sx={{ color: blue[800] }}>map</Icon>{" "}
                              <Typography variant="body2" fontWeight={600}>
                                Mapa
                              </Typography>
                            </ToggleButton>
                            <ToggleButton value="table" aria-label="table">
                              <Icon sx={{ color: blue[800] }}>table_view</Icon>{" "}
                              <Typography variant="body2" fontWeight={600}>
                                Tabla
                              </Typography>
                            </ToggleButton>
                          </ToggleButtonGroup>
                        </Box>
                      </Grid2>
                    </Grid2>
                    <Grid2 container spacing={1}>
                      <Grid2 xs={12}>
                        {viewOption === "map" ? (
                          <Map best={bestTopList} worst={worstTopList} />
                        ) : (
                          <CustomTable
                            rows={rows}
                            columns={COLUMNS_TABLE}
                            stickyHeader
                            isLoading={loading}
                            maxHeight={width > 430 ? height * 0.75 : 800}
                          />
                        )}
                      </Grid2>
                    </Grid2>
                  </CardContent>
                </Card>
              </Grid2>
            </Grid2>
          </Box>
        </ReportsContext.Provider>
      </Container>
    </AdminLayout>
  );
};

export default DashboardNational;
