import { ApiExec } from "@utils/ApiExec";

class ElectoralService {

    getList = (params,api) => {
        return ApiExec(params, `electoral/${api}`, "POST")
          .then(function (res) {
            return res;
          })
          .then((res) => {
            return res;
          })
          .catch((e) => {
            return e;
          });
      };


      getResponsables = (params) => {
        return ApiExec(params, `electoral/get-responsables-electoral`, "POST")
          .then(function (res) {
            return res;
          })
          .then((res) => {
            return res;
          })
          .catch((e) => {
            return e;
          });
      };

      getStatsResponsables = (params) => {
        return ApiExec(params, `electoral/get-stats-responsables`, "POST")
          .then(function (res) {
            return res;
          })
          .then((res) => {
            return res;
          })
          .catch((e) => {
            return e;
          });
      };

      getListCoordinators = (params) => {
        return ApiExec(params, `electoral/get-list-coordinators`, "POST")
          .then(function (res) {
            return res;
          })
          .then((res) => {
            return res;
          })
          .catch((e) => {
            return e;
          });
      };

      getStatsRs = (params) => {
        return ApiExec(params, `electoral/get-totales-r`, "POST")
          .then(function (res) {
            return res;
          })
          .then((res) => {
            return res;
          })
          .catch((e) => {
            return e;
          });
      };

    

      getAvanceMetas = (params) => {
        return ApiExec(params, `electoral/avance-metas`, "POST")
          .then(function (res) {
            return res;
          })
          .then((res) => {
            return res;
          })
          .catch((e) => {
            return e;
          });
      };

      getAvanceMetasResponsabilidad = (params) => {
        return ApiExec(params, `electoral/avance-metas-responsabilidad`, "POST")
          .then(function (res) {
            return res;
          })
          .then((res) => {
            return res;
          })
          .catch((e) => {
            return e;
          });
      };

      getAvanceCasillla = (params) => {
        return ApiExec(params, `electoral/avance-casillas`, "POST")
          .then(function (res) {
            return res;
          })
          .then((res) => {
            return res;
          })
          .catch((e) => {
            return e;
          });
      };

      getDetalleRC = (params) => {
        return ApiExec(params, `electoral/detalle-rc`, "POST")
          .then(function (res) {
            return res;
          })
          .then((res) => {
            return res;
          })
          .catch((e) => {
            return e;
          });
      };

      getListRG = (params) => {
        return ApiExec(params, `electoral/get-rgs-routes`, "POST")
          .then(function (res) {
            return res;
          })
          .then((res) => {
            return res;
          })
          .catch((e) => {
            return e;
          });
      };

      getListAbogado = (params) => {
        return ApiExec(params, `electoral/get-abogados-routes`, "POST")
          .then(function (res) {
            return res;
          })
          .then((res) => {
            return res;
          })
          .catch((e) => {
            return e;
          });
      };

      deleteRGRuta = (params) => {
        return ApiExec(params, `electoral/remove-rg-of-route`, "POST")
          .then(function (res) {
            return res;
          })
          .then((res) => {
            return res;
          })
          .catch((e) => {
            return e;
          });
      };

      deleteAbogadoRuta = (params) => {
        return ApiExec(params, `electoral/remove-abogado-of-route`, "POST")
          .then(function (res) {
            return res;
          })
          .then((res) => {
            return res;
          })
          .catch((e) => {
            return e;
          });
      };

      asignarRGRuta = (params) => {
        return ApiExec(params, `electoral/assign-rg-to-route`, "POST")
          .then(function (res) {
            return res;
          })
          .then((res) => {
            return res;
          })
          .catch((e) => {
            return e;
          });
      };

      asignarAbogadoRuta = (params) => {
        return ApiExec(params, `electoral/assign-abogado-to-route`, "POST")
          .then(function (res) {
            return res;
          })
          .then((res) => {
            return res;
          })
          .catch((e) => {
            return e;
          });
      };

      getListVolunteers = (params) => {
        return ApiExec(params, "electoral/get-volunteers", "POST")
            .then((res) => res)
            .then((res) => res)
            .catch((error) => error);
    };

}

const electoral = new ElectoralService();
export default electoral;
