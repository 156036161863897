import ApiExec from "@utils/ApiExec";
import { encryptVars, DEBUG } from "@utils/global";

class DiaDServices {
  getCatalogs = (params) => {
    return ApiExec(params, "diad/get-catalogs", "POST")
      .then(function (res) {
        return res;
      })
      .then((res) => {
        return res;
      })
      .catch((e) => {
        return e;
      });
  };

  getPeopleToAssign = (params) => {
    return ApiExec(params, "diad/get-people-for-assign", "POST")
      .then(function (res) {
        return res;
      })
      .then((res) => {
        return res;
      })
      .catch((e) => {
        return e;
      });
  };

  setResponsability = (params) => {
    return ApiExec(params, "diad/set-responsibility", "POST")
      .then(function (res) {
        return res;
      })
      .then((res) => {
        return res;
      })
      .catch((e) => {
        return e;
      });
  };

  getResponsabilities = (value) => {
    const values = DEBUG ? value : encryptVars(value);
    return ApiExec({}, `diad/get-responsibilities/${values}`, "GET")
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };


  setResponsability = (params) => {
    return ApiExec(params, "diad/set-responsibility/", "POST")
      .then(function (res) {
        return res;
      })
      .then((res) => {
        return res;
      })
      .catch((e) => {
        return e;
      });
  };

  deleteResponsability = (params) => {
    return ApiExec(params, "diad/delete-responsibility", "DELETE")
      .then(function (res) {
        return res;
      })
      .then((res) => {
        return res;
      })
      .catch((e) => {
        return e;
      });
  };

}

const services = new DiaDServices();
export default services;
