import { useState, useEffect } from "react";
import {
    Box,
    TextField,
    Button,
    Stack,
    Modal,
    Card,
    CardContent,
    Divider,
    CardHeader,
    IconButton,
    ListItem,
    ListItemText,
    ListItemButton,
    Typography,
    Icon
} from "@mui/material";
import Swal from "sweetalert2";
import Grid2 from '@mui/material/Unstable_Grid2';
import DeleteIcon from '@mui/icons-material/Delete';
import SearchIcon from '@mui/icons-material/Search';
import CancelIcon from '@mui/icons-material/Cancel';
import { orange } from "@mui/material/colors";

import CompromisoServices from "@services/CompromisoServices";
import BasicList from "@components/Lists/BasicList";
import { isTypePhone } from "@utils/validations";

const Item = ({ item, selectedIndex, handleSelectedIndex, handleDeleteItem }) => {

    const handleListItemClick = (event, index) => {
        let data = index;
        handleSelectedIndex(index, data);
    };

    return (
        <ListItem key={item.value ? item.value : Math.random()} disablePadding>
            <ListItemButton
                selected={selectedIndex === item.value}
                onClick={event => handleListItemClick(event, item.value)}
            >
                <ListItemText
                    primary={`${item.label}(${item.Edad} años) - ${item.Municipio}`}
                    secondary={`Loc: ${item.Localidad} - Col. ${item.ColoniaVive}`}
                />
            </ListItemButton>
        </ListItem>
    )
}

const ModalResponsibleList = (props) => {
    const { openModal, setOpenModal, setResponsible } = props;
    const config = {
        title: "Responsables del 1xN",
        icon: "people",
        height: 400,
        endMessage: "No hay más registros para mostrar",
    };
    const pageSize = 20;
    const [page, setPage] = useState(0);
    const [hasMore, setHasMore] = useState(false);
    const [options, setOptions] = useState([]);
    const [total, setTotal] = useState(0);
    const [filterData, setFilterData] = useState({
        Celular: '',
        Nombre: '',
        Paterno: '',
        Materno: ''
    });
    const [errors, setErrors] = useState({
        Celular: '',
        Nombre: '',
        Paterno: '',
        Materno: ''
    });

    useEffect(() => {
        if (openModal) {
            handleClearSearch();
        }
    }, [openModal]);

    const handleFilter = () => {
        let filtered = [];

        if (filterData.Nombre.length > 0) {
            filtered.push({ id: 'compromisos_unicos.Nombre', filter: 'LIKE', value: filterData.Nombre.trim() });
        }
        if (filterData.Paterno.length > 0) {
            filtered.push({ id: 'compromisos_unicos.Paterno', filter: 'LIKE', value: filterData.Paterno.trim() });
        }
        if (filterData.Materno.length > 0) {
            filtered.push({ id: 'compromisos_unicos.Materno', filter: 'LIKE', value: filterData.Materno.trim() });
        }
        if (filterData.Celular.length > 0) {
            filtered.push({ id: 'compromisos_unicos.Celular', filter: 'LIKE', value: filterData.Celular.trim() });
        }

        return filtered;
    }

    const handleChangeFilter = (e) => {
        const name = e.target.name;
        let value = e.target.value;
        if (name === 'Celular') {
            setFilterData((prevState) => ({ ...prevState, [name]: isTypePhone(value) ? value : filterData[name] }));
        } else {
            setFilterData((prevState) => ({ ...prevState, [name]: value.length > 0 ? value.toUpperCase() : '' }));
        }
    }

    const customValidate = () => {
        let valid = false;

        if (filterData.Celular.length > 0) {
            if (filterData.Celular.length < 10 || filterData.Celular.length > 10) {
                setErrors((prevState) => ({ ...prevState, Celular: 'Ingrese un número de celular a 10 dígitos' }));
                valid = false;
            } else {
                setErrors({
                    Celular: '',
                    Nombre: '',
                    Paterno: '',
                    Materno: ''
                });
                valid = true;
            }
        } else {
            if (filterData.Nombre.length < 1) {
                if (filterData.Paterno.length < 1 && filterData.Materno.length < 1) {
                    setErrors((prevState) => ({
                        ...prevState,
                        Nombre: 'El Nombre del Responsable del 1xN es requerido',
                        Paterno: 'Ingrese al menos un apellido',
                        Materno: 'Ingrese al menos un apellido'
                    }));
                    valid = false;
                } else {
                    setErrors((prevState) => ({
                        ...prevState,
                        Nombre: 'El Nombre del Responsable del 1xN es requerido',
                        Paterno: '',
                        Materno: ''
                    }));
                    valid = false;
                }
            } else {
                if (filterData.Paterno.length < 1 && filterData.Materno.length < 1) {
                    setErrors((prevState) => ({
                        ...prevState,
                        Paterno: 'Ingrese al menos un apellido',
                        Materno: 'Ingrese al menos un apellido',
                        Nombre: ''
                    }));
                    valid = false;
                } else {
                    setErrors({
                        Celular: '',
                        Nombre: '',
                        Paterno: '',
                        Materno: ''
                    });
                    valid = true;
                }
            }
        }

        return valid;
    }

    const handleSearch = () => {
        let filtered = handleFilter();
        let valid = customValidate();
        if (!valid) return;

        setHasMore(true);
        CompromisoServices.getResponsibles({ page: 0, pageSize, filtered })
            .then(res => {
                if (res.results) {
                    setOptions(res.response.data);
                    setTotal(res.response.total);

                    if (pageSize >= parseInt(res.response.total)) {
                        setHasMore(false);
                    }
                } else {
                    Swal.fire({
                        title: res.message, icon: "warning", customClass: {
                            container: "modal-alert",
                        },
                    });
                    setHasMore(false);
                }
            })
            .catch(error => {
                setHasMore(false);
                Swal.fire({
                    title: error.message,
                    icon: 'warning',
                    customClass: {
                        container: "modal-alert",
                    },
                });
            })
    }

    const handleClearSearch = () => {
        setFilterData({
            Nombre: '',
            Paterno: '',
            Materno: '',
            Celular: ''
        });

        setOptions([]);
        setPage(0);
        setTotal(0);
    }

    const fetchHasMore = () => {
        let filtered = handleFilter();
        CompromisoServices.getResponsibles({ page: page + 1, pageSize, filtered })
            .then(res => {
                if (res.results) {
                    setOptions(options.concat(res.response.data));
                    let end = (page + 1) * pageSize;
                    if (end >= res.response.total) {
                        setHasMore(false);
                    }
                } else {
                    Swal.fire({
                        title: res.message, icon: "warning", customClass: {
                            container: "modal-alert",
                        },
                    });
                    setHasMore(false);
                }
            })
            .catch(error => {
                setHasMore(false);
                Swal.fire({
                    title: error.message,
                    icon: 'warning',
                    customClass: {
                        container: "modal-alert",
                    },
                });
            });

        setPage(page + 1);
    }

    const handleClickItem = (id) => {
        const responsible = options.find(item => item.value === id);
        setResponsible(responsible);
        setOpenModal(false);
    }

    const handleOnKeyPress = (event) => {
        if (event.key === "Enter") {
            event.preventDefault();
            handleSearch();
        }
    };

    return (
        <Modal
            open={openModal}
            onClose={e => setOpenModal(false)}
        >
            <Card sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                width: "60%",
                maxHeight: "100%",
                overflowY: "auto",
                transform: "translate(-50%, -50%)",
                boxShadow: 24,
                p: 2,
            }}>
                <CardHeader
                    title={"Responsable del 1xN"}
                    action={
                        <IconButton aria-label="cancel" onClick={() => setOpenModal(false)}>
                            <CancelIcon />
                        </IconButton>
                    }
                />
                <CardContent>
                    <Box>
                        <Grid2 container spacing={3} >
                            <Grid2 xs={12} sm={12} md={4} lg={4}>
                                <TextField
                                    label='Nombre del Responsable'
                                    value={filterData.Nombre}
                                    name='Nombre'
                                    onChange={e => handleChangeFilter(e)}
                                    error={errors.Nombre}
                                    helperText={errors.Nombre.length > 1 && errors.Nombre}
                                    sx={{ width: '100%' }}
                                    type='text'
                                    variant="outlined"
                                    size="small"
                                    className="fixed-input"
                                    onKeyUp={handleOnKeyPress}
                                />
                            </Grid2>
                            <Grid2 xs={12} sm={12} md={4} lg={4}>
                                <TextField
                                    label='Paterno'
                                    value={filterData.Paterno}
                                    name='Paterno'
                                    onChange={e => handleChangeFilter(e)}
                                    error={errors.Paterno}
                                    helperText={errors.Paterno.length > 1 && errors.Paterno}
                                    sx={{ width: '100%' }}
                                    type='text'
                                    variant="outlined"
                                    size="small"
                                    className="fixed-input"
                                    onKeyUp={handleOnKeyPress}
                                />
                            </Grid2>
                            <Grid2 xs={12} sm={12} md={4} lg={4}>
                                <TextField
                                    label='Materno'
                                    value={filterData.Materno}
                                    name='Materno'
                                    onChange={e => handleChangeFilter(e)}
                                    error={errors.Materno}
                                    helperText={errors.Materno.length > 1 && errors.Materno}
                                    sx={{ width: '100%' }}
                                    type='text'
                                    variant="outlined"
                                    size="small"
                                    className="fixed-input"
                                    onKeyUp={handleOnKeyPress}
                                />
                            </Grid2>
                        </Grid2>

                        <Grid2 container spacing={3} sx={{ mt: 1 }}>
                            <Grid2 xs={12} sm={12} md={4} lg={4}>
                                <TextField
                                    label='Celular'
                                    value={filterData.Celular}
                                    name='Celular'
                                    onChange={e => handleChangeFilter(e)}
                                    error={errors.Celular}
                                    helperText={errors.Celular.length > 1 && errors.Celular}
                                    sx={{ width: '100%' }}
                                    type='text'
                                    variant="outlined"
                                    size="small"
                                    className="fixed-input"
                                    onKeyUp={handleOnKeyPress}
                                />
                            </Grid2>
                            <Grid2 xs={12} sm={12} md={4} lg={4}>
                            </Grid2>
                            <Grid2 xs={12} sm={12} md={4} lg={4}>
                                <Stack direction={'row'}>
                                    <Button
                                        variant="contained"
                                        color="error"
                                        fullWidth
                                        startIcon={<DeleteIcon />}
                                        onClick={e => handleClearSearch()}>
                                        Limpiar
                                    </Button>
                                    <Button
                                        variant="contained"
                                        color="primaryDark"
                                        fullWidth
                                        endIcon={<SearchIcon />}
                                        onClick={e => handleSearch()}>
                                        Buscar
                                    </Button>
                                </Stack>
                            </Grid2>
                        </Grid2>
                        <Grid2 container spacing={3}>
                            <Grid2 xs={12} sm={12}>
                                <Typography fontSize={'0.9em'} sx={{
                                    color: orange[900]
                                }}>
                                    <Icon>info</Icon>
                                    Nota: Para realizar una búsqueda debe ingresar un Nombre de Responsable y al menos un Apellido o por el contrario un número de celular
                                </Typography>
                            </Grid2>
                        </Grid2>
                    </Box>
                    <Divider sx={{ mt: 2, mb: 3 }} />

                    <Grid2 container spacing={3} >
                        <BasicList
                            configParams={config}
                            data={options}
                            fetchHasMore={fetchHasMore}
                            totalItems={total}
                            itemComponent={Item}
                            hasMore={hasMore}
                            handleClickItem={handleClickItem}
                            finder={false}
                        />
                    </Grid2>
                </CardContent>


            </Card>
        </Modal>
    )
}

export default ModalResponsibleList;