import { Box, Tab, Tabs } from "@mui/material";

const TabsOptions = ({ tabValue, setTabValue, setLoading }) => {
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      setTabValue(newValue);
    }, 2000);
  };

  return (
    <Box sx={{ mt: 2, mb: 2, display: "flex", justifyContent: "center" }}>
      <Tabs value={tabValue} onChange={handleTabChange}>
        <Tab label="Compromisos" />
        <Tab label="Estructura Electoral" />
        <Tab label="Estructura Territorial" />
      </Tabs>
    </Box>
  );
};

export default TabsOptions;
