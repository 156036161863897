import {
  Box,
  Card,
  CardContent,
  Icon,
  Stack,
  Typography,
  CircularProgress,
} from "@mui/material";
import { blue } from "@mui/material/colors";
const CardStats = ({
  icon,
  title,
  total,
  subtitle,
  subIcon,
  loading,
  component,
  fontSize,
  sx = {}
}) => {
  return (
    <Card className="card-primary" sx={sx}>
      <CardContent>
        <Stack direction="row" spacing={3} alignItems="center">
          {component ? (
            component
          ) : (
            <Box
              width={"90px"}
              height={"90px"}
              display={"flex"}
              justifyContent={"center"}
              alignItems={"center"}
              margin={"10px"}
              sx={{ backgroundColor: blue[50], borderRadius: "50%" }}
            >
              <Icon sx={{ fontSize: "3rem", color: blue[800] }}>{icon}</Icon>
            </Box>
          )}
          <Box margin={"10px"}>
            <Typography variant="body2" fontWeight={500}>
              {title}
            </Typography>
            {loading ? (
              <CircularProgress />
            ) : (
              <Typography variant="h3" fontWeight={800} fontSize={fontSize}>
                {total}
              </Typography>
            )}

            {subtitle && (
              <Typography
                variant="subtitle2"
                fontWeight={300}
                component="span"
                display={"flex"}
                alignItems={"center"}
              >
                <Icon sx={{ fontSize: "1rem", mr: 1, color: blue[800] }}>
                  {subIcon}
                </Icon>{" "}
                {subtitle}
              </Typography>
            )}
          </Box>
        </Stack>
      </CardContent>
    </Card>
  );
};
export default CardStats;
