import React from "react";
import {
  Modal,
  Box,
  IconButton,
  Icon
} from "@mui/material";

const ModalMap = ({ open, setOpen, Children }) => {
/*   const [data, setData] = useState({}); */

  return (
    <>
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            //overflowY: "auto",
            bgcolor: "white",
            borderRadius: "8px",
            boxShadow: 24,
            p: 2,
          }}
        >
          {
            <IconButton aria-label="cancel" onClick={() => setOpen(false)}>
              <Icon>cancel</Icon>
            </IconButton>
          }
            {Children}
        </Box>
      </Modal>
    </>
  );
};

export default ModalMap;
