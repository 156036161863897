import { TableCell, TableRow, TableHead } from "@mui/material";
import { MENUS_PRIVILEGES } from "@data/constants"

const MenuRowHeader = (props) => {
  const columns = MENUS_PRIVILEGES;

  return (
    <TableHead>
      <TableRow>
        <TableCell
          component="th"
          scope="row"
          align="right"
          colSpan={props.colSpan}
          width="15%"
        >
          Ícono
        </TableCell>
        <TableCell component="th" scope="row" align="left" width="25%">
          Menú
        </TableCell>
        {
          columns && columns.length > 0 && columns.map(col => (
            <TableCell key={Math.random()} component="th" scope="row" align="center" width="7.5%">
              {col}
            </TableCell>
          ))
        }
      </TableRow>
    </TableHead>
  );
};

export default MenuRowHeader;
