import { useState, useEffect } from "react";
import Swal from "sweetalert2";

// Material UI
import { Box, Card, CardContent, Typography, CircularProgress } from "@mui/material";

// Componentes
import StackedBar from "@components/Charts/StackedBarCertifiers";

// Servicios
import certificacion from "@services/CertificationServices";

const ChartCertificationProgress = ({ body, openDrawer, ambitoUser, refresh }) => {
  const [dataChart, setDataChart] = useState({ categories: [], series: [], title: "" });
  const [loading, setLoading] = useState(false)

  const getMobilizedChartByView = async (params) => {
    setLoading(true)
    try {
      const res = await certificacion.getAfinNoAfinChart(params);
      const { results, message, response } = res;
      if (results) {
        setDataChart({
          categories: response.categories,
          series: response.series.map((item) => ({
            ...item,
            data: item.data.map((value) => parseInt(value)),
          })),
          title: response.title.toUpperCase(),
        });
      } else throw new Error(message);
    } catch (error) {
      Swal.fire({ title: error.message, icon: "warning" });
    } finally {
      setLoading(false)
    }
  };

  useEffect(() => {
    if (ambitoUser) getMobilizedChartByView(body);
    // eslint-disable-next-line
  }, [body, refresh]);

  return (
    <Card className="card-primary">
      <CardContent key={`Bingo_${openDrawer}`}>
        {loading ?
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <CircularProgress />
          </Box>
          :
          <Box>
            <Typography fontWeight={600} variant="subtitle2" marginBottom={2}>
              {setDataChart.title}
            </Typography>
            <StackedBar title={dataChart ?? ""} refresh={refresh} series={dataChart.series ?? []} categories={dataChart.categories ?? []} fontSize={'14px'} loading={loading} />
          </Box>
        }
      </CardContent>
    </Card>
  );
};

export default ChartCertificationProgress;
