import ApiExec from "@utils/ApiExec";
import { encryptVars, DEBUG } from "@utils/global";

//Clase para invocar a Apis del módulo de Soporte
class SupportServices {
  //Obtener el listado de usuarios que se pueden seleccionar para la asignación de perfiles permitidos para modificar
  getUsersToAssign = (params) => {
    return ApiExec(params, "support/get-users-to-assign", "POST")
      .then(function (res) {
        return res;
      })
      .then((res) => {
        return res;
      })
      .catch((e) => {
        return e;
      });
  };

  //Registrar los perfiles que el usuario tendrá permitido modificar a futuro en el mismo módulo de soportes (cambio de contraseñas)
  saveAllowedRoles = (params) => {
    return ApiExec(params, "support/save-allowed-roles", "POST")
      .then(function (res) {
        return res;
      })
      .then((res) => {
        return res;
      })
      .catch((e) => {
        return e;
      });
  };

  //Obtener los perfiles que el usuario seleccionado del listado tiene asignados hasta el momento

  //Ocupa id
  getAllowedRoles = (id) => {
    const _id = DEBUG ? id : encryptVars(id);
    return ApiExec({}, `support/get-allowed-roles/${_id}`, "GET")
      .then(function (res) {
        return res;
      })
      .then((res) => {
        return res;
      })
      .catch((e) => {
        return e;
      });
  };
  updatePassword = (params) => {
    return ApiExec(params, `support/update-password`, "PUT")
      .then(function (res) {
        return res;
      })
      .then((res) => {
        return res;
      })
      .catch((e) => {
        return e;
      });
  };
  updatePIN = (params) => {
    return ApiExec(params, `support/update-pin`, "PUT")
      .then(function (res) {
        return res;
      })
      .then((res) => {
        return res;
      })
      .catch((e) => {
        return e;
      });
  };
  updateStatus = (params) => {
    return ApiExec(params, `support/update-status`, "PUT")
      .then(function (res) {
        return res;
      })
      .then((res) => {
        return res;
      })
      .catch((e) => {
        return e;
      });
  };
  getUser = (params) => {
    return ApiExec(params, "support/get-users-to-support", "POST")
      .then(function (res) {
        return res;
      })
      .then((res) => {
        return res;
      })
      .catch((e) => {
        return e;
      });
  };
  deleteDevice = (params) => {
    return ApiExec(params, "support/delete-device", "DELETE")
      .then(function (res) {
        return res;
      })
      .then((res) => {
        return res;
      })
      .catch((e) => {
        return e;
      });
  };
  getDevices = (Device) => {
    const device = DEBUG ? Device : encryptVars(Device);
    return ApiExec({}, `support/get-devices/${device}`, "GET")
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };

  getSupportUsers = (params) => {
    return ApiExec(params, "support/get-support-users", "POST")
      .then(function (res) {
        return res;
      })
      .then((res) => {
        return res;
      })
      .catch((e) => {
        return e;
      });
  };

  getUserLogs = (Device) => {
    return ApiExec(Device, "support/get-user-logs", "POST")
      .then(function (res) {
        return res;
      })
      .then((res) => {
        return res;
      })
      .catch((e) => {
        return e;
      });
  };

  updateUsername = (params) => {
    return ApiExec(params, `support/update-username`, "PUT")
      .then(function (res) {
        return res;
      })
      .then((res) => {
        return res;
      })
      .catch((e) => {
        return e;
      });
  };

  getInactiveUser = (params) => {
    return ApiExec(params, "support/get-inactive-users", "POST")
      .then(function (res) {
        return res;
      })
      .then((res) => {
        return res;
      })
      .catch((e) => {
        return e;
      });
  };
  getListSupport = (params) => {
    return ApiExec(params, "support/get-list-support-request", "POST")
      .then(function (res) {
        return res;
      })
      .then((res) => {
        return res;
      })
      .catch((e) => {
        return e;
      });
  };

  getListSupportHistory = (params) => {
    return ApiExec(params, "support/get-list-support-request-history", "POST")
      .then(function (res) {
        return res;
      })
      .then((res) => {
        return res;
      })
      .catch((e) => {
        return e;
      });
  };
  updateSupportRequest = (params) => {
    return ApiExec(params, "support/update-support-request", "PUT")
      .then(function (res) {
        return res;
      })
      .then((res) => {
        return res;
      })
      .catch((e) => {
        return e;
      });
  };

  // Obtener el listado de usuarios hijos de la cuenta que la consulta
  getMyUsers = (params) => {
    return ApiExec(params, "support/get-my-users", "POST")
      .then(function (res) {
        return res;
      })
      .then((res) => {
        return res;
      })
      .catch((e) => {
        return e;
      });
  };
  // Actualizar el padre del usuario seleccionado
  updateOwner = (params) => {
    return ApiExec(params, "support/update-owner", "PUT")
      .then(function (res) {
        return res;
      })
      .then((res) => {
        return res;
      })
      .catch((e) => {
        return e;
      });
  };

  //Obtener el listado de usuarios que se pueden seleccionar para la asignación de perfiles permitidos para modificar
  getOwnerList = (params) => {
    return ApiExec(params, "support/get-owner-list", "POST")
      .then(function (res) {
        return res;
      })
      .then((res) => {
        return res;
      })
      .catch((e) => {
        return e;
      });
  };

  saveParty = (params) => {
    return ApiExec(params, "support/change-electoral-party", "PUT")
      .then(function (res) {
        return res;
      })
      .then((res) => {
        return res;
      })
      .catch((e) => {
        return e;
      });
  };
}

const user = new SupportServices();
export default user;
