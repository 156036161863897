import { useEffect, useState } from "react";
import AdminLayout from "@components/MainPage/AdminLayout";
import ActionHeader from "@components/Containers/ActionHeader";
//import MessageAlert from "@components/Downloads/MessageAlert";
import { useNavigate } from "react-router-dom";
import BasicTable from "@components/Tables/BasicTable";
import Card from "@components/Card/Card";
import CardCommitments from "@components/Card/StatesCard";
import CardIconColor from "@components/Card/CardIconColor";
import Grid from "@mui/material/Unstable_Grid2";
import SocialService from "@services/SocialServices";
import Filter from "@components/Structure/Filter";

import Swal from "sweetalert2";
import {
  Container,
  CardContent,
  FormControl,
  FormLabel,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";

const SocialStructure = () => {
  const navigate = useNavigate();
  var today = new Date();
  const [data, setData] = useState({
    rows: [],
    columns: [],
    subClumns: [],
    columnsDiarias: [],
    subClumnsDiario: [],
  });
  const [columns, setColumns] = useState({ head: [], sub: [] });
  // const [total /* , setTotal */] = useState(0);
  const [loading, setLoading] = useState(false);
  const [tableData, setTableData] = useState({
    filtered: [],
  });
  const [value, setValue] = useState("general");
  const [dataChart, setDataChart] = useState({
    TotalEstructuras: 0,
    TotalCompromisos: 0,
    compromisos: {
      TotalCompromisos: 0,
      TotalMasculino: 0,
      TotalFemenino: 0,
      TotalBinario: 0,
    },
    responsables: {
      TotalResponsables: 0,
      TotalMasculino: 0,
      TotalFemenino: 0,
      TotalBinario: 0,
    },
    enlaces: {
      TotalEnlaces: 0,
      TotalMasculino: 0,
      TotalFemenino: 0,
      TotalBinario: 0,
    },
  });
  const [loadingChart, setLoadingChart] = useState(false);
  //const [showMessage, setShowMessage] = useState(false);
  // const [successDownload, setSuccessDownload] = useState(false);
  //const [isDownloading, setIsDownloading] = useState(false);

  const getList = () => {
    if (loading) {
      return true;
    }

    setLoading(true);
    SocialService.getTableResumen(tableData)
      .then((res) => {
        if (res.results) {
          setData(res.response);
          setColumns({
            head: res.response.columns,
            sub: res.response.subClumns,
          });
          //setTotal(res.response.total);
        } else {
          Swal.fire({ title: res.message, icon: "warning" });
        }
      })
      .catch((e) => {
        Swal.fire({ title: e.message, icon: "warning" });
      })
      .finally(() => setLoading(false));
  };

  const getCharts = () => {
    SocialService.getTotalesSocial(tableData)
      .then((res) => {
        console.log("=>getChartsRS: ", res);
        if (res.results) {
          setDataChart(res.response);
        } else {
          Swal.fire({ title: res.message, icon: "warning" });
        }
      })
      .catch((e) => {
        Swal.fire({ title: e.message, icon: "warning" });
      })
      .finally(() => setLoadingChart(false));
  };

  useEffect(() => {
    setLoadingChart(true);
    getList();
    getCharts();
    // eslint-disable-next-line
  }, [tableData]);

  const handleChangePagination = (pagination) => {
    setTableData({ ...tableData, ...pagination });
  };

  const handleChangeFilter = (type) => {
    const { Region, Municipio, Segmento } = type;

    let filtered = [];
    //let filteredResponsable = [];

    if (Region > 0) {
      filtered = [
        ...filtered,
        { id: "cat_municipios.idRegion", filter: "=", value: Region },
      ];
    }
    if (Municipio > 0) {
      filtered = [
        ...filtered,
        { id: "cat_municipios.id", filter: "=", value: Municipio },
      ];
    }
    if (Segmento.length > 0) {
      filtered = [
        ...filtered,
        { id: "cat_social.id", filter: "IN", value: Segmento },
      ];
    }

    setTableData({
      ...tableData,
      filtered: filtered,
    });

    /*    setChartCompromisos({
      compromisos:{filtered: filtered},
      responsable:{filtered: filteredResponsable}
    }) */
  };

  const handleChange = (e) => {
    setValue(e.target.value);

    switch (e.target.value) {
      case "general":
        setColumns({
          head: data.columns,
          sub: data.subClumns,
        });
        break;
      case "pordia":
        setColumns({
          head: data.columnsDiarias,
          sub: data.subClumnsDiario,
        });
        break;
      default:
        break;
    }
  };

  /*  const handleDownload = () => {
    let params = tableData;
    delete params.page;
    delete params.pageSize;

    setIsDownloading(true);
    SocialService.exportReportMetas(params)
      .then((res) => {
        if (res.success) {
          setShowMessage(true);
          setSuccessDownload(true);
        } else {
          setShowMessage(true);
          setSuccessDownload(false);
        }
      })
      .catch((error) => {
        setShowMessage(true);
        setSuccessDownload(false);
      })
      .finally(() => setIsDownloading(false));
  };
 */

  return (
    <AdminLayout>
      <Container maxWidth="false">
        <ActionHeader
          title="Compromisos"
          handleclick={() => navigate("/socialcompromisos")}
        />
        <Grid container spacing={2}>
          <Grid xs={2}>
            <CardIconColor
              title="Total Estruturas"
              total={dataChart.TotalEstructuras}
              color="info"
              icon={"schema"}
              sx={{ height: "215px" }}
            />
          </Grid>
          <Grid xs={4}>
            <CardCommitments
              icon={"groups"}
              title={"Total Compromisos"}
              total={`${dataChart.compromisos.TotalCompromisos} ${
                dataChart.MetaCompromisos
                  ? `de ${dataChart.MetaCompromisos}`
                  : ""
              }`}
              subtitle1={"Femenino"}
              subtotal1={dataChart.compromisos.TotalFemenino}
              subtitle2={"Masculino"}
              subtotal2={dataChart.compromisos.TotalMasculino}
              subtitle3={"Binario"}
              subtotal3={dataChart.compromisos.TotalBinario}
              subIcon={"update"}
              subFooter={today.toLocaleDateString()}
              loading={loadingChart}
            />
          </Grid>
          <Grid xs={3}>
            <CardCommitments
              icon={"perm_identity"}
              title={"Total Responsables"}
              total={dataChart.responsables.TotalResponsables}
              subtitle1={"Femenino"}
              subtotal1={dataChart.responsables.TotalFemenino}
              subtitle2={"Masculino"}
              subtotal2={dataChart.responsables.TotalMasculino}
              subtitle3={"Binario"}
              subtotal3={dataChart.responsables.TotalBinario}
              subIcon={"update"}
              subFooter={today.toLocaleDateString()}
              loading={loadingChart}
            />
          </Grid>
          <Grid xs={3}>
            <CardCommitments
              icon={"record_voice_over"}
              title={"Total Enlaces"}
              total={dataChart.enlaces.TotalEnlaces}
              subtitle1={"Femenino"}
              subtotal1={dataChart.enlaces.TotalFemenino}
              subtitle2={"Masculino"}
              subtotal2={dataChart.enlaces.TotalMasculino}
              subtitle3={"Binario"}
              subtotal3={dataChart.enlaces.TotalBinario}
              subIcon={"update"}
              subFooter={today.toLocaleDateString()}
              loading={loadingChart}
            />
          </Grid>
        </Grid>

        <Card className="card-primary">
          <CardContent>
            <Filter
              onChangeFilter={(e) => handleChangeFilter(e)}
              filterCompromiso={false}
              poligono={false}
              seccion={false}
            />
          </CardContent>
        </Card>

        <Card className="card-primary">
          <CardContent>
            {/*      {isDownload ? (
              <Download format={"xlsx"} isDownload={isDownload} />
            ) : (
              <Box display={"flex"} justifyContent={"flex-end"}>
               
              </Box>
            )} */}
            {/*       {showMessage && (
              <Box marginTop={2}>
                <MessageAlert
                  showMessage={showMessage}
                  successDownload={successDownload}
                  setShowMessage={setShowMessage}
                />
              </Box>
            )}
          */}{" "}
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid item>
                <FormControl>
                  <FormLabel id="show">Vista de Avance</FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby="show"
                    name="show-radio-buttons-group"
                    value={value}
                    onChange={handleChange}
                  >
                    <FormControlLabel
                      value="general"
                      control={<Radio />}
                      label="General"
                    />
                    <FormControlLabel
                      value="pordia"
                      control={<Radio />}
                      label="Diario"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              {/*    <Grid item>
                <Button
                  variant="outlined"
                  color="primaryDark"
                  startIcon={<Icon>download</Icon>}
                  size="small"
                  onClick={handleDownload}
                  sx={{ borderRadius: "10px" }}
                >
                  Exportar
                </Button>
              </Grid> */}
            </Grid>
            {/*   {showMessage && (
              <Box marginTop={2}>
                <MessageAlert
                  showMessage={showMessage}
                  successDownload={successDownload}
                  setShowMessage={setShowMessage}
                />
              </Box>
            )} */}
            <BasicTable
              rows={data.rows}
              hcolumns={columns.head}
              hSubColumns={columns.sub}
              /*  pageProp={tableData.page}
              pageSize={tableData.pageSize} */
              /* total={total} */
              showPagination={false}
              handleChangePagination={handleChangePagination}
              isLoading={loading}
              stickyHeader={true}
            />
          </CardContent>
        </Card>
      </Container>
    </AdminLayout>
  );
};
export default SocialStructure;
