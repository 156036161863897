import React, { useState, useEffect } from "react";

import {
  Card,
  CardContent,
  CardActions,
  Typography,
  Divider,
  Box,
  Stack,
  CircularProgress,
  circularProgressClasses,
} from "@mui/material";

const Gauge = (props) => {
  const {
    loading = false,
    data = {
      contentData: "0",
      leftFooterData: "Sin información",
      rightFooterData: "Sin información",
    },
    config = {},
    styleSettings = {},
    titlecontent = "",
  } = props;

  const [stateConfig, setStateConfig] = useState({
    titleHeader: "Title",
    titlecontent: titlecontent,
    ColorCircularProgress: "#0d47a1",
    SizeCircularProgress: 80,
    SizeFooterTitle: "10px",
    leftFooterTitle: "",
    rightFooterTitle: "",
    icon: "",
    borderColor: "none",
  });

  const [stateStyleSettings, setStateStyleSettings] = useState({
    variant: "elevation",
    borderRadius: "12px",
    backgroundColor: "white",
    padding: 0,
    width: "100%",
  });

  useEffect(() => {
    setStateConfig({ ...stateConfig, titlecontent: titlecontent });
  }, [titlecontent]);

  useEffect(() => {
    Object.keys(config).forEach(function (key) {
      setStateConfig((prevState) => ({ ...prevState, [key]: config[key] }));
    });
  }, [config]);

  useEffect(() => {
    Object.keys(styleSettings).forEach(function (key) {
      setStateStyleSettings((prevState) => ({
        ...prevState,
        [key]: styleSettings[key],
      }));
    });
  }, [styleSettings]);

  return (
    <>
      <Card
        elevation={0}
        variant={stateStyleSettings.variant}
        sx={{
          borderRadius: stateStyleSettings.borderRadius,
          width: stateStyleSettings.width,
          p: stateStyleSettings.padding,
          backgroundColor: stateStyleSettings.backgroundColor,
          height: "100%",
          boxShadow: "0px 0px 15px 0px rgb(16 168 255 / 12%)",
          border: stateConfig.borderColor,
        }}
      >
        {stateConfig.titleHeader !== "Title" ? (
          <Box padding={1}>
            <Typography fontWeight={600} variant="body1" textAlign={"center"}>
              {stateConfig.titleHeader}
            </Typography>
          </Box>
        ) : (
          <></>
        )}
        <CardContent
          sx={{
            height: "70%",
            alignContent: "center",
            display: "flex",
            alignItems: "center",
          }}
        >
          <Stack
            alignItems="center"
            justifyContent="center"
            sx={{ width: "100%" }}
          >
            <Box sx={{ position: "relative", display: "inline-flex", mt: 0.3 }}>
              <CircularProgress
                variant={loading ? "indeterminate" : "determinate"}
                sx={{
                  color: (theme) =>
                    theme.palette.grey[
                      theme.palette.mode === "light" ? 200 : 800
                    ],
                }}
                size={stateConfig.SizeCircularProgress}
                thickness={4}
                
                value={100}
              />

              <CircularProgress
                variant={loading ? "indeterminate" : "determinate"}
                value={
                  parseInt(data.contentData) >= 100
                    ? 100
                    : parseInt(data.contentData)
                }
                // disableShrink={true}
                sx={{
                  color: stateConfig.ColorCircularProgress,
                  animationDuration: "550ms",
                  position: "absolute",
                  left: 0,
                  [`& .${circularProgressClasses.circle}`]: {
                    strokeLinecap: "round",
                  },
                }}
                size={stateConfig.SizeCircularProgress}
                thickness={4}
                
              />

              <Box
                sx={{
                  top: 0,
                  left: 0,
                  bottom: 0,
                  right: 0,
                  position: "absolute",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {loading ? (
                  <CircularProgress
                    sx={{ color: stateConfig.iconColor, m: 1 }}
                    size={15}
                  />
                ) : (
                  <Typography
                    sx={{
                      fontWeight: "bold",
                      fontFamily: "Helvetica Neue",
                      fontSize: 14,
                      color: stateConfig.ColorCircularProgress,
                    }}
                  >{`${data.contentData} %`}</Typography>
                )}
              </Box>
            </Box>
            <Typography
              sx={{
                fontWeight: "light",
                fontFamily: "Helvetica Neue",
              }}
            >
              {stateConfig.titlecontent}
            </Typography>
          </Stack>
        </CardContent>
        <Divider></Divider>
        <CardActions sx={{ textAlign: "center" }}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="flex-start"
            divider={<Divider orientation="vertical" flexItem />}
            spacing={2}
            sx={{ width: "100%" }}
          >
            <Stack sx={{ width: "100%" }}>
              <Typography
                sx={{
                  fontWeight: "light",
                  fontFamily: "Helvetica Neue",
                  fontSize: stateConfig.SizeFooterTitle,
                }}
              >
                {stateConfig.leftFooterTitle}
              </Typography>
              {loading ? (
                <CircularProgress
                  sx={{ color: stateConfig.iconColor, m: 1 }}
                  size={15}
                />
              ) : (
                <Typography
                  sx={{
                    fontWeight: "bold",
                    fontFamily: "Helvetica Neue",
                    fontSize:
                      data.leftFooterData === "Sin información" ? 13 : 18,
                  }}
                >
                  {data.leftFooterData}
                </Typography>
              )}
            </Stack>
            <Stack sx={{ width: "100%" }}>
              <Typography
                sx={{
                  fontWeight: "light",
                  fontFamily: "Helvetica Neue",
                  fontSize: stateConfig.SizeFooterTitle,
                }}
              >
                {stateConfig.rightFooterTitle}
              </Typography>
              {loading ? (
                <CircularProgress
                  sx={{ color: stateConfig.iconColor, m: 1 }}
                  size={15}
                />
              ) : (
                <Typography
                  sx={{
                    fontWeight: "bold",
                    fontFamily: "Helvetica Neue",
                    fontSize:
                      data.rightFooterData === "Sin información" ? 13 : 18,
                  }}
                >
                  {data.rightFooterData}
                </Typography>
              )}
            </Stack>
          </Stack>
        </CardActions>
      </Card>
    </>
  );
};

export default Gauge;
