import React, { useEffect, useState } from "react";
import { Container, Icon, Stack, TextField, Typography } from "@mui/material";

import Navbar from "../Navbar";
import Toast from "../Toast";
import FormGeneral from "./FormGeneral";
import ListsBingos from "../ListsBingos";
import CustomTabs from "@components/Tabs/CustomTabs";

import { useMobileServices } from "@hooks/MobileDiaD/MobileServices";
import { ORIGEN_CAPTURA, TIPO_BINGO_COMPROMISO_UNICO, VOTO, NO_VOTO } from "@data/constants/MobileDiaD";

const ScreenBingo = ({ setTypeScreen }) => {
  const { loading, loadingGet, snackbar, setSnackbar, getListings, setBingo } = useMobileServices();

  const [values, setValues] = useState({
    idCasilla: "",
    seccion: "",
    idTipoPropietario: "",
  });
  const [flagStorage, setFlagStorage] = useState(false);
  const [valueSearch, setValueSearch] = useState("");
  const [bingoListsVoto, setBingoListsVoto] = useState([]);
  const [bingoListsNoVoto, setBingoListsNoVoto] = useState([]);
  // const [dataGeneral, setDataGeneral] = useState([]);
  const [selectTab, setSelectTab] = useState("0");

  const handleGetBingoList = async () => {
    const response = await getListings({
      idCasilla: values.idCasilla,
      Seccion: values.seccion,
    });

    if (response) {
      const { data } = response;
      const voto = data.filter((item) => item.idEstatus === VOTO);
      const noVoto = data.filter((item) => item.idEstatus === NO_VOTO);

      setBingoListsVoto(voto);
      setBingoListsNoVoto(noVoto);
      //setDataGeneral(data);
    } else {
      setBingoListsNoVoto([]);
      setBingoListsVoto([]);
    }
  };

  const handleSearch = (e) => {
    const { value } = e.target;
    setValueSearch(value.toUpperCase());
  };
  const data = selectTab === "0" ? bingoListsVoto : bingoListsNoVoto;

  const filteredData = data.filter((item) =>
    item.NombreCompleto.toLowerCase().includes(valueSearch.toLowerCase())
  );
  const total = filteredData.length;

  const handleSetBingo = async (idCompromisoUnico, NoEncontrado = null) => {
    let params = {
      idTipoBingo: TIPO_BINGO_COMPROMISO_UNICO,
      Seccion: values.seccion,
      idCasilla: values.idCasilla,
      idCompromisoUnico,
      OrigenCaptura: ORIGEN_CAPTURA,
    };

    if (NoEncontrado !== null) {
      params = {
        idTipoBingo: TIPO_BINGO_COMPROMISO_UNICO,
        Seccion: values.seccion,
        idCasilla: values.idCasilla,
        Nombre: idCompromisoUnico,
        OrigenCaptura: ORIGEN_CAPTURA,
      };
    }

    const results = await setBingo(params);

    if (results) {
      //handleGetBingoList();
      const registered = bingoListsVoto.find((item) => item.idCompromisoUnico === idCompromisoUnico);
      const newEarrings = bingoListsVoto.filter((item) => item.idCompromisoUnico !== idCompromisoUnico);

      setBingoListsVoto(newEarrings);
      setBingoListsNoVoto((prevState) => [...prevState, registered]);
      setValueSearch("");
      setFlagStorage(true);
    }
  };

  useEffect(() => {
    if (values.idCasilla) handleGetBingoList();
    // eslint-disable-next-line
  }, [values.idCasilla]);

  return (
    <>
      <Toast
        open={snackbar.show}
        message={snackbar.message}
        setOpen={setSnackbar}
        severity={snackbar.severity}
      />

      <Navbar isScreen screenBack="general" title="Bingos" setTypeScreen={setTypeScreen} color="black" />

      <Container sx={{ mt: 4 }}>
        <Stack direction={"column"} spacing={2}>
          <FormGeneral
            values={values}
            flagStorage={flagStorage}
            setValues={setValues}
            showFuncionario={false}
          />

          <TextField
            label="Buscar"
            variant="outlined"
            value={valueSearch}
            onChange={handleSearch}
            disabled={loadingGet}
            InputProps={{
              endAdornment: <Icon>search</Icon>,
            }}
          />

          {filteredData.length > 0 && (
            <Typography variant="bosy2" marginBottom={2}>
              Para realizar una captura, toca el nombre de la persona en el listado.
            </Typography>
          )}

          <CustomTabs
            value={selectTab}
            setValue={setSelectTab}
            tabs={[
              {
                value: "0",
                label: "PENDIENTES",
                component: loadingGet ? (
                  <Typography textAlign="center" variant="body2">
                    Cargando...
                  </Typography>
                ) : (
                  <ListsBingos
                    data={filteredData}
                    total={total}
                    handleClick={handleSetBingo}
                    disabled={loading}
                    search={valueSearch}
                  />
                ),
              },
              {
                value: "1",
                label: "CAPTURADOS",
                component: loadingGet ? (
                  <Typography textAlign="center" variant="body2">
                    Cargando...
                  </Typography>
                ) : (
                  <ListsBingos data={filteredData} total={total} />
                ),
              },
            ]}
          />
        </Stack>
      </Container>
    </>
  );
};

export default ScreenBingo;
