export const DayScheduleInterface = {
    idDia: null, AccesoTotal: 0, HoraInicio: '', HoraCierre: ''
}

export const WeekScheduleInterface = {
    idTipoAcceso: 1,
    idUsuario: null,
    Horarios: [
        { idDia: 1, AccesoTotal: 0, HoraInicio: '', HoraCierre: '' },
        { idDia: 2, AccesoTotal: 0, HoraInicio: '', HoraCierre: '' },
        { idDia: 3, AccesoTotal: 0, HoraInicio: '', HoraCierre: '' },
        { idDia: 4, AccesoTotal: 0, HoraInicio: '', HoraCierre: '' },
        { idDia: 5, AccesoTotal: 0, HoraInicio: '', HoraCierre: '' },
        { idDia: 6, AccesoTotal: 0, HoraInicio: '', HoraCierre: '' },
        { idDia: 7, AccesoTotal: 0, HoraInicio: '', HoraCierre: '' },
    ]
};