import React, { useEffect, useState } from "react";
import { Container, Icon, Stack, TextField, Typography } from "@mui/material";

import Navbar from "../Navbar";
import Toast from "../Toast";
import ListsBingos from "../ListsBingos";
import CustomTabs from "@components/Tabs/CustomTabs";

import { getVars } from "@utils/global";
import { useMobileServices } from "@hooks/MobileDiaD/MobileServices";
import {
  ORIGEN_CAPTURA,
  TIPO_BINGO_COMPROMISO_UNICO,
  VOTO,
  NO_VOTO,
} from "@data/constants/MobileDiaD";

const ScreenBingo = ({ setTypeScreen }) => {
  const {
    casilla_rc: {
      Casillas: { NombreCasilla, Seccion, idCasilla },
      Perfil: { CalidadRepresentante },
    },
  } = getVars("Token");

  const { loading, loadingGet, snackbar, setSnackbar, getListings, setBingo } =
    useMobileServices();

  const [valueSearch, setValueSearch] = useState("");
  const [bingoListsVoto, setBingoListsVoto] = useState([]);
  const [bingoListsNoVoto, setBingoListsNoVoto] = useState([]);
  //const [dataGeneral, setDataGeneral] = useState([]);
  const [selectTab, setSelectTab] = useState("0");

  const handleGetBingoList = async () => {
    const response = await getListings({
      idCasilla,
      Seccion,
    });

    if (response) {
      const { data } = response;
      const voto = data.filter((item) => item.idEstatus === VOTO);
      const noVoto = data.filter((item) => item.idEstatus === NO_VOTO);

      setBingoListsVoto(voto);
      setBingoListsNoVoto(noVoto);
     // setDataGeneral(data);
    }
  };

  const handleSearch = (e) => {
    const { value } = e.target;
    setValueSearch(value.toUpperCase());
  };
  const data = selectTab === "0" ? bingoListsVoto : bingoListsNoVoto;

  const filteredData = data.filter((item) =>
    item.NombreCompleto.toLowerCase().includes(valueSearch.toLowerCase())
  );
  const total = filteredData.length;

  const handleSetBingo = async (idCompromisoUnico) => {
    const params = {
      idTipoBingo: TIPO_BINGO_COMPROMISO_UNICO,
      Seccion,
      idCasilla,
      idCompromisoUnico,
      OrigenCaptura: ORIGEN_CAPTURA,
    };

    const results = await setBingo(params);

    if (results) {
      //handleGetBingoList();
      const registered = bingoListsVoto.find(
        (item) => item.idCompromisoUnico === idCompromisoUnico
      );
      const newEarrings = bingoListsVoto.filter(
        (item) => item.idCompromisoUnico !== idCompromisoUnico
      );

      setBingoListsVoto(newEarrings);
      setBingoListsNoVoto((prevState) => [...prevState, registered]);
      setValueSearch("");
    }
  };

  useEffect(() => {
    if (idCasilla) handleGetBingoList();
  }, []);

  return (
    <>
      <Toast
        open={snackbar.show}
        message={snackbar.message}
        setOpen={setSnackbar}
        severity={snackbar.severity}
      />

      <Navbar
        isScreen
        screenBack={"rcs"}
        title={"Bingos"}
        setTypeScreen={setTypeScreen}
        color="black"
      />

      <Container sx={{ mt: 4 }}>
        <Stack direction={"column"}>
          <Typography variant="body2">Sección</Typography>
          <Typography variant="subtitle1" fontWeight={600}>
            {Seccion}
          </Typography>
          <Typography variant="body2">Casilla</Typography>
          <Typography variant="subtitle1" fontWeight={600}>
            {NombreCasilla} {CalidadRepresentante}
          </Typography>

          <TextField
            label="Buscar"
            variant="outlined"
            value={valueSearch}
            onChange={handleSearch}
            disabled={loadingGet}
            InputProps={{
              endAdornment: <Icon>search</Icon>,
            }}
            sx={{ mt: 2, mb: 2 }}
          />
        </Stack>

        {filteredData.length > 0 && (
          <Typography variant="bosy2" marginBottom={2}>
            Para realizar una captura, toca el nombre de la persona en el
            listado.
          </Typography>
        )}

        <CustomTabs
          value={selectTab}
          setValue={setSelectTab}
          tabs={[
            {
              value: "0",
              label: "PENDIENTES",
              component: (
                <>
                  {loadingGet ? (
                    <Typography textAlign={"center"} variant="body2">
                      Cargando...
                    </Typography>
                  ) : (
                    <ListsBingos
                      data={filteredData}
                      total={total}
                      handleClick={handleSetBingo}
                      disabled={loading}
                    />
                  )}
                </>
              ),
            },
            {
              value: "1",
              label: "CAPTURADOS",
              component: <ListsBingos data={filteredData} total={total} />,
            },
          ]}
        />
      </Container>
    </>
  );
};

export default ScreenBingo;
