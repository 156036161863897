import { ProtectedRoute } from "@middlewares";
import OperationCasilla from "@pages/Sije/OperationCasilla";
import StartCapture from "@pages/Sije/StartCapture";
import CloseCapture from "@pages/Sije/CloseCapture";
import Incidents from "@pages/Sije/Incidents";
import Reports from "@pages/Sije/Reports";
import Directory from "@pages/Sije/Directory";
import Nacional from "@pages/Sije/Nacional"

const SijeRoutes = [
  {
    path: "/casilla-operacion",
    element: (
      <ProtectedRoute>
        <OperationCasilla />
      </ProtectedRoute>
    ),
  },
  {
    path: "/sije-inicio",
    element: (
      <ProtectedRoute>
        <StartCapture />
      </ProtectedRoute>
    ),
  },
  {
    path: "/sije-cierre",
    element: (
      <ProtectedRoute>
        <CloseCapture />
      </ProtectedRoute>
    ),
  },
  {
    path: "/incidencias",
    element: (
      <ProtectedRoute>
        <Incidents />
      </ProtectedRoute>
    ),
  },
  {
    path: "/sije-reportes",
    element: (
      <ProtectedRoute>
        <Reports />
      </ProtectedRoute>
    ),
  },
  {
    path: "/sije-directorio",
    element: (
      <ProtectedRoute>
        <Directory />
      </ProtectedRoute>
    ),
  },
  {
    path: "/sije-nacional",
    element: (
      <ProtectedRoute>
        <Nacional />
      </ProtectedRoute>
    ),
  },
];
export default SijeRoutes;
