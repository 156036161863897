import {
  Box,
  Card,
  CardMedia,
  Icon,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import { useEffect, useRef, useState } from "react";

import Webcam from "react-webcam";

const WebCam = ({ image, label = "Tomate una selfie", setImage }) => {
  const webcamRef = useRef(null);

  const [typeCamara, setTypeCamara] = useState({
    width: 380,
    height: 250,
    facingMode: "user",
  });
  const [open, setOpen] = useState(false);

  const capture = () => {
    const imageSrc = webcamRef.current.getScreenshot();
    setImage(imageSrc);
  };

  const handleClose = () => {
    setOpen(false);
    setImage(null);
  };

  const handleFlipCamera = () => {
    setTypeCamara({
      ...typeCamara,
      facingMode: typeCamara.facingMode === "user" ? "environment" : "user",
    });
  };

  useEffect(() => {
    if (image === null) setOpen(false);
  }, [image]);

  return (
    <Box marginBottom={2}>
      <Typography variant="body2">{label}</Typography>
      {!open ? (
        <>
          <IconButton onClick={() => setOpen(true)} size="large">
            <Icon>photo_camera</Icon>
          </IconButton>
        </>
      ) : (
        <Box
          component={Card}
          variant="outlined"
          padding={1}
          display={"flex"}
          flexDirection={"column"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          {image ? (
            <>
              <CardMedia component={"img"} image={image} alt="webcam" />
              <IconButton onClick={handleClose}>
                <Icon>close</Icon>
              </IconButton>
            </>
          ) : (
            <>
              <Webcam
                forceScreenshotSourceSize
                audio={false}
                ref={webcamRef}
                videoConstraints={typeCamara}
              />
              <Stack direction={"row"} spacing={1}>
                <IconButton onClick={handleFlipCamera}>
                  <Icon>flip_camera_android</Icon>
                </IconButton>
                <IconButton onClick={capture}>
                  <Icon>camera</Icon>
                </IconButton>
                <IconButton onClick={() => setOpen(false)}>
                  <Icon>close</Icon>
                </IconButton>
              </Stack>
            </>
          )}
        </Box>
      )}
    </Box>
  );
};

export default WebCam;
