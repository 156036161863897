import { useState, useEffect, useCallback } from "react";
import { Link } from "react-router-dom";
import UserForm from "../../components/Compromisos/UserForm";
import PersonalInformationForm from "@components/Compromisos/PersonalInformationForm";
import ContactInformationForm from "@components/Compromisos/ContactInformationForm";
import AddressForm from "@components/Compromisos/AddressForm";
import VolunteerForm from "@components/Compromisos/VolunteerForm";
import CatalogServices from "@services/CatalogServices";
import Swal from "sweetalert2";
import moment from "moment";
import UserServices from "@services/UserServices";
import LoadingForm from "@components/LinearProgress/LoadingForm";
import { RECAPTCHA_SITE_KEY } from "@utils/global";
import LoadingButton from "@mui/lab/LoadingButton";
import PersonSearchIcon from "@mui/icons-material/PersonSearch";
import { limpiarTelefono } from "../../utils/global";
import cat_entidad from "@data/json/cat_entidad.json";
import { URL_PUBLICA_FRONT } from "@utils/global";

import {
  VIGENCIA_INE,
  VOL_RC,
  VOL_RG,
  VOL_OBSERVER,
  VOL_FiL,
} from "@data/constants";
import { validaINE, handleAttrs } from "@utils/Utilities";
import { isEmptyOrNullObject } from "@utils/validations";
import BasicSelect from "@components/Selects/BasicSelect";
import { useNavigate } from "react-router-dom";

// material-ui
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormHelperText,
  Grid,
  TextField,
  Typography,
  Stack,
  Chip,
  Icon,
  IconButton,
  Tooltip,
  CircularProgress,
} from "@mui/material";

//formik
import { useFormik } from "formik";
import { CompromisoAddInterfacePublicRegister } from "@data/interfaces/CompromisoInterfaces";
import { CompromisoAddPublicSchema } from "@data/schemas/CompromisoSchemas";

import {
  GoogleReCaptchaProvider,
  useGoogleReCaptcha,
} from "react-google-recaptcha-v3";

const AuthRegister = ({ type = 1 }) => {
  const { executeRecaptcha } = useGoogleReCaptcha();
  let navigate = useNavigate();
  const catalogsOptions = [
    { id: "entidades" },
    { id: "municipios" },
    { id: "voluntarios" },
    { id: "tipo_vialidad" },
    { id: "mis_segmentos" },
    { id: "partidos" },
    { id: "medios_contacto" },
    { id: "generos" },
    { id: "partidos", filtered:[{id:"id", value:[1,2,3], filter:"IN"}] },
  ];
  const [load, setLoad] = useState(true);
  const [celular, setCelular] = useState("");
  const [loadingCel, setLoadingCel] = useState(false);
  const [userInfo, setUserInfo] = useState({});
  const formik = useFormik({
    initialValues: CompromisoAddInterfacePublicRegister,
    validationSchema: CompromisoAddPublicSchema,
    onSubmit: (values) => {
      onChangeCaptcha(handleAttrs(values));
    },
  });
  const [scan, setScan] = useState(false);

  const [isLoadigLocation, setIsLoadingLocation] = useState(false);
  const [isLoadigForm, setIsLoadingForm] = useState(false);
  const [openLinearProgress, setOpenLinearProgress] = useState(false);
  const [isSuccessFormSave, setIsSuccessFormSave] = useState(false);
  const [flagForm, setFlagForm] = useState(false);
  const [catalogsFiltered, setCatalogFiltered] = useState(() => {
    let newObject = {};
    for (const item of catalogsOptions) {
      newObject[item.id] = [];
    }
    return newObject;
  });

  const getLocation = () => {
    setIsLoadingLocation(true);
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          getEstado({ Latitud: latitude, Longitud: longitude });
        },
        (error) => {
          console.error("Error al obtener la ubicación:", error);
        }
      );
    } else {
      console.error("El navegador no soporta la geolocalización");
    }
  };

  useEffect(() => {
    clear();
    formik.handleChange({
      target: {
        name: "isUsuario",
        value: 1,
      },
    });
    formik.handleChange({
      target: {
        name: "idUsuarioPropietario",
        value: 1,
      },
    });
    formik.handleChange({
      target: {
        name: "idEstructuraSocial",
        value: 1,
      },
    });
    // eslint-disable-next-line
  }, [type]);

  const getEstado = (location) => {
    UserServices.getLocationApi(location)
      .then((response) => {
        if (response.data === null) {
          formik.handleChange({
            target: {
              name: "idEstado",
              value: 0,
            },
          });
        }

        formik.handleChange({
          target: {
            name: "idEstado",
            value: response.response.data.entidad,
          },
        });

        getScanValidate(parseInt(response.response.data.entidad));
      })
      .catch((error) =>
        formik.handleChange({
          target: {
            name: "idEstado",
            value: 0,
          },
        })
      )
      .finally(() => setIsLoadingLocation(false));
  };

  const getScanValidate = (id) => {
    UserServices.getScanPermission(id)
    .then((res) => {
      if (res.results) {
       if(res.response.permiso && res.response.credito){
        setScan(true);
       }else{
        setScan(false);
       }
      } else {
        setScan(false);
      }
    })
    .catch((error) =>
      Swal.fire({
        title: error.message,
        icon: "warning",
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false,
      })
    );
  };

  useEffect(() => {
    if (type === 2) {
      setFlagForm(true);
    } else {
      formik.handleChange({
        target: {
          name: "idUsuarioPropietario",
          value: 1,
        },
      });
      setFlagForm(false);
    }

    // eslint-disable-next-line
  }, [type]);

  useEffect(() => {
    if (formik.values.idEstado !== 0) {
      setLoad(true);
      getCatalogs();
      clear();
    }
    // eslint-disable-next-line
  }, [formik.values.idEstado]);

  const getCatalogs = () => {
    CatalogServices.getCatalogsPublicEvents({
      catalogs: catalogsOptions,
      idEstado: formik.values.idEstado,
    })
      .then((res) => {
        if (res.results) {
          setCatalogFiltered(() => {
            let newObject = {};
            for (const item of catalogsOptions) {
              newObject[item.id] = res.response.catalogs[item.id];
            }
            return newObject;
          });
        } else {
          Swal.fire({ title: res.message, icon: "warning" });
        }
      })
      .catch((error) => Swal.fire({ title: error.message, icon: "warning" }))
      .finally(() => setLoad(false));
  };

  const onChangeCaptcha = useCallback(
    (values) => {
      if (!executeRecaptcha) {
        return;
      }

      executeRecaptcha("invito")
        .then((token) => {
          const data = {
            ...values,
            token: token,
          };
          handleVerification(handleAttrs(data));
        })
        .catch((error) => Swal.fire({ title: error, icon: "warning" }));
    },
    // eslint-disable-next-line
    [executeRecaptcha]
  );

  const handleVerification = (values) => {
    if (!isEmptyOrNullObject(values)) {
      const res = validaINE(
        values.INE,
        values.Nombre,
        values.Paterno,
        values.Materno,
        values.idSexo,
        values.FechaNacimiento,
        values.idEstadoNacimiento
      );

      if (!res.results) {
        Swal.fire({
          title: `¡Atención!`,
          text: res.message,
          icon: "info",
          reverseButtons: true,
          showCancelButton: false,
          confirmButtonText: "Ok",
        });
      } else {
        if (
          values.EsVoluntario === 1 &&
          [VOL_RC, VOL_RG, VOL_OBSERVER, VOL_FiL].includes(
            values.idVoluntario
          ) &&
          values.VigenciaINE < VIGENCIA_INE
        ) {
          Swal.fire({
            title: `¡Vigencia INE!`,
            text: "Invite a la persona a renovar su credencial de elector, ya que no será válida como medio de identificación en el siguiente proceso",
            icon: "info",
            reverseButtons: true,
            showCancelButton: false,
            confirmButtonText: "Continuar",
            allowOutsideClick: false,
            allowEscapeKey: false,
          }).then((res) => {
            if (res.isConfirmed) {
              handleSave(values);
            }
          });
        } else {
          handleSave(values);
        }
      }
    }
  };
  const handleSave = (values) => {
    setIsLoadingForm(true);
    setOpenLinearProgress(true);
    setIsSuccessFormSave(false);
    let data = {};
    Object.keys(CompromisoAddInterfacePublicRegister).forEach((key) => {
      data[key] =
        values[key] !== null
          ? values[key]
          : CompromisoAddInterfacePublicRegister[key];
    });

    data = {
      ...data,
      VigenciaINE: values.VigenciaINE ? parseInt(values.VigenciaINE) : null,
      INE: values.INE.toUpperCase(),
      Nombre: values.Nombre.toUpperCase(),
      Paterno: values.Paterno.toUpperCase(),
      Materno: values.Materno.toUpperCase(),
      FechaNacimiento: moment(values.FechaNacimiento).format("YYYY-MM-DD"),
      CalleVive: `${values.TipoVialidad} ${values.CalleVive}`,
      idTipoAfiliacion:data.idAfiliacion,
      idPartido : data.idAfiliacion === 1 ? 0 : data.idPartido
    };
    delete data.TipoVialidad;
    delete data.isUsuario;
    data = { ...data, Username: values.Celular, medioConfirmacion: "sms" };
    delete data.idMedioContacto;
    delete data.Segmentos;
    delete data.idAfiliacion;
    if (![VOL_RC, VOL_RG, VOL_OBSERVER].includes(data.idVoluntario)) {
      delete data.TieneAuto;
      delete data.TieneExperiencia;
      delete data.AceptaCuidarOtra;
    }

    delete data.idEvento;
   // delete data.token;
    delete data.AceptaTerminos;
    delete data.idUsuario;
    delete data.DeviceID;

    UserServices.createUserPublic({
      ...data,
    })
      .then((res) => {
        if (res.success && res.results) {
          Swal.fire({
            title: res.message,
            icon: "success",
            input: "text",
            inputAttributes: {
              autocapitalize: "off",
            },
            showCancelButton: true,
            confirmButtonText: "Confirmar",
            showLoaderOnConfirm: true,
            preConfirm: (code) => {
              if (code === res.response.codigo) {
                setIsLoadingForm(true);
                setOpenLinearProgress(true);
                setIsSuccessFormSave(true);
                UserServices.codeValidate({
                  code: code,
                  c: res.response.UUID,
                  idEstado: data.idEstado,
                })
                  .then((respuesta) => {
                    if (respuesta.success) {
                      Swal.fire({
                        title: `Usuario Validado`,
                        icon: "success",
                        html:
                          "<b>Puede Ingrese en </b>, " +
                          `<a href="${URL_PUBLICA_FRONT}" >Es por México</a> `,
                        preConfirm:(code) => {
                          navigate("/login")
                        }
                      });
                      clear();
                      setIsLoadingForm(false);
                      setOpenLinearProgress(false);
                      setIsSuccessFormSave(true);
                    } else {
                      setIsLoadingForm(false);
                      setOpenLinearProgress(false);
                      setIsSuccessFormSave(true);
                      Swal.fire({
                        title:
                          "Error en la validación. contacte al administrador",
                        icon: "error",
                      });

                    }
                  })
                  .catch((e) => {
                    setIsLoadingForm(false);
                    setOpenLinearProgress(false);
                    setIsSuccessFormSave(true);
                    Swal.showValidationMessage(
                      `Error en la validación. contacte al administrador.`
                    );
                  });
              } else {
                setIsLoadingForm(false);
                setOpenLinearProgress(false);
                setIsSuccessFormSave(true);
                Swal.showValidationMessage(`El codígo no coincide`);
              }
            },
            allowOutsideClick: () => !Swal.isLoading(),
          });
        } else {
          Swal.fire({ title: res.message, icon: "warning" });
        }
      })
      .catch((e) => {
        Swal.fire({ title: e.message, icon: "warning" });
      })
      .finally(() => {
        setIsLoadingForm(false);
        setOpenLinearProgress(false);
        setIsSuccessFormSave(true);
      });
  };

  const clear = () => {
    formik.setValues({
      ...formik.values,
      idEstado:formik.values.idEstado,
      Password: "",
      NombreOSC: null,
      Confirm: "",
      PIN: "",
      idVoluntario: "",
      INE: "",
      VigenciaINE: "",
      Nombre: "",
      Paterno: "",
      Materno: "",
      FechaNacimiento: "",
      idSexo: "",
      idEstadoNacimiento: "",
      CalleVive: "",
      TipoVialidad: "C",
      NumExtVive: "",
      NumIntVive: "",
      idMunicipioVive: "",
      idLocalidadVive: "",
      ColoniaVive: "",
      CPVive: "",
      SeccionVota: "",
      Celular: "",
      Telefono: "",
      TelMensajes: "",
      Correo: "",
      Facebook: "",
      Instagram: "",
      Twitter: "",
      TikTok: "",
      EsVoluntario: 1,
      Segmentos: [],
      TieneAuto: "",
      TieneExperiencia: "",
      AceptaCuidarOtra: "",
      AceptaTerminos:false
    });
  };

  const searchCode = () => {
    setLoadingCel(true);

    if (celular.length < 10) {
      Swal.fire({
        title: "Clave incorrecta",
        text: "Es necesario el número de celular a 10 dígitos de la persona que te invito",
        icon: "warning",
      });
      setLoadingCel(false);
      return true;
    }
    setUserInfo({
      ...userInfo,
      idUsuarioPropietario: { id: null, Nombre: "" },
    });

    UserServices.searchUsers({
      idEstado: formik.values.idEstado,
      Username: celular,
    })
      .then((responseRS) => {
        if (responseRS.results) {
          Swal.fire({
            title: `Invitado por ${responseRS.response.Nombre}`,
            icon: "success",
          });

          formik.setValues({
            ...formik.values,
            idPartido:responseRS.response.idPartido,
            idUsuarioPropietario: responseRS.response.id,
            idAfiliacion:responseRS.response.idTipoAfiliacion,
            NombreOSC: responseRS.idTipoAfiliacion === 2 ? responseRS.NombreOSC : null
          })
          setUserInfo({
            ...userInfo,
            idUsuarioPropietario: responseRS.response.id,
          });
       
          setFlagForm(true);
        } else {
          Swal.fire({
            title: "Error en el registro",
            text: responseRS.message,
            icon: "error",
          });
          formik.handleChange({
            target: {
              name: "idUsuarioPropietario",
              value: null,
            },
          });
          return true;
        }
      })
      .catch((e) => {
        Swal.fire({
          title: "Error en el registro",
          text: e.message,
          icon: "error",
        });
        formik.handleChange({
          target: {
            name: "idUsuarioPropietario",
            value: null,
          },
        });
        return true;
      })
      .finally((end) => {
        setLoadingCel(false);
      });
  };

  const handleChangeEstado = (e) => {
   
    formik.handleChange({
      target:{
        name:"idEstado",
        value:e.target.value
      }
    });

    getScanValidate(parseInt(e.target.value));
 
    //clear();
  };

  const handleSummit = (e) => {


    if(Object.values(formik.errors).length > 0){
      Swal.fire({
        icon: "error",
        title: "Revisar",
        html: `<ul>${Object.values(formik.errors).map(item => `<li>${item}</li>`).join('')}</ul>`,
      });
      return true;
    }

    formik.submitForm(formik.values)
  };


  console.log(formik.values);

  return (
    <>
   
      <LoadingForm
        loadinMessage={"Cargando datos..."}
        successMessage="¡Cargado con exito!"
        isLoading={isLoadigForm}
        success={isSuccessFormSave}
        isOpen={openLinearProgress}
        setIsOpen={() => setOpenLinearProgress(!openLinearProgress)}
      />
      <Grid container direction="column" justifyContent="center" spacing={2}>
        <Grid
          item
          xs={12}
          container
          alignItems="center"
          justifyContent="center"
        >
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Chip icon={<Icon>person_pin_circle</Icon>} label="Ubicación" />
          </Grid>
          <Grid item xs={10} sm={10} md={10} lg={7}>
            <BasicSelect
              name="idEstado"
              label="Estado *"
              options={cat_entidad.data}
              value={formik.values.idEstado}
              onChange={handleChangeEstado}
              isLoading={false}
              sx={{ width: "100%", mt: 3 }}
            />
            {formik.errors.idEstado && formik.touched.idEstado && (
              <FormHelperText error>Seleccione un estado</FormHelperText>
            )}
          </Grid>
          <Grid item xs={2} sm={2} md={2} lg={1}>
            <Tooltip title="Obtener Estado">
              {isLoadigLocation ? (
                <CircularProgress size={"2rem"} sx={{ m: 2, mt: 5 }} />
              ) : (
                <IconButton
                  aria-label="location"
                  sx={{ m: 1, mt: 2 }}
                  color="warning"
                  size="large"
                  onClick={getLocation}
                >
                  <Icon>person_pin_circle</Icon>
                </IconButton>
              )}
            </Tooltip>
          </Grid>
        </Grid>

        <Grid
          item
          xs={12}
          container
          alignItems="center"
          justifyContent="center"
        >
          {type === 1 && (
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Chip icon={<Icon>dialpad</Icon>} label="Código de Invitado" />
            </Grid>
          )}
          {type === 1 && (
            <Grid item xs={12} sm={12} md={12} lg={8}>
              <Stack direction="row" spacing={0} sx={{ mt: 2 }}>
                <TextField
                  label="Celular"
                  size="small"
                  name="phone"
                  value={celular}
                  onChange={(e) => {
                    setCelular(limpiarTelefono(e.target.value));
                  }}
                  //onKeyUp={handleOnKeyPress}
                  sx={{ width: "100%" }}
                  variant="outlined"
                  className="fixed-input"
                />

                <LoadingButton
                  loading={loadingCel}
                  loadingPosition="start"
                  startIcon={<PersonSearchIcon />}
                  sx={{ width: "50%" }}
                  variant="outlined"
                  size="small"
                  onClick={searchCode}
                >
                  Buscar
                </LoadingButton>
              </Stack>
            </Grid>
          )}
        </Grid>
      </Grid>
      {flagForm && (
        <>
          <UserForm
            catalogs={catalogsFiltered}
            loadigCatalogs={load}
            errors={formik.errors}
            touched={formik.touched}
            values={formik.values}
            setValues={formik.setValues}
            handleChange={formik.handleChange}
            TypeAfiliacion={type === 1 ? false : true}
          />

          <PersonalInformationForm
           idEstado = {formik.values.idEstado}
            NotSimple={true}
            catalogs={catalogsFiltered}
            loadigCatalogs={load}
            errors={formik.errors}
            touched={formik.touched}
            values={formik.values}
            ScanIne={scan}
            setValues={formik.setValues}
            handleChange={formik.handleChange}
            validar={false}
          />
          <ContactInformationForm
            redes={true}
            errors={formik.errors}
            touched={formik.touched}
            values={formik.values}
            handleChange={formik.handleChange}
          />
          <AddressForm
            catalogs={catalogsFiltered}
            loadigCatalogs={load}
            errors={formik.errors}
            touched={formik.touched}
            values={formik.values}
            setValues={formik.setValues}
            handleChange={formik.handleChange}
            movile={true}
          />

          <VolunteerForm
            medioContacto={false}
            catalogs={catalogsFiltered}
            loadigCatalogs={load}
            errors={formik.errors}
            touched={formik.touched}
            values={formik.values}
            setValues={formik.setValues}
            handleChange={formik.handleChange}
            question={false}
          />

          <Grid container alignItems="center" justifyContent="space-between">
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={formik.AceptaTerminos}
                    onChange={(event) => formik.handleChange(event)}
                    name="AceptaTerminos"
                    color="primary"
                  />
                }
                label={
                  <Typography variant="subtitle1">
                    Acepta los &nbsp;
                    <Typography
                      variant="subtitle1"
                      component={Link}
                      to="https://espormexico.com/privacidad"
                      target="_blank"
                    >
                      Términos y Condiciones.
                    </Typography>
                  </Typography>
                }
              />
            </Grid>
          </Grid>
          {formik.errors.AceptaTerminos && (
            <Box sx={{ mt: 3 }}>
              <FormHelperText error>
                Es necesario aceptar los términos y condiciones para poder
                registrarse.
              </FormHelperText>
            </Box>
          )}

          <Box sx={{ mt: 2 }}>
            <LoadingButton
              disableElevation
              // disabled={isSubmitting}
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              color="success"
              onClick={handleSummit}
              disabled={isLoadigForm}	
              loading={isLoadigForm}
            >
              Registrarme
            </LoadingButton>
          </Box>
        </>
      )}
    </>
  );
};

const recaptcha = ({ type = 1 }) => {
  return (
    <GoogleReCaptchaProvider reCaptchaKey={RECAPTCHA_SITE_KEY}>
      <AuthRegister type={type} />
    </GoogleReCaptchaProvider>
  );
};
export default recaptcha;
