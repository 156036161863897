import React from "react";

import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";

const MultiAutocomplete = ({
  value,
  onChange,
  limitTags = 2,
  options,
  size = "small",
  placeholder = "",
  label = "",
  disabled = false,
}) => {
  return (
    <Autocomplete
      multiple
      limitTags={limitTags}
      id="multiple-limit-tags"
      size={size}
      value={value}
      options={options}
      disabled={disabled}
      //getOptionLabel={(option) => option.label}
      onChange={(e, newValue) => onChange(e, newValue)}
      getOptionLabel={(option) => {
        if (typeof option === "number") {
          return options.find((item) => item.value === option)?.label;
        } else {
          return option.label;
        }
      }}
      isOptionEqualToValue={(option, value) => option.value === value}
      filterSelectedOptions
      renderInput={(params) => (
        <TextField {...params} label={label} placeholder={placeholder} />
      )}
    />
  );
};

export default MultiAutocomplete;
