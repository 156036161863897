import { useState, useEffect } from "react";

// Material UI
import { Grid, Button, Stack, Chip, Icon, TextField, Paper, styled } from "@mui/material";
import { ScatterPlot } from "@mui/icons-material";

// Utils
import { isEmptyOrInvalidString } from "@utils/validations";
import { getVars, generateRandomString } from "@utils/global";

const style_b = { color: "#000000" };

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const Create = (props) => {
  const { formik, errors, touched, values, handleChange, selectedSections, selectedSectionsProperties } = props;
  const [properties, setProperties] = useState({
    lnom: 0,
    tipo2: 0,
    tipo3: 0,
    tipo4: 0,
  });

  useEffect(() => {
    let lnom = 0;
    let tipo2 = 0;
    let tipo3 = 0;
    let tipo4 = 0;

    selectedSectionsProperties.forEach((element) => {
      lnom = lnom + element.Lnom;
      if (element.TipoSeccion === "2") {
        tipo2 = tipo2 + 1;
      }
      if (element.TipoSeccion === "3") {
        tipo3 = tipo3 + 1;
      }
      if (element.TipoSeccion === "4") {
        tipo4 = tipo4 + 1;
      }
    });
    setProperties({ ...properties, lnom, tipo2, tipo3, tipo4 });
    // eslint-disable-next-line
  }, [selectedSections, selectedSectionsProperties]);

  return (
    <>
      <Grid container rowSpacing={1}>
        <Grid item xs={12}>
          <Item>
            <b style={style_b}>
              <small>LNOM:</small>
            </b>
            <b>
              <small>{properties.lnom}</small>
            </b>
            <br />
            <b style={style_b}>
              <small>T2:</small>
            </b>
            <b>
              <small>{properties.tipo2}</small>
            </b>
            <br />
            <b style={style_b}>
              <small>T3:</small>
            </b>
            <b>
              <small>{properties.tipo3}</small>
            </b>
            <br />
            <b style={style_b}>
              <small>T4:</small>
            </b>
            <b>
              <small>{properties.tipo4}</small>
            </b>
          </Item>
        </Grid>
        <Grid item xs={12}>
          <TextField
            multiline
            error={touched.Description && !isEmptyOrInvalidString(errors.Description)}
            helperText={touched.Description && errors.Description && errors.Description}
            sx={{ width: "100%" }}
            label="Descripción"
            type="text"
            name="Description"
            placeholder="Breve descripción (opcional)"
            onChange={handleChange}
            //onBlur={handleBlur}
            value={values.Description.toUpperCase()}
            variant="outlined"
            size="small"
            className="fixed-input"
          />
        </Grid>
        <Grid item container spacing={0} direction="column" alignItems="center" justifyContent="center">
          <Grid item xs={12}>
            <Chip
              icon={
                <Icon>
                  <ScatterPlot />
                </Icon>
              }
              label={"Secciones seleccionadas: " + selectedSections.length}
            />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          {selectedSections.length !== 0 ? (
            selectedSections.map((item) => {
              return <Chip key={"key_" + item + generateRandomString(5)} label={item} />;
            })
          ) : (
            <></>
          )}
        </Grid>
      </Grid>
      <br></br>
      <Stack direction="row" justifyContent="center" alignItems="center" spacing={2}>
        <Button
          size="small"
          style={{ borderRadius: "20px", alignSelf: "center" }}
          variant="outlined"
          disabled={getVars("flagCreatePolygon", null) && selectedSections.length !== 0 ? false : true}
          onClick={formik.submitForm}
        >
          Guardar
        </Button>
      </Stack>
    </>
  );
};

export default Create;
