import React, { useState, useEffect } from "react";
import { useFormik } from "formik";

import { Box, Divider, TextField, Button, InputAdornment, Grid } from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import Swal from "sweetalert2";
import moment from "moment/moment";

import movilizacion from "@services/MovilizationServices";
import { isEmptyOrInvalidString } from "@utils/validations";
import { getVars } from "@utils/global";
import { isEmptyOrNullObject } from "@utils/validations";
import AdvancedSelect from "@components/Selects/AdvancedSelect";
import BasicSelect from "@components/Selects/BasicSelect";
import { AddBingoIdentificador } from "@data/schemas/MovilizationSchemas";
import { AddBingoInterface } from "@data/interfaces/MovilizationInterfaces";
import { ORIGEN_CAPTURA, TIPO_BINGO_COMPROMISO_UNICO, VOTO, NO_VOTO } from "@data/constants/MobileDiaD";

const BingoForm = (props) => {
  const { getLastInsert, catalogs, loadSeccion, setAlertParams } = props;

  const [loadingCasilla, setLoadingCasilla] = useState(false);
  const [catalogsSecciones, setCatalogsSecciones] = useState([]);
  const [casillasOptions, setCasillasOptions] = useState([]);

  const formik = useFormik({
    initialValues: AddBingoInterface,
    validationSchema: AddBingoIdentificador,
    enableReinitialize: false,
    onSubmit: (values, actions) => {
      handleSendBingo(values, actions);
    },
  });

  const handleSetBingo = (event) => {
    if (event.key === "Enter") {
      formik.handleSubmit();
      event.preventDefault();
    }
  };

  const handleValidateSection = async (section) => {
    try {
      setLoadingCasilla(true);
      const result = await movilizacion.validateSection(section);
      const { results, message, response } = result;

      if (results) {
        setAlertParams({
          type: "success",
          message: message,
          show: true,
        });
        let casillas = response.casillas.map((item) => ({
          value: item.idCasilla,
          label: item.NombreCasilla,
        }));
        setCasillasOptions(casillas);
      } else {
        setAlertParams({
          type: "error",
          message: message,
          show: true,
        });
        formik.resetForm();
      }
    } catch (error) {
      Swal.fire({
        icon: "warning",
        title: error.message,
      });
    } finally {
      setLoadingCasilla(false);
    }
  };

  const handleSendBingo = async (values, actions) => {
    try {
      const { user } = getVars("Token");

      let params = {};

      if (values.FolioBingo === "xx" || values.FolioBingo === "XX") {
        params = {
          idTipoBingo: TIPO_BINGO_COMPROMISO_UNICO,
          Seccion: values.Seccion,
          idCasilla: values.idCasilla,
          Nombre: values.FolioBingo.toUpperCase(),
          OrigenCaptura: ORIGEN_CAPTURA,
        };
      } else {
        params = {
          idTipoBingo: TIPO_BINGO_COMPROMISO_UNICO,
          Seccion: values.Seccion,
          idCasilla: values.idCasilla,
          idCompromisoUnico: parseInt(values.FolioBingo),
          OrigenCaptura: ORIGEN_CAPTURA,
        };
      }
  
      const result = await movilizacion.setParamsBingo(params);
      const { results, message } = result;
      if (results) {
        getLastInsert({
          FolioBingo: values.FolioBingo,
          Seccion: values.Seccion,
          CasillaName: casillasOptions.find((item) => item.value === values.idCasilla)?.label,
          NumHoja: null,
          Hora: moment().format("DD/MM/YYYY HH:mm"),
          Usuario: `${user.Nombre} ${user.Paterno} ${user.Materno}`,
        });
        formik.setValues((prevState) => ({ ...prevState, FolioBingo: "" }));
      } else {
        getLastInsert(null);
        Swal.fire({
          icon: "warning",
          title: message,
        });
      }
    } catch (error) {
      getLastInsert(null);
      Swal.fire({
        icon: "warning",
        title: error.message,
      });
    }
  };

  useEffect(() => {
    if (!isEmptyOrNullObject(catalogs)) {
      setCatalogsSecciones(catalogs?.secciones);
    }
  }, [catalogs]);

  return (
    <Box sx={{ mt: 1, mb: 2 }}>
      <Grid container spacing={1}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <AdvancedSelect
              id="Seccion"
              name="Seccion"
              variant="outlined"
              label="Sección"
              options={catalogsSecciones || []}
              value={formik.values.Seccion}
              onChange={(e) => {
                formik.setValues((prev) => ({ ...prev, Seccion: e.value, idCasilla: "" }));
                handleValidateSection(e.value);
              }}
              error={Boolean(formik.errors.Seccion)}
              helperText={formik.errors.Seccion}
              isLoading={loadSeccion}
              isSearchable
              required
            />
          </Grid>
          <Grid item xs={12}>
            <BasicSelect
              disabled={!formik.values.Seccion || loadingCasilla}
              name="Casilla"
              label="Casilla"
              options={casillasOptions}
              size="small"
              sx={{ width: "100%" }}
              value={formik.values.idCasilla}
              onChange={(e) => {
                formik.setValues((prev) => ({ ...prev, idCasilla: e.target.value, CasillaName: e.target.name }));
              }}
              error={Boolean(formik.errors.idCasilla)}
              errorMessage={formik.errors.idCasilla}
              isLoading={loadingCasilla || loadSeccion}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              id="FolioBingo"
              name="FolioBingo"
              variant="outlined"
              label="Bingo"
              size="small"
              sx={{ width: "100%" }}
              value={formik.values.FolioBingo}
              onKeyDown={handleSetBingo}
              onChange={formik.handleChange}
              error={Boolean(formik.touched.FolioBingo) && Boolean(formik.errors.FolioBingo)}
              helperText={formik.errors.FolioBingo}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start" sx={{ pr: 0, mr: 0, pl: 0, ml: 0 }}>
                    <Divider sx={{ height: 28 }} orientation="vertical" />
                    <Button
                      color="primaryDark"
                      onClick={formik.handleSubmit}
                      disabled={!isEmptyOrInvalidString(formik.errors.FolioBingo) || isEmptyOrInvalidString(formik.values.FolioBingo)}
                      sx={{ mr: 0, pr: 0, ml: 0, pl: 0 }}
                    >
                      <SendIcon sx={{ ml: 0, mr: 0, pr: 0 }} />
                    </Button>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default BingoForm;
