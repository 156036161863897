import { useEffect, useState } from "react";
import Tree from "@components/TreeView/TreeView";
import BasicTable from "@components/Tables/BasicTable";
import CardStats from "@components/Card/CardStats";
import ActionHeader from "@components/Containers/ActionHeader";
import AdminLayout from "@components/MainPage/AdminLayout";
import {
    Card,
    CardContent,
    Container,
    Grid,
    Box,
    Tab,
    Icon,
    Typography,
    Stack,
    Divider,
    TextField,
    Button,
  } from "@mui/material";
  import { TabPanel, TabContext, TabList } from "@mui/lab";
  import SocialService from "@services/SocialServices";
  import Swal from "sweetalert2";
  import { numberWithCommas, /* searchTree */ } from "@utils/Utilities";
  import CompromisoServices from "@services/CompromisoServices";


const SocialResponsible = () => {
    const [loadingTree, setLoadingTree] = useState(false);
    const [loadingCommitments, setLoadingCommitments] = useState(false);
    const [valueTab, setValueTab] = useState("1");
    const [tree, setTree] = useState([]);
    const [nameUser, setNameUser] = useState("");
    const [totalCommitments,setTotalCommitments] = useState({});
    const [commitments, setCommitments] = useState([]);
    const [data,setData] = useState({
      idUsuario: 0,
      page: 0,
      pageSize: 10,
      unique: false,
      nested: false,
      filtered: [],
    });
    const [dataSegmento,setDataSegmento] = useState({
      idUsuario:"",
      idSegmento:""
    });
    const [total,setTotal] = useState(0);

    const columns = [
      { id: "Region", label: "Región", align: 'center' },
      { id: "Municipio", label: "Municipio", align: 'center' },
      { id: "NombreCompleto", label: "Nombre", align: 'center' },
      { id: "SeccionVota", label: "Sección Vota", align: 'center' },
      { id: "EsVoluntario", label: "Voluntario", align: 'center' },
      { id: "CapturadoPor", label: "Capturado Por", align: 'center' },
    ];

    useEffect(()=>{
        getTree();
    },[]);


  useEffect(() => {
    if (data.idUsuario && data.idUsuario !== 0 && valueTab === 2) {
      getUserCommitments(data);
    }
    if (data.idUsuario && data.idUsuario !== 0 && valueTab === 2) {
      getUserCommitments(data);
    }
  }, [data])


   const getTree = () =>{
    setLoadingTree(true);
    SocialService.getResponsables({})
      .then((res) => {
        if (res.results) {
          setTree([res.response]);
          handleItemTree(res.response);
        } else {
          Swal.fire({ title: res.message, icon: "warning" });
        }
      })
      .catch((err) => Swal.fire({ title: err, icon: "warning" }))
      .finally(() => setLoadingTree(false));
  };


  const handleItemTree = (e) => {
    if (e !== null) {
      const {
        idUsuario,
        label,
        TotalCompromisos,
        CompromisosSegmentos,
        CompromisosSegmentosUnicos
      } = e;
   
      setNameUser(label);
      setTotalCommitments({
        ...totalCommitments,
        totalCompromisos: numberWithCommas(TotalCompromisos),
        totalCompromisosSegmentos: numberWithCommas(CompromisosSegmentos),
        totalCompromisosSegmentosUnicos: numberWithCommas(CompromisosSegmentosUnicos),
      });
      setData({ ...data, idUsuario: idUsuario });
    } else {
      setNameUser("");
      setTotalCommitments({
        ...totalCommitments,
        totalUsuarios: 0,
        totalCompromisos: 0,
        totalCompromisosSegmentos: 0,
        totalCompromisosSegmentosUnicos: 0,
      });
    }
  };


  const handleChangeTab = (e,newValue) => {
    setValueTab(newValue);
  };

  const getUserCommitments = (params) => {
    setLoadingCommitments(true);
    CompromisoServices.getUserCommitments(params).then(res => {
      if (res.results) {
        setCommitments(res.response.data.map(item => {
          return {
            ...item,
            EsVoluntario: item.EsVoluntario === 1 ? 'SI' : 'NO'
          }
        }));
        setTotal(res.response.total)
      } else {
        Swal.fire({ title: res.message, icon: "warning" });
      }
    }).catch((err) => Swal.fire({ title: err, icon: "warning" }))
      .finally(() => setLoadingCommitments(false));
  };

  const handleChangePagination = (pagination) => {
    setData({ ...data, ...pagination });
  };

  const getCommitmentsSegmentos = (params) => {
    setLoadingCommitments(true);
    SocialService.getCompromisosSegmento(params).then(res => {
      if (res.results) {
        setCommitments(res.response.data.map(item => {
          return {
            ...item,
            EsVoluntario: item.EsVoluntario === 1 ? 'SI' : 'NO'
          }
        }));
        setTotal(res.response.total)
      } else {
        Swal.fire({ title: res.message, icon: "warning" });
      }
    }).catch((err) => Swal.fire({ title: err, icon: "warning" }))
      .finally(() => setLoadingCommitments(false));
  };



    return(   <AdminLayout>
        <Container maxWidth={false}>
          <ActionHeader title={"Mis Responsables"} />
          <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
              <Card
                className="card-primary"
                sx={{ height: "1200px", overflow: "auto" }}
              >
                <CardContent>
                  <Box marginBottom={"1rem"}>
                    <Stack
                      direction={"row"}
                      alignItems={"center"}
                      spacing={1}
                      marginBottom={"0.5rem"}
                    >
                      <Icon>person_search</Icon>
                      <Typography
                        fontWeight={600}
                        variant={"body2"}
                        marginBottom={"0.5rem"}
                      >
                        Buscar por número de celular
                      </Typography>
                    </Stack>
                    <Stack direction="row" spacing={2}>
                      <TextField
                        label="Celular"
                        size="small"
                        name="phone"
                       // value={phone}
                        //onChange={}
                      //  onKeyUp={handleOnKeyPress}
                     /*    InputProps={{
                          endAdornment: showButtonCancel && (
                            <InputAdornment position="end">
                              <IconButton onClick={handleClearSearch}>
                                <Icon>clear</Icon>
                              </IconButton>
                            </InputAdornment>
                          ),
                        }} */
                        sx={{ width: "100%" }}
                      />
  
                      <Button
                        variant="contained"
                        color="primaryDark"
                        sx={{ width: "25%" }}
                       //onClick={handleSearch}
                      >
                        Buscar
                      </Button>
                    </Stack>
                  </Box>
                  <Divider sx={{ marginBottom: "1rem" }} />
                  <Tree
                    data={tree}
                    typeData={{
                      value: "id",
                      label: "label",
                      S_label: null,
                      T_label: "Celular",
                    }}
                    handleClick={handleItemTree}
                    loading={loadingTree}
                  />
                </CardContent>
              </Card>
            </Grid>
  
            <Grid item xs={12} md={8}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <CardStats
                    icon={"feed"}
                    title={"Compromisos (Gral)"}
                    total={totalCommitments.totalCompromisos}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <CardStats
                    icon={"fact_check"}
                    title={"Compromisos Etiquetados"}
                    total={totalCommitments.totalCompromisosSegmentos}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <CardStats
                    icon={"fact_check"}
                    title={"Compromisos Etiquetados Unicos"}
                    total={totalCommitments.totalCompromisosSegmentosUnicos}
                  />
                </Grid>
              </Grid>
  
              <Card className="card-primary">
                <CardContent>
                 {nameUser !== "" && (
                    <>
                      <Stack direction={"row"} spacing={1} alignItems="center">
                        <Icon sx={{ color: "rgba(0, 0, 0, 0.54)" }}>
                          account_circle
                        </Icon>
                        <Typography variant="subtitle1" fontWeight={600}>
                          {nameUser}
                        </Typography>
                      </Stack>
                      <Divider />
                    </>
                  )} 
                  <Box
                    marginTop={"1rem"}
                    sx={{ width: "100%", typography: "body1" }}
                  >
                    <TabContext value={valueTab}>
                      <Box>
                        <TabList
                          onChange={handleChangeTab}
                          aria-label="lab API tabs example"
                        >
                          <Tab
                            icon={<Icon>badge</Icon>}
                            iconPosition="start"
                            label="Compromisos Sociales"
                            value="1"
                          />
                          {data.idUsuario !== "0" &&
                           <Tab
                           icon={<Icon>diversity_3</Icon>}
                           iconPosition="start"
                           label="Compromisos"
                           value="2"
                         />
                          }
                        </TabList>
                      </Box>
                      <TabPanel value="1">
                       <BasicTable
                          rows={commitments}
                          hcolumns={columns}
                          pageProp={data.page}
                          pageSize={data.pageSize}
                          total={totalCommitments}
                          handleChangePagination={handleChangePagination}
                          isLoading={loadingCommitments}
                          stickyHeader={true}
                        /> 
                      </TabPanel>
                      <TabPanel value="2">
                      <BasicTable
                          rows={commitments}
                          hcolumns={columns}
                          pageProp={data.page}
                          pageSize={data.pageSize}
                          total={total}
                          handleChangePagination={handleChangePagination}
                          isLoading={loadingCommitments}
                          stickyHeader={true}
                        /> 
                      </TabPanel>
                    </TabContext>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Container>
      </AdminLayout>)
};
export default SocialResponsible;