import { useEffect, useState } from "react";

import { Alert, Icon, Stack, Typography } from "@mui/material";

import BasicSelect from "@components/Selects/BasicSelect";

import sije from "@services/SijeService";
import Swal from "sweetalert2";

const FilterRegional = ({ onChangeFilter, onClearFilter }) => {
  const catalogsOptions = [{ id: "regiones" }];
  const defaultOption = { value: 0, label: "TODOS" };

  const [loadingCatalogs, setLoadingCatalogs] = useState(false);
  const [catalogsFiltered, setCatalogFiltered] = useState(() => {
    let newObject = {};
    for (const item of catalogsOptions) {
      newObject[item.id] = [];
    }
    return newObject;
  });
  const [filterData, setFilterData] = useState({
    Region: 0,
  });

  const getCatalogs = async () => {
    try {
      setLoadingCatalogs(true);
      const result = await sije.getCatalogs({ catalogs: catalogsOptions });
      const { results, response, message } = await result;
      if (results) {
        setCatalogFiltered(
          concatDefaultOption({
            ...catalogsFiltered,
            ...response.catalogs,
          })
        );
      } else {
        throw message;
      }
    } catch (error) {
      Swal.fire({
        title: error,
        icon: "warning",
      });
    } finally {
      setLoadingCatalogs(false);
    }
  };

  const concatDefaultOption = (data) => {
    let newObject = {};
    Object.keys(data).forEach((key) => {
      newObject[key] = [defaultOption, ...data[key]];
    });
    return newObject;
  };

  const handleChangeFilter = (e) => {
    const { name, value } = e.target;
    setFilterData((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    handleSearch(value);
  };

  const handleSearch = (value) => {
    if (value > 0) {
      const filters = [
        {
          id: "id",
          filter: "=",
          value,
        },
      ];

      onChangeFilter({ filters: filters });
    } else {
      onClearFilter();
    }
  };

  useEffect(() => {
    getCatalogs();
    // eslint-disable-next-line
  }, []);
  return (
    <>
      <Stack
        direction={"row"}
        spacing={1}
        marginBottom={2}
        alignItems={"center"}
      >
        <Icon>filter_list</Icon>
        <Typography variant="body2" fontWeight={600}>
          FILTRO
        </Typography>
      </Stack>
      <Alert severity="info" sx={{ mb: 2 }}>
        Elija una región para acceder a la información de la estructura.
      </Alert>
      <BasicSelect
        name="Region"
        label="Región"
        options={catalogsFiltered.regiones}
        value={filterData.Region}
        onChange={(e) => {
          handleChangeFilter(e);
        }}
        isLoading={loadingCatalogs}
        sx={{ width: "20%" }}
      />
    </>
  );
};

export default FilterRegional;
