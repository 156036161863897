import { useEffect, useState } from "react";

// Material UI
import { Container, Grid, Button, Typography, Icon } from "@mui/material";
import Swal from "sweetalert2";

//Services
import certificacion from "@services/CertificationServices";

// Components
import AdminLayout from "@components/MainPage/AdminLayout";
import ActionHeader from "@components/Containers/ActionHeader";
import LoadingForm from "@components/LinearProgress/LoadingForm";
import DrawerRelative from "@components/Containers/DrawerRelative";
import FloatingButton from "@components/Button/FloatingButton";

import Filter from "@components/Certificacion/Certifications/Filter";
import TableCertification from "@components/Certificacion/Certifications/TableCertification";
import ChartTops from "@components/Certificacion/Certifications/ChartTops";
import TableTotalTaskHistory from "@components/Certificacion/Certifications/TableTotalTaskHistory";

// Iconos
import {
    KeyboardDoubleArrowRight,
    KeyboardDoubleArrowLeft,
} from "@mui/icons-material";

import { getVars } from "@utils/global";


const DashboardMobilizationTerritorial = () => {
    const [ambitoUser, setAmbitoUser] = useState("");

    const [openDrawer, setOpenDrawer] = useState(true);
    const [dataHistory, setDataHistory] = useState(null);
    const [showFirstContainer, setShowFirstContainer] = useState(true);

    const total = 10;
    const [loading, setLoading] = useState({
        isLoadigForm: false,
        isSuccessForm: false,
        openLinearProgress: false,
    });

    const [idFilter, setIdFilter] = useState(ambitoUser);

    const [idAmbitoAgrupadorTable, setIdAmbitoAgrupadorTable] = useState(ambitoUser);

    const [catView, setCatView] = useState([]);

    const [data, setData] = useState({
        page: 0,
        pageSize: 10,
        filtered: [],
        sorted: [],
        idAmbitoAgrupador: ambitoUser,
    });

    const [bodyChart, setBodyChart] = useState({
        filtered: [],
        idAmbitoAgrupador: ambitoUser,
    });

    const handleSearchFilter = ({ filtereds }) => {
        setData((prev) => ({
            ...prev,
            filtered: filtereds,
            page: 0,
            pageSize: 10,
        }));
        setBodyChart((prev) => ({ ...prev, filtered: filtereds }));
    };

    const filters = getVars("filterValue")

    const handleClearFilter = () => {

        setData((prev) => ({
            ...prev,
            filtered: [],
            idAmbitoAgrupador: filters.tipoVista ? filters.tipoVista : ambitoUser,
        }));
        setBodyChart((prev) => ({
            ...prev,
            filtered: [],
            idAmbitoAgrupador: filters.tipoVista ? filters.tipoVista : ambitoUser,
        }));
        setIdAmbitoAgrupadorTable(ambitoUser);
        // if (data.filtered.length > 0 || data.idAmbitoAgrupador !== ambitoUser) {

        // }
    };

    const changeTypeViewFilter = (view) => {
        setIdAmbitoAgrupadorTable(view);
        setBodyChart((prev) => ({
            ...prev,
            filtered: [],
            idAmbitoAgrupador: view,
        }));
        setData((prev) => ({
            ...prev,
            page: 0,
            pageSize: 10,
            filtered: [],
            idAmbitoAgrupador: view,
        }));
    };

    const getCatView = async () => {
        try {
            const res = await certificacion.getAmbitoAgrupadorMovilizacion();
            const { results, response, message } = res;

            if (results) {
                const data = response.data.sort((a, b) => a.value - b.value);
                setCatView(data);
                setAmbitoUser(data[0]?.value);
            } else {
                throw new Error(message);
            }
        } catch (error) {
            Swal.fire({ title: error.message, icon: "warning" });
        }
    };

    useEffect(() => {
        getCatView();
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (ambitoUser) {
            setIdAmbitoAgrupadorTable(ambitoUser);
            setBodyChart((prev) => ({
                ...prev,
                filtered: [],
                idAmbitoAgrupador: ambitoUser,
            }));
            setData((prev) => ({
                ...prev,
                page: 0,
                pageSize: 10,
                filtered: [],
                idAmbitoAgrupador: ambitoUser,
            }));
        }
        // eslint-disable-next-line
    }, [ambitoUser]);

    return (
        <AdminLayout>
            <LoadingForm
                loadinMessage={"Cargando datos..."}
                isLoading={loading.isLoadigForm}
                success={loading.isSuccessForm}
                isOpen={loading.openLinearProgress}
                setIsOpen={() => setLoading({ ...loading, openLinearProgress: false })}
            />
            <Container maxWidth={false}>
                {showFirstContainer ? (
                    <>
                        <ActionHeader title={"Certificaciones"} isCustom={true}>
                            <Button
                                onClick={() => setOpenDrawer(!openDrawer)}
                                aria-label="open Drawer"
                                endIcon={
                                    !openDrawer ? (
                                        <KeyboardDoubleArrowRight />
                                    ) : (
                                        <KeyboardDoubleArrowLeft />
                                    )
                                }
                            >
                                <Icon sx={{ fontSize: "25px", }}>
                                    {"bar_chart"}
                                </Icon>
                                <Typography sx={{ fontWeight: 500 }}>GRÁFICAS</Typography>
                            </Button>
                        </ActionHeader>

                        <Grid
                            container
                            spacing={openDrawer ? 2 : 0}
                            sx={{ flexDirection: { xs: "column-reverse", md: "row" } }}
                        >
                            <Grid item xs={12} md={4}>
                                <DrawerRelative
                                    anchor="left"
                                    open={openDrawer}
                                    setOpen={setOpenDrawer}
                                    title={"TOP 10"}
                                    icon="public"
                                    titleComponentPlace="under"
                                    className="card-primary"
                                    sxPaper={{ borderRadius: 3 }}
                                    isSticky
                                >
                                    <ChartTops
                                        body={bodyChart}
                                        setLoading={setLoading}
                                        openDrawer={openDrawer}
                                        ambitoUser={ambitoUser}
                                    />
                                </DrawerRelative>
                            </Grid>

                            <Grid item xs={12} md={openDrawer ? 8 : 12}>
                                <Grid item xs={12} >
                                    <Filter
                                        onSearch={handleSearchFilter}
                                        onClear={handleClearFilter}
                                        changeTypeView={changeTypeViewFilter}
                                        setLoading={setLoading}
                                        catView={catView}
                                        setIdFilter={setIdFilter}
                                        ambitoUser={ambitoUser}
                                    />
                                </Grid>

                                <Grid item xs={12} sx={{ mt: 2 }}>
                                    <TableCertification
                                        idAmbitoAgrupadorTable={idAmbitoAgrupadorTable}
                                        total={total}
                                        data={data}
                                        loading={loading}
                                        setLoading={setLoading}
                                        catView={catView}
                                        idFilter={idFilter}
                                        ambitoUser={ambitoUser}
                                        setShowFirstContainer={setShowFirstContainer}
                                        setDataHistory={setDataHistory}
                                    />
                                </Grid>
                            </Grid>


                        </Grid>

                        {!openDrawer && (
                            <FloatingButton
                                onClick={() => setOpenDrawer(true)}
                                label="Mostrar Gráficas"
                                icon="bar_chart"
                                sx={{ display: { xs: "none", md: "block" } }}
                            />
                        )}
                    </>
                ) : null}
            </Container>
            <Container maxWidth={false}>
                {!showFirstContainer ? (
                    <>
                        <ActionHeader title={"Certificadores"}></ActionHeader>

                        <TableTotalTaskHistory idAmbitoAgrupadorTable={idAmbitoAgrupadorTable}
                            total={total}
                            data={data}
                            loading={loading}
                            setLoading={setLoading}
                            catView={catView}
                            idFilter={idFilter}
                            ambitoUser={ambitoUser}
                            setShowFirstContainer={setShowFirstContainer}
                            setDataHistory={setDataHistory}
                            dataHistory={dataHistory}
                        />
                    </>
                ) : null}
            </Container>
        </AdminLayout>
    );
};

export default DashboardMobilizationTerritorial;
