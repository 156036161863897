import { Box, Typography, IconButton, useTheme } from "@mui/material";
import { saveAs } from "file-saver";
import GetAppIcon from "@mui/icons-material/GetApp";
import * as ExcelJS from "exceljs";
import Logo from "@assets/img/c27.png";

const DownloadButton = ({ text, textColor = "white", pY = 0.5, pX = 2, fileData, errorData, rowStart }) => {
  const theme = useTheme();

  const generateErrorExcelFile = async () => {
    const workbook = new ExcelJS.Workbook();

    // Crear hoja de Excel
    const instructionSheet = workbook.addWorksheet("Instrucciones");
    const worksheet = workbook.addWorksheet("Movilizadores");

    // Asignar Instrucciones
    const instructions = [
      "1) Ingresar todos los campos solicitados. En caso de no contar con un apellido; rellenar el campo con XX, como en la INE",
      "2) El campo Celular debe ser un número válido a 10 dígitos (sin espacios, sin símbolos separadores) y debe ser un valor único. De lo contrario se descartarán los demás registros.",
      "3) El campo Sección debe existir en el listado proporcionado en la columna E o de lo contrario se descartará el registro por ser una sección inválida",
      "4) Únicamente debe existir un registro por movilizador",
      "5) Únicamente se recibirá una por Distrito Local",
    ];

    // Establecer el ancho de la columna A
    instructionSheet.getColumn(1).width = 100;

    instructions.forEach((instruction, index) => {
      const cell = instructionSheet.getCell(`A${index + 3}`);
      cell.value = instruction;
      cell.font = { bold: true, size: 12 };
      cell.alignment =
        index === 0 ? { horizontal: "center", vertical: "middle", wrapText: true } : { horizontal: "left", vertical: "middle", wrapText: true };
      // Ajustar el alto de la fila basado en el contenido automáticamente
      instructionSheet.getRow(index + 3).height = undefined;
    });

    // Establecer la celda A1
    instructionSheet.getCell("A1").value = "Instrucciones para el llenado del Formato";
    instructionSheet.getCell("A1").font = { bold: true, size: 12 };
    instructionSheet.getCell("A1").alignment = { horizontal: "center", vertical: "middle" };

    // Cargar imagen
    const imageBuffer = await fetch(Logo).then((res) => res.arrayBuffer());
    const imageId = workbook.addImage({
      buffer: imageBuffer,
      extension: "png",
    });

    // Insertar Imagen A1:B6
    worksheet.addImage(imageId, {
      tl: { col: 0, row: 0 },
      br: { col: 2, row: 6 },
      editAs: "oneCell",
    });

    // Combinacion de celdas
    worksheet.mergeCells("C2:D2");
    worksheet.getCell("C2").value = "Formato de Carga de Movilizadores";
    worksheet.getCell("C2").font = { bold: true, size: 12 };
    worksheet.getCell("C2").alignment = { horizontal: "center", vertical: "middle" };

    // Agregar encabezados
    const headers = ["Nombre", "Paterno", "Materno", "Celular", "Sección"];
    const headerRowNumber = 7;
    const headerRow = worksheet.getRow(headerRowNumber);
    headers.forEach((header, index) => {
      const cell = headerRow.getCell(index + 1);
      cell.value = header;
      cell.font = {
        bold: true,
        size: 12,
        color: { argb: "000000" }, // Black text color
      };
      cell.alignment = { horizontal: "center", vertical: "middle" };
    });
    headerRow.commit();

    const dataStartRowNumber = rowStart;
    fileData.forEach((row, rowIndex) => {
      const rowData = [
        row.Nombre.trim(),
        row.Paterno,
        row.Materno,
        row.Celular ? { text: row.Celular, alignment: { horizontal: "right" } } : "",
        row.Seccion ? { text: row.Seccion, alignment: { horizontal: "right" } } : "",
      ];
      const dataRow = worksheet.getRow(dataStartRowNumber + rowIndex);
      rowData.forEach((data, colIndex) => {
        const cell = dataRow.getCell(colIndex + 1);
        cell.value = data.text || data;
        cell.alignment = data.alignment || { horizontal: "left" };
      });
      dataRow.commit();
    });

    // Ajustar tamaño de celdas
    worksheet.columns.forEach((column) => {
      let maxColumnWidth = 15;
      column.eachCell({ includeEmpty: true }, (cell) => {
        const cellValue = cell.value ? cell.value.toString() : "";
        const cellWidth = cellValue.length + 2;
        if (cellWidth > maxColumnWidth) {
          maxColumnWidth = cellWidth;
        }
      });
      column.width = maxColumnWidth;
    });

    // Agregar notas y celdas en rojo
    errorData.forEach((error) => {
      const row = worksheet.getRow(error.row); // Ajuste para la fila de inicio correcta
      const cell = row.getCell(headers.indexOf(error.column) + 1);
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FFD6D6" },
      };
      cell.note = {
        texts: [{ text: error.message }],
      };
    });

    // Create buffer and save file
    const buffer = await workbook.xlsx.writeBuffer();
    saveAs(new Blob([buffer]), "Errores_Encontrados.xlsx");
  };

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        paddingX: pX,
        paddingY: pY,
        backgroundColor: theme.palette.primary.dark,
        borderRadius: "5rem",
        cursor: "pointer",
      }}
      onClick={generateErrorExcelFile}
    >
      <Typography variant="h6" color={textColor} align="center" overflow="hidden" textOverflow="ellipsis" whiteSpace="nowrap">
        {text}
      </Typography>
      <IconButton size="small" sx={{ color: textColor, ml: 1 }}>
        <GetAppIcon />
      </IconButton>
    </Box>
  );
};

export default DownloadButton;
