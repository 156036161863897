import { useEffect, useState } from "react";
import { useCatalogs } from "@hooks/useCatalogs";

import {
  Box,
  Card,
  CardContent,
  Container,
  Icon,
  Typography,
} from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import style from "./drawer.module.css";
import { blue, grey } from "@mui/material/colors";
import { TraceSpinner } from "react-spinners-kit";
import { Select, MenuItem, FormControl, InputLabel } from '@mui/material';

import TreeDrive from "@components/TreeDrive/TreeDrive";
import Document from "@components/TreeDrive/Document";
import GridDocuments from "@components/TreeDrive/GridDocuments";
import DrawerRelative from "@components/Containers/DrawerRelative";
import LoadingForm from "@components/LinearProgress/LoadingForm";
import InfoDrive from "@components/Activation/DownloadFile/InfoDrive";
import PreviewDocument from "@components/TreeDrive/PreviewDocument";

import movilizacion from "@services/MovilizationServices";
import Swal from "sweetalert2";

const Drive = () => {
  const catalogsOptions = [
    { id: "municipios_reportes", getAll: false },
    { id: "mpio_dl", getAll: false },
    { id: "secciones", getAll: false },
    { id: "municipios", getAll: false },
    { id: "dl", getAll: false }
  ];

  const [documentTree, setDocumentTree] = useState([]);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [loadProgress, setLoadProgress] = useState({
    isLoading: true,
    isOpen: true,
    message: "Cargando ...",
  });
  const [dataInfo, setDataInfo] = useState({});
  const [loadingSimulate, setLoadingSimulate] = useState(false);
  const [openPreview, setOpenPreview] = useState(false);
  const [paramsPreview, setParamsPreview] = useState({});
  const [titlePreview, setTitlePreview] = useState("");
  const [selectedOption, setSelectedOption] = useState("1");

  const [info, setInfo] = useState({})
  const [loadingInfo, setLoadingInfo] = useState(false)

  const { catalogs, load } = useCatalogs({
    catalogsOptions: catalogsOptions,
    putDefaultOption: false,
  });

  const simulateAPICall = (delay = 1000) => {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve();
      }, delay);
    });
  };

  const generateUniqueId = () => {
    return Math.random().toString(36).substring(2, 10);
  };

  //---------------- Select 1
  const generateNodeMunicipalities = (dl, sections) => {
    return dl.map((dls) => ({
      id: generateUniqueId(),
      value: dls.value,
      label: dls.label,
      hasPermissionDownload: true,
      sections: generateNodeSectionsMun(dls.value, sections),
    }));
  };

  const generateNodeSectionsMun = (dlId, sections) => {
    return sections
      .filter((seccion) => seccion.idMunicipioReportes === dlId)
      .map((seccion) => ({
        id: generateUniqueId(),
        value: seccion.value,
        label: seccion.label,
        hasPermissionDownload: true,
      }));
  };

  //---------------- Select 2
  const generateNodeDL = (dl, sections) => {
    return dl.map((dls) => ({
      id: generateUniqueId(),
      value: dls.value,
      label: dls.label,
      hasPermissionDownload: true,
      sections: generateNodeSectionsDl(dls.value, sections),
    }));
  };

  const generateNodeSectionsDl = (dlId, sections) => {
    return sections
      .filter((seccion) => seccion.idDL === dlId)
      .map((seccion) => ({
        id: generateUniqueId(),
        value: seccion.value,
        label: seccion.label,
        hasPermissionDownload: true,
      }));
  };

  useEffect(() => {
    if (!load) {
      if (selectedOption === "1") {
        const treeData2 = generateNodeMunicipalities(
          catalogs.municipios_reportes,
          catalogs.secciones
        );
        setDocumentTree(treeData2);
        setLoadProgress({ ...loadProgress, isLoading: false, isOpen: false });
      } else if (selectedOption === "2") {
        const treeData3 = generateNodeDL(
          catalogs.dl,
          catalogs.secciones
        );
        setDocumentTree(treeData3);
        setLoadProgress({ ...loadProgress, isLoading: false, isOpen: false });
      }
    }
    //eslint-disable-next-line
  }, [load, selectedOption]);


  const handleClick = async (e) => {
    try {
      if (e.hasPermissionDownload) {
        setLoadingSimulate(true);
        await simulateAPICall();

        setDataInfo(e);
        setLoadingInfo(true)

        try {
          // setLoading(true);
          const res = await movilizacion.getMobilizationDetails(
            selectedOption === "1"
              ? { idAmbitoAgrupador: 6, filtered: [{ id: "idMunicipioReportes", filter: "=", value: e.value }] }
              : { idAmbitoAgrupador: 4, filtered: [{ id: "idDL", filter: "=", value: e.value }]}
          );

          const { results, response, message } = res;

          if(results){
            setInfo(response.data.data)
          } else {
            throw new Error(message);
          }
        } catch (error) {
          Swal.fire({ title: error.message, icon: "warning" });
        } finally {
          setLoadingInfo(false)
        }

      } else {
        openDrawer && setOpenDrawer(false);
      }
    } catch (error) {
      Swal.fire({
        title: error.message,
        icon: "warning",
      });
    } finally {
      setLoadingSimulate(false);
    }
  };

  const handleDownload = (e) => {
    if (e.hasPermissionDownload) {
      executeDownload({ idAmbitoAgrupador: 8, filtered: [{ id: "Seccion", filter: "=", value: e.value }] })
    }
  };

  const handlePreview = async (e) => {
    if (e.hasPermissionDownload) {
      setParamsPreview({ idAmbitoAgrupador: 8, filtered: [{ id: "Seccion", filter: "=", value: e.value }] })

      setTitlePreview(e.label);
      setOpenPreview(!openPreview);
    }
  };

  const handleDownloadAll = (e) => {
    e.stopPropagation();
    const { value } = dataInfo;

    if (selectedOption === "1") {
      executeDownload({ idAmbitoAgrupador: 6, filtered: [{ id: "idMunicipioReportes", filter: "=", value: value }] });
    } else if (selectedOption === "2") {
      executeDownload({ idAmbitoAgrupador: 4, filtered: [{ id: "idDL", filter: "=", value: value }] });
    }
  };

  const handleDownloadAllPolygon = (e, obj) => {
    e.stopPropagation();
    const { value } = obj;
    if (selectedOption === "1") {
      executeDownload({ idAmbitoAgrupador: 6, filtered: [{ id: "idMunicipioReportes", filter: "=", value: value }] });
    } else if (selectedOption === "2") {
      executeDownload({ idAmbitoAgrupador: 4, filtered: [{ id: "idDL", filter: "=", value: value }] });
    }
  };

  const executeDownload = async (params = {}) => {
    try {
      setLoadProgress({
        ...loadProgress,
        isLoading: true,
        isOpen: true,
        message: "Descargando ...",
      });

      const res = await movilizacion.exportDocuments({
        params,
        api: "generate-mobilization-list",
      });

      if (!res.results && !res.success) throw new Error(res.message);
    } catch (error) {
      Swal.fire({
        title: error.message,
        icon: "warning",
      });
    } finally {
      //setLoadingDownload(false);
      setLoadProgress({
        ...loadProgress,
        isLoading: false,
        isOpen: false,
        message: "",
      });
    }
  };

  const handleSelectChange = (event) => {
    setSelectedOption(event.target.value);
  };

  useEffect(() => {
    setDataInfo({})
    setInfo({})
    setOpenDrawer(false)
  }, [selectedOption]);

  return (
    <>
      <LoadingForm
        loadinMessage={loadProgress.message}
        isLoading={loadProgress.isLoading}
        isOpen={loadProgress.isOpen}
        setIsOpen={() => setLoadProgress({ ...loadProgress, isOpen: false })}
      />
      
      <FormControl sx={{ width: { xs: '280px', md: '180px' }, mb: 2 }}>
        <InputLabel>Filtrar por</InputLabel>
        <Select
          value={selectedOption}
          label="Filtrar por"
          onChange={handleSelectChange}
        >
          <MenuItem value="1">MUNICIPIOS</MenuItem>
          <MenuItem value="2">DISTRITO LOCAL</MenuItem>
        </Select>
      </FormControl>

      <Grid2 container spacing={2}>
        <Grid2 xs={12} md={3} lg={3}>
          <TreeDrive
            data={documentTree}
            handleClick={handleClick}
            handleDownload={handleDownloadAllPolygon}
          />
        </Grid2>
        <Grid2 xs={12} md={openDrawer ? 6 : 9} lg={openDrawer ? 6 : 9}>
          <Card
            elevation={0}
            sx={{
              height: "100%",
              borderRadius: 5,
              minHeight: "calc(100vh - 200px)",
              maxHeight: "calc(100vh - 150px)",
              position: "sticky",
              top: "5rem",
            }}
          >
            <CardContent>
              {loadingSimulate ? (
                <>
                  <Typography
                    variant="subtitle2"
                    fontWeight={600}
                    fontSize={16}
                  >
                    Archivos
                  </Typography>
                  <Box
                    display={"flex"}
                    justifyContent={"center"}
                    alignItems={"center"}
                    minHeight={"calc(100vh - 150px)"}
                  >
                    <TraceSpinner
                      frontColor={blue[800]}
                      backColor={blue[100]}
                      loading={true}
                    />
                  </Box>
                </>
              ) : (
                <>
                  {dataInfo && dataInfo.sections ? (
                    <GridDocuments
                      dataInfo={dataInfo}
                      openDrawer={openDrawer}
                      setOpenDrawer={setOpenDrawer}
                      handleDownloadAll={handleDownloadAll}
                    >
                      {dataInfo &&
                        dataInfo.sections &&
                        dataInfo.sections.map((item) => (
                          <Document
                            key={item.id}
                            name={"Sección " + item.label}
                            handleDownload={() => handleDownload(item)}
                            handlePreview={() => handlePreview(item)}
                          />
                        ))}
                    </GridDocuments>
                  ) : (
                    <>
                      <Typography
                        variant="subtitle2"
                        fontWeight={600}
                        fontSize={16}
                      >
                        Archivos
                      </Typography>
                      <Box
                        display={"flex"}
                        justifyContent={"center"}
                        alignItems={"center"}
                        flexDirection={"column"}
                        minHeight={"calc(100vh - 150px)"}
                        gap={1}
                      >
                        <Icon
                          fontSize="large"
                          sx={{
                            fontSize: 70,
                            color: grey[400],
                            backgroundColor: grey[100],
                            borderRadius: "50%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            p: 6,
                          }}
                        >
                          insert_drive_file
                        </Icon>
                        {selectedOption === "1" ?
                          <Typography variant="body2" fontWeight={500}>
                            Por favor, selecciona un <span style={{ fontWeight: 800 }}>municipio</span> para proceder con la
                            descarga de los archivos.
                          </Typography> : <Typography variant="body2" fontWeight={500}>
                            Por favor, selecciona un <span style={{ fontWeight: 800 }}>distrito local</span> para proceder con la
                            descarga de los archivos.
                          </Typography>
                        }
                      </Box>
                    </>
                  )}
                </>
              )}
            </CardContent>
          </Card>
        </Grid2>
        <Grid2 xs={12} md={3} lg={3}>
          <DrawerRelative
            anchor="right"
            open={openDrawer}
            setOpen={setOpenDrawer}
            title="Detalles"
            icon="info"
            sxPaper={{ borderRadius: 3 }}
            className={style.drawer}
            isSticky
            screenHeight="md"
          >
            <Container>
              <InfoDrive info={info} loadingInfo={loadingInfo} selectedOption />
            </Container>
          </DrawerRelative>
        </Grid2>
      </Grid2>
      <PreviewDocument
        title={`Sección ${titlePreview}`}
        params={paramsPreview}
        open={openPreview}
        setOpen={setOpenPreview}
      />
    </>
  );
};

export default Drive;
