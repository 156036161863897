import {
  Box,
  Checkbox,
  Icon,
  TableCell,
  TableRow,
  Typography,
  TableBody as TableBodyMui,
} from "@mui/material";
import { grey } from "@mui/material/colors";

const TableBody = ({ data, users, dataFound, handleSelectRow }) => {
  return (
    <TableBodyMui>
      {data.length > 0 ? (
        data.map((row) => (
          <TableRow key={row.idUsuario}>
            <TableCell>
              <Checkbox
                disabled={row.esPaseLista === 1 ? true : false}
                checked={
                  row.esPaseLista === 1 ||
                  users.some((item) => item.id === row.idUsuario)
                }
                onChange={(event) => handleSelectRow(row.idUsuario)}
              />
            </TableCell>
            <TableCell>{row.NombreCompleto}</TableCell>
            <TableCell>{row.ResponsabilidadEtiqueta}</TableCell>
            <TableCell>{row.Seccion}</TableCell>
          </TableRow>
        ))
      ) : (
        <TableRow>
          <TableCell colSpan={4}>
            <Box
              display={"flex"}
              alignContent={"center"}
              alignItems={"center"}
              flexDirection={"column"}
            >
              <Icon sx={{ fontSize: "5rem", color: grey[500] }}>
                {dataFound ? "person_search" : "search_off"}
              </Icon>
              <Typography fontWeight={600} variant="h6" marginTop={1}>
                {dataFound
                  ? "Datos No Disponible"
                  : "No se econtraron resultados"}
              </Typography>
              <Typography variant="caption" marginTop={1}>
                {dataFound
                  ? " Utiliza el filtro a tu izquierda para realizar una búsqueda y obtener los resultados deseados."
                  : "Por favor, intenta realizar la búsqueda nuevamente, ya que no se encontraron datos con los criterios actuales"}
              </Typography>
            </Box>
          </TableCell>
        </TableRow>
      )}
    </TableBodyMui>
  );
};

export default TableBody;
