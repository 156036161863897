import { PrivilegedRoute, ProtectedRoute } from "@middlewares";
import RollCall from "@pages/Activation/RollCall";
import Bingo from "@pages/Activation/Bingo";
import MovilizationCapture from "@pages/Activation/MovilizationCapture";
import DashboardMobilization from "@pages/Activation/DashboardMobilization";
import DownloadFileStructure from "@pages/Activation/DownloadFileStructure";
import DashboardMobilizationElectoral from "@pages/Activation/DashboardMobilizationElectoral";
import Movilizacion from "@pages/Movilizacion/CompromisosMovilizacion"
import CapturaBingo from "@pages/Activation/CapturaBingo"
import AssignStatus from "@pages/Movilizacion/AssignStatus";

const ActivationRoute = [
  {
    path: "/activacion-pase-lista",
    element: (
      <PrivilegedRoute>
        <RollCall />
      </PrivilegedRoute>
    ),
  },
  {
    path: "/captura-folio-bingo",
    element: (
      <ProtectedRoute>
        <Bingo />
      </ProtectedRoute>
    ),
  },
  {
    path: "/captura-movilizacion",
    element: (
      <PrivilegedRoute>
        <MovilizationCapture />
      </PrivilegedRoute>
    ),
  },
  {
    path: "/dashboard-movilizacion",
    element: (
      <PrivilegedRoute>
        <DashboardMobilization />
      </PrivilegedRoute>
    ),
  },
  {
    path: "/impresion-listados",
    element: (
      <PrivilegedRoute>
        <DownloadFileStructure />
      </PrivilegedRoute>
    ),
  },
  {
    path: "/dashboard-movilizacion-electoral",
    element: (
      <PrivilegedRoute>
        <DashboardMobilizationElectoral />
      </PrivilegedRoute>
    ),
  },
  {
    path: '/captura-bingo',
    element: (
      <PrivilegedRoute>
        <Movilizacion />
      </PrivilegedRoute>)
  },
  {
    path: "/captura-bingo-identificador",
    element: (
      <ProtectedRoute>
        <CapturaBingo />
      </ProtectedRoute>
    ),
  },
  {
    path: "/movilizacion-casa-amiga",
    element: (
        <PrivilegedRoute>
            <AssignStatus />
        </PrivilegedRoute>
    ),
},
];

export default ActivationRoute;
