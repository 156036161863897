import ApiExec, { downloadFile } from "@utils/ApiExec";
import { encryptVars, DEBUG } from "@utils/global";

class CertificationServices {
  getAmbitoAgrupadorMovilizacion = (params) => {
    return ApiExec(
      params,
      "certification/get-ambito-agrupador-certificacion",
      "POST"
    )
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };

  getCardCaptureToday = (params) => {
    return ApiExec(
      params,
      "certification/get-card-capture-today",
      "POST"
    )
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };

  getCardAdvance = (params) => {
    return ApiExec(
      params,
      "certification/get-card-advance",
      "POST"
    )
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };

  getCardAfinNoAfin = (params) => {
    return ApiExec(
      params,
      "certification/get-card-afin-no-afin",
      "POST"
    )
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };

  getPendingAdvance = (params) => {
    return ApiExec(
      params,
      "certification/get-pending-advance",
      "POST"
    )
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };

  getNotSuccessfulChart = (params) => {
    return ApiExec(
      params,
      "certification/get-not-successful-chart",
      "POST"
    )
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };

  getAfinNoAfinChart = (params) => {
    return ApiExec(
      params,
      "certification/get-afin-no-afin-chart",
      "POST"
    )
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };

  getAdvanceList = (params) => {
    return ApiExec(
      params,
      "certification/get-advance-list",
      "POST"
    )
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };

  exportAdvanceList = (params) => {
    return downloadFile({
      api: "certification/export-advance-list",
      method: "POST",
      data: params
    })
      .then((res) => res)
      .catch((e) => e);
  }

  getCertifiersDetailsList = (params) => {
    return ApiExec(
      params,
      "certification/get-certifiers-details-list",
      "POST"
    )
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };

  getTopTenChart = (params) => {
    return ApiExec(
      params,
      "certification/get-top-ten-chart",
      "POST"
    )
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };

  getTenWorstChart = (params) => {
    return ApiExec(
      params,
      "certification/get-ten-worst-chart",
      "POST"
    )
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };

  getTasksDetails = (params) => {
    return ApiExec(
      params,
      "certification/get-tasks-details",
      "POST"
    )
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };

  getTasksPersons = (params) => {
    return ApiExec(
      params,
      "certification/get-tasks-persons",
      "POST"
    )
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };

  exportCertifiersDetailsList = (params) => {
    return downloadFile({
      api: "certification/export-certifiers-details-list",
      method: "POST",
      data: params
    })
      .then((res) => res)
      .catch((e) => e);
  };


  getPictures = (params) => {
    return ApiExec(
      params,
      "certification/get-pictures",
      "POST"
    )
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };

  getPeopleToAssign = (params) => {
    return ApiExec(params, "certification/get-people-for-assign", "POST")
      .then(function (res) {
        return res;
      })
      .then((res) => {
        return res;
      })
      .catch((e) => {
        return e;
      });
  };

  setResponsability = (params) => {
    return ApiExec(params, "certification/set-responsibility", "POST")
      .then(function (res) {
        return res;
      })
      .then((res) => {
        return res;
      })
      .catch((e) => {
        return e;
      });
  };

  getResponsabilities = (value) => {
    const values = DEBUG ? value : encryptVars(value);
    return ApiExec({}, `certification/get-responsibilities/${values}`, "GET")
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };

  deleteResponsability = (params) => {
    return ApiExec(params, "certification/delete-responsibility", "DELETE")
      .then(function (res) {
        return res;
      })
      .then((res) => {
        return res;
      })
      .catch((e) => {
        return e;
      });
  };
}

const certificacion = new CertificationServices();
export default certificacion;
