import React, { useContext, useEffect } from "react";
import {
  Container,
  Button,
  Icon,
  Grid,
  TextField,
  Stack,
  Typography,
  Divider,
} from "@mui/material";
import Swal from "sweetalert2";
import { useFormik } from "formik";

// import Confirmation from "./Confirmation";
import { LoadingButton } from "@mui/lab";
import { updateUsernameSchema } from "@schemas/UserSchemas";
import { isTypePhone } from "@utils/validations";
import support from "@services/SupportServices"
import UserUpdateContext from './UserUpdateContext'

const UpdatePhone = (props) => {
  const {
    idUsuario,
    clearForm,
    setClearForm,
    userData,
    setUserData
  } = props;

  const {
    setIsLoadingForm, setOpenLinearProgress, setIsSuccessForm
  } = useContext(UserUpdateContext);

  // const [showPasswordConfirmation, setShowPasswordConfirmation] = useState(false);

  const formik = useFormik({
    initialValues: {
      idUsuario: null,
      CelularAnterior: "",
      CelularNuevo: '',
      Confirm: ''
    },
    validationSchema: updateUsernameSchema,
    onSubmit: async (values) => {
      await handleSave(values)
    }
  });

  useEffect(() => {
    if (userData) {
      formik.setValues({
        ...formik.values,
        idUsuario: userData.id,
        CelularAnterior: userData.Username,
        CelularNuevo: '',
        Confirm: ''
      });
    }
    // eslint-disable-next-line
  }, [userData]);

  const handleSave = async (values) => {
    setIsLoadingForm(true);
    setOpenLinearProgress(true);
    setIsSuccessForm(false);
    try {
      const params = {
        idUsuario: values.idUsuario,
        CelularAnterior: values.CelularAnterior,
        CelularNuevo: values.CelularNuevo
      };

      const result = await support.updateUsername(params);
      const { results, message } = result;

      if (results) {
        Swal.fire({
          title: message,
          icon: 'success',
          allowEnterKey: false,
          allowEscapeKey: false,
          allowOutsideClick: false
        }).then(res => {
          if (res.isConfirmed) {
            console.log('Actualizar');
            setUserData((prevState) => ({
              ...prevState,
              Username: values.CelularNuevo
            }));
          }
        });
        setIsSuccessForm(true);
      } else {
        Swal.fire({
          title: message,
          icon: 'warning'
        });
      }

    } catch (error) {
      Swal.fire({
        title: error.message,
        icon: 'warning'
      });
    } finally {
      setIsLoadingForm(false);
      setOpenLinearProgress(false);
    }
  };

  const handleCleanForm = (e) => {
    formik.setValues({ phoneNumber: "" });
  };

  useEffect(() => {
    if (clearForm) {
      handleCleanForm();
      setClearForm(false);
    }
    // eslint-disable-next-line
  }, [clearForm]);

  const handleOnKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      formik.submitForm();
    }
  };

  return (
    <Container sx={{ ml: 4, mb: 4, mt: 4 }}>
      <Stack spacing={2} sx={{ mr: 4 }}>
        {idUsuario && (
          <div>
            <Typography fontWeight={600} variant="subtitle">Celular Actual: {userData.Username}</Typography>
            <Divider />
          </div>
        )}
        <TextField
          error={formik.touched.CelularNuevo && !!formik.errors.CelularNuevo}
          label="Nuevo número de celular"
          helperText={formik.touched.CelularNuevo && formik.errors.CelularNuevo}
          type="text"
          name="CelularNuevo"
          onChange={(e) =>
            formik.handleChange({
              target: {
                name: "CelularNuevo",
                value: isTypePhone(e.target.value)
                  ? e.target.value
                  : formik.values.CelularNuevo,
              },
            })
          }
          onBlur={(e) =>
            formik.handleChange({
              target: {
                name: "CelularNuevo",
                value: isTypePhone(e.target.value)
                  ? e.target.value
                  : formik.values.CelularNuevo,
              },
            })
          }
          onKeyPress={handleOnKeyPress}
          value={formik.values.CelularNuevo}
          fullWidth
        />

        <TextField
          error={formik.touched.Confirm && !!formik.errors.Confirm}
          label="Confirmar nuevo número de celular"
          helperText={formik.touched.Confirm && formik.errors.Confirm}
          type="text"
          name="phoneNumber"
          onChange={(e) =>
            formik.handleChange({
              target: {
                name: "Confirm",
                value: isTypePhone(e.target.value)
                  ? e.target.value
                  : formik.values.Confirm,
              },
            })
          }
          onBlur={(e) =>
            formik.handleChange({
              target: {
                name: "Confirm",
                value: isTypePhone(e.target.value)
                  ? e.target.value
                  : formik.values.Confirm,
              },
            })
          }
          onKeyPress={handleOnKeyPress}
          value={formik.values.Confirm}
          fullWidth
        />
      </Stack>
      <Grid item xs={12} sx={{ mt: "5vh", mr: 4, textAlign: "end" }}>
        <Button
          variant="outlined"
          size="small"
          color="primaryDark"
          onClick={handleCleanForm}
          startIcon={<Icon>cancel</Icon>}
        >
          Cancelar
        </Button>
        <LoadingButton
          variant="contained"
          color="primaryDark"
          size="small"
          onClick={formik.submitForm}
          startIcon={<Icon>save</Icon>}
          disabled={!idUsuario}
          // loading={loading}
          loadingPosition="start"
          sx={{ ml: 1 }}
        >
          Guardar
        </LoadingButton>
      </Grid>
      {/* <Confirmation
        a={showPasswordConfirmation}
        onClose={setShowPasswordConfirmation}
        onSave={handleSave}
        data={{
          idUsuario: idUsuario,
          phoneNumber: formik.values.phoneNumber,
        }}
      /> */}
    </Container>
  );
};

export default UpdatePhone;
