import { Avatar, Box } from "@mui/material";

import back_pan from "@assets/img/back02.png";
import logo_sian from "@assets/img/c27.png";

const Banner = () => {
  return (
    <>
      <Box
        component={"img"}
        src={back_pan}
        alt="background_pan"
        width={"100%"}
        height={"100vh"}
      />
      <Box
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          backgroundColor: "rgba(0, 0, 0, 0.4)",
          zIndex: (theme) => theme.zIndex.modal + 1,
        }}
      ></Box>
      <Avatar
        alt="logo_sian"
        src={logo_sian}
        variant="rounded"
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: { xs: "90%", md: "35%" },
          height: "42%",
          zIndex: (theme) => theme.zIndex.modal + 1,
        }}
      />
    </>
  );
};

export default Banner;
