import React, { useState } from "react";

import {
  Box,
  Stack,
  Button,
  Grid,
  Icon,
  Typography,
  Divider
} from "@mui/material";
import { useFormik } from "formik";
import { UserAddInterfaceV2 } from "@data/interfaces/UserInterfaces";
import { UserAddSchemaV2 } from "@data/schemas/UserSchemas";
import PersonalInformationForm from "@components/Compromisos/PersonalInformationForm"
import ContactInformationForm from "@components/Compromisos/ContactInformationForm"
import AddressForm from "@components/Compromisos/AddressForm"
import AccessDataForm from "@components/Users/AccessDataForm"
import AmbitForm from "@components/Compromisos/AmbitForm"

const UserForm = (props) => {

  const [listValues, setListValues] = useState([]);
  const formik = useFormik({
    initialValues: UserAddInterfaceV2,
    validationSchema: UserAddSchemaV2,
    onSubmit: (values) => {
    },
  });

  return (
    <>
      <Stack
        direction="row"
        justifyContent="center"
        alignItems="center"
        spacing={2}
      >
        <Grid container xs={12} sm={12} md={12} lg={12}>
          <Grid item xs={12} sm={12} md={2} lg={2} sx={{ mt: 3 }}>
            <Stack
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              spacing={1}
            >
              <Typography variant="h6"><Icon color="primary" sx={{ mt: 0.5 }}>lock</Icon></Typography>
              <Typography variant="h6">Datos de acceso</Typography>
            </Stack>
          </Grid>
          <Grid item xs={12} sm={12} md={10} lg={10}>
            <Grid container spacing={3} sx={{ mt: 1 }}>
              <AccessDataForm
                errors={formik.errors}
                touched={formik.touched}
                values={formik.values}
                handleChange={formik.handleChange}
              />
            </Grid>
            <Divider sx={{ mt: 5 }} />
          </Grid>
        </Grid>
      </Stack>

      <Stack
        direction="row"
        justifyContent="center"
        alignItems="center"
        spacing={2}
      >
        <Grid container xs={12} sm={12} md={12} lg={12}>
          <Grid container xs={12} sm={12} md={12} lg={12}>
            <Grid item xs={12} sm={12} md={2} lg={2} sx={{ mt: 3 }}>
              <Stack
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                spacing={1}
              >
                <Typography variant="h6"><Icon color="primary" sx={{ mt: 0.5 }}>account_circle</Icon></Typography>
                <Typography variant="h6">Datos Personales</Typography>
              </Stack>
            </Grid>
            <Grid item xs={12} sm={12} md={10} lg={10}>
              <Grid container spacing={3} sx={{ mt: 1 }}>
                <PersonalInformationForm
                  errors={formik.errors}
                  touched={formik.touched}
                  values={formik.values}
                  setValues={formik.setValues}
                  handleChange={formik.handleChange}
                />
              </Grid>
              <Divider sx={{ mt: 5 }} />
            </Grid>
          </Grid>
        </Grid>
      </Stack>

      <Stack
        direction="row"
        justifyContent="center"
        alignItems="center"
        spacing={2}
      >
        <Grid container xs={12} sm={12} md={12} lg={12}>
          <Grid container xs={12} sm={12} md={12} lg={12}>
            <Grid item xs={12} sm={12} md={2} lg={2} sx={{ mt: 3 }}>
              <Stack
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                spacing={1}
              >
                <Typography variant="h6"><Icon color="primary" sx={{ mt: 0.5 }}>home</Icon></Typography>
                <Typography variant="h6">Domicilio</Typography>
              </Stack>
            </Grid>
            <Grid item xs={12} sm={12} md={10} lg={10}>
              <Grid container spacing={3} sx={{ mt: 1 }}>
                <AddressForm
                  errors={formik.errors}
                  touched={formik.touched}
                  values={formik.values}
                  setValues={formik.setValues}
                  handleChange={formik.handleChange}
                />
              </Grid>
              <Divider sx={{ mt: 5 }} />
            </Grid>
          </Grid>
        </Grid>
      </Stack>

      <Stack
        direction="row"
        justifyContent="center"
        alignItems="center"
        spacing={2}
      >
        <Grid container xs={12} sm={12} md={12} lg={12}>
          <Grid container xs={12} sm={12} md={12} lg={12}>
            <Grid item xs={12} sm={12} md={2} lg={2} sx={{ mt: 3 }}>
              <Stack
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                spacing={1}
              >
                <Typography variant="h6"><Icon color="primary" sx={{ mt: 0.5 }}>contact_phone</Icon></Typography>
                <Typography variant="h6">Contacto</Typography>
              </Stack>
            </Grid>
            <Grid item xs={12} sm={12} md={10} lg={10}>
              <Grid container spacing={3} sx={{ mt: 1 }}>
                <ContactInformationForm
                  errors={formik.errors}
                  touched={formik.touched}
                  values={formik.values}
                  handleChange={formik.handleChange}
                  telephoneShow={false}
                  emailShow={false}
                />
              </Grid>
              <Divider sx={{ mt: 5 }} />
            </Grid>
          </Grid>
        </Grid>
      </Stack>

      <Stack
        direction="row"
        justifyContent="center"
        alignItems="center"
        spacing={2}
      >
        <Grid container xs={12} sm={12} md={12} lg={12}>
          <Grid container xs={12} sm={12} md={12} lg={12}>
            <Grid item xs={12} sm={12} md={2} lg={2} sx={{ mt: 3 }}>
              <Stack
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                spacing={1}
              >
                <Typography variant="h6"><Icon color="primary" sx={{ mt: 0.5 }}>layers</Icon></Typography>
                <Typography variant="h6">Ámbito</Typography>
              </Stack>
            </Grid>
            <Grid item xs={12} sm={12} md={10} lg={10}>
              <Grid container spacing={3} sx={{ mt: 1 }}>
                <AmbitForm
                  listValues={listValues}
                  setListValues={setListValues}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Stack>

      <Grid container spacing={3} sx={{ mt: 2 }}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Button
              type="button"
              color="primaryDark"
              variant="contained"
              size="small"
              onClick={formik.submitForm}
              startIcon={<Icon>save</Icon>}
            >
              Guardar
            </Button>
          </Box>
        </Grid>

      </Grid>
    </>
  );
};
export default UserForm;
