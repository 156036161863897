import { useState, useEffect, useCallback } from "react";
import { useCatalogs } from "@hooks/useCatalogs";

//MUI
import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Icon,
  ListItemText,
  Typography,
  Paper,
} from "@mui/material";
import Swal from "sweetalert2";
import { blue, grey } from "@mui/material/colors";
import preguntas from "@data/encuestas/encuestas.json";

//Components
const Encuesta = () => {
  //Constants

  return (
    <Paper elevation={0}>
      <Container maxWidth={false} sx={{ backgroundColor: grey }}>
        <h1>Encuesta</h1>
      </Container>
    </Paper>
  );
};

export default Encuesta;
