import React, { Fragment } from "react";
import {
  IconButton,
  ListItem,
  ListItemButton,
  ListItemAvatar,
  ListItemText,
  Avatar,
  Divider
} from "@mui/material";
import Icon from "@mui/material/Icon";

const UserListItem = ({ item, selectedIndex, handleSelectedIndex }) => {
  const handleListItemClick = (event, index) => {
    let data = index;
    handleSelectedIndex(index, data);
  };

  return (
    <Fragment key={`item_${item.id}`}>
      <ListItem
        secondaryAction={
          <IconButton
            edge="end"
            aria-label="info"
            selected={selectedIndex === item.id}
            onClick={event => handleListItemClick(event, item)}
          >
            <Icon sx={{color:"darkblue"}}>visibility</Icon>
          </IconButton>
        }
        disablePadding
      >
        <ListItemButton
          selected={selectedIndex === item.id}
          onClick={event => handleListItemClick(event, item)}
        >
          <ListItemAvatar>
            <Avatar>
              <Icon sx={{fontSize:40}}>account_circle</Icon>
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            primary={`${item.Nombre} ${item.Paterno}`}
            secondary={item.email}
          />
        </ListItemButton>
      </ListItem>
      <Divider variant="inset" component="li" />
    </Fragment>
  );
};

export default UserListItem;
