import { useState, useEffect } from "react";

import CustomTable from "@components/Tables/CustomTable";
import { setDiynamicColumns } from "@utils/Utilities";
import { getColorReverse } from "@utils/Utilities";

const Table = (props) => {
  const { dataTable, summary, loadingTable, tableDetalle = () => {}, page, setPage, setSorted, orderBy } = props;


  const pageSize = 50;

  const columnas = setDiynamicColumns({
    data: dataTable[0],
    columns: [
      {
        id: "Region",
        label: "Región",
        type: "button",
        align: "center",
        onClick: (e) => {
          tableDetalle(e);
        },
      },
      {
        id: "DF",
        label: "DF",
        type: "button",
        align: "center",
        onClick: (e) => {
          tableDetalle(e);
        },
      },
      {
        id: "Municipio",
        label: "Municipio",
        type: "button",
        align: "center",
        onClick: (e) => {
          tableDetalle(e);
        },
      },
      {
        id: "DL",
        label: "Distrito Local",
        type: "button",
        align: "center",
        onClick: (e) => {
          tableDetalle(e);
        },
      },
      {
        id: "Seccion",
        label: "Sección",
        type: "button",
        align: "center",
        onClick: (e) => {
          tableDetalle(e);
        },
      },
      {
        id: "TotalSecciones",
        label: "Total Secciónes",
        type: "text",
        align: "right",
      },
      {
        id: "Promovidos",
        label: "Afines",
        type: "text",
        align: "right",
      },
      {
        id: "Movilizadores",
        label: "Movilizadores",
        type: "text",
        align: "right",
      },
      {
        id: "Movilizados",
        label: "Movilizados",
        type: "text",
        align: "right",
      },
      {
        id: "NoLocalizados",
        label: "No Localizados",
        type: "text",
        align: "right",
      },
      {
        id: "PorcentajePendientes",
        label: "% Pendientes",
        type: "linear",
        align: "center",
        customColors: getColorReverse,
      },
      {
        id: "PorcentajeMovilizados",
        label: "% Movilizados",
        type: "linear",
        align: "center",
      },
      {
        id: "PorcentajeNoLocalizados",
        label: "% No localizados",
        align: "center",
        type: "linear",
      },
    ],
  });

  
  const handleLocalPagination = ({page}) => {
    setPage(page)
  };

  const handleRequestSort=({ orderBy, order })=>{
    setSorted({ id: orderBy, value: order})
  }

  return (
    <CustomTable
      rows={dataTable}
      columns={columnas}
      total={dataTable.length}
      stickyHeader={true}
      maxHeight={600}
      page={page}
      pageSize={pageSize}
      isLoading={loadingTable}
      summary={summary}
      orderBy={orderBy}
      summarySettings={{ start: 0, align: "center" }}
      disableCardType
      handleSort={handleRequestSort}
      handleLocalPagination={handleLocalPagination}
    />

    
  );
};

export default Table;
