import React, { useState, useEffect } from "react";
import {
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  Stack,
  Avatar
} from "@mui/material";
import UserService from "@services/UserServices";
import { getVars } from "@utils/global";
import LoadingForm from "@components/LinearProgress/LoadingForm";
import { UserAccessInterface } from "@data/interfaces/UserInterfaces";
import chrome from '../../assets/img/browsers/google-chrome.png'
import safari from '../../assets/img/browsers/apple-safari.png'
import explorer from '../../assets/img/browsers/internet-explorer.png'
import firefox from '../../assets/img/browsers/mozila-firefox.png'
import opera from '../../assets/img/browsers/opera.png'
import postman from '../../assets/img/browsers/postman.png'

const UserAccess = props => {
  const { data } = props;
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [rows, setRows] = useState([UserAccessInterface]);
  const [total, setTotal] = useState(0);
  const [isLoadigForm, setIsLoadingForm] = useState(false);
  const [isSuccessForm] = useState(false);
  const [openLinearProgress, setOpenLinearProgress] = useState(false);
  const [access_token] = useState(() => {
    let t = getVars("Token");
    if (t !== {})
      return t.access_token;
    return "";
  });

  useEffect(() => {
    setIsLoadingForm(true);
    setOpenLinearProgress(true);
    UserService.getAccessLogs({
      idUsuario: data.id,
      page: page,
      pageSize: rowsPerPage,
      filtered: [],
    }, access_token)
      .then(res => {
        if (res.results) {
          setRows(res.response.data);
          setTotal(res.response.total)
        } else {
        }
      })
      .catch(error => { }).finally(() => {
        setIsLoadingForm(false);
        setOpenLinearProgress(false);
      });
    // eslint-disable-next-line
  }, [page, rowsPerPage, data, access_token]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    const pageSize = parseInt(event.target.value, 10);
    setRowsPerPage(pageSize);
    setPage(0);
  };

  return (
    <Box sx={{ width: "100%", p: 1 }}>
      <LoadingForm
        isLoading={isLoadigForm}
        success={isSuccessForm}
        isOpen={openLinearProgress}
        setIsOpen={() => setOpenLinearProgress(!openLinearProgress)}
        loadinMessage={"Cargando..."}
      />
      <Card className="card-secondary">
        <TableContainer sx={{ maxHeight: 800 }}>
          <Table stickyHeader size="small" aria-label="a dense table">
            <TableHead>
              <TableRow>
                <TableCell align="center" colSpan={6}>
                  <Typography variant="h6">
                    {data.Nombre} {data.Paterno} {data.Materno}
                  </Typography>
                </TableCell>
              </TableRow>
              <TableRow sx={{ fontSize: "0.7rem" }}>
                <TableCell align="center">Navegador</TableCell>
                <TableCell align="center">Tipo de Acceso</TableCell>
                <TableCell align="center">Plataforma</TableCell>
                <TableCell align="center">Sistema Operativo</TableCell>
                <TableCell align="center">IP</TableCell>
                <TableCell align="center">Fecha de Acceso</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row, i) => {
                return (
                  <TableRow
                    key={i}
                    sx={{
                      "& > *": { fontSize: "0.75rem" }
                    }}
                  >
                    <TableCell>

                      <Stack direction="row" alignItems="center" spacing={1}>
                        <Avatar src={
                          row.Navegador === "Chrome" ? chrome :
                          row.Navegador === "Firefox" ? firefox :
                          row.Navegador === "Safari" ? safari :
                          row.Navegador === "Explorer" ? explorer :
                          row.Navegador === "Opera" ? opera :
                          row.Navegador === "PostmanRuntime" ? postman :
                          ""
                        } 
                        sx={{ width: 20, height: 20 }}></Avatar>
                        <Typography>{row.Navegador}</Typography>
                      </Stack>
                    </TableCell>
                    <TableCell>{row.TipoAcceso}</TableCell>
                    <TableCell>{row.Plataforma}</TableCell>
                    <TableCell>{row.DeviceOS}</TableCell>
                    <TableCell>{row.IP}</TableCell>
                    <TableCell>{row.FechaHora}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={total}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelRowsPerPage="Registros por página"
        />
      </Card>

    </Box>
  );
};

export default UserAccess;
