import { PrivilegedRoute, ProtectedRoute } from "@middlewares";
import Audit from "@pages/Audit/Audit";

const AuditoriaRoutes = [
  {
    path: "/dashboard-actividades",
    element: (
      <ProtectedRoute>
        <Audit />
      </ProtectedRoute>
    ),
  },
];

export default AuditoriaRoutes;
