import ApiExec, { downloadFile, uploadFiles, ApiExecPublic } from "@utils/ApiExec";
import { encryptVars, DEBUG } from "@utils/global";
import { handleAttrs } from "@utils/Utilities";

class PrepServices {
    getForm = (params) => {
        return ApiExec(params, "prep/get-form", "POST")
            .then((res) => res)
            .then((res) => res)
            .catch((error) => error);
    };

    saveForm = (params) => {
        return uploadFiles(params, "prep/save-form", "POST")
            .then((res) => res)
            .then((res) => res)
            .catch((error) => error);
    };

    getElectionScope = (params) => {
        return ApiExec(params, "prep/get-election-scope", "POST")
            .then((res) => res)
            .then((res) => res)
            .catch((error) => error);
    };

    getSummary = (params) => {
        return ApiExec(handleAttrs(params), "prep/get-summary", "POST")
            .then((res) => res)
            .then((res) => res)
            .catch((error) => error);
    };

    getChart = (params) => {
        return ApiExec(handleAttrs(params), "prep/get-chart", "POST")
            .then((res) => res)
            .then((res) => res)
            .catch((error) => error);
    };

    getTable = (params) => {
        return ApiExec(handleAttrs(params), "prep/get-table", "POST")
            .then((res) => res)
            .then((res) => res)
            .catch((error) => error);
    };

    exportTable = (params) => {
        return downloadFile({
            api: 'prep/export-table',
            method: 'POST',
            data: params
        })
            .then((res) => res)
            .catch((error) => error);
    };

    getSamplingChart = (params) => {
        return ApiExec(handleAttrs(params), "prep/get-sampling-chart", "POST")
            .then((res) => res)
            .then((res) => res)
            .catch((error) => error);
    };

    /**
     * PARTIDOS 
     */
    getElectoralParties = (params) => {
        return ApiExec(handleAttrs(params), "prep/get-electoral-parties", "POST")
            .then((res) => res)
            .then((res) => res)
            .catch((error) => error);
    };

    findElectoralParties = (id) => {
        const _id = DEBUG ? id : encryptVars(id);
        return ApiExec({}, `prep/parties/${_id}`, "GET")
            .then((res) => res)
            .then((res) => res)
            .catch((error) => error);
    };

    getDetailParties = (id) => {
        const _id = DEBUG ? id : encryptVars(id);
        return ApiExec({}, `prep/get-detail-parties/${_id}`, "GET")
            .then((res) => res)
            .then((res) => res)
            .catch((error) => error);
    };

    saveParty = (params) => {
        return uploadFiles(params, "prep/save-party", "POST")
            .then((res) => res)
            .then((res) => res)
            .catch((error) => error);
    };

    updateParty = (params) => {
        return uploadFiles(params, "prep/update-party", "PUT")
            .then((res) => res)
            .then((res) => res)
            .catch((error) => error);
    };

    deleteParty = (id) => {
        const _id = DEBUG ? id : encryptVars(id);
        return ApiExec({}, `prep/delete-party/${_id}`, "DELETE")
            .then((res) => res)
            .then((res) => res)
            .catch((error) => error);
    };


    /**
     * PLANILLAS
     */
    getFormSummary = (params) => {
        return ApiExec(handleAttrs(params), "prep/get-form-summary", "POST")
            .then((res) => res)
            .then((res) => res)
            .catch((error) => error);
    };

    getCandidates = (params) => {
        return ApiExec(handleAttrs(params), "prep/get-candidates", "POST")
            .then((res) => res)
            .then((res) => res)
            .catch((error) => error);
    };

    findCandidate = (id) => {
        const _id = DEBUG ? id : encryptVars(id);
        return ApiExec({}, `prep/candidates/${_id}`, "GET")
            .then((res) => res)
            .then((res) => res)
            .catch((error) => error);
    };

    saveCandidate = (params) => {
        return uploadFiles(params, "prep/save-candidate", "POST")
            .then((res) => res)
            .then((res) => res)
            .catch((error) => error);
    };

    updateCandidate = (params) => {
        return uploadFiles(params, "prep/update-candidate", "PUT")
            .then((res) => res)
            .then((res) => res)
            .catch((error) => error);
    };

    deleteCandidate = (id) => {
        const _id = DEBUG ? id : encryptVars(id);
        return ApiExec({}, `prep/delete-candidate/${_id}`, "DELETE")
            .then((res) => res)
            .then((res) => res)
            .catch((error) => error);
    };

    getOrderedList = (params) => {
        return ApiExec(handleAttrs(params), "prep/get-ordered-list", "POST")
            .then((res) => res)
            .then((res) => res)
            .catch((error) => error);
    }

    updateOrderForm = (params) => {
        return ApiExec(handleAttrs(params), "prep/update-order-form", "PUT")
            .then((res) => res)
            .then((res) => res)
            .catch((error) => error);
    }

    getElectionScopeReport = (params) => {
        return ApiExec(params, "prep/get-election-scope-report", "POST")
            .then((res) => res)
            .then((res) => res)
            .catch((error) => error);
    };

    getProgressReport = (params) => {
        return ApiExec(params, "prep/get-progress-report", "POST")
            .then((res) => res)
            .then((res) => res)
            .catch((error) => error);
    }

    exportProgressReport = (params) => {
        return downloadFile({
            api: "prep/export-progress-report",
            method: "POST",
            data: params,
        })
            .then((res) => res)
            .catch((e) => e);
    }

    getQuickCount = (params) => {
        return downloadFile({
            api: "prep/get-quick-count",
            method: "POST",
            data: params,
        })
            .then((res) => res)
            .catch((e) => e);
    }
}

const compromiso = new PrepServices();
export default compromiso;