import { ProtectedRoute, PrivilegedRoute } from "@middlewares";
import Assignments from "@pages/DiaD/Assignments";
import WelcomeDiaD from "@pages/MobileDiaD/WelcomeDiaD";
import WelcomeDiaDGeneral from "@pages/MobileDiaD/WelcomeDiaDGeneral";
import Movilizadores from "@pages/MobileDiaD/Movilizadores";

const DiaDRoutes = [
  {
    path: "/dia-d/asignacion-usuarios",
    element: (
      <ProtectedRoute>
        <Assignments />
      </ProtectedRoute>
    ),
  },
  {
    path: "/welcome-dia-d",
    element: (
      <PrivilegedRoute>
        <WelcomeDiaD />
      </PrivilegedRoute>
    ),
  },
  {
    path: "/welcome-dia-d-general",
    element: (
      <PrivilegedRoute>
        <WelcomeDiaDGeneral />
      </PrivilegedRoute>
    ),
  },
  {
    path: "/movilizadores",
    element: (
      <PrivilegedRoute>
        <Movilizadores />
      </PrivilegedRoute>
    ),
  },
];

export default DiaDRoutes;
