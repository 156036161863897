import { useEffect, useState } from "react";
import useWindowDimensions from "@hooks/useWindowDimensions";

import {
  Card,
  CardContent,
  CircularProgress,
  Paper,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  TableBody,
} from "@mui/material";

import { COLUMNS_LNOM } from "@data/constants/Numeralia";
import { numberWithCommas } from "@utils/Utilities/";
import numeralia from "@services/NumeraliaServices";
import Swal from "sweetalert2";

const ListLnom = ({ dataFiltered, title }) => {
  const { width } = useWindowDimensions();
  const paddingCell = width > 1440 ? "2px 20px" : "6px 20px";
  const fontSize = width > 1440 ? width * 0.0085 : 14;

  const [currentDate] = useState(new Date().toLocaleString());
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  const getNumeraliaTerritorialNacional = async (body) => {
    setLoading(true);
    try {
      const result = await numeralia.getNumeraliaTerritorialNacional(body);
      const { results, response, message } = result;
      if (!results) {
        throw new Error(message);
      }
      const [data] = response.data;
      const parseData = Object.keys(data).map((key) => {
        const wordsSeparated = key.split(/(?=[A-Z])/);
        const nameSeparated = wordsSeparated.join(" ");

        return {
          Name: nameSeparated,
          Total: data[key] === null ? "0" : data[key],
        };
      });
      setData(parseData);
    } catch (error) {
      Swal.fire({
        title: error.message,
        icon: "warning",
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const body = {
      idAmbitoAgrupador: dataFiltered.idAmbitoAgrupador,
      filtered: dataFiltered.filtered,
      idEstado: dataFiltered.idEstado,
    };
    dataFiltered.idEstado === null && delete body.idEstado;

    getNumeraliaTerritorialNacional(body);
  }, [dataFiltered]);

  return (
    <Card className="card-primary" sx={{ height: "100%" }}>
      <CardContent>
        <div>
          <Typography variant="subtitle2" fontWeight={600} textAlign={"end"}>
            {title}
          </Typography>
          <Typography variant="body2" textAlign={"end"} color={"GrayText"}>
            {currentDate}
          </Typography>
        </div>
        <div>
          <TableContainer component={Paper} elevation={0} sx={{ mt: 2 }}>
            <Table>
              <TableHead sx={{ backgroundColor: "rgba(189, 189, 189, 0.3)" }}>
                <TableRow>
                  {COLUMNS_LNOM.map((column, idx) => (
                    <TableCell key={idx}>
                      {column.label.toUpperCase()}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {loading ? (
                  <TableRow>
                    <TableCell
                      colSpan={COLUMNS_LNOM.length}
                      align="center"
                      height="200px"
                    >
                      <CircularProgress size={50} />
                    </TableCell>
                  </TableRow>
                ) : (
                  data.map((row, index) => (
                    <TableRow
                      key={row.Nombre}
                      hover
                      tabIndex={-1}
                      sx={{
                        padding: 0,
                        backgroundColor: index % 2 ? "#E4ECFA" : "white",
                      }}
                    >
                      <TableCell
                        sx={{
                          padding: paddingCell,
                          fontSize: fontSize,
                        }}
                      >
                        {row.Name}
                      </TableCell>
                      <TableCell
                        sx={{
                          padding: paddingCell,
                          fontSize: fontSize,
                          textAlign: "right",
                        }}
                      >
                        {numberWithCommas(row.Total)}
                      </TableCell>
                    </TableRow>
                  ))
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </CardContent>
    </Card>
  );
};

export default ListLnom;
