import { useEffect, useState } from "react";
import AdminLayout from "@components/MainPage/AdminLayout";
import ActionHeader from "@components/Containers/ActionHeader";
import { Container, CardContent, Rating, Chip, Stack,Box,Button,Icon } from "@mui/material";
import MessageAlert from "@components/Downloads/MessageAlert";
import Filter from "@components/Structure/Filter";
import { useNavigate } from "react-router-dom";
import BasicTable from "@components/Tables/BasicTable";
import Card from "@components/Card/Card";
import CardCommitments from "@components/Card/StatesCard";
import Download from "@components/Downloads/Download";
import Grid from "@mui/material/Unstable_Grid2";
import SocialService from "@services/SocialServices";
import Swal from "sweetalert2";
import StarIcon from "@mui/icons-material/Star";
import middleware from "@middlewares/middleware";
import { getVars } from "@utils/global";

const SocialCommitments = (props) => {
  const [access_token] = useState(() => {
    let t = getVars("Token");
    if (Object.keys(t).length !== 0) {
      return t.access_token;
    } else {
      return [];
    }
  });
  
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [total  , setTotal ] = useState(0);
  const [loading , setLoading ] = useState(false);
  const [loadingCharts , setLoadingChart ] = useState(false);
  const [chartCompromisos,setChartCompromisos] = useState({});
  const [tableData, setTableData] = useState({
    page: 0,
    pageSize: 10,
    filtered: [],
    sorted: [{ id: "compromisos_segmentos.FechaCreo", value: "DESC" }],
  });
  const [dataCompromisos,setDataCompromisos] = useState({
    TotalEstructuras: 0,
    TotalCompromisos: 0,
    compromisos: {
      TotalCompromisos: 0,
      TotalMasculino: 0,
      TotalFemenino: 0,
      TotalBinario: 0,
    },
    responsables: {
      TotalResponsables: 0,
      TotalMasculino: 0,
      TotalFemenino: 0,
      TotalBinario: 0,
    },
    enlaces: {
      TotalEnlaces: 0,
      TotalMasculino: 0,
      TotalFemenino: 0,
      TotalBinario: 0,
    },
  });
  const [successDownload, setSuccessDownload] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);


  var today = new Date();

  const columns = [
    { id: "Region", label: "Región" },
    { id: "Municipio", label: "Municipio" },
    { id: "Seccion", label: "Sección" },
    { id: "Segmento", label: "Etiquetas",columnAction: false, width: 350},
    { id: "NombreCompleto", label: "Nombre" },
    { id: "Ranking", label: "Ranking" },
  ];

  const getList = () => {
    SocialService.getCompromisos(tableData)
      .then((res) => {
        if (res.results) {
          setData(
            res.response.data.map((item) => {
              return {
                ...item,
                Segmento: (
                  <Stack
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    spacing={0.5}
                    sx={{display:"contents"}}
                  >
                    {item.Segmento.split(",").map((i) => (
                      <Chip label={i} color="primary" variant="outlined" size="small" />
                    ))}
                  </Stack>
                ),
                Ranking: (
                  <Rating
                    name="text-feedback"
                    value={item.Ranking/10}
                    readOnly
                    precision={0.5}       
                    emptyIcon={
                      <StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />}
                  />
                ),
              };
            })
          );
          setTotal(res.response.total);
        } else {
          Swal.fire({ title: res.message, icon: "warning" });
        }
      })
      .catch((e) => {
        Swal.fire({ title: e.message, icon: "warning" });
      })
      .finally(()=> setLoading(false));
  };

  const getTotalCompromiso = () => {
    SocialService.getTotalesSocial(chartCompromisos)
    .then((res) => {
      if (res.results) {
        setDataCompromisos(res.response);
      } else {
        Swal.fire({ title: res.message, icon: "warning" });
      }
    })
    .catch((e) => {
      Swal.fire({ title: e.message, icon: "warning" });
    })
    .finally(()=> setLoadingChart(false));
  };


  useEffect(() => {
    setLoading(true);
    setLoadingChart(true);
    getList();
    getTotalCompromiso();
    // eslint-disable-next-line
  }, [tableData]);

  const handleChangePagination = (pagination) => {
    setTableData({ ...tableData, ...pagination });
  };

  const handleChangeFilter = (type) => {
    const {
      Region,
      Municipio,
      Poligono,
      Seccion,
      Segmento,
      Celular,
      ClaveElector,
      NombreCompleto,
      Calle,
      Colonia,
      Sexo,
      Edad
    } = type;

    let filtered = [];
    let filtro = [];

    if (Region > 0) {
      filtered = [
        ...filtered,
        { id: "cat_secciones.idRegion", filter: "=", value: Region },
      ];

     /*  filtro = [
        ...filtro,
        { id: "cat_region.idRegion", filter: "=", value: Region },
      ]; */
    }
    if (Municipio > 0) {
      filtered = [
        ...filtered,
        { id: "cat_secciones.idMunicipio", filter: "=", value: Municipio },
      ];

    /*   filtro = [
        ...filtro,
        { id: "meta_municipio_segmento.idMunicipio", filter: "=", value: Municipio },
      ]; */
    }
    if (Poligono.length > 0) {
      filtered = [
        ...filtered,
        { id: "cat_secciones.idPoligono", filter: "IN", value: Poligono },
      ];

    /*   filtro = [
        ...filtro,
        { id: "cat_secciones.idPoligono", filter: "IN", value: Poligono },
      ]; */
    }
    if (Seccion.length > 0) {
      filtered = [
        ...filtered,
        { id: "cat_secciones.Seccion", filter: "IN", value: Seccion },
      ];

     /*  filtro = [
        ...filtro,
        { id: "cat_secciones.Seccion", filter: "IN", value: Seccion },
      ]; */
    }
    if (Segmento.length > 0) {
      filtered = [
        ...filtered,
        { id: "cat_social.id", filter: "IN", value: Segmento },
      ];

      filtro = [
        ...filtro,
        { id: "cat_social.id", filter: "IN", value: Segmento },
      ];
    }
    if (Celular.length > 0) {
      filtered = [
        ...filtered,
        {
          id: "compromisos_unicos.Celular",
          filter: "LIKE",
          value: Celular,
        },
      ];

    }
    if (ClaveElector.length > 0) {
      filtered = [
        ...filtered,
        {
          id: "compromisos_unicos.INE",
          filter: "=",
          value: ClaveElector,
        },
      ];
    }
    if (NombreCompleto.length > 0) {
      filtered = [
        ...filtered,
        {
          id: [
            "compromisos_unicos.Nombre",
            "compromisos_unicos.Paterno",
            "compromisos_unicos.Materno",
          ],
          filter: "LIKE",
          value: NombreCompleto,
        },
      ];
    }
    if (Calle.length > 0) {
      filtered = [
        ...filtered,
        {
          id: "compromisos_unicos.CalleVive",
          filter: "LIKE",
          value: Calle,
        },
      ];
    }
    if (Colonia.length > 0) {
      filtered = [
        ...filtered,
        {
          id: "compromisos_unicos.ColoniaVive",
          filter: "LIKE",
          value: Colonia,
        },
      ];
    }
    if (Edad[0] > 17 && Edad[1] < 101) {
      filtered = [
        ...filtered,
        {
          id: "TIMESTAMPDIFF(YEAR, compromisos_unicos.FechaNacimiento, CURDATE())",
          filter: ">=",
          value: Edad[0],
        },
        {
          id: "TIMESTAMPDIFF(YEAR, compromisos_unicos.FechaNacimiento, CURDATE())",
          filter: "<=",
          value: Edad[1],
        },
      ];
    }
    if (Sexo > 0) {
      filtered = [
        ...filtered,
        { id: "compromisos_unicos.idSexo", filter: "=", value: Sexo },
      ];
    }

    setTableData({
      ...tableData,
      filtered: filtered,
    });

    setChartCompromisos({filtered: filtro})
  };

  const handleExport = () =>{
      setIsDownloading(true);
      SocialService.exportCompromisos({filtered:tableData.filtered},access_token)
        .then((res) => {
          if (res.success) {
            setShowMessage(true);
            setSuccessDownload(true);
          } else {
            setShowMessage(true);
            setSuccessDownload(false);
          }
        })
        .catch((error) => {
          setShowMessage(true);
          setSuccessDownload(false);
        })
        .finally(() => setIsDownloading(false));
    };

  return (
    <AdminLayout>
      <Container maxWidth="false">
        <ActionHeader
          title="Compromisos"
          handleclick={() => navigate("/socialcompromisos")}
        />
        <Grid container spacing={2}>
          <Grid xs={6}>
            <CardCommitments
              icon={"groups"}
              title={"Total Compromisos"}
              total={`${dataCompromisos.compromisos.TotalCompromisos} ${dataCompromisos.MetaCompromisos ? `de ${dataCompromisos.MetaCompromisos}`: ""}`}
              subtitle1={"Mujeres"}
              subtotal1={dataCompromisos.compromisos.TotalFemenino}
              subtitle2={"Hombres"}
              subtotal2={dataCompromisos.compromisos.TotalMasculino}
              subtitle3={"Binario"}
              subtotal3={dataCompromisos.compromisos.TotalBinario}
              subIcon={"update"}
              subFooter={today.toLocaleDateString()}
              loading={loadingCharts}
            />
          </Grid>
          <Grid xs={6}>
            <CardCommitments
              icon={"perm_identity"}
              title={"Total Responsables"}
              total={dataCompromisos.responsables.TotalResponsables}
              subtitle1={"Mujeres"}
              subtotal1={dataCompromisos.responsables.TotalFemenino}
              subtitle2={"Hombres"}
              subtotal2={dataCompromisos.responsables.TotalMasculino}
              subtitle3={"Binario"}
              subtotal3={dataCompromisos.responsables.TotalBinario}
              subIcon={"update"}
              subFooter={today.toLocaleDateString()}
              loading={loadingCharts}
            />
          </Grid>
        </Grid>

        <Card className="card-primary">
          <CardContent>
            <Filter onChangeFilter={(e) => handleChangeFilter(e)} />
          </CardContent>
        </Card>

        <Card className="card-primary">
          <CardContent>
          {middleware.checkMenuActionId("Exportar") &&
          <>
          {isDownloading ? (
              <Download isDownload={isDownloading} format="xlsx" />
            ) : (
              <Box display={"flex"} justifyContent={"flex-end"} sx={{mb:2}}>
                <Button
                  variant="outlined"
                  color="primaryDark"
                  startIcon={<Icon>download</Icon>}
                  size="small"
                  onClick={handleExport}
                  sx={{ borderRadius: "10px" }}
                >
                  Exportar
                </Button>
              </Box>
            )
            }</>}

          {showMessage && (
              <Box marginTop={2}>
                <MessageAlert
                  showMessage={showMessage}
                  successDownload={successDownload}
                  setShowMessage={setShowMessage}
                />
              </Box>
            )}

            <BasicTable
              rows={data}
              hcolumns={columns}
              pageProp={tableData.page}
              pageSize={tableData.pageSize}
              total={total}
              handleChangePagination={handleChangePagination}
              isLoading={loading}
              stickyHeader={true}
            />
          </CardContent>
        </Card>
      </Container>
    </AdminLayout>
  );
};
export default SocialCommitments;
