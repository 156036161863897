import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import middleware from "@middlewares/middleware";
// Material UI
import {
  Container,
  Typography,
  Card,
  CardContent,
  Stack,
  Icon,
  CardMedia,
  Divider,
  Box,
  Snackbar,
  IconButton,
  Alert,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import QRCode from "react-qr-code";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
// Componentes
import AdminLayout from "@components/MainPage/AdminLayout";
import CardIconColor from "@components/Card/CardIconColor";

// Utils
import { getVars, setVars } from "@utils/global";
import { URL_PUBLICA_FRONT, ClipboardCopy } from "@utils/global";

// Services
import UserServices from "@services/UserServices";

// catalogos
import { useCatalogs } from "@hooks/useCatalogs";

const Welcome = () => {
  const navigate = useNavigate();
  const { user, ruta_rg, casilla_rc, menus } = getVars("Token");
  const diadMobile = menus.some((item) => item.Ruta === "/welcome-dia-d");
  const diadMobileGeneral = menus.some((item) => item.Ruta === "/welcome-dia-d-general");
  const routeDiadMobile = diadMobile ? "/welcome-dia-d" : "/welcome-dia-d-general";
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const catalogsOptions = [
    {
      id: "entidades",
      filtered: [{ id: "id", value: user.idEstado, filter: "=" }],
    },
  ];
  const username = `${user.Nombre} ${user.Paterno} ${user.Materno}`.split(" ");
  let delayNumber = 0;
  const [shortUrl, setShortUrl] = useState("");
  const [totalesAvance, setTotalesAvance] = useState({
    TotalWeb: 0,
    TotalGuest: 0,
  });
  const [isHovered, setIsHovered] = useState(-1);
  const [fechaFin, setFechaFin] = useState();
  const [loading, setLoading] = useState(true);
  const [timeRemaining, setTimeRemaining] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });
  const [open, setOpen] = useState(true);
  const { catalogs, load } = useCatalogs({
    catalogsOptions: catalogsOptions,
    putDefaultOption: false,
  });

  const [message, setMessage] = useState(``);
  const [messageLink, setMessageLink] = useState(``);

  const validateCheckMenuActionId = middleware.checkMenuActionId("Ver", 256);

  useEffect(() => {
    if (user.ambito_perfil.filter((item) => item.idTipo === 5).length) {
      let perfil = user.ambito_perfil.filter((item) => item.idTipo === 5);
      setMessage(
        `Copia el siguiente link para invitar a tus familiares y amigos a formar parte 
        de tu red para defender al ${!load ? perfil[0].AmbitoVisualizacion : ""}  ${
          !load ? perfil[0].LabelAmbitoValorCargo : ""
        }.`
      );
      setMessageLink(`Hola te invito a defender al ${!load ? perfil[0].AmbitoVisualizacion : ""} ${
        !load ? perfil[0].LabelAmbitoValorCargo : ""
      }, 
      sé parte de mi red registrándote en ${shortUrl}`);
    } else {
      setMessage(
        `Copia el siguiente link para invitar a tus familiares y 
        amigos a formar parte de tu red para defender a ${!load ? catalogs.entidades[0]?.label : ""}.`
      );
      setMessageLink(
        `Hola te invito a defender a ${
          !load ? catalogs.entidades[0]?.label : ""
        }, sé parte de mi red registrándote en ${shortUrl}`
      );
    }
    // eslint-disable-next-line
  }, [load]);

  useEffect(() => {
    if (Object.keys(user).length > 0) {
      setShortUrl(`${URL_PUBLICA_FRONT}invitado/${user.idEstado}/${user.UUID}`);
    }

    getAvances();

    if (fechaFin) {
      // Calcula el tiempo restante
      const targetDate = new Date(fechaFin); // Cambia a tu fecha objetivo
      const now = new Date();

      const timeDiff = targetDate - now;

      if (timeDiff > 0) {
        const days = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
        const hours = Math.floor((timeDiff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const minutes = Math.floor((timeDiff % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((timeDiff % (1000 * 60)) / 1000);

        setTimeRemaining({ days, hours, minutes, seconds });

        // Actualiza la cuenta regresiva cada segundo
        const countdownInterval = setInterval(() => {
          if (timeDiff > 0) {
            const updatedDiff = targetDate - new Date();
            const updatedDays = Math.floor(updatedDiff / (1000 * 60 * 60 * 24));
            const updatedHours = Math.floor((updatedDiff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
            const updatedMinutes = Math.floor((updatedDiff % (1000 * 60 * 60)) / (1000 * 60));
            const updatedSeconds = Math.floor((updatedDiff % (1000 * 60)) / 1000);

            setTimeRemaining({
              days: updatedDays,
              hours: updatedHours,
              minutes: updatedMinutes,
              seconds: updatedSeconds,
            });
          } else {
            clearInterval(countdownInterval);
          }
        }, 1000);
      }
    }
    //eslint-disable-next-line
  }, [fechaFin]);

  /*useEffect(() => {
    if (Object.keys(user).length > 0) {
      setShortUrl(`${URL_PUBLICA_FRONT}invitado/${user.UUID}`);
    }
  }, []); */

  const getAvances = () => {
    UserServices.getAvanceCompromisos({})
      .then((res) => {
        if (res.results) {
          setTotalesAvance(res.response.data);
          setFechaFin(res.response.FechaFin);
        } else {
          setTotalesAvance({
            TotalWeb: 0,
            TotalGuest: 0,
          });
        }
      })
      .catch((e) => {
        Swal.fire({ title: e.message, icon: "warning" });
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    setOpen(true);
  }, []);

  return (
    <AdminLayout>
      <style>{` @keyframes bounce {
                  0%,100%{ transform:translate(0); }
                  25%{ transform:rotateX(20deg) translateY(2px) rotate(-3deg); }
                  50%{ transform:translateY(-10px) rotate(3deg) scale(1.05);  }
      }`}</style>
      <div>
        <Snackbar
          open={open}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          sx={{ mt: "50px" }}
          onClose={() => setOpen(false)}
        >
          <Alert
            severity="info"
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setOpen(false);
                }}
              >
                <Icon fontSize="inherit">close</Icon>
              </IconButton>
            }
            sx={{
              mb: 2,
              fontSize: "20px", // Tamaño de la fuente personalizado
              "& .MuiAlert-icon": {
                display: "none", // Oculta el icono de info
              },
            }}
          >
            {!load ? catalogs.entidades[0]?.label : ""}
          </Alert>
        </Snackbar>
      </div>
      <Container
        component="main"
        sx={{
          "&:hover .span-word": { animation: "bounce .6s" },
          width: "100%",
        }} /* maxWidth="lg" */
      >
        <Grid2 container columnSpacing={1} sx={{ display: "flex", justifyContent: "center" }}>
          {user.linkToShare !== 0 ? (
            <Grid2 lg={4} xs={12} md={12}>
              <Card className="card-primary" sx={{ textAlign: "center", maxHeight: "557px" }}>
                <CardMedia
                  component={() => (
                    <Box sx={{ mt: 3 }}>
                      <QRCode value={shortUrl} />
                    </Box>
                  )}
                  alt="QR Code"
                  title="QR Code"
                  sx={{ marginBottom: "20px" }}
                />
                <CardContent
                  sx={{
                    flexGrow: 1,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    border: "10px",
                  }}
                >
                  <Typography variant="h5" component="div" align="center" color="#303F9F" gutterBottom>
                    ¡Hola!{" "}
                    {username.map((item, index) => (
                      <span key={index} style={{ display: "inline-block", marginRight: 8 }}>
                        {item.split("").map((word, i) => (
                          <span
                            className="span-word"
                            key={i}
                            style={{
                              display: "inline-block",
                              animationDelay: `${delayNumber++ * 0.05}s`,
                            }}
                          >
                            {word}
                          </span>
                        ))}
                      </span>
                    ))}
                  </Typography>
                  <Typography
                    variant="body1"
                    align="center"
                    sx={{ marginBottom: "20px", whiteSpace: "pre-wrap" }}
                  >
                    {message}
                  </Typography>
                  <Typography
                    variant="caption"
                    align="center"
                    component={Link}
                    href={shortUrl}
                    target="_blank"
                    color="#303F9F"
                    sx={{
                      fontSize: "15px",
                      marginBottom: "20px",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    {shortUrl}
                  </Typography>
                  <ClipboardCopy copyText={messageLink} />
                </CardContent>
              </Card>
            </Grid2>
          ) : (
            <Grid2 lg={12} xs={12} md={12}>
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <Typography variant="h5" fontWeight={700} sx={{ mb: 2 }}>
                  ¡Hola!{" "}
                  {username.map((item, index) => (
                    <span key={index} style={{ display: "inline-block", marginRight: 8 }}>
                      {item.split("").map((word, i) => (
                        <span
                          className="span-word"
                          key={i}
                          style={{
                            display: "inline-block",
                            animationDelay: `${delayNumber++ * 0.05}s`,
                          }}
                        >
                          {word}
                        </span>
                      ))}
                    </span>
                  ))}
                </Typography>
              </Box>
            </Grid2>
          )}
          <Grid2 container xs={12} lg={user.linkToShare !== 0 ? 7 : 12} spacing={1}>
            <Grid2 xs={12} md={4}>
              <CardIconColor
                title="Invitados"
                total={totalesAvance.TotalGuest}
                color="info"
                icon={"schema"}
                sx={{ height: "215px" }}
                loading={loading}
              />
            </Grid2>
            <Grid2 xs={12} md={4}>
              <CardIconColor
                title="Compromisos"
                total={totalesAvance.TotalWeb}
                color="success"
                icon={"schema"}
                sx={{ height: "215px" }}
                loading={loading}
              />
            </Grid2>
            <Grid2 xs={12} md={4}>
              <CardIconColor
                title={`${timeRemaining.hours} horas, ${timeRemaining.minutes} minutos, ${timeRemaining.seconds} segundos`}
                total={timeRemaining.days}
                totalTextR="días"
                color="warning"
                icon={"schema"}
                sx={{ height: "215px" }}
              />
            </Grid2>
            {middleware.checkMenuActionId("Ver", 256) && (
              <Grid2 lg={6} xs={12} md={6}>
                <Card
                  className="card-primary"
                  onClick={() => navigate("/compromisos-registro")}
                  onMouseEnter={() => setIsHovered(1)}
                  onMouseLeave={() => setIsHovered(-1)}
                  sx={{
                    minHeight: 330,
                    borderRadius: 10,
                    mt: { xs: 0, md: -2 },
                    boxShadow:
                      isHovered === 1 ? "0px 8px 16px rgba(0, 0, 0, 0.3)" : "0px 2px 4px rgba(0, 0, 0, 0.2)",
                    transition: "box-shadow 0.3s ease, transform 0.3s ease, background-color 0.3s ease",
                    transform: isHovered === 1 ? "scale(1.05)" : "scale(1)",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    backgroundColor: isHovered === 1 ? "#E3F2FD" : "#ffffff",
                    cursor: isHovered === 1 ? "pointer" : "default",
                    zIndex: isHovered === 1 ? 9999999999 : "auto",
                  }}
                >
                  <CardContent>
                    <Icon fontSize="large" style={{ color: "#007bff" }}>
                      edit_note
                    </Icon>{" "}
                    {/* Icono con color azul */}
                    <Typography
                      variant="h5"
                      sx={{
                        textAlign: "center",
                        marginTop: 2,
                        color: "#007bff",
                      }} // Título con color azul
                    >
                      Captura
                    </Typography>
                    <Typography
                      variant="body1"
                      sx={{
                        textAlign: "center",
                        marginTop: 2,
                        color: "#495057",
                      }} // Texto con color gris oscuro
                    >
                      Comienza a registrar tus simpatizantes.
                    </Typography>
                    <br />
                    <Divider color={"#00000"} />
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "end",
                        justifyContent: "end",
                      }}
                    >
                      <Typography variant="body2" sx={{ color: "#FF4081" }}>
                        Ir a Captura de Compromisos{" "}
                      </Typography>{" "}
                      {/* Texto con color rosa */}
                      <Icon style={{ color: "#FF4081" }}>arrow_forward</Icon> {/* Flecha con color rosa */}
                    </Box>
                  </CardContent>
                </Card>
              </Grid2>
            )}
            <Grid2
              xs={12}
              md={middleware.checkMenuActionId("Ver", 256) ? 6 : 12}
              lg={middleware.checkMenuActionId("Ver", 256) ? 6 : 12}
            >
              <Card
                className="card-primary"
                onClick={() => navigate("/mi-perfil")}
                onMouseEnter={() => setIsHovered(2)}
                onMouseLeave={() => setIsHovered(-1)}
                sx={{
                  minHeight: 330,
                  borderRadius: 10,
                  mt: { xs: 0, md: -2 },
                  boxShadow:
                    isHovered === 2 ? "0px 8px 16px rgba(0, 0, 0, 0.3)" : "0px 2px 4px rgba(0, 0, 0, 0.2)",
                  transition: "box-shadow 0.3s ease, transform 0.3s ease, background-color 0.3s ease",
                  transform: isHovered === 2 ? "scale(1.05)" : "scale(1)",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  backgroundColor: isHovered === 2 ? "#FCE4EC" : "#ffffff", // Cambia el color de fondo al acercar el cursor
                  cursor: isHovered === 2 ? "pointer" : "default",
                }}
              >
                <CardContent sx={{ textAlign: "center" }}>
                  <Grid2 container spacing={2}>
                    <Grid2 lg={12} xs={12} md={12}>
                      <Stack direction={{ xs: "column", sm: "row" }} spacing={1}>
                        <Icon sx={{ color: "darkblue" }}>manage_accounts</Icon>
                        <Typography sx={{ fontSize: 20 }} color="darkblue">
                          Mi Perfil
                        </Typography>
                      </Stack>
                    </Grid2>
                    <Grid2 lg={12} xs={12} md={12}>
                      <ul
                        style={{
                          paddingLeft: "20px",
                          listStyleType: "none",
                        }}
                      >
                        <li
                          style={{
                            display: "flex",
                            alignItems: "center",
                            marginBottom: "8px",
                          }}
                        >
                          <Icon sx={{ marginRight: "8px" }}>phonelink_off</Icon>
                          <Typography variant="overline" display="block" gutterBottom>
                            Desvinculación
                          </Typography>
                        </li>
                        <li
                          style={{
                            display: "flex",
                            alignItems: "center",
                            marginBottom: "8px",
                          }}
                        >
                          <Icon sx={{ marginRight: "8px" }}>password</Icon>
                          <Typography variant="overline" display="block" gutterBottom>
                            Cambio Contraseña
                          </Typography>
                        </li>
                        <li
                          style={{
                            display: "flex",
                            alignItems: "center",
                            marginBottom: "8px",
                          }}
                        >
                          <Icon sx={{ marginRight: "8px" }}>dialpad</Icon>
                          <Typography variant="overline" display="block" gutterBottom>
                            Cambio PIN
                          </Typography>
                        </li>
                      </ul>
                    </Grid2>
                  </Grid2>
                </CardContent>
              </Card>
            </Grid2>

            {(diadMobile || diadMobileGeneral) && isMobile && (
              <Grid2 xs={12}>
                <Card
                  className="card-primary"
                  onClick={() => navigate(routeDiadMobile)}
                  sx={{
                    mb: "0 !important",
                    minHeight: 300,
                    height: "100%",
                    borderRadius: 10,
                    transition: "box-shadow 0.3s ease, transform 0.3s ease, background-color 0.3s ease",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.2)",
                  }}
                >
                  <CardMedia
                    component={"img"}
                    src={require("@assets/img/_c27.png")}
                    alt="PAN Logo"
                    sx={{ p: 4 }}
                  />
                  <CardContent>
                    <Typography textAlign={"center"} fontWeight={600}>
                      DÍA D
                    </Typography>
                  </CardContent>
                </Card>
              </Grid2>
            )}
          </Grid2>
        </Grid2>
      </Container>
    </AdminLayout>
  );
};

export default React.memo(Welcome);
