import { useState, useEffect } from "react";
import Swal from "sweetalert2";

// Material UI
import { Grid, Button, Typography } from "@mui/material";

// Componentes
import AdminLayout from "@components/MainPage/AdminLayout";
import ActionHeader from "@components/Containers/ActionHeader";
import FloatingButton from "@components/Button/FloatingButton";
import LoadingForm from "@components/LinearProgress/LoadingForm";
import DrawerRelative from "@components/Containers/DrawerRelative";
import Filter from "@components/Activation/DashboardElectoral/Filter";
import ChartCumulativeProgress from "@components/Activation/DashboardElectoral/ChartCumulativeProgress";
import ChartBingoParticipation from "@components/Activation/DashboardElectoral/ChartBingoParticipation";
import ChartBingoGoal from "@components/Activation/DashboardElectoral/ChartBingoGoal";
import TableMobilization from "@components/Activation/DashboardElectoral/TableMobilization";
import LastUpdatedCard from "@components/Activation/DashboardElectoral/LastUpdatedCard";
import AdaptiveCard from "@components/Card/AdaptiveCard";

// Iconos
import { KeyboardDoubleArrowRight, KeyboardDoubleArrowLeft } from "@mui/icons-material";

// Servicios
import MovilizationServices from "@services/MovilizationServices";

const DashboardElectoralTerritorial = () => {
  const [ambitoUser, setAmbitoUser] = useState("");

  const [openDrawer, setOpenDrawer] = useState(true);
  const [loadingCards, setLoadingCards] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [loading, setLoading] = useState({ isLoading: false, isSuccess: false, open: false });

  const [idAmbitoAgrupador, setIdAmbitoAgrupador] = useState(ambitoUser);
  const [idFilter, setIdFilter] = useState(ambitoUser);
  const [catView, setCatView] = useState([]);

  const [data, setData] = useState({
    page: 0,
    pageSize: 10,
    filtered: [],
    sorted: [],
    idAmbitoAgrupador: ambitoUser,
  });
  const [bodyChart, setBodyChart] = useState({ filtered: [], idAmbitoAgrupador: ambitoUser });
  const [dataChart, setDataChart] = useState({ Fecha: "", Movilizacion: {}, ParticipacionCiudadana: {} });

  const handleSearchFilter = ({ filtereds }) => {
    setData((prev) => ({ ...prev, filtered: filtereds, page: 0, pageSize: 10 }));
    setBodyChart((prev) => ({ ...prev, filtered: filtereds }));
  };

  const handleClearFilter = () => {
    if (data.filtered.length > 0 || data.idAmbitoAgrupador !== ambitoUser) {
      setData((prev) => ({ ...prev, filtered: [], idAmbitoAgrupador: ambitoUser }));
      setBodyChart((prev) => ({ ...prev, filtered: [], idAmbitoAgrupador: ambitoUser }));
      setIdAmbitoAgrupador(ambitoUser);
    }
  };

  const handleRefresh = () => setRefresh(true);

  const cardsTerritorial = async (bodyChart) => {
    setLoadingCards(true);
    setLoading({ ...loading, isLoading: true, open: true });

    try {
      const { idAmbitoAgrupador, ...params } = bodyChart;
      const res = await MovilizationServices.getCardsElectoral(params);

      const { results, message, response } = res;

      if (results) {
        setDataChart(response.data);
        setRefresh(false);
      } else throw new Error(message);
    } catch (error) {
      Swal.fire({ title: error.message, icon: "warning" });
      setDataChart({ Fecha: "", Movilizacion: {}, ParticipacionCiudadana: {} });
    } finally {
      setLoading({ ...loading, isLoading: false, open: false });
      setLoadingCards(false);
    }
  };

  // Catalogos dinamicos
  const getCatView = async () => {
    try {
      const res = await MovilizationServices.getAmbitoAgrupadorMovilizacion();
      const { results, response, message } = res;

      if (results) {
        const data = response.data.sort((a, b) => a.value - b.value);
        setCatView(data);
        setAmbitoUser(data[0]?.value);
      } else throw new Error(message);
    } catch (error) {
      Swal.fire({ title: error.message, icon: "warning" });
    }
  };

  useEffect(() => {
    getCatView();
    // eslint-disable-next-line
  }, []);

  // Cargar info de las cards
  useEffect(() => {
    cardsTerritorial(bodyChart);
    // eslint-disable-next-line
  }, [bodyChart]);

  // Carga inicial de datos ya con el ambito
  useEffect(() => {
    if (ambitoUser) {
      setIdAmbitoAgrupador(ambitoUser);
      setBodyChart((prev) => ({ ...prev, filtered: [], idAmbitoAgrupador: ambitoUser }));
      setData((prev) => ({ ...prev, page: 0, pageSize: 10, filtered: [], idAmbitoAgrupador: ambitoUser }));
    }
    // eslint-disable-next-line
  }, [ambitoUser]);

  // Cambio de agrupado en filtro
  const changeTypeViewFilter = (view) => {
    setIdAmbitoAgrupador(view);
    setBodyChart((prev) => ({ ...prev, filtered: [], idAmbitoAgrupador: view }));
    setData((prev) => ({ ...prev, page: 0, pageSize: 10, filtered: [], idAmbitoAgrupador: view }));
  };

  return (
    <AdminLayout>
      <LoadingForm
        loadinMessage={"Cargando datos..."}
        isLoading={loading.isLoading}
        success={loading.isSuccess}
        isOpen={loading.open}
        setIsOpen={() => setLoading({ ...loading, open: false })}
      />

      <ActionHeader title={"Dashboard Bingo Electoral"} isCustom={true}>
        <Button
          onClick={() => setOpenDrawer(!openDrawer)}
          aria-label="open Drawer"
          endIcon={openDrawer ? <KeyboardDoubleArrowRight /> : <KeyboardDoubleArrowLeft />}
        >
          <Typography sx={{ fontWeight: 500 }}>Filtros</Typography>
        </Button>
      </ActionHeader>

      <Grid
        container
        spacing={openDrawer ? 2 : 0}
        sx={{ flexDirection: { xs: "column", md: "row-reverse" } }}
      >
        <Grid item xs={12} md={openDrawer ? 4 : 0}>
          <DrawerRelative
            anchor="right"
            open={openDrawer}
            setOpen={setOpenDrawer}
            title="Filtrar"
            icon="person_search"
            sxPaper={{ borderRadius: 3 }}
            className="card-primary"
            isSticky
            screenHeight="md"
          >
            <Filter
              onSearch={handleSearchFilter}
              onClear={handleClearFilter}
              changeTypeView={changeTypeViewFilter}
              setIdFilter={setIdFilter}
              catView={catView}
              refresh={refresh}
              setLoading={setLoading}
              ambitoUser={ambitoUser}
            />
          </DrawerRelative>
        </Grid>
        <Grid item xs={12} md={openDrawer ? 8 : 12}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={openDrawer ? 12 : 4} lg={4}>
                  <LastUpdatedCard
                    dateHour={dataChart?.Fecha || ""}
                    handleClick={handleRefresh}
                    refresh={refresh}
                    columnIn={openDrawer ? "lg" : "md"}
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={openDrawer ? 6 : 4} lg={4}>
                  <AdaptiveCard
                    title="Participación"
                    icon="people"
                    config={{
                      title: "Total de votación",
                      data: dataChart?.ParticipacionCiudadana?.Total || 0,
                    }}
                    leftConfig={{
                      title: "Compromisos",
                      data: dataChart?.ParticipacionCiudadana?.VotacionCompromisos || 0,
                      percent: dataChart?.ParticipacionCiudadana?.PorcentajeVotacionCompromisos || 0,
                    }}
                    rightConfig={{
                      title: "Otros",
                      data: dataChart?.ParticipacionCiudadana?.VotacionCiudadana || 0,
                      percent: dataChart?.ParticipacionCiudadana?.PorcentajeVotacionCiudadana || 0,
                    }}
                    fixed
                    adjust
                    loading={loadingCards}
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={openDrawer ? 6 : 4} lg={4}>
                  <AdaptiveCard
                    title="Avance de Bingos"
                    icon="description"
                    config={{
                      title: "Meta Bingo",
                      data: dataChart?.Movilizacion?.Meta || 0,
                    }}
                    leftConfig={{
                      title: "Votación Bingo",
                      data: dataChart?.Movilizacion?.VotacionCompromisos || 0,
                      percent: dataChart?.Movilizacion?.PorcentajeVotacionCompromisos || 0,
                    }}
                    fixed
                    adjust
                    loading={loadingCards}
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <ChartBingoParticipation
                body={bodyChart}
                setLoading={setLoading}
                openDrawer={openDrawer}
                ambitoUser={ambitoUser}
              />
            </Grid>

            <Grid item xs={12}>
              <ChartBingoGoal
                body={bodyChart}
                setLoading={setLoading}
                openDrawer={openDrawer}
                ambitoUser={ambitoUser}
              />
            </Grid>

            <Grid item xs={12}>
              <ChartCumulativeProgress
                body={bodyChart}
                setLoading={setLoading}
                openDrawer={openDrawer}
                ambitoUser={ambitoUser}
              />
            </Grid>

            <Grid item xs={12}>
              <TableMobilization
                data={data}
                idFilter={idFilter}
                catView={catView}
                idAmbitoAgrupador={idAmbitoAgrupador}
                ambitoUser={ambitoUser}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      {!openDrawer && (
        <FloatingButton
          onClick={() => setOpenDrawer(true)}
          label="Mostrar Filtros"
          sx={{ display: { xs: "none", md: "block" } }}
        />
      )}
    </AdminLayout>
  );
};

export default DashboardElectoralTerritorial;
