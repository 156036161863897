import BasicTable from "@components/Tables/BasicTable";
import { numberWithCommas } from "@utils/Utilities/";

const ListAge = ({ data, loading = false }) => {
  return (
    <BasicTable
      rows={data.categories.map((item, index) => ({
        label: item,
        hombre: numberWithCommas(data.Male[index]),
        mujer: numberWithCommas(data.Female[index]),
      }))}
      hcolumns={[
        { id: "label", label: "Edad" },
        { id: "hombre", label: "Masculino", align: "right" },
        { id: "mujer", label: "Femenino", align: "right" },
      ]}
      isLoading={loading}
      stickyHeader={true}
      showPagination={false}
    />
  );
};

export default ListAge;
