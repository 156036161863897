import { useNavigate } from "react-router-dom";
import { useState, useEffect, useCallback } from "react";
import { useFormik } from "formik";

// Material UI
import {
  CircularProgress,
  Card,
  CardHeader,
  CardContent,
  Box,
  Stack,
  Icon,
  Button,
  Typography,
} from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2";
import Swal from "sweetalert2";

// Componentes
import AdminLayout from "@components/MainPage/AdminLayout";
import LoadingForm from "@components/LinearProgress/LoadingForm";
import Form from "@components/Roles/Form";
import Seleccion from "@components/Roles/Seleccion";

// Servicios
import UserRoleService from "@services/RoleServices";
import { AgregarPerfilInterface } from "@data/interfaces/RoleInterfaces";
import { AgregarPerfilSchema } from "@data/schemas/RoleSchemas";

const AgregarPerfiles = () => {
  const [isLoadigForm, setIsLoadingForm] = useState(false);
  const [loadigMessage, setLoadigMessage] = useState("Cargando...");
  const [openLinearProgress, setOpenLinearProgress] = useState(false);
  const [isSuccessFormSave] = useState(false);

  const navigate = useNavigate();

  // Total de elementos
  const [total, setTotal] = useState(0);

  // Todos los datos sin filtrar
  const [allData1, setAllData1] = useState("");
  const [allData2, setAllData2] = useState("");

  // Se usa para desabilitar los campos del componente contrario
  const [disable1, setDisable1] = useState(false);
  const [disable2, setDisable2] = useState(false);

  // Se definen los datos que tiene cada componente de selección
  const [filteredData1, setFilteredData1] = useState("");
  const [filteredData2, setFilteredData2] = useState("");

  // Los datos que se guardan en la columna derecha
  const [newData1, setNewData1] = useState([]);
  const [newData2, setNewData2] = useState([]);

  // Paginación y busqueda
  const [filtered1, setFiltered1] = useState([]);
  const [page1, setPage1] = useState(0);
  const [hasMore1, setHasMore1] = useState(true);

  const [filtered2, setFiltered2] = useState([]);
  const [page2, setPage2] = useState(0);
  const [hasMore2, setHasMore2] = useState(true);

  const pageSize = 10;

  // Obtiene los roles
  const getRolesFun = useCallback((data) => {
    setIsLoadingForm(true);
    setOpenLinearProgress(true);

    UserRoleService.getRoles({
      page: data.page,
      pageSize: data.pageSize,
      filtered: data.filtered,
    })
      .then((res) => {
        if (res.results) {
          if (res.response.total > 0) {
            // Filtra los datos para evitar duplicidad
            const filtered = res.response.data.filter(
              (item) => !data.data.find((newItem) => newItem.id === item.id)
            );
            data.setFilteredData(filtered);

            if (!data.filtered.length) {
              data.setAllData(res.response.data);
              setTotal(res.response.total);
            }
          }

          // Verifica si realmente ya no hay más datos que llamar
          data.pageSize >= parseInt(res.response.total) ? data.setHasMore(false) : data.setHasMore(true);
        } else {
          Swal.fire({ title: res.message, icon: "warning" });
          data.setHasMore(false);
        }
      })
      .catch((e) => {
        data.setHasMore(false);
        Swal.fire({ title: e.message, icon: "warning" });
      })
      .finally(() => {
        setIsLoadingForm(false);
        setOpenLinearProgress(false);
      });
  }, []);

  // Filtra los datos para evitar duplicidad en el buscador
  const getRolesFiltered = useCallback((data) => {
    setIsLoadingForm(true);
    setOpenLinearProgress(true);

    UserRoleService.getRoles({
      page: data.page,
      pageSize: data.pageSize,
      filtered: data.filtered,
    })
      .then((res) => {
        data.setHasMore(false);
        if (res.results) {
          if (res.response.total > 0) {
            // Filtra los datos para evitar duplicidad
            const filtered = res.response.data.filter(
              (item) => !data.data.find((newItem) => newItem.id === item.id)
            );
            data.setFilteredData(filtered);
          }
        } else {
          Swal.fire({ title: res.message, icon: "warning" });
        }
      })
      .catch((e) => {
        Swal.fire({ title: e.message, icon: "warning" });
      })
      .finally(() => {
        setIsLoadingForm(false);
        setOpenLinearProgress(false);
      });
  }, []);

  useEffect(() => {
    getRolesFun({
      page: page1,
      pageSize: pageSize,
      filtered: filtered1,
      data: newData2,
      setFilteredData: setFilteredData1,
      setHasMore: setHasMore1,
      setAllData: setAllData1,
    });
    getRolesFun({
      page: page2,
      pageSize: pageSize,
      filtered: filtered2,
      data: newData1,
      setFilteredData: setFilteredData2,
      setHasMore: setHasMore2,
      setAllData: setAllData2,
    });
    // eslint-disable-next-line
  }, []);

  // Manda a llamar mas datos cuando hacen falta cargar más del componente 2 (scroll y boton)
  const fetchHasMore1 = () => {
    UserRoleService.getRoles({
      page: page1 + 1,
      pageSize: pageSize,
    })
      .then((res) => {
        if (res.results) {
          // Agrega los datos nuevos con los anteriores
          const allData = allData1.concat(res.response.data);

          // Filtra los datos para evitar duplicidad
          if (allData) {
            const filtered = allData.filter((item) => !newData2.find((newItem) => newItem.id === item.id));
            setFilteredData1(filtered);
          }
          setAllData1(allData);

          let end = (page1 + 1) * pageSize;

          if (end >= res.response.total) {
            setHasMore1(false);
          }
        } else {
          Swal.fire({ title: res.message, icon: "warning" });
          setHasMore1(false);
        }
      })
      .catch((e) => {
        setHasMore1(false);
        Swal.fire({ title: e.message, icon: "warning" });
      });
    setPage1(page1 + 1);
  };

  // Manda a llamar mas datos cuando hacen falta cargar más del componente 2 (scroll y boton)
  const fetchHasMore2 = () => {
    UserRoleService.getRoles({
      page: page2 + 1,
      pageSize: pageSize,
    })
      .then((res) => {
        if (res.results) {
          // Agrega los datos nuevos con los anteriores
          const allData = allData2.concat(res.response.data);

          // Filtra los datos para evitar duplicidad
          if (allData) {
            const filtered = allData.filter((item) => !newData1.find((newItem) => newItem.id === item.id));
            setFilteredData2(filtered);
          }
          setAllData2(allData);
          let end = (page2 + 1) * pageSize;

          if (end >= res.response.total) {
            setHasMore2(false);
          }
        } else {
          Swal.fire({ title: res.message, icon: "warning" });
          setHasMore2(false);
        }
      })
      .catch((e) => {
        setHasMore2(false);
        Swal.fire({ title: e.message, icon: "warning" });
      });
    setPage2(page2 + 1);
  };

  // Buscador del componente 1
  const handleSearch1 = (searchInput) => {
    let filtro = [];
    if (searchInput === "") {
      filtro = [];
      setPage1(0);
      setFiltered1(filtro);
      getRolesFun({
        page: 0,
        pageSize,
        filtered: filtro,
        data: newData2,
        setFilteredData: setFilteredData1,
        setHasMore: setHasMore1,
        setAllData: setAllData1,
      });
    } else {
      filtro = [
        {
          id: "perfiles.Perfil",
          filter: "LIKE",
          value: searchInput,
          inheritFilterType: null,
        },
      ];
      // Llenado de los datos del filtro
      setPage1(0);
      setFiltered1(filtro);
      getRolesFiltered({
        page: 0,
        pageSize: total,
        filtered: filtro,
        setFilteredData: setFilteredData1,
        setHasMore: setHasMore1,
        setAllData: setAllData1,
        data: newData2,
        filter: true,
      });
    }
  };

  // Buscador del componente 2
  const handleSearch2 = (searchInput) => {
    let filtro = [];
    if (searchInput === "") {
      filtro = [];
      setPage2(0);
      setFiltered2(filtro);
      getRolesFun({
        page: 0,
        pageSize,
        filtered: filtro,
        data: newData1,
        setFilteredData: setFilteredData2,
        setHasMore: setHasMore2,
        setAllData: setAllData2,
      });
    } else {
      filtro = [
        {
          id: "perfiles.Perfil",
          filter: "LIKE",
          value: searchInput,
          inheritFilterType: null,
        },
      ];
      // Llenado de los datos del filtro
      setPage2(0);
      setFiltered2(filtro);
      getRolesFiltered({
        page: 0,
        pageSize: total,
        filtered: filtro,
        setFilteredData: setFilteredData2,
        setHasMore: setHasMore2,
        setAllData: setAllData2,
        data: newData1,
      });
    }

    /*     if (searchInput === "") {
      filtro = [];
    } else {
      filtro = [
        {
          id: "perfiles.Perfil",
          filter: "LIKE",
          value: searchInput,
          inheritFilterType: null,
        },
      ];
    }
    // Llenado de los datos del filtro
    setFiltered2(filtro);
    setPage2(0);
    getRoles({
      page: 0,
      pageSize,
      filtered: filtro,
      setFilteredData: setFilteredData2,
      setHasMore: setHasMore2,
      setAllData: setAllData2,
      data: newData1,
      filter: true,
    }); */
  };

  const formik = useFormik({
    initialValues: AgregarPerfilInterface,
    validationSchema: AgregarPerfilSchema,
    onSubmit: (values, actions) => {
      handleVerification(values);
    },
  });

  // Al tener el Formik completo
  const handleVerification = async (values, actions) => {
    // Sacar el ID de cada objeto para subir solo el ID
    const idsAC = values.AmbitoCargo.map((option) => option.value || option);
    const idsPerfilHijo = values.PerfilHijo.map((option) => option.id || option);
    const idsPerfilPadre = values.PerfilPadre.map((option) => option.id || option);

    // Estructurar los datos a enviar
    const params = {
      idTipo: values.TipoPerfil,
      Perfil: values.NombrePerfil,
      EtiquetaCargo: values.EtiquetaCargo,
      Descripcion: values.Descripcion,
      idAmbitoCargo: idsAC,
      PerfilesHijos: idsPerfilHijo,
      PerfilesPadres: idsPerfilPadre,
    };

    try {
      setIsLoadingForm(true);
      setOpenLinearProgress(true);
      setLoadigMessage("Subiendo...");
      const result = await UserRoleService.setRole(params);
      const { success, results, message } = await result;

      if (success && results) {
        Swal.fire({ title: message, icon: "success", customClass: { container: "modal-alert" } });
      } else Swal.fire({ title: message, icon: "warning", customClass: { container: "modal-alert" } });
    } catch (error) {
      Swal.fire({ title: error.message, icon: "warning" });
    } finally {
      setIsLoadingForm(false);
      setOpenLinearProgress(false);
      setLoadigMessage("Cargando...");
    }
  };

  // Verifica que cambio el dato en el Componente de Seleccion
  useEffect(() => {
    if (allData2) {
      const filtered = allData2.filter((item) => !newData1.find((newItem) => newItem.id === item.id));
      setFilteredData2(filtered);
    }
    formik.values.PerfilHijo = newData1;
    setTimeout(() => {
      setDisable2(false);
    }, 50);
    // eslint-disable-next-line
  }, [newData1, setNewData1]);

  useEffect(() => {
    if (allData1) {
      const filtered = allData1.filter((item) => !newData2.find((newItem) => newItem.id === item.id));
      setFilteredData1(filtered);
    }
    formik.values.PerfilPadre = newData2;
    setTimeout(() => {
      setDisable1(false);
    }, 50);
    // eslint-disable-next-line
  }, [newData2, setNewData2]);

  return (
    <>
      <AdminLayout>
        <Card>
          <CardHeader
            title={"Registrar Perfil"}
            action={
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "end",
                  marginTop: "3%",
                }}
              >
                <Stack direction="row" spacing={1}>
                  <Button
                    color={"primaryDark"}
                    variant={"contained"}
                    size={"medium"}
                    startIcon={<Icon>arrow_back</Icon>}
                    onClick={() => navigate(-1)}
                  >
                    Regresar
                  </Button>
                </Stack>
              </Box>
            }
          ></CardHeader>
          <CardContent className="p-0">
            <LoadingForm
              loadinMessage={loadigMessage}
              successMessage="¡Consultado con éxito!"
              isLoading={isLoadigForm}
              success={isSuccessFormSave}
              isOpen={openLinearProgress}
              setIsOpen={() => {}}
            />
            <Card
              className="card-primary"
              sx={{
                boxShadow: { xs: 0, sm: 1 },
                backgroundColor: { xs: "transparent", sm: "white" },
              }}
            >
              <CardContent sx={{ paddingX: { xs: 0, sm: 2 } }}>
                <Box sx={{ mt: 1, mb: 2 }}>
                  <Grid2 container spacing={2}>
                    <Form
                      errors={formik.errors}
                      touched={formik.touched}
                      values={formik.values}
                      setFieldValue={formik.setFieldValue}
                      handleChange={formik.handleChange}
                    />
                    {filteredData1 ? (
                      <Seleccion
                        total={total}
                        usedData={newData2.length}
                        fetchHasMore={fetchHasMore1}
                        hasMore={hasMore1}
                        initialData={filteredData1}
                        prevData={newData1}
                        setNewData={setNewData1}
                        setDisable={setDisable2}
                        handleSearch={handleSearch1}
                        disable={disable1}
                        error={
                          formik.touched.PerfilHijo &&
                          formik.values.PerfilHijo.length === 0 &&
                          formik.errors.PerfilHijo
                        }
                        title="Seleccione los perfiles que podrá asignar"
                      />
                    ) : (
                      <Grid2 xs={12} justifyContent="center">
                        <CircularProgress size={50} />
                      </Grid2>
                    )}
                    {/* En caso de que se requiera mensajes de error en los Seleccion */}
                    {formik.touched.PerfilHijo &&
                      formik.values.PerfilHijo.length === 0 &&
                      formik.errors.PerfilHijo && (
                        <Grid2 xs={12} paddingLeft="22px">
                          <Typography
                            variant="body4"
                            color="#d32f2f"
                            sx={{
                              fontSize: "0.75rem",
                              fontWeight: 400,
                              lineHeight: "1.66",
                              fontFamily:
                                '"Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"',
                              textAlign: "left",
                              marginTop: "4px",
                              marginRight: "14px",
                              marginBottom: 0,
                            }}
                          >
                            {formik.errors.PerfilHijo || formik.touched.PerfilHijo}
                          </Typography>
                        </Grid2>
                      )}
                    {filteredData2 ? (
                      <Seleccion
                        total={total}
                        usedData={newData1.length}
                        fetchHasMore={fetchHasMore2}
                        hasMore={hasMore2}
                        initialData={filteredData2}
                        prevData={newData2}
                        setNewData={setNewData2}
                        setDisable={setDisable1}
                        handleSearch={handleSearch2}
                        disable={disable2}
                        error={
                          formik.touched.PerfilPadre &&
                          formik.values.PerfilPadre.length === 0 &&
                          formik.errors.PerfilPadre
                        }
                        title="Seleccione los perfiles que lo podrán asignar"
                      />
                    ) : (
                      <Grid2 xs={12}>
                        <CircularProgress size={50} />
                      </Grid2>
                    )}
                    {/* En caso de que se requiera mensajes de error en los Seleccion */}
                    {formik.touched.PerfilPadre &&
                      formik.values.PerfilPadre.length === 0 &&
                      formik.errors.PerfilPadre && (
                        <Grid2 xs={12} paddingLeft="22px">
                          <Typography
                            variant="body4"
                            color="#d32f2f"
                            sx={{
                              fontSize: "0.75rem",
                              fontWeight: 400,
                              lineHeight: "1.66",
                              fontFamily:
                                '"Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"',
                              textAlign: "left",
                              marginTop: "4px",
                              marginRight: "14px",
                              marginBottom: 0,
                            }}
                          >
                            {formik.errors.PerfilPadre || formik.touched.PerfilPadre}
                          </Typography>
                        </Grid2>
                      )}
                    <Grid2 xs={12} sm={5} md={4} lg={3} marginX="auto">
                      <Button fullWidth variant="contained" color="primaryDark" onClick={formik.handleSubmit}>
                        Agregar
                      </Button>
                    </Grid2>
                  </Grid2>
                </Box>
              </CardContent>
            </Card>
          </CardContent>
        </Card>
      </AdminLayout>
    </>
  );
};

export default AgregarPerfiles;
