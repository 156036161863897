import { PrivilegedRoute, ProtectedRoute } from "@middlewares";
import Dashboard from "@pages/Movilzadores/Dashboard";
import UploadInformation from "@pages/Movilzadores/UploadInformation";
import DashboardMovilizados from "@pages/Movilzadores/DashboardMovilizados";

const MovilizadoresRoutes = [
  {
    path: "/dashboard-movilizadores",
    element: (
      <PrivilegedRoute>
        <Dashboard />
      </PrivilegedRoute>
    ),
  },
  {
    path: "/carga-movilizadores",
    element: (
      <ProtectedRoute>
        <UploadInformation />
        </ProtectedRoute>
    ),
  },
  {
    path: "/dashboard-movilizados",
    element: (
      <ProtectedRoute>
        <DashboardMovilizados />
      </ProtectedRoute>
    ),
  },
];

export default MovilizadoresRoutes;