import { useState, useEffect } from "react";
import Swal from "sweetalert2";

// Material UI
import { Container } from "@mui/material";

// Componentes
import AdminLayout from "@components/MainPage/AdminLayout";
import Pendientes from "@components/MobileDiaD/Movilizadores/Pendientes";
import Reportados from "@components/MobileDiaD/Movilizadores/Reportados";
import CustomTabs from "@components/Tabs/CustomTabs";
import Navbar from "@components/MobileDiaD/Navbar";

// Servicios
import MobilizationService from "@services/MovilizationServices";
import { getVars } from "@utils/global";

const Movilizadores = () => {
  const isPerfil = getVars("Token", { user: { ambito_perfil: [] } })?.user?.ambito_perfil.some(
    (item) => item.idPerfil === 198
  );

  const [tabValue, setTabValue] = useState("0");

  const [usuarios, setUsuarios] = useState([]);

  const [isLoading, setIsLoading] = useState(false);

  const getListing = async () => {
    setIsLoading(true);
    try {
      const res = await MobilizationService.getListings({});
      const { results, message, response } = res;

      if (results) {
        const dataUsers = response.data;
        setUsuarios(dataUsers);
      } else throw new Error(message);
    } catch (error) {
      Swal.fire({ title: error.message, icon: "warning" });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getListing();
  }, []);

  return (
    <AdminLayout delPadding>
      <Navbar color="#000" disableGoHome />
      <Container sx={{ mt: 1, px: 1 }}>
        <CustomTabs
          value={tabValue}
          setValue={setTabValue}
          tabs={[
            {
              label: "Por movilizar",
              value: "0",
              icon: "account_circle",
              iconPosition: "start",
              component: (
                <Pendientes
                  usuarios={usuarios}
                  setUsuarios={setUsuarios}
                  isLoading={isLoading}
                  isPerfil={isPerfil}
                />
              ),
              sxContent: { pt: 1 },
            },
            {
              label: "Movilizados",
              value: "1",
              icon: "groups",
              iconPosition: "start",
              component: (
                <Reportados
                  usuarios={usuarios}
                  setUsuarios={setUsuarios}
                  isLoading={isLoading}
                  isPerfil={isPerfil}
                />
              ),
              sxContent: { pt: 1 },
            },
          ]}
        />
      </Container>
    </AdminLayout>
  );
};

export default Movilizadores;
