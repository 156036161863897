import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Card, CardHeader, CardContent, CardActions, Paper, InputBase, IconButton, List, LinearProgress, Typography, Button } from "@mui/material";
import Icon from "@mui/material/Icon";
import InfiniteScroll from "react-infinite-scroll-component";
import Alert from "@mui/material/Alert";
import Grid2 from "@mui/material/Unstable_Grid2";

const BasicList = (props) => {
  const {
    Header = true,
    search,
    configParams,
    data,
    fetchHasMore,
    totalItems,
    itemComponent,
    hasMore,
    handleClickItem,
    finder,
    handleDeleteItem,
    alertUser = false,
    onClick = () => {},
  } = props;
  const [showedItems, setShowedItems] = useState(0);
  const [listingData, setListingData] = useState([]);
  const [selectedIndex, setSelectedIndex] = useState(1);
  const [searchInput, setSearchInput] = useState("");

  const [config, setConfig] = useState({
    title: "Listado",
    icon: "list_alt",
    height: 400,
    placeholder: "Buscar usuario",
    endMessage: "No hay registros para mostrar",
    alertMessage: "Reportar",
    buttonName: "Submit",
  });

  useEffect(() => {
    Object.keys(configParams).forEach(function (key) {
      setConfig((prevState) => ({ ...prevState, [key]: configParams[key] }));
    });
  }, [configParams]);

  useEffect(() => {
    setListingData(data);
    setShowedItems(data ? data.length : 0);
  }, [data]);

  const handleSelectedIndex = (index, data) => {
    setSelectedIndex(index);
    handleClickItem(data);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    search(searchInput);
  };

  const handleChangeInputSearch = (value) => {
    setSearchInput(value);
    if (value.length < 1) {
      search(value);
    }
  };

  return (
    <Card sx={{ width: "100%" }} className="card-secondary">
      {Header && <CardHeader avatar={<Icon>{config.icon}</Icon>} title={config.title} />}
      <CardContent>
        {finder && (
          <Paper
            component="form"
            sx={{
              p: "2px 4px",
              display: "flex",
              alignItems: "center",
            }}
            onSubmit={(e) => handleSubmit(e)}
          >
            <InputBase
              sx={{ ml: 1, flex: 1 }}
              placeholder={config.placeholder}
              inputProps={{ "aria-label": config.placeholder }}
              value={searchInput}
              onChange={(e) => handleChangeInputSearch(e.target.value)}
            />
            <IconButton type="submit" sx={{ p: "10px" }} aria-label="search" onClick={() => search(searchInput)}>
              <Icon>search</Icon>
            </IconButton>
          </Paper>
        )}
        <InfiniteScroll
          dataLength={showedItems}
          next={fetchHasMore}
          hasMore={hasMore}
          loader={<LinearProgress color="secondary" />}
          height={config.height}
          endMessage={
            <p style={{ textAlign: "center" }}>
              <b>{config.endMessage !== undefined ? config.endMessage : "¡Final de la lista de registros!"}</b>
            </p>
          }
        >
          <List dense={true}>
            {listingData.map((i, index) =>
              itemComponent({
                item: i,
                selectedIndex: selectedIndex,
                handleSelectedIndex: handleSelectedIndex,
                index: index,
                handleDeleteItem: handleDeleteItem,
              })
            )}
          </List>
        </InfiniteScroll>
      </CardContent>

      <CardActions style={{ display: "flex", justifyContent: "center" }}>
        {alertUser && (
          <Alert severity="warning" sx={{ height: "40px", display: "flex", alignItems: "center", justifyContent: "center" }}>
            <Grid2 style={{ display: "flex", alignItems: "center" }}>
              <Typography mr={2}>{config.alertMessage}</Typography>
              <Button variant="contained" size="small" color="primaryDark" onClick={onClick}>
                {config.buttonName}
              </Button>
            </Grid2>
          </Alert>
        )}
      </CardActions>
      <CardActions style={{ display: "flex", justifyContent: "center" }}>
        <Typography variant="body2" sx={{ color: "#888888" }}>
          Mostrando {showedItems} de {totalItems}
        </Typography>
      </CardActions>
    </Card>
  );
};

BasicList.propTypes = {
  data: PropTypes.array.isRequired,
  fetchHasMore: PropTypes.func.isRequired,
  hasMore: PropTypes.bool.isRequired,
  itemComponent: PropTypes.any.isRequired,
  totalItems: PropTypes.number.isRequired,
  configParams: PropTypes.object,
  handleClickItem: PropTypes.func,
  finder: PropTypes.bool,
  handleDeleteItem: PropTypes.func,
};

BasicList.defaultProps = {
  totalItems: 0,
  finder: true,
  configParams: {
    title: "Listado",
    icon: "list_alt",
    height: 400,
    endMessage: "No hay registros para mostrar",
  },
  handleClickItem: () => {},
  handleDeleteItem: () => {},
};

export default BasicList;
