import AdminLayout from "@components/MainPage/AdminLayout";
import ActionHeader from "@components/Containers/ActionHeader";
import Card from "@components/Card/Card";
import { useNavigate } from "react-router-dom";
import { useCatalogs } from "@hooks/useCatalogs";
import Swal from "sweetalert2";
import CatalogService from "@services/CatalogServices";
import CardStats from "@components/Card/CardStats";
import { useEffect, useState } from "react";
import middleware from "@middlewares/middleware";
import LoadingForm from "@components/LinearProgress/LoadingForm";
import moment from "moment";
import {
  isEmptyOrInvalidString,
  isValidSection,
  isTypePhone,
} from "@utils/validations";
import { useFormik } from "formik";
import { dataStreetLocation } from "@data/interfaces/TerritorialInterface";
import { StreetLocationSchema } from "../../data/schemas/TerritorialSchema";
import BasicTable from "@components/Tables/BasicTable";
import Select from "react-select";
import InputSelect from "@components/Selects/BasicSelect";
import makeAnimated from "react-select/animated";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import CancelIcon from "@mui/icons-material/Cancel";
import SendIcon from "@mui/icons-material/Send";
import AddLocationAltIcon from "@mui/icons-material/AddLocationAlt";
import LoadingButton from "@mui/lab/LoadingButton";
import TerritorialService from "../../services/TerritorialServices";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  Container,
  CardContent,
  Grid,
  Box,
  Button,
  Icon,
  IconButton,
  TextField,
  InputAdornment,
  CircularProgress,
  FormHelperText,
  Divider,
} from "@mui/material";

const StreetLocation = () => {
  const catalogsParams = [{ id: "secciones" }];
  let today = new Date();
  const navigate = useNavigate();
  const animatedComponents = makeAnimated();
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);
  const [chart, setChart] = useState(0);
  const [loading, setLoading] = useState(false);
  const [loadingAdd, setLoadingAdd] = useState(false);
  const [loadingStatus, setLoadingStatus] = useState(false);
  const [statusValidated, setStatusValidated] = useState(false);
  const [btnDisabled, setBtnDisabled] = useState(true);
  const [loadingBlocks, setLoadingBlocks] = useState(false);
  const [sectionValid, setSectionValid] = useState(false);
  const [manzana, setManzana] = useState({});
  const [blocks, setBlocks] = useState([]);
  const [form, setForm] = useState(true);
  const [isloadigFormApi, setIsLoadingFormApi] = useState(false);
  const [issuccessFormApi, setIsSuccessFormApi] = useState(false);
  const [openLinearProgressApi, setOpenLinearProgressApi] = useState(false);
  const [iconLoading, setIconLoading] = useState("delete_forever");
  const [messageLoading, setMessageLoading] = useState("");
  const [tableData, setTableData] = useState({
    page: 0,
    pageSize: 10,
    filtered: [],
    sorted: [],
  });
  const [columns, setColumns] = useState([
    { id: "Region", label: "Región", width: 10 },
    { id: "Municipio", label: "Municipio", width: 50 },
    { id: "Seccion", label: "Sección", width: 10 },
    { id: "Manzana", label: "Manzana", width: 10 },
    { id: "Tipo", label: "Tipo", width: 20 },
    { id: "Calle", label: "Calle" },
    { id: "NumeroInicial", label: "N° Inicial", width: 100 },
    { id: "NumeroFinal", label: "N° Final", width: 100 },
  ]);

  const tipoNumeros = [
    { value: 1, label: "PAR" },
    { value: 2, label: "IMPAR" },
    { value: 3, label: "MIXTO" },
  ];
  const { catalogs, load } = useCatalogs({
    catalogsOptions: catalogsParams,
    putDefaultOption: false,
  });
  const formik = useFormik({
    initialValues: dataStreetLocation,
    validationSchema: StreetLocationSchema,
    onSubmit: (values) => {
      onSubmit(values);
    },
  });

  useEffect(() => {
    if (middleware.checkMenuAction("Eliminar")) {
      setColumns([...columns, { id: "delete", label: "", width: 100 }]);
    }
    List();
  }, [tableData]);

  const validateSection = (value) => {
    setManzana({});
    formik.handleChange({ target: { name: "manzana", value: "" } });
    setForm(true);
    const validationPromise = new Promise((resolve, reject) => {
      let section = catalogs.secciones.find(
        (item) => item.value === parseInt(value)
      );
      if (!section) {
        reject({
          success: false,
          message: "Sección inválida",
        });
      } else {
        resolve({
          success: true,
          message: "Sección Valida",
        });
      }
    });

    setStatusValidated(false);
    setLoadingStatus(true);

    validationPromise
      .then((res) => {
        setSectionValid(true);
        loadUrbanBlocks(value);
      })
      .catch((error) => {
        setBtnDisabled(true);
        setSectionValid(false);
      })
      .finally(() => {
        setStatusValidated(true);
        setLoadingStatus(false);
      });
  };

  const loadUrbanBlocks = (section) => {
    setLoadingBlocks(true);
    const catalogsParams = [
      {
        id: "manzanas",
        filtered: [
          {
            id: "cat_manzanas.Seccion",
            filter: "=",
            value: section,
          },
        ],
      },
    ];
    CatalogService.getCatalogs(catalogsParams)
      .then((res) => {
        if (res.results) {
          setBlocks(
            res.response.catalogs.manzanas ? res.response.catalogs.manzanas : []
          );
        }
      })
      .catch((e) => {
        Swal.fire({
          title: e.message,
          icon: "warning",
        });
      })
      .finally(() => {
        setLoadingBlocks(false);
      });
  };

  const handleOnKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      validateSection(formik.values.seccion);
    }
  };

  const handleManzana = (value) => {
    setManzana(value);
    formik.handleChange({
      target: {
        name: "manzana",
        value: value.Manzana,
      },
    });
    setBtnDisabled(false);
  };

  const List = () => {
    TerritorialService.getStreetLocation(tableData)
      .then((res) => {
        if (res.results) {
          setData(
            res.response.data.map((item) => {
              return {
                ...item,
                delete: (
                  <IconButton aria-label="delete" size="large" color="error" onClick={()=>onDelete(item.idRegistro)}>
                    <DeleteIcon fontSize="inherit" />
                  </IconButton>
                ),
              };
            })
          );
          setTotal(res.response.total);
          setChart(res.response.capturas);
        } else {
          Swal.fire({ title: res.message, icon: "warning" });
        }
      })
      .catch((e) => {
        Swal.fire({ title: e.message, icon: "warning" });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleSearch = (value) => {
    if (loading) {
      return false;
    }
    setLoading(true);
    setForm(false);
    setTableData({
      ...tableData,
      page: 0,
      pageSize: 10,
      filtered: [
        {
          id: "manzanas_encuestas.Seccion",
          filter: "=",
          value: formik.values.seccion,
        },
        {
          id: "manzanas_encuestas.Manzana",
          filter: "=",
          value: formik.values.manzana,
        },
      ],
    });
  };

  const handleChangePagination = (pagination) => {
    setTableData({ ...tableData, ...pagination });
  };

  const selectStyles = {
    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
    menu: (provided) => ({ ...provided, zIndex: "9999 !important" }),
  };

  const onSubmit = (values) => {
    if (loadingAdd) {
      return false;
    }
    setLoadingAdd(true);

    TerritorialService.setStreetLocation(values)
      .then((res) => {
        if (res.success && res.results) {
          formik.setValues({
            ...formik.values,
            calle: "",
            tipo: "",
            NumFinal: "",
            NumInicio: "",
          });
          setData(
            res.response.data.map((item) => {
              return {
                ...item,
                delete: (
                  <IconButton aria-label="delete" size="large" color="error" onClick={()=>onDelete(item.idRegistro)}>
                  <DeleteIcon fontSize="inherit" />
                </IconButton>
                ),
              };
            })
          );
          setTotal(res.response.total);
          setChart(res.response.capturas);
          Swal.fire({ title: res.message, icon: "success" });
        } else {
          Swal.fire({ title: res.message, icon: "warning" });
        }
      })
      .catch((e) => {
        handleSearch();
        Swal.fire({ title: e.message, icon: "warning" });
      })
      .finally(() => {
        setLoadingAdd(false);
      });
  };

  const onDelete = (id) => {
    if (isloadigFormApi) {
      return true;
    }

    let params = {
      id: id,
    };
    Swal.fire({
      title: `¿Está seguro de eliminar el domicilio?`,
      text: "",
      icon: "question",
      showCancelButton: true,
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, Eliminar!",
      confirmButtonColor: "#14A52E",
    }).then((result) => {
      if (result.isConfirmed) {
        setOpenLinearProgressApi(true);
        setIsLoadingFormApi(true);
        setIsSuccessFormApi(true);
        setIconLoading("delete_forever");
        setMessageLoading("Eliminando Domicilio");
        TerritorialService.deleteStreetLocation(params)
          .then((res) => {
            if (res.success && res.results) {
              Swal.fire("Eliminado!", "", "success");
              setData(
                res.response.data.map((item) => {
                  return {
                    ...item,
                    delete: (
                      <IconButton aria-label="delete" size="large" color="error" onClick={()=>onDelete(item.idRegistro)}>
                        <DeleteIcon fontSize="inherit" />
                      </IconButton>
                    ),
                  };
                })
              );
              setTotal(res.response.total);
              setChart(res.response.capturas);
            } else {
              Swal.fire({ title: res.message, icon: "warning" });
            }
          })
          .catch((e) => {
            Swal.fire({ title: e.message, icon: "warning" });
          })
          .finally(() => {
            setOpenLinearProgressApi(false);
            setIsLoadingFormApi(false);
            setIsSuccessFormApi(false);
          }); 
      }
    });
  };

  return (
    <>
      <AdminLayout>
        <Container maxWidth="false">
          <ActionHeader
            title={`Ubicación de Manzana`}
            handleclick={() => navigate(`/ubicacion_manzana`)}
          />
          <LoadingForm
            isLoading={isloadigFormApi}
            success={issuccessFormApi}
            isOpen={openLinearProgressApi}
            setIsOpen={() => setOpenLinearProgressApi(!openLinearProgressApi)}
            IconOption={iconLoading}
            successMessage={messageLoading}
            loadinMessage={"Cargando..."}
          />
          <Grid container spacing={2}>
            <Grid item xs={12} sm={4} md={4} lg={4}>
              <CardStats
                icon={"location_on"}
                title={"Total Manzanas Capturadas"}
                total={chart}
                subIcon={"calendar_today"}
                subtitle={moment(today).format("DD/MM/YYYY")}
                loading={loading}
              />
            </Grid>
          </Grid>
          <Card className="card-primary">
            <CardContent>
              <Grid
                container
                spacing={2}
                marginBottom={"1rem"}
                marginTop={"0.5rem"}
              >
                <Grid item xs={12} sm={4} md={4} lg={4}>
                  <TextField
                    required
                    label="Sección"
                    type={"text"}
                    name="seccion"
                    size="small"
                    color="info"
                    className="fixed-input"
                    placeholder="Validar Sección"
                    value={formik.values.seccion}
                    onChange={(e) => {
                      formik.handleChange({
                        target: {
                          name: "seccion",
                          value: isValidSection(e.target.value)
                            ? e.target.value
                            : formik.values.seccion,
                        },
                      });
                    }}
                    onBlur={() => validateSection(formik.values.seccion)}
                    onKeyUp={handleOnKeyPress}
                    InputProps={{
                      maxLength: 4,
                      endAdornment: (
                        <InputAdornment position="end">
                          {loadingStatus ? (
                            <CircularProgress />
                          ) : sectionValid ? (
                            <TaskAltIcon color="success" />
                          ) : (
                            <CancelIcon color="error" />
                          )}
                        </InputAdornment>
                      ),
                    }}
                    error={!sectionValid}
                  />
                  <FormHelperText error>
                    {!sectionValid && "Ingrese una sección válida"}
                  </FormHelperText>
                </Grid>
                <Grid item xs={12} sm={4} md={4} lg={4}>
                  <Select
                    placeholder="Manzana"
                    name="manzana"
                    styles={selectStyles}
                    closeMenuOnSelect={true}
                    components={animatedComponents}
                    value={manzana}
                    onChange={handleManzana}
                    options={blocks}
                    isDisabled={blocks.length < 1 ? true : false}
                    isLoading={loadingBlocks}
                  />
                </Grid>
                <Grid item xs={12} sm={4} md={4} lg={4}>
                  <Button
                    variant="contained"
                    size="small"
                    sx={{ pt: 1 }}
                    endIcon={<SendIcon />}
                    onClick={() => handleSearch()}
                    disabled={btnDisabled}
                  >
                    Buscar
                  </Button>
                </Grid>
              </Grid>
              <Grid
                container
                spacing={2}
                marginBottom={"1rem"}
                marginTop={"0.5rem"}
              >
                <Grid item xs={12} sm={3} md={3} lg={3}>
                  <TextField
                    error={
                      formik.touched.calle &&
                      !isEmptyOrInvalidString(formik.errors.calle)
                    }
                    helperText={
                      formik.touched.calle &&
                      formik.errors.calle &&
                      formik.errors.calle
                    }
                    sx={{ width: "100%" }}
                    required
                    label="Calle"
                    type="text"
                    name="calle"
                    onChange={formik.handleChange}
                    //onBlur={handleBlur}
                    value={formik.values.calle.toUpperCase()}
                    variant="outlined"
                    size="small"
                    className="fixed-input"
                    disabled={form}
                  />
                </Grid>
                <Grid item xs={12} sm={3} md={3} lg={3}>
                  <InputSelect
                    label="Tipo"
                    name="tipo"
                    options={tipoNumeros}
                    value={formik.values.tipo}
                    onChange={formik.handleChange}
                    sx={{ width: "100%" }}
                    disabled={form}
                  />
                </Grid>
                <Grid item xs={12} sm={3} md={3} lg={3}>
                  <TextField
                    error={
                      formik.touched.calle &&
                      !isEmptyOrInvalidString(formik.errors.calle)
                    }
                    helperText={
                      formik.touched.calle &&
                      formik.errors.calle &&
                      formik.errors.calle
                    }
                    sx={{ width: "100%" }}
                    required
                    label="Número Inicio"
                    type="text"
                    name="NumInicio"
                    onChange={formik.handleChange}
                    //onBlur={handleBlur}
                    value={formik.values.NumInicio}
                    variant="outlined"
                    size="small"
                    className="fixed-input"
                    disabled={form}
                  />
                </Grid>
                <Grid item xs={12} sm={3} md={3} lg={3}>
                  <TextField
                    error={
                      formik.touched.calle &&
                      !isEmptyOrInvalidString(formik.errors.calle)
                    }
                    helperText={
                      formik.touched.calle &&
                      formik.errors.calle &&
                      formik.errors.calle
                    }
                    sx={{ width: "100%" }}
                    required
                    label="Número Fin"
                    type="text"
                    name="NumFinal"
                    onChange={formik.handleChange}
                    //onBlur={handleBlur}
                    value={formik.values.NumFinal}
                    variant="outlined"
                    size="small"
                    className="fixed-input"
                    disabled={form}
                  />
                </Grid>
              </Grid>
              <Grid
                container
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
              >
                <LoadingButton
                  disabled={form}
                  variant="contained"
                  size="small"
                  sx={{ pt: 1 }}
                  endIcon={<AddLocationAltIcon />}
                  loadingPosition="end"
                  loading={loadingAdd}
                  onClick={formik.submitForm}
                >
                  Agregar
                </LoadingButton>
              </Grid>
            </CardContent>
          </Card>
          <BasicTable
            rows={data}
            hcolumns={columns}
            pageProp={tableData.page}
            pageSize={tableData.pageSize}
            total={total}
            handleChangePagination={handleChangePagination}
            isLoading={loading}
            stickyHeader={true}
          />
        </Container>
      </AdminLayout>
    </>
  );
};
export default StreetLocation;
