import React, { useState, useEffect } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { CircularProgress } from "@mui/material";

const StackedBar = (props) => {
  const { categories, series, title, date, loading = false, text = "", fontSize = "", fontSizeSubtitle = "", color = "", align = "left" } = props;
  const [data, setData] = useState(null);

  useEffect(() => {
    setData({
      chart: {
        type: 'bar'
      },
      title: {
        text: title.primary ?? title.title,
        align: align,
        style: fontSize !== "" ? {
          fontSize: fontSize,
        } : {}
      },

      subtitle: {
        text: title.sub ?? date,
        align: align,
        style: fontSizeSubtitle !== "" ? {
          fontSize: fontSizeSubtitle,
        } : {}
      },

      yAxis: {
        allowDecimals: false,
        min: 0,
        title: {
          text: text ?? "",
        },
      },

      xAxis: {
        categories: categories,
      },

      legend: {
        reversed: true
      },
      credits: false,
      plotOptions: {
        series: {
          stacking: 'normal',
          dataLabels: {
            enabled: true,
            align: 'right',
            style: {
              fontSize: '13px', // Tamaño de la fuente de las etiquetas
            },
            y:-2
            // x: 25
          },
          color: color,

        }
      },
      series: series,

      /*responsive: {
          rules: [
            {
              condition: {
                maxWidth: 500,
              },
              chartOptions: {
                legend: {
                  layout: "horizontal",
                  align: "center",
                  verticalAlign: "bottom",
                },
              },
            },
          ],
        }, */
    });
    // eslint-disable-next-line
  }, [series, title]);

  return data !== null || loading ? (
    <HighchartsReact highcharts={Highcharts} options={data} />
  ) : (
    <CircularProgress />
  );
};

export default StackedBar;
