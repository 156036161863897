import PropTypes from "prop-types";

import { Card, Box, Typography, CircularProgress, Icon } from "@mui/material";

import { numberWithCommas } from "@utils/Utilities";

CardIconColor.propTypes = {
  image: PropTypes.string,
  icon: PropTypes.string,
  title: PropTypes.string.isRequired,
  total: PropTypes.number.isRequired,
  totalTextL: PropTypes.string,
  totalTextR: PropTypes.string,
  textColor: PropTypes.string,
  backgroundColor: PropTypes.string,
  backgroundImage: PropTypes.string,
  boxShadow: PropTypes.number,
  loading: PropTypes.bool,
  sx: PropTypes.object,
};

CardIconColor.defaultProps = {
  title: "Titulo",
  total: 0,
  totalTextL: "",
  totalTextR: "",
  textColor: "#ffffff",
  backgroundColor: "#000000",
  backgroundImage: null,
  boxShadow: 0,
  loading: false,
  sx: {},
};

export default function CardIconColor({
  image,
  icon,
  title,
  total,
  totalTextL,
  totalTextR,
  textColor,
  backgroundColor,
  backgroundImage,
  boxShadow,
  loading,
  sx,
}) {
  const localBackgroundImage = backgroundImage ?? backgroundColor;

  return (
    <Card
      elevation={6}
      sx={{
        pt: 7,
        pb: 3,
        boxShadow: 1,
        borderRadius: 2,
        textAlign: "center",
        color: textColor,
        backgroundColor: backgroundColor,
        overflow: "visible",
        ...sx,
      }}
    >
      <Box sx={{ width: "100%", height: 0, display: "flex", justifyContent: "center" }}>
        <Box
          sx={{
            position: "relative",
            zIndex: 0,
            top: "-90px",
            width: "90px",
            height: "90px",
            backgroundColor: localBackgroundImage,
            borderRadius: 5,
            overflow: "hidden",
            padding: 1,
            boxShadow: boxShadow,
          }}
        >
          {image && <img src={image} style={{ width: "100%", height: "100%", objectFit: "cover" }} alt="404" />}
          {icon && <Icon sx={{ fontSize: "74px" }}>{icon}</Icon>}
        </Box>
      </Box>

      <Box sx={{ position: "relative", zIndex: 1 }}>
        {loading ? (
          <CircularProgress />
        ) : (
          <Typography variant="h3">
            {totalTextL} {numberWithCommas(total)} {totalTextR}
          </Typography>
        )}

        <Typography variant="subtitle2" fontSize={17} sx={{ opacity: 0.72 }}>
          {title}
        </Typography>
      </Box>
    </Card>
  );
}
