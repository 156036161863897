import Yup from "@utils/Yupi18n";
import { ELECCION_DIPUTADO_FEDERAL, ELECCION_DIPUTADO_LOCAL, ELECCION_PRESIDENTE_MUNICIPAL } from "@data/constants/Prep"

const ActaEscrutinioSchema = Yup.object({
  Seccion: Yup.number().required().label("Sección"),
  idTipoEleccion: Yup.number().required().label("Tipo de elección"),
  idCasilla: Yup.number().required().label("Casilla"),
});

const AddPartySchema = Yup.object({
  Partido: Yup.string().required(),
  Siglas: Yup.string().required(),
  Color: Yup.string().required(),
  EsIndependiente: Yup.number().required(),
  EsCoalicion: Yup.number().required()
});

const AddCandidateSchema = Yup.object({
  idTipoEleccion: Yup.number().required().label('Tipo de elección'),
  idPartido: Yup.number().required().label('Partido'),
  NombreCandidato: Yup.string().required().label('Nombre del candidato'),
  Color: Yup.string().required(),
  AmbitoValor: Yup.number().when('idTipoEleccion', {
    is: val => ([ELECCION_DIPUTADO_FEDERAL, ELECCION_DIPUTADO_LOCAL, ELECCION_PRESIDENTE_MUNICIPAL].includes(val)),
    then: Yup.number().required().label('El ámbito para la elección'),
    otherwise: Yup.number().optional().nullable()
  })
});

export { ActaEscrutinioSchema, AddPartySchema, AddCandidateSchema };
