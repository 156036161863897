import { useState } from "react";
import {
    Box,
    Chip,
    Icon,
    TextField,
    InputAdornment,
    Button,
    Divider
} from "@mui/material"
import Grid2 from "@mui/material/Unstable_Grid2";
import middleware from "@middlewares/middleware";
import PropTypes from "prop-types";

import ModalUserResponsibleList from "@components/Users/ModalUserResponsibleList";
import TerritorialForm from "@components/Volunteers/TerritorialForm";
import ElectoralForm from "@components/Volunteers/ElectoralForm";
import GenericForm from "@components/Volunteers/GenericForm";
import { TIPO_ELECTORAL, TIPO_TERRITORIAL } from "@data/constants";
import { getVars } from "@utils/global";
import FormContext from './FormContext'

const GlobalForm = (props) => {
    const {
        formType,
        volunteer,
        catResponsibilities,
        catalogs,
        onSubmit,
        assigned
    } = props;

    const { user } = getVars("Token");
    const [openModal, setOpenModal] = useState(false);
    const [userOwner, setUserOwner] = useState(() => {
        return {
            value: user.id,
            label: `${user.Nombre} ${user.Paterno} ${user.Materno}`,
            TieneUsuario: 1,
        };
    });
    const [functionLabel] = useState(() => {
        if (catResponsibilities.length > 0) {
            return catResponsibilities[0].Tipo ? catResponsibilities[0].Tipo : ''
        }
        return '';
    });

    const resetUserOwner = () => {
        setUserOwner({
            value: user.id,
            label: `${user.Nombre} ${user.Paterno} ${user.Materno}`,
            TieneUsuario: 1,
        });
    };

    const SetOwner = (item) => {
        setUserOwner({
            value: item.value,
            label: item.label,
            TieneUsuario: item.TieneUsuario,
        });
    };

    const handleSubmit = (values) => {
        onSubmit(values);
    }

    return (
        <FormContext.Provider value={{ functionLabel, catalogs, volunteer, catResponsibilities, userOwner }}>
            <Box sx={{ width: "100%" }}>
                <Grid2 container spacing={2}>
                    <Grid2 xs={12} sm={12} md={12} lg={12} sx={{ mt: 2 }}>
                        <Chip
                            icon={<Icon>groups2</Icon>}
                            label={`${middleware.checkMenuAction("Capturista") ? "Seleccione al" : ""
                                } Responsable de asignar la responsabilidad`}
                        />
                    </Grid2>
                </Grid2>

                <Grid2 container spacing={1}>
                    <Grid2 xs={12} sm={12} md={6} lg={6}>
                        <TextField
                            required={true}
                            disabled={true}
                            label="Nombre del Responsable del Voluntario"
                            value={userOwner.label}
                            name="Nombre"
                            sx={{ width: "100%" }}
                            type="text"
                            variant="outlined"
                            size="small"
                            className="fixed-input"
                            InputProps={
                                middleware.checkMenuAction("Capturista") && {
                                    startAdornment: (
                                        <InputAdornment position="start" sx={{ pl: 0, pr: 0 }}>
                                            <Button
                                                color="warning"
                                                onClick={(e) => resetUserOwner()}
                                                sx={{ ml: 0, pl: 0 }}
                                            >
                                                <Icon>restore</Icon>
                                            </Button>
                                            <Divider sx={{ height: 28 }} orientation="vertical" />
                                        </InputAdornment>
                                    ),
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <Divider
                                                sx={{ height: 28, m: 0.5 }}
                                                orientation="vertical"
                                            />
                                            <Button
                                                color="info"
                                                onClick={(e) => setOpenModal(true)}
                                                sx={{ mr: 0, pr: 0 }}
                                            >
                                                <Icon>search</Icon>
                                                <small>Buscar</small>
                                            </Button>
                                        </InputAdornment>
                                    ),
                                }
                            }
                        />
                    </Grid2>
                </Grid2>
                {formType === TIPO_ELECTORAL && <ElectoralForm assigned={assigned} handleSubmit={handleSubmit} />}
                {formType === TIPO_TERRITORIAL && <TerritorialForm assigned={assigned} handleSubmit={handleSubmit} />}
                {![TIPO_ELECTORAL, TIPO_TERRITORIAL].includes(formType) && <GenericForm formType={formType} assigned={assigned} handleSubmit={handleSubmit} />}
            </Box>

            <ModalUserResponsibleList
                openModal={openModal}
                setOpenModal={setOpenModal}
                setResponsible={(e) => SetOwner(e)}
            />

        </FormContext.Provider>
    )
}

GlobalForm.propTypes = {
    formType: PropTypes.number.isRequired,
    catResponsibilities: PropTypes.array.isRequired,
    volunteer: PropTypes.object.isRequired,
    catalogs: PropTypes.object.isRequired,
    onSubmit: PropTypes.func.isRequired,
};

export default GlobalForm;