import React, { useEffect, useState } from "react";
import { Container, Card, Grid, Typography, Select, MenuItem, IconButton, TextField, Button, Box, Chip, Tooltip } from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";

//Components
import AdminLayout from "@components/MainPage/AdminLayout";
import ActionHeader from "@components/Containers/ActionHeader";
import { getVars } from "@utils/global";
import { UserProfileInterface } from "@data/interfaces/UserInterfaces";
import BasicTable from "@components/Tables/BasicTable";
import UserAssistSchema from "@data/schemas/UserAssistSchema";
import LoadingForm from "@components/LinearProgress/LoadingForm";
import SupportService from "@services/SupportServices";

//ICONS
import WhatsAppIcon from "@mui/icons-material/WhatsApp";

import Swal from "sweetalert2";

const UserAssistDescription = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { id, Nombre, Celular, NumeroSolicitudes, FechaCreo, idEstatus, chipColor, folio = `2024${String(id).padStart(4, '0')}` } = location.state;
  const [isLoadigForm, setIsLoadingForm] = useState(false);
  const [isSuccessForm, setIsSuccessForm] = useState(false);
  const [errors, setErrors] = useState({});
  const [descripcion, setDescripcion] = useState("");
  const [openLinearProgress, setOpenLinearProgress] = useState(false);
  const [windows, setWindows] = useState([]);
  const [rowStatus, setRowStatus] = useState(idEstatus);
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);
  const [paginated, setPaginated] = useState({
    page: 0,
    pageSize: 10,
  });

  const [userInfo /* setUserInfo */] = useState(() => {
    let token = getVars("Token").user;
    let user = UserProfileInterface;
    Object.keys(token).forEach((key) => {
      user[key] = token[key] !== undefined ? token[key] : UserProfileInterface[key];
    });
    return user;
  });

  useEffect(() => {
    supportRequestHistory();
    // eslint-disable-next-line
  }, [id]);

  const supportRequestHistory = async () => {
    try {
      setIsLoadingForm(true);
      setOpenLinearProgress(true);

      const result = await SupportService.getListSupportHistory({ id: id });
      const { results, response } = result;
      if (results) {
        setData(response.data);
        setTotal(response.total);
      }
    } catch (error) {
      Swal.fire({
        title: error.message,
        icon: "warning",
      });
    } finally {
      setIsLoadingForm(false);
      setOpenLinearProgress(false);
    }
  };

  const handleSeguimientoClick = async () => {
    const sendMessage = await Swal.fire({
      title: `¿Desea dar seguimiento a ${Nombre}?`,
      text: `Se enviara un mensaje de seguimiento al numero ${Celular}`,
      icon: "info",
      showCancelButton: true,
      confirmButtonText: "Sí, enviar",
      cancelButtonText: "Cancelar",
      cancelButtonColor: "#ff0000",
      allowEscapeKey: false,
      allowOutsideClick: false,
    });
    if (sendMessage.isConfirmed) {
      try {
        setIsLoadingForm(true);
        setOpenLinearProgress(true);
        const message =
          `¡Hola ${Nombre}!\n\n` +
          "Hemos recibido tu solicitud 📨. Estamos revisándola y te mantendremos informado/a pronto. ¡Gracias por tu paciencia! 👍\n\n" +
          "Tiempo de espera: 2 años hábiles ";
        const existingWindow = windows.find((window) => window.Celular === Celular);

        if (existingWindow) {
          const whatsappApiLink = `https://api.whatsapp.com/send?phone=${Celular}`;
          window.open(whatsappApiLink, "_blank");
        } else {
          const whatsappApiLink = `https://api.whatsapp.com/send?phone=${Celular}&text=${encodeURIComponent(message)}`;
          const newWindow = window.open(whatsappApiLink, "_blank");
          setWindows([...windows, { Celular, window: newWindow }]);
        }
        setRowStatus(1);
      } catch (error) {
        Swal.fire({
          title: error.message,
          icon: "warning",
        });
      } finally {
        setIsLoadingForm(false);
        setOpenLinearProgress(false);
      }
    }
  };

  const handleGuardarResuelto = async () => {
    try {
      await UserAssistSchema.validate({ descripcion }, { abortEarly: false });
      setIsLoadingForm(true);
      setOpenLinearProgress(true);
      setIsSuccessForm(false);

      let params = { id: id, idEstatus: rowStatus, ComentarioSeguimiento: descripcion };

      const result = await SupportService.updateSupportRequest(params);
      const { results, message } = result;
      if (results) {
        Swal.fire({
          title: message,
          icon: "success",
        });
        setDescripcion("");
        setErrors({});
      } else {
        Swal.fire({
          title: message,
          icon: "warning",
        });
      }
    } catch (error) {
      const newErrors = {};
      error.inner.forEach((e) => {
        newErrors[e.path] = e.message;
      });
      setErrors(newErrors);
    } finally {
      setIsLoadingForm(false);
      setOpenLinearProgress(false);
      setIsSuccessForm(true);
    }
  };

  const handleStatusChange = (event) => {
    setRowStatus(event.target.value);
  };

  const handleChangePagination = (pagination) => {
    setPaginated({ ...paginated, ...pagination });
  };

  return (
    <AdminLayout>
      <Container maxWidth="false">
        <ActionHeader
          //title={`user description`}
          isAction={true}
          titleButton="Regresar"
          iconButton="arrow_back"
          handleclick={() => navigate(-1)}
        />
        <LoadingForm
          isLoading={isLoadigForm}
          success={isSuccessForm}
          isOpen={openLinearProgress}
          setIsOpen={() => setOpenLinearProgress(!openLinearProgress)}
          loadinMessage={"Cargando..."}
        />
        <Card className="card-primary">
          <Grid container spacing={2} alignItems="center" justifyContent="flex-end">
            <Grid item xs={3} m={2}>
              <Box display="flex" justifyContent="flex-end">
                <Chip
                  label={`#${folio}`}
                  style={{
                    backgroundColor: chipColor,
                    fontSize: "1rem",
                    padding: "8px 12px",
                    minWidth: "120px",
                  }}
                />
              </Box>
            </Grid>
          </Grid>

          <Grid container spacing={2} /* justifyContent="center" */ mt={3}>
            <Grid item xs={12} lg={3.5} ml={4}>
              <Typography variant="h6">Solicita:</Typography>
              <Typography variant="subtitle1">{Nombre}</Typography>
              <Typography variant="subtitle1">{Celular}</Typography>
              <Typography variant="subtitle1">No° Solicitudes: {NumeroSolicitudes}</Typography>
              <Typography variant="subtitle1">{FechaCreo}</Typography>
            </Grid>

            <Grid item xs={12} lg={3.5} ml={4} sx={{ borderRight: "2px solid #ccc" }}>
              <Typography variant="h6">Atiende:</Typography>
              <Box marginBottom={1}>
                <Typography variant="subtitle1">
                  {userInfo.Nombre} {userInfo.Paterno} {userInfo.Materno}
                </Typography>
                <Typography variant="subtitle1">{userInfo.Celular}</Typography>
              </Box>
              <Tooltip
                title={<Typography style={{ fontSize: 16 }}>Contactar al solicitante</Typography>}
                arrow
                placement="right"
                enterTouchDelay={0}
                leaveTouchDelay={1500}
              >
                <IconButton color="primary" onClick={handleSeguimientoClick}>
                  <WhatsAppIcon />
                </IconButton>
              </Tooltip>
            </Grid>

            <Grid item xs={12} lg={3} ml={3}>
              <Box mb={2}>
                <Select value={rowStatus} onChange={handleStatusChange} size="small" sx={{ minWidth: "120px" }}>
                  <MenuItem value={2}>Atendido</MenuItem>
                  <MenuItem value={1}>Pendiente</MenuItem>
                  <MenuItem value={3}>Cancelado</MenuItem>
                </Select>
              </Box>
              <Box textAlign="right">
                <TextField
                  autoFocus
                  margin="dense"
                  label="Descripción sobre el problema resuelto."
                  type="text"
                  fullWidth
                  multiline
                  rows={3}
                  variant="outlined"
                  error={!!errors.descripcion}
                  helperText={errors.descripcion || ""}
                  value={descripcion}
                  onChange={(e) => setDescripcion(e.target.value)}
                  disabled={rowStatus === 1}
                />
                <Button variant="contained" color="primaryDark" size="small" onClick={handleGuardarResuelto} disabled={rowStatus === 1}>
                  Guardar
                </Button>
              </Box>
            </Grid>
            <Grid item xs={12} lg={12} m={2}>
              <BasicTable
                rows={data}
                hcolumns={[
                  { id: "Comentarios", label: "Comentarios de Solicitudes", align: "left" },
                  { id: "SolicitudSoporte", label: "Solicitud", align: "left" },
                  { id: "FechaActualizo", label: "Fecha", align: "left" },
                ]}
                //isLoading={isLoadingTable}
                total={total}
                pageProp={paginated.page}
                pageSize={paginated.pageSize}
                stickyHeader={true}
                showPagination={true}
                handleChangePagination={handleChangePagination}
              />
            </Grid>
          </Grid>
        </Card>
      </Container>
    </AdminLayout>
  );
};

export default UserAssistDescription;
