import { useState } from "react";

import { Box, Card, CardContent, Container, Icon, Typography } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import { blue } from "@mui/material/colors";

import Navbar from "@components/MobileDiaD/Navbar";
import GridGeneral from "@components/MobileDiaD/GridGeneral";

//Screens general
import ScreenPaseListaGeneral from "@components/MobileDiaD/ScreenGeneral/ScreenPaseLista";
import ScreenInstalacionCasillaGeneral from "@components/MobileDiaD/ScreenGeneral/ScreenInstalacionCasilla";
import ScreenAperturaCasillaGeneral from "@components/MobileDiaD/ScreenGeneral/ScreenAperturaCasilla";
import ScreenBingoGeneral from "@components/MobileDiaD/ScreenGeneral/ScreenBingo";
import ScreenIncidenciasGeneral from "@components/MobileDiaD/ScreenGeneral/ScreenIncidencias";
import ScreenCierreCasillaGeneral from "@components/MobileDiaD/ScreenGeneral/ScreenCierreCasilla";
import ScreenFinVotacionGeneral from "@components/MobileDiaD/ScreenGeneral/ScreenFinVotacion";
import ScreenReporteResultadosGeneral from "@components/MobileDiaD/ScreenGeneral/ScreenReporteResultados";
import ScreenTransmisionGeneral from "@components/MobileDiaD/ScreenGeneral/ScreenTransmision";

// import { getVars } from "@utils/global";

const CardOption = ({ icon, label, handleClick }) => {
  return (
    <Box component={Card} className="card-primary" onClick={handleClick}>
      <CardContent sx={{ textAlign: "center" }}>
        <Icon fontSize="large" sx={{ color: blue[900] }}>
          {icon}
        </Icon>
        <Typography variant="body2" fontWeight={600}>
          {label}
        </Typography>
      </CardContent>
    </Box>
  );
};

const Welcome = ({ setTypeScreen }) => {
  // const { casilla_rc, ruta_rg /* user, menus  */ } = getVars("Token");

  const handleClickGeneral = () => setTypeScreen("general");

  return (
    <>
      <Navbar isScreen={false} disableGoHome enableLogout />
      <Container component="main">
        <Grid2 container spacing={2} height={"100vh"} justifyContent={"center"} alignItems={"center"}>
          <Grid2 xs={6} md={6}>
            <CardOption icon={"assignment"} label={"Día D General"} handleClick={handleClickGeneral} />
          </Grid2>
        </Grid2>
      </Container>
    </>
  );
};

const WelcomeDiaDGeneral = () => {
  const [typeScreen, setTypeScreen] = useState("welcome");

  const SwitchScreen = (type_screen) => {
    const screen = {
      welcome: <Welcome setTypeScreen={setTypeScreen} />,
      general: <GridGeneral setTypeScreen={setTypeScreen} />,

      /* General screens */
      PaseListaGeneral: <ScreenPaseListaGeneral setTypeScreen={setTypeScreen} />,
      InicioInstalacionGeneral: <ScreenInstalacionCasillaGeneral setTypeScreen={setTypeScreen} />,
      AperturaCasillaGeneral: <ScreenAperturaCasillaGeneral setTypeScreen={setTypeScreen} />,
      BingoGeneral: <ScreenBingoGeneral setTypeScreen={setTypeScreen} />,
      ReporteIncidenciasGeneral: <ScreenIncidenciasGeneral setTypeScreen={setTypeScreen} />,
      CierreCasillaGeneral: <ScreenCierreCasillaGeneral setTypeScreen={setTypeScreen} />,
      CierreVotacionGeneral: <ScreenFinVotacionGeneral setTypeScreen={setTypeScreen} />,
      ReporteResultadosGeneral: <ScreenReporteResultadosGeneral setTypeScreen={setTypeScreen} />,
      TransmisionGeneral: <ScreenTransmisionGeneral setTypeScreen={setTypeScreen} />,
    };

    return screen[type_screen];
  };

  return (
    <Box
      sx={{
        background:
          typeScreen === "welcome" || typeScreen === "general"
            ? "linear-gradient(to bottom,rgba(0, 102, 204, 1) 40%, rgba(255, 105, 180, 0.8) 60%, rgba(255, 69, 0, 0.7) 80%, rgba(255, 255, 0, 0.9) 100%);"
            : "none",
      }}
    >
      {SwitchScreen(typeScreen || "welcome")}
    </Box>
  );
};

export default WelcomeDiaDGeneral;
