// Material UI
import { IconButton } from "@mui/material";
import { ExpandMore, ExpandLess } from "@mui/icons-material";

const DrawerButton = ({ handleToggle, open }) => (
  <div className="leaflet-control-container">
    <div className="leaflet-top leaflet-right" style={{ top: 115 }}>
      <div className="leaflet-bar leaflet-control">
        <IconButton
          aria-label="Abrir menu superior"
          onClick={handleToggle}
          style={{ backgroundColor: "white" }}
          sx={{
            color: "#000",
            height: "30px",
            width: "30px",
            borderRadius: "3px",
            "&:hover": { backgroundColor: "#eeeeee!important" },
          }}
        >
          {open ? <ExpandLess style={{ fontSize: 25 }} /> : <ExpandMore style={{ fontSize: 25 }} />}
        </IconButton>
      </div>
    </div>
  </div>
);

export default DrawerButton;
