import ApiExec, { downloadFile, downloadFilePreview } from "@utils/ApiExec";
import { encryptVars, DEBUG } from "@utils/global";
//import { handleAttrs } from "@utils/Utilities";
import { ApiExecDecrypted } from "@utils/ApiExec";

class MovilizationServices {
  validateSection = (Seccion) => {
    const section = DEBUG ? Seccion : encryptVars(Seccion);
    return ApiExec({}, `movilization/validate-section/${section}`, "GET")
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };

  getBingosList = (params) => {
    return ApiExec(params, "movilization/get-bingos-list", "POST")
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };

  getMovilization = (params) => {
    return ApiExec(params, "movilization/get-movilizacion-controller", "POST")
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };

  setBingo = (params) => {
    return ApiExec(params, "movilization/set-bingo", "POST")
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };

  setMovilization = (params) => {
    return ApiExec(params, "movilization/set-movilizacion-controller", "POST")
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };

  deleteBingo = (params) => {
    return ApiExec(params, "movilization/delete-bingo", "DELETE")
      .then(function (res) {
        return res;
      })
      .then((res) => {
        return res;
      })
      .catch((e) => {
        return e;
      });
  };

  getSchedules = (params) => {
    return ApiExec(params, "movilization/get-schedules", "GET")
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };

  getDetailMobilizedByHour = (params) => {
    return ApiExec(params, "movilization/get-detalle-movilizados-hora", "POST")
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };

  exportDocuments = ({ params, api }) => {
    return downloadFile({
      api: `movilization/${api}`,
      method: "POST",
      data: params,
    })
      .then((res) => res)
      .catch((e) => e);
  };

  previewDocument = (params) => {
    return downloadFilePreview({
      data: params,
      api: "movilization/generate-mobilization-list",
      method: "POST",
    })
      .then((res) => res)
      .then((res) => res)
      .catch((e) => e);
  };

  getMobilizationDetails = (params) => {
    return ApiExec(params, "movilization/get-mobilization-details", "POST")
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  }
ƒ
  /* ELECTORAL */
  getCardsElectoral = (params) => {
    return ApiExec(params, "movilization/get-cards-electoral", "POST")
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };

  getAmbitoAgrupadorMovilizacion = (params) => {
    return ApiExec(
      params,
      "movilization/get-ambito-agrupador-movilizacion",
      "POST"
    )
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };

  getCompromisosMovElectoralChart = (params) => {
    return ApiExec(
      params,
      "movilization/get-compromisos-mov-electoral-chart",
      "POST"
    )
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };

  getCompromisosBingosChart = (params) => {
    return ApiExec(params, "movilization/get-compromisos-bingos-chart", "POST")
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };

  getMobilizedChartElectoralCumulative = (params) => {
    return ApiExec(
      params,
      "movilization/get-mobilized-chart-electoral-cumulative",
      "POST"
    )
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };

  // getMobilizedByScheduleChart = (params) => {
  //   return ApiExec(
  //     params,
  //     "movilization/get-mobilized-by-schedule-chart",
  //     "POST"
  //   )
  //     .then((res) => res)
  //     .then((res) => res)
  //     .catch((error) => error);
  // };

  getMovAdvanceElectoralDetails = (params) => {
    return ApiExec(
      params,
      "movilization/get-mov-advance-electoral-details",
      "POST"
    )
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };

  getMovilizedChartElectoralDesiredProgress = (params) => {
    return ApiExec(
      params,
      "movilization/get-mobilized-chart-electoral-desired-progress",
      "POST"
    )
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };

  exportMovAdvanceElectoralDetails = (params) => {
    return downloadFile({
      api: "movilization/export-mov-advance-electoral-details",
      method: "POST",
      data: params,
    })
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };

  getCompromisosMovElectoral = (params) => {
    return ApiExec(params, "bingos/get-bingos-name", "POST")
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };

  setParamsBingo = (params) => {
    return ApiExec(params, "bingos/set-bingo", "POST")
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };

  setParamsCompromiso = (params) => {
    return ApiExec(params, "bingos/set-bingo-compromiso", "POST")
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };

  getTotalsBySeccion = (params) => {
    return ApiExec(params, "bingos/get-details-promoter", "POST")
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };

  getdDetalleBySeccion = (params) => {
    return ApiExec(params, "bingos/get-promoter-summary", "POST")
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };

  getAmbitoAgrupadorMovilizacion = (params) => {
    return ApiExec(
      params,
      "movilization/get-ambito-agrupador",
      "POST"
    )
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };

  //Movilizadores
  getListings = (params) => {
    return ApiExecDecrypted(params, "decrypted/movilization/get-listings", "POST")
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  }

  getListings4Capture = (params) => {
    return ApiExecDecrypted(params, "decrypted/movilization/get-listings-4-capture", "POST")
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  }

  reportMobilized = (params) => {
    return ApiExecDecrypted(params, "movilization/report-mobilized", "POST")
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  }


}

const movilizacion = new MovilizationServices();
export default movilizacion;
