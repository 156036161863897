import { useState, useEffect } from "react";

// MUI
import { Card, CircularProgress, CardContent, Box } from "@mui/material";

import Swal from "sweetalert2";
import moment from "moment/moment";

// COMPONENTS
import PlacementColumns from "@components/Charts/DashChart";

import movilizadores from "@services/MovilizadoresServices";

const ProgressChart = (props) => {
  const { openDrawer } = props;
  const [loadingChart, setLoadingChart] = useState(false);
  const [data, setData] = useState({
    title: "",
    date: "",
    data: [],
    categories: [],
  });

  const ApiResponse = async () => {
    try {
      setLoadingChart(true);
      const result = await movilizadores.getMovilizadores({idAmbitoAgrupador: 8});
      const { results, message, response } = result;
      if (results) {
        const Secciones = response.data.map(item => String(item.Seccion));
        const Afin = response.data.map(item => parseInt(item.Promovidos));
        const Movilizados = response.data.map(item => parseInt(item.Movilizados));
        setData({
          ...data,
          title: "Avance por Sección",
          date: new Date(),
          data: [
            { name: "Afin", data: Afin },
            { name: "Movilizados", data: Movilizados },
          ],
          categories: Secciones,
        });
      } else {
        throw new Error(message);
      }
    } catch (error) {
      Swal.fire({ title: error.message, icon: "warning" });
    } finally {
      setLoadingChart(false);
    }
  };

  useEffect(() => {
    ApiResponse();
    //eslint-disable-next-line
  }, []);
  
  return (
    <Card className="card-primary" sx={{height: "100%", mb: "0 !important" }}>
      <CardContent key={openDrawer}>
        {loadingChart ? (
          <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "30vh" }}>
            <CircularProgress />
          </Box>
        ) : (
          <Box key={openDrawer}>
            <PlacementColumns
              titulo={{ title: data.title }}
              subtitle={{
                subtitle: moment(data.date).format("DD/MM/YYYY H:mm"),
              }}
              datos={{
                categories: data.categories,
                series: data.data,
              }}
              loading={loadingChart}
            />
          </Box>
        )}
      </CardContent>
    </Card>
  );
};

export default ProgressChart;
