import React, { useEffect, useState } from "react";
import {
  Box,
  Container,
  Icon,
  Stack,
  TextField,
  Typography,
} from "@mui/material";

import Toast from "../Toast";
import CustomTabs from "@components/Tabs/CustomTabs";
import ListsBingos from "../ListsBingos";
import Navbar from "../Navbar";

import { useMobileServices } from "@hooks/MobileDiaD/MobileServices";
import {
  ORIGEN_CAPTURA,
  TIPO_BINGO_COMPROMISO_UNICO,
  VOTO,
  NO_VOTO,
} from "@data/constants/MobileDiaD";

const FormBingos = ({ idCasilla, Seccion, NombreCasilla, setShowForm }) => {
  const { loading, loadingGet, snackbar, setSnackbar, getListings, setBingo } =
    useMobileServices();

  const [valueSearch, setValueSearch] = useState("");
  const [bingoListsVoto, setBingoListsVoto] = useState([]);
  const [bingoListsNoVoto, setBingoListsNoVoto] = useState([]);
  //const [dataGeneral, setDataGeneral] = useState([]);
  const [selectTab, setSelectTab] = useState("0");

  const handleGetBingoList = async () => {
    const response = await getListings({
      idCasilla,
      Seccion,
    });

    if (response) {
      const { data } = response;
      const voto = data.filter((item) => item.idEstatus === VOTO);
      const noVoto = data.filter((item) => item.idEstatus === NO_VOTO);

      setBingoListsVoto(voto);
      setBingoListsNoVoto(noVoto);
      //setDataGeneral(data);
    }
  };

  const handleSearch = (e) => {
    const { value } = e.target;
    setValueSearch(value.toUpperCase());
  };
  const data = selectTab === "0" ? bingoListsVoto : bingoListsNoVoto;

  const filteredData = data.filter((item) =>
    item.NombreCompleto.toLowerCase().includes(valueSearch.toLowerCase())
  );
  const total = filteredData.length;

  const handleSetBingo = async (idCompromisoUnico) => {
    const params = {
      idTipoBingo: TIPO_BINGO_COMPROMISO_UNICO,
      Seccion,
      idCasilla,
      idCompromisoUnico,
      OrigenCaptura: ORIGEN_CAPTURA,
    };

    const results = await setBingo(params);

    if (results) {
      //handleGetBingoList();
      const registered = bingoListsVoto.find(
        (item) => item.idCompromisoUnico === idCompromisoUnico
      );
      const newEarrings = bingoListsVoto.filter(
        (item) => item.idCompromisoUnico !== idCompromisoUnico
      );

      setBingoListsVoto(newEarrings);
      setBingoListsNoVoto((prevState) => [...prevState, registered]);
      setValueSearch("");
    }
  };

  useEffect(() => {
    if (idCasilla) handleGetBingoList();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <Toast
        open={snackbar.show}
        message={snackbar.message}
        severity={snackbar.severity}
        setOpen={setSnackbar}
      />

       <Navbar
          isScreen
          screenBack={false}
          title={"Bingos"}
          setTypeScreen={setShowForm}
          color="black"
          disableGoHome
        />

      <Container>
        <Stack direction={"column"}>
          <Box marginTop={4}>
            <Typography variant="body2">Sección</Typography>
            <Typography variant="subtitle1" fontWeight={600}>
              {Seccion}
            </Typography>
            <Typography variant="body2">Casilla</Typography>
            <Typography variant="subtitle1" fontWeight={600}>
              {NombreCasilla}
            </Typography>
          </Box>

          <TextField
            label="Buscar"
            variant="outlined"
            value={valueSearch}
            onChange={handleSearch}
            disabled={loadingGet}
            InputProps={{
              endAdornment: <Icon>search</Icon>,
            }}
            sx={{ mt: 2, mb: 2 }}
          />
        </Stack>

        {filteredData.length > 0 && (
          <Typography variant="bosy2" marginBottom={2}>
            Para realizar una captura, toca el nombre de la persona en el
            listado.
          </Typography>
        )}

        <CustomTabs
          value={selectTab}
          setValue={setSelectTab}
          tabs={[
            {
              value: "0",
              label: "PENDIENTES",
              component: (
                <>
                  {loadingGet ? (
                    <Typography textAlign={"center"} variant="body2">
                      Cargando...
                    </Typography>
                  ) : (
                    <ListsBingos
                      data={filteredData}
                      total={total}
                      handleClick={handleSetBingo}
                      disabled={loading}
                    />
                  )}
                </>
              ),
            },
            {
              value: "1",
              label: "CAPTURADOS",
              component: <ListsBingos data={filteredData} total={total} />,
            },
          ]}
        />
      </Container>
    </>
  );
};

export default FormBingos;
