import ApiExec, { downloadFile } from "@utils/ApiExec";

class AuditoriaServices {
  getTotals = (params) => {
    return ApiExec(params, "audit/get-totals", "POST")
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };
  getUserStats = (params) => {
    return ApiExec(params, "audit/get-user-stats", "POST")
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };
  getDetailsTracking = (params) => {
    return ApiExec(params, "audit/get-details-by-tracking", "POST")
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };
  getTotalsCopy = (params) => {
    return ApiExec(params, "audit/get-progress-per-day", "POST")
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };
  getProgressByScope = (params) => {
    return ApiExec(params, "audit/get-progress-by-scope", "POST")
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };
  getCatScopeReport = (params) => {
    return ApiExec(params, "audit/get-cat-scope-report", "GET")
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };
  getScopeEval = (params) => {
    return ApiExec(params, "catalogs/scope-eval", "GET")
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };
  exportProgressByScope = (params) => {
    return downloadFile({
      api: "audit/export-progress-by-scope",
      method: "POST",
      data: params,
    })
      .then((res) => res)
      .catch((e) => e);
  };
}

const auditoria = new AuditoriaServices();
export default auditoria;
