import { useEffect, useState } from "react";

const useSearchData = (data, setData, searchField) => {
  const [searchValue, setSearchValue] = useState("");
  const [filteredData, setFilteredData] = useState([]);

  const handleSearch = (value) => {
    const dataFound = data.filter((row) =>
      row[searchField].toLowerCase().includes(value.toLowerCase())
    );
    /* console.log("data", data.length);
    console.log("dataFound", dataFound.length); */

    setSearchValue(value.toUpperCase());
    //setData(dataFound);
    setFilteredData(dataFound);
  };

  /* useEffect(() => {
    setFilteredData(data);
  }, [data]); */

  return {
    searchValue,
    filteredData,
    handleSearch,
  };
};

export default useSearchData;
