import { useState, useEffect, memo } from "react";
import Swal from "sweetalert2";

// Material UI
import { Container, Typography, IconButton, Divider, Drawer, Toolbar, Box, CircularProgress } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import { ChevronLeft, ChevronRight } from "@mui/icons-material";

// Componentes
import AdminLayout from "@components/MainPage/AdminLayout";
import CardIconColor from "@components/Card/CardIconColor";
import CardColor from "@components/Card/CardColor";
import CardProgress from "@components/Welcome/CardProgress";

// Utils
import { getVars, setVars } from "@utils/global";
import { numberWithCommas } from "@utils/Utilities";

// Services
import ReportsServices from "@services/ReportsServices";

// Hooks
import useWindowDimensions from "@hooks/useWindowDimensions";

// Assets
import PANLogo from "@assets/img/PAN_logo.png";
import PRILogo from "@assets/img/PRI_logo.png";
import PRDLogo from "@assets/img/PRD_logo.png";
import Back from "@assets/img/back02.png";
import c27 from "@assets/img/c27.png";

const Welcome = () => {
  const { user } = getVars("Token");
  const { width } = useWindowDimensions();

  const username = `${user.Nombre} ${user.Paterno} ${user.Materno}`.split(" ");
  let delayNumber = 0;

  const [localWidth, setLocalWidth] = useState(0 + "px");
  const [drawerOpen, setDrawerOpen] = useState(true);

  const [drawerInfo, setDrawerInfo] = useState([]);
  const [loadingDrawer, setLoadingDrawer] = useState(true);

  const [totalesAvance, setTotalesAvance] = useState({});
  const [fechaFin, setFechaFin] = useState();
  const [loading, setLoading] = useState(true);
  const [timeRemaining, setTimeRemaining] = useState({ days: 0, hours: 0, minutes: 0, seconds: 0 });

  useEffect(() => {
    setVars("sideBar", "");

    getAvances();

    if (fechaFin) {
      // Calcula el tiempo restante
      const targetDate = new Date(fechaFin); // Cambia a tu fecha objetivo
      const now = new Date();

      const timeDiff = targetDate - now;

      if (timeDiff > 0) {
        const days = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
        const hours = Math.floor((timeDiff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const minutes = Math.floor((timeDiff % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((timeDiff % (1000 * 60)) / 1000);

        setTimeRemaining({ days, hours, minutes, seconds });

        // Actualiza la cuenta regresiva cada segundo
        const countdownInterval = setInterval(() => {
          if (timeDiff > 0) {
            const updatedDiff = targetDate - new Date();
            const updatedDays = Math.floor(updatedDiff / (1000 * 60 * 60 * 24));
            const updatedHours = Math.floor((updatedDiff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
            const updatedMinutes = Math.floor((updatedDiff % (1000 * 60 * 60)) / (1000 * 60));
            const updatedSeconds = Math.floor((updatedDiff % (1000 * 60)) / 1000);

            setTimeRemaining({
              days: updatedDays,
              hours: updatedHours,
              minutes: updatedMinutes,
              seconds: updatedSeconds,
            });
          } else {
            clearInterval(countdownInterval);
          }
        }, 1000);
      }
    }
    //eslint-disable-next-line
  }, [fechaFin]);

  const getAvances = async () => {
    setLoading(true);
    setLoadingDrawer(true);

    try {
      const r1 = await ReportsServices.getPromotionEndDate({});
      const r2 = await ReportsServices.getStatsTable({ type: 1 });
      const r3 = await ReportsServices.getTotalsByElectoralParties({ type: 1 });

      const { results, response, message } = r1;
      const { results: results2, response: response2, message: message2 } = r2;
      const { results: results3, response: response3, message: message3 } = r3;

      if (results) setFechaFin(response.FechaFin);
      else Swal.fire({ title: message, icon: "warning" });

      if (results2) setDrawerInfo(response2.data);
      else {
        Swal.fire({ title: message2, icon: "warning" });
        setDrawerInfo([]);
      }

      if (results3) {
        const diferencia = parseInt(response3.AvanceGRAL) - (parseInt(response3.MetaPRI) + parseInt(response3.MetaPRD) + parseInt(response3.MetaPAN));
        response3.diferencia = diferencia;

        setTotalesAvance(response3);
      } else {
        Swal.fire({ title: message3, icon: "warning" });
        setTotalesAvance({});
      }
    } catch (error) {
      Swal.fire({ title: error.message, icon: "warning" });
    } finally {
      setLoading(false);
      setLoadingDrawer(false);
    }
  };

  const handleDrawerToggle = () => setDrawerOpen(!drawerOpen);

  useEffect(() => {
    if (drawerOpen) {
      const elemento = document.getElementById("WelcomeDrawer");
      const ancho = elemento ? elemento.offsetWidth : 0;
      let remove = ancho / 10;

      if (width < 1400) remove = ancho / 12;
      else if (width < 1500) remove = ancho / 4;
      else if (width < 1600) remove = ancho / 2.5;
      else if (width < 1700) remove = ancho / 1.85;
      else if (width < 1800) remove = ancho / 1.45;
      else if (width < 1900) remove = ancho / 1.2;
      else remove = ancho;

      setLocalWidth(ancho - remove + "px");
    } else setLocalWidth(0 + "px");

    // eslint-disable-next-line
  }, [drawerOpen, width]);

  return (
    <AdminLayout backgroundImage={Back}>
      <style>{` @keyframes bounce {
                    0%,100%{ transform:translate(0); }
                    25%{ transform:rotateX(20deg) translateY(2px) rotate(-3deg); }
                    50%{ transform:translateY(-10px) rotate(3deg) scale(1.05);  }
                }`}</style>
      <Container
        component="main"
        sx={{ flexGrow: 1, px: { xs: 0, sm: "24px" }, "&:hover .span-word": { animation: "bounce .6s" } }} /* maxWidth="lg" */
      >
        <Grid2 container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3, lg: 2 }} sx={{ paddingRight: localWidth }}>
          <Grid2 xs={12} sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography variant="h5" fontWeight={700} textTransform="uppercase" style={{ flex: 1 }}>
              <span
                style={{
                  display: "inline-block",
                  animation: "bounce 2s ease-in-out infinite",
                  animationDelay: "1s",
                  marginRight: 8,
                }}
              >
                ¡Hola!
              </span>
              {username.map((item, index) => (
                <span key={index} style={{ display: "inline-block", marginRight: 8 }}>
                  {item.split("").map((word, i) => (
                    <span
                      className="span-word"
                      key={i}
                      style={{
                        display: "inline-block",
                        animationDelay: `${delayNumber++ * 0.05}s`,
                      }}
                    >
                      {word}
                    </span>
                  ))}
                </span>
              ))}
            </Typography>

            {!drawerOpen && (
              <IconButton
                aria-label="Abrir menu lateral"
                onClick={handleDrawerToggle}
                style={{ height: "30px", width: "30px" }}
                sx={{ display: { xs: "none", md: "flex" } }}
              >
                <ChevronLeft style={{ fontSize: 25, color: "#000" }} />
              </IconButton>
            )}
          </Grid2>
          <Grid2 xs={12} md={6} lg={4}>
            <Box sx={{ height: "215px" }}>
              <img src={`${c27}`} alt="logo" style={{ width: "100%", height: "100%", objectFit: "contain" }} />
            </Box>
          </Grid2>
          <Grid2 xs={12} md={6} lg={4}>
            <CardIconColor
              title="Compromisos"
              total={parseInt(totalesAvance?.AvanceGRAL || 0, 10)}
              color="success"
              icon={"schema"}
              sx={{ height: "215px" }}
              loading={loading}
            />
          </Grid2>
          <Grid2 xs={12} md={12} lg={4}>
            <CardIconColor
              title={`${timeRemaining.hours} horas, ${timeRemaining.minutes} minutos, ${timeRemaining.seconds} segundos`}
              total={timeRemaining.days}
              totalTextR={"días"}
              color="warning"
              icon={"schema"}
              sx={{ height: "215px" }}
              loading={loading}
            />
          </Grid2>
        </Grid2>
        <Grid2 container rowSpacing={6} columnSpacing={{ xs: 1, sm: 2, md: 1, lg: 2 }} sx={{ paddingRight: localWidth, marginTop: 3 }}>
          <Grid2 xs={12} sm={6} lg={4}>
            <CardColor
              title="Compromisos"
              total={parseInt(totalesAvance?.AvancePAN || 0, 10)}
              image={PANLogo}
              textColor="#fff"
              backgroundColor="#00308E"
              backgroundImage="#00308E"
              boxShadow={3}
              loading={loading}
            />
          </Grid2>
          <Grid2 xs={12} sm={6} lg={4}>
            <CardColor
              title="Compromisos"
              total={parseInt(totalesAvance?.AvancePRI || 0, 10)}
              image={PRILogo}
              textColor="#000000"
              backgroundColor="#AAA9A9"
              backgroundImage="#AAA9A9"
              boxShadow={3}
              loading={loading}
            />
          </Grid2>
          <Grid2 xs={12} sm={6} lg={4}>
            <CardColor
              title="Compromisos"
              total={parseInt(totalesAvance?.AvancePRD || 0, 10)}
              image={PRDLogo}
              textColor="#000000"
              backgroundColor="#F7CF00"
              backgroundImage="#F7CF00"
              boxShadow={3}
              loading={loading}
            />
          </Grid2>
          <Grid2 xs={12} sm={6} lg={12}>
            <CardColor
              title="Compromisos"
              total={parseInt(totalesAvance?.diferencia || 0, 10)}
              icon="groups"
              textColor="#000000"
              backgroundColor="#cfd8dc"
              backgroundImage="#cfd8dc"
              boxShadow={3}
              loading={loading}
            />
          </Grid2>
        </Grid2>

        <Box sx={{ display: { xs: "block", md: "none" }, marginTop: 3 }}>
          <Typography variant="h6" noWrap component="div" sx={{ marginBottom: 1 }}>
            Avance Nacional
          </Typography>
          <Box sx={{ minWidth: "250px", display: "flex", flexDirection: { xs: "column", sm: "row" }, flexWrap: "wrap", gap: 1 }}>
            {!loadingDrawer ? (
              drawerInfo.length > 0 &&
              drawerInfo.map((item, index) => (
                <CardProgress
                  key={index}
                  progress={item.pavance + ""}
                  title={item.estado}
                  total={item.value}
                  align="right"
                  sx={{ flex: 1 }}
                  sxText={{ maxWidth: "200px" }}
                  tooltip={{ title: `Meta: ${numberWithCommas(item.meta)}`, placement: "top" }}
                />
              ))
            ) : (
              <CircularProgress sx={{ marginY: 3, marginX: "auto" }} />
            )}
          </Box>
        </Box>
      </Container>

      <Drawer
        variant="persistent"
        anchor="right"
        open={drawerOpen}
        sx={{
          display: { xs: "none", md: "block" },
          "& .MuiDrawer-paper": {
            zIndex: 1,
            overflowY: "auto",
            scrollbarWidth: "thin",
            marginTop: "64px",
            "&::-webkit-scrollbar": { width: "6px" },
            "&::-webkit-scrollbar-thumb": { backgroundColor: "rgba(0, 0, 0, 0.2)", borderRadius: "6px" },
            "&::-webkit-scrollbar-track": { backgroundColor: "rgba(0, 0, 0, 0.1)" },
          },
        }}
      >
        <Box id="WelcomeDrawer" px={2} height="100%">
          <Toolbar style={{ paddingLeft: 0, paddingRight: 0, justifyContent: "space-between" }}>
            <Typography variant="h6" noWrap component="div">
              Avance Nacional
            </Typography>
            <IconButton onClick={handleDrawerToggle}>
              <ChevronRight />
            </IconButton>
          </Toolbar>
          <Divider sx={{ marginBottom: 2 }} />

          <Box minWidth="300px" maxWidth="300px" sx={{ display: "flex", flexDirection: "column", gap: 1, paddingBottom: 2 }}>
            {!loadingDrawer ? (
              drawerInfo.length > 0 &&
              drawerInfo.map((item, index) => (
                <CardProgress
                  key={index}
                  progress={item.pavance + ""}
                  title={item.estado}
                  total={item.value}
                  align="right"
                  sxText={{ maxWidth: "200px" }}
                  tooltip={{ title: `Meta: ${numberWithCommas(item.meta)}`, placement: "left" }}
                />
              ))
            ) : (
              <CircularProgress sx={{ marginY: 3, marginX: "auto" }} />
            )}
          </Box>
        </Box>
      </Drawer>
    </AdminLayout>
  );
};

export default memo(Welcome);
