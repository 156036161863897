import { useState } from "react";
import {
  TableRow,
  TableCell,
  Icon,
  IconButton,
  Checkbox,
} from "@mui/material";
import useWindowDimensions from "@hooks/useWindowDimensions";
import { MENUS_PRIVILEGES } from "@data/constants"

const MenuRow4Select = (props) => {
  const { menu, select } = props;
  const [submenus] = useState(menu.submenus);
  const columns = MENUS_PRIVILEGES;
  const [open, setOpen] = useState(false);
  const { width } = useWindowDimensions();

  return (
    <>
      <TableRow>
        <TableCell width="5%">
          {menu.submenus && menu.submenus.length > 0 && (
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? (
                <Icon>keyboard_arrow_up_icon</Icon>
              ) : (
                <Icon>keyboard_arrow_down_icon</Icon>
              )}
            </IconButton>
          )}
        </TableCell>
        <TableCell align="right" width="5%">
          {select && (
            <Checkbox
              color="primary"
            // checked={isItemSelected}
            // inputProps={{
            //   "aria-labelledby":
            // }}
            />
          )}
        </TableCell>
        <TableCell align="right" width="5%">
          {menu.Icono ? (
            <Icon>{menu.Icono}</Icon>
          ) : menu.Siglas ? (
            menu.Siglas
          ) : (
            ""
          )}
        </TableCell>
        <TableCell width="25%">
          {menu.Menu} <br />
          <small>{menu.Ruta}</small>
        </TableCell>
        {columns.map((col) => {
          return (
            <TableCell align="center" key={Math.random()} width="10%">
              {menu[`_${col}`] === 1 ? <Icon>check_circle</Icon> : "-"}
            </TableCell>
          );
        })}
      </TableRow>
      {submenus && submenus.length > 0 && submenus.map((submenu) =>
      (
        <TableRow key={submenu.id} sx={{ display: `${open ? '' : 'none'}` }}>
          <TableCell
            align="right"
            colSpan={2}
            width={width <= 1440 ? "12%" : "18%"}
          >
            {select && <Checkbox color="primary" />}
          </TableCell>
          <TableCell align="right" width="4%">
            {submenu.Icono ? (
              <Icon>{submenu.Icono}</Icon>
            ) : submenu.Siglas ? (
              submenu.Siglas
            ) : (
              ""
            )}
          </TableCell>
          <TableCell
            width={width <= 1440 ? "11%" : "18%"}
            sx={{ pr: 0 }}
          >
            {submenu.Menu} <br />
            <small>{submenu.Ruta}</small>
          </TableCell>
          {columns.map((col) => (
            <TableCell key={Math.random()} width="10%" align="center">
              &nbsp;{menu[col] === 1 ? <Icon>check_circle</Icon> : "-"}
            </TableCell>))
          }
        </TableRow>
      )
      )}
    </>
  );
};

export default MenuRow4Select;
