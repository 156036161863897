import { useEffect, useState } from "react";

// Material UI
import { Container, Grid, Button, Typography } from "@mui/material";
import Swal from "sweetalert2";

//Services
import certificacion from "@services/CertificationServices";

// Components
import AdminLayout from "@components/MainPage/AdminLayout";
import ActionHeader from "@components/Containers/ActionHeader";
import LoadingForm from "@components/LinearProgress/LoadingForm";
import DrawerRelative from "@components/Containers/DrawerRelative";
import FloatingButton from "@components/Button/FloatingButton";

import Filter from "@components/Certificacion/DashboardCertifications/Filter";
import LastUpdatedCard from "@components/Certificacion/DashboardCertifications/LastUpdatedCard";
import TableCertification from "@components/Certificacion/DashboardCertifications/TableCertification";
import TablePendingCertification from "@components/Certificacion/DashboardCertifications/TablePendingCertification";
import AffinityCertifiedPeople from "@components/Certificacion/DashboardCertifications/AffinityCertifiedPeople"
import AccumulatedCertifiedPeople from '@components/Certificacion/DashboardCertifications/AccumulatedCertifiedPeople'
import ChartCertificationProgress from "@components/Certificacion/DashboardCertifications/ChartCertificationProgress";

// Iconos
import {
    KeyboardDoubleArrowRight,
    KeyboardDoubleArrowLeft,
} from "@mui/icons-material";

const DashboardCertification = () => {
    const [ambitoUser, setAmbitoUser] = useState("");

    const [openDrawer, setOpenDrawer] = useState(true);

    const total = 10;
    const [loading, setLoading] = useState({
        isLoadigForm: false,
        isSuccessForm: false,
        openLinearProgress: false,
    });
    const [refresh, setRefresh] = useState(false);

    const [idFilter, setIdFilter] = useState(ambitoUser);

    const [idAmbitoAgrupadorTable, setIdAmbitoAgrupadorTable] =
        useState(ambitoUser);

    const [catView, setCatView] = useState([]);
    const [tableGrouper, setTableGrouper] = useState([]);
    const [data, setData] = useState({
        page: 0,
        pageSize: 10,
        filtered: [],
        sorted: [],
        idAmbitoAgrupador: ambitoUser,
    });

    const [bodyChart, setBodyChart] = useState({
        filtered: [],
        idAmbitoAgrupador: ambitoUser,
    });

    const handleSearchFilter = ({ filtereds }) => {
        setData((prev) => ({
            ...prev,
            filtered: filtereds,
            page: 0,
            pageSize: 10,
        }));
        setBodyChart((prev) => ({ ...prev, filtered: filtereds }));
    };

    const handleClearFilter = () => {
        if (data.filtered.length > 0 || data.idAmbitoAgrupador !== ambitoUser) {
            setData((prev) => ({
                ...prev,
                filtered: [],
                idAmbitoAgrupador: ambitoUser,
            }));
            setBodyChart((prev) => ({
                ...prev,
                filtered: [],
                idAmbitoAgrupador: ambitoUser,
            }));
            setIdAmbitoAgrupadorTable(ambitoUser);
            setTableGrouper(catView);
        }
    };

    const handleRefresh = () => {
        setData((prev) => ({ ...prev, page: 0, pageSize: 10, sorted: [] }));
        setRefresh(true);
    };

    const changeTypeViewFilter = (view) => {
        const option = catView.find(item => item.value === view);
        const filtered = catView.filter(item => item.Nivel >= option.Nivel);
        setTableGrouper(filtered);
        setIdAmbitoAgrupadorTable(filtered[0]?.value || ambitoUser);

        setBodyChart((prev) => ({
            ...prev,
            filtered: [],
            idAmbitoAgrupador: view,
        }));
        setData((prev) => ({
            ...prev,
            page: 0,
            pageSize: 10,
            filtered: [],
            idAmbitoAgrupador: view,
        }));
    };

    const getCatView = async () => {
        try {
            const res = await certificacion.getAmbitoAgrupadorMovilizacion();
            const { results, response, message } = res;

            if (results) {
                const data = response.data.sort((a, b) => a.value - b.value);
                setCatView(data);
                setTableGrouper(data);
                setAmbitoUser(data[0]?.value);
            } else {

                throw new Error(message);
            }
        } catch (error) {
            Swal.fire({ title: error.message, icon: "warning" });
        }
    };

    useEffect(() => {
        getCatView();
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (ambitoUser) {
            setIdAmbitoAgrupadorTable(ambitoUser);
            setBodyChart((prev) => ({
                ...prev,
                filtered: [],
                idAmbitoAgrupador: ambitoUser,
            }));
            setData((prev) => ({
                ...prev,
                page: 0,
                pageSize: 10,
                filtered: [],
                idAmbitoAgrupador: ambitoUser,
            }));
        }
        // eslint-disable-next-line
    }, [ambitoUser]);

    return (
        <AdminLayout>
            <LoadingForm
                loadinMessage={"Cargando datos..."}
                isLoading={loading.isLoadigForm}
                success={loading.isSuccessForm}
                isOpen={loading.openLinearProgress}
                setIsOpen={() => setLoading({ ...loading, openLinearProgress: false })}
            />
            <Container maxWidth={false}>
                <ActionHeader title={"Dashboard Certificación"} isCustom={true}>
                    <Button
                        onClick={() => setOpenDrawer(!openDrawer)}
                        aria-label="open Drawer"
                        endIcon={
                            openDrawer ? (
                                <KeyboardDoubleArrowRight />
                            ) : (
                                <KeyboardDoubleArrowLeft />
                            )
                        }
                    >
                        <Typography sx={{ fontWeight: 500 }}>FILTROS</Typography>
                    </Button>
                </ActionHeader>

                <Grid
                    container
                    spacing={openDrawer ? 2 : 0}
                    sx={{ flexDirection: { xs: "column-reverse", md: "row" } }}
                >
                    <Grid item xs={12} md={openDrawer ? 8 : 12}>
                        <Grid container spacing={2}>
                            <Grid
                                item
                                xs={12}
                                md={openDrawer ? 12 : 4}
                                lg={4}
                            >
                                <LastUpdatedCard
                                    data={data}
                                    dateHour={""}
                                    handleClick={handleRefresh}
                                    refresh={refresh}
                                    columnIn={openDrawer ? "lg" : "md"}
                                />
                            </Grid>

                            <Grid item xs={12} sm={6} md={openDrawer ? 6 : 4} lg={4}>
                                <AccumulatedCertifiedPeople data={data} openDrawer={openDrawer} setRefresh={setRefresh} refresh={refresh} />
                            </Grid>

                            <Grid item xs={12} sm={6} md={openDrawer ? 6 : 4} lg={4}>
                                <AffinityCertifiedPeople data={data} openDrawer={openDrawer} />
                            </Grid>
                        </Grid>

                        <Grid item xs={12}>
                            <ChartCertificationProgress
                                body={bodyChart}
                                setLoading={setLoading}
                                openDrawer={openDrawer}
                                refresh={refresh}
                                ambitoUser={ambitoUser} />
                        </Grid>
                        <Grid item xs={12}>
                            <TablePendingCertification data={data} />
                        </Grid>

                        <Grid item xs={12}>
                            <TableCertification
                                idAmbitoAgrupadorTable={idAmbitoAgrupadorTable}
                                total={total}
                                data={data}
                                loading={loading}
                                setLoading={setLoading}
                                catView={tableGrouper}
                                idFilter={idFilter}
                                ambitoUser={ambitoUser}
                            />
                        </Grid>
                    </Grid>

                    <Grid item xs={12} md={4}>
                        <DrawerRelative
                            anchor="right"
                            open={openDrawer}
                            setOpen={setOpenDrawer}
                            title={"Filtrar"}
                            icon="person_search"
                            titleComponentPlace="under"
                            className="card-primary"
                            sxPaper={{ borderRadius: 3 }}
                            isSticky
                        >
                            <Filter
                                onSearch={handleSearchFilter}
                                onClear={handleClearFilter}
                                changeTypeView={changeTypeViewFilter}
                                refresh={refresh}
                                setLoading={setLoading}
                                catView={catView}
                                setIdFilter={setIdFilter}
                                ambitoUser={ambitoUser}
                            />
                        </DrawerRelative>
                    </Grid>
                </Grid>
                {!openDrawer && (
                    <FloatingButton
                        onClick={() => setOpenDrawer(true)}
                        label="Mostrar Filtros"
                        sx={{ display: { xs: "none", md: "block" } }}
                    />
                )}
            </Container>
        </AdminLayout>
    );
};

export default DashboardCertification;
