import { useTheme } from "@mui/material/styles";
import {
  Divider,
  Grid,
  useMediaQuery,
  Container,
  ButtonGroup,
  Button,
  Icon
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import MainCard from "../../components/Card/MainCard";
import AuthRegister from "../../components/Auth/AuthRegister";
import { blue } from "@mui/material/colors";
import { useState } from "react";
import avatar from "@assets/img/c27.png";

const RegisterPublic = () => {
  const theme = useTheme();
  let navigate = useNavigate();
  const matchDownSM = useMediaQuery(theme.breakpoints.down("md"));
  const [type, setType] = useState(1);

  const handleType = (idType) => {
    setType(idType);
  };

  return (
    <Container sx={{ backgroundColor: blue[1], minHeight: "100vh" }}>
      <MainCard>
        <Grid container spacing={2} alignItems="center" justifyContent="center">
          <Grid item xs={12}>
            <Grid
              container
              direction={matchDownSM ? "column-reverse" : "row"}
              alignItems="center"
              justifyContent="center"
            >
              <Grid item xs={12}>
                <img alt="Logo" src={avatar} width={50} height={50} />
              </Grid>
              <Grid item>
                <ButtonGroup size="large" variant="outlined">
                  <Button
                    color="info"
                    key={Math.random()}
                    onClick={(e) => handleType(1)}
                    variant={1 === type ? "contained" : "outlined"}
                    sx={{
                      borderWidth: "1px",
                      borderRadius: "30px",
                      pr: "23 px",
                      pl: "23 px",
                    }}
                  >
                    Con Invitación
                  </Button>
                  <Button
                    color="info"
                    key={Math.random()}
                    onClick={(e) => handleType(2)}
                    variant={2 === type ? "contained" : "outlined"}
                    sx={{
                      borderWidth: "1px",
                      borderRadius: "30px",
                      pr: "23 px",
                      pl: "23 px",
                    }}
                  >
                    Sin Invitación
                  </Button>
                </ButtonGroup>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <AuthRegister type={type} />
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            <Grid item container direction="column" alignItems="center" xs={12}>
              <Button
                variant="outlined"
                startIcon={<Icon>login</Icon>}
                onClick={() => navigate("/login")}
                size="small"
                color="success"
              >
                Iniciar Sesión
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </MainCard>
    </Container>
  );
};

export default RegisterPublic;
