import { PrivilegedRoute, ProtectedRoute } from "@middlewares";
import MainPage from "@pages/Numeralia";
import National from "@pages/Numeralia/National/National";

const NumeraliaRoutes = [
  {
    path: "/numeralia",
    element: (
      <PrivilegedRoute>
        <MainPage />
      </PrivilegedRoute>
    ),
  },
  {
    path: "/numeralia-nacional",
    element: (
      <ProtectedRoute>
        <National />
      </ProtectedRoute>
    ),
  },
];

export default NumeraliaRoutes;
