import { Fragment, useEffect, useState } from "react";
import {
  Typography,
  ListItemAvatar,
  ListItemText,
  Divider,
  Icon,
  List,
  Stack,
  IconButton,
  LinearProgress,
  Paper,
  InputBase,
  Chip,
  ListItemButton,
  Grid,
  Box,
  CardActions
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";

import SearchIcon from "@mui/icons-material/Search";
import InfiniteScroll from "react-infinite-scroll-component";
import BasicSelect from "@components/Selects/BasicSelect";
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import { isEmptyOrInvalidArray,isEmptyOrNullObject } from "@utils/validations";


const ListComponente = (props) => {
  const {
    avatar,
    Buscar,
    titleSearch,
    selectBasic = true,
    dataBasic = {
      title: "tilte",
      options: [],
      required: false
    },
    loading,
    loadingButon,
    buttonLoading,
    pageSize,
    config = {
      height: "770px",
      endMessage: "Fin de la lista",
    },
    data = [],
    titleLineDivider,
    fetchHasMore,
    totalItems,
    /*itemComponent, */
    hasMore,
    ClickItem,
    handleClickItem,
    handleSearchItems,
    handleDeleteItem,
    searchText,
    setSearch,
    idEstructura,
    structs
  } = props;
  const [showedItems, setShowedItems] = useState(0);
  const [listingData, setListingData] = useState([]);
  const [textSearch, setTextSearch] = useState(searchText);
  const [responsabilidad, setResponsabilidad] = useState({
    data: null,
    responsabilidad: {},
    idEstructura:idEstructura,
    errors:{value:false,label:""}
  });
  const animatedComponents = makeAnimated();

  const selectStyles = {
    menuPortal: base => ({ ...base, zIndex: 9999 }),
    menu: provided => ({ ...provided, zIndex: "9999 !important" })
  };


  useEffect(() => {
    setListingData(data);
    setShowedItems(data.length);
  }, [data]);


 /*  useEffect(()=>{},[responsabilidad.responsabilidad]) */

  const search = () => {
    let paramsFilter = {
      page: 0,
      pageSize: pageSize,
      filtered: [],
    };

    if (textSearch !== "") {
      let filter = textSearch.replace(/\s+/g, "%");
      paramsFilter = {
        page: 0,
        pageSize: pageSize,
        filtered: [
          {
            id: "CONCAT_WS('',usuarios.Nombre,usuarios.Paterno,usuarios.Materno,usuarios.Celular)",
            filter: "LIKE",
            value: `%${filter}%`,
          },
        ],
      };
    }

    setSearch(textSearch);
    handleSearchItems(paramsFilter);
    //setParams(paramsFilter);
  };


  const hadleSelect = (data) => {
    setResponsabilidad({
      ...responsabilidad,
      data: data,
    })
    setTextSearch(data.NombreCompleto);
  };

  const handleButton = () => {
      if(dataBasic.required){
        if(!responsabilidad.responsabilidad.value || responsabilidad.responsabilidad.value < 1){
          setResponsabilidad({
            ...responsabilidad,
            errors:{label:`${dataBasic.title} es requerido`, value:true}
          });

          return false;
        }
      }

      let estructura = Array.isArray(responsabilidad.idEstructura) ? isEmptyOrInvalidArray(responsabilidad.idEstructura) :isEmptyOrNullObject(responsabilidad.idEstructura);
      
     if(estructura){
      setResponsabilidad({
        ...responsabilidad,
        errors:{label:`Segmento es requerido`, value:true}
      });

      return false;
      } 

     setResponsabilidad({
        ...responsabilidad,
        errors:{label:"", value:false}
      });

      handleClickItem(responsabilidad);
      buttonLoading(true); 
  };

  const handleChangeStruct = (e) =>{
    setResponsabilidad({
      ...responsabilidad,
      errors:{label:"",value:false},
      idEstructura:e
    })
  };

  return (
    <>
      {Buscar && (
        <>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Paper
                sx={{ p: "2px 4px", display: "flex", alignItems: "center" }}
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    e.preventDefault();
                    search(e);
                  }
                }}
              >
                <InputBase
                  sx={{ ml: 1, flex: 1 }}
                  placeholder={titleSearch}
                  value={textSearch}
                  onChange={(e) => setTextSearch(e.target.value)}
                  inputProps={{ "aria-label": titleSearch }}
                />
                <IconButton
                  type="button"
                  sx={{ p: "10px" }}
                  aria-label="search"
                  onClick={(e) => search(e)}
                >
                  <SearchIcon />
                </IconButton>
              </Paper>
            </Grid>
            {selectBasic && responsabilidad.data && (
              <>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <BasicSelect
                    name={dataBasic.title}
                    label={`${dataBasic.title}`}
                    options={dataBasic.options}
                    value={responsabilidad.responsabilidad.value}
                    //onBlur={handleBlur}
                    onChange={(e) =>
                      setResponsabilidad({
                        ...responsabilidad,
                        responsabilidad: e.target,
                        idEstructura:[],
                        errors:{label:"", value:false}
                      })
                    }
                    size="small"
                    //isLoading={isLoading}
                    sx={{ width: "100%" }}
                  />
                </Grid>
              </>
            )}
            {responsabilidad.data && (
              <>
              {responsabilidad.responsabilidad.value !== 131 && <Grid item xs={12} sm={12} md={12} lg={12}>
                <Select
                  menuPlacement="top"
                  styles={selectStyles}
                  closeMenuOnSelect={false}
                  components={animatedComponents}
                  value={responsabilidad.idEstructura}
                  onChange={handleChangeStruct}
                  isMulti ={(responsabilidad.responsabilidad.value === 34 || dataBasic.options.length === 0 ) ? true : false}
                  options={structs}
                  //isLoading={loadigCatalogs}
                />
                </Grid>}
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Stack
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="center"
                    spacing={2}
                  >
                  {responsabilidad.errors.value && <Typography variant="caption" color={"error"}>{responsabilidad.errors.label}</Typography>}
                  <LoadingButton
                      disabled={responsabilidad.errors.value}
                      color="info"
                      onClick={(e)=>handleButton(e)}
                      loading={loadingButon}
                      loadingPosition="start"
                      startIcon={<Icon>assignment_returned</Icon>}
                      variant="contained"
                      size="small"
                    >
                      <span>Asignar</span>
                    </LoadingButton>
                  </Stack>
                </Grid>
                </>
            )}
          </Grid>
          <br />
        </>
      )}
      <InfiniteScroll
        dataLength={showedItems}
        next={fetchHasMore}
        hasMore={hasMore}
        loader={<LinearProgress color="secondary" />}
        height={config.height}
        endMessage={
          <p style={{ textAlign: "center" }}>
            <b>
              {config.endMessage !== undefined
                ? config.endMessage
                : "¡Final de la lista de registros!"}
            </b>
          </p>
        }
      >
      {
        loading && <LinearProgress color="secondary" />
      }
        <List dense={true} sx={{bgcolor: "background.paper" }}>
          {listingData.map((item, index) => {
            return (
              <>
                <ListItemButton
                  sx={{
                    color: "darkBlue",
                    borderRadius: "16px",
                    backgroundColor: "#f1fffd",
                    pb: 5,
                  }}
                  divider={true}
                  disabled={ClickItem}
                  /*    selected={selectedIndex === 0}*/
                  onClick={() => hadleSelect(item)}
                >
                  {avatar && <ListItemAvatar>{avatar}</ListItemAvatar>}
                  <ListItemText
                    primary={
                      <Fragment>
                        <Box sx={{ ml: 3, mr: 3 }}>
                          <Grid container alignItems="center">
                            <Grid item xs>
                              <Typography gutterBottom variant="h5">
                                {item.title.toUpperCase()}
                              </Typography>
                            </Grid>
                          </Grid>
                          <Typography color="#1976d2" variant="body2">
                            {item.subTitle}
                          </Typography>
                        </Box>
                        <Box sx={{ ml: 4, mr: 4, mt: 1}}>
                          {
                            item.content.length > 0 &&
                            <>
                          <Divider textAlign="left">{titleLineDivider}</Divider>
                          <Stack direction="row" spacing={1} sx={{flexWrap: "wrap"}}>
                            {item.content.map((data) => {
                              return (
                                <>
                                  {data.validate ? (
                                    <Chip
                                      label={data.Responsabilidad}
                                      color="primary"
                                      variant="outlined"
                                      sx={{mb:1}}
                                      /*  onClick={handleClick}*/
                                      onDelete={()=>handleDeleteItem({...data,idUsuario:item.id})}
                                    />
                                  ) : (
                                    <Chip
                                      label={data.Responsabilidad}
                                      sx={{mb:1}}
                                      color="primary"
                                      variant="outlined"
                                    />
                                  )}
                                </>
                              );
                            })}
                          </Stack>
                            </>
                          }
                        
                        </Box>
                      </Fragment>
                    }
                  />
                </ListItemButton>
                {/* <Divider variant="inset" component="li" /> */}
              </>
            );
          })}
        </List>
      </InfiniteScroll>
      <CardActions>
        <small>
          Mostrando {showedItems} de {totalItems}
        </small>
      </CardActions>
    </>
  );
};
export default ListComponente;
