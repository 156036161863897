import { useState, useEffect, useContext } from "react";

// Componentes
import Map from "@components/Maps/CustomComponents/GenericMap";
import ReportsContext from "@components/Reports/ReportsContext";

// Estilos de mapas
import { style_original } from "@components/Maps/Auxiliars/ColorFeature";

const elements = {
  manzana: [
    { title: "Manzana:", name: "Manzana", type: "text" },
    { title: "Prioridad:", name: "PrioridadLabel", type: "text" },
  ],
  seccion: [
    { title: "Seccion:", name: "seccion", type: "text" },
    { title: "DLOC:", name: "DLOC", type: "text" },
    { title: "DFED:", name: "DFED", type: "text" },
    { title: "Meta:", name: "MetaCompromisos", type: "text" },
    { title: "Av Esp:", name: "AvanceEsperado", type: "text" },
    { title: "Av Comp:", name: "AvanceCompromisos", type: "text" },
    { title: "Av Comp Únicos:", name: "AvanceCompromisosUnicos", type: "text" },
    { title: "% Comp Únicos:", name: "PorcentajeAvanceCompromisosUnicos", type: "text" },
    { title: "LNOM:", name: "Lnom", type: "text" },
  ],
  dfed: [{ title: "DFED:", name: "dfed", type: "text" }],
};

const Maps = (props) => {
  const { best, worst } = props;
  const { polygons, loadedLayer } = useContext(ReportsContext);

  const [_polygons, setPolygons] = useState(null);

  useEffect(() => {
    if (loadedLayer) {
      const _best = best.map((item) => parseInt(item.idEntidad));
      const _worst = worst.map((item) => parseInt(item.idEntidad));
      const _features = polygons?.features?.map((row) => {
        const id = parseInt(row.id);
        return {
          ...row,
          properties: {
            ...row.properties,
            line_color: setLineColor(id, _best, _worst),
            color: setColor(id, _best, _worst),
          },
        };
      });

      setPolygons(() => {
        return { ...polygons, features: _features };
      });
    }
    // eslint-disable-next-line
  }, [loadedLayer, best, worst]);

  const setColor = (id, best, worst) => {
    if (best.includes(id)) return "#1a237e";
    else if (worst.includes(id)) return "#c62828";
    return "#fff8e1";
  };

  const setLineColor = (id, best, worst) => {
    if (best.includes(id)) return "#2196f3";
    else if (worst.includes(id)) return "#c62828";
    return "#666";
  };

  const highlightFeature = (e, params) => {
    const { resetHighlight, setFeature, L, selectedFeature, setSelectedFeature } = params;
    const layer = e.target;
    const properties = layer.feature.properties;

    if (selectedFeature) resetHighlight(selectedFeature);
    if (!properties.seccion) resetHighlight(e);

    setSelectedFeature(e);
    setFeature(layer.feature);

    if (properties.seccion) {
      if (!L.Browser.ie && !L.Browser.opera && !L.Browser.edge) layer.bringToFront();
      layer.setStyle(style_original(layer.feature, true));
    }
  };

  return (
    <Map
      height="85vh"
      data={_polygons}
      highlightFeature={highlightFeature}
      centerByFeatures
      cardInfo={{ initialValues: { title: "INFORMACIÓN", elements: elements[polygons?.shp ?? "dfed"] } }}
    />
  );
};

export default Maps;
