import { useEffect, useState } from "react";

//Mui
import {
  Button,
  Card,
  CardContent,
  Icon,
  Stack,
  Typography,
} from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import Select from "react-select";
import makeAnimated from "react-select/animated";

//Components
import BasicSelect from "@components/Selects/BasicSelect";

//Services
import sije from "@services/SijeService";
import Swal from "sweetalert2";

const Filter = ({ onChangeFilter }) => {
  //Variables
  const animatedComponents = makeAnimated();
  const selectStyles = {
    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
    menu: (provided) => ({
      ...provided,
      zIndex: "9999 !important",
      boxShadow:
        "0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)",
      borderRadius: "4px",
    }),
  };

  //States
  const [catReportType, setCatReportType] = useState([]);
  const [catGroupType, setCatGroupType] = useState([]);
  const [reportType, setReportType] = useState([]);
  const [groupType, setGroupType] = useState(5);
  const [loadingCatalogs, setLoadingCatalogs] = useState(false);

  const getCatAmbitGroup = async () => {
    try {
      setLoadingCatalogs((prevState) => !prevState);
      const result = await sije.getAmbitoAgrupadorSijeAsistencia({});
      const { response, results, message } = await result;
      if (results) {
        setCatGroupType(response.data);
      } else {
        throw message;
      }
    } catch (error) {
      Swal.fire({
        title: error,
        icon: "warning",
      });
    } finally {
      setLoadingCatalogs((prevState) => !prevState);
    }
  };

  const getCatColumnsReport = async () => {
    try {
      setLoadingCatalogs((prevState) => !prevState);
      const result = await sije.getColumnasSijeAsistencia({});
      const { response, results, message } = await result;
      if (results) {
        setCatReportType(response.data);
      } else {
        throw message;
      }
    } catch (error) {
      Swal.fire({
        title: error,
        icon: "warning",
      });
    } finally {
      setLoadingCatalogs((prevState) => !prevState);
    }
  };

  const handleChangeReportType = (items) => setReportType(items);
  const handleChangeGroupType = (e) => setGroupType(e.target.value);

  const handleSearchFilter = () => {
    onChangeFilter({
      columnas: reportType.map((item) => item.value),
      idAmbitoAgrupador: groupType,
    });
  };

  const handleClearFilter = () => {
    setReportType([]);
    setGroupType(5);
    onChangeFilter({
      columnas: [],
      idAmbitoAgrupador: 5,
    });
  };

  useEffect(() => {
    getCatAmbitGroup();
    getCatColumnsReport();
  }, []);

  return (
    <Card className="card-primary">
      <CardContent>
        <Stack
          direction={"row"}
          alignItems={"center"}
          spacing={2}
          marginBottom={2}
        >
          <Icon>tune</Icon>
          <Typography variant="subtitle1" fontWeight={600}>
            Filtros
          </Typography>
        </Stack>

        <Grid2 container spacing={2}>
          <Grid2 xs={12} md={5} lg={5}>
            <BasicSelect
              name="groupType"
              label="Tipo de agrupación"
              options={catGroupType}
              value={groupType}
              onChange={handleChangeGroupType}
              isLoading={loadingCatalogs}
              sx={{ width: "100%" }}
            />
          </Grid2>
          <Grid2 xs={12} md={5} lg={5}>
            <Select
              name="reportType"
              menuPlacement="bottom"
              placeholder="Tipo de reporte"
              styles={selectStyles}
              closeMenuOnSelect={false}
              menuPortalTarget={document.body}
              components={animatedComponents}
              isMulti
              options={catReportType}
              value={reportType}
              isLoading={loadingCatalogs}
              onChange={handleChangeReportType}
            />
          </Grid2>
          <Grid2 xs={12} md={1} lg={1}>
            <Button
              sx={{ width: "100%" }}
              color="primaryDark"
              variant="contained"
              onClick={handleSearchFilter}
            >
              Filtrar
            </Button>
          </Grid2>
          <Grid2 xs={12} md={1} lg={1}>
            <Button
              sx={{ width: "100%" }}
              color="primaryDark"
              onClick={handleClearFilter}
            >
              Limpiar
            </Button>
          </Grid2>
        </Grid2>
      </CardContent>
    </Card>
  );
};

export default Filter;
