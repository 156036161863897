import Yup from "@utils/Yupi18n";

import { AMBITO_MZA, idPropietario, PERFIL_RC, PERFIL_RG, PERFIL_OBSERVER, PERFIL_ABOGADO, PERFIL_FiL, GUARDIAN_SECCION, LOGISTICA_ALIMENTOS } from "@data/constants"

const AddResponsibilitySchema = Yup.object({
  idUsurioAutorizo: Yup.number().required(),
  idCompromisoUnico: Yup.number().required(),
  idPerfil: Yup.number().required().label("Seleccione una responsabilidad"),
  idAmbitoCargo: Yup.number().required(),
  AmbitoValorCargo: Yup.number().required(),
  ResponsabilidadEtiqueta: Yup.string().required(),
  Prioridad: Yup.number().when('idAmbitoCargo', {
    is: AMBITO_MZA,
    then: Yup.number().required(),
    otherwise: Yup.number().optional().nullable()
  }),
  VigenciaINE: Yup.number().when("idVoluntario", {
    is: val => ([PERFIL_RC, PERFIL_RG, PERFIL_OBSERVER, PERFIL_FiL].includes(val)),
    then: Yup.number().required().typeError('Ingrese una Vigencia INE válida'),// .min(VIGENCIA_INE, "la Vigencia no debe ser menor al año actual")
    otherwise: Yup.number().optional().nullable(true),
  }).label('Vigencia INE'),
  idPropietario: Yup.number().when('idPerfil', {
    is: (value) => idPropietario.includes(value),
    then: Yup.number().required(),
    otherwise: Yup.number().optional().nullable()
  }),
  TieneAuto: Yup.number().when("idVoluntario", {
    is: val => ([PERFIL_RC, PERFIL_RG, PERFIL_OBSERVER, PERFIL_ABOGADO, GUARDIAN_SECCION, LOGISTICA_ALIMENTOS].includes(val)),
    then: Yup.number().required(),
    otherwise: Yup.number().optional().nullable(true),
  }).label('Tiene Auto'),
  TieneExperiencia: Yup.number().when("idVoluntario", {
    is: val => ([PERFIL_RC, PERFIL_RG, PERFIL_OBSERVER, PERFIL_ABOGADO, GUARDIAN_SECCION, LOGISTICA_ALIMENTOS].includes(val)),
    then: Yup.number().required(),
    otherwise: Yup.number().optional().nullable(true),
  }).label('Tiene Experiencia'),
  AceptaCuidarOtra: Yup.number().when("idVoluntario", {
    is: val => ([PERFIL_RC, PERFIL_OBSERVER].includes(val)),
    then: Yup.number().required(),
    otherwise: Yup.number().optional().nullable(true),
  }).label('Acepta cuidar otra casilla'),
});

export { AddResponsibilitySchema }