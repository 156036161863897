import { useEffect, useState, useRef } from "react";

// Charts
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

// Material UI
import { Card, CardContent, Box, CircularProgress } from "@mui/material";
import Swal from "sweetalert2";
import certificacion from "@services/CertificationServices";
import { numberWithCommas } from "@utils/Utilities/";

// Hooks
import useWindowDimensions from "@hooks/useWindowDimensions";

const AccumulatedCertifiedPeople = (props) => {
    const { openDrawer, refresh, data } = props;

    const title = "Personas visitadas (Acumulado)"

    const chartRef = useRef(null);

    const { height, width } = useWindowDimensions();

    const [dataCard, setDataCard] = useState({
        Avance: "",
        Meta: "",
        PorcentajeAvance: ""
    })

    const [loading, setLoading] = useState(false)

    const cardsTerritorial = async () => {
        setLoading(true)
        try {
            const res = await certificacion.getCardAdvance({ filtered: data.filtered });

            const { results, message, response } = res;
            if (results) {
                const roundedPorcentajeAvance = parseFloat(response.data.data.PorcentajeAvance).toFixed(2);

                setDataCard({
                    Avance: response.data.data.Avance,
                    Meta: response.data.data.Meta,
                    PorcentajeAvance: roundedPorcentajeAvance
                });
            } else {
                throw new Error(message);
            }
        } catch (error) {
            Swal.fire({
                title: error.message,
                icon: "warning",
            });
        } finally {
            setLoading(false)
        }
    };

    useEffect(() => {
        cardsTerritorial()
        //eslint-disable-next-line
    }, [refresh, data])

    // Opciones del chart
    const options = {
        title: {
            text: `${title.toUpperCase()}`,
            align: "right",
            style: {
                color: "#999",
                fontSize: width > 1440 ? width * 0.01 : "18px",
            },
        },
        chart: {
            type: 'solidgauge',
            height: width > 1440 ? height * 0.4 : "auto",
        },
        pane: {
            center: ['50%', '60%'],
            size: '100%',
            startAngle: -90,
            endAngle: 90,
            background: {
                backgroundColor:
                    Highcharts.defaultOptions.legend.backgroundColor || '#EEE',
                innerRadius: '60%',
                outerRadius: '100%',
                shape: 'arc'
            }
        },
        exporting: {
            enabled: false
        },
        tooltip: {
            enabled: false
        },
        yAxis: {
            min: 0,
            max: dataCard.Meta,
            stops: [
                [0.1, '#DF5353'], // red
                [0.5, '#DDDF0D'], // yellow
                [0.9, '#55BF3B'] // green
            ],
            lineWidth: 0,
            tickWidth: 0,
            minorTickInterval: null,
            tickInterval: dataCard.Meta / 1,
            title: {
                y: -70
            },
            labels: {
                y: 16,
                style: {
                    fontSize: '12px'
                },
                formatter: function () {
                    // Verificamos si es el valor mínimo
                    if (this.isFirst) {
                        return '0'; // Colocamos '0' como etiqueta para el mínimo
                    }
                    // Verificamos si es el valor máximo
                    if (this.isLast) {
                        return `<span>${numberWithCommas(dataCard.Meta)}</span>`; // Colocamos el valor de Meta como etiqueta para el máximo
                    }
                    // En otros casos, simplemente retornamos el valor original
                    return this.value;
                }
            }
        },
        plotOptions: {
            solidgauge: {
                dataLabels: {
                    y: -20,
                    borderWidth: 0,
                    useHTML: true
                }
            }
        },
        series: [{
            data: [parseInt(dataCard.Avance)],
            dataLabels: {
                format:
                    '<div style="text-align:center">' +
                    `<span style="font-size:29px";opacity:0.4">${numberWithCommas(dataCard.Avance)}</span><br/> ` +
                    `<span style="font-size:18px;opacity:0.4">${dataCard.PorcentajeAvance}%</span><br/>` +
                    '</div>'
            },
            // labels: {
            //     y: 16,
            //     style: {
            //         fontSize: width > 1400 ? width * 0.4 : '10px' // Ajusta el tamaño de la fuente según el ancho de la pantalla
            //     },
            //     formatter: function () {
            //         const fontSize = width > 1400 ? width * 0.4 : 10;
            //         return (`<span style="font-size: ${fontSize}px">${this.value}</span>
            //         <span style="font-size: ${fontSize}px">Certificados</span>
            //     `); 
            //     }
            // },
            tooltip: {
                valueSuffix: ' km/h'
            },
        }],
        credits: {
            enabled: false,
        },
    };

    return (
        <Card className="card-primary" sx={{ height: '96%' }}>
            <CardContent CardContent key={openDrawer}>
                {loading ?
                    <Box sx={{ mt: 10, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <CircularProgress />
                    </Box> :
                    <HighchartsReact
                        highcharts={Highcharts}
                        options={options}
                        ref={chartRef}
                    />
                }
            </CardContent>
        </Card>
    );
};

export default AccumulatedCertifiedPeople;

