import { Box, Fab, Icon, Tooltip } from "@mui/material";
import { blue } from "@mui/material/colors";

const RefreshButton = ({ handleRefresh }) => {
  return (
    <Box position={"fixed"} bottom={"24px"} right={"24px"} zIndex={10}>
      <Tooltip title="Actualizar">
        <Fab
          size={"small"}
          aria-label={"refresh"}
          color={"primaryDark"}
          sx={{ "&:hover": { backgroundColor: blue[900] } }}
          onClick={handleRefresh}
        >
          <Icon>sync</Icon>
        </Fab>
      </Tooltip>
    </Box>
  );
};

export default RefreshButton;
