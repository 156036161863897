import { useEffect, useState } from "react";
import Swal from "sweetalert2";

// Utilidades
import { numberWithCommas } from "@utils/Utilities/";

// Componentes
import BasicTable from "@components/Tables/BasicTable";

// Servicios
import Auditoria from "@services/AuditServices";

const TableResponsable = (props) => {
  const { change, filtered } = props;

  const [loading, setLoading] = useState(false);

  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [pages, setPage] = useState(0);
  const [orderBy, setOrderBy] = useState("");
  const [order, setOrder] = useState("");

  // Llamada a la API
  const fetchData = async (params) => {
    setLoading(true);
    try {
      const result = await Auditoria.getUserStats(params);
      const { results, message, response } = result;

      if (results) {
        setTotal(response.total);
        setData(response.data);
        setLoading(false);
      } else {
        /* Swal.fire({
          title: message,
          icon: "warning",
        }); */
        setTotal([]);
        setData([]);
      }
    } catch (errors) {
      Swal.fire({
        title: errors.message,
        icon: "warning",
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const params = {
      page: 0,
      pageSize: rowsPerPage,
      filtered: filtered.filtered,
    };

    fetchData(params);
  }, [change]);

  // Titulos mostrados en la tabla
  const titulos = [
    {
      id: 0,
      width: "40%",
      label: "Responsable".toUpperCase(),
      name: "Responsable",
    },
    {
      id: 1,
      width: "20%",
      label: "Total".toUpperCase(),
      name: "Total",
    },
    {
      id: 2,
      width: "20%",
      label: "Exitoso".toUpperCase(),
      name: "TotalExitoso",
    },
    {
      id: 3,
      width: "20%",
      label: "No exitoso".toUpperCase(),
      name: "TotalNoExitoso",
    },
  ];

  // Se estructura la tabla, y agrega comas a los miles
  const rowsRefactored = data.map((item) => [
    item.Responsable,
    numberWithCommas(item.Total),
    numberWithCommas(item.TotalExitoso),
    numberWithCommas(item.TotalNoExitoso),
  ]);

  // Paginación remota de la tabla
  const handleChangePagination = ({ page, pageSize }) => {
    setPage(page);
    setRowsPerPage(pageSize);

    const params = {
      page: page,
      pageSize: pageSize,
      filtered: filtered.filtered,
    };

    // Comprueba si esta ordenada
    if (orderBy) {
      params.sorted = [{ id: titulos[orderBy].name, value: order }];
    }

    fetchData(params);
  };

  // Ordenamiento remoto de la tabla
  const handleChangeSort = (e) => {
    setOrderBy(e.orderBy);
    setOrder(e.order);

    const params = {
      page: pages,
      pageSize: rowsPerPage,
      filtered: filtered.filtered,
      sorted: [{ id: titulos[e.orderBy].name, value: e.order }],
    };

    fetchData(params);
  };

  return (
    <BasicTable
      rows={rowsRefactored}
      hcolumns={titulos}
      total={total}
      stickyHeader={"100%"}
      pageSize={rowsPerPage}
      pageProp={pages}
      handleChangePagination={handleChangePagination}
      isLoading={loading}
      showPagination={true}
      pagination={[5, 10, 25]}
      manual={true}
      handleManualSort={handleChangeSort}
    />
  );
};

export default TableResponsable;
