import { isNullOrUndefined,isEmptyOrInvalidString } from "@utils/validations";
import { useFormik } from "formik";
import { SocialAddInterface } from "@data/interfaces/SocialInterface";
import { SocialAddSchema } from "@data/schemas/SocialSchema";
import {
  Card,
  CardContent,
  CardActions,
  Grid,
  TextField,
  CardHeader,
  Icon,
  Box,
  Input,
  Typography
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import SocialServices from "@services/SocialServices";
import CatalogServices from "@services/CatalogServices";
import Swal from "sweetalert2";
import { useEffect, useState } from "react";
import BasicTable from "@components/Tables/BasicTable";


const FormStruct = (props) => {
  const {
    setOpen,
    desing = {
      color: "#3063BA",
      titleColor: "white",
    },
    data = {
      title: "Title",
    }
  } = props;
  const [isLoading, setIsLoading] = useState(false);

  const formik = useFormik({
    initialValues: data.edit ? data.edit :SocialAddInterface,
    validationSchema: SocialAddSchema,
    onSubmit: (values) => {
      if(data.edit){
        handleEdit(values);
      }
      else{handleAdd(values);}
    },
  });
  const columns = [{
    id:"label", label:"Municipio"
  },{id:"Meta",label:"Meta"}];
  const [municipios, setMunicipios] = useState([]);
  const [inpusMunicipios,setInputsMunicipios] = useState({});
  const [avance,setAvance] = useState(0);
  const [validateMun,setValidateMun] = useState(false);
  const [loadingMun,setLoadingMun] = useState(false);
 // const [metasEdit,setMetasEdit] = useState({});

  const MunicipioData = () =>{
    if(data.edit){
      if(data.Nivel === 1){
      SocialServices.getStructMunicipios({idEstructura:formik.values.id})
      .then((res) => {
        if (res.results) {
          let inputsMun = {};
            res.response.forEach(element => {
              inputsMun[element.idMunicipio] = element.Meta;
            });
            setInputsMunicipios(inputsMun);
            setMunicipios(
              res.response.map(dato => {return {...dato,value:dato.idMunicipio,label:dato.Municipio,Meta:<Input type="number" defaultValue={inputsMun[dato.idMunicipio]} value={inpusMunicipios[dato.idMunicipio]}  onChange={(e)=>onChange(inputsMun,dato.idMunicipio,e.target.value)}></Input>}})
            );
       
        } else {
          Swal.fire({ title: res.message, icon: "warning" });
        }
      })
      .catch((error) => Swal.fire({ title: error.message, icon: "warning" }))
      .finally(()=>setLoadingMun(false));
      }
    }else{
      if(data.Nivel === 0){
      let catalogsParams = [{ id: "municipios", getAll: false }]
      CatalogServices.getCatalogs(catalogsParams)
      .then((res) => {
        if (res.results) {
          catalogsParams.forEach((item) => {
            switch (item.id) {
              case "municipios":
                let inputsMun = {};
                res.response.catalogs[item.id].forEach(element => {
                  inputsMun[element.value] = null;
                });
                setInputsMunicipios(inputsMun);
                setMunicipios(
                  res.response.catalogs[item.id].map(dato => {return {...dato,Meta:<Input type="number" defaultValue={inpusMunicipios[dato.value]} value={inpusMunicipios[dato.value]}  onChange={(e)=>onChange(inputsMun,dato.value,e.target.value)}></Input>}})
                );
                break;
              default:
            }
          });
        } else {
          Swal.fire({ title: res.message, icon: "warning" });
        }
      })
      .catch((error) => Swal.fire({ title: error.message, icon: "warning" }))
      .finally(()=>setLoadingMun(false));;
     }
    }
    
  }
 
  useEffect(()=>{
    setLoadingMun(true);
    MunicipioData();
    // eslint-disable-next-line
  },[]);


  const onChange = (inputsMun,mun,meta) => {
    let newData = inpusMunicipios;
    newData[mun] = null;
    if(!isNullOrUndefined(mun) && meta !== '' &&  parseInt(meta) > 0){
      if (meta.match(/^[0-9]*$/)){
        newData[mun] = meta;
        setAvance(Object.keys(newData).length);
        Object.keys(inputsMun).forEach(item => {
          if(newData[item]){
            // eslint-disable-next-line
            newData[item] = newData[item]
          }else{
            newData[item] = inputsMun[item]
          }
      
        })
        setInputsMunicipios(newData);
      }
    }
  };

  const handleAdd = (values) => {
    setIsLoading(true);

   if(data.Nivel === 0){
      if(municipios.length > Object.keys(inpusMunicipios).length){
        setIsLoading(false);
        setValidateMun(true);
        return false;
      }

      let validateNull = false;
      municipios.forEach(element => {
        if(isEmptyOrInvalidString(inpusMunicipios[element.value])){
          validateNull=true;
          return false;
        }
      });

      if(validateNull){
        setIsLoading(false);
        setValidateMun(true);
        return false
      }
      setValidateMun(false);
    } 

    let newMeta = {};
    Object.keys(inpusMunicipios).forEach(element => {
       if(element){
        newMeta[element] = inpusMunicipios[element];
       }
    });

    let paramsData = {
      ...values,
      NombreSocial:values.NombreSocial.toUpperCase(),
      idPadre: data.idPadre,
      isMetas: data.Nivel === 0 ? true : false,
      Metas:newMeta
    };

    SocialServices.createStruct(paramsData)
      .then((res) => {
        if (res.success && res.results) {
          formik.resetForm();
          setOpen(false);
          Swal.fire({
            title: res.message,
            icon: "success",
            confirmButtonText: "Continuar",
            cancelButtonText: "Listo",
            showCancelButton: true,
            cancelButtonColor: "#14A52E",
          }).then((isResponse) => {
            if (isResponse.isConfirmed) {
              setOpen(true);
            }
          });
        } else {
          setOpen(false);
          Swal.fire({ title: res.message, icon: "warning" });
        }
      })
      .catch((e) => {
        setOpen(false);
        Swal.fire({ title: e.message, icon: "warning" });
      })
      .finally(() => {
        setIsLoading(false);
      }); 
  };

  const handleEdit = (values) => {
  
    setIsLoading(true);
    if(data.Nivel === 0){
    

      if(municipios.length > Object.keys(inpusMunicipios).length){
        setIsLoading(false);
        setValidateMun(true);
        return false;
      }

      let validateNull = false;
      municipios.forEach(element => {
        if(isEmptyOrInvalidString(inpusMunicipios[element.value])){
          validateNull=true;
          return false;
        }
      });

      if(validateNull){
        setIsLoading(false);
        setValidateMun(true);
        return false
      }
      setValidateMun(false);

    }

    let newMeta = {};
    Object.keys(inpusMunicipios).forEach(element => {
       if(element){
        newMeta[element] = inpusMunicipios[element];
       }
    });
    
    let paramsData = {
      ...values,
      NombreSocial:values.NombreSocial.toUpperCase(),
      isMetas: data.Nivel === 1 ? true : false,
      Metas:newMeta
    };
    
    SocialServices.updateSocial(paramsData)
      .then((res) => {
        if (res.success && res.results) {
          formik.resetForm();
          setOpen(false);
          Swal.fire({
            title: res.message,
            icon: "success",
            confirmButtonText: "Listo"
          });
        } else {
          setOpen(false);
          Swal.fire({ title: res.message, icon: "warning" });
        }
      })
      .catch((e) => {
        setOpen(false);
        Swal.fire({ title: e.message, icon: "warning" });
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

 /*  const handleChangePagination = (pagination) => {
    setTableData({ ...tableData, ...pagination });
  }; */
  

  return (
    <Card sx={{ width: 445 }}>
      <CardHeader
        title={data.title}
        titleTypographyProps={{
          color: desing.titleColor,
          align: "center",
          variant: "h6",
        }}
        sx={{ bgcolor: desing.color, borderRadius: "16px", p: 0 }}
      />
      <CardContent>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <TextField
              error={
                formik.touched.NombreSocial &&
                !isEmptyOrInvalidString(formik.errors.NombreSocial)
              }
              helperText={
                formik.touched.NombreSocial &&
                formik.errors.NombreSocial &&
                formik.errors.NombreSocial
              }
              sx={{ width: "100%" }}
              required
              label="NombreSocial"
              type="text"
              name="NombreSocial"
              onChange={formik.handleChange}
              //onBlur={handleBlur}
              value={formik.values.NombreSocial.toUpperCase()}
              variant="outlined"
              size="small"
              className="fixed-input"
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <TextField
              error={
                formik.touched.Alias &&
                !isEmptyOrInvalidString(formik.errors.Alias)
              }
              helperText={
                formik.touched.Alias &&
                formik.errors.Alias &&
                formik.errors.Alias
              }
              sx={{ width: "100%" }}
              label="Alias"
              type="text"
              name="Alias"
              onChange={formik.handleChange}
              //onBlur={handleBlur}
              value={formik.values.Alias.toUpperCase()}
              variant="outlined"
              size="small"
              className="fixed-input"
            />
          </Grid>
          {
          (data.Nivel === 0 || (data.edit && data.Nivel === 1)) &&
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Box sx={{overflow:"auto", height:"300px"}}>
              {
                validateMun && <Typography variant="caption" color={'red'}>Hace falta la meta de algun municipio, verifique de nuevo</Typography>
              }
            <BasicTable
              rows={municipios}
              hcolumns={columns}
              pageProp={0}
              pageSize={10} 
              total={municipios.length}
              showPagination={false}
              handleChangePagination={()=>{}}
              isLoading={loadingMun}
              stickyHeader={true}
            />
            </Box>
          </Grid>
          }
        </Grid>
      </CardContent>
      <CardActions sx={{ display: "flex", justifyContent: "flex-end" }}>
        { data.Nivel === 0 &&  <Typography variant="caption" color={municipios.length > avance? 'red':'blue'}> {avance} de {municipios.length} </Typography>}
        <LoadingButton
          loading={isLoading}
          loadingPosition="start"
          startIcon={<Icon>save_as</Icon>}
          variant="contained"
          size="small"
          color="success"
          onClick={formik.submitForm}
        >
          {data.edit ? "Editar" : "Crear"}
        </LoadingButton>
      </CardActions>
    </Card>
  );
};
export default FormStruct;
