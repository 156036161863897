import { useState, useEffect } from "react";

import CustomTable from "@components/Tables/CustomTable";
import { setDiynamicColumns } from "@utils/Utilities";
import ContactsStack from "@components/ContactsStack/ContactsStack";

const TableDetalle = (props) => {
  const { dataTableDetalle, loadingDetalles, resPage } = props;

  const [mappedData, setMappedData] = useState([]);
  const [page, setPage] = useState(0);

  const pageSize = 50;

  const columnas = setDiynamicColumns({
    data: dataTableDetalle[0],
    columns: [
      {
        id: "Region",
        label: "Región",
        type: "text",
        align: "left",
      },
      {
        id: "idDF",
        label: "DF",
        type: "text",
        align: "left",
      },
      {
        id: "Municipio",
        label: "Municipio",
        type: "text",
        align: "left",
      },
      {
        id: "idDL",
        label: "Distrito Local",
        type: "text",
        align: "left",
      },
      {
        id: "Seccion",
        label: "Sección",
        type: "text",
        align: "left",
      },
      {
        id: "NombreCompleto",
        label: "Nombre",
        type: "text",
        align: "left",
      },
      {
        id: "Celular",
        label: "Celular",
        type: "text",
        align: "center",
      },
      {
        id: "Asignados",
        label: "Asignados",
        type: "text",
        align: "right",
      },
      {
        id: "Pendientes",
        label: "Pendientes",
        type: "text",
        align: "right",
      },
      {
        id: "Movilizados",
        label: "Movilizados",
        type: "text",
        align: "right",
      },
      {
        id: "NoLocalizados",
        label: "No Localizados",
        type: "text",
        align: "center",
      },
    ],
  });

  useEffect(() => {
    const mapData = () => {
      return dataTableDetalle.map((item) => ({
        ...item,
        Celular: (
          <ContactsStack
            whatsapp={item.Celular}
            nombre={item.NombreCompleto}
          />
        ),
      }));
    };
    if (dataTableDetalle.length > 0) {
      setMappedData(mapData());
    }
  }, [dataTableDetalle]);

  useEffect(() => {
    setPage(0);
  }, [resPage]);
  
  return (
    <CustomTable
      rows={mappedData}
      columns={columnas}
      total={dataTableDetalle.length}
      page={page}
      pageSize={pageSize}
      isLoading={loadingDetalles}
      stickyHeader={true}
      maxHeight={600}
      disableCardType
    />
  );
};

export default TableDetalle;
