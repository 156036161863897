//MUI
import {
  Box,
  CircularProgress,
  Icon,
  IconButton,
  List,
  ListItem,
  Stack,
  Typography,
} from "@mui/material";

//Components
import Modal from "@components/Modal/Dataview";
import ListRepresentatives from "./ListRepresentatives";

const ModalDetailsSection = (props) => {
  const { loading, openModal, setOpenModal, data = [] } = props;

  return (
    <Modal
      open={openModal}
      setOpen={setOpenModal}
      hasButtonClose={false}
      maxWidth={"sm"}
      borderRadius={12}
    >
      <Box>
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Typography variant="h6">Información de la sección</Typography>
          {!loading && (
            <IconButton
              size="small"
              sx={{
                boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
              }}
              onClick={() => setOpenModal(false)}
            >
              <Icon>close</Icon>
            </IconButton>
          )}
        </Stack>

        {loading ? (
          <Box marginTop={4} marginBottom={4}>
            <CircularProgress sx={{ margin: "0 auto", display: "flex" }} />
          </Box>
        ) : (
          <Box marginTop={2}>
            <Stack flexDirection={"column"}>
              <Stack flexDirection={"column"}>
                <List sx={{ mb: 2 }}>
                  <ListItem divider sx={{ justifyContent: "space-between" }}>
                    <Typography variant="subtitle2">RG</Typography>
                    <Typography variant="body2" sx={{ width: "50%" }}>
                      {data[0]?.NombreRG} <br />
                      {data[0]?.CelularRG}
                    </Typography>
                  </ListItem>

                  <ListItem divider sx={{ justifyContent: "space-between" }}>
                    <Typography variant="subtitle2">Ruta</Typography>
                    <Typography variant="body2" sx={{ width: "50%" }}>
                      {data[0]?.NombreRuta}
                    </Typography>
                  </ListItem>

                  <ListItem divider sx={{ justifyContent: "space-between" }}>
                    <Typography variant="subtitle2">Sección</Typography>
                    <Typography variant="body2" sx={{ width: "50%" }}>
                      {data[0]?.Seccion}
                    </Typography>
                  </ListItem>
                </List>
              </Stack>

              <Stack flexDirection={"column"}>
                <Typography variant="h6">Casillas</Typography>
                <Box marginTop={1} sx={{ height: "50vh", overflowY: "scroll" }}>
                  {data?.map((item, idx) => (
                    <Box key={idx}>
                      <Typography variant="subtitle2">
                        {item.NombreCasilla}
                      </Typography>
                      <ListRepresentatives representative={item} />
                    </Box>
                  ))}
                </Box>
              </Stack>
            </Stack>
          </Box>
        )}
      </Box>
    </Modal>
  );
};

export default ModalDetailsSection;
