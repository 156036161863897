import { useState, useEffect, useCallback } from "react";
import Swal from "sweetalert2";

// Componentes
import AdminLayout from "@components/MainPage/AdminLayout";
import Map from "@components/Maps/CustomComponents/GenericMap";
import Filter from "@components/MapsModules/Cartography/Filter";
import { onEachFeature, highlightFeature, cardComponent } from "@components/MapsModules/Cartography/GlobalFunctions";

// Services
import CatalogService from "@services/CatalogServices";
import CartographyService from "@services/MapServices/CartographyServices";

// Utils
import { setVars, getVars } from "@utils/global";

// Estilos de mapas
import { style_original_data_cartography, style_original_sub_data_cartography } from "@components/Maps/Auxiliars/ColorFeature";

const elements = {
  manzana: [
    { title: "Manzana: ", name: "Manzana", type: "text" },
    { title: "Prioridad: ", name: "PrioridadLabel", type: "text" },
  ],
  poligono: [{ title: "Polígono: ", name: "poligono", type: "text" }],
  municipio_reportes: [{ title: "Municipio: ", name: "municipio_reportes", type: "text" }],
  dfed: [{ title: "DFED: ", name: "dfed", type: "text" }],
};

const Maps = () => {
  const { user } = getVars("Token");

  const [polygons, setPolygons] = useState({ type: "FeatureCollection", features: [] });
  const [polygonsBlocks, setPolygonsBlocks] = useState(null);

  const [isLoadingCatalogs, setIsLoadingCatalogs] = useState(true);
  const [catalogs, setCatalogs] = useState({ regiones: [], municipios: [] });
  const [, /* shpInfoGeneral */ setShpInfoGeneral] = useState("Region");

  const getEmptyMap = () => setPolygons({ type: "FeatureCollection", features: [] });

  const handleFilterExect = (_filter) => {
    setPolygons(null);
    setPolygonsBlocks(null);
    CartographyService.getSections({ color: 0, page: 0, pageSize: 10, filtered: _filter, idEntidad: user.idEstado })
      .then((res) => {
        if (res.results) setPolygonsBlocks(res.response.data);
        else Swal.fire({ title: res.message, icon: "warning" });
      })
      .catch((e) => {
        Swal.fire({ title: e.message, icon: "warning" });
      });

    let _filterModified = _filter.map((item) => {
      const regex = /cat_secciones/gi;
      let itemModified = item;
      itemModified.id = item.id.replace(regex, "tb");
      return itemModified;
    });

    let lastElement = _filterModified.slice(-1);
    _filterModified = lastElement;
    setShpInfoGeneral("Entidad");
    if (lastElement.length !== 0) {
      switch (_filterModified[0].id) {
        case "tb.idMunicipioReportes":
          //shp = 'municipio_reportes';
          setShpInfoGeneral("Municipio");
          break;
        case "tb.idPoligono":
          //shp = 'poligono';
          setShpInfoGeneral("Poligono");
          break;
        case "tb.idRegion":
        default:
          //shp = 'region';
          setShpInfoGeneral("Entidad");
      }
    }

    CartographyService.getShapes({ color: 0, page: 0, pageSize: 10, filtered: _filterModified, shp: "municipio_reportes", idEntidad: user.idEstado })
      .then((res) => {
        if (res.results) setPolygons(res.response.data);
        else Swal.fire({ title: res.message, icon: "warning" });
      })
      .catch((e) => {
        Swal.fire({ title: e.message, icon: "warning" });
      });
  };

  const loadCatalogs = useCallback(() => {
    let catalogsParams = [
      { id: "regiones", getAll: false },
      { id: "municipios_dl", getAll: false },
    ];
    CatalogService.getCatalogs(catalogsParams)
      .then((res) => {
        if (res.results) {
          if (res.response.errors.length > 0) {
            Swal.fire({
              title: "Algunos catálogos NO pudieron ser cargados. Contacte al administrador",
              icon: "warning",
            });
          }

          const catalogsResponse = res.response.catalogs;
          setCatalogs((prevState) => {
            return {
              ...prevState,
              municipios: catalogsResponse.municipios_dl ? [{ value: 0, label: "TODOS" }].concat(catalogsResponse.municipios_dl) : [],
              regiones: catalogsResponse.regiones ? [{ value: 0, label: "TODAS" }].concat(catalogsResponse.regiones) : [],
            };
          });
        } else Swal.fire({ title: res.message, icon: "warning" });
      })
      .catch((e) => {
        Swal.fire({ title: e.message, icon: "warning" });
      })
      .finally(() => {
        setIsLoadingCatalogs(false);
      });
  }, []);

  useEffect(() => {
    setVars("filterSavedMap", {
      idRegion: "",
      idMunicipioReportes: "",
      idPoligono: "",
      Seccion: "",
    });
    getEmptyMap();
    loadCatalogs();
    // eslint-disable-next-line
  }, []);

  const FilterComponent = (
    <Filter
      catalogs={catalogs}
      loadingCatalogs={isLoadingCatalogs}
      handleFilter={handleFilterExect}
      region={1}
      municipio={1}
      poligono={0}
      seccion={0}
    />
  );

  const handleDownloadFile = (e) => {
    CartographyService.downloadShapeCartography({})
      .then((res) => {
        if (res.success) Swal.fire({ title: "¡Descarga exitosa!", icon: "success" });
        else Swal.fire({ title: res.error.message, icon: "warning" });
      })
      .catch((e) => {
        Swal.fire({ title: e.message, icon: "warning" });
      });
  };

  const type_map = elements[polygons?.shp ?? "dfed"] ? elements[polygons?.shp ?? "dfed"][0].name : "seccion";

  return (
    <AdminLayout delPadding>
      <Map
        zoom={5}
        position={[23, -102]}
        data={polygons}
        subData={polygonsBlocks}
        drawerLeft={{ open: true, component: FilterComponent, width: { sm: 300, md: 350, lg: 400 } }}
        cardInfo={{
          initialValues: { title: "INFORMACIÓN", elements: elements[polygons?.shp ?? "dfed"] },
          button: { title: "Descargar PDF", handleClick: handleDownloadFile, validShp: ["seccion"] },
          component: { validShp: ["entidad"], component: cardComponent },
        }}
        highlightFeature={highlightFeature}
        onEachFeature={(a, b, c, d) => onEachFeature(a, b, c, d, type_map)}
        centerByFeatures
        styleProp={{
          data: style_original_data_cartography,
          subData: style_original_sub_data_cartography,
          onEachFeature: style_original_data_cartography,
          highlightFeature: style_original_data_cartography,
        }}
      />
    </AdminLayout>
  );
};

export default Maps;
