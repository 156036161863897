import React, { useEffect, useState } from "react";
import { Box, Card, CardContent, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, CircularProgress } from "@mui/material";
import Swal from "sweetalert2";
import certificacion from "@services/CertificationServices";
import ProgressPercent from "@components/LinearProgress/ProgressPercent";
import { getColorReverse } from "@utils/Utilities";
// import BasicTable from "@components/Tables/BasicTable";
import { numberWithCommas } from "@utils/Utilities/";

const TablePendingCertification = ({ data }) => {
  const [loading, setLoading] = useState(false);
  const [dataTable, setDataTable] = useState([]);

  // const titulos = [
  //   { id: "Label", label: "ESTATUS", align: 'center' },
  //   { id: "Avance", label: "AVANCE", align: 'center' },
  //   { id: "Meta", label: "META", align: "center" },
  //   { id: "PorcentajeAvance", label: "PORCENTAJE AVANCE", align: 'center' },
  // ];

  const getPaseListaTable = async (params) => {
    try {
      setLoading(true);
      const { results, response, message, success } = await certificacion.getPendingAdvance(params);
      if (!results && !success) throw new Error(message);
      if (results && success) {
        const colorIndexes = [0,1,2,3,5,6,7]

        setDataTable(response.data.map((item, index) => ({
          ...item,
          PorcentajeAvance: (
            <ProgressPercent
              type="linear"
              fixed={2}
              value={parseFloat(item.PorcentajeAvance)}
              customColors={colorIndexes.includes(index) ? getColorReverse : null}
            />
          ),
          Avance: numberWithCommas(item.Avance),
          Meta: numberWithCommas(item.Meta),
        })))
      } else {
        setDataTable([]);
      }
    } catch (error) {
      Swal.fire({ title: error.message, icon: "warning" });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getPaseListaTable({
      filtered: data.filtered,
    });
  }, [data]);

  return (
    <Card className="card-primary" sx={{ height: "95%" }}>
      <CardContent sx={{ height: "100%" }}>
        <Typography variant="body2" fontWeight={600} marginBottom={2}>
          PENDIENTES DE CERTIFICAR
        </Typography>
          <TableContainer component={Paper} elevation={0}>
            <Table>
              <TableHead sx={{ backgroundColor: 'rgb(189, 189, 189)' }}>
                <TableRow>
                  <TableCell align='center'>ESTATUS</TableCell>
                  <TableCell align='center'>META</TableCell>
                  <TableCell align='center'>AVANCE</TableCell>
                  <TableCell align='center'>PORCENTAJE AVANCE</TableCell>
                </TableRow>
              </TableHead>
              {loading ? (
                <Box display="flex" justifyContent="center">
                  <CircularProgress />
                </Box>
              ) : (
                <TableBody>
                  {dataTable.map((row, index) => (
                    <TableRow
                      key={index}
                      sx={{
                        backgroundColor: index % 2 === 0 ? "#ffffff" : "rgb(227, 236, 250)",
                      }}
                    >
                      <TableCell align="center">{row.Label}</TableCell>
                      <TableCell align="center">{row.Meta}</TableCell>
                      <TableCell align="center">{row.Avance}</TableCell>
                      <TableCell>
                      {row.PorcentajeAvance}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              )}
            </Table>

            {/* <BasicTable
              rows={dataTable}
              hcolumns={titulos}
              stickyHeader={true}
              isLoading={loading}
              showPagination={false}
              cardType={false}
              isAction={false}
              handleManualSort={false}
            /> */}
          </TableContainer>
      </CardContent>
    </Card>
  );
};

export default TablePendingCertification;
