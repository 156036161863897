import { useEffect, useState, cloneElement, useCallback } from "react";
import { URL_PUBLICA } from "@utils/global";
import { useParams } from "react-router-dom";
import { useFormik } from "formik";
import { useCatalogsPublic } from "@hooks/useCatalogsPublic";
import { CompromisoEventSchema } from "@data/schemas/CompromisoSchemas";
import events from "../../services/EventsServices";

import {
  Container,
  Grid,
  Box,
  Typography,
  Card,
  AppBar,
  Toolbar,
  Button,
  Stack,
  Skeleton,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import SearchPeople from "../../components/Events/SearchPeople";
import Swal from "sweetalert2";
import eventosImg from "./../../assets/img/eventosImg.jpg";

import EventQuestions from "../../components/Events/EventQuestions";
import { isNullOrUndefined, isEmptyOrNullObject } from "@utils/validations";
import parse from "html-react-parser";
import QRPreregistro from "../../components/Events/QRPreregistro";
import FormRegister from "../../components/Events/FormRegister";
import useScrollTrigger from "@mui/material/useScrollTrigger";
import CssBaseline from "@mui/material/CssBaseline";
import HomeIcon from "@mui/icons-material/Home";
import AppRegistrationIcon from "@mui/icons-material/AppRegistration";
import { validaINE, handleAttrs, validaCURP } from "@utils/Utilities";
import {
  GoogleReCaptchaProvider,
  useGoogleReCaptcha,
} from "react-google-recaptcha-v3";
import { RECAPTCHA_SITE_KEY } from "@utils/global";
import { CompromisoEventInterface } from "@data/interfaces/EventosInterfaces";
import moment from "moment";

function ElevationScroll(props) {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window ? window() : undefined,
  });

  return cloneElement(children, {
    elevation: trigger ? 4 : 0,
  });
}

const PreRegistro = (props) => {
  const catalogsParams = [
    { id: "generos" },
    { id: "entidades" },
    { id: "municipios" },
    { id: "tipo_vialidad" },
    { id: "medios_contacto" },
  ];
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [flagNot, setFlagNot] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [dataEvent, setDataEvent] = useState({});
  const [dataPreguntas, setDataPreguntas] = useState({});
  const [flagQueestion, setFlagQueestion] = useState(false);
  const [loadingRegister, setLoadingRegister] = useState(false);
  const [datauser, setDataUser] = useState({});
  const [dataQR, setDataQR] = useState({});
  const [flagQR, setFlagQR] = useState(false);
  const [mensaje, setMensaje] = useState("");
  const [LoadingForm, setLoadingForm] = useState(false);
  const [loadingImage, setLoadingImage] = useState(true);
  let image = new Image();
  const [dominantColor, setDominantColor] = useState("#00000");
  const { catalogs, load } = useCatalogsPublic({
    catalogsOptions: catalogsParams,
    putDefaultOption: false,
  });
  const { executeRecaptcha } = useGoogleReCaptcha();

  const formik = useFormik({
    initialValues: CompromisoEventInterface,
    validationSchema: CompromisoEventSchema,
    onSubmit: (values) => {
      onChangeCaptcha(handleAttrs(values));
    },
  });

  useEffect(() => {
    getEventos();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const userAgent = navigator.userAgent;
    const mobileRegex = /Mobi/i;
    const isMobileDevice = mobileRegex.test(userAgent);
    setIsMobile(isMobileDevice);

    const imageUrl = isMobileDevice
      ? dataEvent.imgMovilURL
        ? dataEvent.imgMovilURL
        : eventosImg
      : dataEvent.imgURL
      ? dataEvent.imgURL
      : eventosImg;

    const image = new Image();
    image.crossOrigin = "anonymous";
    image.src = imageUrl;

    image.onload = () => {
      const canvas = document.createElement("canvas");
      canvas.width = image.width;
      canvas.height = image.height;
      const context = canvas.getContext("2d");
      context.drawImage(image, 0, 0);

      const imageData = context.getImageData(
        0,
        0,
        canvas.width,
        canvas.height
      ).data;
      let totalR = 0;
      let totalG = 0;
      let totalB = 0;
      const totalPixels = imageData.length / 4;

      for (let i = 0; i < imageData.length; i += 4) {
        totalR += imageData[i];
        totalG += imageData[i + 1];
        totalB += imageData[i + 2];
      }

      const avgR = Math.floor(totalR / totalPixels);
      const avgG = Math.floor(totalG / totalPixels);
      const avgB = Math.floor(totalB / totalPixels);

      const rgbToHex = (r, g, b) => {
        const componentToHex = (c) => {
          const hex = c.toString(16);
          return hex.length === 1 ? "0" + hex : hex;
        };
        return `#${componentToHex(r)}${componentToHex(g)}${componentToHex(b)}`;
      };

      const hexColor = rgbToHex(avgR, avgG, avgB);
      setDominantColor(hexColor);
    };
  }, [dataEvent, navigator.userAgent]);

  useEffect(() => {
    // eslint-disable-next-line
  }, [dominantColor]);

  const getEventos = () => {
    events
      .getEvents({ uuid: id })
      .then((res) => {
        if (res.results) {
          formik.setFieldValue(
            "idUsuarioPropietario",
            res.response.data.idUsuarioCreo,
            false
          );
          formik.setFieldValue("idEvento", res.response.data.id, false);

          if (parseInt(res.response.data.idModoEvento) === 3) {
            formik.setFieldValue("Password", "C01202**", false);
            formik.setFieldValue("Confirm", "C01202**", false);
            formik.setFieldValue("PIN", "12345", false);
          }

          setDataPreguntas(res.response.preguntas);
          setDataEvent({
            ...res.response.data,
            imgURL: res.response.data.imgURL
              ? `${URL_PUBLICA}${res.response.data.imgURL}`
              : res.response.data.imgURL,
            imgMovilURL: res.response.data.imgMovilURL
              ? `${URL_PUBLICA}${res.response.data.imgMovilURL}`
              : res.response.data.imgMovilURL,
          });
        } else {
          Swal.fire({
            title: res.message,
            icon: "warning",
            showConfirmButton: false,
            allowOutsideClick: false,
            allowEscapeKey: false,
          });
        }
      })
      .catch((error) =>
        Swal.fire({
          title: error.message,
          icon: "warning",
          showConfirmButton: false,
          allowOutsideClick: false,
          allowEscapeKey: false,
        })
      )
      .finally(() => setLoading(false));
  };

  const rgbToHex = (r, g, b) => {
    const componentToHex = (c) => {
      const hex = c.toString(16);
      return hex.length === 1 ? "0" + hex : hex;
    };
    return `#${componentToHex(r)}${componentToHex(g)}${componentToHex(b)}`;
  };

  const RegisterEvent = () => {
    if (!datauser.id) {
      Swal.fire({ title: "Hace Falta, su clave elector", icon: "warning" });
      return true;
    }
    if (
      dataEvent.idModoEvento === 3 &&
      dataPreguntas.length !== 0 &&
      formik.values.preguntas_respuestas.length === 0 &&
      datauser.pedir_cuestionario
    ) {
      Swal.fire({
        title: "Hace Falta, contestar alguna pregunta del formulario.",
        icon: "warning",
      });
      return true;
    }
    if (loadingRegister) {
      return false;
    }

    setLoadingRegister(true);
    events
      .registerEvent({
        idUsuarioInvitado: datauser.id,
        idEvento: dataEvent.id,
        preguntas_respuestas: formik.values.preguntas_respuestas,
      })
      .then((res) => {
        if (res.results) {
          setFlagQR(true);
          setMensaje(res.message);
          setDataQR(res.response.data);
        } else {
          Swal.fire({ title: res.message, icon: "warning" });
        }
      })
      .catch((error) => Swal.fire({ title: error.message, icon: "warning" }))
      .finally(() => {
        setLoadingRegister(false);
        setFlagQueestion(false);
      });
  };

  const SearchEvent = () => {
    if (!datauser.id) {
      Swal.fire({ title: "Hace Falta, su clave elector", icon: "warning" });
      return true;
    }

    if (loadingRegister) {
      return false;
    }

    setLoadingRegister(true);
    events
      .registerEvent({
        idUsuarioInvitado: datauser.id,
        idEvento: dataEvent.id,
        preguntas_respuestas: formik.values.preguntas_respuestas,
      })
      .then((res) => {
        if (res.results) {
          setFlagQR(true);
          setMensaje(res.message);
          setDataQR(res.response.data);
        } else {
          Swal.fire({ title: res.message, icon: "warning" });
        }
      })
      .catch((error) => Swal.fire({ title: error.message, icon: "warning" }))
      .finally(() => {
        setLoadingRegister(false);
        setFlagQueestion(false);
      });
  };

  const onChangeCaptcha = useCallback(
    (values) => {
      if (!executeRecaptcha) {
        return;
      }

      executeRecaptcha("eventos")
        .then((token) => {
          const data = {
            ...values,
            token: token,
          };
          handleVerification(handleAttrs(data));
        })
        .catch((error) => Swal.fire({ title: error, icon: "warning" }));
    },
    // eslint-disable-next-line
    [executeRecaptcha]
  );

  const handleVerification = (values) => {
    if (!isEmptyOrNullObject(values)) {
      let res = { results: false, message: "Datos no validados" };
      if (values.isMenor) {
        res = validaCURP(
          values.CURP,
          values.Nombre,
          values.Paterno,
          values.Materno
        );
      } else {
        res = validaINE(
          values.INE,
          values.Nombre,
          values.Paterno,
          values.Materno,
          values.idSexo,
          values.FechaNacimiento,
          values.idEstadoNacimiento
        );
      }

      if (!res.results) {
        Swal.fire({
          title: `¡Atención!`,
          text: res.message,
          icon: "info",
          reverseButtons: true,
          showCancelButton: false,
          confirmButtonText: "Ok",
        });
      } else {
        handleSave(values);
      }
    }
  };

  const handleSave = (values) => {
    if (LoadingForm) {
      return false;
    }

    setLoadingForm(true);
    let data = {};

    Object.keys(CompromisoEventInterface).forEach((key) => {
      data[key] =
        values[key] !== null ? values[key] : CompromisoEventInterface[key];
    });

    data = {
      ...data,
      VigenciaINE: values.VigenciaINE ? parseInt(values.VigenciaINE) : null,
      INE: values.INE ? values.INE.toUpperCase() : "",
      CURP: values.CURP ? values.CURP.toUpperCase() : "",
      SeccionVota: data.isMenor === 1 ? null : data.SeccionVota,
      Nombre: values.Nombre.toUpperCase(),
      Paterno: values.Paterno.toUpperCase(),
      Materno: values.Materno.toUpperCase(),
      EsMenor: values.isMenor ? 1 : 0,
      FechaNacimiento: moment(values.FechaNacimiento).format("YYYY-MM-DD"),
      CalleVive: `${values.TipoVialidad} ${values.CalleVive}`,
    };

    delete data.TipoVialidad;
    delete data.isUsuario;
    data = {
      ...data,
      Username: values.Celular,
      medioConfirmacion: "sms",
      idEstructuraSocial: 1,
    };
    delete data.isMenor;
    delete data.idMedioContacto;
    delete data.Segmentos;
    delete data.TieneAuto;
    delete data.TieneExperiencia;
    delete data.AceptaCuidarOtra;

    if (
      dataEvent.idModoEvento === 3 &&
      dataPreguntas.length !== 0 &&
      data.preguntas_respuestas.length === 0
    ) {
      Swal.fire({
        title: "Hace Falta, contestar una pregunta del formulario.",
        icon: "warning",
      });
      return true;
    }

    events
      .createUser(data)
      .then((res) => {
        if (res.success && res.results) {
          setFlagQueestion(false);
          setFlagQR(true);
          setMensaje(res.message);
          setDataQR(res.response.data);
          /*   Swal.fire({
            title: res.message,
            icon: "success",
            input: "text",
            inputAttributes: {
              autocapitalize: "off",
            },
            showCancelButton: true,
            confirmButtonText: "Confirmar",
            showLoaderOnConfirm: true,
            preConfirm: (code) => {
              if (code === res.response.codigo) {
                UserServices.codeValidate({
                  code: code,
                  c: res.response.UUID,
                })
                  .then((respuesta) => {
                    if (respuesta.success) {
                      setFlagNot(false);
                      setFlagAccount(true);
                      setDataUser({
                        NombreCompleto: `${respuesta.response.Nombre} ${respuesta.response.Paterno}`,
                        id: respuesta.response.id,
                      });
                    } else {
                      Swal.showValidationMessage(
                        `Error en la validación. contacte al administrador`
                      );
                    }
                  })
                  .catch((e) => {
                    Swal.showValidationMessage(
                      `Error en la validación. contacte al administrador.`
                    );
                  });
              } else {
                Swal.showValidationMessage(`El codígo no coincide`);
              }
            },
            allowOutsideClick: () => !Swal.isLoading(),
          }); */
          clearForm();
        } else {
          Swal.fire({ title: res.message, icon: "warning" });
        }
      })
      .catch((e) => {
        Swal.fire({ title: e.message, icon: "warning" });
      })
      .finally(() => {
        setLoadingForm(false);
      });
  };

  const clear = () => {
    setFlagNot(false);
    setFlagQR(false);
    setDataUser({ message: "" });
    setDataQR({});
    setFlagQueestion(false);

    formik.resetForm({});
    formik.setFieldValue(
      "idUsuarioPropietario",
      dataEvent.idUsuarioCreo,
      false
    );
    formik.setFieldValue("idMedioContacto", 2, false);
    formik.setFieldValue("idEvento", dataEvent.id, false);
  };

  const clearForm = () => {
    formik.resetForm({});
    formik.setFieldValue(
      "idUsuarioPropietario",
      dataEvent.idUsuarioCreo,
      false
    );
    formik.setFieldValue("idEvento", dataEvent.id, false);
  };

  return (
    <>
      <CssBaseline />
      <ElevationScroll {...props}>
        <AppBar position="fixed" sx={{ backgroundColor: dominantColor }}>
          <Toolbar>
            {!flagNot && !flagQR && (
              <Typography
                variant="h6"
                noWrap
                component="div"
                sx={{ flexGrow: 1 }}
              >
                {dataEvent ? dataEvent.Actividad : "Eventos"}
              </Typography>
            )}
            {(flagNot || flagQR) && (
              <>
                <Button
                  variant="outlined"
                  onClick={() => {
                    clear();
                  }}
                  sx={{ border: 0, p: 0 }}
                  size="large"
                  edge="start"
                  color="inherit"
                  aria-label="menu"
                  startIcon={<HomeIcon />}
                >
                  Inicio
                </Button>
              </>
            )}
          </Toolbar>
        </AppBar>
      </ElevationScroll>
      {loading ? (
        <Grid container>
          <Grid item xs={12}>
            <Skeleton
              animation="wave"
              sx={{ bgcolor: "aliceblue" }}
              variant="rectangular"
              width={"100%"}
              height={1000}
            />
          </Grid>
        </Grid>
      ) : (
        <>
          <Box
            sx={{
              minHeight: "60vh",
              width: "100%",
              backgroundImage:
                Object.entries(dataEvent).length !== 0 &&
                isNullOrUndefined(isMobile ? dataEvent : dataEvent.imgURL)
                  ? `url(${eventosImg})`
                  : isMobile
                  ? `url(${dataEvent.imgMovilURL})`
                  : `url(${dataEvent.imgURL})`,
              backgroundSize: "100%",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
              display: "grid",
              placeItems: "center",
            }}
          >
            <Box
              sx={{
                position: "absolute",
                top: 0,
                left: 0,
                minHeight: "75vh",
                width: "100%",
                backgroundColor:
                  Object.entries(dataEvent).length !== 0 &&
                  isNullOrUndefined(
                    isMobile ? dataEvent.imgMovilURL : dataEvent.imgURL
                  )
                    ? "rgba(255, 255, 255, 0.5)"
                    : "rgba(0, 0, 0, 0)", // Color oscuro con una opacidad de 0.5
                zIndex: 1,
              }}
            />
            <Container sx={{ zIndex: 2 }}>
              <Grid
                container
                item
                xs={12}
                lg={8}
                justifyContent="center"
                alignItems="center"
                flexDirection="column"
                sx={{ mx: "auto", textAlign: "center" }}
              >
                <>
                  {Object.entries(dataEvent).length !== 0 &&
                    isNullOrUndefined(dataEvent.imgURL) && (
                      <>{parse(dataEvent.textoPreregistro)}</>
                    )}
                </>
              </Grid>
            </Container>
          </Box>
          <Card
            sx={{
              position: "relative",
              zIndex: 3,
              p: 2,
              mx: { xs: 2, lg: 3 },
              mt: -8,
              mb: 4,
            }}
          >
            {!flagNot && !flagQR && !flagQueestion && (
              <SearchPeople
                dataEvent={dataEvent}
                datauser={datauser}
                dataPreguntas={dataPreguntas}
                setFlagNot={(e) => setFlagNot(e)}
                setFlagQueestion={(e) => setFlagQueestion(e)}
                setDataUser={(e) => setDataUser(e)}
                errors={formik.errors}
                touched={formik.touched}
                values={formik.values}
                setValues={formik.setValues}
                handleChange={formik.handleChange}
                catalogsFiltered={catalogs}
                register={(e) => {
                  if (dataEvent.puedeRegistrarse === 1) {
                    RegisterEvent(e);
                  }else{
                    SearchEvent(e)
                  }
                }}
                loadingRegister={loadingRegister}
              />
            )}
            {flagNot && !flagQR ? (
              <FormRegister
                catalogsFiltered={catalogs}
                dataEvent={dataEvent}
                errors={formik.errors}
                touched={formik.touched}
                values={formik.values}
                setValues={formik.setValues}
                handleChange={formik.handleChange}
                load={load}
              />
            ) : (
              <></>
            )}
            {flagQueestion ? (
              <EventQuestions
                dataEvent={dataEvent}
                datauser={datauser}
                dataPreguntas={dataPreguntas}
                handleChange={formik.handleChange}
                flagNot={flagNot}
                loading={loadingRegister}
                RegisterEvent={() => RegisterEvent()}
              />
            ) : (
              <></>
            )}
            {flagQR && <QRPreregistro dataQR={dataQR} mensaje={mensaje} />}
            {!flagNot && flagQueestion ? (
              <Grid item xs={12} sm={12} md={12}>
                <br />
                <Stack
                  direction="row"
                  justifyContent="flex-end"
                  alignItems="center"
                  spacing={2}
                >
                  <LoadingButton
                    size="small"
                    color="primary"
                    onClick={(e) => RegisterEvent()}
                    loading={loadingRegister}
                    loadingPosition="end"
                    endIcon={<AppRegistrationIcon />}
                    variant="contained"
                  >
                    <span>Registrarme</span>
                  </LoadingButton>
                </Stack>
              </Grid>
            ) : (
              <></>
            )}
            {flagNot && !flagQR ? (
              <Grid item xs={12} sm={12} md={12}>
                <br />
                <Stack
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  spacing={4}
                >
                  <LoadingButton
                    size="small"
                    color="primary"
                    onClick={formik.submitForm}
                    loading={LoadingForm}
                    loadingPosition="end"
                    endIcon={<AppRegistrationIcon />}
                    variant="contained"
                  >
                    <span>Registrarme</span>
                  </LoadingButton>
                </Stack>
              </Grid>
            ) : (
              <></>
            )}
          </Card>
        </>
      )}
    </>
  );
};

const recaptcha = () => {
  return (
    <GoogleReCaptchaProvider reCaptchaKey={RECAPTCHA_SITE_KEY}>
      <PreRegistro />
    </GoogleReCaptchaProvider>
  );
};
export default recaptcha;
