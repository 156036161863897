import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import {
  Box,
  Chip,
  Icon,
  TextField,
  InputAdornment,
  Divider,
  FormControlLabel,
  IconButton,
  Checkbox,
  Typography,
} from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2";
import { isEmptyOrInvalidString } from "@utils/validations";

import ModalResponsibleList from "./ModalResponsibleListFrente";
import { getVars } from "@utils/global";
import middleware from "@middlewares/middleware";
import { isEmptyOrNullObject } from "@utils/validations";
import FrenteAmplioServices from "@services/FrenteAmplioServices";

const ResponsibleListFrente = (props) => {
  const { errors, touched, values, handleChange, setValues, finished } = props;

  const { user } = getVars("Token");
  const location = useLocation();
  const [openModal1, setOpenModal1] = useState(false);
  const [responsible, setResponsible] = useState(() => {
    return {
      value: user.id,
      label: `${user.Nombre} ${user.Paterno} ${user.Materno}`,
      TieneUsuario: 1,
    };
  });


  const getFindRecord = location.state ? location.state : null;

  useEffect(() => {
    // eslint-disable-next-line
  }, [finished]);

  useEffect(() => {
    if (!isEmptyOrNullObject(getFindRecord)) {
      if (getFindRecord.idUsuarioPropietario !== responsible.idUsuario) {
        const filtered = [
          {
            id: "usuarios.id",
            filter: "=",
            value: getFindRecord.idUsuarioPropietario,
          },
        ];
        FrenteAmplioServices.getResponsibles({ page: 0, pageSize: 10, filtered }).then(
          (res) => {
            if (res.results) {
              let data = res.response.data[0];
              if (data.length > 0) {
                setResponsible(data);
              } else {
                setResponsible(data);
              }
            }
          }
        );
      }
    }
    // eslint-disable-next-line
  }, [getFindRecord]);

  useEffect(() => {
    if (!isEmptyOrNullObject(responsible)) {
   
      if (responsible.TieneUsuario) {
        setValues({
          ...values,
          idUsuarioPropietario: responsible.idUsuario,
          idUsuarioResponsable: null,
          idCompromisoPropietario: null,
        });
      } else {
        setValues({
          ...values,
          idUsuarioPropietario: null,
          idCompromisoPropietario: responsible.value,
          idUsuarioResponsable: values.idUsuarioResponsable
            ? values.idUsuarioResponsable
            : user.id,
        });
      }
    }
    // eslint-disable-next-line
  }, [responsible]);

  const onPublic = (e) => {
    let check = e.target.checked;

    handleChange({
      target: {
        name: "flagOtro",
        value: false,
      },
    });

    handleChange({
      target: {
        name: "InvitadoPor",
        value: null,
      },
    });

    handleChange({
      target: {
        name: "PublicoGeneral",
        value: check ? 1 : 0,
      },
    });
  };

  const onOtro = (e) => {
    let check = e.target.checked;

    if (!check) {
      handleChange({
        target: {
          name: "InvitadoPor",
          value: null,
        },
      });
    }

    if (check) {
      handleChange({
        target: {
          name: "PublicoGeneral",
          value: 0,
        },
      });
    }

    handleChange({
      target: {
        name: "flagOtro",
        value: check,
      },
    });
  };

  return (
    <Box sx={{ mt: 2, mb: 2 }}>
      <Grid2 xs={12} sm={12} md={12} lg={12} sx={{ mt: 2 }}>
        <Chip
          icon={<Icon>groups2</Icon>}
          label={`${
            middleware.checkMenuAction("Capturista") ? "Seleccione a" : ""
          } Quien te invito`}
        />
      </Grid2>

      <Grid2 container spacing={1} sx={{ mt: 1 }}>
        <Grid2 xs={12} sm={12} md={6} lg={6}>
          <TextField
            required={true}
            disabled={true}
            label="Quien te invito"
            value={responsible.label}
            name="Nombre"
            sx={{ width: "100%" }}
            type="text"
            variant="outlined"
            size="small"
            className="fixed-input"
            InputProps={
              middleware.checkMenuAction("Capturista") && {
                /*  startAdornment: (
                                <InputAdornment position="start" sx={{ pl: 0, pr: 0 }}>
                                    <Button
                                        color="warning"
                                        onClick={e => resetResponsible()}
                                        sx={{ ml: 0, pl: 0 }}>
                                        <Icon>restore</Icon>
                                    </Button>
                                    <Divider sx={{ height: 28, }} orientation="vertical" />
                                </InputAdornment>
                            ), */
                endAdornment: (
                  <InputAdornment position="end">
                    <Divider
                      sx={{ height: 28, m: 0.5 }}
                      orientation="vertical"
                    />
                    <IconButton
                      color="info"
                      onClick={(e) => setOpenModal1(true)}
                      sx={{ mr: 0, pr: 0 }}
                    >
                      <Icon>search</Icon>
                    </IconButton>
                  </InputAdornment>
                ),
              }
            }
          />
        </Grid2>
        <Grid2 xs={12} sm={12} md={3} lg={3}>
        <Typography variant={"body2"} color={"error"} fontWeight={400}>
            Nota: En caso de no encontrar a la persona que lo invito, favor de
            selecconar otro y escribir su nombre
          </Typography>
        </Grid2>
      </Grid2>
      <Grid2 container spacing={1} sx={{ mt: 1 }}>
      <Grid2 xs={12} sm={12} md={1} lg={1}>
          <FormControlLabel
            control={
              <Checkbox
                checked={values.flagOtro}
                name="otro"
                onChange={(e) => onOtro(e)}
              />
            }
            label={
              <Typography variant={"body2"} fontWeight={400}>
                Otro
              </Typography>
            }
          />
        </Grid2>
        {values.flagOtro && (
          <Grid2 xs={12} sm={12} md={4} lg={4}>
            <TextField
              error={
                touched.InvitadoPor &&
                !isEmptyOrInvalidString(errors.InvitadoPor)
              }
              helperText={
                touched.InvitadoPor && errors.InvitadoPor && errors.InvitadoPor
              }
              sx={{ width: "100%" }}
              required
              label="Invitado Por"
              type="text"
              name="InvitadoPor"
              onChange={handleChange}
              value={values.InvitadoPor ? values.InvitadoPor.toUpperCase() : ""}
              variant="outlined"
              size="small"
              className="fixed-input"
            />
          </Grid2>
        )}
        <Grid2 xs={12} sm={12} md={2} lg={2}>
          <FormControlLabel
            control={
              <Checkbox
                checked={
                  values.PublicoGeneral && values.PublicoGeneral === 1
                    ? true
                    : false
                }
                name="PublicoGeneral"
                onChange={(e) => onPublic(e)}
              />
            }
            label={
              <Typography variant={"body2"} fontWeight={400}>
                Público en General
              </Typography>
            }
          />
        </Grid2>
      </Grid2>

      <ModalResponsibleList
        openModal={openModal1}
        setOpenModal={setOpenModal1}
        setResponsible={setResponsible}
      />
    </Box>
  );
};

export default ResponsibleListFrente;
