import { useState, useEffect } from "react";
import Swal from "sweetalert2";

// Material UI
import { Card, CardContent, Typography, Box } from "@mui/material";
import ErrorIcon from '@mui/icons-material/Error';

// Componentes
import BarColumnTripleHidden from "@components/Charts/BarColumnTripleHidden";

// Servicios
import MovilizationServices from "@services/MovilizationServices";

const ChartBingoParticipation = ({ body, setLoading, openDrawer, ambitoUser }) => {
  const [dataChart, setDataChart] = useState({ categories: [], series: [], title: "" });

  // controlar si no se encontraron resultados
  const [noResults, setNoResults] = useState(false);

  const getMobilizedChartByView = async (body) => {
    setLoading((prev) => ({ ...prev, isLoading: true, open: true }));

    try {
      const res = await MovilizationServices.getCompromisosBingosChart(body);
      const { results, message, response } = res;

      if (results) {
        setDataChart({
          categories: response.categories,
          series: response.series.map((item) => ({
            ...item,
            data: item.data.map((value) => parseInt(value)),
          })),
          title: response.title.toUpperCase(),
        });
        setNoResults(false);
      } else {
        setNoResults(true);
        throw new Error(message);
      }
    } catch (error) {
      Swal.fire({ title: error.message, icon: "warning" });
    } finally {
      setLoading((prev) => ({ ...prev, isLoading: false, open: false }));
    }
  };

  useEffect(() => {
    if (ambitoUser) getMobilizedChartByView(body);
    // eslint-disable-next-line
  }, [body]);

  return (
    <Card className="card-primary" sx={{ marginBottom: "0px!important" }}>
      <CardContent key={`Bingo_${openDrawer}`}>
        {noResults ? (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              color: "black",
            }}
          >
            <ErrorIcon sx={{ fontSize: 40, mt: 1 }} />
            <Typography fontWeight={600} variant="subtitle2" marginTop={2}>
              No se encontraron resultados
            </Typography>
          </Box>
        ) : (
          <>
            <Typography fontWeight={600} variant="subtitle2" marginBottom={2}>
              {dataChart.title}
            </Typography>
            <BarColumnTripleHidden series={dataChart.series} categories={dataChart.categories} />
          </>
        )}
      </CardContent>
    </Card>
  );
};

export default ChartBingoParticipation;
