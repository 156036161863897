import { useCatalogs } from "@hooks/useCatalogs";

import {
  Button,
  Card,
  Container,
  Grid,
  Icon,
  Typography,
  Stack,
  TextField,
} from "@mui/material";
import { useFormik } from "formik";
import PropTypes from "prop-types";

import BasicSelect from "@components/Selects/BasicSelect";

import { CompromisoFilterHistoryExt } from "@data/interfaces/CompromisoInterfaces";
import { CompromisoFilterHistorySchemaExt } from "@data/schemas/CompromisoSchemas";

import { isTypePhone } from "@utils/validations";

const HistoryForeignFilter = ({ onChangeFilter, onChangeFilterClear }) => {
  const formik = useFormik({
    initialValues: CompromisoFilterHistoryExt,
    validationSchema: CompromisoFilterHistorySchemaExt,
    onSubmit: (values) => {
      handleFilter(values);
    },
  });

  const { catalogs, load } = useCatalogs({
    catalogsOptions: [{ id: "paises" }],
    putDefaultOption: false,
  });

  const handleFilter = (values) => {
    const filters = [
      {
        id: "compromisos_extranjero.idPais",
        key: "idPais",
      },
      {
        id: "compromisos_extranjero.Celular",
        key: "celular",
      },
      {
        id: [
          "compromisos_extranjero.Nombre",
          "compromisos_extranjero.Paterno",
          "compromisos_extranjero.Materno",
        ],
        key: "nombre",
      },
    ];

    const filtered = filters
      .filter((filter) => {
        const value = values[filter.key];
        return (
          value !== undefined &&
          value !== null &&
          (typeof value === "string" ? value.length > 0 : value > 0)
        );
      })
      .map((filter) => ({
        id: filter.id,
        filter: filter.key === "nombre" ? "LIKE" : "=",
        value: values[filter.key],
      }));

    onChangeFilter(filtered);
  };

  const handleOnKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      formik.submitForm();
    }
  };

  const handleClearFilter = () => {
    formik.setValues({
      ...formik,
      idPais: "",
      celular: "",
      nombre: "",
    });

    onChangeFilterClear();
  };
  return (
    <Card className="card-primary">
      <Container maxWidth={false} sx={{ p: 2 }}>
        <Stack direction="row" spacing={1} marginBottom={2} alignItems="center">
          <Icon>tune</Icon>
          <Typography variant="subtitle1" fontWeight={600}>
            Filtros
          </Typography>
        </Stack>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={2} lg={2}>
            <BasicSelect
              label="País"
              name="idPais"
              options={catalogs.paises}
              value={formik.values.idPais}
              onChange={formik.handleChange}
              sx={{ width: "100%" }}
              isLoading={load}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4}>
            <TextField
              sx={{ width: "100%" }}
              label="Celular"
              type="text"
              name="celular"
              onChange={(e) =>
                formik.handleChange({
                  target: {
                    name: "celular",
                    value: isTypePhone(e.target.value)
                      ? e.target.value
                      : formik.values.celular,
                  },
                })
              }
              onBlur={(e) =>
                formik.handleChange({
                  target: {
                    name: "celular",
                    value: isTypePhone(e.target.value)
                      ? e.target.value
                      : formik.values.celular,
                  },
                })
              }
              onKeyDown={handleOnKeyPress}
              value={formik.values.celular}
              variant="outlined"
              size="small"
              className="fixed-input"
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4}>
            <TextField
              sx={{ width: "100%" }}
              label="Nombre"
              type="text"
              name="nombre"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              onKeyDown={handleOnKeyPress}
              value={formik.values.nombre.toUpperCase()}
              variant="outlined"
              size="small"
              className="fixed-input"
            />
          </Grid>
          <Grid item xs={12} sm={12} md={2} lg={2}>
            <Button
              variant="contained"
              color="primaryDark"
              onClick={formik.submitForm}
            >
              Filtrar
            </Button>
            <Button
              variant="text"
              color="primaryDark"
              onClick={handleClearFilter}
              sx={{ ml: 1 }}
            >
              Limpiar
            </Button>
          </Grid>
        </Grid>
      </Container>
    </Card>
  );
};

HistoryForeignFilter.propTypes = {
  onChangeFilter: PropTypes.func.isRequired,
};

export default HistoryForeignFilter;
