import { useEffect } from "react";
import {
  Container,
  Grid,
  Box,
  Typography
} from "@mui/material";
import UserForm from "../../components/Compromisos/UserForm";
import AddressForm from "@components/Compromisos/AddressForm";
import PersonalInformationForm from "@components/Compromisos/PersonalInformationForm";
import ContactInformationForm from "@components/Compromisos/ContactInformationForm";


const  FormRegister = (props) => {
  const {
    catalogsFiltered,
    dataEvent,
    errors,
    touched,
    values,
    setValues,
    handleChange,
    load
  } = props;


  useEffect(() => {
 
  }, [load]);

  return (
    <Box component="section" pt={6} my={6} sx={{ alignItems: "center" }}>
      <Container >
          <Grid
            container
            justifyContent="center"
            alignItems="center"
          >
            <Grid item xs={12}>
              <Typography variant="h4" color={"#1769aa"} gutterBottom>
                Para poderse registrar en {`${dataEvent.Actividad}`} es
                necesario proporcionar la siguiente información.
              </Typography>
            </Grid>
            {dataEvent.idModoEvento !== 3 && (
              <Grid item xs={12}>
                <UserForm
                  catalogs={catalogsFiltered}
                 loadigCatalogs={load}
                  errors={errors}
                  touched={touched}
                  values={values}
                  setValues={setValues}
                  handleChange={handleChange}
                />
              </Grid>
            )}
            <Grid item xs={12}>
              <PersonalInformationForm
                catalogs={catalogsFiltered}
                loadigCatalogs={load} 
                errors={errors}
                touched={touched}
                values={values}
                setValues={setValues}
                handleChange={handleChange}
                NotINE={true}
                NotSimple={true}
                isMenor={values.isMenor}
              />
            </Grid>
            <Grid item xs={12}>
              <ContactInformationForm
                errors={errors}
                touched={touched}
                values={values}
                handleChange={handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <AddressForm
                catalogs={catalogsFiltered}
               loadigCatalogs={load} 
                errors={errors}
                touched={touched}
                values={values}
                setValues={setValues}
                handleChange={handleChange}
                movile={true}
              />
            </Grid>
{/*             <LoadingButton
              type="submit"
              size="small"
              fullWidth
              color="primary"
              sx={{ mt: 3, mb: 2 }}
              onClick={submitForm}
              loading={LoadingForm}
              variant="contained"
            >
              <span>Registrarme</span>
            </LoadingButton> */}
          </Grid>
      </Container>
    </Box>
  );
}

export default FormRegister;
