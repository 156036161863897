import React, { useState } from "react";
import {
  Box,
  Container,
  Icon,
  IconButton,
  Stack,
  TextField,
  Typography,
  FormControlLabel,
  FormGroup,
  Checkbox,
  Button,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";

import Toast from "../Toast";
import WebCam from "../WebCam";
import Navbar from "../Navbar";

import moment from "moment/moment";
import "moment/locale/es";
import esLocale from "date-fns/locale/es";
import { getVars } from "@utils/global";
import { useMobileServices } from "@hooks/MobileDiaD/MobileServices";

const FormTransmision = ({
  idCasilla,
  NombreCasilla,
  Seccion,
  setShowForm,
}) => {
  const {
    user: { ambito_perfil },
  } = getVars("Token");

  const { loading, snackbar, setSnackbar, setTransmision } =
    useMobileServices();

  const [formValue, setFormValue] = useState({
    hour: moment(),
    transmisionApp: "",
    transmisionCelular: "",
    transmisionWhatsapp: "",
    transmisionPapel: "",
  });
  const [image, setImage] = useState(null);

  /*   const handleChange = (event) => {
    setFormValue({ ...formValue, [event.target.name]: event.target.value });
  }; */

  const handleChangeCheck = (event) => {
    const { name, checked, value } = event.target;
    const _value = parseInt(value);

    setFormValue({
      ...formValue,
      [name]: checked ? parseInt(value) : _value,
    });
  };

  const handleSetTransmision = async () => {
    const params = {
      idUser: ambito_perfil[0].idUsuario,
      casillas: [
        {
          idCasilla: idCasilla,
          FechaHora: moment(formValue.hour).format("HH:mm"),
          App: formValue.transmisionApp || 0,
          Telefono: formValue.transmisionCelular || 0,
          Whatsapp: formValue.transmisionWhatsapp || 0,
          Papel: formValue.transmisionPapel || 0,
        },
      ],
    };

    if (image) params.casillas[0].files = [{ file: image }];

    const results = await setTransmision(params);

    if (results) {
      setImage(null);
      setFormValue({
        hour: moment(),
        transmisionApp: "",
        transmisionCelular: "",
        transmisionWhatsapp: "",
        transmisionPapel: "",
      });
    }
  };

  return (
    <>
      <Toast
        open={snackbar.show}
        message={snackbar.message}
        setOpen={setSnackbar}
      />

      <Navbar
        isScreen
        screenBack={false}
        title={"Transmisión"}
        setTypeScreen={setShowForm}
        color="black"
        disableGoHome
      />

      <Container>
        <Stack marginBottom={2} />
        

        <Typography variant="body2" fontWeight={600} textAlign={"center"}>
          ¿Ya estás en tu casilla?
        </Typography>

        <Stack direction={"column"}>
          <Typography variant="body2">Sección</Typography>
          <Typography variant="subtitle1" fontWeight={600}>
            {Seccion}
          </Typography>
          <Typography variant="body2">Casilla</Typography>
          <Typography variant="subtitle1" fontWeight={600}>
            {NombreCasilla}
          </Typography>

          <Box marginBottom={2} marginTop={2}>
            <Typography variant="body2" marginBottom={1}>
              Hora del Reporte
            </Typography>
            <Stack direction={"row"} spacing={1}>
              <LocalizationProvider
                dateAdapter={AdapterDateFns}
                adapterLocale={esLocale}
                localeText={{
                  timePicker24Hour: "24 horas",
                  okButtonLabel: "Aceptar",
                  cancelButtonLabel: "Cancelar",
                }}
              >
                <TimePicker
                  label={"Hora reporte"}
                  value={formValue.hour}
                  onChange={(newValue) =>
                    setFormValue({ ...formValue, hour: newValue })
                  }
                  renderInput={(params) => <TextField fullWidth {...params} />}
                />
              </LocalizationProvider>
              <IconButton
                onClick={() => setFormValue({ ...formValue, hour: "" })}
              >
                <Icon>delete</Icon>
              </IconButton>
            </Stack>
          </Box>

          <Box marginBottom={2}>
            <Typography variant="body2">
              ¿Se puede transmitir por vía App?
            </Typography>
            <FormGroup row sx={{ display: "flex", gap: 2 }}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={
                      formValue.transmisionApp && formValue.transmisionApp === 1
                        ? true
                        : false
                    }
                    name="transmisionApp"
                    value={1}
                    onChange={handleChangeCheck}
                  />
                }
                label={
                  <Typography variant={"body2"} fontWeight={600}>
                    Sí
                  </Typography>
                }
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={
                      !formValue.transmisionApp &&
                      formValue.transmisionApp === 0
                        ? true
                        : false
                    }
                    name="transmisionApp"
                    value={0}
                    onChange={handleChangeCheck}
                  />
                }
                label={
                  <Typography variant={"body2"} fontWeight={600}>
                    No
                  </Typography>
                }
              />
            </FormGroup>
          </Box>

          <Box marginBottom={2}>
            <Typography variant="body2">
              ¿Se puede transmitir por vía llamada celular?
            </Typography>
            <FormGroup row sx={{ display: "flex", gap: 2 }}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={
                      formValue.transmisionCelular &&
                      formValue.transmisionCelular === 1
                        ? true
                        : false
                    }
                    name="transmisionCelular"
                    value={1}
                    onChange={handleChangeCheck}
                  />
                }
                label={
                  <Typography variant={"body2"} fontWeight={600}>
                    Sí
                  </Typography>
                }
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={
                      !formValue.transmisionCelular &&
                      formValue.transmisionCelular === 0
                        ? true
                        : false
                    }
                    name="transmisionCelular"
                    value={0}
                    onChange={handleChangeCheck}
                  />
                }
                label={
                  <Typography variant={"body2"} fontWeight={600}>
                    No
                  </Typography>
                }
              />
            </FormGroup>
          </Box>

          <Box marginBottom={2}>
            <Typography variant="body2">
              ¿Se puede transmitir por vía WhatsApp?
            </Typography>
            <FormGroup row sx={{ display: "flex", gap: 2 }}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={
                      formValue.transmisionWhatsapp &&
                      formValue.transmisionWhatsapp === 1
                        ? true
                        : false
                    }
                    name="transmisionWhatsapp"
                    value={1}
                    onChange={handleChangeCheck}
                  />
                }
                label={
                  <Typography variant={"body2"} fontWeight={600}>
                    Sí
                  </Typography>
                }
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={
                      !formValue.transmisionWhatsapp &&
                      formValue.transmisionWhatsapp === 0
                        ? true
                        : false
                    }
                    name="transmisionWhatsapp"
                    value={0}
                    onChange={handleChangeCheck}
                  />
                }
                label={
                  <Typography variant={"body2"} fontWeight={600}>
                    No
                  </Typography>
                }
              />
            </FormGroup>
          </Box>

          <Box marginBottom={2}>
            <Typography variant="body2">
              ¿Se puede transmitir por vía Papel?
            </Typography>
            <FormGroup row sx={{ display: "flex", gap: 2 }}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={
                      formValue.transmisionPapel &&
                      formValue.transmisionPapel === 1
                        ? true
                        : false
                    }
                    name="transmisionPapel"
                    value={1}
                    onChange={handleChangeCheck}
                  />
                }
                label={
                  <Typography variant={"body2"} fontWeight={600}>
                    Sí
                  </Typography>
                }
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={
                      !formValue.transmisionPapel &&
                      formValue.transmisionPapel === 0
                        ? true
                        : false
                    }
                    name="transmisionPapel"
                    value={0}
                    onChange={handleChangeCheck}
                  />
                }
                label={
                  <Typography variant={"body2"} fontWeight={600}>
                    No
                  </Typography>
                }
              />
            </FormGroup>
          </Box>

          <WebCam image={image} setImage={setImage} />

          <Button
            variant="contained"
            onClick={handleSetTransmision}
            disabled={loading}
            sx={{ mb: 2 }}
          >
            {loading ? "Guardando..." : "Enviar Reporte"}
          </Button>
        </Stack>
      </Container>
    </>
  );
};

export default FormTransmision;
