import { useState, useEffect } from "react";

import { CircularProgress } from "@mui/material";

import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

const SummaryChart = (props) => {
  const { data: datos, height, loading } = props.data;
  const [data, setData] = useState(null);

  useEffect(() => {
    setData({
      credits: {
        enabled: false,
      },
      chart: {
        type: "column",
        height: height,
      },
      title: {
        text: "",
      },
      subtitle: {
        text: "",
      },
      xAxis: {
        // categories: props.data.names,
        labels: {
          enabled: false,
        },
        title: {
          text: null,
        },
        startOnTick: false,
        endOnTick: false,
        tickPositions: [],
      },
      yAxis: {
        endOnTick: false,
        startOnTick: false,
        labels: {
          enabled: false,
        },
        title: {
          text: null,
        },
        tickPositions: [0],
      },
      legend: {
        enabled: false,
      },
      tooltip: {
        headerFormat: "",
        pointFormat: "<strong>{point.y}</strong>",
        backgroundColor: "white",
        borderWidth: 1,
        hideDelay: 0,
        shared: true,
        padding: 8,
        borderColor: "silver",
        borderRadius: 2,
        positioner: function (w, h, point) {
          return { x: point.plotX - w / 3, y: point.plotY - h / 2 };
        },
      },
      plotOptions: {
        series: {
          animation: false,
          lineWidth: 1,
          shadow: false,
          states: {
            hover: {
              lineWidth: 1,
            },
          },
          marker: {
            radius: 1,
            states: {
              hover: {
                radius: 2,
              },
            },
          },
          fillOpacity: 0,
        },
        column: {
          negativeColor: "#910000",
          borderColor: "silver",
        },
      },
      series: [
        {
          showInLegend: false,
          data: datos,
          colorByPoint: true,
        },
      ],
    });

    // eslint-disable-next-line
  }, [datos]);

  return data !== null || loading ? (
    <HighchartsReact highcharts={Highcharts} options={data} />
  ) : (
    <CircularProgress />
  );
};

export default SummaryChart;
