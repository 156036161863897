import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Card, CardHeader, CardContent, CardActions, Paper, InputBase, IconButton, List, LinearProgress, Typography, TextField, Button } from "@mui/material";
import Icon from "@mui/material/Icon";
import InfiniteScroll from "react-infinite-scroll-component";
import Alert from "@mui/material/Alert";
import Grid2 from "@mui/material/Unstable_Grid2";

const ListLocal = (props) => {
  const {
    Header = true,
    configParams,
    data,
    fetchHasMore,
    totalItems,
    itemComponent,
    hasMore,
    handleClickItem,
    finder2,
    filterSearch,
    filterValue,
    handleDeleteItem,
    alertUser = false,
    onClick = () => {},
  } = props;
  const [showedItems, setShowedItems] = useState(0);
  const [listingData, setListingData] = useState([]);
  const [selectedIndex, setSelectedIndex] = useState(1);

  const [config, setConfig] = useState({
    title: "Listado",
    icon: "list_alt",
    height: 400,
    placeholder: "Buscar usuario",
    endMessage: "No hay registros para mostrar",
    alertMessage: "Reportar",
    buttonName: "Submit",
  });

  useEffect(() => {
    Object.keys(configParams).forEach(function (key) {
      setConfig((prevState) => ({ ...prevState, [key]: configParams[key] }));
    });
  }, [configParams]);

  useEffect(() => {
    setListingData(data);
    setShowedItems(data ? data.length : 0);
  }, [data]);

  const handleSelectedIndex = (index, data) => {
    setSelectedIndex(index);
    handleClickItem(data);
  };

  return (
    <Card sx={{ width: "100%" }} className="card-secondary">
      {Header && <CardHeader avatar={<Icon>{config.icon}</Icon>} title={config.title} />}
      <CardContent>
        {finder2 && (
          <TextField
            placeholder="Buscar por nombre"
            onChange={(e) => filterSearch(e.target.value)}
            value={filterValue}
            InputProps={{
              startAdornment: (
                <Icon fontSize="small" color="action">
                  search
                </Icon>
              ),
              sx: { borderRadius: 2 },
            }}
            size="small"
            sx={{ width: "100%", mb:2 }}
          />
        )}

        <InfiniteScroll
          dataLength={showedItems}
          next={fetchHasMore}
          hasMore={hasMore}
          loader={<LinearProgress color="secondary" />}
          height={config.height}
          endMessage={
            <p style={{ textAlign: "center" }}>
              <b>{config.endMessage !== undefined ? config.endMessage : "¡Final de la lista de registros!"}</b>
            </p>
          }
        >
          <List dense={true}>
            {listingData.map((i, index) =>
              itemComponent({
                item: i,
                selectedIndex: selectedIndex,
                handleSelectedIndex: handleSelectedIndex,
                index: index,
                handleDeleteItem: handleDeleteItem,
              })
            )}
          </List>
        </InfiniteScroll>
      </CardContent>

      <CardActions style={{ display: "flex", justifyContent: "center" }}>
        {alertUser && (
          <Alert severity="warning" sx={{ height: "40px", display: "flex", alignItems: "center", justifyContent: "center" }}>
            <Grid2 style={{ display: "flex", alignItems: "center" }}>
              <Typography mr={2}>{config.alertMessage}</Typography>
              <Button variant="contained" size="small" color="primaryDark" onClick={onClick}>
                {config.buttonName}
              </Button>
            </Grid2>
          </Alert>
        )}
      </CardActions>
      <CardActions style={{ display: "flex", justifyContent: "center" }}>
        <Typography variant="body2" sx={{ color: "#888888" }}>
          Mostrando {showedItems} de {totalItems}
        </Typography>
      </CardActions>
    </Card>
  );
};

ListLocal.propTypes = {
  data: PropTypes.array.isRequired,
  fetchHasMore: PropTypes.func.isRequired,
  hasMore: PropTypes.bool.isRequired,
  itemComponent: PropTypes.any.isRequired,
  totalItems: PropTypes.number.isRequired,
  configParams: PropTypes.object,
  handleClickItem: PropTypes.func,
  finder: PropTypes.bool,
  handleDeleteItem: PropTypes.func,
};

ListLocal.defaultProps = {
  totalItems: 0,
  finder: true,
  configParams: {
    title: "Listado",
    icon: "list_alt",
    height: 400,
    endMessage: "No hay registros para mostrar",
  },
  handleClickItem: () => {},
  handleDeleteItem: () => {},
};

export default ListLocal;
