import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

import {
  Button,
  Stack,
  Box,
  List,
  ListItem,
  ListItemText,
  Divider,
  Typography,
  Collapse,
  CardHeader,
  CardContent,
  Card,
  IconButton,
} from "@mui/material";
import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import ExpandMore from "@mui/icons-material/ExpandMore";
import ExpandLess from "@mui/icons-material/ExpandLess";

import BasicSelect from "@components/Selects/BasicSelect";
import CatalogServices from "@services/CatalogServices";

const FilterSije = ({ onChangeFilter, onClearFilter, entidad = true, setOpenFilter, anchor }) => {
  //CONSTANTS
  const navigate = useNavigate();
  const defaultOption = { value: 0, label: "TODOS" };
  const catalogsOptions = [
    { id: "df", getAll: false },
    { id: "secciones", getAll: false },
    { id: "dl", getAll: false },
    { id: "municipios_reportes", getAll: false },
    { id: "mpio_df", getAll: false },
    { id: "mpio_dl", getAll: false },
    { id: "entidades", getAll: false },
  ];
  //STATES
  const divFilterRef = useRef(null);
  const [open, setOpen] = useState({
    0: true,
    4: false,
  });
  const [loadingCatalogs, setLoadingCatalogs] = useState(false);
  const [isScroll, setIsScroll] = useState(false);
  const [catalogs, setCatalogs] = useState([]);
  const [catalogsFiltered, setCatalogFiltered] = useState(() => {
    let newObject = {};
    for (const item of catalogsOptions) {
      newObject[item.id] = [];
    }
    return newObject;
  });
  const [filterData, setFilterData] = useState({
    DttoFederalPrio: 0,
    DttoFederal: 0,
    Entidad: 0,
  });
  //ALMACENAR LOS DATOS TEMPORALMENRE AL HACER USO DE NAVIGATE()
  const saveStateToLocalStorage = () => {
    const stateToSave = {
      filterData,
      catalogsFiltered,
      open,
    };

    localStorage.setItem("filterSijeState", JSON.stringify(stateToSave));
  };

  const getCatalogs = async () => {
    try {
      setLoadingCatalogs((prevState) => !prevState);
      const result = await CatalogServices.getCatalogs(catalogsOptions);

      const { results, response, message } = result;

      if (results) {
        setCatalogFiltered(
          concatDefaultOption({
            ...catalogsFiltered,
            ...response.catalogs,
          })
        );
        setCatalogs(response.catalogs);
      } else {
        Swal.fire({
          title: message,
          icon: "warning",
        });
      }
    } catch (error) {
      Swal.fire({
        title: error,
        icon: "warning",
      });
    } finally {
      setLoadingCatalogs((prevState) => !prevState);
    }
  };

  const concatDefaultOption = (data) => {
    let newObject = {};
    Object.keys(data).forEach((key) => {
      newObject[key] = [defaultOption, ...data[key]];
    });
    return newObject;
  };

  
  const handleChangeEntidad = (e) => {
    const idEntidad = e.target.value;

    if (idEntidad > 0) {
      const cat_entidad = catalogs.entidades;

      if (cat_entidad) {
        const entidades = [
          defaultOption,
          ...cat_entidad
            .filter(({ idEstado }) => idEstado === idEntidad)
            .map(({ idMunicipioReportes, Municipio }) => ({
              value: idMunicipioReportes,
              label: Municipio,
            })),
        ];

        setCatalogFiltered((prevState) => ({
          ...prevState,
          secciones: [defaultOption, ...catalogs.secciones.filter((item) => item.idDL === idEntidad)],
          municipios_reportes: entidades,
        }));

        setFilterData((prevState) => ({
          ...prevState,
          Municipio: 0,
          Seccion: 0,
        }));
      }
    } else {
      setCatalogFiltered(
        concatDefaultOption({
          ...catalogsFiltered,
          ...catalogs,
        })
      );

      setFilterData((prevState) => ({
        ...prevState,
        Municipio: 0,
        Seccion: 0,
      }));
    }
  };

  const handleChangeDttoFederal = (e) => {
    const idDttoFederal = e.target.value;

    if (idDttoFederal > 0) {
      const cat_mpio_df = catalogs.mpio_df;

      if (cat_mpio_df) {
        const municipios_df = [
          defaultOption,
          ...catalogs.mpio_df
            .filter(({ idDF }) => idDF === idDttoFederal)
            .map(({ idMunicipioReportes, Municipio }) => ({
              value: idMunicipioReportes,
              label: Municipio,
            })),
        ];

        setCatalogFiltered((prevState) => ({
          ...prevState,
          secciones: [defaultOption, ...catalogs.secciones.filter((item) => item.idDF === idDttoFederal)],
          municipios_reportes: municipios_df,
        }));

        setFilterData((prevState) => ({
          ...prevState,
          Municipio: 0,
          Seccion: 0,
        }));
      }
    } else {
      setCatalogFiltered(
        concatDefaultOption({
          ...catalogsFiltered,
          ...catalogs,
        })
      );

      setFilterData((prevState) => ({
        ...prevState,
        Municipio: 0,
        Seccion: 0,
      }));
    }
  };

  const handleChangeFilter = (e) => {
    const key = e.target.name;
    setFilterData({ ...filterData, [key]: e.target.value });
  };

  const handleSearch = () => {
    let filteredChart = [];
    let filtered = [];
    let filteredMinutes = [];

    if (filterData.DttoFederal > 0) {
      filteredChart = [
        ...filteredChart,
        {
          id: "cat_secciones.idDF",
          filter: "=",
          value: filterData.DttoFederal,
        },
      ];
      filtered = [
        ...filtered,
        {
          id: "idDF",
          filter: "=",
          value: filterData.DttoFederal,
        },
      ];
      filteredMinutes = [
        ...filteredMinutes,
        {
          id: "cat_secciones.idDF",
          filter: "=",
          value: filterData.DttoFederal,
        },
      ];
    }

    if (filterData.Entidad > 0) {
      filteredChart = [
        ...filteredChart,
        {
          id: "cat_secciones.idEstado",
          filter: "=",
          value: filterData.Entidad,
        },
      ];
      filtered = [
        ...filtered,
        {
          id: "idEstado",
          filter: "=",
          value: filterData.Entidad,
        },
      ];
      filteredMinutes = [
        ...filteredMinutes,
        {
          id: "cat_secciones.idEstado",
          filter: "=",
          value: filterData.Entidad,
        },
      ];
      saveStateToLocalStorage();
    }

    onChangeFilter({
      filtersChart: filteredChart,
      filtersData: filtered,
      filtersMinutes: filteredMinutes,
    });
  };

  const handleClearFilter = () => {
    const cleanFileds = {
      DttoFederalPrio: 0,
      DttoFederal: 0,
      Entidad: 0,
    };
    setCatalogFiltered(
      concatDefaultOption({
        ...catalogsFiltered,
        ...catalogs,
      })
    );
    setFilterData(cleanFileds);
    onClearFilter();
    saveStateToLocalStorage();
  };

  const handleClick = (target) => {
    setOpen({ [target]: !open[target] });
  };

  const handleClickNavigate = (idEntidad) => {
    localStorage.setItem(
      "filterSijeState",
      JSON.stringify({ filterData, catalogsFiltered, open })
    );

    navigate("/casilla-operacion", {
      state: { idEstado: idEntidad, navidateBack: true },
    });
  };

  //OBTIENE LOS DATOS ALMACENADOS TEMPORALMENTE Y LOS INSERTA EN DONDE CORRESPONDE
  useEffect(() => {
    const storedState = localStorage.getItem("filterSijeState");
    if (storedState) {
      const parsedState = JSON.parse(storedState);
      setFilterData(parsedState.filterData);
      setCatalogFiltered(parsedState.catalogsFiltered);
      setOpen(parsedState.open);
    } else {
      // Si no hay datos en el localStorage, obtener los catálogos
      getCatalogs();
    }
      handleSearch();
    // Limpiar el localStorage después de obtener los datos
    localStorage.removeItem("filterSijeState");
  }, []);

  /* useEffect(() => {
    getCatalogs();
    // eslint-disable-next-line
  }, []); */

  useEffect(() => {
    divFilterRef.current.onscroll = () => {
      divFilterRef.current.scrollTop > 10 ? setIsScroll(true) : setIsScroll(false);
    };
  }, []);
  return (
    <Card
      className="card-primary"
      sx={{
        position: "sticky",
        top: "6rem",
      }}
    >
      <CardHeader
        title={
          <Typography variant="subtitle1" fontWeight={600}>
            Filtros
          </Typography>
        }
        action={
          <Stack direction={"row"} spacing={1}>
            <Button onClick={handleClearFilter} size="small" color="primaryDark">
              Limpiar
            </Button>
            <Button variant="contained" size="small" onClick={handleSearch} color="primaryDark">
              Filtrar
            </Button>
            <Box sx={{ height: 2 }}>
              <IconButton onClick={() => setOpenFilter(false)}>{anchor === "left" ? <ChevronLeft /> : <ChevronRight />}</IconButton>
            </Box>
          </Stack>
        }
        sx={{
          boxShadow: isScroll ? "rgb(145 158 171 / 20%) 0px 0px 2px 0px, rgb(145 158 171 / 12%) 0px 12px 24px -4px !important" : "none",
        }}
      />
      <Divider />
      <CardContent ref={divFilterRef} sx={{ height: "610px", overflowY: "scroll" }}>
        <List
          sx={{
            width: "100%",
            bgcolor: "background.paper",
          }}
          component="nav"
          aria-labelledby="nested-list-subheader"
        >
          <Box marginBottom={3}>
            <ListItem onClick={(e) => handleClick(0)} sx={{ cursor: "pointer" }}>
              <ListItemText
                primary={
                  <Typography variant="body1" fontWeight={600}>
                    Distritos
                  </Typography>
                }
              />
              {open[0] ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Divider />
            <Collapse in={open[0]} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItem>
                  <BasicSelect
                    name="DttoFederal"
                    label="Distrito Federales"
                    options={catalogsFiltered.df}
                    value={filterData.DttoFederal}
                    onChange={(e) => {
                      handleChangeFilter(e);
                      handleChangeDttoFederal(e);
                    }}
                    isLoading={loadingCatalogs}
                    sx={{ width: "100%" }}
                  />
                </ListItem>
                <ListItem>
                  <BasicSelect
                    name="DttoFederalPrioritario"
                    label="Distrito Federales Prioritarios"
                    //options={catalogsFiltered.dl}
                    value={filterData.DttoFederalPrio}
                    onChange={(e) => {
                      handleChangeFilter(e);
                      //handleChangeDttoLocal(e);
                    }}
                    isLoading={loadingCatalogs}
                    sx={{ width: "100%" }}
                  />
                </ListItem>
              </List>
            </Collapse>
          </Box>

          {entidad && (
            <Box marginBottom={3}>
              <ListItem onClick={(e) => handleClick(4)} sx={{ cursor: "pointer" }}>
                <ListItemText
                  primary={
                    <Typography variant="body1" fontWeight={600}>
                      Entidades
                    </Typography>
                  }
                />
                {open[4] ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
              <Divider />
              <Collapse in={open[4]} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  <ListItem>
                    <BasicSelect
                      name="Entidad"
                      label="Entidad"
                      options={catalogsFiltered.entidades}
                      value={filterData.Entidad}
                      onChange={(e) => {
                        handleChangeFilter(e);
                        handleChangeEntidad(e);
                      }}
                      isLoading={loadingCatalogs}
                      sx={{ width: "100%" }}
                    />
                  </ListItem>
                </List>
                <Stack direction="row" justifyContent="flex-end">
                  <Button
                    variant="contained"
                    size="small"
                    onClick={() => handleClickNavigate(filterData.Entidad)}
                    color="primaryDark"
                    /* disabled={!filterData.Entidad} */
                  >
                    Ver Detalle
                  </Button>
                </Stack>
              </Collapse>
            </Box>
          )}
        </List>
      </CardContent>
    </Card>
  );
};

export default FilterSije;
