import useWindowDimensions from "@hooks/useWindowDimensions";
import { useState, useEffect } from "react";

import { CircularProgress, Card, CardContent, Typography } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2";

import { numberWithCommas } from "@utils/Utilities/";
import Swal from "sweetalert2";
import numeralia from "@services/NumeraliaServices";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

const BarChartAge = ({
  dataFiltered,
  dataAge,
  title,
  loadingAge,
  setLoadingAge,
  setDataAge,
}) => {
  const { height, width } = useWindowDimensions();
  const maleData = dataAge.Male;
  const femaleData = dataAge.Female.map((item) => -Math.abs(item));

  const maxDataValue = Math.max(
    Math.max(...maleData.map((val) => Math.abs(val))),
    Math.max(...femaleData.map((val) => Math.abs(val)))
  );

  const [currentDate] = useState(new Date().toLocaleString());

  const yAxisOptions = {
    title: {
      text: null,
    },
    max: Math.ceil(maxDataValue / 10) * 10,
    min: -Math.ceil(maxDataValue / 10) * 10,
    labels: {
      formatter: function () {
        return numberWithCommas(Math.abs(this.value));
      },
      style: {
        fontSize: width > 1400 ? width * 0.01 : "14px",
      },
    },
    accessibility: {
      description: "Population",
      rangeDescription: `Range: -${Math.ceil(maxDataValue / 10) * 10} to ${
        Math.ceil(maxDataValue / 10) * 10
      }`,
    },
  };

  const options = {
    chart: {
      type: "bar",
      height: width > 1440 ? height * 0.4 : "auto",
    },
    title: {
      text: ``,
    },

    credits: {
      enabled: false,
    },
    accessibility: {
      point: {
        valueDescriptionFormat: "{index}. Age {xDescription}, {value}%.",
      },
    },
    xAxis: [
      {
        categories: dataAge.categories,
        reversed: false,
        labels: {
          step: 1,
        },
        accessibility: {
          description: "Age (male)",
        },
      },
      {
        opposite: true,
        reversed: false,
        categories: dataAge.categories,
        linkedTo: 0,
        labels: {
          step: 1,
        },
        accessibility: {
          description: "Age (female)",
        },
      },
    ],
    yAxis: yAxisOptions,
    plotOptions: {
      series: {
        stacking: "normal",
        borderRadius: "1%",
      },
    },
    tooltip: {
      formatter: function () {
        const isNegative = this.y < 0;
        const formattedValue = isNegative ? Math.abs(this.y) : this.y;
        return `<b>Edad: ${this.point.category}</b><br/><span style="color:${
          this.color
        }">\u25CF</span> ${this.series.name}: <b>${numberWithCommas(
          formattedValue
        )}</b><br/>`;
      },
    },
    series: [
      {
        name: "Masculino",
        data: maleData,
        color: "#37a3eb",
      },
      {
        name: "Femenino",
        data: femaleData,
        color: "#FF6486",
      },
    ],
  };

  const getNumeraliaEdadSexoNacional = async (body) => {
    setLoadingAge(true);
    try {
      const result = await numeralia.getNumeraliaEdadSexoNacional(body);
      const { results, response, message, success } = result;
      const {
        categorias,
        series: { hombres, mujeres },
      } = response.data;

      if (!results && success) {
        setDataAge({
          ...dataAge,
          categories: [],
          Male: [],
          Female: [],
        });
        return;
      }

      if (!results && !success) {
        throw new Error(message);
      }

      setDataAge({
        ...dataAge,
        categories: categorias,
        Male: hombres.map((male) => parseInt(male)),
        Female: mujeres.map((female) => parseInt(female)),
      });
    } catch (error) {
      Swal.fire({
        title: error.message,
        icon: "warning",
      });
    } finally {
      setLoadingAge(false);
    }
  };

  useEffect(() => {
    const body = {
      idAmbitoAgrupador: dataFiltered.idAmbitoAgrupador,
      filtered: dataFiltered.filtered,
      idEstado: dataFiltered.idEstado,
    };
    dataFiltered.idEstado === null && delete body.idEstado;

    getNumeraliaEdadSexoNacional(body);
  }, [dataFiltered]);

  return (
    <Card className="card-primary">
      <CardContent>
        <div>
          <Typography variant="subtitle2" fontWeight={600} textAlign={"end"}>
            {title}
          </Typography>
          <Typography variant="body2" textAlign={"end"} color={"GrayText"}>
            {currentDate}
          </Typography>
        </div>
        {loadingAge ? (
          <Grid2
            container
            xs={12}
            height={width > 1440 ? height * 0.4 : 400}
            alignItems="center"
            justifyContent="center"
          >
            <CircularProgress size={50} />
          </Grid2>
        ) : (
          <HighchartsReact highcharts={Highcharts} options={options} />
        )}
      </CardContent>
    </Card>
  );
};

export default BarChartAge;
