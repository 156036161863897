import Yup from "@utils/Yupi18n";

const MenuAddSchema = Yup.object({
  Menu: Yup.string().required(),
  Ruta: Yup.string().required(),
  Ver: Yup.number().required(),
  Agregar: Yup.number().required(),
  Editar: Yup.number().required(),
  Eliminar: Yup.number().required(),
  Exportar: Yup.number().required(),
  Importar: Yup.number().required(),
  Autorizar: Yup.number().required(),
  Visible: Yup.number().required(),
  Descripcion: Yup.string().nullable().optional(),
  Movil: Yup.number().required(),
  idPadre: Yup.number().optional().nullable(),
});

const MenuEditSchema = Yup.object({
  Menu: Yup.string().required(),
  Ruta: Yup.string(),
  Ver: Yup.number().required(),
  Agregar: Yup.number().required(),
  Editar: Yup.number().required(),
  Eliminar: Yup.number().required(),
  Exportar: Yup.number().required(),
  Importar: Yup.number().required(),
  Visible: Yup.number().required(),
  Descripcion: Yup.string().nullable().optional(),
  Movil: Yup.number().required(),
  idPadre: Yup.number().optional().nullable(),
});

export { MenuAddSchema, MenuEditSchema };
