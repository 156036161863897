import ApiExec from "@utils/ApiExec";

class HomoColoniasServices {
  updateColonia = (params) => {
    return ApiExec(params, "colonias/update-colonias-homologadas", "POST")
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };

  getColonia = (params) => {
    return ApiExec(params, "colonias/get-colonias-por-homologar", "POST")
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };
}

const services = new HomoColoniasServices();
export default services;
