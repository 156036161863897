import { useState, useEffect } from "react";
import Swal from "sweetalert2";

// Material UI
import { Button, Box, Divider, Stack, Card, CardContent, Typography } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";

// Componentes
import BasicSelect from "@components/Selects/BasicSelect";
import AdvancedSelect from "@components/Selects/AdvancedSelect";

// Servicios y data
import catalogServ from "@services/CatalogServices";
import { CATALOG_OPTIONS } from "@data/constants/Certificacion";
import {
  AMBITO_REGIONAL,
  AMBITO_DF,
  AMBITO_DL,
  AMBITO_MPAL,
  AMBITO_POLIGONAL,
  AMBITO_SECCIONAL,
} from "@data/constants";

// Utilidades

const Filter = (props) => {
  const { onSearch, onClear, refresh, setLoading, changeTypeView, catView, setIdFilter, ambitoUser, changeSize = false } = props;

  const defaultOption = { value: 0, label: "TODOS" };
  const [catViewFiltered, setCatViewFiltered] = useState([]);

  const [typeView, setTypeView] = useState(ambitoUser);
  const [catalogMpio, setCatalogMpio] = useState("municipios_reportes");
  const [catalogs, setCatalogs] = useState({});
  const [loadingCatalogs, setLoadingCatalogs] = useState(false);
  const [catalogsFiltered, setCatalogFiltered] = useState(() => {
    let newObject = {};
    Object.keys(CATALOG_OPTIONS).forEach((key) => {
      CATALOG_OPTIONS[key.id] = [];
    });
    return newObject;
  });


  const [filterValue, setFilterValue] = useState({
    tipoVista: ambitoUser,
    region: 0,
    distritoFederal: 0,
    distritoLocal: 0,
    municipio: 0,
    poligono: 0,
    seccion: 0,
    color: "",
  });

  const isIncluded = (value) => value.includes(typeView);

  const getCatalogs = async () => {
    setLoadingCatalogs(true);
    setLoading((prev) => ({ ...prev, isLoadigForm: true, openLinearProgress: true }));

    try {
      const result = await catalogServ.getCatalogs(CATALOG_OPTIONS);
      const { results, response, message } = await result;

      if (results) {
        const df = response.catalogs.mpio_df.map((item) => ({
          value: item.idMunicipioReportes,
          label: item.Municipio,
          idDF: item.idDF,
        }));

        const dl = response.catalogs.mpio_dl.map((item) => ({
          value: item.idMunicipioReportes,
          label: item.Municipio,
          idDL: item.idDL,
        }));

        response.catalogs.mpio_df = df;
        response.catalogs.mpio_dl = dl;

        setCatalogFiltered(concatDefaultOption({ ...catalogsFiltered, ...response.catalogs }));
        setCatalogs(response.catalogs);


      } else throw new Error(message);
    } catch (error) {
      Swal.fire({ title: error, icon: "warning" });
    } finally {
      setLoadingCatalogs(false);
      setLoading((prev) => ({ ...prev, isLoadigForm: false, openLinearProgress: false }));
    }
  };


  
  useEffect(() => {
    
      getCatalogs();

    // eslint-disable-next-line
  }, []);
  
  const concatDefaultOption = (data) => {
    let newObject = {};
    Object.keys(data).forEach((key) => {
      let option = [defaultOption];

      if (data[key]?.length === 1) {
        option = [];
        const value = handleOneCatalog(key);
        setTimeout(() => {
          handleChangeFilter({ name: value, value: value === "seccion" ? data[key][0] : data[key][0].value });
        }, 500);
      }

      return (newObject[key] = [...option, ...data[key]]);
    });
    return newObject;
  };

  const handleChangeTypeView = (event) => {
    setIdFilter(event);

    const view = event;
    setCatalogMpio("municipios_reportes");

    setFilterValue((prev) => ({
      ...prev,
      tipoVista: view,
      region: 0,
      distritoFederal: 0,
      distritoLocal: 0,
      municipio: 0,
      poligono: 0,
      seccion: 0,
      color: "",
    }));
    setTypeView( view );
    setCatalogFiltered(concatDefaultOption({ ...catalogsFiltered, ...catalogs }));

    // Actualizar en page
    changeTypeView(view);
  };

  const handleChangeFilter = ({ name, value }) => setFilterValue((prev) => ({ ...prev, [name]: value }));

  const handleCatalogFilterChange = ({ filterKey, filterValue, filterType }) => {
    const catalog = catalogs[filterKey].filter((item) => item[filterType] === filterValue);
    const option = catalog.length > 1 ? [defaultOption] : [];

    setCatalogFiltered((prev) => ({ ...prev, [filterKey]: [...option, ...catalog] }));

    if (catalog.length === 1) {
      const key = handleOneCatalog(filterKey);
      setTimeout(() => {
        handleChangeFilter({ name: key, value: key === "seccion" ? catalog[0] : catalog[0].value });
      }, 500);
    }
  };

  const handleCatalogFilterChangePS = ({ filterKey, filterType, filterValue, filterType2, filterValue2 }) => {
    const catalog = catalogs[filterKey].filter(
      (item) => item[filterType] === filterValue && item[filterType2] === filterValue2
    );
    const option = catalog.length > 1 ? [defaultOption] : [];

    setCatalogFiltered((prev) => ({ ...prev, [filterKey]: [...option, ...catalog] }));

    if (catalog.length === 1) {
      setTimeout(() => {
        const key = handleOneCatalog(filterKey);
        handleChangeFilter({ name: key, value: key === "seccion" ? catalog[0] : catalog[0].value });
      }, 500);
    }
  };

  const handleOneCatalog = (key) => {
    switch (key) {
      case "regiones":
        return "region";
      case "df":
        return "distritoFederal";
      case "dl":
        return "distritoLocal";
      case "mpio_df":
      case "mpio_dl":
      case "municipios_reportes":
        return "municipio";
      case "poligonos":
        return "poligono";
      case "secciones":
        return "seccion";
      default:
        return "";
    }
  };

  const handleChangeRegion = (_idRegion) => {
    if (_idRegion > 0) {
      const repeat = { filterType: "idRegion", filterValue: _idRegion };
      setIdFilter(AMBITO_REGIONAL);
      handleCatalogFilterChange({ filterKey: "municipios_reportes", ...repeat });
      handleCatalogFilterChange({ filterKey: "poligonos", ...repeat });
      handleCatalogFilterChange({ filterKey: "secciones", ...repeat });

      setFilterValue((prev) => ({ ...prev, municipio: 0, poligono: 0, seccion: 0 }));
    } else {
      setIdFilter(0);
      setCatalogFiltered(concatDefaultOption({ ...catalogsFiltered, ...catalogs }));
      setFilterValue((prev) => ({ ...prev, municipio: 0, poligono: 0, seccion: 0 }));
    }
  };


  const handleChangeDfs = (_idDfs) => {
    if (_idDfs > 0) {
      setIdFilter(AMBITO_DF);
      setCatalogMpio("mpio_df");

      const repeat = { filterType: "idDF", filterValue: _idDfs };

      handleCatalogFilterChange({ filterKey: "mpio_df", ...repeat });
      handleCatalogFilterChange({ filterKey: "poligonos", ...repeat });
      handleCatalogFilterChange({ filterKey: "secciones", ...repeat });

      setFilterValue((prev) => ({ ...prev, municipio: 0, poligono: 0, seccion: 0 }));
    } else {
      setIdFilter(0);
      setCatalogMpio("municipios_reportes");

      setCatalogFiltered(concatDefaultOption({ ...catalogsFiltered, ...catalogs }));
      setFilterValue((prev) => ({ ...prev, municipio: 0, poligono: 0, seccion: 0 }));
    }
  };

  const handleChangeDls = (_idDls) => {
    if (_idDls > 0) {
      setIdFilter(AMBITO_DL);
      setCatalogMpio("mpio_dl");

      const repeat = { filterType: "idDL", filterValue: _idDls };

      handleCatalogFilterChange({ filterKey: "mpio_dl", ...repeat });
      handleCatalogFilterChange({ filterKey: "poligonos", ...repeat });
      handleCatalogFilterChange({ filterKey: "secciones", ...repeat });

      setFilterValue((prev) => ({ ...prev, municipio: 0, poligono: 0, seccion: 0 }));
    } else {
      setIdFilter(0);
      setCatalogMpio("municipios_reportes");

      setCatalogFiltered(concatDefaultOption({ ...catalogsFiltered, ...catalogs }));
      setFilterValue((prev) => ({ ...prev, municipio: 0, poligono: 0, seccion: 0 }));
    }
  };

  const handleChangeMunicipality = (_idMunicipality) => {
    if (_idMunicipality > 0) {
      let repeat = {};
      let isEmpty = false;
      setIdFilter(AMBITO_MPAL);

      if (ambitoUser !== AMBITO_MPAL) {
        // Verifica si existen
        if (filterValue.region || filterValue.distritoFederal || filterValue.distritoLocal) {
          switch (typeView) {
            case AMBITO_REGIONAL:
              repeat = { filterType: "idRegion", filterValue: filterValue.region };
              break;
            case AMBITO_DF:
              repeat = { filterType: "idDF", filterValue: filterValue.distritoFederal };
              break;
            case AMBITO_DL:
              repeat = { filterType: "idDL", filterValue: filterValue.distritoLocal };
              break;
            default:
              repeat = { filterType: "idRegion", filterValue: filterValue.region };
              break;
          }
        } else isEmpty = true;
      }

      if (isEmpty) {
        // Si es vacio solo filtra por el municipio
        const repeat2 = { filterType: "idMunicipioReportes", filterValue: _idMunicipality };

        handleCatalogFilterChange({ filterKey: "poligonos", ...repeat2 });
        handleCatalogFilterChange({ filterKey: "secciones", ...repeat2 });
      } else {
        // Si NO es vacio solo filtra por ambos
        const repeat2 = { filterType2: "idMunicipioReportes", filterValue2: _idMunicipality };
        handleCatalogFilterChangePS({ filterKey: "poligonos", ...repeat, ...repeat2 });
        handleCatalogFilterChangePS({ filterKey: "secciones", ...repeat, ...repeat2 });
      }
      setFilterValue((prev) => ({ ...prev, poligono: 0, seccion: 0 }));
    } else {
      let repeat = "";
      let filterKey = "municipios_reportes";
      let isEmpty = false;

      if (ambitoUser !== AMBITO_MPAL && typeView !== AMBITO_MPAL) {
        switch (typeView) {
          case AMBITO_REGIONAL:
            repeat = { filterType: "idRegion", filterValue: filterValue.region };
            filterKey = "municipios_reportes";
            isEmpty = filterValue.region === 0;
            break;
          case AMBITO_DF:
            repeat = { filterType: "idDF", filterValue: filterValue.distritoFederal };
            filterKey = "mpio_df";
            isEmpty = filterValue.distritoFederal === 0;
            break;
          case AMBITO_DL:
            repeat = { filterType: "idDL", filterValue: filterValue.distritoLocal };
            filterKey = "mpio_dl";
            isEmpty = filterValue.distritoLocal === 0;
            break;
          default:
            break;
        }

        if (!isEmpty) {
          // Si NO es vacio el ambito filtra por el tipo que corresponda
          handleCatalogFilterChange({ filterKey: filterKey, ...repeat });
          handleCatalogFilterChange({ filterKey: "poligonos", ...repeat });
          handleCatalogFilterChange({ filterKey: "secciones", ...repeat });
          setIdFilter(typeView);
        } else {
          // Si es vacio el ambito pone el catalogo inicial
          setCatalogFiltered((prev) => ({
            ...prev,
            [filterKey]: [defaultOption, ...catalogs[filterKey]],
            poligonos: [defaultOption, ...catalogs["poligonos"]],
            secciones: [defaultOption, ...catalogs["secciones"]],
          }));
          setIdFilter(0);
        }
      } else {
        setCatalogFiltered((prev) => ({
          ...prev,
          [filterKey]: [defaultOption, ...catalogs[filterKey]],
          poligonos: [defaultOption, ...catalogs["poligonos"]],
          secciones: [defaultOption, ...catalogs["secciones"]],
        }));
        setIdFilter(0);
      }

      setFilterValue((prev) => ({ ...prev, poligono: 0, seccion: 0 }));
    }
  };

  const handleChangeSection = (_idSection) => {
    if (_idSection > 0) setIdFilter(AMBITO_SECCIONAL);
    else {
      if (filterValue.poligono > 0) setIdFilter(AMBITO_POLIGONAL); // Ve si hay algun poligono seleccionado
      else if (filterValue.municipio > 0) setIdFilter(AMBITO_MPAL); // Ve si hay algun municipio seleccionado
      else if (filterValue.region > 0 || filterValue.distritoFederal || filterValue.distritoLocal)
        setIdFilter(typeView); // ve si hay algo seleccioando
      else setIdFilter(0);
    }
  };

  const handleSearch = () => {
    const filters = [
      { id: "s.idRegion", key: "region" },
      { id: "s.idDF", key: "distritoFederal" },
      { id: "s.idDL", key: "distritoLocal" },
      { id: "s.idMunicipioReportes", key: "municipio" },
      { id: "s.idPoligono", key: "poligono" },
      { id: "s.Seccion", key: "seccion" },
      { id: "color", key: "color" },
    ];

    const filtered = filters
      .filter((filter) => filterValue[filter.key] > 0)
      .map((filter) => ({
        id: filter.id,
        filter: "=",
        value: filterValue[filter.key],
      }));

    onSearch({ filtereds: filtered, view: typeView });


  };

  const handleClear = () => {
    const filter = {
      tipoVista: ambitoUser,
      region: 0,
      distritoFederal: 0,
      distritoLocal: 0,
      municipio: 0,
      poligono: 0,
      seccion: 0,
      color: "",
    }

    setFilterValue(filter);

    setTypeView(ambitoUser);
    setCatalogFiltered(concatDefaultOption({ ...catalogsFiltered, ...catalogs }));
    setIdFilter(0);
    onClear();
  };

  useEffect(() => {
    setCatViewFiltered(catView);
    setTypeView(catView[0]?.value);
    
    setFilterValue((prev) => ({
      ...prev,
      tipoVista: ambitoUser,
      region:  0,
      distritoFederal:   0,
      distritoLocal:   0,
      municipio:  0,
      poligono:  0,
      seccion:  0,
      color: "",
    }));

    // eslint-disable-next-line
  }, [catView]);

  useEffect(() => {
    if (refresh) handleSearch();
    // eslint-disable-next-line
  }, [refresh]);

  return (
    <Card className="card-primary" sx={{ marginBottom: "0px!important" }}>
      <CardContent>
        <Typography variant="body2" sx={{ mb: 2 }} fontWeight={700}>
          FILTROS
        </Typography>

        <Box sx={{ mt: 2, paddingX: 2 }}>
          <Grid2 container spacing={2}>
            <Grid2 xs={12} sm={changeSize ? 6 : 3} md={changeSize ? 8 : 3}  lg={changeSize ? 6 : 3}>
              <BasicSelect
                name="tipoVista"
                label="Filtrar por"
                options={catViewFiltered}
                value={filterValue.tipoVista}
                onChange={(e) => handleChangeTypeView(e.target.value)}
                sx={{ width: "100%" }}
                disabled={catViewFiltered?.length <= 1}
              />
            </Grid2>
          </Grid2>

          <Divider sx={{ mt: 1 }} />

          <Grid2 container marginTop={1} spacing={2}>
            {isIncluded([AMBITO_REGIONAL]) && (
              <Grid2 xs={12} sm={changeSize ? 6 : 3} md={changeSize ? 12 : 3}  lg={changeSize ? 6 : 3}>
                <BasicSelect
                  name="region"
                  label="Región"
                  options={catalogsFiltered.regiones}
                  value={filterValue.region}
                  onChange={(e) => {
                    handleChangeFilter({ name: e.target.name, value: e.target.value });
                    handleChangeRegion(e.target.value);
                  }}
                  isLoading={loadingCatalogs}
                  sx={{ width: "100%" }}
                  disabled={catalogsFiltered.regiones?.length <= 1}
                />
              </Grid2>
            )}

            {isIncluded([AMBITO_DF]) && (
              <Grid2 xs={12} sm={changeSize ? 6 : 3} md={changeSize ? 12 : 3}  lg={changeSize ? 6 : 3}>
                <BasicSelect
                  name="distritoFederal"
                  label="Distrito Federal"
                  options={catalogsFiltered.df}
                  value={filterValue.distritoFederal}
                  onChange={(e) => {
                    handleChangeFilter({ name: e.target.name, value: e.target.value });
                    handleChangeDfs(e.target.value);
                  }}
                  isLoading={loadingCatalogs}
                  sx={{ width: "100%" }}
                  disabled={catalogsFiltered.df?.length <= 1}
                />
              </Grid2>
            )}

            {isIncluded([AMBITO_DL]) && (
              <Grid2 xs={12} sm={changeSize ? 6 : 3} md={changeSize ? 12 : 3}  lg={changeSize ? 6 : 3}>
                <BasicSelect
                  name="distritoLocal"
                  label="Distrito Local"
                  options={catalogsFiltered.dl}
                  value={filterValue.distritoLocal}
                  onChange={(e) => {
                    handleChangeFilter({ name: e.target.name, value: e.target.value });
                    handleChangeDls(e.target.value);
                  }}
                  isLoading={loadingCatalogs}
                  sx={{ width: "100%" }}
                  disabled={catalogsFiltered.dl?.length <= 1}
                />
              </Grid2>
            )}

            {isIncluded([AMBITO_REGIONAL, AMBITO_DF, AMBITO_DL, AMBITO_MPAL, AMBITO_SECCIONAL]) && (
              <Grid2 xs={12} sm={changeSize ? 6 : 3} md={changeSize ? 12 : 3}  lg={changeSize ? 6 : 3}>
                <BasicSelect
                  name="municipio"
                  label="Municipio"
                  // options={catalog[catalogMpio] ?? []}
                  options={catalogsFiltered[catalogMpio] ?? []}
                  value={filterValue.municipio}
                  onChange={(e) => {
                    handleChangeFilter({ name: e.target.name, value: e.target.value });
                    handleChangeMunicipality(e.target.value);
                  }}
                  isLoading={loadingCatalogs}
                  sx={{ width: "100%" }}
                  disabled={catalogsFiltered[catalogMpio]?.length <= 1}
                />
              </Grid2>
            )}

            {/* {isIncluded([AMBITO_REGIONAL, AMBITO_DF, AMBITO_DL, AMBITO_MPAL]) && (
              <Grid2 xs={12} sm={6} md={6} lg={6}>
                <AdvancedSelect
                  name="poligono"
                  label="Polígono"
                  options={catalogsFiltered.poligonos}
                  value={filterValue.poligono}
                  onChange={(e) => {
                    handleChangeFilter({ name: e.name, value: e.value });
                    handleChangePolygon(e.value);
                  }}
                  isSearchable
                  isLoading={loadingCatalogs}
                  disabled={catalogsFiltered.poligonos?.length <= 1}
                />
              </Grid2>
            )} */}

            {isIncluded([AMBITO_REGIONAL, AMBITO_DF, AMBITO_DL, AMBITO_MPAL, AMBITO_SECCIONAL]) && (
              <Grid2 xs={12} sm={changeSize ? 6 : 3} md={changeSize ? 12 : 3}  lg={changeSize ? 6 : 3}>
                <AdvancedSelect
                  name="seccion"
                  label="Sección"
                  value={filterValue.seccion}
                  // options={catalog.secciones}
                  options={catalogsFiltered.secciones}
                  onChange={(e) => {
                    handleChangeFilter({ name: e.name, value: e.value });
                    handleChangeSection(e.value);
                  }}
                  isSearchable
                  isLoading={loadingCatalogs}
                  disabled={catalogsFiltered.secciones?.length <= 1}
                />
              </Grid2>
            )}
          </Grid2>

          <Stack direction={"row"} spacing={1} marginTop={2} justifyContent={"flex-end"}>
            <Button variant="outlined" color="primaryDark" size="small" onClick={handleClear}>
              Limpiar
            </Button>
            <Button variant="contained" color="primaryDark" size="small" onClick={handleSearch}>
              Filtrar
            </Button>
          </Stack>
        </Box>
      </CardContent>
    </Card>
  );
};

export default Filter;
