const MenuAddInterface = {
  Menu: "",
  Descripcion: "",
  Ver: 1,
  Agregar: 0,
  Editar: 0,
  Eliminar: 0,
  Exportar: 0,
  Importar: 0,
  Visible: 1,
  Autorizar: 0,
  Desplegable: 0,
  Ruta: "",
  Icono: "",
  TipoIcono: "mui",
  Siglas: "",
  Orden: "",
  Movil: 0,
  idPadre: "",
};

const MenuUpdateInterface = {
  id: null,
  Menu: "",
  Descripcion: "",
  Ver: 1,
  Agregar: 0,
  Editar: 0,
  Eliminar: 0,
  Exportar: 0,
  Importar: 0,
  Visible: 1,
  Desplegable: 0,
  Ruta: "",
  Icono: "",
  TipoIcono: "mui",
  Siglas: "",
  Plantilla: "admin",
  Orden: "",
  Movil: 0,
  idPadre: "",
};

const MenuAccess = {
  idMenu: "",
  Ver: 0,
  Agregar: 0,
  Editar: 0,
  Eliminar: 0,
  Exportar: 0,
  Visible: 0,
};

export { MenuAddInterface, MenuAccess, MenuUpdateInterface };
