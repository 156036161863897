import React, { useState, useEffect } from "react";

import { numberWithCommas } from "@utils/Utilities/";
import Gauge from "../Charts/Gauge";
import { blue } from "@mui/material/colors";

const ProgressCard = (props) => {
  const { data, title, firstTitle, secondTitle, showLast, lastTitle, loading } =
    props;

    const [subTitles, setSubTitles] = useState({
    first: firstTitle,
    second: secondTitle,
    last: lastTitle,
    tfirstGraph: "",
    tSecondGraph: "",
  });
  const [params, setParams] = useState({
    firstCapturados: 0,
    secondCapturados: 0,
    days: 0,
  });
  const [aditional] = useState(showLast ? showLast : false);

  useEffect(() => {
    if (aditional) {
      setSubTitles({
        ...subTitles,
        tfirstGraph: "Acomulados",
        tSecondGraph: "Esperados",
      });
      setParams({
        firstCapturados: numberWithCommas(data.historico),
        secondCapturados: numberWithCommas(data.esperado),
        days: numberWithCommas(data.diasTranscuridos),
      });
    } else {
      setSubTitles({
        ...subTitles,
        tfirstGraph: "Hoy",
        tSecondGraph: "Ayer",
      });
      setParams({
        firstCapturados: numberWithCommas(data.capturadoHoy),
        secondCapturados: numberWithCommas(data.capturadoAyer),
        days: 0,
      });
    }
  }, [props]);


  return (
    <Gauge
      data={{
        contentData: "86.1",
        leftFooterData: params.firstCapturados,
        rightFooterData: params.secondCapturados,
      }}
      config={{
        leftFooterTitle: subTitles.first,
        rightFooterTitle: subTitles.second,
        SizeCircularProgress: 140,
        ColorCircularProgress: blue[600],
        titleHeader: title,
      }}
      loading={loading}
    />
  );
};

export default ProgressCard;
