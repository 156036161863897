// Material UI
import { Stack } from "@mui/material";

// Estilos de mapas
import { style_original_data_cartography } from "@components/Maps/Auxiliars/ColorFeature";

export const onEachFeature = (feature, layer, click, params, type_map) => {
  const properties = feature.properties;

  if (properties[properties.shp] !== undefined) {
    if (properties.shp === "manzana" && type_map === "seccion") {
      layer.bindTooltip("M:" + properties.manzana + " L" + properties.Localidad, { permanent: true, direction: "center" });
      return true;
    }

    if (properties.shp === "seccion" && type_map === "poligono") {
      layer.bindTooltip("S:" + properties.seccion, { permanent: true, direction: "center" });
      return true;
    }

    if (properties.shp === "seccion" && type_map === "municipio_reportes") {
      layer.bindTooltip("S:" + properties.seccion, { permanent: true, direction: "center" });
      return true;
    }

    if (properties.shp === "poligono" && type_map === "municipio_reportes") {
      layer.bindTooltip("" + properties.Poligono, { permanent: true, direction: "center" });
      return true;
    }

    /* if (properties.shp === 'municipio_reportes' && type_map === 'region') {
      layer.bindTooltip(""+properties.municipio_reportes, { permanent: true, direction: "center" });
      return true;
    } */

    if (properties.shp === "region" && type_map === "entidad") {
      layer.bindTooltip("" + properties.region, { permanent: true, direction: "center" });
      return true;
    }

    if (properties.shp === "poligono" && type_map === "DistritoLocal") {
      layer.bindTooltip("" + properties.poligono, { permanent: true, direction: "center" });
      return true;
    }

    if (properties.shp === "poligono" && type_map === "DistritoFederal") {
      layer.bindTooltip("" + properties.poligono, { permanent: true, direction: "center" });
      return true;
    }

    layer.setStyle(style_original_data_cartography);
    layer.on({ click: click });
  }
};

export const highlightFeature = (e, params) => {
  const { resetHighlight, setFeature, L, selectedFeature, setSelectedFeature } = params;
  const layer = e.target;
  const properties = layer.feature.properties;

  if (selectedFeature) resetHighlight(selectedFeature);

  if (!properties[properties.shp]) resetHighlight(e);

  setFeature(layer.feature);
  setSelectedFeature(e);

  if (properties[properties.shp]) {
    if (!L.Browser.ie && !L.Browser.opera && !L.Browser.edge) layer.bringToFront();
    layer.setStyle(style_original_data_cartography(layer.feature, true));
  }
};

export const cardComponent = (values) => {
  const style = { width: "18px", height: "18px", float: "left", borderRadius: "9px", opacity: 0.7, marginRight: 4 };
  return (
    <Stack direction="column" justifyContent="center" alignItems="center" spacing={1} marginBottom={1}>
      <div>
        <i style={{ ...style, background: "#FF0001" }} />
        <b>Prioridad 1</b>
      </div>
      <div>
        <i style={{ ...style, background: "#FFD000" }} />
        <b>Prioridad 2</b>
      </div>
      <div>
        <i style={{ ...style, background: "#666" }} />
        <b>Prioridad 3</b>
      </div>
    </Stack>
  );
};
