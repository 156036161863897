import { Box, Typography } from "@mui/material";

import { getVars } from "@utils/global";

const ShowInfo = () => {
  const tokenData = getVars("Token");

  const casillaData = tokenData?.casilla_rc;
  const rutaData = tokenData?.ruta_rg;

  const dataToShow = casillaData ? (
    <div>
      <Typography fontWeight={600}>
        Sección: {casillaData.Casillas.Seccion}
      </Typography>
      <Typography fontWeight={600}>
        Casilla: {casillaData.Casillas.NombreCasilla}
      </Typography>
    </div>
  ) : rutaData ? (
    <div>
      <Typography fontWeight={600}>{rutaData.Ruta}</Typography>
      <Typography fontWeight={600}>{rutaData.Descripcion}</Typography>
    </div>
  ) : null;

  return (
    <Box textAlign="center" color="white">
      {dataToShow}
    </Box>
  );
};

export default ShowInfo;
