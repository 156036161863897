import Yup from "@utils/Yupi18n";
// import moment from "moment";

const StreetLocationSchema = Yup.object({
  seccion: Yup.number().required(),
  manzana: Yup.number().required(),
  tipo: Yup.number().required(),
  calle: Yup.string().required(),
  NumInicio: Yup.string().required(),
  NumFinal: Yup.string().required()
});



export { StreetLocationSchema };
