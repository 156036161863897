
//MUI
import {
  Container
} from "@mui/material";
import { grey } from "@mui/material/colors";

//Components
import AdminLayout from "@components/MainPage/AdminLayout";
const Eventos = () => {
  //Constants

  return (
    <AdminLayout>
      <Container maxWidth={false} sx={{ backgroundColor: grey }}>
        <h1>Eventos</h1>
      </Container>
    </AdminLayout>
  );
};

export default Eventos;
