import { useState, useEffect } from "react";
import Swal from "sweetalert2";

// Material UI
import { Box, Stack, Typography, Button, Icon } from "@mui/material";

// Componentes
import Filter from "@components/Prep/ReportFilter";
import Download from "@components/Downloads/Download";
import CustomTable from "@components/Tables/CustomTable";

// Servicios
import PrepServices from "@services/PrepServices";

//Utils
import { getColorReverse } from "@utils/Utilities";
import useWindowDimensions from "@hooks/useWindowDimensions";

const Reporte = () => {
  const { width } = useWindowDimensions();

  const [params, setParams] = useState(null);
  const [columns, setColumns] = useState([]);
  const [rows, setRows] = useState([]);
  const [total, setTotal] = useState(0);
  const [summary, setSummary] = useState([]);
  const [paramsTable, setParamsTable] = useState({ page: 0, pageSize: 25, filtered: [] });
  const [isLoading, setIsLoading] = useState(false);
  const [isDownload, setIsDownloading] = useState(false);

  useEffect(() => {
    if (params) getReport(params);
    //eslint-disable-next-line
  }, [params]);

  const handleChangePagination = (pagination) => {
    setParamsTable({ ...paramsTable, ...pagination });
    setParams((prevState) => ({ ...prevState, ...pagination }));
  };

  const handleRequestSort = (dataSorted) => {
    let sorted = [];
    let id = dataSorted.orderBy;

    switch (id) {
      case "TotalActas":
        id = `COUNT(vw_prep_reporte_actas.idCasilla)`;
        break;
      case "ActasRecibidas":
        id = `COUNT(IF(vw_prep_reporte_actas.TotalVotos IS NOT NULL, 1, NULL))`;
        break;
      case "PorcentajeRecibidas":
        id = `(ROUND((100 * (COUNT(IF(vw_prep_reporte_actas.TotalVotos IS NOT NULL, 1, NULL)) / COUNT(vw_prep_reporte_actas.idCasilla) )), 2))`;
        break;
      case "ActasPendientes":
        id = `(COUNT(vw_prep_reporte_actas.idCasilla) - COUNT(IF(vw_prep_reporte_actas.TotalVotos IS NOT NULL, 1, NULL)))`;
        break;
      case "PorcentajePendientes":
        id = `ROUND((100 * (1 - (COUNT(IF(vw_prep_reporte_actas.TotalVotos IS NOT NULL, 1, NULL)) / COUNT(vw_prep_reporte_actas.idCasilla)))), 2)`;
        break;
      case "TotalActasMuestreo":
        id = `COUNT(IF(vw_prep_reporte_actas.Muestra = 1, vw_prep_reporte_actas.idCasilla, NULL))`;
        break;
      case "ActasRecibidasMuestreo":
        id = `COUNT(IF(vw_prep_reporte_actas.Muestra = 1 AND vw_prep_reporte_actas.TotalVotos IS NOT NULL, 1, NULL))`;
        break;
      case "PorcentajeRecibidasMuestreo":
        id = `IFNULL((ROUND((100 * (COUNT(IF(vw_prep_reporte_actas.Muestra = 1 AND vw_prep_reporte_actas.TotalVotos IS NOT NULL, 1, NULL)) / COUNT(IF(vw_prep_reporte_actas.Muestra = 1, vw_prep_reporte_actas.idCasilla, NULL)) )), 2)), 0)`;
        break;
      case "ActasPendientesMuestreo":
        id = `(COUNT(IF(vw_prep_reporte_actas.Muestra = 1, vw_prep_reporte_actas.idCasilla, NULL)) - COUNT(IF(vw_prep_reporte_actas.Muestra = 1 AND vw_prep_reporte_actas.TotalVotos IS NOT NULL, 1, NULL)))`;
        break;
      case "PorcentajePendientesMuestreo":
        id = `IFNULL(ROUND((100 * (1 - (COUNT(IF(vw_prep_reporte_actas.Muestra = 1 AND vw_prep_reporte_actas.TotalVotos IS NOT NULL, 1, NULL)) / COUNT(IF(vw_prep_reporte_actas.Muestra = 1, vw_prep_reporte_actas.idCasilla, NULL))))), 2), 0)`;
        break;
      default:
        break;
    }

    setParams((prevState) => ({ ...prevState, sorted: [...sorted, { id: id, value: dataSorted.order }] }));
  };

  const handleChangeFilter = (values) => {
    const params = { ...values, ...paramsTable };
    setParams(params);
  };

  const getReport = async (params) => {
    setIsLoading(true);

    try {
      const result = await PrepServices.getProgressReport(params);
      const { results, message, response } = await result;

      if (results) {
        const formatedColumns = response.columns.map((item) => {
          const id = item.id;
          //Centrar todas las columnas
          let newItem = { ...item, align: 'center' };

          if (id.startsWith("Porcentaje")) {
            newItem = { ...newItem, type: "circular" };
          } else if (id.startsWith("Total") || id.startsWith("Actas")) {
            newItem = { ...newItem, type: "number" };
          }

          if (id === 'PorcentajePendientes') {
            newItem = { ...newItem, align: 'center', customColors: getColorReverse }
          }

          if (id === 'PorcentajePendientesMuestreo') {
            newItem = { ...newItem, align: 'center', customColors: getColorReverse }
          }

          return newItem;
        });

        setColumns(formatedColumns);
        setRows(response.data);
        setTotal(response.total);
        setSummary(Object.values(response.totals));
      } else throw new Error(message);
    } catch (error) {
      Swal.fire({ title: error.message, icon: "warning" });
      setColumns([]);
      setRows([]);
      setTotal(0);
      setSummary([]);
    } finally {
      setIsLoading(false);
    }
  };

  const handleExport = async () => {
    try {
      setIsDownloading(true);

      const localParams = {
        idTipoEleccion: params.idTipoEleccion,
        idAmbitoAgrupador: params.idAmbitoAgrupador,
      };

      const result = await PrepServices.exportProgressReport(localParams);
      const { success } = await result;

      if (success) Swal.fire({ title: "Descargado con éxito.", icon: "success" });
      else throw new Error("Ocurrió un error, contacte al administrador.");
    } catch (error) {
      Swal.fire({ title: error.message, icon: "warning" });
    } finally {
      setIsDownloading(false);
    }
  };

  return (
    <Box>
      <Filter onChangeFilter={handleChangeFilter} />

      <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"} marginBottom={2}>
        <Typography variant="body2" fontWeight={700}>
          REPORTES
        </Typography>
        <Button
          variant="outlined"
          color="primaryDark"
          startIcon={<Icon>download</Icon>}
          size="small"
          disabled={isDownload || isLoading}
          onClick={handleExport}
        >
          Exportar
        </Button>
      </Stack>
      {isDownload && (
        <Box marginBottom={2}>
          <Download format={"xlsx"} isDownload={isDownload} />
        </Box>
      )}

      <CustomTable
        rows={rows}
        columns={columns}
        summary={summary}
        summarySettings={{ start: 0, title: "Totales: ", align: "center" }}
        total={total}
        page={paramsTable.page}
        pageSize={paramsTable.pageSize}
        handlePagination={handleChangePagination}
        handleSort={handleRequestSort}
        stickyHeader
        maxHeight={width * 0.6}
        isLoading={isLoading}
        disableCardType
      />
    </Box>
  );
};

export default Reporte;
