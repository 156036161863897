import React, { useState } from "react";
import { Container, Button, Icon, Grid, TextField } from "@mui/material";
import Swal from "sweetalert2";
import { useFormik } from "formik";
import { isEmptyOrInvalidString } from "@utils/validations";
import BasicSelect from "@components/Selects/BasicSelect";
import { UserStatusInterface } from "@data/interfaces/UserInterfaces";
import { UserStatusSchema } from "@data/schemas/UserSchemas";
import LoadingForm from '@components/LinearProgress/LoadingForm';

const UserStatus = props => {
  const { idUsuario, API } = props;
  const formik = useFormik({
    initialValues: UserStatusInterface,
    validationSchema: UserStatusSchema,
    onSubmit: values => {
      values.id = idUsuario;
      handleSave(values);
    }
  });

  const [isLoadigForm, setIsLoadingForm] = useState(false);
  const [isSuccessForm, setIsSuccessForm] = useState(false);
  const [openLinearProgress, setOpenLinearProgress] = useState(false);

  const catalogs = [
    {
      value: 2,
      label: "Activar"
    },
    // {
    //   value: 3,
    //   label: "Suspender"
    // },
    {
      value: 4,
      label: "Bloquear"
    }
  ];

  const handleSave = async (data) => {
    setIsLoadingForm(true);
    setOpenLinearProgress(true);

    try {
      const result = await API(data);
      const { results, message } = result;

      if (results) {
        Swal.fire({
          title: message,
          icon: "success",
          allowEscapeKey: false,
          allowOutsideClick: false,
          allowEnterKey: false
        }).then((res) => {
          if (res) {
            formik.resetForm()
          }
        });
      } else {
        Swal.fire({
          title: message,
          icon: "warning"
        });
        setIsSuccessForm(false);
      }
    } catch (error) {
      Swal.fire({
        title: error.message,
        icon: "warning",
      });
      setIsSuccessForm(false);
    } finally {
      setIsLoadingForm(false);
      setOpenLinearProgress(false);
    }
  };

  const handleCleanForm = e => {
    formik.setValues(UserStatusInterface);
  };

  return (
    <Container sx={{ ml: 4, mb: 4, mt: 4 }}>
      <LoadingForm
        isLoading={isLoadigForm}
        success={isSuccessForm}
        isOpen={openLinearProgress}
        setIsOpen={() => setOpenLinearProgress(!openLinearProgress)}
        loadinMessage={'Cargando...'}
      />
      <Grid item xs={12} sm={12} sx={{ mr: 4 }}>
        <BasicSelect
          error={
            formik.touched.idEstatus &&
            !isEmptyOrInvalidString(formik.errors.idEstatus)
          }
          label="Acción"
          errorMessage={formik.errors.idEstatus}
          name="idEstatus"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.idEstatus}
          options={catalogs}
          sx={{ width: "100%" }}
        />
      </Grid>
      <Grid item xs={12} sm={12} sx={{ mr: 4, mt: 4 }}>
        <TextField
          error={
            formik.touched.Motivo &&
            !isEmptyOrInvalidString(formik.errors.Motivo)
          }
          label="Motivo"
          helperText={
            formik.touched.Motivo &&
            formik.errors.Motivo &&
            formik.errors.Motivo
          }
          type="text"
          multiline
          rows={2}
          name="Motivo"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.Motivo}
          fullWidth
          //   variant="standard"
          className="fixed-input"
        />
      </Grid>
      <Grid item xs={12} sx={{ mt: "5vh", mr: 4, textAlign: "end" }}>
        <Button
          variant="outlined"
          size="small"
          onClick={handleCleanForm}
          startIcon={<Icon>cancel</Icon>}
        >
          Cancelar
        </Button>
        <Button
          variant="contained"
          color="primaryDark"
          size="small"
          onClick={formik.submitForm}
          startIcon={<Icon>save</Icon>}
          disabled={!idUsuario}
          sx={{ ml: 1 }}
        >
          Guardar
        </Button>
      </Grid>
    </Container>
  );
};


export default UserStatus;
