import { useState, useEffect } from "react";

// Material UI
import {
  Card,
  CardContent,
  Box,
  Button,
  Grid,
  FormControl,
  //CircularProgress,
} from "@mui/material";
import Swal from "sweetalert2";

// Componentes
import FilterCollapse from "@components/Filters/FilterCollapse";
import InputSelect from "@components/Selects/BasicSelect";
import makeAnimated from "react-select/animated";
import Select from "react-select";

// Servicios
import CatalogServices from "@services/CatalogServices";
import Auditoria from "@services/AuditServices";

const FilterData = (props) => {
  const { data, setData, handleFilter } = props;

  const selectStyles = {
    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
    menu: (provided) => ({ ...provided, zIndex: "9999 !important" }),
  };
  const animatedComponents = makeAnimated();
  const [isLoading, setIsLoading] = useState(false);
  // const [region, setRegion] = useState([]);
  const [municipio, setMunicipio] = useState([]);
  const [municipioFilter, setMunicipioFilter] = useState([]);
  const [section, setSection] = useState([]);
  const [sectionFilter, setSectionFilter] = useState([]);
  //const [poligono, setPoligonos] = useState([]);
  //const [poligonoFilter, setPoligonoFilter] = useState([]);
  const [dl, setDL] = useState([]);
  const [df, setDF] = useState([]);
  // const [selectedPolygons, setSelectedPolygons] = useState([]);
  const [selectedSections, setSelectedSections] = useState([]);

  // Llamada a la api de catalogos de los selects
  const ApiResponseData = async (params) => {
    setIsLoading(true);
    try {
      const result = await CatalogServices.getCatalogs(params);
      const { results, message, response } = result;

      if (results) {
        params.forEach((item) => {
          switch (item.id) {
            /* case "regiones":
              const dataRegiones = [
                { value: 0, label: "TODOS" },
                ...response.catalogs[item.id],
              ];
              setRegion(dataRegiones);
              break; */
            case "municipios_dl":
              const dataMunicipios = [
                { value: 0, label: "TODOS" },
                ...response.catalogs[item.id],
              ];
              setMunicipio(dataMunicipios);
              setMunicipioFilter(dataMunicipios);
              break;
            /*   case "poligonos":
              setPoligonos(response.catalogs[item.id]);
              setPoligonoFilter(response.catalogs[item.id]);
              break; */
            case "secciones":
              setSection(response.catalogs[item.id]);
              setSectionFilter(response.catalogs[item.id]);
              break;
            case "df":
              const dataDF = [
                { value: 0, label: "TODOS" },
                ...response.catalogs[item.id],
              ];
              setDF(dataDF);
              break;
            case "dl":
              const dataDL = [
                { value: 0, label: "TODOS" },
                ...response.catalogs[item.id],
              ];
              setDL(dataDL);
              break;
          }
        });

        // Si solo tienen 1 dato, se poner por defecto
        setData({
          ...data,
          /* Region:
            response.catalogs["regiones"].length === 1
              ? response.catalogs["regiones"][0].value
              : 0, */
          Municipio:
            response.catalogs["municipios_dl"].length === 1
              ? response.catalogs["municipios_dl"][0].value
              : 0,
        });
      } else {
        Swal.fire({
          title: message,
          icon: "warning",
        });
      }
    } catch (errors) {
      Swal.fire({
        title: errors.message,
        icon: "warning",
      });
    } finally {
      setIsLoading(false);
    }
  };

  // Llamada a la api de DF, DL y Region (ver cuales mostrar)
  const ApiResponseFilter = async () => {
    setIsLoading(true);
    try {
      const result = await Auditoria.getScopeEval();
      const { results, message, response } = result;

      if (results) {
        const params = [
          { id: "municipios_dl", getAll: false },
          //{ id: "poligonos", getAll: false },
          { id: "secciones", getAll: false },
        ];

        // Verifica si tiene permitidos esos filtros y los agrega al objeto
        if (response.DF) params.push({ id: "df", getAll: false });
        if (response.DL) params.push({ id: "dl", getAll: false });
        //if (response.Region) params.push({ id: "regiones", getAll: false });

        // Manda a llamar la otra API
        ApiResponseData(params);
      } else {
        Swal.fire({
          title: message,
          icon: "warning",
        });
      }
    } catch (errors) {
      Swal.fire({
        title: errors.message,
        icon: "warning",
      });
    }
  };

  // Llamada a las APIS
  useEffect(() => {
    //setRegion([]);
    setDF([]);
    setDL([]);
    setMunicipioFilter([]);
    //setSelectedPolygons([]);
    setSelectedSections([]);

    ApiResponseFilter();
  }, []);

  /* useEffect(() => {
    if (poligonoFilter.length == 1) {
      setData({
        ...data,
        Poligono: poligonoFilter[0].value,
        Seccion: [],
      });
    }
  }, [poligonoFilter]); */

  useEffect(() => {
    if (sectionFilter.length == 1) {
      setData({
        ...data,
        Seccion: sectionFilter[0].value,
      });
    }
  }, [sectionFilter]);

  const handleClearClick = () => {
    setData({
      Df: 0,
      Dl: 0,
      // Region: 0,
      Municipio: 0,
      //Poligono: [],
      Seccion: [],
    });
    //setSelectedPolygons([]);
    setSelectedSections([]);
    setMunicipioFilter(municipio);
    setSectionFilter(section);
    //setPoligonoFilter(poligono);

    handleFilter("clear");
  };

  const handleChangeFederalDistrict = (event) => {
    const idDF = event.target.value;
    setData({
      ...data,
      Df: idDF,
      Municipio: 0,
      //Poligono: [],
      Seccion: [],
    });
    //setSelectedPolygons([]);
    setSelectedSections([]);
  };

  const handleChangeLocalDistrict = (event) => {
    const idDL = event.target.value;
    setData({
      ...data,
      Dl: idDL,
      Municipio: 0,
      //Poligono: [],
      Seccion: [],
    });
    //setSelectedPolygons([]);
    setSelectedSections([]);
  };

  /* const handleChangeRegion = (event) => {
    const idRegion = event.target.value;
    setData({
      ...data,
      Region: idRegion,
      Municipio: 0,
      Poligono: [],
      Seccion: [],
    });
    setSelectedPolygons([]);
    setSelectedSections([]);
    if (idRegion > 0) {
      const dataMunicipios = [
        { value: 0, label: "TODOS" },
        ...municipio.filter((item) => item.idRegion === idRegion),
      ];

      setMunicipioFilter(dataMunicipios);
      setPoligonoFilter(poligono.filter((item) => item.idRegion === idRegion));
      setSectionFilter(section.filter((item) => item.idRegion === idRegion));
    } else {
      setMunicipioFilter(municipio);
      setPoligonoFilter(poligono);
      setSectionFilter(section);
    }
  }; */

  const handleChangeMunicipio = (event) => {
    const idMunicipio = event.target.value;
    setData({
      ...data,
      Municipio: idMunicipio,
      //Poligono: [],
      Seccion: [],
    });
    //setSelectedPolygons([]);
    setSelectedSections([]);

    if (idMunicipio > 0) {
      /*  setPoligonoFilter(
        poligono.filter((item) => item.idMunicipioReportes === idMunicipio)
      ); */
      setSectionFilter(
        section.filter((item) => item.idMunicipioReportes === idMunicipio)
      );
    } else {
      //  setPoligonoFilter(poligono);
      setSectionFilter(section);
    }
  };

  /* const handleChangePoligono = (newValue) => {
    setSelectedPolygons(newValue);
    const array_poligon = newValue.map((option) => option.value || option);
    setData({
      ...data,
      Poligono: array_poligon,
    });
    if (array_poligon > 0) {
      setSectionFilter(
        section.filter((item) =>
          array_poligon.some((id) => id === item.idPoligono)
        )
      );
    } else {
      setSection(section);
    }
  };
 */
  const handleChangeSection = (newValue) => {
    setSelectedSections(newValue);
    const array_section = newValue.map((option) => option.value || option);
    setData({
      ...data,
      Seccion: array_section,
    });
  };

  const DFSize = df.length > 0;
  const DLSize = dl.length > 0;
  //const RegionSize = region.length > 0;

  return (
    <FilterCollapse expand>
      <Card elevation={0}>
        <CardContent sx={{ paddingX: { xs: 0, sm: 2 } }}>
          <Box display="flex" flexDirection="column">
            <Grid container spacing={2} alignItems="center">
              {(DFSize || DLSize) && (
                <Grid item xs={12}>
                  <Grid container spacing={2} alignItems="center">
                    {DFSize && (
                      <Grid item xs={12} sm={6} lg={6}>
                        <InputSelect
                          label="Distrito Federal"
                          options={df}
                          name="DistritoFederal"
                          value={df.length === 2 ? df[1].value : data.Df}
                          onChange={handleChangeFederalDistrict}
                          isLoading={isLoading}
                          sx={{ width: "100%" }}
                        />
                      </Grid>
                    )}
                    {DLSize && (
                      <Grid item xs={12} sm={6} lg={6}>
                        <InputSelect
                          label="Distrito Local"
                          options={dl}
                          name="DistritoLocal"
                          value={dl.length === 2 ? dl[1].value : data.Dl}
                          onChange={handleChangeLocalDistrict}
                          isLoading={isLoading}
                          sx={{ width: "100%" }}
                        />
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              )}

              {/* {RegionSize && (
                <Grid item xs={12} sm={6} lg={3}>
                  <FormControl
                    size="small"
                    sx={{ width: "100%" }}
                    className="select-box"
                  >
                    <InputSelect
                      sx={{ margin: 0 }}
                      label="Región"
                      options={region}
                      name="region"
                      value={data.Region}
                      onChange={handleChangeRegion}
                      isLoading={isLoading}
                      variant="outlined"
                      required
                    />
                  </FormControl>
                </Grid>
              )} */}
              <Grid item xs={12} sm={6} lg={6}>
                <FormControl
                  size="small"
                  sx={{ width: "100%" }}
                  className="select-box"
                >
                  <InputSelect
                    sx={{ margin: 0 }}
                    label="Municipio"
                    options={municipioFilter}
                    name="municipio"
                    value={data.Municipio}
                    onChange={handleChangeMunicipio}
                    isLoading={isLoading}
                    variant="outlined"
                    required
                  />
                </FormControl>
              </Grid>

              {/*  <Grid item xs={12} sm={6} lg={4}>
                <FormControl
                  size="small"
                  sx={{ width: "100%" }}
                  className="select-box"
                >
                  <Select
                    sx={{ margin: 0 }}
                    placeholder="Polígono"
                    closeMenuOnSelect={false}
                    components={animatedComponents}
                    value={
                      poligonoFilter.length === 1
                        ? [poligonoFilter[0]]
                        : selectedPolygons
                    }
                    onChange={handleChangePoligono}
                    isLoading={isLoading}
                    isMulti
                    options={poligonoFilter}
                    isDisabled={poligonoFilter.length === 1 ? true : false}
                  />
                </FormControl>
              </Grid> */}
              <Grid item xs={12} sm={6} lg={6}>
                <FormControl
                  size="small"
                  sx={{ width: "100%" }}
                  className="select-box"
                >
                  <Select
                    sx={{ margin: 0 }}
                    placeholder="Sección"
                    styles={selectStyles}
                    menuPortalTarget={document.body}
                    closeMenuOnSelect={false}
                    components={animatedComponents}
                    value={
                      sectionFilter.length === 1
                        ? [sectionFilter[0]]
                        : selectedSections
                    }
                    onChange={handleChangeSection}
                    isLoading={isLoading}
                    isMulti
                    options={sectionFilter}
                    isDisabled={section.length === 1 ? true : false}
                  />
                </FormControl>
              </Grid>

              <Grid
                item
                xs={12}
                sx={{
                  display: "flex",
                  gap: { xs: 1, sm: 2 },
                  justifyContent: "flex-end",
                }}
              >
                <Button
                  variant="outlined"
                  sx={{ paddingX: { xs: 5, sm: "auto" } }}
                  onClick={handleClearClick}
                  disabled={isLoading}
                  size="small"
                  color="primaryDark"
                >
                  Limpiar
                </Button>
                <Button
                  onClick={() => handleFilter("filter")}
                  variant="contained"
                  sx={{ paddingX: { xs: 5, sm: "auto" } }}
                  disabled={isLoading}
                  size="small"
                  color="primaryDark"
                >
                  Filtrar
                </Button>
              </Grid>
            </Grid>
          </Box>
        </CardContent>
      </Card>
    </FilterCollapse>
  );
};

export default FilterData;
