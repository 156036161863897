import { useState } from "react";

import {
  Table,
  TableContainer,
  Card,
  Typography,
  Button,
  CardContent,
  Alert,
  Divider,
  CardActions,
} from "@mui/material";

import TableHead from "./TableHead";
import TableBody from "./TableBody";

import moment from "moment";

const ListMobilization = ({
  data,
  users,
  dataFound,
  setUsers,
  setMovilization,
}) => {
  const [selectAll, setSelectAll] = useState(false);

  const handleSelectAll = () => {
    if (!selectAll) {
      const notSelectedRows = data.filter((row) => row.esPaseLista !== 1);
      const newSelectedRows = notSelectedRows.map((row) => ({
        id: row.idUsuario,
        fechaHora: moment().format("HH:mm"),
      }));

      setUsers(newSelectedRows);
    } else {
      setUsers([]);
    }

    setSelectAll(!selectAll);
  };

  const handleSelectRow = (idUsuario) => {
    const selectedRow = users.find((row) => row.id === idUsuario);

    if (selectedRow) {
      setUsers((prevSelectedRows) =>
        prevSelectedRows.filter((row) => row.id !== idUsuario)
      );
    } else {
      setUsers((prevSelectedRows) => [
        ...prevSelectedRows,
        { id: idUsuario, fechaHora: moment().format("HH:mm") },
      ]);
    }
  };

  return (
    <Card className="card-primary">
      <CardContent>
        <Typography fontWeight={600} variant="subtitle2">
          USUARIOS
        </Typography>
        <Divider />

        {users.length > 0 && (
          <Alert severity="info" sx={{ mt: 1 }}>
            {users.length} usuarios seleccionados
          </Alert>
        )}

        <TableContainer sx={{ marginTop: 2, borderRadius: 3 }}>
          <Table>
            <TableHead
              data={data}
              handleSelectAll={handleSelectAll}
              selectAll={selectAll}
              indeterminate={users.length > 0 && users.length < data.length}
            />
            <TableBody
              data={data}
              dataFound={dataFound}
              users={users}
              handleSelectRow={handleSelectRow}
            />
          </Table>
        </TableContainer>
      </CardContent>

      {data.length > 0 && (
        <CardActions sx={{ justifyContent: "center" }}>
          <Button
            variant="contained"
            color="primaryDark"
            onClick={() => setMovilization({ body: users })}
          >
            Guardar
          </Button>
        </CardActions>
      )}
    </Card>
  );
};

export default ListMobilization;
