import {ApiExec,downloadFile} from "@utils/ApiExec";
import { encryptVars, DEBUG } from "@utils/global";

class FrenteAmplioServices {


  getList = (params) => {
    return ApiExec(params, "compromisos/get-my-records-fa", "POST")
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };

  getHistory = (params) => {
    return ApiExec(params, "compromisos/get-my-history-fa", "POST")
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  }; 

  getCapturistas = (params) => {
    return ApiExec(params, "compromisos/get-list-capturers-fa", "POST")
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };
 
  getPendientes = (params) => {
    return ApiExec(params, "compromisos/get-list-missing-records", "POST")
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };

  create = (params) => {
    return ApiExec(params, `compromisos/create-fa`, "POST")
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };

  update = (params) => {
    return ApiExec(params, "compromisos/update-fa", "PUT")
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };

  getFind = (id) => {
    const _id = DEBUG ? id : encryptVars(id);
    return ApiExec({}, `compromisos/find-my-record-fa/${_id}`, "GET")
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };

  getResponsibles = (params) => {
    return ApiExec(params, "compromisos/get-responsiblesFA", "POST")
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };

  getLitStats = (params) => {
    return ApiExec(params, "compromisos/get-stats-fam", "POST")
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };


  getChartCaptura = (params) => {
    return ApiExec(params, "compromisos/get-stats-compromisos-dia-fam", "POST")
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };

  getChartCandidatos = (params) => {
    return ApiExec(params, "compromisos/get-stats-candidato-fam", "POST")
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };

  getListLiderazgoz = (params) => {
    return ApiExec(params, "compromisos/get-stats-liderazgo-fam", "POST")
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };


  getListMovilizacion = (params) => {
    return ApiExec(params, "compromisos/get-mobilized", "POST")
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };

  getCatalogsFA = (params) => {
    return ApiExec(params, "compromisos/get-catalogs-mobilized", "POST")
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };  

  exportMovilizacion  = (params) => {
    return downloadFile({
      api: "compromisos/export-mobilized",
      method: "POST",
      data: params,
    })
      .then((res) => res)
      .catch((e) => e);
  };

  getBingos = (params) => {
    return ApiExec(params, "movilization/get-bingos-list-fam", "POST")
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  }; 

  setBingo = (params) => {
    return ApiExec(params, `movilization/set-bingo-fam`, "POST")
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };

  searchCompromisos = (params) => {
    return ApiExec(params, "movilization/get-bingos-compromisos-list-fam", "POST")
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  }; 



  getListMovilizacionCDM = (params) => {
    return ApiExec(params, "compromisos/get-mobilized-cdm", "POST")
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };

  getCatalogsFACDM = (params) => {
    return ApiExec(params, "compromisos/get-catalogs-mobilized-cdm", "POST")
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };  

  exportMovilizacionCDM  = (params) => {
    return downloadFile({
      api: "compromisos/export-mobilized-cdm",
      method: "POST",
      data: params,
    })
      .then((res) => res)
      .catch((e) => e);
  };

  getListStastLiderazgo = (params) => {
    return ApiExec(params, "compromisos/get-list-leaderships", "POST")
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };

  setMovilizacion = (params) => {
    return ApiExec(params, `movilization/set-list-fam`, "POST")
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };

  getListMovilizadores = (params) => {
    return ApiExec(params, "movilization/get-my-list-fam", "POST")
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };


  ExportMyListMovilizacion  = (params) => {
    return downloadFile({
      api: "movilization/export-my-list-fam",
      method: "POST",
      data: params,
    })
      .then((res) => res)
      .catch((e) => e);
  };

  getMyListMovilizacion = (params) => {
    return ApiExec(params, "movilization/get-my-list-to-mobilize", "POST")
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };

  setUpdateStatus = (params) => {
    return ApiExec(params, `movilization/set-status-mobilized-fam`, "POST")
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };

}

const frente = new FrenteAmplioServices();
export default frente;
