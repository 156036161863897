import ExcelJS from "exceljs";
import Swal from "sweetalert2";
import { removeDiacritics } from "@utils/Utilities"

export const processExcelFile = async (file, startRow, sheet = 0) => {
  try {
    const workbook = new ExcelJS.Workbook();
    const reader = new FileReader();

    const loadExcel = () => {
      return new Promise((resolve, reject) => {
        reader.onload = async (event) => {
          try {
            const buffer = event.target.result;
            await workbook.xlsx.load(buffer);
            const worksheet = workbook.worksheets[sheet];

            const jsonData = [];
            let headers = [];
            const error = [];
            let previousRowNumber = startRow - 1;

            let rowCount = 0;
            worksheet.eachRow({ includeEmpty: false }, (row, rowNumber) => {
              if (rowNumber >= startRow) {
                rowCount++;
              }
            });

            if (rowCount > 2000) {
              Swal.fire({
                icon: "error",
                title: "Ocurrió un inconveniente al validar los datos",
                text: "El archivo debe contener menos o igual a 1000 elementos",
              });
              resolve({ data: [], error: [] });
              return;
            }

            worksheet.eachRow({ includeEmpty: false }, (row, rowNumber) => {
              if (rowNumber === previousRowNumber) {
                headers = row.values.slice(1).map((header) => header.trim());
              } else if (rowNumber >= startRow) {
                const rowData = {};
                row.eachCell({ includeEmpty: false }, (cell, colNumber) => {
                  const header = headers[colNumber - 1];
                  let cellValue = cell.value ? cell.value.toString().trim() : "";
                  if (header === "Sección") {
                    if (!/^\d+$/.test(cellValue)) {
                      error.push({
                        row: rowNumber,
                        column: "Seccion",
                        message: `'Sección' debe ser un número y no puede estar vacío.`,
                      });
                      cellValue = cellValue;
                    } else {
                      cellValue = parseInt(cellValue);
                    }
                  } else if (
                    header === "Nombre" ||
                    (header === "Paterno" && cellValue !== "" && cellValue !== null && cellValue !== undefined) ||
                    (header === "Materno" && cellValue !== "" && cellValue !== null && cellValue !== undefined)
                  ) {
                    cellValue = cellValue.trim(); // Eliminar espacios al inicio y final
                    cellValue = cellValue.toUpperCase(); // Convertir a mayúsculas
                  
                    // Validar sin eliminar diacríticos
                    if (!/^[A-ZÁÉÍÓÚÑÜ\s.]{2,}$/.test(cellValue)) {
                      error.push({
                        row: rowNumber,
                        column: header,
                        message: `El campo '${header}' debe contener solo letras y tener una longitud mínima de 2 caracteres.`,
                      });
                    }
                  }else if( header === 'Celular'){
                    cellValue = cellValue.replace(/\+s/, '').replace('-', '');
                  }
                  rowData[header] = cellValue;
                });

                rowData["NombreCompleto"] = `${rowData["Nombre"]} ${rowData["Paterno"] || "XX"} ${rowData["Materno"] || "XX"}`?.toLocaleUpperCase();

                const requiredFields = ["Nombre", "Paterno", "Materno", "Celular", "Sección"];
                requiredFields.forEach((field) => {
                  if (!(field in rowData) || rowData[field].length < 1) {
                    if (field === "Paterno" || field === "Materno") {
                      rowData[field] = "XX";
                    }else if(field === 'Celular'){
                      rowData[field] = '9876543210';
                    }
                    else {
                      rowData[field] = "";
                      error.push({
                        row: rowNumber,
                        column: field,
                        message: `El campo '${field}' es obligatorio y se agregó con un valor vacío.`,
                      });
                    }
                  }
                });
                if (!/^[2-9]{1}[1-9]{1}[0-9]{8}$/.test(rowData["Celular"])) {
                  // error.push({
                  //   row: rowNumber,
                  //   column: "Celular",
                  //   message: `El campo 'Celular' debe ser un número de 10 dígitos.`,
                  // });
                  rowData["Celular"] = '9876543210';
                }
                jsonData.push(rowData);
              }
            });

            resolve({ data: jsonData, error });
          } catch (err) {
            reject({ data: [], error: [{ message: "Error procesando las filas del archivo Excel" }] });
          }
        };

        reader.onerror = (error) => {
          reject({ data: [], error: [{ message: "Error al leer el archivo" }] });
        };

        reader.readAsArrayBuffer(file);
      });
    };

    return await loadExcel();
  } catch (e) {
    return { data: [], error: [{ message: "Error al procesar el archivo" }] };
  }
};
