import { useState, useEffect } from "react";
import Swal from "sweetalert2";

// Material UI
import { Card, CardContent, Stack, Grid, Typography } from "@mui/material";

// Componentes
import CustomTable from "@components/Tables/CustomTable";
import BasicSelect from "@components/Selects/BasicSelect";

// Middlewares ,Servicios, Utils
import { setDiynamicColumns } from "@utils/Utilities";

// Constantes
import { COLUMNS_MOVILIZADOS } from "@data/constants/Movilizadores";

import {
  AMBITO_REGIONAL,
  AMBITO_DF,
  AMBITO_DL,
  AMBITO_MPAL,
  AMBITO_SECCIONAL,
} from "@data/constants";

import movilizadores from "@services/MovilizadoresServices";

import { green, orange, yellow, red } from '@mui/material/colors';

const TableMobilization = (props) => {
  const { data, catView, idAmbitoAgrupador, ambitoUser, idFilter, setDataChart, setTotalCharts, setLoading, loading } = props;

  const [localData, setLocalData] = useState(data);
  const [ambitoAgrupador, setAmbitoAgrupador] = useState(ambitoUser);

  const [catViewFiltered, setCatViewFiltered] = useState([]);

  const [orderBy, setOrderBy] = useState("");

  const [total, setTotal] = useState(10);
  const [summary, setSummary] = useState([]);
  const [columnsDinamics, setColumnsDinamics] = useState([]);
  const [dataMovilization, setDataMovilization] = useState([]);

  const getMovilization = async (data) => {
    const { page, pageSize, ...prev } = data
    if (data.idAmbitoAgrupador === '') return;
    setLoading(true);

    try {
      const res = await movilizadores.getTableDetalleGral(prev);
      const { results, response, message } = res;

      if (results) {
        setTotal(response.total);

        setDataMovilization(response.data);
        const categories = response.data.map((item) => item?.Ambito)

        const dataChart = [
          {
            name: "Votos Afines",
            data: [],
            color: green[200],
            Meta: [],
            id: "VotantesAfines"
          },
          {
            name: "Voto Ciudadano",
            data: [],
            color: red[200],
            Meta: [],
            id: "NoSimpatizantes"
          },
          {
            name: "Movilizado",
            data: [],
            color: orange[200],
            Meta: [],
            id: "TotalMovilizados"
          },
          {
            name: "Promovidos Afines",
            data: [],
            color: yellow[300],
            Meta: [],
            id: "Afines"
          },
        ]

        const series = dataChart.map((item) => {

          const data = response.data.map(sub => parseFloat(sub[item.id]))

          const Meta = Array(data.length).fill(100);

          /*
          VotantesAfines
          TotalMovilizados
          NoSimpatizantes
          TotalVotantes
          */
          return {
            ...item,
            data,
            Meta,
          }
        })

        handleMobilizationData(response)
        setDataChart({ categories, series })
        setTotalCharts(response.totales)
      } else throw new Error(message);
    } catch (error) {
      setDataChart({ categories: [], series: [] })
      setTotalCharts({ Afines: 0, VotantesAfines: 0, PorcentajeAfines: 0, TotalMovilizados: 0, PorcentajeMovilizados: 0 })
      setTotal(0);
      setSummary([]);
      setColumnsDinamics([]);
      setDataMovilization([]);
      Swal.fire({ title: error.message, icon: "warning" });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (localData.sorted.length) {
      setOrderBy(localData.sorted[0].id);
    } else setOrderBy("");
  }, [localData]);


  const handleMobilizationData = ({ data }) => {
    const dataDinamic = data.map((item) => {
      return item;
    });

    const columns = setDiynamicColumns({ data: data[0], columns: [...COLUMNS_MOVILIZADOS] });

    const columnas = [
      ...columns,
    ];

    setColumnsDinamics(columnas);
    setDataMovilization(
      dataDinamic.map((item) => ({ ...item }))
    );
  };

  useEffect(() => {
    // se asegura que ya haya cargado el ambito
    if (ambitoUser) {
      let values = [AMBITO_MPAL, AMBITO_SECCIONAL];
      let defaultValue = false;

      switch (idFilter) {
        case 1:
          values = [1, AMBITO_REGIONAL, AMBITO_DF, AMBITO_DL, AMBITO_MPAL, AMBITO_SECCIONAL];
          break;
        case AMBITO_REGIONAL:
          values = [AMBITO_REGIONAL, AMBITO_MPAL, AMBITO_SECCIONAL];
          break;
        case AMBITO_DF:
          values = [AMBITO_DF, AMBITO_MPAL, AMBITO_SECCIONAL];
          break;
        case AMBITO_DL:
          values = [AMBITO_DL, AMBITO_MPAL, AMBITO_SECCIONAL];
          break;
        case AMBITO_MPAL:
          values = [AMBITO_MPAL, AMBITO_SECCIONAL];
          break;
        case AMBITO_SECCIONAL:
          values = [AMBITO_SECCIONAL];
          break;
        default:
          defaultValue = true;
          break;
      }

      // Si es no es default deja los items principales seleccionados
      let catalogs = [];
      if (!defaultValue) catalogs = catView.filter((item) => values.includes(item.value));
      else
        catalogs = catView.filter((item) => item.value === idAmbitoAgrupador || values.includes(item.value));

      setCatViewFiltered(catalogs);

      const agrupadorLocal = catalogs[0]?.value ?? "";
      setAmbitoAgrupador(agrupadorLocal);

      setLocalData({ ...data, page: 0, pageSize: 10, idAmbitoAgrupador: agrupadorLocal });
    }
    // eslint-disable-next-line
  }, [data]);

  useEffect(() => {
    if (ambitoUser) getMovilization(localData);
    // eslint-disable-next-line
  }, [localData]);

  // Actualiza el ambito agrupador local y el select
  useEffect(() => {
    if (ambitoUser) {
      const ambito = idAmbitoAgrupador ?? ambitoUser;

      // Define los dos arrays de valores
      const values = [AMBITO_MPAL, AMBITO_SECCIONAL];
      const values2 = [AMBITO_REGIONAL, AMBITO_DF, AMBITO_DL, AMBITO_MPAL, AMBITO_SECCIONAL];

      // Selecciona el array de valores adecuado en función del valor de ambito
      const selectedValues = (ambito === 1) ? values2 : values;

      // Filtra catView usando el array de valores seleccionado
      const filtered = catView.filter((item) => item.value === ambito || selectedValues.includes(item.value));

      // Actualiza el estado con el resultado filtrado
      setCatViewFiltered(filtered);
      setAmbitoAgrupador(filtered[0]?.value ?? "");
    }
    // eslint-disable-next-line
  }, [idAmbitoAgrupador]);


  const handleChangeAmbito = (value) => {
    setAmbitoAgrupador(value);
    setLocalData((prev) => ({
      ...prev,
      page: 0,
      pageSize: 10,
      idAmbitoAgrupador: value,
      sorted: [],
    }));
  };

  return (
    <>
      <Card className="card-primary" sx={{ marginBottom: "0px!important" }}>
        <CardContent>
          <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"} marginBottom={2}>
            <Typography variant="body2" fontWeight={700}>
              Estado
            </Typography>
          </Stack>

          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={4}>
              <BasicSelect
                name="agrupador"
                label="Detalle por"
                options={catViewFiltered}
                value={ambitoAgrupador}
                onChange={e => handleChangeAmbito(e.target.value)}
                sx={{ width: "100%" }}
                disabled={catViewFiltered?.length <= 1}
              />
            </Grid>
          </Grid>

          <br />

          <CustomTable
            rows={dataMovilization}
            columns={columnsDinamics}
            total={total}
            orderBy={orderBy}
            page={data.page}
            pageSize={data.pageSize}
            isLoading={loading}
            summary={summary}
            summarySettings={{ start: 100, align: "center", }}
            disableCardType
          />
        </CardContent>
      </Card>
    </>
  );
};

export default TableMobilization;

