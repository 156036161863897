import React, { useState } from "react";

import {
  Grid,
  Icon,
  TextField,
  IconButton,
  InputAdornment
} from "@mui/material";
import {
  isEmptyOrInvalidString,
  isTypePhone,
} from "@utils/validations";

const AccessDataForm = (props) => {
  const {
    errors,
    touched,
    values,
    handleChange,
  } = props;

  const [flags, setFlags] = useState({
    showPassword: false,
    showConfirmPassword: false,
  });


  const handleClickShowPassword = (prop) => (event) => {
    setFlags({ ...flags, [prop]: !flags[prop] });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <>
      <Grid item xs={12} sm={12} md={6} lg={6}>
        <TextField
          error={
            touched.Celular &&
            !isEmptyOrInvalidString(errors.Celular)
          }
          helperText={
            touched.Celular &&
            errors.Celular &&
            errors.Celular
          }
          sx={{ width: "100%" }}
          required
          label="Telefono Celular"
          type="text"
          name="Celular"
          onChange={(e) =>
            handleChange({
              target: {
                name: "Celular",
                value: isTypePhone(e.target.value)
                  ? e.target.value
                  : values.Celular,
              },
            })
          }
          onBlur={(e) =>
            handleChange({
              target: {
                name: "Celular",
                value: isTypePhone(e.target.value)
                  ? e.target.value
                  : values.Celular,
              },
            })
          }
          value={values.Celular}
          variant="outlined"
          size="small"
          className="fixed-input"
        />
      </Grid>
      <Grid item xs={12} sm={12} md={6} lg={6}>
        <TextField
          error={
            touched.Correo &&
            !isEmptyOrInvalidString(errors.Correo)
          }
          label="Correo Electrónico"
          helperText={
            touched.Correo &&
            errors.Correo &&
            errors.Correo
          }
          type="text"
          name="Correo"
          onChange={handleChange}
          //onBlur={formik.handleBlur}
          value={values.Correo}
          size="small"
          variant="outlined"
          className="fixed-input"

        />
      </Grid>
      <Grid item xs={12} sm={12} md={6} lg={6}>
        <TextField
          error={
            touched.password &&
            !isEmptyOrInvalidString(errors.password)
          }
          label="Contraseña *"
          helperText={
            touched.password &&
            errors.password &&
            errors.password
          }
          type={flags.showPassword ? "text" : "password"}
          name="password"
          onChange={handleChange}
          //onBlur={handleBlur}
          value={values.password}
          size="small"
          variant="outlined"
          className="fixed-input"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword("showPassword")}
                  onMouseDown={handleMouseDownPassword}
                >
                  {flags.showPassword ? (
                    <Icon>visibility_off</Icon>
                  ) : (
                    <Icon>visibility</Icon>
                  )}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={6} lg={6}>
        <TextField
          error={
            touched.confirm &&
            !isEmptyOrInvalidString(errors.confirm)
          }
          label="Confirmar contraseña *"
          helperText={
            touched.confirm &&
            errors.confirm &&
            errors.confirm
          }
          type={flags.showConfirmPassword ? "text" : "password"}
          name="confirm"
          onChange={handleChange}
          //onBlur={handleBlur}
          value={values.confirm}
          size="small"
          variant="outlined"
          className="fixed-input"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword("showConfirmPassword")}
                  onMouseDown={handleMouseDownPassword}
                >
                  {flags.showConfirmPassword ? (
                    <Icon>visibility_off</Icon>
                  ) : (
                    <Icon>visibility</Icon>
                  )}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Grid>
    </>
  );
};
export default AccessDataForm;
