import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

// Material UI
import { Container, Icon, IconButton, Button, Stack, Typography, Tooltip } from "@mui/material";

// Utils y Middleware
import { getVars } from "@utils/global";
import ApiExec from "@utils/ApiExec";
import middleware from "@middlewares/middleware";

const Navbar = (props) => {
  const {
    title = "",
    screenBack = "",
    isScreen,
    enableLogout = false,
    disableGoHome = false,
    setTypeScreen = () => {},
    color = "white",
  } = props;

  const navigate = useNavigate();

  const {
    user: { Nombre, Paterno, Materno },
  } = getVars("Token");

  const handleGoToHome = () => setTypeScreen("welcome");

  const handleGoToBack = (screen) => setTypeScreen(screen);

  const handleLogout = async () => {
    const url_redirect = middleware.getLoginUrl();
    const api_url = middleware.isNationalUser() ? "auth/national/logout" : "auth/logout";
    try {
      const result = await ApiExec({}, api_url);

      if (result.results) {
        sessionStorage.clear();
        navigate(url_redirect);
      } else throw new Error(result.message);
    } catch (error) {
      Swal.fire({ title: error.message, icon: "warning" });
    }
  };

  return (
    <Container>
      <Stack direction="row" justifyContent="space-between" alignItems="center" color={color} minHeight={40}>
        {!isScreen ? (
          <Typography variant="body2" fontWeight={600}>
            Hola {Nombre} {Paterno} {Materno}
          </Typography>
        ) : (
          <Tooltip title="Regresar" disableInteractive arrow>
            <Button
              onClick={() => handleGoToBack(screenBack)}
              startIcon={<Icon sx={{ color: color }}>keyboard_arrow_left</Icon>}
              sx={{ textTransform: 'none' }}
            >
              <Typography color={color}>{title}</Typography>
            </Button>
          </Tooltip>
        )}
        {(!disableGoHome || enableLogout) && (
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            {!disableGoHome && (
              <Tooltip title="Ir al Inicio" disableInteractive arrow>
                <IconButton onClick={handleGoToHome}>
                  <Icon sx={{ color: color }}>home</Icon>
                </IconButton>
              </Tooltip>
            )}
            {enableLogout && (
              <Tooltip title="Cerrar Sesión" disableInteractive arrow>
                <IconButton onClick={handleLogout}>
                  <Icon sx={{ color: color }}>logout</Icon>
                </IconButton>
              </Tooltip>
            )}
          </Stack>
        )}
      </Stack>
    </Container>
  );
};

export default Navbar;
