import Yup from "../../utils/Yupi18n";

const SocialAddSchema = Yup.object({
    NombreSocial: Yup.string().min(5).required(),
    "Alias": Yup.string().optional()
})

const SocialEditSchema = Yup.object({
    id:Yup.number().min(1).required(),
    NombreSocial: Yup.string().min(5).required(),
    "Alias": Yup.string().optional()
})

export {
    SocialAddSchema,
    SocialEditSchema
}