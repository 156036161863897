const FrenteAddInterface = {
  flagOtro:false,
  idCompromisoPropietario: "",
  idUsuarioPropietario: "",
  idUsuarioResponsable: "",
  PublicoGeneral:0,
  InvitadoPor:null,
  insertID:"",
  idPartido: 1,
  INE: "",
  VigenciaINE: "",
  Nombre: "",
  Paterno: "",
  Materno: "",
  FechaNacimiento: "",
  idSexo: "",
  idEstadoNacimiento: "",
  CalleVive: "",
  TipoVialidad: "C",
  NumExtVive: "",
  NumIntVive: "",
  idMunicipioVive: "",
  idLocalidadVive: "",
  ColoniaVive: "",
  CPVive: "",
  SeccionVota: "",
  Celular: "",
  Telefono: "",
  TelMensajes: "",
  Correo: "",
  Facebook: "",
  Instagram: "",
  Twitter: "",
  TikTok: "",
  EsVoluntario: 0,
  idVoluntario: null,
  TieneAuto: null,
  TieneExperiencia: null,
  AceptaCuidarOtra: null,
  Segmentos: [],
  idMedioContacto: null,
  FrontIne:null,
  BehindIne:null
};


const FilterFrente = {
  celular: "",
  nombre: "",
  Correo: "",
  municipio:0,
};

const BingofaInterface = {
idMunicipio:{value:0,label:"Seleccione"},
Hoja:0,
Bingo:""
};

const AsignacionInterface = {
  Municipio:{},
};

export {
  FrenteAddInterface,
  FilterFrente,
  BingofaInterface,
  AsignacionInterface
};
