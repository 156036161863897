import { useState } from "react";

// Material UI
import { Grid, ListItem, ListItemText, ListItemButton, Divider } from "@mui/material";

// Componentes
import AdminLayout from "@components/MainPage/AdminLayout";
import ActionHeader from "@components/Containers/ActionHeader";
import LoadingForm from "@components/LinearProgress/LoadingForm";
import BasicListDoble from "@components/Lists/BasicListDoble";
import UserForm from "@components/DiaD/UserForm";
import UserInfo from "@components/DiaD/UserInfo";

// Utilidades
import { isTypePhone } from "@utils/validations";

// Servicios
import DiaDServices from "@services/DiaDServices"

const Item = ({ item, index, selected, handleSelected }) => {
  return (
    <>
      <ListItem disablePadding>
        <ListItemButton selected={selected === index} onClick={() => handleSelected(item, index)}>
          <ListItemText primary={`${item.NombreCompleto} - (${item.Edad} años)`} secondary={item.email} />
        </ListItemButton>
      </ListItem>

      <Divider variant="inset" component="li" />
    </>
  );
};

const Assignments = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState("Cargando datos...");
  const [userParams, setUserParams] = useState({});
  const [userData, setUserData] = useState([]);
  const [permisos, getPermisos] = useState()

  const handleClick = (data) => setUserParams(data);

  const handleClear = () => setUserParams({});

  return (
    <AdminLayout>
      <LoadingForm loadinMessage={loadingMessage} isLoading={isLoading} isOpen={isLoading} setIsOpen={() => {}} />

      <ActionHeader title={"Permisos a Usuarios Dia D"} isCustom />

      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={4}>
          <BasicListDoble
            API={DiaDServices.getPeopleToAssign}
            APIKeys={[
              { id: ["usuarios.Nombre", "usuarios.Paterno", "usuarios.Materno"], filter: "LIKE" },
              { id: "usuarios.Username", filter: "=" },
            ]}
            header={{ title: "Usuarios disponibles", icon: "people" }}
            handleClick={handleClick}
            handleClear={handleClear}
            label={"Encuentra y elige al usuario para la asignación de permisos"}
            labelNote={"Nota: Para realizar una búsqueda debe ingresar un nombre o por el contrario un número de celular."}
            itemComponent={Item}
            finder
            inputValidation={{ input2: isTypePhone }}
            lengthValidation={{ input2: { filter: "=", value: 10 } }}
            doubleSearch
            breaks={{ sm: 6 }}
            buttonsBreaks={{ sm: 3, md: 6 }}
            config={{ height: 250 }}
            clearData
          />
        </Grid>

        <Grid item xs={12} sm={12} md={5}>
          <UserForm
            params={userParams}
            getPermisos={getPermisos}
            setLoading={(load) => {
              setIsLoading(load);
            }}
          />
        </Grid>

        <Grid item xs={12} sm={12} md={3}>
          <UserInfo
            params={userParams}
            permisos={permisos}
            setLoading={(load) => {
              setIsLoading(load);
            }}
          />
        </Grid>
      </Grid>
    </AdminLayout>
  );
};

export default Assignments;
