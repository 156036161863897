import ApiExec from "@utils/ApiExec";

class AuthServices {
  login = (params) => {
    return ApiExec(params, "auth/login", "POST")
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };

  logout = (params) => {
    return ApiExec(params, "auth/logout", "POST")
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };
}

const auth = new AuthServices();
export default auth;
