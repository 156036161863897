import Yup from "@utils/Yupi18n";

const CapturaSchema = Yup.object({
  //seccion: Yup.string().optional(),
  //municipio: Yup.string().optional(),
  casilla: Yup.number().required().label("casilla"),
  //reportaRCRG: Yup.number().required().label("reporta RC/RG"),
  //idUser: Yup.number().optional(),
  hora: Yup.string()
    .required()
    .matches(
      /^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/,
      "Ingrese una hora válida"
    )
    .label("Hora")
    .nullable(true),
});

const CapturaOpeningSchema = Yup.object({
  casilla: Yup.number().label("casilla"),
  NumFuncionarios: Yup.string().required().label("Número de funcionarios"),
  TomadosFila: Yup.string().required().label("Número tomados de la fila"),
  hora: Yup.string()
    .required()
    .matches(
      /^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/,
      "Ingrese una hora válida"
    )
    .label("Hora")
    .nullable(true),
});

const CapturaPaseListaSchema = Yup.object({
  casilla: Yup.number().required().label("casilla"),
  hora: Yup.string()
    .required()
    .matches(
      /^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/,
      "Ingrese una hora válida"
    )
    .label("Hora")
    .nullable(true),
});

const capturaIncidenciaSchema = Yup.object({
  idCasilla: Yup.number().required().label("Casilla"),
  idIncidencia: Yup.number().required().label("Incidencia"),
  DescripcionIncidencia: Yup.string()
    .required()
    .label("Descripción de la incidencia"),
  HoraIncidencia: Yup.string()
    .required()
    .matches(
      /^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/,
      "Ingrese una hora válida"
    )
    .label("Hora")
    .nullable(true),
  esResuelto: Yup.number().required(),
  ComoSeResolvio: Yup.string()
    .when("esResuelto", {
      is: 1,
      then: Yup.string().required(),
      otherwise: Yup.string().optional().nullable(true),
    })
    .label("Descripcion de la solución"),
  HoraSolucion: Yup.string()
    .matches(
      /^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/,
      "Ingrese una hora válida"
    )
    .when("esResuelto", {
      is: 1,
      then: Yup.string().required(),
      otherwise: Yup.string().optional().nullable(true),
    })
    .label("Hora")
    .nullable(true),
});

export {
  CapturaSchema,
  capturaIncidenciaSchema,
  CapturaPaseListaSchema,
  CapturaOpeningSchema,
};
