import { useEffect, useState } from "react";
import { useSorted } from "@hooks/useSorted";

//MUI
import {
  Button,
  Card,
  CardContent,
  Container,
  Stack,
  Typography,
  Icon,
  ListItemText,
  Chip,
  Box,
} from "@mui/material";
import { green, red } from "@mui/material/colors";

//Components
import AdminLayout from "@components/MainPage/AdminLayout";
import ActionHeader from "@components/Containers/ActionHeader";
import Download from "@components/Downloads/Download";
import Filter from "@components/Sije/Directory/Filter";
import ModalDetailsSection from "@components/Sije/Directory/ModalDetailsSection";
import ModalDetailsCasilla from "@components/Sije/Directory/ModalDetailsCasilla";
import LoadingForm from "@components/LinearProgress/LoadingForm";
import CustomTable from "@components/Tables/CustomTable";
import ContactsStack from "@components/ContactsStack/ContactsStack";

//Services
import { COLUMNS_DIRECTORY } from "@data/constants/Sije";
import sije from "@services/SijeService";
import Swal from "sweetalert2";

const Directory = () => {
  const [dataDirectory, setDataDirectory] = useState([]);
  const [dataDirectoryFilter, setDataDirectoryFilter] = useState([]);
  //const [loadingData, setLoadingData] = useState(false);
  const [isDownload, setIsDownloading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [openModalCasilla, setOpenModalCasilla] = useState(false);
  const [loadingDetailSection, setLoadingDetailSection] = useState(false);
  const [total, setTotal] = useState(10);
  const [itemCasilla, setItemCasilla] = useState({});
  const [data, setData] = useState({
    page: 0,
    pageSize: 25,
    filtered: [],
    sorted: [],
  });
  const [loading, setLoading] = useState({
    isLoading: false,
    isOpen: false,
  });
  const { handleRequestSort } = useSorted({
    total: total,
    setData,
  });

  const getDirectoryList = async (data) => {
    try {
      setLoading({ ...loading, isLoading: true, isOpen: true });
      const result = await sije.getListSijeAgenda(data);
      const { response, message, results } = result;
      if (results) {
        setDataDirectory(
          response.data.map((item) => {
            return {
              ...item,
              Seccion: (
                <Button
                  variant="outlined"
                  size="small"
                  sx={{ width: "100%" }}
                  color="primaryDark"
                  onClick={() =>
                    handleModalDetailsSection({
                      dataDirectory: response.data,
                      item,
                    })
                  }
                >
                  {item.Seccion}
                </Button>
              ),

              NombreRG: (
                <ListItemText
                  primary={item.NombreRG}
                  secondary={item.CelularRG}
                />
              ),
              NombreRCP1: (
                <ListItemText
                  primary={item.NombreRCP1}
                  secondary={
                    <ContactsStack
                      whatsapp={item.CelularRCP1}
                      nombre={item.NombreRCP1}
                      telefono={item.TelRCP1}
                      telefono_mensaje={item.TelMensajesRCP1}
                    />
                  }
                />
              ),
              NombreRCP2: (
                <ListItemText
                  primary={item.NombreRCP2}
                  secondary={
                    <ContactsStack
                      whatsapp={item.CelularRCP2}
                      nombre={item.NombreRCP2}
                      telefono={item.TelRCP2}
                      telefono_mensaje={item.TelMensajesRCP2}
                    />
                  }
                />
              ),
              NombreRCS1: (
                <ListItemText
                  primary={item.NombreRCS1}
                  secondary={
                    <ContactsStack
                      whatsapp={item.CelularRCS1}
                      nombre={item.NombreRCS1}
                      telefono={item.TelRCS1}
                      telefono_mensaje={item.TelMensajesRCS1}
                    />
                  }
                />
              ),
              NombreRCS2: (
                <ListItemText
                  primary={item.NombreRCS2}
                  secondary={
                    <ContactsStack
                      whatsapp={item.CelularRCS2}
                      nombre={item.NombreRCS2}
                      telefono={item.TelMensajesRCS2}
                      telefono_mensaje={item.TelRCS2}
                    />
                  }
                />
              ),
              PaseListaRC: (
                <Chip
                  label={
                    <Typography variant="body2" fontWeight={600}>
                      {item.PaseListaRC}
                    </Typography>
                  }
                  size="small"
                  sx={{
                    backgroundColor:
                      item.PaseListaRC === "SI" ? green[50] : red[50],
                    color: item.PaseListaRC === "SI" ? green[800] : red[800],
                  }}
                />
              ),
              PaseListaRG: (
                <Chip
                  label={
                    <Typography variant="body2" fontWeight={600}>
                      {item.PaseListaRG}
                    </Typography>
                  }
                  size="small"
                  sx={{
                    backgroundColor:
                      item.PaseListaRG === "SI" ? green[50] : red[50],
                    color: item.PaseListaRG === "SI" ? green[800] : red[800],
                  }}
                />
              ),
            };
          })
        );

        setTotal(response.total);
      } else {
        throw new Error(message);
      }
    } catch (error) {
      Swal.fire({
        title: error.message,
        icon: "warning",
      });
    } finally {
      setLoading({ ...loading, isLoading: false, isOpen: false });
    }
  };

  const handleExportDirectoryList = async () => {
    try {
      setIsDownloading((prevState) => !prevState);
      const result = await sije.exportSije({
        params: { filtered: data.filtered },
        api: "sije/export-list-sije-agenda",
      });
      const { success } = await result;
      if (success) {
        Swal.fire({
          title: "Descargado con éxito.",
          icon: "success",
        });
      } else {
        throw new Error("Ocurrió un error, contacte al administrador.");
      }
    } catch (error) {
      Swal.fire({
        title: error,
        icon: "warning",
      });
    } finally {
      setIsDownloading((prevState) => !prevState);
    }
  };

  const handleModalDetailsSection = async ({ dataDirectory, item }) => {
    try {
      setLoadingDetailSection((prevState) => !prevState);
      setOpenModal((prevState) => !prevState);
      /* const dataInfoSection = dataDirectory.filter(
        (data) => data.Seccion === item.Seccion
      ); */
      // setDataDirectoryFilter(dataInfoSection);
      //  setOpenModal((prevState) => !prevState);

      const result = await sije.getListSijeAgenda({
        page: 0,
        pageSize: 10,
        filtered: [
          {
            id: "Seccion",
            filter: "=",
            value: item.Seccion,
          },
        ],
        sorted: [],
      });
      const { response, results, message } = await result;

      if (results) {
        setDataDirectoryFilter(response.data);
      } else {
        throw new Error(message);
      }
    } catch (error) {
      Swal.fire({
        title: error,
        icon: "warning",
      });
    } finally {
      setLoadingDetailSection((prevState) => !prevState);
    }
  };

  /* const handleModalDetailsCasilla = ({ item }) => {
    setItemCasilla(item);
    setOpenModalCasilla((prevState) => !prevState);
  }; */

  const onChangeFilter = ({ filtersData }) => {
    if (filtersData.length > 0)
      setData((prevState) => ({
        ...prevState,
        page: 0,
        filtered: filtersData,
      }));
  };

  const onClearFilter = () => {
    if (data.filtered.length > 0)
      setData((prevState) => ({ ...prevState, filtered: [] }));
  };

  const handleChangePagination = (pagination) => {
    setData({ ...data, ...pagination });
  };

  useEffect(() => {
    getDirectoryList(data);
    // eslint-disable-next-line
  }, [data]);

  return (
    <AdminLayout>
      <LoadingForm
        loadinMessage={"Cargando..."}
        isLoading={loading.isLoading}
        isOpen={loading.isOpen}
        setIsOpen={() =>
          setLoading((prevState) => ({ ...prevState, isOpen: false }))
        }
      />
      <Container maxWidth={false}>
        <ActionHeader title="Directorio" />
        <Filter onChangeFilter={onChangeFilter} onClearFilter={onClearFilter} />
        <Card className="card-primary">
          <CardContent>
            <Stack
              direction={"row"}
              justifyContent={"space-between"}
              alignItems={"center"}
              marginBottom={2}
            >
              <Typography variant="body2" fontWeight={700}>
                DIRECTORIO
              </Typography>
              <Button
                variant="outlined"
                color="primaryDark"
                startIcon={<Icon>download</Icon>}
                size="small"
                disabled={isDownload}
                onClick={handleExportDirectoryList}
              >
                Exportar
              </Button>
            </Stack>
            {isDownload && (
              <Box marginBottom={2}>
                <Download format={"xlsx"} isDownload={isDownload} />
              </Box>
            )}

            <CustomTable
              rows={dataDirectory}
              columns={COLUMNS_DIRECTORY}
              total={total}
              pageProp={data.page}
              pageSize={data.pageSize}
              maxHeight="calc(100vh - 300px)"
              stickyHeader
              //isLoading={loadingData}
              handlePagination={handleChangePagination}
              handleSort={handleRequestSort}
            />
          </CardContent>
        </Card>

        <ModalDetailsSection
          loading={loadingDetailSection}
          openModal={openModal}
          setOpenModal={setOpenModal}
          data={dataDirectoryFilter}
        />

        <ModalDetailsCasilla
          openModal={openModalCasilla}
          setOpenModal={setOpenModalCasilla}
          data={itemCasilla}
        />
      </Container>
    </AdminLayout>
  );
};

export default Directory;
