import React from "react";

import { Icon, TextField, InputAdornment, Box, Chip } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2";
import { isEmptyOrInvalidString, isTypePhone } from "@utils/validations";
import { Email } from "@mui/icons-material";

const ContactInformationFormExtranjero = (props) => {
  const {
    errors,
    touched,
    values,
    handleChange,
    telephoneShow = true,
    emailShow = true,
  } = props;

  return (
    <Box sx={{ mt: 2, mb: 2 }}>
      <Grid2 xs={12} sm={12} md={12} lg={12}>
        <Chip icon={<Icon>contact_phone</Icon>} label="Contacto" />
      </Grid2>

      <Grid2 container spacing={3} sx={{ mt: 2 }}>
        {telephoneShow && (
          <Grid2 xs={12} sm={12} md={3} lg={3}>
            <TextField
              error={touched.Celular && !isEmptyOrInvalidString(errors.Celular)}
              helperText={touched.Celular && errors.Celular && errors.Celular}
              sx={{ width: "100%" }}
              required
              label="Telefono Celular"
              type="text"
              name="Celular"
              onChange={(e) =>
                handleChange({
                  target: {
                    name: "Celular",
                    value: isTypePhone(e.target.value)
                      ? e.target.value
                      : values.Celular,
                  },
                })
              }
              onBlur={(e) =>
                handleChange({
                  target: {
                    name: "Celular",
                    value: isTypePhone(e.target.value)
                      ? e.target.value
                      : values.Celular,
                  },
                })
              }
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">{"+1"}</InputAdornment>
                ),
              }}
              value={values.Celular}
              variant="outlined"
              size="small"
              className="fixed-input"
            />
          </Grid2>
        )}
        <Grid2 xs={12} sm={12} md={3} lg={3}>
          <TextField
            error={touched.Telefono && !isEmptyOrInvalidString(errors.Telefono)}
            helperText={touched.Telefono && errors.Telefono && errors.Telefono}
            sx={{ width: "100%" }}
            label="Telefono Fijo"
            type="text"
            name="Telefono"
            onChange={(e) =>
              handleChange({
                target: {
                  name: "Telefono",
                  value: isTypePhone(e.target.value)
                    ? e.target.value
                    : values.Telefono,
                },
              })
            }
            onBlur={(e) =>
              handleChange({
                target: {
                  name: "Telefono",
                  value: isTypePhone(e.target.value)
                    ? e.target.value
                    : values.Telefono,
                },
              })
            }
            value={values.Telefono}
            variant="outlined"
            size="small"
            className="fixed-input"
          />
        </Grid2>
        <Grid2 xs={12} sm={12} md={3} lg={3}>
          <TextField
            error={
              touched.TelMensajes && !isEmptyOrInvalidString(errors.TelMensajes)
            }
            helperText={
              touched.TelMensajes && errors.TelMensajes && errors.TelMensajes
            }
            sx={{ width: "100%" }}
            label="Telefono Mensajes"
            type="text"
            name="TelMensajes"
            onChange={(e) =>
              handleChange({
                target: {
                  name: "TelMensajes",
                  value: isTypePhone(e.target.value)
                    ? e.target.value
                    : values.TelMensajes,
                },
              })
            }
            onBlur={(e) =>
              handleChange({
                target: {
                  name: "TelMensajes",
                  value: isTypePhone(e.target.value)
                    ? e.target.value
                    : values.TelMensajes,
                },
              })
            }
            value={values.TelMensajes}
            variant="outlined"
            size="small"
            className="fixed-input"
          />
        </Grid2>
        {emailShow && (
          <Grid2 xs={12} sm={12} md={3} lg={3}>
            <TextField
              error={touched.Correo && !isEmptyOrInvalidString(errors.Correo)}
              placeholder="Correo Electrónico"
              helperText={touched.Correo && errors.Correo && errors.Correo}
              type="text"
              name="Correo"
              onChange={handleChange}
              value={values.Correo}
              size="small"
              variant="outlined"
              className="fixed-input"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Email />
                  </InputAdornment>
                ),
              }}
            />
          </Grid2>
        )}
      </Grid2>
    </Box>
  );
};
export default ContactInformationFormExtranjero;
