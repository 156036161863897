import { useEffect, useState } from "react";

// Material UI
import { Box } from "@mui/material";
import Swal from "sweetalert2";

// Componentes
import CircularProgressPorcent from "@components/LinearProgress/CircularProgressPorcent";
import DataTableReport from "@components/Tables/DataTableReport";
import Download from "@components/Downloads/Download";

// Servicios
import Auditoria from "@services/AuditServices";

// Utilidades
import { numberWithCommas } from "@utils/Utilities/";

const TableReportes = (props) => {
  const { data, setData, isDownload, selectedOption } = props;

  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState([]);
  const [titulos, setTitulos] = useState([]);
  const [summaryData, setSummaryData] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [pages, setPage] = useState(0);
  const [orderBy, setOrderBy] = useState("");
  const [order, setOrder] = useState("");

  // Llamada a la API
  const ApiResponse = async (params) => {
    setLoading(true);
    try {
      const result = await Auditoria.getProgressByScope(params);
      const { results, message, response } = result;

      if (results) {
        setTotal(response.total);
        setTitulos(response.columns);
        setData(response.data);
        const totals = response.totals;
        const columns = response.columns.length;
        const numberOfEmptySpaces = columns - 7;

        const summaryData = Array(numberOfEmptySpaces)
          .fill("")
          .concat([
            totals.TotalCompromisos,
            totals.Exitosos,
            totals.PorcentajeExitosos,
            totals.NoExitosos,
            totals.PorcentajeNoExitosos,
            totals.Pendientes,
            totals.PorcentajePendientes,
          ]);

        setSummaryData(summaryData);
        setLoading(false);
      } else {
        Swal.fire({
          title: message,
          icon: "warning",
        });
        setData([]);
      }
    } catch (error) {
      Swal.fire({
        title: error.message,
        icon: "warning",
      });
      setData([]);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const params = {
      page: pages,
      pageSize: rowsPerPage,
      idAmbitoAgrupador: selectedOption,
      sorted: [
        { id: "CONVERT(PorcentajeExitosos, DECIMAL(4,3))", value: "desc" },
      ],
    };

    setData([]);
    setRowsPerPage(5);
    setPage(0);
    setOrderBy("");
    setOrder("");
    setTotal(0);

    if (selectedOption >= 0) ApiResponse(params);
  }, [selectedOption]);

  // Se agregan las comas de miles y si hay algun valor nulo se convierte en 0
  const dataWithoutNulls = data.map((item) => {
    for (const key in item) {
      if (!item[key]) item[key] = 0;
      else if (typeof item[key] === "number")
        item[key] = numberWithCommas(item[key]);
    }
    return item;
  });

  // Se agregan los porcentajes a la información
  const dataWithProgress = dataWithoutNulls.map((item) => ({
    ...item,
    PorcentajeExitosos: (
      <CircularProgressPorcent
        size={42}
        fontSize={10}
        value={parseFloat(item.PorcentajeExitosos)}
      />
    ),
    PorcentajeNoExitosos: (
      <CircularProgressPorcent
        size={42}
        fontSize={10}
        value={parseFloat(item.PorcentajeNoExitosos)}
      />
    ),
    PorcentajePendientes: (
      <CircularProgressPorcent
        size={42}
        fontSize={10}
        value={parseFloat(item.PorcentajePendientes)}
      />
    ),
  }));

  // Paginación remota de la tabla
  const handleChangePagination = ({ page, pageSize }) => {
    setPage(page);
    setRowsPerPage(pageSize);

    const params = {
      page: page,
      pageSize: pageSize,
      idAmbitoAgrupador: selectedOption,
      sorted: [
        { id: "CONVERT(PorcentajeExitosos, DECIMAL(4,3))", value: "desc" },
      ],
    };

    // Comprueba si esta ordenada
    if (orderBy) {
      params.sorted = [{ id: orderBy, value: order }];
    }

    ApiResponse(params);
  };

  // Ordenamiento remoto de la tabla
  const handleChangeSort = (e) => {
    setOrderBy(e.orderBy);
    setOrder(e.order);

    const params = {
      page: pages,
      pageSize: rowsPerPage,
      idAmbitoAgrupador: selectedOption,
      sorted: [{ id: e.orderBy, value: e.order }],
    };

    ApiResponse(params);
  };

  return (
    <>
      {isDownload && (
        <Box marginBottom={2}>
          <Download format={"xlsx"} isDownload={isDownload} />
        </Box>
      )}
      <DataTableReport
        rows={dataWithProgress}
        hcolumns={titulos}
        total={total}
        isLoading={loading}
        summary={summaryData}
        stickyHeader={true}
        pageSize={rowsPerPage}
        pageProp={pages}
        handleChangePagination={handleChangePagination}
        manual={true}
        handleManualSort={handleChangeSort}
      />
    </>
  );
};

export default TableReportes;
