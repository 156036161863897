import { useState, useEffect } from "react";


import {
  Button,
  Card,
  CardContent,
  Container,
  Icon,
  Typography,
  Tooltip,
  Box,
  Chip,
  Stack,
  Grid,
  Link
} from "@mui/material";

import Swal from "sweetalert2";
import Download from "@components/Downloads/Download";
import "moment/locale/es";
import MessageAlert from "@components/Downloads/MessageAlert";
import AdminLayout from "@components/MainPage/AdminLayout";
import BasicTable from "@components/Tables/BasicTable";
import ActionHeader from "@components/Containers/ActionHeader";
import FilterMovilizacion from "../../components/FrenteAmplio/FilterMovilizacion";
import FrenteAmplioServices from "@services/FrenteAmplioServices";
import { isEmptyOrNullObject } from "@utils/validations";
import middleware from "@middlewares/middleware";
import { getVars } from "@utils/global";
import PhoneIphoneIcon from "@mui/icons-material/PhoneIphone";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import PermPhoneMsgIcon from "@mui/icons-material/PermPhoneMsg";
import CardStats from "@components/Card/CardStats";

const MovilizacionFA = () => {
  //Constantes
  const colums = [
    {
      label: "Municipio",
      id: "Municipio",
      orderBy: "Municipio",
      columnAction: false,
      width: 200,
    },
    {
      label: "Nombre Completo",
      id: "NombreCompleto",
      columnAction: false,
      width: 700,
      orderBy: "NombreCompleto",
    },
    {
      label: "Colonia",
      id: "ColoniaVive",
      columnAction: false,
      width: 300,
      orderBy: "ColoniaVive",
    },
    {
      label: "Calle",
      id: "Calle",
      columnAction: false,
      width: 350,
      orderBy: false,
    },
    {
      label: "Teléfono",
      id: "Telefono",
      columnAction: false,
      width: 350,
      orderBy: false,
    },
    {
      label: "Sección",
      id: "SeccionVota",
      columnAction: false,
      width: 100,
      orderBy: "SeccionVota",
    },
    {
      label: "Estatus",
      id: "DataResult",
      columnAction: false,
      width: 100,
      orderBy: "DataResult",
    },
    {
      label: "Capturado Por",
      id: "Capturista",
      columnAction: false,
      width: 350,
      orderBy: "Capturista",
    },
  ];
  const { user } = getVars("Token");

  const [data, setData] = useState({
    page: 0,
    pageSize: 10,
    filtered: [{
      id: "compromisos_unicos_fa.DataResult",
      filter: "like",
      value:"PARTICIPANTE" ,
    }],
    sorted: [{ id: "compromisos_unicos_fa.FechaCreo", value: "desc" }],
  });

  //State
  const [total, setTotal] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [dataLisCommitments, setDataLisCommitments] = useState([]);
  const [sortedData, setSortedData] = useState([]);
  const [successDownload, setSuccessDownload] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);

  const handleExport = () => {
    setIsDownloading(true);
    FrenteAmplioServices.exportMovilizacion({
      filtered: data.filtered,
      page: 0,
      pageSize: 99999999999999,
    })
      .then((res) => {
        if (res.success) {
          setShowMessage(true);
          setSuccessDownload(true);
        } else {
          setShowMessage(true);
          setSuccessDownload(false);
        }
      })
      .catch((error) => {
        setShowMessage(true);
        setSuccessDownload(false);
      })
      .finally(() => setIsDownloading(false));
  };

  const getList = (data) => {
    setIsLoading(true);
    FrenteAmplioServices.getListMovilizacion(data)
      .then((res) => {
        if (res.results) {
          const Commitments = res.response.data.map((item) => {
            return {
              ...item,
              NombreCompleto: (
                <Typography variant="div" fontWeight={700} fontSize={15}>
                  {item.NombreCompleto}
                </Typography>
              ),
              Telefono: (
                <Stack direction="column" spacing={1}>
                  {item.Celular !== null && (
                    <Tooltip title="Celular" placement="right-start">
                      <Chip
                        component={Link}
                        href={`whatsapp://send?phone=52${item.Celular}&text=Hola%20*${item.NombreCompleto}*`}
                        target="_blank"
                        color={"primary"}
                        icon={<PhoneIphoneIcon />}
                        label={item.Celular}
                        variant="outlined"
                      />
                    </Tooltip>
                  )}
                  {item.Telefono !== null && (
                    <Tooltip title="Teléfono" placement="right-start">
                      <Chip
                        color={"primary"}
                        icon={<LocalPhoneIcon />}
                        label={item.Telefono}
                        variant="outlined"
                      />
                    </Tooltip>
                  )}
                  {item.TelMensajes !== null && (
                    <Tooltip
                      title="Teléfono de Recados"
                      placement="right-start"
                    >
                      <Chip
                        color={"primary"}
                        icon={<PermPhoneMsgIcon />}
                        label={item.TelMensajes}
                        variant="outlined"
                      />
                    </Tooltip>
                  )}
                </Stack>
              ),
            };
          });
          setDataLisCommitments(Commitments);
          setTotal(res.response.total);
        } else {
          Swal.fire({ title: res.message, icon: "warning" });
          setDataLisCommitments([]);
          setTotal(0);
        }
      })
      .catch((err) => {
        Swal.fire({ title: err, icon: "error" });
        setDataLisCommitments([]);
        setTotal(0);
      })
      .finally(() => setIsLoading(false));
    // eslint-disable-next-line
  };

  useEffect(() => {
    getList(data);
    // eslint-disable-next-line
  }, [data]);

  useEffect(() => {
    if (sortedData.length > 0) {
      const params = {
        ...data,
        sorted: sortedData,
      };

      getList(params);
    }
    // eslint-disable-next-line
  }, [sortedData]);

  const handleChangeFilter = (filtered) => {
    setDataLisCommitments([]);
    setData((prevState) => ({ ...prevState,page:0, filtered }));
  };

  const handleChangePagination = (pagination) => {
    setData({ ...data, ...pagination });
  };

  const handleSorted = (dataSorted) => {
    const fields = {
      Municipio: "cat_municipios.Municipio",
      Capturista:"CONCAT_WS(' ',usuarios.Celular,usuarios.Nombre,usuarios.Paterno)",
      NombreCompleto: "CONCAT_WS(' ',compromisos_unicos_fa.Nombre, compromisos_unicos_fa.Paterno)",
      ColoniaVive:"compromisos_unicos_fa.ColoniaVive",
      DataResult: "compromisos_unicos_fa.DataResult",
      SeccionVota:"compromisos_unicos_fa.SeccionVota"
    };

    let sorted = [];
    if (!isEmptyOrNullObject(dataSorted)) {
      sorted.push({
        id: fields[dataSorted.orderBy],
        value: dataSorted.order,
      });
    }

    return sorted;
  };

  const handleRequestSort = (sortData) => {
    if (total > 0) setSortedData(handleSorted(sortData));
  };

  return (
    <AdminLayout>
      <Container maxWidth="false">
        <ActionHeader
          title={
            <>
              Movilización FAM
              <Typography color="primary" variant="h5">
              {`${user.Nombre} ${user.Paterno}`}
              </Typography>
            </>
          }
        />
        <Box>
        <Grid container>
        <Grid item xs={12} sm={12} md={2} lg={2}>
        <CardStats
              title=""
              total={total}
              color="info"
              icon={"schema"}
              sx={{ height: "215px" }}
            />
          </Grid>
          </Grid>
          <FilterMovilizacion onChangeFilter={(e) => handleChangeFilter(e)} catalogo={(e)=>{}} updateAsing = {(e)=> {}}/>
          <Card className="card-primary">
            <CardContent>

            {middleware.checkMenuActionId("Exportar") && (<>
              {isDownloading ? (
                <Download isDownload={isDownloading} format="xlsx" />
              ) : (
                <Box
                  display={"flex"}
                  justifyContent={"flex-end"}
                  sx={{ mb: 2 }}
                >
                  <Button
                    variant="outlined"
                    color="primaryDark"
                    startIcon={<Icon>download</Icon>}
                    size="small"
                    onClick={handleExport}
                    sx={{ borderRadius: "10px" }}
                  >
                    Exportar
                  </Button>
                </Box>
              )}
              </>)}
              {showMessage && (
                <Box marginTop={2}>
                  <MessageAlert
                    showMessage={showMessage}
                    successDownload={successDownload}
                    setShowMessage={setShowMessage}
                  />
                </Box>
              )}
              <BasicTable
                rows={dataLisCommitments}
                hcolumns={colums}
                handleChangePagination={handleChangePagination}
                isLoading={isLoading}
                total={total}
                pageProp={data.page}
                pageSize={data.pageSize}
                stickyHeader={true}
                handleManualSort={handleRequestSort}
              />
            </CardContent>
          </Card>
        </Box>
      </Container>
    </AdminLayout>
  );
};

export default MovilizacionFA;
