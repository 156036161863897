import React, { useState } from "react";
import {
  Button,
  Container,
  Icon,
  IconButton,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";

import Navbar from "../Navbar";
import WebCam from "../WebCam";
import Toast from "../Toast";
import FormGeneral from "./FormGeneral";

import moment from "moment/moment";
import "moment/locale/es";
import esLocale from "date-fns/locale/es";
import { getVars } from "@utils/global";
import { useMobileServices } from "@hooks/MobileDiaD/MobileServices";

const ScreenCierreCasilla = ({ setTypeScreen }) => {
  const {
    user: { ambito_perfil },
  } = getVars("Token");

  const { loading, snackbar, setSnackbar, setCierreCasilla } =
    useMobileServices();

  const [hour, setHour] = useState(moment());
  const [image, setImage] = useState(null);
  const [values, setValues] = useState({
    idCasilla: "",
    seccion: "",
    idTipoPropietario: "",
  });
  const [flagStorage, setFlagStorage] = useState(false);

  const handleSetCierreCasilla = async () => {
    const params = {
      idUser: ambito_perfil[0].idUsuario,
      casillas: [
        {
          idCasilla: values.idCasilla,
          FechaHora: moment(hour).format("HH:mm"),
        },
      ],
    };

    if (image) params.casillas[0].files = [{ file: image }];

    const results = await setCierreCasilla(params);

    if (results) {
      setHour(moment());
      setImage(null);
      setFlagStorage(true);
    }
  };

  return (
    <>
      <Toast
        open={snackbar.show}
        message={snackbar.message}
        setOpen={setSnackbar}
      />
      <Navbar
        isScreen
        screenBack={"general"}
        title={"Cierre de Casilla"}
        setTypeScreen={setTypeScreen}
        color="black"
      />
      <Container sx={{ mt: 4 }}>
        <Typography textAlign={"center"} fontWeight={600} marginBottom={2}>
          ¿Ya cerró la casilla?
        </Typography>
        <Stack direction={"column"} spacing={1}>
          <FormGeneral
            values={values}
            flagStorage={flagStorage}
            setValues={setValues}
            showFuncionario={false}
          />

          <Typography variant="body2">
            Hora del cierre de casilla formato 24hrs (Ejemplo: 07:30)
          </Typography>
          <Stack direction={"row"} spacing={1}>
            <LocalizationProvider
              dateAdapter={AdapterDateFns}
              adapterLocale={esLocale}
              localeText={{
                timePicker24Hour: "24 horas",
                okButtonLabel: "Aceptar",
                cancelButtonLabel: "Cancelar",
              }}
            >
              <TimePicker
                label={"Hora cierre casilla"}
                value={hour}
                onChange={(newValue) => setHour(newValue)}
                renderInput={(params) => <TextField fullWidth {...params} />}
              />
            </LocalizationProvider>
            <IconButton onClick={() => setHour("")}>
              <Icon>delete</Icon>
            </IconButton>
          </Stack>
          <WebCam image={image} setImage={setImage} />
          <Button
            variant="contained"
            onClick={handleSetCierreCasilla}
            disabled={loading || !values.idCasilla || !hour || !values.seccion}
          >
            {loading ? "Guardando..." : "Cerró la casilla"}
          </Button>
        </Stack>
      </Container>
    </>
  );
};

export default ScreenCierreCasilla;
