import { Box, Table, TableContainer, TableBody } from "@mui/material";
import PropTypes from "prop-types";
import MenuRowHeader from "@components/Menus/MenuRowHeader";
import BasicDraggable from "@components/Draggable/BasicDraggable";
import MenuRowDraggable from "@components/Menus/MenuRowDraggable";
import MenuRow4Select from "@components/Menus/MenuRow4Select";
import MenuRow4SelectEdit from "@components/Menus/MenuRow4SelectEdit";

const BasicList = (props) => {
  const {
    items,
    maxHeight,
    select,
    toolbar,
    ComponentToolbar,
    configToolbar,
  } = props;

  return (
    <TableContainer sx={{ maxHeight: maxHeight }}>
      {toolbar && <ComponentToolbar configToolbar={configToolbar} />}
      <Table stickyHeader aria-label="sticky table">
        <MenuRowHeader colSpan={3} />
        <TableBody>
          {items && items.length > 0 &&
            items.map((item, index) => {
              return (
                <MenuRow4Select
                  menu={item}
                  index={index}
                  key={`row_${Math.random()}`}
                  select={select}
                />
              );
            })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const ListSelectEdit = (props) => {
  const {
    items,
    maxHeight,
    select,
    setMenus,
    reset,
    toolbar,
    ComponentToolbar,
    configToolbar,
    setIsOpen,
    setIsLoading,
    setIsSuccess,
    handleReloadList,
    externalID,
  } = props;
  return (
    <TableContainer sx={{ maxHeight: maxHeight }}>
      {toolbar && <ComponentToolbar configToolbar={configToolbar} />}
      <Table stickyHeader aria-label="sticky table">
        <MenuRowHeader colSpan={3} />
        <TableBody>
          {items && items.length > 0 &&
            items.map((item, index) => {
              return (
                <MenuRow4SelectEdit
                  menu={item}
                  index={index}
                  key={`row_${Math.random()}`}
                  select={select}
                  selectedMenu={setMenus}
                  reset={reset}
                  setIsOpen={setIsOpen}
                  setIsLoading={setIsLoading}
                  setIsSuccess={setIsSuccess}
                  handleReloadList={handleReloadList}
                  externalID={externalID}
                />
              );
            })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const ListDraggable = (props) => {
  const {
    items,
    maxHeight,
    setIsOpen,
    setIsLoading,
    setIsSuccess,
    handleReloadList,
    setEdit,
    edit,
    setEditData
  } = props;
  return (
    <TableContainer sx={{ maxHeight: maxHeight }}>
      <Table stickyHeader aria-label="sticky table">
        <MenuRowHeader colSpan={2} />
        <TableBody>
          {items && items.length > 0 &&
            items.map((item, index) => {
              return (
                <MenuRowDraggable
                  menu={item}
                  index={index}
                  key={`row_${Math.random()}`}
                  setIsOpen={setIsOpen}
                  setIsLoading={setIsLoading}
                  setIsSuccess={setIsSuccess}
                  handleReloadList={handleReloadList}
                  setEdit={(e) => setEdit(e)}
                  edit={edit}
                  setEditData={(e) => setEditData(e)}
                />
              );
            })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const MenusList = (props) => {
  const {
    menus,
    setMenus,
    type,
    maxHeight,
    reset,
    toolbar,
    ComponentToolbar,
    configToolbar,
    setIsOpen,
    setIsLoading,
    setIsSuccess,
    handleReloadList,
    externalID,
    setEdit,
    edit,
    setEditData
  } = props;
  if (type === "draggable") {
    return (
      <BasicDraggable
        items={menus}
        setItems={setMenus}
        rowComponent={ListDraggable({
          items: menus,
          maxHeight: maxHeight,
          setIsOpen: setIsOpen,
          setIsLoading: setIsLoading,
          setIsSuccess: setIsSuccess,
          handleReloadList: handleReloadList,
          setEdit: (e) => setEdit(e),
          edit: edit,
          setEditData: (e) => setEditData(e)

        })}
      ></BasicDraggable>
    );
  } else if (type === "list") {
    return (
      <Box sx={{ width: "100%", overflow: "auto", ml: 0, mr: 0 }}>
        {BasicList({ items: menus, maxHeight: maxHeight, select: false })}
      </Box>
    );
  } else if (type === "select-edit") {
    return (
      <Box sx={{ width: "100%", overflow: "auto", ml: 0, mr: 0 }}>
        {ListSelectEdit({
          items: menus,
          maxHeight: maxHeight,
          select: true,
          setMenus: setMenus,
          reset: reset,
          toolbar: toolbar,
          ComponentToolbar: ComponentToolbar,
          configToolbar: configToolbar,
          setIsOpen: setIsOpen,
          setIsLoading: setIsLoading,
          setIsSuccess: setIsSuccess,
          handleReloadList: handleReloadList,
        })}
      </Box>
    );
  } else if (type === "list-edit") {
    return (
      <Box sx={{ width: "100%", overflow: "auto", ml: 0, mr: 0 }}>
        {ListSelectEdit({
          items: menus,
          maxHeight: maxHeight,
          select: false,
          reset: reset,
          toolbar: toolbar,
          ComponentToolbar: ComponentToolbar,
          configToolbar: configToolbar,
          setIsOpen: setIsOpen,
          setIsLoading: setIsLoading,
          setIsSuccess: setIsSuccess,
          handleReloadList: handleReloadList,
          externalID: externalID,
        })}
      </Box>
    );
  }
};

MenusList.propTypes = {
  menus: PropTypes.array.isRequired,
  setMenus: PropTypes.func,
  type: PropTypes.string.isRequired,
  maxHeight: PropTypes.number.isRequired,
  reset: PropTypes.func,
  toolbar: PropTypes.bool.isRequired,
  title: PropTypes.string,
  ComponentToolbar: PropTypes.any,
  configToolbar: PropTypes.any,
  setIsOpen: PropTypes.func,
  setIsLoading: PropTypes.func,
  setIsSuccess: PropTypes.func,
  handleReloadList: PropTypes.func.isRequired,
  externalID: PropTypes.number,
};

MenusList.defaultProps = {
  menus: [],
  type: "list",
  maxHeight: 800,
  toolbar: false,
  configToolbar: {
    title: "",
    icon: "",
    btnAdd: true,
  },
};

export default MenusList;
