import React, { useState, useEffect } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { green, red, orange, yellow, grey, blue, deepPurple } from '@mui/material/colors';

const StackedBar = (props) => {
  const { categories, series, title, date, text = "", fontSize = "", fontSizeSubtitle = "", align = "left" } = props;
  const [data, setData] = useState(null);

  // Definir los colores de las series
  const seriesColors = series.map(item => {
    switch (item.name) {
      case "Pendientes":
        return orange[300]
      case "AvanceAfin":
        return green[300]
      case "AvanceNoAfin":
        return grey[300]
      case "AvancePersonaNoEncontrada":
        return red[300]
      case "AvanceDomicilioNoEncontrado":
        return yellow[300]
      case "AvanceDomicilioConocido":
        return blue[300]
      case "AvanceViveEnOtraSeccion":
        return deepPurple[300]
      default:
        return ""
    }
  });

  useEffect(() => {
    setData({
      chart: {
        type: 'bar',
        scrollablePlotArea: {
          minHeight: categories.length * 20,
          scrollPositionY: 0,
        },
      },
      title: {
        text: title.primary ?? title.title,
        align: align,
        style: fontSize !== "" ? {
          fontSize: fontSize,
        } : {}
      },

      subtitle: {
        text: title.sub ?? date,
        align: align,
        style: fontSizeSubtitle !== "" ? {
          fontSize: fontSizeSubtitle,
        } : {}
      },

      yAxis: {
        allowDecimals: false,
        min: 0,
        title: {
          text: text ?? "",
        },
        categories: categories,
        scrollbar: {
          enabled: true,
          showFull: true,
        },
      },

      xAxis: {
        categories: categories,
      },

      legend: {
        reversed: true
      },
      credits: false,
      plotOptions: {
        series: {
          stacking: 'normal',
          dataLabels: {
            enabled: true,
            align: 'right',
            style: {
              fontSize: '13px', // Tamaño de la fuente de las etiquetas
            },
            y: -2
            // x: 25
          },
        }
      },
      colors: seriesColors, // Pasar los colores aquí

      series: series,

      /*responsive: {
          rules: [
            {
              condition: {
                maxWidth: 500,
              },
              chartOptions: {
                legend: {
                  layout: "horizontal",
                  align: "center",
                  verticalAlign: "bottom",
                },
              },
            },
          ],
        }, */
    });
    // eslint-disable-next-line
  }, [series, title]);

  return <HighchartsReact highcharts={Highcharts} options={data} />

};

export default StackedBar;
