import { useState, useEffect } from "react";
import {
  Container,
  Card,
  CardContent,
  Box,
  Typography,
  Alert,
  AlertTitle,
  Icon
} from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2";
import AdminLayout from "@components/MainPage/AdminLayout";
import ActionHeader from "@components/Containers/ActionHeader";
import LoadingForm from "@components/LinearProgress/LoadingForm";
import BingoForm from "@components/Activation/CapturaBingo/BingoForm";
import BingoTable from "@components/Activation/CapturaBingo/BingoTable";
import CustomTabs from "@components/Tabs/CustomTabs";
import movilizacion from "@services/MovilizationServices";
import Swal from "sweetalert2";
import AdvancedSelect from "@components/Selects/AdvancedSelect";
import { useCatalogs } from "@hooks/useCatalogs";
import CustomTable from "@components/Tables/CustomTable";
import { isEmptyOrNullObject } from "@utils/validations";
import ContactsStack from "@components/ContactsStack/ContactsStack";
import { getColorReverse } from "@utils/Utilities";

const Bingo = () => {
  const [lastInsert, getLastInsert] = useState(null);
  const [isLoadigForm, setIsLoadingForm] = useState(false);
  const [isSuccessForm] = useState(false);
  const [openLinearProgress, setOpenLinearProgress] = useState(false);
  const [loadingTable, setLoadingTable] = useState(false)
  const [/* loadingDetalle */, setLoadingDetlle] = useState(false)
  const [tabValue, setTabValue] = useState("0");
  const [total, setTotal] = useState(0);
  const [Seccion, setSeccion] = useState("");
  const [tableData, setTableData] =useState([])
  const [detalle, setDetalle] = useState([])
  const [dataForTable, setDataForTable] = useState([])
  const [alertParams, setAlertParams] = useState({
    type: "",
    message: "",
    show: false,
  });

  const tableColumns = [
    {id: "Seccion", label: "Sección"},
    {id: "Promotor", label: "Promotor"},
    {id: "number", label: "Celular"},
    {id: "TotalAMovilizar", label: "Total a Movilizar"},
    {id: "PendienteMovilizar", label: "Pendientes de Movilizar"},
    {id: "ProcentajePendienteMovilizar", label: "% Pendientes Movilizar", type: "circular", customColors: getColorReverse},
    {id: "Movilizados", label: "Movilizados"},
    {id: "ProcentajeMovilizados", label: "% Movilizados", type: "circular"},
  ]

  const resetParams = () =>{
    setSeccion("");
    setDataForTable([])
    setDetalle([])
    setTotal(0);
    setAlertParams({
      type: "",
      message: "",
      show: false,
    });
  }

  const catalogsParams = [{ id: "secciones", getAll: false }];

  const { catalogs, load } = useCatalogs({
    catalogsOptions: catalogsParams,
    putDefaultOption: false,
  });

  const mapData = (data) =>
    data.map((item, index) => ({
      number: (
        <ContactsStack
          whatsapp={item.CelularPromotor}
          nombre={item.Promotor} 
        />
      ),
      ...item,
    }));

  const handleValidateSection = async (section) => {
    try {
      setIsLoadingForm(true);
      setOpenLinearProgress(true);
      const result = await movilizacion.validateSection(section);
      const { results, message } = result;

      if (results) {
        setAlertParams({
          type: "success",
          message: message,
          show: true,
        });
        await getTotals(section)
        await getDetalle(section)
      } else {
        setAlertParams({
          type: "error",
          message: message,
          show: true,
        });
      }
    } catch (error) {
      Swal.fire({
        icon: "warning",
        title: error.message,
      });
    } finally {
      setIsLoadingForm(false);
      setOpenLinearProgress(false);
    }
  };

  const getTotals = async (seccion) =>{
    try {
      setLoadingTable(true)
      const result = await movilizacion.getTotalsBySeccion({Seccion:seccion});
      const {results, message, response} = result
      if(results){
        setDataForTable(mapData(response.data))
        setTotal(response.total)
      }else{
        throw new Error(message)
      }
    } catch (error) {
      setDataForTable([])
      setTotal(0)
      Swal.fire({ title: error.message, icon: "error"})
    }finally{
      setLoadingTable(false)
    }
  }

  const getDetalle = async (seccion) =>{
    try {
      setLoadingDetlle(true)
      const result = await movilizacion.getdDetalleBySeccion({Seccion:seccion});
      const {results, message, response} = result
      if(results){
        setDetalle(response.data)
      }else{
        setDetalle([])
        throw new Error(message)
      }
    } catch (error) {
      Swal.fire({ title: error.message, icon: "error"})
    }finally{
      setLoadingDetlle(false)
    }
  }

  useEffect(() => {
    if (Seccion) {
      handleValidateSection(Seccion);
    }
    //eslint-disable-next-line
  }, [Seccion]);

  useEffect(() => {
    if (alertParams.show) {
      const timer = setTimeout(() => {
        setAlertParams((prev) => ({ ...prev, show: false }));
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [alertParams.show]);

  useEffect(() => {
    resetParams()
  }, [tabValue]);

  useEffect(()=>{
    if(lastInsert){
      setTableData([...tableData, lastInsert])
    }
    //eslint-disable-next-line
  }, [lastInsert])

  return (
    <AdminLayout>
      <Container maxWidth="false">
        <ActionHeader title="Cotejo / Captura Bingo Identificador" />
        <LoadingForm
          isLoading={isLoadigForm}
          success={isSuccessForm}
          isOpen={openLinearProgress}
          setIsOpen={() => setOpenLinearProgress(!openLinearProgress)}
          loadinMessage={"Cargando..."}
        />
        <Card className="card-primary">
          <CardContent>
            <Box>
              <Grid2 container spacing={2}>
                <Grid2 xs={12}>
                  <CustomTabs
                    value={tabValue}
                    setValue={setTabValue}
                    tabs={[
                      {
                        label: "Captura Bingo",
                        value: "0",
                      },
                      {
                        label: "Listado por Sección",
                        value: "1",
                      },
                    ]}
                  />
                </Grid2>

                {tabValue === "0" && (
                  <>
                    <Grid2 xs={12} sm={12} md={3} lg={3}>
                      <BingoForm 
                        getLastInsert={getLastInsert}
                        setAlertParams={(e)=>{setAlertParams({type: e.type, message: e.message, show: e.show})}} 
                        catalogs={catalogs} 
                        loadSeccion={load} isLoading={(e)=>{}}
                      />
                      {alertParams.show && (
                        <Alert severity={alertParams.type} sx={{mt: 2}}>
                          <AlertTitle>{alertParams.message}</AlertTitle>
                        </Alert>
                      )}
                    </Grid2>
                    <Grid2 xs={12} sm={12} md={9} lg={9}>
                      <BingoTable
                        setIsLoadingForm={setIsLoadingForm}
                        setOpenLinearProgress={setOpenLinearProgress}
                        lastInsert={tableData}
                        updatedData={setTableData}
                      />
                    </Grid2>
                  </>
                )}
                {tabValue === "1" && (
                  <>
                    <Grid2 xs={12} sm={12} md={3} lg={3}>
                      <AdvancedSelect
                        id="Seccion"
                        name="Seccion"
                        variant="outlined"
                        label="Sección"
                        options={catalogs?.secciones || []}
                        value={Seccion}
                        onChange={(e) => {
                          setSeccion(e.value);
                        }}
                        isLoading={load}
                        isSearchable
                        required
                      />
                      <Card className="card-primary" sx={{mt: 2}}>
                        <CardContent>
                        {!isEmptyOrNullObject(detalle) ?(
                        <Box width={"100%"} minHeight={"10vh"} ml={"1vh"}>
                          <Typography gutterBottom>
                            <strong>Seccion: {detalle.Seccion}</strong> 
                          </Typography> 
                          <Typography gutterBottom>
                            <strong>Simpatizantes: {detalle.Simpatizantes}</strong>
                          </Typography> 
                          <Typography gutterBottom>
                            <strong>Promotores: {detalle.Promotores}</strong> 
                          </Typography> 
                          <Typography gutterBottom>
                            <strong>Movilizados: {detalle.Movilizados}</strong> 
                          </Typography> 
                          <Typography gutterBottom>
                            <strong>Por Movilizar: {detalle.PorMovilizar}</strong> 
                          </Typography>
                        </Box>) : (
                          <Box width={"100%"} display="flex" flexDirection="column" justifyContent="center" alignItems="center" mt={1}> 
                            <Icon fontSize={"large"}>info</Icon>
                            <Typography variant="body1">No hay información disponible. Por favor, seleccione una sección.</Typography>
                          </Box>
                        )}
                        </CardContent>
                      </Card>
                      {alertParams.show && (
                        <Alert severity={alertParams.type} sx={{mt: 2}}>
                          <AlertTitle>{alertParams.message}</AlertTitle>
                        </Alert>
                      )}
                    </Grid2>
                    <Grid2 xs={12} sm={12} md={9} lg={9}>
                      <CustomTable
                        rows={dataForTable}
                        columns={tableColumns}
                        total={total}
                        page={0}
                        pageSize={10}
                        isLoading={loadingTable}
                        stickyHeader={true}
                        maxHeight= {600}
                      />
                    </Grid2>
                  </>
                )}
              </Grid2>
            </Box>
          </CardContent>
        </Card>
      </Container>
    </AdminLayout>
  );
};

export default Bingo;
