import { useState } from "react";

// Material UI
import { Card, CardContent, CardHeader, Grid, Paper, styled, IconButton, Stack, Button } from "@mui/material";
import { RemoveCircleOutline, InfoOutlined } from "@mui/icons-material";

// Utils
import { generateRandomString } from "@utils/global";

// Map Things
import { roundTo } from "@components/Maps/Auxiliars/MathFunctions";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const CardInfoViewDetail = ({ initialValues, values, handleClickViewDetail }) => {
  const [showCard, setShowCard] = useState(true);
  /* 
    let exampleInitialValues = {
          title: "INFORMACIÓN",
          elements: [
            {
              title: "Seccion:",
              name: "seccion",
              color: "#000",
              type: "text",
            },
            {
              title: "Avance:",
              name: "avance",
              color: "#000",
              type: "int",
            },
            {
              title: "Porcentaje de Avance:",
              name: "avance_porcentual",
              color: "#000",
              type: "percent",
            },
          ],
        }; */
  const handleClick = () => handleClickViewDetail(values);

  return values !== null && values !== undefined && showCard ? (
    <div className="leaflet-control-container">
      <div className="leaflet-top leaflet-right">
        <div
          className="leaflet-control"
          style={{
            padding: "6px 8px",
            font: "14px/16px Arial, Helvetica, sans-serif",
            background: "rgba(255,255,255,0.8)",
            boxShadow: "0 0 15px rgba(0,0,0,0.2)",
            borderRadius: "5px",
            marginRight: "55px",
          }}
        >
          <Card className="card-secondary">
            <CardHeader
              title={initialValues.title}
              style={{ textAlign: "center" }}
              subheader={""}
              titleTypographyProps={{ fontSize: "14px" }}
              action={
                <IconButton
                  onClick={() => {
                    setShowCard(false);
                  }}
                  aria-label="Minimizar"
                >
                  <RemoveCircleOutline />
                </IconButton>
              }
            />
            <CardContent>
              <Grid container spacing={0}>
                <Grid item xs={12}>
                  {initialValues.elements &&
                    initialValues.elements.map((itemOfArray) => {
                      return (
                        <div key={"key_" + values[itemOfArray.name] + generateRandomString(5)}>
                          <Item style={{ fontSize: "12px", padding: "0px" }}>
                            {" "}
                            <b style={{ color: "#000000" }}>
                              <small>{itemOfArray.title}</small>
                            </b>
                            <b style={{ color: values.color === "#FFFFFF" ? "#0066FF" : values.color }}>
                              <small>
                                {itemOfArray.type === "text" ? (
                                  values[itemOfArray.name] || values[itemOfArray.name] === 0 ? (
                                    values[itemOfArray.name]
                                  ) : (
                                    "--"
                                  )
                                ) : itemOfArray.type === "int" ? (
                                  new Intl.NumberFormat("en-EN").format(values[itemOfArray.name])
                                ) : itemOfArray.type === "percent" ? (
                                  values[itemOfArray.name] === null ? (
                                    "--"
                                  ) : (
                                    roundTo(values[itemOfArray.name], 2) + "%"
                                  )
                                ) : itemOfArray.type === "color" ? (
                                  <>
                                    <i
                                      style={{
                                        width: "18px",
                                        height: "18px",
                                        float: "left",
                                        marginLeft: "35px",
                                        opacity: 0.7,
                                        background: values.color === "#FFFFFF" ? "#0066FF" : values.color,
                                      }}
                                    />
                                    <b>{values[itemOfArray.name]}</b>
                                  </>
                                ) : (
                                  values[itemOfArray.name]
                                )}
                              </small>
                            </b>
                          </Item>
                        </div>
                      );
                    })}
                </Grid>
              </Grid>
            </CardContent>
            {values.shp === "seccion" || initialValues.titleButton === "Descargar PDF" ? (
              <>
                <Stack direction="row" justifyContent="center" alignItems="center" spacing={2}>
                  <Button size="small" style={{ borderRadius: "20px" }} variant="outlined" onClick={handleClick}>
                    {initialValues.titleButton !== undefined ? initialValues.titleButton : "Ver Detalle"}
                  </Button>
                </Stack>
                <br />
              </>
            ) : values.shp === "entidad" && values.titleButton !== "Descargar PDF" ? (
              <Stack direction="column" justifyContent="center" alignItems="center" spacing={1}>
                <div>
                  <i
                    style={{
                      width: "18px",
                      height: "18px",
                      float: "left",
                      borderRadius: "9px",
                      opacity: 0.7,
                      background: "#FF0001",
                      marginRight: 4,
                    }}
                  />
                  <b>Prioridad 1</b>
                </div>
                <div>
                  <i style={{ width: "18px", height: "18px", float: "left", borderRadius: "9px", opacity: 0.7, background: "#FFD000" }} />
                  <b>Prioridad 2</b>
                </div>
                <div>
                  <i style={{ width: "18px", height: "18px", float: "left", borderRadius: "9px", opacity: 0.7, background: "#666" }} />
                  <b>Prioridad 3</b>
                </div>
              </Stack>
            ) : (
              <></>
            )}
          </Card>
        </div>
      </div>
    </div>
  ) : (
    <div className="leaflet-control-container">
      <div className="leaflet-top leaflet-right">
        <div className="leaflet-bar leaflet-control" style={{ marginRight: "55px" }}>
          <IconButton
            aria-label="Abrir menu"
            onClick={() => setShowCard(true)}
            disabled={!values}
            style={{ backgroundColor: "white" }}
            sx={{
              height: "30px",
              width: "30px",
              borderRadius: "3px",
              "&:hover": { backgroundColor: values ? "#eeeeee!important" : "white!important" },
            }}
          >
            <InfoOutlined sx={{ fontSize: 25, fontWeight: 200, color: "#000" }} />
          </IconButton>
        </div>
      </div>
    </div>
  );
};

export default CardInfoViewDetail;
