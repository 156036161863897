import { useState, useEffect } from "react";
import Swal from "sweetalert2";

// Material UI
import {
  Box,
  Button,
  Card,
  CardContent,
  Icon,
  Stack,
  Grid,
  Typography,
} from "@mui/material";

// Componentes
import CustomTable from "@components/Tables/CustomTable";
import Download from "@components/Downloads/Download";
import BasicSelect from "@components/Selects/BasicSelect";

// Middlewares ,Servicios, Utils
import middleware from "@middlewares/middleware";
import certificacion from "@services/CertificationServices";

import { COLUMNS_TABLE } from "@data/constants/Certificacion";

import { setDiynamicColumns } from "@utils/Utilities";

// Hooks
import { useSorted } from "@hooks/useSorted";

const TableCertification = (props) => {
  const {
    data,
    catView,
    idAmbitoAgrupadorTable,
    ambitoUser,
    // idFilter,
  } = props;
  
  const [localData, setLocalData] = useState({
    ...data,
    idAmbitoAgrupador: idAmbitoAgrupadorTable
  });
  const [ambitoAgrupador, setAmbitoAgrupador] = useState(idAmbitoAgrupadorTable);

  const [isDownload, setIsDownloading] = useState(false);
  const [loading, setLoading] = useState(false);

  const [orderBy, setOrderBy] = useState("");

  const [total, setTotal] = useState(10);
  const [summary, setSummary] = useState([]);
  const [columnsDinamics, setColumnsDinamics] = useState([]);
  const [dataMovilization, setDataMovilization] = useState([]);

  const { handleRequestSort } = useSorted({
    total: total,
    setData: setLocalData,
  });
  
  const [refreshSchedule, setRefreshSchedule] = useState(false);
  
  const handleChangePagination = (value) => {
    setLocalData((prev) => ({
      ...prev,
      page: value.page,
      pageSize: value.pageSize,
    }));
  }

  useEffect(() => {
    if(data){
      setLocalData((prev) => ({...prev, filtered: data.filtered}));
    }
  }, [data]);

  useEffect(() => {
    if(idAmbitoAgrupadorTable){
      handleChangeAmbito(idAmbitoAgrupadorTable);
    }
  }, [idAmbitoAgrupadorTable]);

  useEffect(() => {
    if (localData.sorted.length) {
      setOrderBy(localData.sorted[0].id);
    } else setOrderBy("");
  }, [localData.sorted]);

  useEffect(() => {
    if(localData){
      getMovilization(localData)
    }
    //eslint-disable-next-line
  }, [localData]);

  const getMovilization = async (data) => {
    if(data.idAmbitoAgrupador === '') return;
    setLoading(true);
    try {
      const res = await certificacion.getAdvanceList(data);
      const { results, response, message } = res;
      if (results) {
        setTotal(response.total);
  
        const summaryValues = Object.values(response.totales);
        setSummary([...summaryValues]);
  
        setDataMovilization(response.data);
  
        handleMobilizationData(response)
  
      } else {
        setTotal(0);
        setSummary([]);
        setColumnsDinamics([]);
        setDataMovilization([]);
        throw new Error(message);
      }
    } catch (error) {
      Swal.fire({ title: error.message, icon: "warning" });
    } finally {
      setLoading(false);
    }
  };


  const handleChangeAmbito = (value) => {
    setAmbitoAgrupador(value);
    setLocalData((prev) => ({
      ...prev,
      page: 0,
      pageSize: 10,
      idAmbitoAgrupador: value,
      sorted: [],
    }));
  };
  const exportMovAdvanceTerritorialDetails = async () => {
    setIsDownloading(true);

    const { page, pageSize, ...params } = localData;

    try {
      const res = await certificacion.exportAdvanceList(params);
      const { results, message } = res;

      if (!results) {
        throw new Error(message);
      }
    } catch (error) {
      Swal.fire({
        title: error.message,
        icon: "warning",
      });
    } finally {
      setIsDownloading(false);
    }
  };

  useEffect(() => {
    if (refreshSchedule) {
      getMovilization(localData);
      setRefreshSchedule(false);
    }
    // eslint-disable-next-line
  }, [refreshSchedule]);


  const handleMobilizationData = ({ data }) => {
    const dataDinamic = data.map((item) => {
      return item;
    });

    const columns = setDiynamicColumns({
      data: dataDinamic[0],
      columns: [
        ...COLUMNS_TABLE,
      ],
    });

    const columnas = [
      ...columns,
    ];

    setColumnsDinamics(columnas);
    setDataMovilization(
      dataDinamic.map((item) => ({ ...item }))
    );
  };

  return (
    <>
      <Card className="card-primary" sx={{ marginBottom: "0px!important" }}>
        <CardContent>
          <Stack
            direction={"row"}
            justifyContent={"space-between"}
            alignItems={"center"}
            marginBottom={2}
          >
            <Typography variant="body2" fontWeight={700}>
              CERTIFICACIÓN
            </Typography>
          </Stack>

          {isDownload && (
            <Box marginBottom={2}>
              <Download format={"xlsx"} isDownload={isDownload} />
            </Box>
          )}

          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={4}>
              <BasicSelect
                name="agrupador"
                label="Agrupar por"
                options={catView}
                value={ambitoAgrupador}
                onChange={e => handleChangeAmbito(e.target.value)}
                sx={{ width: "100%" }}
                disabled={catView?.length <= 1}
                // loading={loadingCatalogs}
              />
            </Grid>
          </Grid>

          <Box
            display={'flex'}
            justifyContent={"end"}
            alignItems={"center"}
            marginBottom={2}
          >
          {middleware.checkMenuAction("Exportar") && ( 
            <Button
              variant="outlined"
              color="primaryDark"
              startIcon={<Icon>download</Icon>}
              size="small"
              disabled={isDownload}
              onClick={exportMovAdvanceTerritorialDetails}
            >
              Exportar
            </Button>
          )}
          </Box>

          <CustomTable
            rows={dataMovilization}
            columns={columnsDinamics}
            total={total}
            orderBy={orderBy}
            page={data.page}
            pageSize={data.pageSize}
            isLoading={loading}
            handlePagination={handleChangePagination}
            handleSort={handleRequestSort}
            summary={summary}
            summarySettings={{ start: 100, align: "center", }}
            disableCardType
          />
        </CardContent>
      </Card>
    </>
  );
};

export default TableCertification;
