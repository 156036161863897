import {
  Box,
  Modal,
  Card,
  CardContent,
  CardHeader,
  IconButton
} from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";


const ModalQR = (props) => {
  const { openModal, handleCloseModal, data } = props;

  return (
    <Modal open={openModal} onClose={(e) => handleCloseModal()}>
      <Card
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          width: "60%",
          maxHeight: "100%",
          overflowY: "auto",
          transform: "translate(-50%, -50%)",
          boxShadow: 24,
          p: 2,
        }}
      >
        <CardHeader
          title={"Escanear QR"}
          action={
            <IconButton aria-label="cancel" onClick={() => handleCloseModal()}>
              <CancelIcon />
            </IconButton>
          }
        />
        <CardContent>
          <Box>Data: {data}</Box>
        </CardContent>
      </Card>
    </Modal>
  );
};

export default ModalQR;
