import { PrivilegedRoute, ProtectedRoute } from "@middlewares";

import Users from "@pages/Users/Users";
import Menus from "@pages/Menus/Menus";
import Roles from "@pages/Roles/Roles";
import UserProfile from "@pages/Users/UserProfile";
import AgregarPerfil from "@pages/Roles/AgregarPerfil";
import MyUsers from "@pages/Users/MyUsers";
import MyUsersEdit from "@pages/Users/MyUsersEdit";
import Schedules from "@pages/Schedules";

const AdministrationRoutes = [
    {
        path: '/usuarios',
        element: <PrivilegedRoute><Users /></PrivilegedRoute>
    },
    {
        path: '/perfiles',
        element: <PrivilegedRoute><Roles /></PrivilegedRoute>
    },
    {
        path: '/perfiles/agregar',
        element: <ProtectedRoute><AgregarPerfil /></ProtectedRoute>
    },
    {
        path: '/horarios',
        element: <ProtectedRoute><Schedules /></ProtectedRoute>
    },
    {
        path: '/menus',
        element: <PrivilegedRoute><Menus /></PrivilegedRoute>
    },
    {
        path: '/mis-usuarios',
        element: <PrivilegedRoute><MyUsers /></PrivilegedRoute>
    },
    {
        path: '/mis-usuarios-editar',
        element: <ProtectedRoute><MyUsersEdit /></ProtectedRoute>
    },
    {
        path: "/mi-perfil",
        element: <ProtectedRoute><UserProfile /></ProtectedRoute>
    }
];

export default AdministrationRoutes;