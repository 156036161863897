import ApiExec, { downloadFile, ApiExecPublic } from "@utils/ApiExec";
import { encryptVars, DEBUG } from "@utils/global";
import { handleAttrs } from "@utils/Utilities";

class NumeraliaServices {
  getCatalogsSije = (params) => {
    return ApiExec(params, "sije/get-catalogs-sije", "POST")
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };
  getNumeraliaLnom = (params) => {
    return ApiExec(params, "numeralia/get-numeralia-lnom", "POST")
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };
  getNumeraliaSexo = (params) => {
    return ApiExec(params, "numeralia/get-numeralia-sexo", "POST")
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };
  getNumeraliaTipoSeccion = (params) => {
    return ApiExec(params, "numeralia/get-numeralia-tipo-seccion", "POST")
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };
  getNumeraliaEdadSexo = (params) => {
    return ApiExec(params, "numeralia/get-numeralia-edad-sexo", "POST")
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };
  getNumeraliaTerritorial = (params) => {
    return ApiExec(params, "numeralia/get-numeralia-territorial", "POST")
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };
  getPrioridadLNOM = (params) => {
    return ApiExec(params, "numeralia/get-numeralia-prioridad-mzn-lnom", "POST")
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };
  getPrioridadTotal = (params) => {
    return ApiExec(
      params,
      "numeralia/get-numeralia-prioridad-mzn-total",
      "POST"
    )
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };

  /* Nacional */
  getCatalogsNumeralia = (params) => {
    return ApiExec(params, "numeralia/national/get-catalogs-numeralia", "POST")
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };

  getNumeraliaTerritorialNacional = (params) => {
    return ApiExec(
      params,
      "numeralia/national/get-numeralia-territorial",
      "POST"
    )
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };

  getNumeraliaSexoNacional = (params) => {
    return ApiExec(params, "numeralia/national/get-numeralia-sexo", "POST")
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };

  getNumeraliaTipoSeccionNacional = (params) => {
    return ApiExec(
      params,
      "numeralia/national/get-numeralia-tipo-seccion",
      "POST"
    )
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };

  getNumeraliaPrioridadMznTotalNacional = (params) => {
    return ApiExec(
      params,
      "numeralia/national/get-numeralia-prioridad-mzn-total",
      "POST"
    )
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };

  getNumeraliaPrioridadMznLnomNacional = (params) => {
    return ApiExec(
      params,
      "numeralia/national/get-numeralia-prioridad-mzn-lnom",
      "POST"
    )
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };

  getNumeraliaEdadSexoNacional = (params) => {
    return ApiExec(params, "numeralia/national/get-numeralia-edad-sexo", "POST")
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };

  getNumeraliaLnomNacional = (params) => {
    return ApiExec(params, "numeralia/national/get-numeralia-lnom", "POST")
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };
}

const numeralia = new NumeraliaServices();
export default numeralia;
