import { useState, useEffect, useCallback } from "react";
import Swal from "sweetalert2";

// Material UI
import { Typography, Divider } from "@mui/material";

// Componentes
import AdminLayout from "@components/MainPage/AdminLayout";
import Map from "@components/Maps/CustomComponents/GenericMap";
import Filter from "@components/MapsModules/CreationOfPolygons/Filter";
import PolygonsCard from "@components/MapsModules/CreationOfPolygons/PolygonsCard";

// Servicios y utilidades
import CatalogService from "@services/CatalogServices";
import PolygonServices from "@services/MapServices/PolygonServices";
import { getVars, setVars } from "@utils/global";

// Data
import geojson_ex_pol_data from "@data/polygons/poligonos.json";

// Estilos de mapas
import { style_rutas, style_original_rutas } from "@components/Maps/Auxiliars/ColorFeature";

const elements = {
  seccion: [
    { title: "Seccion:", name: "seccion", type: "text" },
    { title: "Polígono:", name: "Poligono", type: "text" },
    { title: "Tipo:", name: "TipoSeccion", type: "text" },
    { title: "DLOC:", name: "DLOC", type: "text" },
    { title: "DFED:", name: "DFED", type: "text" },
    { title: "LNOM:", name: "Lnom", type: "text" },
  ],
  dfed: [{ title: "DFED:", name: "dfed", type: "text" }],
};

const infoGeneralElements = {
  title: "RESUMEN MUNICIPAL",
  elements: [
    { title: "Municipio:", name: "MunicipioReportes", color: "#000", type: "text" },
    { title: "Total Polígonos:", name: "TotalPoligonos", color: "#000", type: "text" },
    { title: "Total Secciones:", name: "TotalSecciones", color: "#000", type: "text" },
    { title: "Con Polígono:", name: "TotalSeccionesOcupadas", color: "#000", type: "text" },
    { title: "Sin Polígono:", name: "TotalSeccionesLibres", color: "#000", type: "text" },
  ],
};

const Maps = () => {
  const [polygons, setPolygons] = useState({ type: "FeatureCollection", features: [] });
  const [polygonsOLD, setPolygonsOLD] = useState(null);

  const [filter, setFilter] = useState({ idMunicipio: "", idDF: "", idDL: "" });
  const [isLoadingCatalogs, setIsLoadingCatalogs] = useState(true);
  const [selectedSections, setSelectedSections] = useState([]);
  const [valuesInfoGeneral, setValuesInfoGeneral] = useState(null);
  const [selectedSectionsProperties, setSelectedSectionsProperties] = useState([]);
  const [catalogs, setCatalogs] = useState({ municipios: [] });

  /* const formik = useFormik({
    initialValues: FilteringInterface,
    validationSchema: FilteringSchema,
    onSubmit: (values) => {
      handleVerification(handleAttrs(values));
    },
  }); */

  /*   const handleVerification = (values) => {
    if (!isEmptyOrNullObject(values)) {
      Swal.fire({
        title: `¡Atención!`,
        text: "handleVerification",
        icon: "info",
        reverseButtons: true,
        showCancelButton: false,
        confirmButtonText: "Ok",
      });
      //handleSave(values);
    }
  }; */

  const getEmptyMap = () => setPolygons({ type: "FeatureCollection", features: [] });

  const handleFilterExect = (_filter, polygon) => {
    let data_parameters = {};
    if (!polygon)
      data_parameters = {
        page: 0,
        pageSize: 10,
        filtered: _filter,
      };
    else
      data_parameters = {
        polygon,
        page: 0,
        pageSize: 10,
        filtered: _filter,
      };
    setFilter(_filter);

    setPolygons(null);
    PolygonServices.getSections(data_parameters)
      .then((res) => {
        if (res.results) setPolygons(res.response.data);
        else Swal.fire({ title: res.message, icon: "warning" });
      })
      .catch((e) => {
        Swal.fire({ title: e.message, icon: "warning" });
      });
    if (_filter.length === 1) {
      const data_pol_old = geojson_ex_pol_data.features.filter((item) => item.properties.idMunicipioReportes === _filter[0].value);
      setPolygonsOLD({
        type: "FeatureCollection",
        features: data_pol_old,
      });
      setVars("itemEdit", null);
      setVars("selectedSections", []);
      setVars("selectedSectionsProperties", []);
      setVars("selectedDFED", null);
      setVars("flagCreatePolygon", false); //agregue
      setVars("valueTab", 0);

      setSelectedSections([]);
      setSelectedSectionsProperties([]);
      PolygonServices.getInformationMunicipality({ page: 0, pageSize: 10, filtered: _filter })
        .then((res) => {
          if (res.results) {
            if (res.response.data.length !== 0) setValuesInfoGeneral(res.response.data[0]);
            else Swal.fire({ title: res.message, icon: "warning" });
          } else Swal.fire({ title: res.message, icon: "warning" });
        })
        .catch((e) => {
          Swal.fire({ title: e.message, icon: "warning" });
        });
    }
  };

  const loadCatalogs = useCallback(() => {
    let catalogsParams = [{ id: "municipios_dl", getAll: false }];
    CatalogService.getCatalogs(catalogsParams)
      .then((res) => {
        if (res.results) {
          if (res.response.errors.length > 0) {
            Swal.fire({
              title: "Algunos catálogos NO pudieron ser cargados. Contacte al administrador",
              icon: "warning",
            });
          }

          const catalogsResponse = res.response.catalogs;
          setCatalogs((prevState) => {
            return {
              ...prevState,
              municipios: catalogsResponse.municipios_dl ? catalogsResponse.municipios_dl : [],
            };
          });
        } else Swal.fire({ title: res.message, icon: "warning" });
      })
      .catch((e) => {
        Swal.fire({ title: e.message, icon: "warning" });
      })
      .finally(() => {
        setIsLoadingCatalogs(false);
      });
  }, []);

  useEffect(() => {
    setVars("itemEdit", null);
    setVars("selectedSections", []);
    setVars("selectedSectionsProperties", []);
    setVars("selectedDFED", null);
    setVars("valueTab", 0);
    getEmptyMap();
    loadCatalogs();
    // eslint-disable-next-line
  }, []);

  const handleRefreshShape = () => {
    setSelectedSections([]);
    handleFilterExect(filter, null);
  };

  const handleGetShapePolygonEvent = (item, flag) => {
    const _filter = [
      { id: "cat_secciones.idMunicipio", filter: "=", value: item.idMunicipio },
      { id: "cat_secciones.idDF", filter: "=", value: item.idDF },
      { id: "cat_secciones.idDL", filter: "=", value: item.idDL },
    ];
    if (flag) handleFilterExect(_filter, item.id);
    else handleFilterExect(_filter, null);
  };

  const handleChangeSections = (array, array_properties) => {
    setSelectedSections(array);
    setSelectedSectionsProperties(array_properties);
  };

  const handleSetSelectedSections = (e) => setSelectedSections(e);

  const FilterComponent = (
    <>
      <Filter catalogs={catalogs} loadingCatalogs={isLoadingCatalogs} handleFilter={handleFilterExect} />
      <br />

      <Divider sx={{ marginY: 1 }} />

      <Typography variant="h6" fontSize={16} align="center">
        Polígonos
      </Typography>

      <PolygonsCard
        selectedSections={selectedSections}
        polygons={polygons}
        selectedSectionsProperties={selectedSectionsProperties}
        handleSetSelectedSections={handleSetSelectedSections}
        handleRefreshShape={handleRefreshShape}
        handleGetShapePolygonEvent={handleGetShapePolygonEvent}
        handleEmptyProperties={() => {
          setSelectedSections([]);
          setSelectedSectionsProperties([]);
        }}
      />
    </>
  );

  const onEachFeature = (feature, layer, click) => {
    if (feature.properties[feature.properties.shp] !== undefined) layer.on({ click: click });
  };

  const highlightFeature = (e, params) => {
    const { resetHighlight, L, selectedFeature, setSelectedFeature, setFeature } = params;

    const layer = e.target;
    const properties = layer.feature.properties;

    if (selectedFeature && !properties.seccion) resetHighlight(selectedFeature);

    setFeature(layer.feature);
    setSelectedFeature(e);

    const flagCreatePolygon = getVars("flagCreatePolygon", null);
    if (!flagCreatePolygon) return true;

    const DFED = getVars("selectedDFED", null);
    if (properties.Selected === 1 && properties.Editable === 0) return true;

    if (DFED !== null && properties.DFED !== DFED) return true;

    if (properties.seccion) {
      //Vamos a recuperar el array selectedSections del storage para revisar si el feature se encuentra dentro
      let selectedSections = getVars("selectedSections", []);
      let selectedSectionsProperties = getVars("selectedSectionsProperties", []);

      if (selectedSections.length === 0) setVars("selectedDFED", properties.DFED);

      const found = selectedSections.find((element) => element === properties.seccion);

      if (found === undefined) {
        if (!L.Browser.ie && !L.Browser.opera && !L.Browser.edge) layer.bringToFront();
        layer.setStyle(style_rutas(layer.feature, true));

        selectedSections.push(properties.seccion);
        setVars("selectedSections", selectedSections);

        selectedSectionsProperties.push(properties);
        setVars("selectedSectionsProperties", selectedSectionsProperties);
      } else {
        setVars(
          "selectedSections",
          selectedSections.filter((element) => element !== properties.seccion)
        );
        const arr_selectedSectionsPropertiesTemp = selectedSectionsProperties.filter((element) => element.seccion !== properties.seccion);
        setVars("selectedSectionsProperties", arr_selectedSectionsPropertiesTemp);
        resetHighlight(e);
      }

      const array = getVars("selectedSections", []);
      const array_properties = getVars("selectedSectionsProperties", []);
      handleChangeSections(array, array_properties);
    } else {
      if (!L.Browser.ie && !L.Browser.opera && !L.Browser.edge) layer.bringToFront();
      layer.setStyle(style_rutas(layer.feature, true));
    }
  };

  return (
    <AdminLayout delPadding>
      <Map
        data={polygons}
        polygonsOLD={polygonsOLD}
        drawerLeft={{ open: true, component: FilterComponent, width: { sm: 300, md: 350, lg: 500, xl: 600 } }}
        cardInfo={{ initialValues: { title: "INFORMACIÓN", elements: elements[polygons?.shp ?? "dfed"] } }}
        infoGeneral={{ values: valuesInfoGeneral, initialValues: infoGeneralElements, oldShapeFlag: true }}
        highlightFeature={highlightFeature}
        onEachFeature={onEachFeature}
        centerByFeatures
        styleProp={{
          data: style_original_rutas,
          polygonsOLD: style_original_rutas,
          highlightFeature: style_rutas,
        }}
      />
    </AdminLayout>
  );
};

export default Maps;
