import { useEffect, useState } from "react";

import useWindowDimensions from "@hooks/useWindowDimensions";
//MUI
import { Container, Card, CardContent, Box, Typography, IconButton, Button, ListItemText } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2";
import { ChevronRight, Block, KeyboardDoubleArrowLeft } from "@mui/icons-material";
import { blue, grey, red } from "@mui/material/colors";

import Swal from "sweetalert2";

//COMPONENTS
import AdminLayout from "@components/MainPage/AdminLayout";
import ActionHeader from "@components/Containers/ActionHeader";
import LoadingForm from "@components/LinearProgress/LoadingForm";
import UserFilter from "@components/Compromisos/AuditoriaOrigen/Filter";
import BasicTable from "@components/Tables/BasicTable";
import Tree from "@components/Compromisos/AuditoriaOrigen/Tree";
import DetalleCompromiso from "@components/Compromisos/AuditoriaOrigen/DetalleCompromiso";
import DetalleUsuario from "@components/Compromisos/AuditoriaOrigen/DetalleUsuario";
import IconsMessage from "@components/Compromisos/AuditoriaOrigen/IconsMessage";

//SERVICES
import AuditServices from "@services/AuditoriaOrigenCapturaServices";
import middleware from "@middlewares/middleware";

const OrigenCaptura = () => {
  const { height } = useWindowDimensions();
  const [isLoadigForm, setIsLoadingForm] = useState(false);
  const [isLoadingTable, setIsLoadingTable] = useState(false);
  const [openLinearProgress, setOpenLinearProgress] = useState(false);
  const [responsable, setResponsable] = useState("");
  const [isExpanded, setIsExpanded] = useState(true);
  const [searchTree, setSearchTree] = useState("");
  const [dataCompromiso, setDataCompromiso] = useState([]);
  const [dataTable, setDataTable] = useState([]);
  const [userData, setUserData] = useState([]);
  const config = {
    label: "Usuario",
  };
  const titulos = [
    { id: "Nombre", label: "Nombre" },
    { id: "Municipio", label: "Municipio" },
    { id: "Domicilio", label: "Domicilio" },
    { id: "RegistadoPor", label: "Registrado por" },
  ];

  const modifiedRows = dataTable.map((row) => {
    const params = {
      Message: row.TelMensajes,
      Phone: row.Celular,
      WhatsApp: row.Telefono,
    };
    const showIcons = {
      Message: row.TelMensajes ? true : false,
      Phone: row.Celular ? true : false,
      WhatsApp: row.Telefono ? true : false,
    };
    const iconos = <IconsMessage params={params} showIcons={showIcons} iconColor={grey[900]} fontSize={22} />;
    return {
      ...row,
      Nombre: (
        <Box display="flex" sx={{ width: "100%" }}>
          <Box
            variant="text"
            color="primaryDark"
            size="small"
            sx={{
              textAlign: "left",
              width: "100%",
            }}
          >
            <ListItemText
              primary={
                <Typography variant="div" fontWeight={700} fontSize={15} color={blue[900]} sx={{ display: "flex", alignItems: "center" }}>
                  <Box>
                    {row.Nombre}
                    <Typography fontWeight={500} fontSize={13} color="text.secondary">
                      {row.Edad} Años
                    </Typography>
                  </Box>
                  {row.idEstatus === 2 ? (
                    <Box sx={{ marginLeft: "2vh" }}>
                      <Block fontSize="small" sx={{ color: red[400] }} />
                    </Box>
                  ) : null}
                </Typography>
              }
            />
          </Box>
          {iconos}
        </Box>
      ),
      RegistadoPor: (
        <Box display="flex" sx={{ width: "100%" }}>
          <Button
            variant="text"
            color="primaryDark"
            size="small"
            sx={{
              ":hover": {
                bgcolor: blue[900],
                color: "white",
              },
              textAlign: "left",
              width: "100%",
            }}
            onClick={() => {
              handleGetUser(row);
            }}
          >
            <ListItemText
              primary={
                <Typography variant="div" fontWeight={700} fontSize={15}>
                  {row.RegistadoPor}
                </Typography>
              }
              secondary={
                <Typography fontWeight={500} fontSize={13} color="text.secondary" sx={{ whiteSpace: "nowrap" }}>
                  {row.FechaRegistro}
                </Typography>
              }
            />
          </Button>
        </Box>
      ),
    };
  });

  const getInfo = async () => {
    try {
      setIsLoadingForm(true);
      setOpenLinearProgress(true);
      const { user } = responsable;
      const result = await AuditServices.getInfo(user);
      const { results, message, response } = result;
      if (results) {
        setDataCompromiso(response);
      } else {
        throw new Error(message);
      }
    } catch (error) {
      Swal.fire({
        title: error.message,
        icon: "warning",
      });
    } finally {
      setIsLoadingForm(false);
      setOpenLinearProgress(false);
    }
  };

  const getInfoTable = async () => {
    try {
      setIsLoadingTable(true);
      const { user } = responsable;
      const result = await AuditServices.getCaptureSource(user);
      const { results, message, response } = result;
      if (results) {
        setDataTable(response);
      } else {
        throw new Error(message);
      }
    } catch (error) {
      Swal.fire({
        title: error.message,
        icon: "warning",
      });
    } finally {
      setIsLoadingTable(false);
    }
  };

  const handleGetUser = async (value) => {
    try {
      setIsLoadingForm(true);
      setOpenLinearProgress(true);
      const result = await AuditServices.getUserInfo(value.idUsuarioPropietario);
      const { results, message, response } = result;
      if (results) {
        setUserData(response);
        setSearchTree({ response, datalength: modifiedRows.length });
      } else {
        throw new Error(message);
      }
    } catch (error) {
      Swal.fire({
        title: error.message,
        icon: "warning",
      });
    } finally {
      setIsLoadingForm(false);
      setOpenLinearProgress(false);
    }
  };

  useEffect(() => {
    setDataTable([]);
    setDataCompromiso([]);
    setUserData([]);
    if (responsable) {
      getInfo();
      getInfoTable();
    }
    //eslint-disable-next-line
  }, [responsable]);

  return (
    <AdminLayout>
      <Container maxWidth="false">
        <ActionHeader title="Consulta: Origen de Captura" />
        <LoadingForm
          isLoading={isLoadigForm}
          isOpen={openLinearProgress}
          setIsOpen={() => setOpenLinearProgress(!openLinearProgress)}
          loadinMessage={"Cargando..."}
        />
        <Card className="card-primary">
          <CardContent>
            <Grid2 container spacing={2}>
              <Grid2 item xs={12} md={4}>
                <UserFilter values={responsable} setValues={setResponsable} label={config.label} />
              </Grid2>
            </Grid2>
          </CardContent>
        </Card>
        <Grid2 container direction={{ md: "row-reverse" }}>
          {!isExpanded && (
            <IconButton onClick={() => setIsExpanded(!isExpanded)} aria-label="open Drawer" color={isExpanded ? "default" : "primary"}>
              <span style={{ fontFamily: "Arial, sans-serif", fontWeight: "bold", fontSize: "16px", color: blue[800] }}>DETALLES</span>{" "}
              {isExpanded ? <ChevronRight /> : <KeyboardDoubleArrowLeft />}
            </IconButton>
          )}
        </Grid2>
        <Grid2 container spacing={2}>
          <Grid2 item xs={12} md={isExpanded ? 5 : 7}>
            <Card className="card-primary" sx={{ maxHeight: height * 0.75, overflow: "scroll" }}>
              <CardContent>
                <Typography variant="h5" color="textPrimary" align="center" mb={2} style={{ fontWeight: "bold" }}>
                  Registros de captura del compromiso
                </Typography>
                <BasicTable hcolumns={titulos} rows={modifiedRows} showPagination={false} stickyHeader={true} isLoading={isLoadingTable} />
              </CardContent>
            </Card>
          </Grid2>
          <Grid2 item xs={12} md={isExpanded ? 4 : 5}>
            <Card className="card-primary" sx={{ maxHeight: height * 0.75, overflow: "scroll" }}>
              <CardContent>
                <Typography variant="h5" color="textPrimary" align="center" mb={2} style={{ fontWeight: "bold" }}>
                  Origen de los usuarios que capturaron al compromiso
                </Typography>
                <Tree
                  setLoading={(e) => {
                    setIsLoadingForm(e);
                    setOpenLinearProgress(e);
                  }}
                  searchTreeValue={searchTree}
                  prioritario={responsable}
                />
              </CardContent>
            </Card>
          </Grid2>
          {isExpanded && (
            <Grid2 item xs={12} md={3}>
              <DetalleCompromiso
                data={dataCompromiso}
                isExpanded={isExpanded}
                setIsExpanded={setIsExpanded}
                label={"Detalle del compromiso"}
                enableEdit={middleware.checkMenuAction("Editar")}
                setLoading={(isLoading) => {
                  setIsLoadingForm(isLoading);
                  setOpenLinearProgress(isLoading);
                }}
              />
              <DetalleUsuario data={userData} isExpanded={isExpanded} setIsExpanded={setIsExpanded} label={"Detalle del usuario"} option={false} />
            </Grid2>
          )}
        </Grid2>
      </Container>
    </AdminLayout>
  );
};

export default OrigenCaptura;
