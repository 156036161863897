import { useEffect, useState, useRef } from "react";

// Charts
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

// Material UI
import { CircularProgress, Card, CardContent, Typography } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2";
import Swal from "sweetalert2";

import { numberWithCommas } from "@utils/Utilities/";
import numeralia from "@services/NumeraliaServices";

// Hooks
import useWindowDimensions from "@hooks/useWindowDimensions";

const PieChartSexo = ({ dataFiltered, title }) => {
  const colors = ["#FF6486", "#37A3EB", "#999999"];

  const { height, width } = useWindowDimensions();
  const [data, setData] = useState([]);
  const [currentDate] = useState(new Date().toLocaleString());
  const [loading, setLoading] = useState(false);

  const chartRef = useRef(null);
  const seriesData = data.map((item, index) => ({
    name: `<span style="color: ${colors[index]}">${
      item.label
    } ${numberWithCommas(item.data)}</span>`,
    y: parseFloat(item.data),
    x: item.label,
    color: colors[index],
    dataLabels: {
      style: {
        fontSize: width > 1440 ? width * 0.009 : "13px",
        color: colors[index],
      },
    },
  }));
  const options = {
    credits: {
      enabled: false,
    },
    chart: {
      type: "pie",
      height: width > 1440 ? height * 0.4 : "auto",
    },
    title: {
      text: "",
    },

    xAxis: {
      categories: data.map((item) => item.Estatus),
    },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        dataLabels: {
          enabled: true,
          format: "{point.percentage:.1f} %", // El porcentaje
          distance: 0.1,
        },
        showInLegend: true,
      },
    },
    legend: {
      itemStyle: {
        fontSize: width > 1440 ? `${width * 0.0085}px` : "12px",
      },
    },
    tooltip: {
      formatter: function () {
        const regex = /<span[^>]*>([^<]+)<\/span>/;
        const coincidencia = this.point.name.match(regex);
        const partes = coincidencia[1].split(" ");
        const resultado =
          partes.length === 3 ? partes[0] + " " + partes[1] : partes[0];
        // Formato personalizado del tooltip
        return `<span style="font-size: ${
          width > 1440 ? `${width * 0.0085}px` : "12px"
        }"><b>${resultado}  ${this.point.percentage.toFixed(1)} %</b>
      <br/>Total: ${numberWithCommas(this.point.y)}</span>`; // Muestra el nombre y el valor total en el tooltip
      },
    },
    series: [
      {
        type: "pie",
        name: "Total",
        data: seriesData,
      },
    ],
  };

  const getNumeraliaSexoNacional = async (body) => {
    setLoading(true);
    try {
      const result = await numeralia.getNumeraliaSexoNacional(body);
      const { results, response, message } = result;
      if (!results) {
        throw new Error(message);
      }

      const [data] = response.data;
      const newData = [
        {
          data: data.TotalMujeres ? data.TotalMujeres : 0,
          label: "Mujeres",
        },
        {
          data: data.TotalHombres ? data.TotalHombres : 0,
          label: "Hombres",
        },
      ];

      if (data.TotalNoBinario) {
        newData.push({
          data: data.TotalNoBinario ? data.TotalNoBinario : 0,
          label: "No Binario",
        });
      }

      setData(newData);
    } catch (error) {
      Swal.fire({
        title: error.message,
        icon: "warning",
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getNumeraliaSexoNacional({
      idAmbitoAgrupador: dataFiltered.idAmbitoAgrupador,
      idEstado: dataFiltered.idEstado,
      filtered: dataFiltered.filtered,
    });
  }, [dataFiltered]);

  return (
    <Card className="card-primary">
      <CardContent>
        <div>
          <Typography variant="subtitle2" fontWeight={600} textAlign={"end"}>
            {title}
          </Typography>
          <Typography variant="body2" textAlign={"end"} color={"GrayText"}>
            {currentDate}
          </Typography>
        </div>
        {loading ? (
          <Grid2
            container
            xs={12}
            height={width > 1440 ? height * 0.4 : 400}
            alignItems="center"
            justifyContent="center"
          >
            <CircularProgress size={50} />
          </Grid2>
        ) : (
          <HighchartsReact
            highcharts={Highcharts}
            options={options}
            ref={chartRef}
          />
        )}
      </CardContent>
    </Card>
  );
};

export default PieChartSexo;
