import { useState, useEffect } from "react";
import Swal from "sweetalert2";

// Material UI
import { Grid, Button, Stack, Tabs, Tab } from "@mui/material";

// Componentes
import BasicSelect from "@components/Selects/BasicSelect";
import { TabPanel, a11yProps } from "@components/Maps/Varius/TabComponent";

// Servicios y Utils
import CatalogService from "@services/CatalogServices";
import { setVars, getVars } from "@utils/global";

const FilterTabs = ({ catalogs, loadingCatalogs, handleFilter, entidad }) => {
  const [catMunicipios, setCatMunicipios] = useState([]);
  const [catDLOC, setCatDLOC] = useState([]);
  const [catDFED, setCatDFED] = useState([]);
  const [catSecciones, setCatSecciones] = useState([]);
  const [isLoadingCatMunicipios, setIsLoadingMunicipios] = useState(false);
  const [isLoadingCatDL, setIsLoadingCatDL] = useState(false);
  const [isLoadingCatDF, setIsLoadingCatDF] = useState(false);
  const [isLoadingCatSecciones, setIsLoadingCatSecciones] = useState(false);
  const [filter, setFilter] = useState({
    idEntidad: "",
    idMunicipio: "",
    idDF: "",
    idDL: "",
    Seccion: "",
  });
  const [value, setValue] = useState(getVars("valueTab", 0));

  const handleChange = (event, newValue) => {
    setCatSecciones([]);
    setFilter({
      ...filter,
      idMunicipio: "",
      idDF: "",
      idDL: "",
      Seccion: "",
    });
    setVars("valueTab", newValue);
    setValue(newValue);
  };

  const getCatalogsWithStored = () => {
    const filterSavedMapMunicipio = getVars("filterSavedMapMunicipio", []);
    const filterSavedMapSeccion = getVars("filterSavedMapSeccion", []);
    const filterSavedMapDFED = getVars("filterSavedMapDFED", []);
    const filterSavedMapDLOC = getVars("filterSavedMapDLOC", []);
    setCatMunicipios(filterSavedMapMunicipio);
    setCatDFED(filterSavedMapDFED);
    setCatDLOC(filterSavedMapDLOC);
    setCatSecciones(filterSavedMapSeccion);
  };

  useEffect(() => {
    if (entidad) {
      const val = { target: { value: entidad } };
      handleChangeEntidad(val);
    }

    let filter_saved = getVars("filterSavedMap", {
      idEntidad: "",
      idMunicipio: "",
      idDF: "",
      idDL: "",
      Seccion: "",
    });
    if (filter_saved.idEntidad !== "" || filter_saved.idMunicipio !== "" || filter_saved.Seccion !== "") {
      setFilter(filter_saved);
      getCatalogsWithStored();
    }
    // eslint-disable-next-line
  }, []);

  const loadCatalogsByEntity = (idEntidad = null) => {
    const elemento_seleccionar = [{ value: 0, label: "Selecciona una entidad" }];
    const elemento_todos = [{ value: 0, label: "TODOS" }];
    const catalogsParams = idEntidad ? [{ id: "idEntidad", value: idEntidad, filter: "=" }] : [];

    if (!idEntidad) {
      setCatMunicipios(elemento_seleccionar);
      setCatDFED(elemento_seleccionar);
      setCatDLOC(elemento_seleccionar);
      return true;
    }
    setIsLoadingCatDL(true);
    setIsLoadingCatDF(true);
    setIsLoadingMunicipios(true);

    CatalogService.getCatalogsNationalMunicipalities(catalogsParams)
      .then((res) => {
        if (res.success && res.results) setCatMunicipios(elemento_todos.concat(res.response.data));
      })
      .catch((e) => {
        Swal.fire({ title: e.message, icon: "warning" });
      })
      .finally(() => {
        setIsLoadingMunicipios(false);
      });

    CatalogService.getCatalogsNationalDFED(catalogsParams)
      .then((res) => {
        if (res.success && res.results) setCatDFED(elemento_todos.concat(res.response.data));
      })
      .catch((e) => {
        Swal.fire({ title: e.message, icon: "warning" });
      })
      .finally(() => {
        setIsLoadingCatDF(false);
      });

    CatalogService.getCatalogsNationalDLOC(catalogsParams)
      .then((res) => {
        if (res.success && res.results) setCatDLOC(elemento_todos.concat(res.response.data));
      })
      .catch((e) => {
        Swal.fire({ title: e.message, icon: "warning" });
      })
      .finally(() => {
        setIsLoadingCatDL(false);
      });
  };

  const loadCatalogsByMunicipio = (idMunicipio = null) => {
    let catalogsParams = [];

    if (filter.idEntidad) {
      catalogsParams.push({ id: "idEntidad", value: filter.idEntidad, filter: "=" });
    }
    if (idMunicipio) {
      catalogsParams.push({ id: "idMunicipio", value: idMunicipio, filter: "=" });
    }
    if (!idMunicipio) {
      setCatSecciones([{ value: 0, label: "Selecciona un municipio" }]);
      return true;
    }
    setIsLoadingCatSecciones(true);

    CatalogService.getCatalogsNationalSections(catalogsParams)
      .then((res) => {
        if (res.success && res.results) setCatSecciones([{ value: 0, label: "TODAS" }].concat(res.response.data));
      })
      .catch((e) => {
        Swal.fire({ title: e.message, icon: "warning" });
      })
      .finally(() => {
        setIsLoadingCatSecciones(false);
      });
  };

  const loadCatalogsByDFED = (idDF = null) => {
    let catalogsParams = [];
    if (filter.idEntidad) {
      catalogsParams.push({ id: "idEntidad", value: filter.idEntidad, filter: "=" });
    }
    if (idDF) {
      catalogsParams.push({ id: "idDF", value: idDF, filter: "=" });
    }
    if (!idDF) {
      setCatSecciones([{ value: 0, label: "Selecciona un distrito" }]);
      return true;
    }
    setIsLoadingCatSecciones(true);

    CatalogService.getCatalogsNationalSections(catalogsParams)
      .then((res) => {
        if (res.success && res.results) setCatSecciones([{ value: 0, label: "TODAS" }].concat(res.response.data));
      })
      .catch((e) => {
        Swal.fire({ title: e.message, icon: "warning" });
      })
      .finally(() => {
        setIsLoadingCatSecciones(false);
      });
  };

  const loadCatalogsByDLOC = (idDL = null) => {
    let catalogsParams = [];
    if (filter.idEntidad) {
      catalogsParams.push({ id: "idEntidad", value: filter.idEntidad, filter: "=" });
    }
    if (idDL) {
      catalogsParams.push({ id: "idDL", value: idDL, filter: "=" });
    }
    if (!idDL) {
      setCatSecciones([{ value: 0, label: "Selecciona un distrito" }]);
      return true;
    }
    setIsLoadingCatSecciones(true);

    CatalogService.getCatalogsNationalSections(catalogsParams)
      .then((res) => {
        if (res.success && res.results) setCatSecciones([{ value: 0, label: "TODAS" }].concat(res.response.data));
      })
      .catch((e) => {
        Swal.fire({ title: e.message, icon: "warning" });
      })
      .finally(() => {
        setIsLoadingCatSecciones(false);
      });
  };

  const handleChangeEntidad = (event) => {
    const idEntidad = event.target.value;
    setFilter({
      idEntidad: idEntidad,
      idMunicipio: "",
      idDF: "",
      idDL: "",
      Seccion: "",
    });
    if (idEntidad > 0) loadCatalogsByEntity(idEntidad);
    else loadCatalogsByEntity();
  };

  const handleChangeMunicipio = (e) => {
    const value = e.target.value;
    setFilter({
      //...filter,
      idEntidad: filter.idEntidad,
      idMunicipio: value,
      Seccion: "",
    });
    setVars("filterSavedMap", {
      ...filter,
      //idEntidad: filter.idEntidad,
      idMunicipio: value,
      Seccion: "",
    });
    if (value > 0) loadCatalogsByMunicipio(value);
    else loadCatalogsByMunicipio();
  };

  const handleChangeDFED = (e) => {
    const value = e.target.value;
    setVars("flagCreatePolygon", false);
    setFilter({ ...filter, [e.target.name]: value });
    if (value > 0) loadCatalogsByDFED(value);
    else loadCatalogsByDFED();
  };

  const handleChangeDLOC = (e) => {
    const value = e.target.value;
    setVars("flagCreatePolygon", false);
    setFilter({ ...filter, [e.target.name]: value });
    if (value > 0) loadCatalogsByDLOC(value);
    else loadCatalogsByDLOC();
  };

  const handleChangeSeccion = (e) => setFilter({ ...filter, [e.target.name]: e.target.value });

  const handleClick = () => {
    let filter_data = [];
    if (filter.idEntidad !== "" && filter.idEntidad !== undefined) {
      filter_data.push({
        id: "tb.idEntidad",
        filter: filter.idEntidad === 0 ? "IN" : "=",
        value: filter.idEntidad === 0 ? 0 : filter.idEntidad,
      });
    }
    if (filter.idMunicipio !== "" && filter.idMunicipio !== undefined) {
      if (filter.idMunicipio !== 0)
        filter_data.push({
          id: "tb.idMunicipio",
          filter: "=",
          value: filter.idMunicipio,
        });
    }
    if (filter.idDL !== "" && filter.idDL !== undefined) {
      if (filter.idDL !== 0)
        filter_data.push({
          id: "tb.idDL",
          filter: "=",
          value: filter.idDL,
        });
    }
    if (filter.idDF !== "" && filter.idDF !== undefined) {
      if (filter.idDF !== 0)
        filter_data.push({
          id: "tb.idDF",
          filter: "=",
          value: filter.idDF,
        });
    }
    if (filter.Seccion !== "" && filter.Seccion !== undefined) {
      if (filter.Seccion !== 0)
        filter_data.push({
          id: "tb.Seccion",
          filter: "=",
          value: filter.Seccion,
        });
    }

    handleFilter(filter_data, value);
    setVars("filterSavedMapMunicipio", catMunicipios);
    setVars("filterSavedMapSeccion", catSecciones);
    setVars("filterSavedMapDFED", catDFED);
    setVars("filterSavedMapDLOC", catDLOC);
    setVars("filterSavedMap", {
      idEntidad: filter.idEntidad,
      idMunicipio: filter.idMunicipio,
      idDF: filter.idDF,
      idDL: filter.idDL,
      Seccion: filter.Seccion,
    });
  };

  return (
    <>
      <Grid container rowSpacing={1}>
        <Grid item xs={12}>
          <Tabs value={value} onChange={handleChange} aria-label="Tabs" variant="scrollable" scrollButtons={false}>
            <Tab label="Municipio" {...a11yProps(0)} sx={{ flex: 1 }} />
            <Tab label="DFED" {...a11yProps(1)} sx={{ flex: 1 }} />
            <Tab label="DLOC" {...a11yProps(2)} sx={{ flex: 1 }} />
          </Tabs>
        </Grid>
        {!entidad ? (
          <Grid item xs={12}>
            <BasicSelect
              label="Entidad"
              name="entidad"
              options={catalogs.entidades}
              value={filter.idEntidad}
              onChange={handleChangeEntidad}
              isLoading={loadingCatalogs}
              sx={{ width: "100%" }}
            />
          </Grid>
        ) : (
          <></>
        )}

        <Grid item xs={12}>
          <TabPanel value={value} index={0}>
            <BasicSelect
              name="idMunicipio"
              label="Muncipio"
              options={catMunicipios}
              value={filter.idMunicipio >= 0 ? filter.idMunicipio : ""}
              onChange={handleChangeMunicipio}
              isLoading={loadingCatalogs || isLoadingCatMunicipios}
              sx={{ width: "100%" }}
            />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <BasicSelect
              name="idDF"
              label="DFED"
              options={catDFED}
              value={filter.idDF >= 0 ? filter.idDF : ""}
              onChange={handleChangeDFED}
              isLoading={loadingCatalogs || isLoadingCatDF}
              sx={{ width: "100%" }}
            />
          </TabPanel>
          <TabPanel value={value} index={2}>
            <BasicSelect
              name="idDL"
              label="DLOC"
              options={catDLOC}
              value={filter.idDL >= 0 ? filter.idDL : ""}
              onChange={handleChangeDLOC}
              isLoading={loadingCatalogs || isLoadingCatDL}
              sx={{ width: "100%" }}
            />
          </TabPanel>
        </Grid>

        <Grid item xs={12}>
          <BasicSelect
            name="Seccion"
            label="Sección"
            options={catSecciones}
            value={filter.Seccion || ""}
            onChange={handleChangeSeccion}
            isLoading={loadingCatalogs || isLoadingCatSecciones}
            sx={{ width: "100%" }}
          />
        </Grid>
      </Grid>

      <br></br>

      <Stack direction="row" justifyContent="center" alignItems="center" spacing={2}>
        <Button
          size="small"
          style={{ borderRadius: "20px", alignSelf: "center" }}
          variant="outlined"
          disabled={filter.idMunicipio === "" && filter.idDF === "" && filter.idDL === ""}
          onClick={handleClick}
        >
          Filtrar
        </Button>
      </Stack>
    </>
  );
};

export default FilterTabs;
