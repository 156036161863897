import React, { useState } from "react";
import { Draggable } from "react-beautiful-dnd";
import {
  Table,
  TableRow,
  TableCell,
  Icon,
  IconButton,
  Switch,
  Collapse,
  Tooltip
} from "@mui/material";
import PropTypes from "prop-types";
import Swal from "sweetalert2";
// import "@sweetalert2/theme-material-ui/material-ui.css";

import MenuService from "@services/MenuServices";
import { MENUS_PRIVILEGES } from "@data/constants"

const MenuRowDraggable = (props) => {
  const {
    menu,
    index,
    setIsOpen,
    setIsLoading,
    handleReloadList,
    setEdit,
    edit,
    setEditData
  } = props;
  const [submenus] = useState(() => {
    if (menu.submenus.length > 0) return menu.submenus;
    return [];
  });
  const [menu4Select] = useState(menu);
  const columns = MENUS_PRIVILEGES;
  const colspanSizeSub = columns.length + 3;
  const [open, setOpen] = useState(false);

  const getItemStyle = (isDragging, draggableStyle) => ({
    // styles we need to apply on draggables
    ...draggableStyle,

    ...(isDragging && {
      background: "rgb(235,235,235)",
    }),
  });

  const handleSwitch = (e, type, index, col) => {
    e.preventDefault();
    let checkedValue = e.target.checked ? 1 : 0;
    let idMenu = type === "menu" ? menu4Select.idMenu : submenus[index].idMenu;
    showImpact4Change(idMenu, col, checkedValue, type, index);
  };

  const showImpact4Change = (id, column, value, type, index) => {
    setIsLoading(true);
    setIsOpen(true);

    MenuService.getImpact4Change({ idMenu: id, Campo: column, Valor: value })
      .then((res) => {
        if (res.success) {
          let itAffects =
            res.response.AffectedUsers + res.response.AffectedUserRoles > 0
              ? true
              : false;
          selectApplyChange(
            itAffects,
            res.response,
            id,
            column,
            value,
            type,
            index
          );
        }
      })
      .catch((e) => {
        Swal.fire({ title: e.message, icon: "warning" });
      })
      .finally(() => {
        setIsLoading(false);
        setIsOpen(false);
      });
  };

  const selectApplyChange = (
    itAffects,
    response,
    id,
    col,
    value,
    type
  ) => {
    if (itAffects) {
      const inputOptions = new Promise((resolve) => {
        setTimeout(() => {
          resolve({
            0: "Aplicar a partir de ahora",
            1: "Aplicar a todos",
          });
        }, 1000);
      });

      Swal.fire({
        title: "Advertencia",
        html: `Esta acción puede modificar el privilegio a ${response.AffectedUserRoles} perfil(es) y ${response.AffectedUsers} usuario(s) que tienen asignado este menú. ¿Qué acción desea realizar?`,
        icon: "warning",
        input: "radio",
        inputOptions: inputOptions,
        showCancelButton: true,
        cancelButtonText: "Cancelar",
        confirmButtonText: "Aplicar",
        confirmButtonColor: "#1976d2",
        reverseButtons: true,
        allowOutsideClick: false,
        allowEscapeKey: false,
        inputValidator: (action) => {
          if (!action) {
            return "Debe seleccionar una opción!";
          } else {
            if (action) {
              applyChange(type, id, col, value, parseInt(action));
            }
          }
        },
      });
    } else {
      applyChange(type, id, col, value, 0);
    }
  };

  const applyChange = (type, id, col, value, action) => {
    if (type === "submenu") {
      updatePrivillege(id, col, value, action);
    } else if (type === "menu") {
      updatePrivillege(id, col, value, action);
    }
  };

  const updatePrivillege = (id, column, value, action) => {
    setIsLoading(true);
    setIsOpen(true);
    
    MenuService.changeAccess(
      { idMenu: id, Campo: column, Valor: value, Accion: action }
    )
      .then((res) => {
        if (res.success && res.results) {
          handleReloadList();
          Swal.fire({ title: res.message, icon: "success" });
        }
      })
      .catch((e) => {
        Swal.fire({ title: e.message, icon: "warning" });
      })
      .finally(() => {
        setIsLoading(false);
        setIsOpen(false);
      });
  };

  return (
    <Draggable draggableId={`${menu.idMenu}`} index={index}>
      {(provided, snapshot) => (
        <>
          <TableRow
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            style={getItemStyle(
              snapshot.isDragging,
              provided.draggableProps.style
            )}
          >
            <TableCell width="10%">
              <Tooltip title="Editar Menu">
                <IconButton
                  aria-label="edit"
                  size="small"
                  onClick={() => { setEditData(menu); setEdit(!edit) }}
                >
                  <Icon color="warning">edit</Icon>
                </IconButton>
              </Tooltip>
              {menu.submenus.length > 0 && (
                <Tooltip title="Ver submenus">
                  <IconButton
                    aria-label="expand row"
                    size="small"
                    onClick={() => setOpen(!open)}
                  >
                    {open ? (
                      <Icon>keyboard_arrow_up_icon</Icon>
                    ) : (
                      <Icon>keyboard_arrow_down_icon</Icon>
                    )}
                  </IconButton>
                </Tooltip>
              )}
            </TableCell>
            <TableCell width="5%" align="right">
              {menu.Icono ? (
                <Icon>{menu.Icono}</Icon>
              ) : menu.Siglas ? (
                menu.Siglas
              ) : (
                ""
              )}
            </TableCell>
            <TableCell width="25%">
              {menu.Menu} <br />
              <small>{menu.Ruta}</small>
            </TableCell>
            {columns.map((col) => {
              return (
                <TableCell align="center" key={Math.random()} width="10%">
                  <Switch
                    aria-label={`Switch ${menu[col]}`}
                    onChange={(e) => handleSwitch(e, "menu", 0, col)}
                    checked={menu4Select[col] === 1 ? true : false}
                  />
                </TableCell>
              );
            })}
          </TableRow>
          <TableRow>
            <TableCell sx={{ padding: 0 }} colSpan={colspanSizeSub}>
              <Collapse in={open} timeout="auto" unmountOnExit>
                <Table size="small" aria-label="purchases">
                  {submenus.map((submenu, i) => {
                    return (
                      <TableRow key={submenu.id}>
                        <TableCell width="10%">
                          <Tooltip title="Editar Menu">
                            <IconButton
                              aria-label="edit"
                              size="small"
                              onClick={() => { setEditData(submenu); setEdit(!edit) }}
                            >
                              <Icon color="warning">edit</Icon>
                            </IconButton>
                          </Tooltip>
                        </TableCell>
                        <TableCell align="right" width="5%" colSpan={2}>
                          {submenu.Icono ? (
                            <Icon>{submenu.Icono}</Icon>
                          ) : submenu.Siglas ? (
                            submenu.Siglas
                          ) : (
                            ""
                          )}
                        </TableCell>
                        <TableCell width="25%">
                          {submenu.Menu} <br />
                          <small>{submenu.Ruta}</small>
                        </TableCell>
                        {columns.map((col) => {
                          return (
                            <TableCell
                              align="center"
                              key={Math.random()}
                              width="10%"
                            >
                              <Switch
                                aria-label={`Switch ${submenu[col]}`}
                                onChange={(e) =>
                                  handleSwitch(e, "submenu", i, col)
                                }
                                checked={submenu[col] === 1 ? true : false}
                              />
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    );
                  })}
                </Table>
              </Collapse>
            </TableCell>
          </TableRow>
        </>
      )
      }
    </Draggable >
  );
};

MenuRowDraggable.propTypes = {
  menu: PropTypes.object.isRequired,
  handleReloadList: PropTypes.func.isRequired,
  setIsOpen: PropTypes.func,
  setIsLoading: PropTypes.func,
  setIsSuccess: PropTypes.func,
};

export default MenuRowDraggable;
