import React, { useEffect, useState } from 'react';
import useWindowDimensions from '@hooks/useWindowDimensions';
import {
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Card, CardContent, TableSortLabel,
  IconButton, TablePagination
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import Swal from 'sweetalert2';

//import movilization from "@services/MovilizationServices";

const BingoTable = (props) => {
  const { setIsLoadingForm, setOpenLinearProgress, lastInsert, updatedData } = props;
  const { height } = useWindowDimensions();

  const [highlightedRow, setHighlightedRow] = useState(-1);
  const [orderBy, setOrderBy] = useState('');
  const [order, setOrder] = useState('asc');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [data, setData] = useState([]);

  useEffect(() => {
    if (Array.isArray(lastInsert) && lastInsert.length > 0) {
      const uniqueNewData = lastInsert.filter(
        insert => !data.some(item => parseInt(item.FolioBingo) === parseInt(insert.FolioBingo))
      );
  
      if (uniqueNewData.length > 0) {
        setData(prevData => {
          const combinedData = [...prevData, ...uniqueNewData];
          const uniqueDataMap = new Map(
            combinedData.map(item => [parseInt(item.FolioBingo), item])
          );
          return mapData(
            Array.from(uniqueDataMap.values()).sort(
              (a, b) => parseInt(a.FolioBingo) - parseInt(b.FolioBingo)
            )
          );
        });
      }
    }
    // eslint-disable-next-line
  }, [lastInsert])
  const cellBorderStyle = {
    border: '1px solid rgba(0, 0, 0, 0.1)'
  };

  const headerCellStyle = {
    backgroundColor: '#b1b1b1',
    fontWeight: 'bold'
  };

  const mapData = (data) => data?.map((item, index) => ({
    accion: (
      <IconButton onClick={() => handleDeleteClick(item)} sx={{ m: 0, p: 0 }} color='error'>
        <DeleteIcon />
      </IconButton>
    ),
    ...item
  }));

  const handleSort = (field) => {
    if (orderBy === field && order === 'asc') {
      setOrder('desc');
    } else {
      setOrder('asc');
    }
    setOrderBy(field);
  };

  const handleDeleteClick = (item) => {
    Swal.fire({
      title: 'Advertencia!',
      text: "¿Está seguro que desea eliminar el registro?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sí, eliminar!',
      cancelButtonText: 'No, Cancelar',
      allowOutsideClick: false,
      allowEscapeKey: false
    }).then((result) => {
      if (result.isConfirmed) {
        deleteBingo(item);
      }
    });
  };

  const deleteBingo = async (item) => {
    setIsLoadingForm(true);
    setOpenLinearProgress(true);

    await new Promise(resolve => setTimeout(resolve, 2000));

    try {
      const result = await "";
      const { results= true, message="Eliminado con éxito" } = result;

      if (results) {
        Swal.fire({
          title: message,
          icon: 'success',
          allowOutsideClick: false,
          allowEscapeKey: false
        }).then((res) => {
          setData(prevData => {
            const filteredData = prevData.filter(dataItem => parseInt(dataItem.FolioBingo) !== parseInt(item.FolioBingo));
            updatedData(filteredData);
            return filteredData;
          });
          //getBingos(item.idCasilla);
        });
      } else {
        Swal.fire({
          icon: 'warning',
          title: message
        });
      }
    } catch (error) {
      Swal.fire({
        icon: 'warning',
        title: error.message
      });
    } finally {
      setIsLoadingForm(false);
      setOpenLinearProgress(false);
    }
  }

  const sortedData = [...data].sort((a, b) => {
    if (order === 'asc') {
      return a[orderBy]?.toString().localeCompare(b[orderBy]?.toString()) || 0;
    } else {
      return b[orderBy]?.toString().localeCompare(a[orderBy]?.toString()) || 0;
    }
  });

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <Card variant="outlined" sx={{ borderRadius: 4 }}>
      <CardContent>
        <TableContainer component={Paper} sx={{ maxHeight: height * 0.70 }}>
          <Table size="small" stickyHeader>
            <TableHead>
              <TableRow>
                {/* <TableCell style={{ ...cellBorderStyle, ...headerCellStyle }}>ACCIÓN</TableCell> */}
                <TableCell style={{ ...cellBorderStyle, ...headerCellStyle }}>
                  <TableSortLabel
                    active={orderBy === 'Seccion'}
                    direction={order}
                    onClick={() => handleSort('Seccion')}
                  >
                    SECCIÓN
                  </TableSortLabel>
                </TableCell>
                <TableCell style={{ ...cellBorderStyle, ...headerCellStyle }}>
                  <TableSortLabel
                    active={orderBy === 'NombreCasilla'}
                    direction={order}
                    onClick={() => handleSort('NombreCasilla')}
                  >
                    CASILLA
                  </TableSortLabel>
                </TableCell>
                <TableCell style={{ ...cellBorderStyle, ...headerCellStyle }}>
                  <TableSortLabel
                    active={orderBy === 'Hora'}
                    direction={order}
                    onClick={() => handleSort('Hora')}
                  >
                    HORA
                  </TableSortLabel>
                </TableCell>
                <TableCell style={{ ...cellBorderStyle, ...headerCellStyle }}>
                  <TableSortLabel
                    active={orderBy === 'FolioBingo'}
                    direction={order}
                    onClick={() => handleSort('FolioBingo')}
                  >
                    BINGO
                  </TableSortLabel>
                </TableCell>
                <TableCell style={{ ...cellBorderStyle, ...headerCellStyle }}>
                  <TableSortLabel
                    active={orderBy === 'Usuario'}
                    direction={order}
                    onClick={() => handleSort('Usuario')}
                  >
                    USUARIO
                  </TableSortLabel>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {sortedData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => (
                <TableRow
                  key={index}
                  sx={{
                    backgroundColor: highlightedRow === index ? 'rgba(0, 0, 0, 0.05)' : 'initial'
                  }}
                  onMouseEnter={() => setHighlightedRow(index)}
                  onMouseLeave={() => setHighlightedRow(-1)}
                >
                  {/* <TableCell style={cellBorderStyle}>{row.accion}</TableCell> */}
                  <TableCell style={cellBorderStyle}>{row.Seccion}</TableCell>
                  <TableCell style={cellBorderStyle}>{row?.CasillaName?.toUpperCase()}</TableCell>
                  <TableCell style={cellBorderStyle}>{row.Hora}</TableCell>
                  <TableCell style={cellBorderStyle}>{row.FolioBingo}</TableCell>
                  <TableCell style={cellBorderStyle}>{row.Usuario}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[50, 100, 200, 350, 500]}
          component="div"
          count={sortedData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          sx={{ position: 'sticky', bottom: 0, backgroundColor: 'white' }} // Estilo para hacerlo fijo en la parte inferior
        />
      </CardContent>
    </Card>
  );
};

export default BingoTable;
