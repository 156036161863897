import React from "react";
import {
  IconButton,
  ListItem,
  ListItemButton,
  ListItemAvatar,
  ListItemText,
  Avatar,
  Divider,
  Icon
} from "@mui/material";
import middleware from "@middlewares/middleware";

const RoleListItem = ({ item, selectedIndex, handleSelectedIndex, handleDeleteItem }) => {
  const canDelete = middleware.checkMenuAction('Eliminar');
  const handleListItemClick = (event, index) => {
    let data = index;
    handleSelectedIndex(index, data);
  };

  return (
    <>
      <ListItem
        secondaryAction={
          <IconButton
            color={canDelete ? 'error' : 'primary'}
            edge="end"
            aria-label="info"
            selected={selectedIndex === item.id}
            onClick={event => {
              canDelete ? handleDeleteItem(item) : handleListItemClick(event, item.id)
            }}
          >
            <Icon>{canDelete ? 'delete' : 'visibility'}</Icon>
          </IconButton>
        }
        disablePadding
      >
        <ListItemButton
          selected={selectedIndex === item.id}
          onClick={event => handleListItemClick(event, item.id)}
        >
          <ListItemAvatar>
            <Avatar>
              <Icon>account_circle</Icon>
            </Avatar>
          </ListItemAvatar>
          <ListItemText primary={item.Perfil} />
        </ListItemButton>
      </ListItem>
      <Divider variant="inset" component="li" />
    </>
  );
};

export default RoleListItem;