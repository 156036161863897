import { getColorReverse } from "@utils/Utilities";


export const CATALOG_OPTIONS = [
    { id: "regiones", getAll: false },
    { id: "df", getAll: false },
    { id: "secciones", getAll: false },
    { id: "poligonos", getAll: false },
    { id: "dl", getAll: false },
    { id: "municipios_reportes", getAll: false },
    { id: "municipios_df", getAll: false },
    { id: "municipios_dl", getAll: false },
    { id: "mpio_df", getAll: false },
    { id: "mpio_dl", getAll: false },
];

export const COLUMNS_CERTIFICATION = [
    { id: "Seccion", label: "SECCIÓN", align: "center", type: "text" },
    { id: "Municipio", label: "MUNICIPIO", align: "center", type: "text" },
    { id: "Region", label: "REGIÓN", align: "center", type: "text" },
    { id: "DistritoFederal", label: "DISTRITO FEDERAL", align: "center", type: "text" },
    { id: "DistritoLocal", label: "DISTRITO LOCAL", align: "center", type: "text" },
    { id: "NombreCompleto", label: "NOMBRE COMPLETO", align: "center", type: "text" },
    { id: "TotalPersonasAsignadas", label: "TOTAL PERSONAS ASIGNADAS", align: "center", type: "text" },
    { id: "TotalPersonasCertificadas", label: "TOTAL PERSONAS CERTIFICADAS", align: "center", type: "text" },
    { id: "TotalPersonasCertificadasHoy", label: "TOTAL PERSONAS CERTIFICADAS HOY", align: "center", type: "text" },
    { id: "TotalPersonasVisitadas", label: "TOTAL PERSONAS VISITADAS", align: "center", type: "text" },
    { id: "TotalTareas", label: "TOTAL TAREAS", align: "center", type: "text" },
];

export const COLUMNS_CERTIFICATION_TASKS_HISTORY = [
    { id: "FechaInicioTarea", label: "FECHA INICIO DE TAREAS", align: "center", type: "text" },
    { id: "FechaFinTarea", label: "FECHA FIN DE TAREAS", align: "center", type: "text" },
    { id: "PersonasVisitadas", label: "PERSONAS VISITADAS POR TAREA", align: "center", type: "text" },
]

export const COLUMNS_TABLE_PENDING_CERTIFICATION = [
    { id: "Label", label: "ESTATUS", align: "left" },
    { id: "Avance", label: "AVANCE", align: "center", type: "number" },
    { id: "Meta", label: "META", align: "center", type: "number" },
    { id: "PorcentajeAvance", label: "PORCENTAJE AVANCE", align: "center", type: "linear", fixed: 1, customColors: getColorReverse },
];

export const COLUMNS_TABLE = [
    { id: "Region", label: "REGIÓN", align: "center", type: "text" },
    { id: "Municipio", label: "MUNICIPIO", align: "center", type: "text" },
    { id: "MunicipioReportes", label: "MUNICIPIO REPORTES", align: "center", type: "text" },
    { id: "Seccion", label: "SECCIÓN", align: "center", type: "text" },
    { id: "DistritoFederal", label: "DF", align: "center", type: "text" },
    { id: "DistritoLocal", label: "DL", align: "center", type: "text" },
    { id: "AvanceAfin", label: "AVANCE AFÍN", align: "center", type: 'number' },
    { id: "AvanceDomicilioNoEncontrado", label: "AVANCE DOMICILIO NO ENCONTRADO", align: "center", type: 'number' },
    { id: "AvanceEnProceso", label: "AVANCE EN PROCESO", align: "center", type: 'number' },
    { id: "AvanceNoAfin", label: "AVANCE NO AFÍN", align: "center", type: 'number' },
    { id: "AvanceNoAsignado", label: "AVANCE NO ASIGNADO", align: "center", type: 'number' },
    { id: "AvanceNoVisitado", label: "AVANCE NO VISITADO", align: "center", type: 'text' },

    { id: "AvanceNoViveEnDomicilio", label: "AVANCE NO VIVE EN DOMICILIO", align: "center", type: 'number' },
    { id: "AvancePersonaNoEncontrada", label: "AVANCE PERSONA NO ENCONTRADA", align: "center", type: 'number' },

    { id: "PorcentajeAvanceAfin", label: "PORCENTAJE AVANCE AFÍN", align: "center", type: "circular", fixed: 2 },
    { id: "PorcentajeAvanceDomicilioNoEncontrado", label: "PORCENTAJE AVANCE DOMICILIO NO ENCONTRADO", align: "center", type: "circular", fixed: 2, customColors: getColorReverse },
    { id: "PorcentajeAvanceEnProceso", label: "PORCENTAJE AVANCE EN PROCESO", align: "center", type: "circular", fixed: 2 },
    { id: "PorcentajeAvanceNoAfin", label: "PORCENTAJE AVANCE NO AFÍN", align: "center", type: "circular", fixed: 2, customColors: getColorReverse },
    { id: "PorcentajeAvanceNoAsignado", label: "PORCENTAJE AVANCE NO ASIGNADO", align: "center", type: "circular", fixed: 2, customColors: getColorReverse },
    { id: "PorcentajeAvanceNoVisitado", label: "PORCENTAJE AVANCE NO VISITADO", align: "center", type: "circular", fixed: 2, customColors: getColorReverse },

    { id: "PorcentajeAvanceNoViveEnDomicilio", label: "PORCENTAJE AVANCE NO VIVE EN DOMICILIO", align: "center", type: "circular", fixed: 2, customColors: getColorReverse },
    { id: "PorcentajeAvancePersonaNoEncontrada", label: "PORCENTAJE AVANCE PERSONA NO ENCONTRADA", align: "center", type: "circular", fixed: 2, customColors: getColorReverse },
    { id: "PorcentajePersonasCertificadas", label: "PORCENTAJE PERSONAS CERTIFICADAS", align: "center", type: "circular", fixed: 2 },
    { id: "PorcentajeTotalPendientesCertificar", label: "PORCENTAJE TOTAL PENDIENTES CERTIFICAR", align: "center", type: "circular", fixed: 2, customColors: getColorReverse },

    { id: "TotalCertificadores", label: "TOTAL CERTIFICADORES", align: "center", type: 'number' },
    { id: "TotalPendientesCertificar", label: "TOTAL PENDIENTES CERTIFICAR", align: "center", type: 'number' },
    { id: "TotalPersonas", label: "TOTAL PERSONAS", align: "center", type: 'number' },
    { id: "TotalPersonasCertificadas", label: "TOTAL PERSONAS CERTIFICADAS", align: "center", type: 'number' },
    { id: "TotalPersonasCertificadasHoy", label: "TOTAL PERSONAS CERTIFICADAS HOY", align: "center", type: 'number' },

    { id: "AvanceViveEnOtraSeccion", label: "AVANCE VIVE EN OTRA SECCIÓN", align: "center", type: 'number' },
    { id: "PorcentajeAvanceViveEnOtraSeccion", label: "PORCENTAJE AVANCE VIVE EN OTRA SECCIÓN", align: "center", type: "circular", fixed: 2 },
    { id: "AvanceDomicilioConocido", label: "AVANCE DOMICILIO CONOCIDO", align: "center", type: 'number' },
    { id: "PorcentajeAvanceDomicilioConocido", label: "PORCENTAJE AVANCE DOMICILIO CONOCIDO", align: "center", type: "circular", fixed: 2 },
]
