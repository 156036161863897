import { useEffect, useState, useCallback } from "react";

// Servicios
import UserRoleService from "@services/RoleServices";

// MaterialUI
import {
  Typography,
  CircularProgress,
  Button,
  Card,
  CardContent,
  Divider,
  Alert,
} from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2";
import Swal from "sweetalert2";

// Componentes
import LoadingForm from "@components/LinearProgress/LoadingForm";
import Seleccion from "@components/Roles/Seleccion";

const UserRoles = (props) => {
  const {
    userID,
    error,
    nombreCompleto,
    handleSubmit,
    API,
    change = userID,
  } = props;

  const [isLoadigForm, setIsLoadingForm] = useState(false);
  const [isSuccessForm] = useState(false);
  const [openLinearProgress, setOpenLinearProgress] = useState(false);

  const [isLoadingMore, setIsLoadingMore] = useState(false);

  // Total de elementos
  const [total, setTotal] = useState(0);

  // Todos los datos sin filtrar
  const [allData1, setAllData1] = useState("");

  // Se definen los datos que tiene cada componente de selección
  const [filteredData1, setFilteredData1] = useState("");

  // Los datos que se guardan en la columna derecha
  const [newData1, setNewData1] = useState([]);

  // Paginación y busqueda
  const [filtered1, setFiltered1] = useState([]);
  const [page1, setPage1] = useState(0);
  const [hasMore1, setHasMore1] = useState(true);
  const [pageSize, setPageSize] = useState(10);

  const formatData = (data) => {
    const dataEdited = data.map((item) => ({
      id: item.idPerfil,
      Perfil: item.Perfil,
      check: 1,
    }));
    return dataEdited;
  };

  // Agrega los perfiles que ya tenga el usuario
  useEffect(() => {
    if (userID) {
      API(userID)
        .then((res) => {
          setIsLoadingForm(true);
          setOpenLinearProgress(true);
          if (res.results) {
            setNewData1(formatData(res.response));
          } else {
            setNewData1([]);
          }
        })
        .catch((error) => {
          Swal.fire({ title: error.message, icon: "warning" });
        })
        .finally(() => {
          setIsLoadingForm(false);
          setOpenLinearProgress(false);
        });
    } else {
      setNewData1([]);
      setPage1(0);
    }
    // eslint-disable-next-line
  }, [change]);

  // Obtiene los roles
  const getRolesFun = useCallback((data) => {
    setIsLoadingForm(true);
    setOpenLinearProgress(true);

    UserRoleService.getRoles({
      page: data.page,
      pageSize: data.pageSize,
      filtered: data.filtered,
    })
      .then((res) => {
        if (res.results) {
          if (res.response.total > 0) {
            // Filtra los datos para evitar duplicidad
            const filtered = res.response.data.filter(
              (item) => !data.data.find((newItem) => newItem.id === item.id)
            );
            data.setFilteredData(filtered);

            if (!data.filtered.length) {
              data.setAllData(res.response.data);
              setTotal(res.response.total);
            }
          }

          // Verifica si realmente ya no hay más datos que llamar
          data.pageSize >= parseInt(res.response.total)
            ? data.setHasMore(false)
            : data.setHasMore(true);
        } else {
          Swal.fire({ title: res.message, icon: "warning" });
          data.setHasMore(false);
        }
      })
      .catch((e) => {
        data.setHasMore(false);
        Swal.fire({ title: e.message, icon: "warning" });
      })
      .finally(() => {
        setIsLoadingForm(false);
        setOpenLinearProgress(false);
      });
  }, []);

  // Filtra los datos para evitar duplicidad en el buscador
  const getRolesFiltered = useCallback((data) => {
    setIsLoadingForm(true);
    setOpenLinearProgress(true);

    UserRoleService.getRoles({
      page: data.page,
      pageSize: data.pageSize,
      filtered: data.filtered,
    })
      .then((res) => {
        data.setHasMore(false);
        if (res.results) {
          if (res.response.total > 0) {
            // Filtra los datos para evitar duplicidad
            const filtered = res.response.data.filter(
              (item) => !data.data.find((newItem) => newItem.id === item.id)
            );
            data.setFilteredData(filtered);
          }
        } else {
          Swal.fire({ title: res.message, icon: "warning" });
        }
      })
      .catch((e) => {
        Swal.fire({ title: e.message, icon: "warning" });
      })
      .finally(() => {
        setIsLoadingForm(false);
        setOpenLinearProgress(false);
      });
  }, []);

  useEffect(() => {
    getRolesFun({
      page: page1,
      pageSize: pageSize,
      filtered: filtered1,
      data: [],
      setFilteredData: setFilteredData1,
      setHasMore: setHasMore1,
      setAllData: setAllData1,
    });
    // eslint-disable-next-line
  }, []);

  // Manda a llamar mas datos cuando hacen falta cargar más del componente 1 (scroll y boton)
  const fetchHasMore1 = () => {
    // Evita la llamada a la api al completar todas las paginas
    const end = (page1 + 1) * pageSize;
    if (end < total) {
      setIsLoadingMore(true);
      UserRoleService.getRoles({
        page: page1 + 1,
        pageSize: pageSize,
      })
        .then((res) => {
          if (res.results) {
            // Agrega los datos nuevos con los anteriores
            const allData = allData1.concat(res.response.data);

            // Filtra los datos para evitar duplicidad
            if (allData) {
              setFilteredData1(allData);
            }
            setAllData1(allData);

            if (end >= res.response.total) {
              setHasMore1(false);
            }
          } else {
            Swal.fire({ title: res.message, icon: "warning" });
            setHasMore1(false);
          }
        })
        .catch((e) => {
          setHasMore1(false);
          Swal.fire({ title: e.message, icon: "warning" });
        })
        .finally(() => {
          setTimeout(() => {
            setIsLoadingMore(false);
          }, 1000);
        });
      setPage1(page1 + 1);
    }
  };

  // Buscador del componente 1
  const handleSearch1 = (searchInput) => {
    let filtro = [];
    if (searchInput === "") {
      filtro = [];
      setPage1(0);
      setFiltered1(filtro);
      getRolesFun({
        page: 0,
        pageSize,
        filtered: filtro,
        data: [],
        setFilteredData: setFilteredData1,
        setHasMore: setHasMore1,
        setAllData: setAllData1,
      });
    } else {
      filtro = [
        {
          id: "perfiles.Perfil",
          filter: "LIKE",
          value: searchInput,
          inheritFilterType: null,
        },
      ];
      // Llenado de los datos del filtro
      setPage1(0);
      setFiltered1(filtro);
      getRolesFiltered({
        page: 0,
        pageSize: total,
        filtered: filtro,
        setFilteredData: setFilteredData1,
        setHasMore: setHasMore1,
        setAllData: setAllData1,
        data: [],
        filter: true,
      });
    }
  };

  return (
    <>
      <LoadingForm
        isLoading={isLoadigForm}
        success={isSuccessForm}
        isOpen={openLinearProgress}
        setIsOpen={() => setOpenLinearProgress(!openLinearProgress)}
        loadinMessage={"Cargando..."}
      />

      <Card
        variant="outlined"
        className="card-primary"
        sx={{ width: "100%", height: "100%" }}
      >
        <CardContent style={{ textAlign: "center" }}>
          {nombreCompleto ? (
            <Typography variant="h6" mb={2}>
              {nombreCompleto}
            </Typography>
          ) : (
            <Typography variant="div" mb={2} mt={2} color={"secondary"}>
              Seleccione un usuario
            </Typography>
          )}

          <Divider
            style={{
              marginBottom: 20,
            }}
          />

          <Grid2 container spacing={2}>
            {filteredData1 ? (
              <Seleccion
                total={total}
                fetchHasMore={fetchHasMore1}
                hasMore={hasMore1}
                initialData={filteredData1}
                prevData={newData1}
                setNewData={setNewData1}
                handleSearch={handleSearch1}
                title="Seleccione los perfiles a los que podrá ofrecer soporte"
                error={error.value}
                open={!userID ? false : true}
                loading={!userID}
                loadingMore={isLoadingMore}
              />
            ) : (
              <Grid2 xs={12} justifyContent="center">
                <CircularProgress size={50} />
              </Grid2>
            )}
            {/* En caso de que se requiera mensajes de error en los Seleccion */}
            {error.value && (
              <Grid2 xs={12} paddingLeft="22px">
                <Alert severity="error">{error.message}</Alert>
              </Grid2>
            )}
            <Grid2 xs={12} sm={5} md={4} lg={3} marginX="auto">
              <Button
                fullWidth
                variant="contained"
                color="primaryDark"
                onClick={() => handleSubmit(newData1)}
                disabled={!userID}
              >
                Guardar
              </Button>
            </Grid2>
          </Grid2>
        </CardContent>
      </Card>
    </>
  );
};
export default UserRoles;
