import { Fragment, useEffect, useState } from "react";

// MUI
import { Card, CardContent, Box, Typography, IconButton, Icon, Divider, Rating, Tooltip, Avatar } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2";
import { LocationOn as LocationIcon } from "@mui/icons-material";
import { ChevronLeft, ChevronRight, WhatsApp, Edit } from "@mui/icons-material";
import { blue } from "@mui/material/colors";

import Swal from "sweetalert2";

//COMPONENTS
import EditModal from "@components/Compromisos/AuditoriaOrigen/EditModal";

//ASSETS
import PAN from "@assets/img/PAN_logo.png";
import PRD from "@assets/img/PRD_logo.png";
import PRI from "@assets/img/PRI_logo.png";

// UTILS
import { isTypePhone } from "@utils/validations";

// Middleware
import middleware from "@middlewares/middleware";

const DetalleCompromisos = (props) => {
  const { data, isExpanded, setIsExpanded, label, enableEdit, setLoading } = props;
  const [rating, setRating] = useState(0);
  const [openModal, setOpenModal] = useState(false);

  const edit = middleware.checkMenuAction("Editar");

  const handleRatingChange = (value) => {
    const normalizedValue = Math.min(Math.max(value, 0), 5);
    setRating(normalizedValue);
  };

  const redirectToWhatsApp = (phoneNumber) => {
    const whatsappUrl = `https://wa.me/52${phoneNumber}`;
    if (isTypePhone(phoneNumber)) window.open(whatsappUrl, "_blank");
    else
      Swal.fire({
        title: "Numero invalido",
        text: `"${phoneNumber}" no es un número válido`,
        icon: "warning",
      });
  };

  const editClick = () => {
    setOpenModal((prev) => !prev);
  };

  useEffect(() => {
    if (data?.VecesCapturado) {
      handleRatingChange(data.VecesCapturado);
    }
  }, [data]);

  return (
    <>
      <Card className="card-primary" style={{ width: "100%" }}>
        <CardContent>
          <Box display="flex" alignItems="center" justifyContent={enableEdit && data.length !== 0 && edit ? "space-between" : "flex-start"}>
            <IconButton onClick={() => setIsExpanded(!isExpanded)}>{isExpanded ? <ChevronRight /> : <ChevronLeft />}</IconButton>
            <Typography variant="h5" color="textPrimary">
              {label}
            </Typography>
            {enableEdit && data.length !== 0 && edit && (
              <IconButton
                edge="end"
                aria-label="Editar"
                onClick={editClick}
                sx={{
                  "&:hover": {
                    color: "rgb(0, 65, 160, .9)",
                  },
                }}
              >
                <Edit color="action" sx={{ color: blue[900] }} />
              </IconButton>
            )}
          </Box>
          <Divider />
          {data.length !== 0 && (
            <Grid2 container m={1}>
              <Box textAlign="center" width="100%" mb={3}>
                <Typography variant="body1" color="textPrimary" fontSize={17}>
                  <strong>
                    {" "}
                    {`${data.Nombre}`} {data.Paterno ? data.Paterno : ""} {data.Materno ? data.Materno : ""}{" "}
                    {data.Edad ? ` - (${data.Edad} años)` : null}{" "}
                  </strong>
                </Typography>
              </Box>
              {data?.INE && (
                <Box width="100%" display="flex" alignItems="center" m={1} ml="2vh">
                  <Typography>
                    <strong>Clave de elector:</strong> {data.INE}
                  </Typography>
                </Box>
              )}
              {data?.SeccionVota && (
                <Box width="100%" display="flex" alignItems="center" m={1} ml="2vh">
                  <Typography>
                    <strong>Sección Vota: </strong> {data.SeccionVota}
                  </Typography>
                </Box>
              )}
              {data?.Celular && (
                <Box width="100%" display="flex" alignItems="center" mt={1} ml="2vh">
                  <Typography>
                    <strong>Celular: </strong> {data.Celular}
                  </Typography>
                  <Tooltip title={`WhatsApp: ${data.Celular}`} arrw position="left">
                    <IconButton
                      edge="end"
                      aria-label="whatsapp"
                      onClick={() => redirectToWhatsApp(data.Celular)}
                      sx={{
                        "&:hover": {
                          color: "rgb(0, 65, 160, .9)",
                        },
                        marginLeft: 1,
                      }}
                    >
                      <WhatsApp sx={{ fontSize: 24 }} />
                    </IconButton>
                  </Tooltip>
                </Box>
              )}
              {data?.Telefono && (
                <Box width="100%" display="flex" alignItems="center" ml="2vh">
                  <Typography>
                    <strong>Teléfono fijo: </strong> {data.Telefono}
                  </Typography>
                </Box>
              )}
              {data?.TelMensajes && (
                <Box width="100%" display="flex" alignItems="center" ml="2vh">
                  <Typography>
                    <strong>Teléfono mensajes:</strong> {data.TelMensajes}
                  </Typography>
                </Box>
              )}
              {data?.Municipio && (
                <Box width="100%" display="flex" alignItems="center" m={1} ml="2vh">
                  <LocationIcon />
                  <Typography>{data.Municipio}</Typography>
                </Box>
              )}
              {data?.Domicilio && (
                <Box width="100%" display="flex" alignItems="center" m={1} ml="2vh">
                  <Typography>
                    <strong>Domicilio:</strong> {data.Domicilio}
                  </Typography>
                </Box>
              )}
              {(data?.EsInsaculado === 0 || data?.EsInsaculado === 1) && (
                <Box width="100%" display="flex" alignItems="center" m={1} ml="2vh">
                  <Typography>
                    <strong>Es Insaculado: </strong> {data.EsInsaculado ? "Si" : "No"}
                  </Typography>
                </Box>
              )}
              {data?.ResponsableCompromiso && (
                <Typography m={1} ml="2vh">
                  <strong>Responsable del Compromiso:</strong> {data.ResponsableCompromiso}
                </Typography>
              )}
              {data?.VecesCapturado && (
                <Tooltip title={`Veces capturado: ${rating}`} arrow>
                  <Box width="100%" height="auto" display="flex" alignItems="center" m={1} ml="2vh">
                    <Typography>
                      <strong>No. de veces capturado:</strong>
                    </Typography>
                    <Rating name="half-rating-read" value={rating / 2} precision={0.5} readOnly />
                  </Box>
                </Tooltip>
              )}
              {
                data.TieneUsuario === 1 && (
                  <>
                    <Divider />
                    <Box width="100%" m={1} ml="2vh">
                      <Typography>
                        <strong>Tiene Usuario:</strong> {data.TieneUsuario ? "Si" : "No tiene cuenta"}
                      </Typography>
                      {data.TieneUsuario ? (
                        <Typography mt={1}>
                          <strong>Responsabilidad:</strong> {data.Responsabilidad}
                        </Typography>
                      ) : null}
                    </Box>
                    <Box width="100%" display="flex" alignItems="center" m={1} ml="2vh">
                      <Typography>
                        <strong>Partido: </strong>
                      </Typography>
                      <Avatar
                        src={data.idPartido === 1 ? PAN : data.idPartido === 2 ? PRI : data.idPartido === 3 ? PRD : null}
                        sx={{ width: 40, height: 40, ml: "2vh" }}
                      ></Avatar>
                    </Box>
                    <Box width="100%">
                      <Typography m={1} ml="2vh">
                        <strong>Responsable del Usuario:</strong> {data.ResponsableUsuario}
                      </Typography>
                    </Box>
                  </>
                )
              }
            </Grid2>
          )}
          {data.length === 0 && (
            <Grid2 container direction="column" justifyContent="center" alignItems="center" m={2}>
              <Icon fontSize={"large"}>info</Icon>
              <Typography variant="body1">Sin Informacion</Typography>
            </Grid2>
          )}
        </CardContent>
      </Card>
      {openModal && <EditModal setModalOpen={setOpenModal} modalOpen={openModal} params={data} setLoading={(e) => setLoading(e)} />}
    </>
  );
};

export default DetalleCompromisos;
