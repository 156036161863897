import { PrivilegedRoute } from "@middlewares";

import Estadisticas from "@pages/FrenteAmplio/Estadisticas";
import List from "@pages/FrenteAmplio/List";
import Form from "@pages/FrenteAmplio/Form";
import HistoryFA from "../../pages/FrenteAmplio/History";
import ListCaptura from "../../pages/FrenteAmplio/ListCaptura";
import ListPendiente from "../../pages/FrenteAmplio/ListPendientes";
import MovilizacionFA from "../../pages/FrenteAmplio/Movilizacion";
import BingoFA from "../../pages/FrenteAmplio/Bingo";
import MovilizacionCDE from "../../pages/FrenteAmplio/MovilizacionCDM";
import EstadisticaLiderazgo from "../../pages/FrenteAmplio/EstadisticaLiderazgo";
import EstadisticasBingo from "../../pages/FrenteAmplio/EstadisticasBingo";
import AsignacionMovilizacion from "../../pages/FrenteAmplio/AsignacionMovilizacion";
import ListAsignacion from "../../pages/FrenteAmplio/ListAsignacion";
import MyListMove from "../../pages/FrenteAmplio/MyListMove";
import RutasMovilizacion from "../../pages/FrenteAmplio/RutasMovilizacion";

const FrenteAmplioRoutes = [
  {
    path: "/frente-list",
    element: (
      <PrivilegedRoute>
        <List />
      </PrivilegedRoute>
    ),
  },
  {
    path: "/history_fa",
    element: (
      <PrivilegedRoute>
        <HistoryFA />
      </PrivilegedRoute>
    ),
  },
  {
    path: "/frente-avance",
    element: (
      <PrivilegedRoute>
        <ListCaptura />
      </PrivilegedRoute>
    ),
  },
  {
    path: "/lis-pendiente",
    element: (
      <PrivilegedRoute>
        <ListPendiente />
      </PrivilegedRoute>
    ),
  },
  {
    path: "/fam-stats",
    element: (
      <PrivilegedRoute>
        <Estadisticas />
      </PrivilegedRoute>
    ),
  },
  {
    path: "/frente-captura",
    element: (
      <PrivilegedRoute>
        <Form />
      </PrivilegedRoute>
    ),
  },
  {
    path: "/movilizacion-fa",
    element: (
      <PrivilegedRoute>
        <MovilizacionFA />
      </PrivilegedRoute>
    ),
  },
  {
    path: "/bingo_fa",
    element: (
      <PrivilegedRoute>
        <BingoFA />
      </PrivilegedRoute>
    ),
  },
  {
    path: "/movilizacion-CDM",
    element: (
      <PrivilegedRoute>
        <MovilizacionCDE />
      </PrivilegedRoute>
    ),
  },
  {
    path: "/estadisticas-liderazgo",
    element: (
      <PrivilegedRoute>
        <EstadisticaLiderazgo />
      </PrivilegedRoute>
    ),
  },
  {
    path: "/estadisticas-bingo",
    element: (
      <PrivilegedRoute>
        <EstadisticasBingo />
      </PrivilegedRoute>
    ),
  },
  {
    path: "/asignacion-movilizacion",
    element: (
      <PrivilegedRoute>
        <AsignacionMovilizacion />
      </PrivilegedRoute>
    ),
  },
  {
    path: "/list-movilizadores",
    element: (
      <PrivilegedRoute>
        <ListAsignacion />
      </PrivilegedRoute>
    ),
  },
  {
    path: "/mi-movilizacion",
    element: (
      <PrivilegedRoute>
        <MyListMove />
      </PrivilegedRoute>
    ),
  },
  {
    path: "/ruta-movilizacion",
    element: (
      <PrivilegedRoute>
        <RutasMovilizacion />
      </PrivilegedRoute>
    ),
  }

  
];

export default FrenteAmplioRoutes;
