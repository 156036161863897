import ApiExec, { downloadFile } from "@utils/ApiExec";

class RcoService {
  getInfoRcos = (params) => {
    return ApiExec(params, "rcos/get-info-rcos", "POST")
      .then(function (res) {
        return res;
      })
      .then((res) => {
        return res;
      })
      .catch((e) => {
        return e;
      });
  };

  getInfoRcosPoligonos = (params) => {
    return ApiExec(params, "rcos/get-info-rcos-poligonos", "POST")
      .then(function (res) {
        return res;
      })
      .then((res) => {
        return res;
      })
      .catch((e) => {
        return e;
      });
  };

  getInfoRcosMunicipios = (params) => {
    return ApiExec(params, "rcos/get-info-rcos-municipios", "POST")
      .then(function (res) {
        return res;
      })
      .then((res) => {
        return res;
      })
      .catch((e) => {
        return e;
      });
  };

  getInfoRcosRegiones = (params) => {
    return ApiExec(params, "rcos/get-info-rcos-regiones", "POST")
      .then(function (res) {
        return res;
      })
      .then((res) => {
        return res;
      })
      .catch((e) => {
        return e;
      });
  };

  getInfoRcosGeneral = (params) => {
    return ApiExec(params, "rcos/get-info-rcos-general-by-scope", "POST")
      .then(function (res) {
        return res;
      })
      .then((res) => {
        return res;
      })
      .catch((e) => {
        return e;
      });
  };

  getCatalogAmbitos = (params) => {
    return ApiExec(params, "rcos/get-catalog-ambitos", "POST")
      .then(function (res) {
        return res;
      })
      .then((res) => {
        return res;
      })
      .catch((e) => {
        return e;
      });
  };

  getInfoRcosMunicipalHistorial = (params) => {
    return ApiExec(params, "rcos/get-info-rcos-municipal-hist", "POST")
      .then(function (res) {
        return res;
      })
      .then((res) => {
        return res;
      })
      .catch((e) => {
        return e;
      });
  };

  getInfoRcosPoligonalHistorial = (params) => {
    return ApiExec(params, "rcos/get-info-rcos-poligonal-hist", "POST")
      .then(function (res) {
        return res;
      })
      .then((res) => {
        return res;
      })
      .catch((e) => {
        return e;
      });
  };

  export = ({ params, api }) => {
    return downloadFile({
      api,
      method: "POST",
      data: params,
    })
      .then((res) => res)
      .catch((e) => e);
  };
}
const rco = new RcoService();
export default rco;
