import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

//Mui
import {
  Container,
  Card,
  CardContent,
  Box,
  Grid,
  Typography,
} from "@mui/material";

//Componentes
import AdminLayout from "@components/MainPage/AdminLayout";
import ActionHeader from "@components/Containers/ActionHeader";
import LoadingForm from "@components/LinearProgress/LoadingForm";
import UserPhone from "@components/Users/UpdatePhone";
import UserUpdateContext from '@components/Users/UserUpdateContext'
import SupportService from "@services/SupportServices";
import BasicListDoble from "@components/Lists/BasicListDoble";
import ListItemCustom from '@components/Users/ListItemCustom'

//Validaciones
import { isTypePhone } from "@utils/validations";

const UpdatePhone = () => {
  const navigate = useNavigate();

  const [isLoadigForm, setIsLoadingForm] = useState(false);
  const [isSuccessForm, setIsSuccessForm] = useState(false);
  const [openLinearProgress, setOpenLinearProgress] = useState(false);
  const [id, setId] = useState("");
  const [nombreCompleto, setNombreCompleto] = useState("");
  const [userData, setUserData] = useState({
    id: null,
    idEstatus: 2,
    Estatus: "",
    Username: "",
    NombreCompleto: "",
    Edad: 0,
    Municipio: ""
  });

  const handleClick = (e) => {
    setId(e.id)
    setNombreCompleto(e.NombreCompleto)
    setUserData({
      id: e.id,
      idEstatus: e.idEstatus,
      Estatus: e.Estatus,
      Username: e.Username,
      NombreCompleto: e.NombreCompleto,
      Edad: e.Edad,
      Municipio: e.Municipio
    })
  };

  const handleClear = () => {
    setId(0)
    setNombreCompleto('')
    setUserData({
      id: null,
      idEstatus: 2,
      Estatus: "",
      Username: "",
      NombreCompleto: "",
      Edad: 0,
      Municipio: ""
    })
  };


  return (
    <AdminLayout>
      <LoadingForm
        isLoading={isLoadigForm}
        success={isSuccessForm}
        isOpen={openLinearProgress}
        setIsOpen={() => setOpenLinearProgress(!openLinearProgress)}
        loadinMessage={'Cargando...'}
        successMessage="¡Consultado con éxito!"
      />
      <Container maxWidth="false">
        <ActionHeader
          title="Cambio de Celular"
          isAction={true}
          titleButton="Regresar"
          iconButton="arrow_back"
          handleclick={() => navigate(-1)}
        />

        <Card className="card-primary">
          <CardContent>
            <Box sx={{ mt: 1, mb: 1 }}>
              <Grid container spacing={2}>
                <Grid item xs={12} lg={6}>
                  <BasicListDoble
                    id="id_nuevo"
                    API={SupportService.getUser}
                    APIKeys={[
                      { id: ["usuarios.Nombre", "usuarios.Paterno", "usuarios.Materno"], filter: "LIKE" },
                      { id: "usuarios.Username", filter: "=" },
                    ]}
                    itemComponent={ListItemCustom}
                    header={false}
                    handleClick={handleClick}
                    handleClear={handleClear}
                    label={"Encuentra y elige al usuario para la acción a realizar"}
                    labelNote={"Nota: Para realizar una búsqueda debe ingresar un nombre o por el contrario un número de celular."}
                    finder={true}
                    inputValidation={{ input2: isTypePhone }}
                    lengthValidation={{ input2: { filter: "=", value: 10 } }}
                    initialSearch={false}
                    emptySearch={false}
                    doubleSearch={true}
                    breaks={{ sm: 12, md: 6 }}
                    buttonsBreaks={{ sm: 12, md: 4 }}
                    disableCardType={true}
                    config={{ height: 400 }}
                    clearData={true}
                  />
                </Grid>
                <Grid item xs={12} lg={5.5}>
                  <Grid
                    style={{
                      height: "50px",
                      textAlign: "center",
                      marginBottom: "10px",
                      fontStyle: "italic",
                      borderBottom: "2px solid #000",
                    }}
                  >
                    <Typography
                      variant="h6"
                      gutterBottom
                      style={{
                        visibility: nombreCompleto ? "visible" : "hidden",
                      }}
                    >
                      {nombreCompleto}
                    </Typography>
                  </Grid>
                  <Card variant="outlined">
                    <CardContent>
                      <UserUpdateContext.Provider value={{ setIsLoadingForm, setOpenLinearProgress, setIsSuccessForm }}>
                        <UserPhone idUsuario={id} userData={userData} setUserData={setUserData} />
                      </UserUpdateContext.Provider>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </Box>
          </CardContent>
        </Card>
      </Container>
    </AdminLayout>
  );
};

export default UpdatePhone;
