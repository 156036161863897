import { useState } from "react";

// Material UI
import { Grid, TextField, InputAdornment, IconButton, Icon, Tooltip } from "@mui/material";
import { CleaningServicesOutlined } from "@mui/icons-material";

const Filter = ({ handleFilter }) => {
  const [search, setSearch] = useState("");
  const [data, setData] = useState({
    page: 0,
    pageSize: 5,
    filtered: [],
    sorted: [],
  });

  const handleSearch = (e) => {
    if (e.key === "Enter" && e.target.value.length !== 0) {
      const _isNaN = !isNaN(e.target.value);
      if (_isNaN)
        setData({
          ...data,
          filtered: [
            {
              id: "cat_secciones.Seccion",
              filter: "=",
              value: parseInt(e.target.value),
            },
          ],
        });

      handleFilter([
        {
          id: "cat_secciones.Seccion",
          filter: "=",
          value: parseInt(e.target.value),
        },
      ]);
    }
  };

  const handleClean = () => {
    setSearch("");
    setData({ ...data, filtered: [] });
  };

  const handleChange = (e) => setSearch(e.target.value);

  return (
    <Grid container rowSpacing={1}>
      <TextField
        sx={{ width: "100%" }}
        label="Buscar"
        type="text"
        name="Buscar"
        placeholder="Sección... (ej. 300)"
        onChange={handleChange}
        onKeyDown={handleSearch}
        value={search.toUpperCase()}
        variant="outlined"
        size="small"
        className="fixed-input"
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <Tooltip title="Buscar">
                <IconButton
                  color="secondary"
                  aria-label="upload picture"
                  component="label"
                  sx={{ m: -1 }}
                  onClick={() => handleSearch({ key: "Enter", target: { value: search } })}
                >
                  <Icon>search</Icon>
                </IconButton>
              </Tooltip>
              <Tooltip title="Limpiar">
                <IconButton color="primary" aria-label="upload picture" component="label" onClick={() => handleClean()}>
                  <CleaningServicesOutlined />
                </IconButton>
              </Tooltip>
            </InputAdornment>
          ),
        }}
      />
    </Grid>
  );
};

export default Filter;
