import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { Card, CardContent, CircularProgress } from "@mui/material";
import useWindowDimensions from "@hooks/useWindowDimensions";
import Grid2 from "@mui/material/Unstable_Grid2";
import { numberWithCommas } from "@utils/Utilities"

const Chart = ({ series, categories, label, graphType, loading }) => {
  const { height, width } = useWindowDimensions();
  let barColor = "#53D0EC";

  if (graphType === 1) {
    barColor = "#42a5f5";
  } else if (graphType === 2) {
    barColor = "#FFFF00";
  } else if (graphType === 3) {
    barColor = "#ef9a9a";
  }

  const mapData = (data) => {
    return data.map(function (item, i) {
      return {
        name: item.estado,
        y: item.pavance,
        label: numberWithCommas(item.value),
        color: barColor
      };
    });
  }

  const options = {
    credits: {
      enabled: false,
    },
    chart: {
      type: "bar",
      height: width > 430 ? height * 0.4 : 400,
    },
    title: {
      text: `${label.name} - ${label.type}`,
      style: {
        fontSize: width > 1400 ? width * 0.012 : "18px",
      },
    },
    subtitle: {
      text: `${series[0]?.FechaActualizo ? `Última actualización - ${series[0].FechaActualizo}` : ''}`,
      style: {
        fontSize: width > 1400 ? width * 0.0095 : "16px",
      },
    },
    xAxis: {
      categories: categories,
      style: {
        fontSize: width > 1400 ? width * 0.01 : "14px",
      },
      
    },
    yAxis: {
      title: {
        text: '',
      }
    },
    tooltip: {
      shared: true,
      headerFormat: `<span style="font-size: 15px">{point.point.name}</span><br/>`,
      pointFormat: `<span style="color:{point.color}">\u25CF</span> <b>{point.label}</b><br/>`
    },
    series:[
      {
        name: label.name,
        dataLabels: [
          {
            enabled: true,
            inside: false,
            format: `{point.y}%`,
            style: {
              fontSize: "0.85em",
            },
          },
        ],
        data: mapData(series),
      },
    ],
  };

  return (
    <Card className="card-primary">
      <CardContent>
        {loading ? (
          <Grid2
            container
            xs={12}
            height={width > 1440 ? height * 0.4 : 400}
            alignItems="center"
            justifyContent="center"
          >
            <CircularProgress size={50} />
          </Grid2>
        ) : (
          <HighchartsReact highcharts={Highcharts} options={options} />
        )}
      </CardContent>
    </Card>
  );
};

export default Chart;
