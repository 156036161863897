import { useEffect, useState } from "react";
import { useSorted } from "@hooks/useSorted";

import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Icon,
  Stack,
  Typography,
} from "@mui/material";

import Download from "@components/Downloads/Download";
import AdminLayout from "@components/MainPage/AdminLayout";
import ActionHeader from "@components/Containers/ActionHeader";
import DataTableReport from "@components/Tables/DataTableReport";
import CircularProgressPorcent from "@components/LinearProgress/CircularProgressPorcent";
import LoadingForm from "@components/LinearProgress/LoadingForm";
import FilterEstatal from "@components/Rcos/FilterEstatal";

import { COLUMNS_RCO_ESTATAL, PROGRESS_KEYS } from "@data/constants/Rco";
import { numberWithCommas, setDiynamicColumns } from "@utils/Utilities";
import rco from "@services/RcosServices";
import Swal from "sweetalert2";
import middleware from "@middlewares/middleware";
import { getVars } from "@utils/global";
import { API_EXPORT_BY_ID_ENTITY } from "@data/constants/Rco";
import { COORDINADOR_GENERAL, COORDINADOR_GENERAL_C27 } from "@data/constants";

const Estatal = () => {
  const {
    user: { idEstado, ambito_perfil },
  } = getVars("Token");

  const [isDownload, setIsDownload] = useState(false);
  const [total, setTotal] = useState(10);
  const [dataEstatal, setDataEstatal] = useState({
    data: [],
    totales: {},
  });
  const [idAmbitoAgrupadorTable, setIdAmbitoAgrupadorTable] = useState(null);
  const [data, setData] = useState({
    page: 0,
    pageSize: 25,
    filtered: [],
    sorted: [],
    idAmbitoAgrupador: null,
  });
  const [columnsDinamics, setColumnsDinamics] = useState([]);
  const [loading, setLoading] = useState({
    isLoadigForm: false,
    isSuccessForm: false,
    openLinearProgress: false,
  });
  const [hasPermissionDownload, setHasPermissionDownload] = useState(false);
  const { handleRequestSort } = useSorted({
    total: total,
    setData,
  });

  const getInfoRcosGeneral = async (body) => {
    try {
      setLoading({ ...loading, isLoadigForm: true, openLinearProgress: true });
      const result = await rco.getInfoRcosGeneral(body);
      const { response, message, results } = result;
      if (!results) {
        throw new Error(message);
      }
      parseToData({ data: response });
      setTotal(response.total);
    } catch (e) {
      Swal.fire({
        title: e,
        icon: "warning",
      });
    } finally {
      setLoading({
        ...loading,
        isLoadigForm: false,
        openLinearProgress: false,
      });
    }
  };

  const exportToExcel = async () => {
    setIsDownload(true);
    try {
      const result = await rco.export({
        api: "rcos/export-info-rcos-general-by-scope",
        params: {
          idAmbitoAgrupador: idAmbitoAgrupadorTable,
          filtered: data.filtered,
          sorted: data.sorted,
        },
      });
      const { success, error } = result;
      if (!success) {
        throw new Error(error.message);
      }
      Swal.fire({
        title: "Descargado con éxito",
        icon: "success",
      });
    } catch (e) {
      Swal.fire({
        title: e,
        icon: "warning",
      });
    } finally {
      setIsDownload(false);
    }
  };

  const exportToExcelByEntity = async () => {
    const route_api = API_EXPORT_BY_ID_ENTITY[idEstado];

    if (route_api) {
      setIsDownload(true);
      try {
        const result = await rco.export({
          api: route_api,
          params: {},
        });
        const { success, error } = result;

        if (!success) {
          throw new Error(error.message);
        }

        Swal.fire({
          title: "Descargado con éxito",
          icon: "success",
        });
      } catch (e) {
        Swal.fire({
          title: e.message,
          icon: "warning",
        });
      } finally {
        setIsDownload(false);
      }
    }
  };

  const parseToData = ({ data }) => {
    const { data: _data, totales } = data;

    const columns = setDiynamicColumns({
      data: _data[0],
      columns: COLUMNS_RCO_ESTATAL,
    });
    setColumnsDinamics(columns);

    const newDataMovilization = _data.map((item) => {
      const updatedItem = { ...item };

      PROGRESS_KEYS.forEach((key) => {
        updatedItem[key] = (
          <CircularProgressPorcent size={42} fontSize={10} value={item[key]} />
        );
      });

      columns.forEach((key) => {
        if (!PROGRESS_KEYS.includes(key.id)) {
          updatedItem[key.id] = numberWithCommas(item[key.id]);
        }
      });

      return updatedItem;
    });

    setDataEstatal({
      ...dataEstatal,
      data: newDataMovilization,
      totales: totales ? Object.values(totales) : [],
    });
  };

  const handleChangePagination = (pagination) => {
    setData({ ...data, ...pagination });
  };

  const handleSearchFilter = ({ filtereds }) => {
    setData((prevState) => ({ ...prevState, filtered: filtereds }));
  };

  const handleClearFilter = () => {
    if (
      data.filtered.length > 0 ||
      data.idAmbitoAgrupador !== idAmbitoAgrupadorTable
    ) {
      setData((prevState) => ({
        ...prevState,
        filtered: [],
        idAmbitoAgrupador: idAmbitoAgrupadorTable,
      }));

      //setIdAmbitoAgrupadorTable();
    }
  };

  useEffect(() => {
    const route_api = API_EXPORT_BY_ID_ENTITY[idEstado];
    const flagProfile = ambito_perfil.some(x => [COORDINADOR_GENERAL, COORDINADOR_GENERAL_C27].includes(x.idPerfil))
    const flag = route_api && flagProfile;
    setHasPermissionDownload(flag);
  }, []);

  useEffect(() => {
    if (data.idAmbitoAgrupador !== null) getInfoRcosGeneral(data);
    //eslint-disable-next-line
  }, [data]);

  return (
    <AdminLayout>
      <LoadingForm
        loadinMessage={"Cargando datos..."}
        isLoading={loading.isLoadigForm}
        success={loading.isSuccessForm}
        isOpen={loading.openLinearProgress}
        setIsOpen={() => setLoading({ ...loading, openLinearProgress: false })}
      />
      <Container maxWidth={false}>
        <ActionHeader title="RCO Avance Estructura" />
        <FilterEstatal
          //idAmbitoAgrupador={idAmbitoAgrupadorTable}
          setIdAmbitoAgrupador={setIdAmbitoAgrupadorTable}
          setData={setData}
          onChangeFilter={handleSearchFilter}
          onClearFilter={handleClearFilter}
        />
        <Card className="card-primary">
          <CardContent>
            <Stack
              direction={"row"}
              justifyContent={"space-between"}
              alignItems={"center"}
              marginBottom={2}
            >
              <Typography variant="body2" fontWeight={700}>
                Avance Estructura
              </Typography>
              <Box display={"flex"} gap={1}>
                {middleware.checkMenuAction("Exportar") && (
                  <Button
                    variant="outlined"
                    color="primaryDark"
                    startIcon={<Icon>download</Icon>}
                    size="small"
                    disabled={isDownload}
                    onClick={exportToExcel}
                  >
                    Exportar
                  </Button>
                )}

                {middleware.checkMenuAction("Exportar") &&
                  hasPermissionDownload && (
                    <Button
                      variant="outlined"
                      color="primaryDark"
                      startIcon={<Icon>download</Icon>}
                      size="small"
                      disabled={isDownload}
                      onClick={exportToExcelByEntity}
                    >
                      Reporte BCS
                    </Button>
                  )}
              </Box>
            </Stack>

            {isDownload && (
              <Box marginBottom={2}>
                <Download format={"xlsx"} isDownload={isDownload} />
              </Box>
            )}

            <DataTableReport
              rows={dataEstatal.data}
              hcolumns={columnsDinamics}
              total={total}
              summary={dataEstatal.totales}
              startIdxReport={0}
              pageProp={data.page}
              pageSize={data.pageSize}
              minHeight="0px"
              stickyHeader={true}
              isLoading={false}
              handleChangePagination={handleChangePagination}
              handleManualSort={handleRequestSort}
              totalsHasFloat={true}
            />
          </CardContent>
        </Card>
      </Container>
    </AdminLayout>
  );
};

export default Estatal;
