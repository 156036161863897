import { useState } from "react";

// Material
import { Container, ListItem, ListItemText, ListItemButton, Divider } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";

// Components
import AdminLayout from "@components/MainPage/AdminLayout";
import ActionHeader from "@components/Containers/ActionHeader";
import LoadingForm from "@components/LinearProgress/LoadingForm";
import AssignUser from "@components/Certificacion/Assign/AssignUserCertification";

import Filter from "@components/Certificacion/Assign/Filter";
import UserInfo from "@components/Certificacion/Assign/UserInfo";

import BasicListDoble from "@components/Lists/BasicListDoble";
import CertificacionServices from "@services/CertificationServices";

// Utilidades
import { isTypePhone } from "@utils/validations";

const Item = ({ item, index, selected, handleSelected }) => {
  return (
    <>
      <ListItem disablePadding>
        <ListItemButton selected={selected === index} onClick={() => handleSelected(item, index)}>
          <ListItemText primary={`${item.NombreCompleto} - (${item.Edad} años)`} secondary={item.email} />
        </ListItemButton>
      </ListItem>
      <Divider />
    </>
  );
};

const AssignCertification = () => {
  const [loading, setLoading] = useState(false);
  const [openLinearProgress, setOpenLinearProgress] = useState(false);
  const [userParams, setUserParams] = useState({});
  const[certificacion, getCertificacion] = useState(false)

  const handleClick = (data) => setUserParams(data);

  const handleClear = () => setUserParams({});

  return (
    <AdminLayout>
      <LoadingForm
        loadinMessage={"Cargando datos..."}
        isLoading={loading}
        isOpen={openLinearProgress}
        setIsOpen={() => setOpenLinearProgress(!openLinearProgress)}
      />
      <Container maxWidth={false}>
        <ActionHeader title={"Asignación de certificación"} isCustom={true} />
        <Grid2 container xs={12} spacing={2}>
          <Grid2 item xs={12} md={4}>
            <BasicListDoble
              API={CertificacionServices.getPeopleToAssign}
              APIKeys={[
                { id: ["usuarios.Nombre", "usuarios.Paterno", "usuarios.Materno"], filter: "LIKE" },
                { id: "usuarios.Username", filter: "=" },
              ]}
              header={{ title: "Usuarios disponibles", icon: "people" }}
              handleClick={handleClick}
              handleClear={handleClear}
              label={"Encuentra y elige al usuario para la asignación de permisos"}
              labelNote={"Nota: Para realizar una búsqueda debe ingresar un nombre o por el contrario un número de celular."}
              itemComponent={Item}
              finder
              inputValidation={{ input2: isTypePhone }}
              lengthValidation={{ input2: { filter: "=", value: 10 } }}
              doubleSearch
              breaks={{ sm: 6 }}
              buttonsBreaks={{ sm: 3, md: 6 }}
              config={{ height: 250 }}
              clearData
            />
          </Grid2>
          <Grid2 item xs={12} md={5}>
            <AssignUser
              params={userParams}
              getCertificacion = {getCertificacion}
              setLoading={(loading) => {
                setLoading(loading);
                setOpenLinearProgress(loading);
              }}
            />
          </Grid2>
          <Grid2 item xs={12} md={3}>
            <UserInfo
              params={userParams}
              certificacion={certificacion}
              setLoading={(loading) => {
                setLoading(loading);
                setOpenLinearProgress(loading);
              }}
            />
          </Grid2>
        </Grid2>
      </Container>
    </AdminLayout>
  );
};

export default AssignCertification;