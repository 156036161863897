import { useState } from "react";

// Material UI
import { Container, Card, CardContent, Typography } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2";
import Swal from "sweetalert2";

// Componentes
import AdminLayout from "@components/MainPage/AdminLayout";
import ActionHeader from "@components/Containers/ActionHeader";
import LoadingForm from "@components/LinearProgress/LoadingForm";
import Filter from "@components/Audit/Filter";
import LineChart from "@components/Audit/LineChart";
import PieChart from "@components/Audit/PieChart";
import TableSeguimiento from "@components/Audit/TableSeguimiento";
import TableResponsable from "@components/Audit/TableResponsable";
import FilterTable from "@components/Audit/FilterTable";
import TableReportes from "@components/Audit/TableReports";

// Servicios
import AuditoriaServices from "@services/AuditServices";

export default function Audit() {
  const [isLoadigForm /* setIsLoadingForm */] = useState(false);
  const [openLinearProgress, setOpenLinearProgress] = useState(false);
  const [isSuccessFormSave] = useState(false);

  // Graficas y tabla
  const [change, setChange] = useState(false);
  const [data, setData] = useState({
    Df: 0,
    Dl: 0,
    Region: 0,
    Municipio: 0,
    Poligono: [],
    Seccion: [],
  });
  const [filtered] = useState({
    filtered: [],
  });

  // Tabla reportes
  const [selectedOption, setSelectedOption] = useState(-1);
  const [dataT3, setDataT3] = useState([]);
  const [isDownload, setIsDownloading] = useState(false);

  // Filtrar graficas y tablas superiores
  const handleFilter = (action) => {
    setChange(!change);
    filtered.filtered = [];

    if (action !== "clear") {
      if (data.Df) {
        filtered.filtered.push({
          id: "idDF",
          filter: "=",
          value: data.Df,
        });
      }

      if (data.Dl) {
        filtered.filtered.push({
          id: "idDL",
          filter: "=",
          value: data.Dl,
        });
      }

      if (data.Region) {
        filtered.filtered.push({
          id: "idRegion",
          filter: "=",
          value: data.Region,
        });
      }

      if (data.Municipio) {
        filtered.filtered.push({
          id: "cat_secciones.idMunicipio",
          filter: "=",
          value: data.Municipio,
        });
      }

      /* if (data.Poligono.length > 0) {
        filtered.filtered.push({
          id: "idPoligono",
          filter: "IN",
          value: data.Poligono,
        });
      } */

      if (data.Seccion.length > 0) {
        filtered.filtered.push({
          id: "Seccion",
          filter: "IN",
          value: data.Seccion,
        });
      }
    }
  };

  const handleExport = async () => {
    setIsDownloading(true);
    try {
      // Parametros a enviar
      const data = {
        idAmbitoAgrupador: selectedOption,
        sorted: [
          { id: "CONVERT(PorcentajeExitosos, DECIMAL(4,3))", value: "desc" },
        ],
      };

      const result = await AuditoriaServices.exportProgressByScope(data);
      const { success } = result;
      if (!success) {
        throw new Error("Ocurrió un error, contacte al administrador.");
      }
      Swal.fire({
        title: "Descargado con éxito.",
        icon: "success",
      });
    } catch (error) {
      Swal.fire({
        title: error.message,
        icon: "warning",
      });
    } finally {
      setIsDownloading(false);
    }
  };

  return (
    <>
      <AdminLayout>
        <Container maxWidth={false}>
          <ActionHeader title="Dashboard de Actividades" />
          <LoadingForm
            loadinMessage={"Validando..."}
            successMessage="¡Consultado con éxito!"
            isLoading={isLoadigForm}
            success={isSuccessFormSave}
            isOpen={openLinearProgress}
            setIsOpen={() => setOpenLinearProgress(!openLinearProgress)}
          />

          <Card className="card-primary">
            <CardContent>
              <Filter
                data={data}
                setData={setData}
                handleFilter={handleFilter}
              />
            </CardContent>
          </Card>

          <Grid2 container spacing={2}>
            <Grid2 xs={12} md={8} lg={8}>
              <Card className="card-primary">
                <CardContent>
                  <LineChart change={change} filtered={filtered} />
                </CardContent>
              </Card>
            </Grid2>
            <Grid2 xs={12} md={4} lg={4}>
              <Card className="card-primary">
                <CardContent>
                  <PieChart change={change} filtered={filtered} />
                </CardContent>
              </Card>
            </Grid2>
          </Grid2>

          <Grid2 container spacing={2}>
            <Grid2 xs={12} md={4} lg={4}>
              <Card className="card-primary">
                <CardContent>
                  <Typography variant="body2" fontWeight={600} marginBottom={3}>
                    SEGUIMIENTO
                  </Typography>
                  <TableSeguimiento change={change} filtered={filtered} />
                </CardContent>
              </Card>
            </Grid2>
            <Grid2 xs={12} md={8} lg={8}>
              <Card className="card-primary">
                <CardContent>
                  <Typography variant="body2" fontWeight={600} marginBottom={3}>
                    RESPONSABLES
                  </Typography>
                  <TableResponsable change={change} filtered={filtered} />
                </CardContent>
              </Card>
            </Grid2>
          </Grid2>

          <Card className="card-primary">
            <CardContent>
              <FilterTable
                selectedOption={selectedOption}
                setSelectedOption={setSelectedOption}
                dataTable={dataT3.length}
                handleExport={handleExport}
              />
              <TableReportes
                data={dataT3}
                setData={setDataT3}
                isDownload={isDownload}
                selectedOption={selectedOption}
              />
            </CardContent>
          </Card>

          {/*  <Card className="card-primary">
            <CardContent>
              <Box sx={{ mt: 1, mb: 2 }}>
                <Grid2 container spacing={4}>
                  <Grid2 xs={12}>
                    <Filter />
                  </Grid2>
                  <Grid2 xs={12} lg={8}>
                    <LineChart />
                  </Grid2>
                  <Grid2 xs={12} md={6} lg={4}>
                    <PieChart />
                  </Grid2>
                  <Grid2 xs={12} md={6} lg={4}>
                    <TableSeguimiento />
                  </Grid2>
                  <Grid2 xs={12} lg={8}>
                    <TableResponsable />
                  </Grid2>
                  <Grid2 xs={12}>
                    <FilterTable
                      selectedOption={selectedOption}
                      setSelectedOption={setSelectedOption}
                      dataTable={dataT3.length}
                      handleExport={handleExport}
                    />
                    <TableReportes
                      data={dataT3}
                      setData={setDataT3}
                      isDownload={isDownload}
                      selectedOption={selectedOption}
                    />
                  </Grid2>
                </Grid2>
              </Box>
            </CardContent>
          </Card> */}
        </Container>
      </AdminLayout>
    </>
  );
}
