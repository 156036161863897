import { useState /* useEffect */ } from "react";

import { Box, CircularProgress } from "@mui/material";
//import { grey } from "@mui/material/colors";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";

//import UserSearchForm from "@components/Sije/CaptureForm/UserSearchForm";
//import RollCallForm from "@components/Sije/CaptureForm/RollCallForm";
import CaptureRollCallForm from "@components/Sije/CaptureForm/CaptureRollCallForm";
//import Switch from "../CaptureForm/Switch";
//import ListCasillas from "@components/Sije/CaptureForm/ListCasillas";
import CardRepresentative from "@components/Sije/CaptureForm/CardRepresentative";

import Swal from "sweetalert2";
import sije from "@services/SijeService";

const Installation = (props) => {
  const {
    nameTab,
    setOpenLinearProgress,
    setIsSuccessFormSave,
    setIsLoadingForm,
    isLoadigForm,
    reload,
    dataRC,
    loadingRc,
    userId,
  } = props;

  const [listCasillas, setListCasillas] = useState([]);
  //const [options, setOptions] = useState([]);
  const [user, setUser] = useState({});
  //const [loadingListCasillas, setLoadingListCasilla] = useState(false);
  /*   const [page, setPage] = useState(0);
  const [totalList, setTotalList] = useState(0);
  const [filterData, setFilterData] = useState({
    Celular: "",
    Nombre: "",
    Paterno: "",
    Materno: "",
  }); */
  /* 
  const getCasillasUser = async ({ idUser }) => {
    try {
      setLoadingListCasilla((prevState) => !prevState);
      const result = await sije.getCasillasUsuario({ idUsuario: idUser });
      const { response, message, results } = result;
      if (results) {
        setListCasillas(response.data);
      } else {
        throw new Error(message);
      }
    } catch (error) {
      Swal.fire({
        title: error,
        icon: "warning",
      });
    } finally {
      setLoadingListCasilla((prevState) => !prevState);
    }
  };
 */
  const handleSetValuesForm = (values) => {
    const { casilla, idUser, hora } = values;
    handleSaveForm({
      casillas: [{ idCasilla: casilla, FechaHora: hora }],
      idUser: userId,
    });
  };

  /*   const handleSaveValuesFormMassive = ({ casillas, FechaHora }) => {
    handleSaveForm({
      idUser: user.idUsuario,
      casillas: casillas.map((item) => ({ ...item, FechaHora })),
    });
  }; */

  const handleSaveForm = async (body) => {
    try {
      setIsLoadingForm(true);
      setOpenLinearProgress(true);
      setIsSuccessFormSave(false);
      const result = await sije.setInstalacionCasillas({
        ...body,
        OrigenCaptura: "web",
      });
      const { results, message } = result;

      if (results) {
        Swal.fire({
          title: message,
          icon: "success",
        });
        //setUser({});
        //setOptions([]);
        /* setFilterData({
          Nombre: "",
          Paterno: "",
          Materno: "",
          Celular: "",
        }); */
        /* setPage(0);
        setTotalList(0); */
        reload();
      } else {
        throw new Error(message);
      }
    } catch (error) {
      Swal.fire({
        title: error,
        icon: "warning",
      });
    } finally {
      setIsLoadingForm(false);
      setOpenLinearProgress(false);
      setIsSuccessFormSave(true);
    }
  };

  /* const handleClickItem = (id) => {
    const user = options.find((item) => item.value === id);
    getCasillasUser({ idUser: user.value });
    setUser(user);
  }; */

  /*   useEffect(() => {
    if (userId !== "") {
      setUser(userId);
      getCasillasUser({ idUser: userId, reload: true });
    }
  }, [userId]); */

  return (
    <Grid2 container spacing={2}>
      {/* <Grid2 xs={12} md={6} lg={6}>
        <UserSearchForm
          title="Encuentra y elige al usuario para registrar la instalación de la casilla."
          page={page}
          total={totalList}
          setPage={setPage}
          setTotal={setTotalList}
          filterData={filterData}
          options={options}
          setFilterData={setFilterData}
          setOptions={setOptions}
          setUser={setUser}
          handleClickItem={handleClickItem}
        />
      </Grid2> */}
      <Grid2 xs={12} md={6} lg={6}>
        {/* {Object.entries(user).length === 0 && (
          <Box
            className="card-primary"
            component={Card}
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
            height={"400px"}
          >
            <Stack
              direction={"column"}
              justifyContent={"center"}
              alignItems={"center"}
              alignContent={"center"}
            >
              <Box
                sx={{ backgroundColor: grey[200] }}
                width={"80px"}
                height={"80px"}
                padding={2}
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
                borderRadius={"50%"}
              >
                <Icon fontSize={"large"} sx={{ color: grey[700] }}>
                  post_add
                </Icon>
              </Box>
              <Typography variant="body2" marginTop={1} fontWeight={600}>
                Busca y selecciona al usuario para poder hacer el registro de la
                instalación de casilla.
              </Typography>
            </Stack>
          </Box>
        )} */}

        {!userId ? (
          <Box
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
            height={"600px"}
          >
            <CircularProgress />
          </Box>
        ) : (
          <CaptureRollCallForm
            idUser={userId}
            title={`${nameTab}`}
            handleSave={handleSetValuesForm}
            flag={
              listCasillas.length === 0 ///* && Object.entries(user).length > 0
            }
          />
        )}
      </Grid2>
      <Grid2 xs={12} md={6} lg={6}>
        <CardRepresentative dataRC={dataRC} loading={loadingRc} />
      </Grid2>
    </Grid2>
  );
};

export default Installation;

/* 

 <Switch test={true}>
            <CaptureRollCallForm
              idUser={user}
              title={`${nameTab}`}
              handleSave={handleSetValuesForm}
              flag={
                listCasillas.length === 0 ///* && Object.entries(user).length > 0
              }
            />
            <RollCallForm
              title={`${nameTab} - RC`}
              itemUser={user}
              setItemUser={setUser}
              options={options}
              handleSave={handleSetValuesForm}
              loading={isLoadigForm}
              flag={
                listCasillas.length === 1 ///* && Object.entries(user).length > 0 
              }
            />
            <ListCasillas
              title={`${nameTab} - RGS`}
              typeCheck="esInicioInstalacion"
              labelHora={
                "* Hora de Instalación Casilla (Formato: 24 hrs, ejemplo: 13:30)"
              }
              data={listCasillas}
              handleSave={handleSaveValuesFormMassive}
              flag={listCasillas.length > 1 ///* && Object.entries(user).length > 0 
            />
          </Switch>


*/
