import { useState /* useEffect */ } from "react";

import {
  Box,
  //Card,
  CircularProgress,
  //Icon,
  //Stack,
  //Typography,
} from "@mui/material";
//import { grey } from "@mui/material/colors";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";

//import UserSearchForm from "@components/Sije/CaptureForm/UserSearchForm";
//import RollCallForm from "@components/Sije/CaptureForm/RollCallForm";
//import ListCasillas from "@components/Sije/CaptureForm/ListCasillas";
import CaptureRollCallForm from "@components/Sije/CaptureForm/CaptureRollCallForm";
//import Switch from "../CaptureForm/Switch";
import CardCloseCasilla from "@components/Sije/OperationCasilla/CardCloseCasilla";

import Swal from "sweetalert2";
import sije from "@services/SijeService";

const Vote = (props) => {
  const {
    nameTab,
    setOpenLinearProgress,
    setIsSuccessFormSave,
    setIsLoadingForm,
    isLoadigForm,
    reload,
    userId,
  } = props;

  const [listCasillas, setListCasillas] = useState([]);
  //const [options, setOptions] = useState([]);
  //const [user, setUser] = useState({});
  //const [loadingListCasillas, setLoadingListCasilla] = useState(false);
  /*   const [page, setPage] = useState(0);
    const [totalList, setTotalList] = useState(0);
    const [filterData, setFilterData] = useState({
      Celular: "",
      Nombre: "",
      Paterno: "",
      Materno: "",
    }); */

  /*   const getCasillasUser = async ({ idUser }) => {
    try {
      setLoadingListCasilla((prevState) => !prevState);
      const result = await sije.getCasillasUsuario({ idUsuario: idUser });
      const { response, message, results } = result;
      if (results) {
        setListCasillas(response.data);
      } else {
        throw new Error(message);
      }
    } catch (error) {
      Swal.fire({
        title: error,
        icon: "warning",
      });
    } finally {
      setLoadingListCasilla((prevState) => !prevState);
    }
  }; */

  const handleSetValuesForm = (values) => {
    const { casilla, idUser, hora } = values;
    handleSaveForm({
      casillas: [{ idCasilla: casilla, FechaHora: hora }],
      idUser,
    });
  };

  /*  const handleSetValuesFormMassive = ({ casillas, FechaHora }) => {
    handleSaveForm({
      casillas: casillas.map((item) => ({ ...item, FechaHora })),
      idUser: user,
    });
  }; */

  const handleSaveForm = async (body) => {
    try {
      setIsLoadingForm(true);
      setOpenLinearProgress(true);
      setIsSuccessFormSave(false);
      const result = await sije.setCierreVotacion({
        ...body,
        OrigenCaptura: "web",
      });
      const { results, message } = await result;
      if (results) {
        Swal.fire({
          title: message,
          icon: "success",
        });
        // setUser({});
        // setOptions([]);
        /* setFilterData({
          Nombre: "",
          Paterno: "",
          Materno: "",
          Celular: "",
        }); */
        /* setPage(0);
        setTotalList(0); */
        reload();
      } else {
        throw new Error(message);
      }
    } catch (error) {
      Swal.fire({
        title: error,
        icon: "warning",
      });
    } finally {
      setIsLoadingForm(false);
      setOpenLinearProgress(false);
      setIsSuccessFormSave(true);
    }
  };

  /* const handleClickItem = (id) => {
    const user = options.find((item) => item.value === id);
    getCasillasUser({ idUser: user.value });
    setUser(user);
  }; */
  /* 
  useEffect(() => {
    if (userId !== "") {
      setUser(userId);
      getCasillasUser({ idUser: userId, reload: true });
    }
  }, [userId]); */

  return (
    <Grid2 container spacing={2}>
      {/*  <Grid2 xs={12} md={6} lg={6}>
        <UserSearchForm
          title="Encuentra y elige al usuario para registrar el cierre de la votación."
          page={page}
          total={totalList}
          setPage={setPage}
          setTotal={setTotalList}
          filterData={filterData}
          options={options}
          setFilterData={setFilterData}
          setOptions={setOptions}
          setUser={setUser}
          handleClickItem={handleClickItem}
        />
      </Grid2> */}
      <Grid2 xs={12} md={6} lg={6}>
        {/* <CardCloseCasilla loading={isLoadigForm} />
        {Object.entries(user).length === 0 && (
          <Box
            className="card-primary"
            component={Card}
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
            height={"400px"}
          >
            <Stack
              direction={"column"}
              justifyContent={"center"}
              alignItems={"center"}
              alignContent={"center"}
            >
              <Box
                sx={{ backgroundColor: grey[200] }}
                width={"80px"}
                height={"80px"}
                padding={2}
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
                borderRadius={"50%"}
              >
                <Icon fontSize={"large"} sx={{ color: grey[700] }}>
                  person_search
                </Icon>
              </Box>
              <Typography variant="body2" marginTop={1} fontWeight={600}>
                Busca y selecciona al usuario para completar el pase de lista.
              </Typography>
            </Stack>
          </Box>
        )} */}

        {!userId ? (
          <Box
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
            height={"600px"}
          >
            <CircularProgress />
          </Box>
        ) : (
          <CaptureRollCallForm
            idUser={userId}
            title={`${nameTab}`}
            handleSave={handleSetValuesForm}
            flag={listCasillas.length === 0}
          />
        )}
      </Grid2>
      <Grid2 xs={12} md={6} lg={6}>
        <CardCloseCasilla loading={isLoadigForm} />
      </Grid2>
    </Grid2>
  );
};

export default Vote;

/* 

<Switch test={true}>
            <CaptureRollCallForm
              idUser={user}
              title={`${nameTab}`}
              handleSave={handleSetValuesForm}
              flag={listCasillas.length === 0}
            />
            <RollCallForm
              title={`${nameTab} - RC`}
              itemUser={user}
              setItemUser={setUser}
              options={options}
              handleSave={handleSetValuesForm}
              loading={isLoadigForm}
              flag={listCasillas.length === 1}
            />
            <ListCasillas
              title={`${nameTab} - RGS`}
              typeCheck="esCierreVotacion"
              labelHora={
                "* Hora de Cierre Votación (Formato: 24 hrs, ejemplo: 13:30)"
              }
              data={listCasillas}
              handleSave={handleSetValuesFormMassive}
              flag={listCasillas.length > 1}
            />
          </Switch>

*/
