import { useState, useContext, useEffect, Fragment } from "react";
import { useFormik } from "formik";
import { Box, FormHelperText } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2";
import Swal from "sweetalert2";

import BasicSelect from "@components/Selects/BasicSelect";
import FormContext from "./FormContext";
import { AddResponsibilityInterface } from "@interfaces/VolunteerInterfaces";
import { AddResponsibilitySchema } from "@schemas/VolunteerSchemas";
import {
  AMBITO_ESTATAL,
  AMBITO_POLIGONAL,
  AMBITO_SECCIONAL,
  AMBITO_MZA,
} from "@data/constants";
import { removeDefaultOption } from "@utils/Utilities";
import CatalogService from "@services/CatalogServices";
import { ValidateSection, SelectFunction, FormButtons } from "./FormComponents";
import { changeResponsibility, changeScope } from "./GenericFunctions";

const TerritorialForm = (props) => {
  const { handleSubmit, assigned } = props;
  const { functionLabel, catalogs, volunteer, catResponsibilities, userOwner } =
    useContext(FormContext);

  const formik = useFormik({
    initialValues: {
      ...AddResponsibilityInterface,
      idUsurioAutorizo: userOwner.value,
      idCompromisoUnico: volunteer.id,
    },
    validationSchema: AddResponsibilitySchema,
    onSubmit: (values) => {
      const params = {
        ...values,
        idUsurioAutorizo: userOwner.value,
        idCompromisoUnico: volunteer.id,
      };
      handleSubmit(params);
    },
  });

  const [scopeLabel, setScopeLabel] = useState("");
  const [catScope, setCatScope] = useState([]);
  const [btnDisabled, setBtnDisabled] = useState(false);
  const [loadingCatScope, setLoadingCatScope] = useState(false);
  const [sectionValue, setSectionValue] = useState("");
  const [sectionValid, setSectionValid] = useState(false);

  useEffect(() => {
    if (assigned.length > 0) {
      handleClearForm();
    }
    //eslint-disable-next-line
  }, [assigned]);

  useEffect(() => {
    if (
      formik.values.idAmbitoCargo &&
      formik.values.idAmbitoCargo === AMBITO_MZA
    ) {
      formik.setValues({
        ...formik.values,
        AmbitoValorCargo: "",
        ResponsabilidadEtiqueta: "",
        Prioridad: "",
      });
    }
    //eslint-disable-next-line
  }, [formik.values.idPerfil]);

  useEffect(() => {
    if (sectionValid && formik.values.idAmbitoCargo === AMBITO_MZA) {
      loadUrbanBlocks(sectionValue);
    } else {
      if (formik.values.idAmbitoCargo === AMBITO_MZA) {
        setCatScope([]);
      }
    }
    //eslint-disable-next-line
  }, [sectionValid]);

  const handleChangeResponsibility = async (value) => {
    const profileInfo = catResponsibilities.find(
      (item) => item.value === value
    );
    setSectionValue("");
    setLoadingCatScope(true);

    if (profileInfo.idAmbitoCargo === AMBITO_MZA) {
      setBtnDisabled(true);
    } else {
      setBtnDisabled(false);
    }

    try {
      const { success, data } = await changeResponsibility({
        value,
        catalogs,
        profileInfo,
        values: formik.values,
      });

      if (success) {
        formik.setValues(data.newValues);
        setCatScope(data.catScope);
        setScopeLabel(data.scopeLabel);
      } else {
        setCatScope([]);
      }
    } catch (error) {
      setCatScope([]);
    } finally {
      setLoadingCatScope(false);
    }
  };

  const handleChangeScope = async (value) => {
    const profileInfo = catResponsibilities.find(
      (item) => item.value === formik.values.idPerfil
    );

    try {
      const { success, data } = await changeScope({
        value,
        catalogs,
        profileInfo,
        values: formik.values,
        catScope,
      });

      if (success) {
        formik.setValues(data);
      }
    } catch (error) {}
  };

  const handleChangeMpio = (value) => {
    setCatScope(
      removeDefaultOption(
        catalogs.poligonos.filter((item) => item.idMunicipio === value)
      )
    );
  };

  const loadUrbanBlocks = (section) => {
    setLoadingCatScope(true);
    const catalogsParams = [
      {
        id: "manzanas",
        filtered: [
          {
            id: "cat_manzanas.Seccion",
            filter: "=",
            value: section,
          },
        ],
      },
    ];
    CatalogService.getCatalogs(catalogsParams)
      .then((res) => {
        if (res.results) {
          setCatScope(
            res.response.catalogs.manzanas ? res.response.catalogs.manzanas : []
          );
        }
      })
      .catch((e) => {
        Swal.fire({
          title: e.message,
          icon: "warning",
        });
      })
      .finally(() => setLoadingCatScope(false));
  };

  const handleClearForm = () => {
    formik.setValues({
      idUsurioAutorizo: userOwner.value,
      idCompromisoUnico: volunteer.id,
      idPerfil: "",
      idAmbitoCargo: "",
      AmbitoValorCargo: "",
      ResponsabilidadEtiqueta: "",
      Prioridad: "",
      idPropietario: "",
    });
  };

  const RenderScope = () => {
    return (
      <Fragment>
        <BasicSelect
          label={scopeLabel}
          options={catScope}
          name="AmbitoValorCargo"
          value={formik.values.AmbitoValorCargo}
          onChange={(e) => handleChangeScope(e.target.value)}
          onBlur={formik.handleBlur}
          size="small"
          sx={{ width: "100%" }}
          isLoading={loadingCatScope}
        />
        <FormHelperText error>
          {formik.errors.AmbitoValorCargo &&
            formik.errors.AmbitoValorCargo.replace(
              "AmbitoValorCargo",
              scopeLabel
            )}
        </FormHelperText>
      </Fragment>
    );
  };

  return (
    <Box>
      <SelectFunction
        label={functionLabel}
        values={formik.values}
        touched={formik.touched}
        handleBlur={formik.handleBlur}
        errors={formik.errors}
        options={catResponsibilities}
        handleChange={handleChangeResponsibility}
      />

      {formik.values.idAmbitoCargo &&
        formik.values.idAmbitoCargo !== AMBITO_ESTATAL && (
          <Box>
            <Grid2 container spacing={2}>
              {formik.values.idAmbitoCargo &&
                formik.values.idAmbitoCargo === AMBITO_POLIGONAL && (
                  <Grid2 item xs={12} sm={12} md={6} lg={6}>
                    <BasicSelect
                      label={"Municipio"}
                      options={removeDefaultOption(catalogs.municipios)}
                      onChange={(e) => handleChangeMpio(e.target.value)}
                      size="small"
                      sx={{ width: "100%" }}
                    />
                  </Grid2>
                )}
              {formik.values.idAmbitoCargo &&
                ![AMBITO_SECCIONAL, AMBITO_MZA].includes(
                  formik.values.idAmbitoCargo
                ) && (
                  <Grid2 item xs={12} sm={12} md={6} lg={6}>
                    <RenderScope />
                  </Grid2>
                )}
            </Grid2>

            {formik.values.idAmbitoCargo &&
              [AMBITO_SECCIONAL, AMBITO_MZA].includes(
                formik.values.idAmbitoCargo
              ) && (
                <Grid2 container spacing={2}>
                  {formik.values.idAmbitoCargo &&
                  formik.values.idAmbitoCargo === AMBITO_MZA ? (
                    <ValidateSection
                      catalogs={catalogs}
                      handleChange={formik.handleChange}
                      setBtnDisabled={setBtnDisabled}
                      sectionValid={sectionValid}
                      setSectionValid={setSectionValid}
                      sectionValue={sectionValue}
                      setSectionValue={setSectionValue}
                    >
                      <RenderScope />
                    </ValidateSection>
                  ) : (
                    <Grid2 item xs={12} sm={12} md={6} lg={6}>
                      <RenderScope />
                    </Grid2>
                  )}
                </Grid2>
              )}
          </Box>
        )}

      <FormButtons
        submitForm={formik.submitForm}
        handleClearForm={handleClearForm}
        btnDisabled={btnDisabled}
      />
    </Box>
  );
};

export default TerritorialForm;
