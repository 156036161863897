import { useState } from "react";

import { Container } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";

import AdminLayout from "@components/MainPage/AdminLayout";
import ActionHeader from "@components/Containers/ActionHeader";
import LoadingForm from "@components/LinearProgress/LoadingForm";
import Filter from "@components/Activation/Capture/Filter";
import ListMobilization from "@components/Activation/Capture/ListMobilization";
import mobilization from '@services/MovilizationServices';
import Swal from "sweetalert2";

const MovilizationCapture = () => {

    const [isLoadigForm, setIsLoadingForm] = useState(false);
    const [isSuccessForm] = useState(false);
    const [openLinearProgress, setOpenLinearProgress] = useState(false);
    const [dataMovilization, setDataMovilization] = useState([]);
    const [users, setUsers] = useState([]);

    const onChangeFilter = async (params) => {
        setIsLoadingForm(true);
        setOpenLinearProgress(true);

        try {
            const { results, response, message } = await mobilization.getDetailMobilizedByHour(params);

            if (results) {
                setDataMovilization(response.data);
            } else {
                Swal.fire({
                    title: message,
                    icon: 'warning'
                });
            }
        } catch (error) {
            Swal.fire({
                title: error.message,
                icon: 'warning'
            })
        } finally {
            setIsLoadingForm(false);
            setOpenLinearProgress(false);
        }

    };

    const onClearFilter = () => {

    };

    const setMovilization = async ({ body }) => {

    }

    return (
        <AdminLayout>
            <LoadingForm
                loadinMessage={"Cargando Datos..."}
                isLoading={isLoadigForm}
                success={isSuccessForm}
                isOpen={openLinearProgress}
                setIsOpen={() => setOpenLinearProgress(!openLinearProgress)}
            />
            <Container maxWidth={false}>
                <ActionHeader title="Captura de Movilización" />

                <Grid2 container spacing={2}>
                    <Grid2 xs={12} md={3} lg={3}>
                        <Filter onChange={onChangeFilter} onClear={onClearFilter} />
                    </Grid2>
                    <Grid2 xs={12} md={9} lg={9}>
                        <ListMobilization
                            data={dataMovilization}
                            users={users}
                            setUsers={setUsers}
                            setMovilization={setMovilization}
                        />
                    </Grid2>
                </Grid2>
            </Container>
        </AdminLayout>
    )
}

export default MovilizationCapture;