import React, { useState, Fragment } from "react";
import { useNavigate } from "react-router-dom";
import { styled } from "@mui/material/styles";
import { Box, Drawer, List, Icon, Divider, ListItemButton, ListItemIcon } from "@mui/material";
import PropTypes from "prop-types";
import { getVars, setVars } from "@utils/global";
import NestedLink from "./NestedLink";

const drawerWidth = 260;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(9)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "left",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const Drawers = styled(Drawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const AdminSidebar = (props) => {
  const { open, handleToggle } = props;
  const Token = getVars("Token");
  const [menus] = useState(() => {
    if (Token.menus.length < 1) return [];
    return Token.menus;
  });
  const historyMenu = getVars("sideBar");
  const [openMenuList, setOpenMenuList] = useState(["Inicio"]);
  const [stateMenu] = useState(historyMenu.clickMenu || "");
  const navigate = useNavigate();

  const setBreadcrumbs = (name, id) => {
    if (openMenuList.includes(name)) {
      const result = openMenuList.filter((item) => item !== name);
      setOpenMenuList(result);
      setVars("sideBar", {
        clickMenu: stateMenu,
        caminoMenu: result,
        Menu: name,
      });
    } else {
      setOpenMenuList([...openMenuList, name]);
      setVars("sideBar", {
        clickMenu: stateMenu,
        caminoMenu: [...openMenuList, name],
        Menu: name,
      });
    }
  };

  const content = (
    <List>
      {menus &&
        menus.map((menu, index) => (
          <Fragment key={menu.id || menu.idMenu || index}>
            {menu.Visible === 1 &&
              (menu.submenus.length < 1 ? (
                <Fragment key={Math.random()}>
                  <ListItemButton
                    onClick={() => {
                      setBreadcrumbs(menu.Menu, menu.idMenu);
                      handleToggle(true);
                      navigate(menu.Ruta ?? "#");
                    }}
                  >
                    <Box
                      sx={{
                        textDecoration: "none",
                        color: "white",
                        display: "flex",
                        width: "100%",
                        py: 0.5,
                        alignItems: "center",
                      }}
                    >
                      <ListItemIcon>
                        <Icon sx={{ color: "white" }}>{menu.Icono}</Icon>
                      </ListItemIcon>

                      {menu.Menu}
                    </Box>
                  </ListItemButton>
                  <Divider />
                </Fragment>
              ) : (
                <NestedLink menu={menu} setBreadcrumbs={setBreadcrumbs} handleToggle={handleToggle} />
              ))}
          </Fragment>
        ))}
    </List>
  );

  return (
    <Drawers
      PaperProps={{
        sx: {
          color: "white",
          background: "linear-gradient(to bottom,#0343c6,#101e4c)",
          fillOpacity: 50,
          "&::-webkit-scrollbar": { width: { xs: "5px", sm: "10px" }, left: 0 },
          "&::-webkit-scrollbar-thumb": { backgroundColor: "rgba(0, 0, 0, 0.2)", borderRadius: "6px" },
          "&::-webkit-scrollbar-track": { backgroundColor: "rgb(255, 255, 255)" },
          "&::-webkit-scrollbar-button:vertical:increment": {
            backgroundColor: "rgb(255, 255, 255)",
            height: "12px",
            display: "block",
            backgroundImage:
              'url(\'data:image/svg+xml;charset=UTF-8,<svg xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 8 8"><path fill="%23000" d="M4 8 L0 4 L8 4 Z"/></svg>\')',
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
          },
        },
      }}
      variant="permanent"
      open={open}
      sx={{ backgroundColor: "whitesmoke" }}
      onMouseLeave={(e) => handleToggle(false)}
      // onMouseOut={(e) => handleToggle(true)}
    >
      <DrawerHeader />
      <Divider />
      {content}
    </Drawers>
  );
};

AdminSidebar.propTypes = {
  handleToggle: PropTypes.func,
  open: PropTypes.bool,
};

export default React.memo(AdminSidebar);
