export const CATALOGS_OPTIONS = [
  {
    id: "dfs",
  },
  {
    id: "dls",
  },
  {
    id: "municipios_df",
  },
  {
    id: "municipios_dl",
  },
  {
    id: "municipios",
  },
  {
    id: "secciones",
  },
];

export const VIEW_NATIONAL = 0;

export const VIEW_ESTATAL = 1;

export const GROUPBY = {
  DF: 3,
  DL: 4,
};

export const NAME_GROUP = {
  [VIEW_ESTATAL]: "Vista Estatal",
  [GROUPBY.DF]: "Distrito Federal",
  [GROUPBY.DL]: "Distrito Local",
};

export const COLUMNS_LNOM = [
  { id: "Nombre", label: "Nombre" },
  { id: "Total", label: "Total", align: "right" },
];
